import { createSlice } from '@reduxjs/toolkit'
import { getIncludeExcludeIds } from '../../utils/commonFunctions';

const initialState = {
  searchList: [],
  updatesSearchList: [],
  tagList: [],
  tagDetailsById: [],
  offsetLimitValue: {
    offset: 0,
    records: 50
  },
  isListView: false,
  isShowMoreActive: false,
  appliedContext: "project",
  tagId: 0,
  noResult: false,
  selectedSelectorControlValue: 0,
  countOfSelectedObjectIds: 0,
  dropdownSelectedObjectType: 'project',
  leadSearchListCount: 0,
  selectedLeadsFlag: [],
  selectorControlSelectionCount: 0,
  count: -1,
  noResultNotesTextSearch: false,
  notesList: [],
  notesSearchText: '',
  notesCount: 0,
  isProjectSelected: false,
  showProjectHierarchy: false,
  myLeadsAllUpdates: [],
  isAllNotesArchive: false,
  isInValidReq: false,
  isActiveTabChanged: false,
  updatesSearchListCount: 0,
  selectedCount: 0
}

const myLeadsSlice = createSlice({
  name: 'myleads',
  initialState,
  reducers: {
    updateSearchList: (state, action) => {
      state.searchList = action.payload;
      return state
    },
    updateLeadsSearchListCount: (state, action) => {
      state.leadSearchListCount = action.payload;
      return state
    },
    updateSearchListForUpdates: (state, action) => {
      state.updatesSearchList = action.payload;
      return state
    },
    updateLeadsSearchListCountForUpdates: (state, action) => {
      state.updatesSearchListCount = action.payload;
      return state
    },
    updateTagList: (state, action) => {
      state.tagList = action.payload;
      return state
    },
    updateTagDetailsById: (state, action) => {
      state.tagDetailsById = action.payload;
      return state
    },
    updateMyLeadOffsetLimitValue: (state, action) => {
      state.offsetLimitValue = action.payload
      return state
    },
    updateIsMyleadsListView: (state, action) => {
      state.isListView = action.payload
      return state
    },
    updateAppliedContext: (state, action) => {
      state.appliedContext = action.payload
      return state;
    },
    updateIfMoreResultAvailable: (state, action) => {
      state.isShowMoreActive = action.payload
      return state
    },
    updateTagId: (state, action) => {
      state.tagId = action.payload?.id
      state.createdByLoggedInUser = action.payload?.createdByLoggedInUser
      state.tagName = action.payload?.label
      return state;
    },
    updateNoResult: (state, action) => {
      state.noResult = action.payload;
      return state
    },
    updateSelectedSelectorControlValue: (state, action) => {
      let currentCountSelectorControlValue = state.selectedSelectorControlValue;
      let currentCountSelectedObjectIds = state.countOfSelectedObjectIds
      if (action.payload.count && (action.payload.count).toString() === "") {
        action.payload.count = action.payload.list === 'myLeadsUpdates' ? state.updatesSearchList.length : state.searchList.length
      }
      if (action.payload.isSelectionRelatedEntities) {
        state.countOfSelectedObjectIds = action.payload.count
        state.selectedSelectorControlValue = currentCountSelectorControlValue
        state.selectedCount = action.payload.count
      }
      else {
        state.countOfSelectedObjectIds = currentCountSelectedObjectIds
        state.selectedSelectorControlValue = action.payload.count.toString() !== "" ? action.payload.count : state.count
        state.selectedCount = action.payload.count.toString() !== "" ? action.payload.count : state.count
      }
      return state
    },
    updateSearchListSelection: (state, action) => {
      let selectionCount = action.payload.count;
      let updatedLeadsFlags = []
      let leadsFlags = action.payload.list === 'myLeadsUpdates' ? state.updatesSearchList : state.searchList
      if (selectionCount > 0) {
        leadsFlags.map(function (list, index) {
          if (index <= (selectionCount - 1)) {
            list['is_selected'] = true
            updatedLeadsFlags.push(list)
            state.selectedLeadsFlag.push(list)
          } else {
            list['is_selected'] = false
            updatedLeadsFlags.push(list)
            state.selectedLeadsFlag.push(list)
          }
        })
      } else {
        leadsFlags.map(function (list) {
          list['is_selected'] = false
          updatedLeadsFlags.push(list)
          state.selectedLeadsFlag.push(list)
        })
      }
      state.selectedSelectorControlValue = selectionCount
      return state
    },
    updateIsSelectedToSpecificRowMyLeads: (state, action) => {
      let leadsFlags = Object.assign({}, action.payload.entityType === 'myLeadsUpdates' ? state.updatesSearchList : state.searchList);
      let updatedLeadsFlags = []
      let objectType = action.payload.filterType === 'project' ? 'project_id' : action.payload.filterType === 'company' ? 'company_id' : 'person_id';

      let currnetIndexes = parseInt(state.selectedSelectorControlValue)
      Object.values(leadsFlags).map((record) => {
        if (record[objectType] === action.payload.id) {
          if (action.payload.isSelected === true) {
            record['is_selected'] = true
            currnetIndexes++
          }
          else {
            record['is_selected'] = false
            currnetIndexes--
          }
        }
        updatedLeadsFlags.push(record)
      })
      state.searchList = updatedLeadsFlags
      state.selectedSelectorControlValue = currnetIndexes
      const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
      state.excludeIds = excludeIds
      state.includeIds = includeIds
      return state
    },
    resetRelationalEntititySelectionCount: (state) => {
      state.countOfSelectedObjectIds = 0
      return state
    },
    updateDropDownSelectedObjectType: (state, action) => {
      state.dropdownSelectedObjectType = action.payload
    },
    updateNoResultNotesTextSearch: (state, action) => {
      state.noResultNotesTextSearch = action.payload
      return state
    },
    updateMyLeadsTag: (state, action) => {
      state.searchList = action.payload
      return state
    },
    updatelistOfSelectedObjectIdsTags: (state, action) => {
      state.searchList = action.payload
      return state
    },
    updateNotesList: (state, action) => {
      state.notesList = action.payload
    },
    updateNotesSearchText: (state, action) => {
      state.notesSearchText = action.payload
      return state
    },
    updateNotesCount: (state, action) => {
      state.notesCount = action.payload
    },
    updateShowMoreNotesList: (state, action) => {
      state.notesList = state.notesList.concat(action.payload)
    },
    updateIsProjectSelected: (state, action) => {
      state.isProjectSelected = action.payload
      return state
    },
    updateMyLeadsTag: (state, action) => {
      state.searchList = action.payload
      return state
    },
    updatelistOfSelectedObjectIdsTags: (state, action) => {
      state.searchList = action.payload
      return state
    },
    updateProcessId: (state, action) => {
      state.processId = action.payload
      return state
    },
    resetIncludeExcludeIds: (state, action) => {
      state.excludeIds = action.payload
      state.includeIds = action.payload
      return state
    },
    updateMyLeadsShowHierarchy: (state, action) => {
      state.showProjectHierarchy = action.payload.showProjectHierarchy
      return state
    },
    updateShowMoreSearchList: (state, action) => {
      let leadsFlags = state.searchList.concat(action.payload);
      if (state.selectedSelectorControlValue && state.searchList.length < state.selectedSelectorControlValue) {
        leadsFlags = leadsFlags.map((item, index) => index < state.selectedSelectorControlValue ? { ...item, is_selected: true } : item)
      }
      state.searchList = leadsFlags
      return state
    },
    updateShowMoreSearchListForUpdates: (state, action) => {
      let leadsFlags = state.updatesSearchList.concat(action.payload);
      if (state.selectedSelectorControlValue && state.updatesSearchList.length < state.selectedSelectorControlValue) {
        leadsFlags = leadsFlags.map((item, index) => index < state.selectedSelectorControlValue ? { ...item, is_selected: true } : item)
      }
      state.updatesSearchList = leadsFlags
      return state
    },
    storeUpdatesInMyLeads: (state, action) => {
      let myLeadsUpdatesArray = state.myLeadsAllUpdates
      let updateObject = {}
      updateObject['objectId'] = action.payload.objectId;
      updateObject['updates'] = action.payload.updates;
      const index = myLeadsUpdatesArray.findIndex((object) => object.objectId === action.payload.objectId);
      if (index === -1) {
        myLeadsUpdatesArray.push(updateObject)
      }
      state.myLeadsAllUpdates = myLeadsUpdatesArray
      return state;
    },
    updateIsAllNotesArchive: (state, action) => {
      state.isAllNotesArchive = action.payload
      return state
    },
    updateInValidRequest: (state, action) => {
      state.isInValidReq = action.payload
      return state
    },
    updateIsActiveTabChanged: (state, action) => {
      state.isActiveTabChanged = action.payload
      return state
    }
  }
})

export const { updateSearchList, updateMyLeadOffsetLimitValue, updateIsMyleadsListView, updateTagList, updateTagDetailsById, updateAppliedContext, updateTagId, updateIfMoreResultAvailable, updateNoResult, resetRelationalEntititySelectionCount, updateSearchListSelection, updateSelectedSelectorControlValue, updateDropDownSelectedObjectType, updateLeadsSearchListCount, updateIsSelectedToSpecificRowMyLeads, updateNoResultNotesTextSearch, updateNotesList, updateNotesSearchText, updateNotesCount, updateShowMoreNotesList, resetIncludeExcludeIds, updateMyLeadsTag, updatelistOfSelectedObjectIdsTags, updateProcessId, updateIsProjectSelected, updateMyLeadsShowHierarchy, updateShowMoreSearchList, storeUpdatesInMyLeads, updateIsAllNotesArchive, updateSearchListForUpdates, updateShowMoreSearchListForUpdates, updateInValidRequest, updateIsActiveTabChanged, updateLeadsSearchListCountForUpdates } = myLeadsSlice.actions

export const myleadsReducer = myLeadsSlice.reducer