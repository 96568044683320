import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import MyLeadsSearchSelector from '../selectorDropdown'
import { useSelector } from 'react-redux';
import SearchListResult from '../../search/searchListResult';
import ProjectHierarchy from '../../search/hierarchyList/projectHierarchy';

const Archive = (props) => {
    const myleads = useSelector((state) => state.myleads)
    const search = useSelector((state) => state.search)
    const [entityFlag, setEntityFlag] = useState(false)
    const [listMapToggle] = useState(true)

    useEffect(() => {
        if (search?.fetchRolesForProjects?.roleGroups?.length > 0) {
            setEntityFlag(true)
        }
    }, [search.fetchRolesForProjects])

    const getSearchListResult = () => {
        return (<SearchListResult
            currentOffset={0}
            type={"project"}
            prevNextList='myLeadsArchive'
            activeTab={props.activeTab}
            isListMapButtonFlag={listMapToggle}
        />)
    }

    return (

        props.activeTab === 'archive' &&
        (<div className="search-list-content">
            <div className={`search-result list-view-panel`}>
                <div className="active page-title-bar">
                    <div className='top-5'>
                        <div className="only-listview d-flex justify-content-between me-3">
                            <MyLeadsSearchSelector prevNextList='myLeadsArchive' activeTab={props.activeTab} />
                        </div>
                    </div>
                </div>
                <Row className="me-0">
                    <Col>
                        {
                            entityFlag ?  // check nested and hierarchical
                                (
                                    myleads.showProjectHierarchy && search.fetchRolesForProjects && search.fetchRolesForProjects.roleGroups && search.fetchRolesForProjects.roleGroups.length > 0 ?
                                        <ProjectHierarchy listMapToggle={listMapToggle} type={'project'} prevNextList="projectHierarchicalMyLeadsArchive" />
                                        : getSearchListResult()
                                ) : getSearchListResult()
                        }
                    </Col>
                </Row>
            </div>
        </div>)

    )
}

export default Archive