import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { fetchAllCollabs } from '../../apiclient';
import { updateCollabCheckboxFilter } from '../../redux/reducer';

const CollabFilter = (sharedType) => {
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings)
    const userData = useSelector((state) => state.user)
    const [isFilerPanelOpen, setisFilerPanelOpen] = useState(false)
    const [collaborationFilter, setcollaborationFilter] = useState(project && project.collaborationFilter ? project && project.collaborationFilter : {})
    const dispatch = useDispatch();

    const handleChange = (e, type) => {
        e.stopPropagation()
        dispatch(updateCollabCheckboxFilter({ checked: e.target.checked, type: type, objectType: sharedType.objectType }))
    }

    useEffect(() => {
        setcollaborationFilter(project && project.collaborationFilter)
    }, [project && project.collaborationFilter])

    const handleApply = () => {
        let payload = {
            id: project && project.detailsPage.objectId,
            type: project && project.detailsPage.objectType,
            offset: 0
        }
        setisFilerPanelOpen(!isFilerPanelOpen)
        fetchAllCollabs(payload, dispatch, project, settings, userData)
    }

    return (
        <div className="collab-filter-panel p-3 mb-3">
            <h6 className="mb-3">Filter by activity</h6>
            <div className="mb-0 d-flex justify-content-start align-items-center">
                <FormGroup className="mb-0 d-flex justify-content-start" check >
                    <div className="check-box-button me-4 mb-0">
                        <Input type="checkbox" id="type-one-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.tags ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.tags.active : false} onChange={e => handleChange(e, "tags")} />
                        <Label for="type-one-checkbox">Tags</Label>
                    </div>
                    <div className="check-box-button me-4 mb-0">
                        <Input type="checkbox" id="shares-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.shares.active ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.shares.active : false} onChange={e => handleChange(e, "shares")} />
                        <Label for="shares-checkbox">Shares</Label>
                    </div>
                    <div className="check-box-button me-4 mb-0">
                        <Input type="checkbox" id="notes-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.notes ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.notes.active : false} onChange={e => handleChange(e, "notes")} />
                        <Label for="notes-checkbox">Notes</Label>
                    </div>
                    <div className="check-box-button me-4 mb-0">
                        <Input type="checkbox" id="favourite-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.favourites ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.favourites.active : false} onChange={e => handleChange(e, "favourites")} />
                        <Label for="favourite-checkbox">Favourites</Label>
                    </div>
                    {
                        sharedType.objectType === "project" && settings && settings.preferences && settings.preferences.archiving_enabled ?
                            <div className="check-box-button me-4 mb-0">
                                <Input type="checkbox" id="archive-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.archived ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.archived.active : false} onChange={e => handleChange(e, "archived")} />
                                <Label for="archive-checkbox">Archived</Label>
                            </div> : ""
                    }
                    <div className="check-box-button me-4 mb-0">
                        <Input type="checkbox" id="mailed-checkbox" checked={collaborationFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter && collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.mailed ? collaborationFilter[sharedType.objectType] && collaborationFilter[sharedType.objectType].checkboxFilter.mailed.active : false} onChange={e => handleChange(e, "mailed")} />
                        <Label for="mailed-checkbox">Mailed</Label>
                    </div>
                </FormGroup>
                <Button className="btn btn-sm primary-cta-btn" onClick={handleApply}>Apply</Button>
            </div>
        </div>
    )
}
export default CollabFilter
