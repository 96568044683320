import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import PeopleProjectsRoles from "./PeopleProjectsRoles"
import './person.scss';

const ContactTabDetails = () => {
	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
		}
	}

	return (
		<Row className="pt-3 pb-3 ms-0 me-0 mb-3 project-tab-panel">
			<Col className="p-0 project-tab-wrapper">
				<Nav tabs>
					<NavItem>
						<NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}>
							Projects
                        </NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1" className="p-3">
						<PeopleProjectsRoles />
					</TabPane>
				</TabContent>
			</Col>
			
		</Row>
	);
}

export default ContactTabDetails;
