import React from 'react';
import { Col, Row } from 'reactstrap';
import ConstructionsHub from './constructionshub'


const Integrations = () => {
    return (
        <>
        <Row className="page-title-bar">
            <Col sm="12" col="12">
                <h2 className="h5 pl-3">Integrations</h2>
            </Col>
        </Row>
        <ConstructionsHub />
        </>
    )
}

export default Integrations;
