import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import Icon from '../../common/js/icon';
import FirstPage from './subpages/firstPage';
import SecondPage from './subpages/secondPage';
import ThirdPage from './subpages/thirdPage';
import { initializeFilterState } from '../../search/filters/utils';
import { resetDataExportCriteria, updateExportCountQuery, updateExportName, updateExportRolesFilter, updateSelectedTypeName, } from '../reducer';
import { updateCurrentPage } from '../../search/redux/searchPeristReducer';

const RightPanel = (props) => {
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const exports = useSelector((state) => state.export)
    const [countQuery, setCountQuery] = useState(exports?.exportCountQuery)
    const [currentPage, setCurrentPage] = useState(searchPersist?.currentPage);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const childRef = useRef(null);
    const childRefForFields = useRef(null);

    const handleCancel = () => {
        dispatch(resetDataExportCriteria())
        navigate('/app/export/list')
    }

    const handleNext = () => {
        if (currentPage === 0 && childRef.current.isValidated()) {
            setCurrentPage(1)
            dispatch(updateCurrentPage(1))
        }
        if (currentPage === 1 && childRefForFields.current.isValidated()) {
            setCurrentPage(2)
            dispatch(updateCurrentPage(2))
        }
        dispatch(updateExportCountQuery(countQuery))
    }

    const handlePrevious = () => {
        if (currentPage === 1) {
            dispatch(updateExportName({ feedname: exports.exportCriteria.feedname }))
            dispatch(updateSelectedTypeName(exports.selectedTypeName))
            setCurrentPage(0)
            dispatch(updateCurrentPage(0))
        }
        if (currentPage === 2) {
            setCurrentPage(1)
            dispatch(updateCurrentPage(1))
        }
    }

    useEffect(() => {
        props.currentPage(searchPersist?.currentPage)
        setCurrentPage(searchPersist?.currentPage)
    }, [searchPersist?.currentPage])

    const updateRefreshCountQuery = (query) => {
        setCountQuery(query)
    }

    return (
        <>
            <div className='export-all-fields'>
                <div className="export-page-wrapper">
                    <ColoredScrollbars className="details-panel-scrollbar">
                        {currentPage === 0 && <FirstPage updateRefreshCountQuery={updateRefreshCountQuery} ref={childRef} />}
                        {currentPage === 1 && <SecondPage updateRefreshCountQuery={updateRefreshCountQuery} ref={childRefForFields} />}
                        {currentPage === 2 && <ThirdPage updateRefreshCountQuery={updateRefreshCountQuery} pageindex={currentPage} handlePrevious={handlePrevious} handleCancel={handleCancel} />}
                        {currentPage !== 2 ? <div className="steps-footer-buttons">
                            <button className="outline-btn cancel me-3" onClick={() => handleCancel()}>Cancel</button>
                            {currentPage !== 0 && <button className="outline-btn previous me-3" onClick={() => handlePrevious()}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" />Previous</button>}
                            {currentPage === 0 || currentPage === 1 ?
                                (<button className="primary-btn next" disabled={exports?.saveSearchCount > 0 ? false : true} onClick={() => handleNext()}>Next<Icon color="#ffffff" size={20} icon="icon-gen-chevron" className="ms-1" /></button>) : ''
                            }
                        </div> : ("")
                        }
                    </ColoredScrollbars>
                </div>
            </div >
        </>
    )
}

export default RightPanel