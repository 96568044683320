import React from 'react'
import { Col, Row } from 'reactstrap';

export const ExportPlaceholder = () => {
    return (
        <div className="export-list-container placeholder">
            <div className="export-list-block placeholder">
                <div className="m-0 row">
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                    <Row className="list-header placeholder">
                        <Col className="user-names" xs="12" sm="12" md="3">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <div className="result-row-icon-placeholder">
                                        <span className="mb-0 icon-project"></span>
                                    </div>
                                    <div className="result-row-overview">
                                        <h5 className="result-title-name mb-1"></h5>
                                        <div className="sub-resultdetails-content">
                                            <span className="rs-client-name"><strong></strong></span>
                                            <span className="rs-value "><strong></strong></span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-feed text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-licenses text-center"  xs="12" sm="12" md="1">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                            </ul>
                        </Col>
                        <Col className="user-teamnames text-center"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-status"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="user-action"  xs="12" sm="12" md="2">
                        <ul className="list-group">
                            <li className="list-group-item">
                            <a action="true" className="result-block">
                                    <span className="rs-client-name"><strong></strong></span>
                                </a>
                            </li>
                        </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default ExportPlaceholder;