import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux';

import CollabFilter from './collabfilter';
import { collaborationTimelineView } from '../../../../utils';
import { fetchAllCollabs } from '../../apiclient';
import ColoredScrollbars from '../../../common/js/coloredScrollbars';

var MAX_LENGTH = 50
const ActivitiesCollabDetails = (props) => {
    const [collabs, setcollabs] = useState([])
    const [isFilterApplied, setisFilterApplied] = useState(false)
    const [size, setSize] = useState(MAX_LENGTH)
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings)
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch();

    useEffect(() => {
        setcollabs(collaborationTimelineView(project && project.collabList))
        setisFilterApplied(project && project.isNoCollaboration)
    }, [project, project && project.collabList])

    const appendData = (event) => {
        const bottom = Math.ceil(event.target.scrollHeight) - Math.floor(event.target.scrollTop) === Math.ceil(event.target.clientHeight) ||
            Math.ceil(event.target.scrollHeight) - Math.ceil(event.target.scrollTop) === Math.ceil(event.target.clientHeight);
        if (bottom && project && project.collabList) {
            if (!project && project.noMoreCollab) {
                let payload = {
                    id: project && project.detailsPage && project.detailsPage.objectId,
                    type: project && project.detailsPage && project.detailsPage.objectType,
                    offset: size
                }
                setSize(size + MAX_LENGTH)
                fetchAllCollabs(payload, dispatch, project, settings, userData)
            } else {
                setSize(MAX_LENGTH)
            }
        }
    }

    const renderTags = (event) => {
        return (
            <div>
                <div className="update-activityby">{event.eventBy.name} added {event.eventDetails && event.eventDetails.length === 1 ? "a tag" : event.eventDetails && event.eventDetails.length + " tags"}</div>
                <div className="tags-activity">
                    <ul>
                        {event.eventDetails && event.eventDetails.map(item => {
                            return (
                                <li className={item.isLabelShared === 1 ? "share-tag" : ""}>
                                    {item.description}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
    const renderDeleteTag = (event) => {
        return (
            <div>
                <div className="update-activityby">{event.eventBy.name} deleted {event.eventDetails && event.eventDetails.length === 1 ? " a tag" : event.eventDetails && event.eventDetails.length + " tags"}</div>
                <div className="tags-activity">
                    <ul>
                        {event.eventDetails && event.eventDetails.map(item => {
                            return (
                                <li className={item.isLabelShared === 1 ? "share-tag" : ""}>
                                    {item.description}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    const renderUpdateNote = (event) => {
        return (
            <div className="note-activity">
                <div className="note-activityby">{event.eventBy.name} edited a note </div>
                {event.eventDetails.map(item => {
                    return (
                        <div className="note-actitvyContent">
                            <pre className='notes-list'>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </pre>
                        </div>
                    )
                })}
            </div>
        )
    }
    const renderNotes = (event) => {
        return (
            <div className="note-activity">
                <div className="note-activityby">{event.eventBy.name} added a note </div>
                {event.eventDetails.map(item => {
                    return (
                        <div className="note-actitvyContent">
                            <pre className='notes-list'>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </pre>

                        </div>
                    )
                })}
            </div>
        )
    }
    const renderDeleteNote = (event) => {
        return (
            <div className="note-activity">
                <div className="note-activityby">{event.eventBy.name} deleted a note </div>
                {event.eventDetails.map(item => {

                    return (
                        <div className="note-actitvyContent">
                            <pre className='notes-list'>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </pre>
                        </div>
                    )
                })}
            </div>
        )
    }
    const renderDeleteShare = (event) => {
        return (
            <div>
                <div className="update-activityby">{event.eventBy.name} deleted shared with</div>
                <div className="shared-activity">
                    <ul>
                        {event.eventDetails.shareDelete.map(item => {
                            return (
                                <li className={item.type === "user" ? "user-icon" : item.type === "team" ? "teams-icon" : "everyone-icon"}>
                                    {item.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
    const renderShares = (event) => {
        return (
            <div>
                <div className="update-activityby">{event.eventBy.name} shared with</div>
                <div className="shared-activity">
                    <ul>
                        {event.eventDetails.shareWith.map(item => {
                            return (
                                <li className={item.type === "user" ? "user-icon" : item.type === "team" ? "teams-icon" : "everyone-icon"}>
                                    {item.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {event.eventDetails.message && event.eventDetails.message !== "" ?
                    (<div className="note-activity">
                        <div className="note-actitvyContent">
                            <p>{event.eventDetails.message}</p>
                        </div>
                    </div>) : ("")}
            </div>
        )
    }

    const renderDetails = () => {
        return (
            <div className="height-400">
            <ColoredScrollbars className="collab-wrapper" onScroll={appendData}>
                <div className="collaborationBox py-3 pl-4 ms-3">
                    <CollabFilter 
                        id={project && project.detailsPage.objectId}
                        objectType={props.objectType}
                        size={size}
                    />
                    <div className="collaborationBoxList">
                        <div className="collaborationUpdateBlock">
                            {Object.keys(collabs).map(collabItem => {
                                return (
                                    <div className="collaborationBy">
                                        <div className="collabtn-updated-date">{collabItem}</div>
                                        {collabs[collabItem].map(event => {
                                            return (
                                                <div className="collabtnBox d-flex">
                                                    <div className="user-name-icon">
                                                        {event.eventBy.initials}
                                                    </div>
                                                    <div className="collabtnContent">
                                                        <div className="update-time">{moment(event.eventOn).format("DD-MMM-YYYY")}</div>
                                                        {
                                                            event.eventType === "notes" ?
                                                                renderNotes(event) :
                                                                event.eventType === "tag" ?
                                                                    renderTags(event) :
                                                                    event.eventType === "share" ?
                                                                        renderShares(event) :
                                                                        event.eventType === "deleteShare" ?
                                                                            renderDeleteShare(event) :
                                                                            event.eventType === "deleteNote" ?
                                                                                renderDeleteNote(event) :
                                                                                event.eventType === "updateNote" ?
                                                                                    renderUpdateNote(event) :
                                                                                    event.eventType === "deleteTag" ?
                                                                                        renderDeleteTag(event) :
                                                                                        (<div className="update-activityby">
                                                                                            {event.eventBy.name} {event.eventType === "favourite" ? "favourited" : event.eventType === "unfavourite" ? "unfavourited" : event.eventType === "mail" ? "mailed" : event.eventType} this {props.objectType}
                                                                                        </div>)
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </ColoredScrollbars>
            </div>
        )
    }

    const renderLoader = () => {
        return (
            <div className="collab-wrapper">
                <div className="collaborationBox py-3 pr-4 mr-2">
                    {
                        !isFilterApplied ?
                            <CollabFilter
                                id={project && project.detailsPage.objectId}
                                objectType={props.objectType}
                                size={size}
                            /> : ""
                    }
                    <div className="m-3">
                        <strong>
                            {!isFilterApplied ? "No activities to view. Try adjusting your selection." : "No collaborations available"}
                        </strong>
                        <p>
                            {!isFilterApplied ? "" : "To perform activities, go to My tags/My shares/My notes"}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        collabs && Object.keys(collabs).length > 0
            ? renderDetails()
            : renderLoader()
    )
}

export default ActivitiesCollabDetails