import React from 'react'
import no_result_img from "../common/images/empty_state_noresults.png";

export function HttpErrorHandler() {
    return (
        <div className="d-flex flex-column align-items-center no-result-emptystate">
            <div className="text-center">
                <img src={no_result_img} />
                <p className="text-center empty-info">
                    <h6>Page not found.</h6>
                    <p className="mt-3">Sorry, the page you are looking for cannot be found. Try going back to the search screen or get help using 'Chat with us'</p>
                </p>
            </div>
        </div>
    )
}
