import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';

import { getRoutes } from './utils/routes'
import { persistor, store } from './redux/configureStore';
import reportWebVitals from './components/main/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tooltip/dist/react-tooltip.css'
import "react-datepicker/dist/react-datepicker.css";
import '../src/components/common/style.scss'
import './intercom.js'
import { getGoogleAnalyticsMeasurementId } from './utils';


const AppProvider = () => {
  useEffect(() => {
    let measurementId = getGoogleAnalyticsMeasurementId(window.location)
    ReactGA.initialize(measurementId);
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {getRoutes()}
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppProvider />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
