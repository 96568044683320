import { messageAlert } from "../../utils";
import getAxios from "../../utils/baseApi";
import { handleError } from "../../utils/errorHandler";
import { updateCustomerPreferences } from "../../redux/features/integrations/integrationslice"
var axios = getAxios()

export function getConstructionHubSettings(id, dispatch) {
    axios.get("customers/" + id + "/config")
        .then((response) => {
            if (response.status === 200) {
                dispatch(updateCustomerPreferences(response.data))
            }

        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export function saveConstructionHubSettings(payload, id, dispatch) {
    axios.put("customers/" + id + "/config", payload)
        .then((response) => {
            if (response.status === 201) {
                messageAlert(dispatch, "API key added successfully", 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}