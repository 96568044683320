import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { formatUrlWithProtocol, getFormatedPersonAddedDate } from '../../../../../utils';


const PeopleCurrentProject = ({ peopleList, activeTab, prevNextList }) => {
    const project = useSelector((state) => state.project)
    let navigate = useNavigate();
    let location = useLocation();

    const handleDetails = (event, personId, index) => {
        event.preventDefault();
        let routingState = {
            prevNextList: prevNextList,
            index: index,
            project_id: location.pathname.split('/')[3],
            company_id: project && project.detailsPage.objectId,
            previousState: location.state,
            list: prevNextList === 'peopleWorkingOnRole' ? project.peopleWorkingOnRole
                : prevNextList === 'peopleOnOtherProject' ? project.PeopleOnOtherProject
                    : prevNextList === 'peopleOtherOrgRoles' ? project.OtherOrgRoles : [],
            tab: '1'
        }
        navigate(`/app/person/${personId}`, { state: routingState });
    }

    return (
        <>
            {project && project.isPrint === false ?
                <div>
                    {activeTab !== '3' ?
                        <div class="container three-col-container mt-3 mb-4 ps-3 pe-4">
                            <div class="d-flex flex-wrap flex-people mb-3">
                                {peopleList && peopleList.length === 0 ?
                                    activeTab === '1' ?
                                        <div className="d-flex justify-content-center align-items-center h-100 flex-column people-empty-message">
                                            <div className="empty-people-image my-5">
                                                <div className="font-weight-bold p-3 text-center"><strong>No people found working on the project</strong></div>
                                            </div>
                                        </div> : activeTab === '2' ?
                                            <span><strong>No people found working here</strong></span>
                                            : ""
                                    : activeTab !== '3' && peopleList && peopleList.length >= 0 ?
                                        peopleList && peopleList.map((person, index) => (
                                            <Link
                                                id={person.person_id}
                                                to={`/app/person/${person.person_id}`}
                                                onClick={(e) => handleDetails(e, person.person_id, index)}
                                                className="contact-panel" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls={person.person_id}>
                                                <div class="contact-wrapper pl-5 pr-3">
                                                    <div className="card" key={person.person_id}>
                                                        <div className="card-body p-2 text-black">
                                                            <div className="d-flex align-items-start mt-2 mb-3">
                                                                <div className="user-contact-details me-3">
                                                                    <div className="flex-grow-1 ms-5">
                                                                        <div className="">
                                                                            <h5 className="card-title mb-1">{person && person.person_first_name ? person && person.person_first_name : ""} {person && person.person_last_name ? person && person.person_last_name : ""}</h5>
                                                                            {
                                                                                person && person.person_linkedin ?
                                                                                    (
                                                                                        <a className="linkedin-icon" target="_blank"
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                            href={formatUrlWithProtocol(person && person.person_linkedin)}
                                                                                        >
                                                                                        </a>
                                                                                    ) :
                                                                                    ('')
                                                                            }
                                                                            <p className="mb-0 me-2 pb-1 otherinfo">{person && person.person_job_title ? person && person.person_job_title : ""}</p>
                                                                            <h6 className="card-subtitle mb-0 otherinfo">{(person && person.person_phone || project && project.detailsPage.company_phone) ?
                                                                                (<>{person && person.person_phone ?
                                                                                    <span>{person && person.person_phone}
                                                                                        {person && person.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}</span>
                                                                                    :
                                                                                    <span> {project && project.detailsPage.company_phone}
                                                                                        {project && project.detailsPage.company_phone_tps ?
                                                                                            (<span className="tps-badge">TPS</span>) : ("")}</span>}</>) :
                                                                                ("")}</h6>
                                                                            {person && person.person_mobile ? (<p><span>{person && person.person_mobile}
                                                                                {person && person.person_mobile_tps ? (<span className="tps-badge">TPS</span>)
                                                                                    : ("")}</span></p>) : ""}
                                                                            <p className="card-text">
                                                                                {person && person.person_email ?
                                                                                    (<a href={`mailto:${person && person.person_email}`}>{person && person.person_email}</a>)
                                                                                    : (project && project.detailsPage.company_email ?
                                                                                        (
                                                                                            <a href={`mailto:${project && project.detailsPage.company_email}`}>{project && project.detailsPage.company_email}</a>
                                                                                        ) : ('')
                                                                                    )}</p>
                                                                        </div>
                                                                        <div>
                                                                            {person && person.person_added && activeTab !== '3' ?
                                                                                (<div className="person-date-added text-break">
                                                                                    {getFormatedPersonAddedDate(person && person.person_added, "YYYY-MM-DD")}
                                                                                </div>) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                        : ''
                                }
                            </div>
                        </div>
                        :
                        <div class="container three-col-container mt-3 mb-4 ps-3 pe-4">
                            <div class="d-flex flex-wrap management-people mb-3">
                                {peopleList && peopleList.length === 0 ?
                                    <div className="d-flex justify-content-center align-items-center h-100 flex-column people-empty-message">
                                        <div className="empty-people-image my-5">
                                            <div className="font-weight-bold p-3 text-center"><strong>There are no other roles currently listed for this organisation.</strong></div>
                                        </div>
                                    </div>
                                    : activeTab === '3' && peopleList && peopleList.length >= 0 ?
                                        peopleList && peopleList.map(person => (
                                            <div
                                                id={person.person_id}
                                                className="contact-panel">
                                                <div class="contact-wrapper pl-5 pr-3">
                                                    <div className="card" key={person.id}>
                                                        <div className="card-body p-2 text-black">
                                                            <div className="d-flex align-items-start mt-2 mb-3">
                                                                <div className="user-contact-details me-3"></div>
                                                                <div className="flex-grow-1 ms-4">
                                                                    <div>
                                                                        <h5 className="card-title mb-1">{person && person.person_first_name ? person && person.person_first_name : ""} {person && person.person_last_name ? person && person.person_last_name : ""}</h5>
                                                                        {
                                                                            person && person.person_linkedin ?
                                                                                (
                                                                                    <a className="linkedin-icon" target="_blank"
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                        href={formatUrlWithProtocol(person && person.person_linkedin)}
                                                                                    >
                                                                                    </a>
                                                                                ) :
                                                                                ('')
                                                                        }
                                                                        <p className="mb-0 me-2 pb-1 otherinfo">{person && person.person_job_title ? person && person.person_job_title : ""}</p>
                                                                        <h6 className="card-subtitle mb-0 otherinfo">{(person && person.person_phone || project && project.detailsPage.company_phone) ?
                                                                            (<>{person && person.person_phone ?
                                                                                <span>{person && person.person_phone}
                                                                                    {person && person.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}</span>
                                                                                :
                                                                                <span> {project && project.detailsPage.company_phone}
                                                                                    {project && project.detailsPage.company_phone_tps ?
                                                                                        (<span className="tps-badge">TPS</span>) : ("")}</span>}</>) :
                                                                            ("")}</h6>
                                                                        {person && person.person_mobile ? (<p className="mb-0"><span>{person && person.person_mobile}
                                                                            {person && person.person_mobile_tps ? (<span className="tps-badge">TPS</span>)
                                                                                : ("")}</span></p>) : ""}
                                                                        <p className="card-text">
                                                                            {person && person.person_email ?
                                                                                (<a href={`mailto:${person && person.person_email}`}>{person && person.person_email}</a>)
                                                                                : (project && project.detailsPage.company_email ?
                                                                                    (
                                                                                        <a href={`mailto:${project && project.detailsPage.company_email}`}>{project && project.detailsPage.company_email}</a>
                                                                                    ) : ('')
                                                                                )}</p>
                                                                    </div>
                                                                    <div>
                                                                        {person && person.person_added && activeTab !== '3' ?
                                                                            (<div className="person-date-added text-break">
                                                                                {getFormatedPersonAddedDate(person && person.person_added, "YYYY-MM-DD")}
                                                                            </div>) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : ''

                                }
                            </div>
                        </div>}
                </div>
                : (
                    <div className={`print-contact-people ${project && project.peopleWorkingOnRole && project.peopleWorkingOnRole.length > 0 ? '' : `${project && project.PeopleOnOtherProject && project.PeopleOnOtherProject.length > 0 ? '' : 'hide'}`}`}>
                        <strong className={`${project && project.peopleWorkingOnRole && project.peopleWorkingOnRole.length > 0 ? '' : `${project && project.PeopleOnOtherProject && project.PeopleOnOtherProject.length > 0 ? '' : 'hide'}`}`}>People </strong>
                        {
                            project && project.peopleWorkingOnRole && project.peopleWorkingOnRole.length > 0 &&
                            <p className="sub-title">People working on this project</p>
                        }
                        {
                            project && project.peopleWorkingOnRole && project.peopleWorkingOnRole.length > 0 && project.peopleWorkingOnRole.map((person, index) =>
                                <Row>
                                    <div className="user-contact-details" key={index}>
                                        <Col sm="3">{person && person.person_first_name ? person && person.person_first_name : ""} {person && person.person_last_name ? person && person.person_last_name : ""}</Col>
                                        <Col sm="3">{person && person.person_job_title ? person && person.person_job_title : ""}</Col>
                                        <Col sm="2">{(person && person.person_phone || project && project.detailsPage.company_phone) ?
                                            (<p>{person && person.person_phone ?
                                                <span>{person && person.person_phone}
                                                    {person && person.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}</span>
                                                :
                                                <span> {project && project.detailsPage.company_phone}
                                                    {project && project.detailsPage.company_phone_tps ?
                                                        (<span className="tps-badge">TPS</span>) : ("")}</span>}</p>) :
                                            ('')}
                                            <p>
                                                {person && person.person_mobile ? (<p><span>{person && person.person_mobile}
                                                    {person && person.person_mobile_tps ? (<span className="tps-badge">TPS</span>)
                                                        : ("")}</span></p>) : ""}
                                            </p>
                                        </Col>
                                        <Col sm="4">{person && person.person_email ?
                                            (<p><a href={`mailto:${person && person.person_email}`}>{person && person.person_email}</a></p>)
                                            : (project && project.detailsPage.company_email ?
                                                (<p>
                                                    <a href={`mailto:${project && project.detailsPage.company_email}`}>{project && project.detailsPage.company_email}</a>
                                                </p>) : ('')
                                            )}</Col>
                                    </div>
                                </Row>
                            )
                        }
                        {
                            project && project.PeopleOnOtherProject && project.PeopleOnOtherProject.length > 0 &&
                            <p className="sub-title">People on other projects</p>
                        }
                        {
                            project && project.PeopleOnOtherProject && project.PeopleOnOtherProject.length > 0 && project.PeopleOnOtherProject.map((person, index) =>
                                <Row>
                                    <div className="user-contact-details" key={index}>
                                        <Col sm="3">{person && person.person_first_name ? person && person.person_first_name : ""} {person && person.person_last_name ? person && person.person_last_name : ""}</Col>
                                        <Col sm="3">{person && person.person_job_title ? person && person.person_job_title : ""}</Col>
                                        <Col sm="2">{(person && person.person_phone || project && project.detailsPage.company_phone) ?
                                            (<p>{person && person.person_phone ?
                                                <span>{person && person.person_phone}
                                                    {person && person.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}</span>
                                                :
                                                <span> {project && project.detailsPage.company_phone}
                                                    {project && project.detailsPage.company_phone_tps ?
                                                        (<span className="tps-badge">TPS</span>) : ("")}</span>}</p>) :
                                            ('')}
                                            <p>
                                                {person && person.person_mobile ? (<p><span>{person && person.person_mobile}
                                                    {person && person.person_mobile_tps ? (<span className="tps-badge">TPS</span>)
                                                        : ("")}</span></p>) : ""}
                                            </p>
                                        </Col>
                                        <Col sm="4">{person && person.person_email ?
                                            (<p><a href={`mailto:${person && person.person_email}`}>{person && person.person_email}</a></p>)
                                            : (project && project.detailsPage.company_email ?
                                                (<p>
                                                    <a href={`mailto:${project && project.detailsPage.company_email}`}>{project && project.detailsPage.company_email}</a>
                                                </p>) : ('')
                                            )}</Col>
                                    </div>
                                </Row>
                            )
                        }
                        {
                            project && project.OtherOrgRoles && project.OtherOrgRoles.length > 0 &&
                            <p className="sub-title">Other roles within this organisation</p>
                        }

                        {
                            project && project.OtherOrgRoles && project.OtherOrgRoles.length > 0 && project.OtherOrgRoles.map((person, index) =>
                                <Row>
                                    <div className="user-contact-details" key={index}>
                                        <Col sm="3">{person && person.person_first_name ? person && person.person_first_name : ""} {person && person.person_last_name ? person && person.person_last_name : ""}</Col>
                                        <Col sm="3">{person && person.person_job_title ? person && person.person_job_title : ""}</Col>
                                        <Col sm="2">{(person && person.person_phone || project && project.detailsPage.company_phone) ?
                                            (<p>{person && person.person_phone ?
                                                <span>{person && person.person_phone}
                                                    {person && person.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}</span>
                                                :
                                                <span> {project && project.detailsPage.company_phone}
                                                    {project && project.detailsPage.company_phone_tps ?
                                                        (<span className="tps-badge">TPS</span>) : ("")}</span>}</p>) :
                                            ('')}
                                            <p>
                                                {person && person.person_mobile ? (<p><span>{person && person.person_mobile}
                                                    {person && person.person_mobile_tps ? (<span className="tps-badge">TPS</span>)
                                                        : ("")}</span></p>) : ""}
                                            </p>
                                        </Col>
                                        <Col sm="4">{person && person.person_email ?
                                            (<p><a href={`mailto:${person && person.person_email}`}>{person && person.person_email}</a></p>)
                                            : (project && project.detailsPage.company_email ?
                                                (<p>
                                                    <a href={`mailto:${project && project.detailsPage.company_email}`}>{project && project.detailsPage.company_email}</a>
                                                </p>) : ('')
                                            )}</Col>
                                    </div>
                                </Row>
                            )
                        }
                    </div>
                )

            }
        </>
    );
}

export default PeopleCurrentProject;