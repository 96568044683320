import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

import { updateArchive } from '../details/apiclient';

const ArchiveComponent = (props) => {
    const [openModal, setIsOpenModal] = useState(props.isArchiveModalOpen)
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings.preferences.user)
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const handleArchive = (e) => {
        e.preventDefault()
        let entity = {
            objectId: project && project.detailsPage.project_id,
            archive: project && project.detailsPage.archive ? false : true,
            objectType: "project"
        }
        updateArchive(entity, dispatch, project, settings, userData)
        props.handleClose()
    }

    const handleCancelArchive = () => {
        props.handleClose()
    }

    const toggleModal = () => {
        setIsOpenModal(!openModal)
    }

    useEffect(() => {
        setIsOpenModal(props.isArchiveModalOpen)
    },[props.isArchiveModalOpen])

    return (
        <>
            <Modal isOpen={openModal} toggle={() => toggleModal()}>
                <ModalHeader>
                    {(project && project.detailsPage.archive ? "Unarchive " : "Archive")}
                </ModalHeader>
                <div className="archive-patch">
                    Do you want to {project && project.detailsPage.archive ? " unarchive " : " archive "} this project ?
                    {/* {this.props.multiple ? " these projects" : " this project"} */}
                </div>
                <ModalFooter className="archive-footer">
                    <Button id="primaryBtn" className="primary-btn btn-save-cancel" onClick={(e)=>handleArchive(e)} autoFocus={true}>Yes</Button>
                    <Button className="outline-btn btn-save-delete" onClick={() => handleCancelArchive()}>No</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ArchiveComponent