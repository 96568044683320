import { persistStore, persistReducer, persistCombineReducers } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import { userReducer } from './features/user/userslice'
import { projectReducer } from "../components/details/redux/reducer";
import { savedsearchReducer } from "../components/savedsearch/redux/reducer";
import { myleadsReducer } from "../components/myleads/reducer";
import { useradminReducer } from "../components/useradmin/reducer";
import { searchReducer } from "../components/search/redux/reducer";
import { searchPersistReducer } from "../components/search/redux/searchPeristReducer";
import { settingReducer } from './features/settings/settingslice'
import { integrationReducer } from "./features/integrations/integrationslice";
import { notificationReducer } from "./features/notifications/notificationslice";
import { filterReducer } from "../components/search/filters/redux/reducer";
import { teamsReducer } from "../components/teams/reducer";
import { exportReducer } from "../components/export/reducer";
import { userAdminPersistReducer } from "../components/useradmin/userAdminPersistReducer";
import { leagueTableReducer } from "../components/leaguetable/reducer";
import { SIGNOUT, STORAGE_KEY } from "../utils/settings";
import { calendarReducer } from "../components/calendar/reducer";
import { justAskReducer } from "../components/justask/search/redux/reducer";
import { jAskFilterReducer } from "../components/justask/search/filters/redux/reducer";
import { jAskPersistReducer } from "../components/justask/search/redux/searchPeristReducer";

const logger = createLogger({});

const persistConfigCombined = {
	key: 'root',
	storage: storage,
	whitelist: ['user', 'searchPersist', 'settings', 'integrations', 'notifications', 'userAdminPersist', 'filter',
		'justAskFilter', 'justAskPersist'],
	stateReconciler: autoMergeLevel2
}

const appReducer = combineReducers({
	user: userReducer,
	project: projectReducer,
	savedsearch: savedsearchReducer,
	leagueTable: leagueTableReducer,
	calendar: calendarReducer,
	myleads: myleadsReducer,
	useradmin: useradminReducer,
	search: searchReducer,
	searchPersist: searchPersistReducer,
	settings: settingReducer,
	integrations: integrationReducer,
	notifications: notificationReducer,
	filter: filterReducer,
	teams: teamsReducer,
	export: exportReducer,
	userAdminPersist: userAdminPersistReducer,
	justAsk: justAskReducer,
	justAskFilter: jAskFilterReducer,
	justAskPersist: jAskPersistReducer
})

const rootReducer = (state, action) => {
	if (action.type === SIGNOUT) { // check for action type
		storage.removeItem(STORAGE_KEY)
		state = {}
	}
	return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfigCombined, rootReducer)

export const store = configureStore(
	{ reducer: persistedReducer, middleware: [thunk] });

export const persistor = persistStore(store);