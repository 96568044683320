import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import IncludeExcludeGrid from './includeExcludeGrid';
import { toggleFilterActiveState, toggleRoleAddedFilter, updateRoleAddedFilter, updateTextInputFilter } from './redux/reducer';
import { getRoleAddedSlideValue } from '../../../utils/commonFunctions';

const RoleFilter = (props) => {
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [data, setData] = useState(searchPersist?.customerProfile['project_roles']?.values)
    const [rolesSliderMsg, setRolesSliderMsg] = useState('1 week')
    const [toggleActive, setToggleActive] = useState(props.currentAdvancedSearch && props.currentAdvancedSearch.roleAdded && props.currentAdvancedSearch.roleAdded.active ? true : false)
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_roles']?.all_subscribed)
    const [rolesSlider, setRolesSlider] = useState(-1)
    const [isClearAll, setIsClearAll] = useState(props?.isClearAll)
    const dispatch = useDispatch()

    const showAll = () => {
        setAllSubscribed(!allSubscribed)
    }

    useEffect(() => {
        dispatch(updateTextInputFilter(getDefaultInputTextFilterPayload()))
    }, [])

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
    }, [props?.isClearAll])

    function getDefaultInputTextFilterPayload() {
        return {
            text: '',
            filter: props.dataMappings ? props.dataMappings.filterType : '',
            child: props.dataMappings ? props.dataMappings.subFilterType : '',
            isIncludeExclude: props?.dataMappings?.isIncludeExclude,
            includeKey: props.dataMappings?.includeKey,
            excludeKey: props.dataMappings?.excludeKey
        }
    }

    const handleToggle = (e) => {
        setToggleActive(e.target.checked)
        dispatch(toggleFilterActiveState({ key: "roleAdded", value: e.target.checked }))
        dispatch(toggleRoleAddedFilter(e.target.checked))
        dispatch(updateRoleAddedFilter(rolesSliderMsg))
    }

    function onSliderChange(e) {
        var sliderValue = {};
        let sliderOriginalValue = {
            "365": "-7",
            "180": "-6",
            "90": "-5",
            "60": "-4",
            "30": "-3",
            "14": "-2",
            "7": "-1",
            "1": "0"
        }
        for (var i = 0; i < props.currentAdvancedSearch.roleAdded.options.length; i++) {
            sliderValue[sliderOriginalValue[props.currentAdvancedSearch.roleAdded.options[i].value]] = props.currentAdvancedSearch.roleAdded.options[i].name
        }
        setRolesSlider(e)
        setRolesSliderMsg(sliderValue[e])
    }

    function onSliderChangeAfter(e) {
        var sliderValue = {};
        let sliderOriginalValue = {
            "365": "-7",
            "180": "-6",
            "90": "-5",
            "60": "-4",
            "30": "-3",
            "14": "-2",
            "7": "-1",
            "1": "0"
        }
        for (var i = 0; i < props.currentAdvancedSearch.roleAdded.options.length; i++) {
            sliderValue[sliderOriginalValue[props.currentAdvancedSearch.roleAdded.options[i].value]] = props.currentAdvancedSearch.roleAdded.options[i].name
        }
        // this.setState({ rolesSlider: e, rolesSliderMsg: sliderValue[e] }, function () {
        setRolesSlider(e)
        setRolesSliderMsg(sliderValue[e])
        dispatch(updateRoleAddedFilter(sliderValue[e]))

        // })
    }

    useEffect(() => {
        if (rolesSliderMsg == "") {
            onSliderChange(getRoleAddedSlideValue(filter?.filtersState[searchPersist.appliedContext]))
        }
        if (filter?.filtersState[searchPersist.appliedContext]?.roleAdded) {
            setToggleActive(filter.filtersState[searchPersist.appliedContext].roleAdded.active)
        }
        if (filter?.filtersState[searchPersist.appliedContext]?.roleAdded?.toggleActive) {
            filter?.filtersState[searchPersist.appliedContext]?.roleAdded?.options?.map(item => {
                if (item.active) {
                    onSliderChange(item.sliderValue[0])
                }
            })
        }
        if (filter?.filtersState[searchPersist.appliedContext]?.roleAdded?.active && filter.filtersState[searchPersist.appliedContext]?.roleAdded?.toggleActive && (filter?.filtersState[searchPersist.appliedContext]?.roleAdded?.options?.filter(c => c.active).length == 0)
       
        ) {
            onSliderChangeAfter(-1)
        }
        // props.initTags()
    }, [filter.filtersState])

    return (
        <>
            {
                props?.checkboxListFilters?.map(mapping => (
                    props?.currentAdvancedSearch && (props.currentAdvancedSearch[mapping.includeKey] || props.currentAdvancedSearch[mapping.excludeKey]) ? (
                        <>
                            <div className="advanced-filter-grid" id="new_filter_roles">
                                <div className="filterHeading">
                                    <h5 className="mb-3">Roles</h5>
                                </div>
                                <div className="filterBlock">
                                    <div className="textcontent">
                                        Filter by selected roles occupied by people and companies on projects
                                    </div>
                                    <div className="checkBoxFilterBx">
                                        <FormGroup id="categoryTypeFilter" name="category-filter" row className="pb-0 px-0 mb-0">
                                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                                <IncludeExcludeGrid
                                                    filterData={data}
                                                    filterType="project_roles"
                                                    allSubscribed={allSubscribed}
                                                    showAll={showAll}
                                                    dataMappings={mapping}
                                                    currentFilters={props.currentAdvancedSearch[mapping.filter]}
                                                    includeFilters={props.currentAdvancedSearch[mapping.includeKey]}
                                                    excludeFilters={props.currentAdvancedSearch[mapping.excludeKey]}
                                                    variablesHandler={props.variablesHandler}
                                                    initTags={props.initTags} fetchEntityCounts={props.fetchEntityCounts}
                                                    isClearAll={isClearAll}
                                                    handleSearchText={props.handleSearchText}
                                                />
                                                <div className='px-3 role-Slider'>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div className="textcontent">
                                                            <span >
                                                                Role added in the last <b>{rolesSliderMsg}</b>
                                                            </span>
                                                        </div>
                                                        <span className="switch-label">Specify period</span>
                                                        <div className="switch">
                                                            <Input id="switch-roles" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                                                checked={filter?.filtersState[searchPersist.appliedContext]?.roleAdded?.active}
                                                                onChange={(e) => handleToggle(e)} />
                                                            <Label for="switch-roles" className="mb-0"></Label>
                                                        </div>
                                                    </div>
                                                    <div className={`relative-slider-loc px-3 ${toggleActive ? '' : 'disableBlock'}`}>
                                                        <Slider
                                                            onChange={onSliderChange}
                                                            onAfterChange={onSliderChangeAfter}
                                                            value={rolesSlider}
                                                            min={-7}
                                                            max={0}
                                                            marks={1}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </>) : ('')
                ))}
        </>
    );

}
export default RoleFilter;
