import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
const initialState = {
  isAddNewUser: false,
  isEditUser: false,
  isModalOpen: false,
  isUniqueUser: true,
  offsetLimitValue: {
    offset: 0,
    records: 50
  },
  userLicences: [],
  assignedLicences: [],
  allUserDetails: [],
  adminUserList: [],
  tempAdminUserList:[],
  isUserAdminShowMoreActive:false,
  userListDeleteWithTransfer:[],
  userActivityList:{},
  offsetLimitValueForUserActivities: {
    offset: 0,
    records: 50
  },
  userSearchTerm:"",
  assignLicenceSearchTerm:"",
  isuserActivityShowMoreActive:false
}

const userAdminSlice = createSlice({
  name: 'useradmin',
  initialState,
  reducers: {
    updateIsAddNewUser: (state, action) => {
      state.isAddNewUser = action.payload
      return state
    },
    updateIsEditUser: (state, action) => {
      state.isEditUser = action.payload
      return state
    },
    modalOpenClose: (state, action) => {
      state.isModalOpen = action.payload
      return state
    },
    userDetails: (state, action) => {
      state.allUserDetails = action.payload;
      return state
    },
    updateIsUniqueUser: (state, action) => {
      state.isUniqueUser = action.payload
    },
    updateAdminUserOffsetLimitValue: (state, action) => {
      state.offsetLimitValue = action.payload
      return state
    },
    updateAdminUserCount: (state, action) => {
      state.adminUserListCount = action.payload
      return state
    },
    userLicences: (state, action) => {
      state.userLicences = action.payload
      return state
    },
    updateTempCountOfuserLicences: (state, action) => {
      state.userLicences = state.userLicences.map(record => record.licence_id === action.payload.licence_id ? action.payload : record)
      return state;
    },
    updateAssignedLicences: (state, action) => {
      state.assignedLicences = action.payload
      return state;
    },
    updateAdminUserList: (state, action) => {
      state.tempAdminUserList = action.payload
      state.adminUserList = action.payload
      return state;
    },
    updateLicenseCheckedStatus: (state, action) => {
      state.adminUserList = state.adminUserList.map(record => record.user_no === parseInt(action.payload.userno) ?
        {
          ...record,
          license: action.payload.licensesArray,
          licenseStatus: action.payload.licenseStatus
        } : record)
      return state;
    },
    resetAssignedLicenses: (state, action) => {
      state.assignedLicences = action.payload
      return state;

    },
    updateUserAdminShowMoreActive:(state,action)=>{
      state.isUserAdminShowMoreActive=action.payload
      return state;
    },
    updateUserListDeleteWithTransfer:(state,action)=>{
      state.userListDeleteWithTransfer=action.payload
      return state;
    },
    updateUserActivityList:(state,action)=>{
      state.userActivityList=action.payload
      return state;
    },
   
    updateUserActivityFilePath:(state,action)=>{
      state.filePath=action.payload
      return state;
    },
   
    updateAdminUserActivitiesOffsetLimitValue:(state,action)=>{
      state.offsetLimitValueForUserActivities=action.payload
      return state
    },
    resetUserSearchList:(state,action)=>{
      state.allUserDetails=action.payload
      return state;

    },
    resetAdminUserList:(state,action)=>{
      state.adminUserList=action.payload
      return state;
    },
    updateUserSearchTerm:(state,action)=>{
      state.userSearchTerm=action.payload
      return state;
    },
    updateAssignLicenseSearchTerm:(state,action)=>{
      state.assignLicenceSearchTerm=action.payload
      return state;
    },
    updateUserActivityShowMoreList: (state, action) => {
      state.userActivityList = state.userActivityList.concat(action.payload)
      return state
    },

    updateIsShowMoreActiveForUserActivity: (state, action) => {
      state.isuserActivityShowMoreActive = action.payload
    },
    

  }
})

export const { updateIsAddNewUser, updateIsEditUser, modalOpenClose, userDetails, updateIsUniqueUser, updateAdminUserOffsetLimitValue, updateAdminUserCount, userLicences, updateTempCountOfuserLicences, updateAssignedLicences, updateLicenseCheckedStatus, updateAdminUserList, resetAssignedLicenses,updateUserAdminShowMoreActive,updateUserListDeleteWithTransfer,updateUserActivityList ,updateUserActivityFilePath,updateAdminUserActivitiesOffsetLimitValue ,resetUserSearchList,resetAdminUserList,updateUserSearchTerm,updateAssignLicenseSearchTerm ,updateIsShowMoreActiveForUserActivity,updateUserActivityShowMoreList } = userAdminSlice.actions

export const useradminReducer = userAdminSlice.reducer