import { createSlice } from '@reduxjs/toolkit'
import { setActiveIfKeyIsPresent } from "../../../utils/commonFunctions"

const initialState = {
  preferences: {},
  allProjectStageChange: true,
  archiveProject: false,
  reminderEmails: true,
  allShares: true,
  feedNotification: true,
  allRoles: true,
  onSite: true,
  enablingWorks: true,
  dateChanges: true,
  mainContractAwarded: true,
  mainContractTender: true,
  preTender: true,
  plansApproved: true,
  plansSubmitted: true,
  projectRolesAdded: true,
  switchEmailNotification: true,
  feedEmailNotificationSwitch: true,
  switchShareNotification: true,
  switchReminderNotification: true,
  notificationSettings: [],
  isCurrentPasswordValidated: null,
  isDeveloper: false,
  hasApiKey: false,
  apiKey: null,
};

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateUserPreferences(state, action) {
      state.preferences = action.payload
      return state
    },
    userSettingsInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    updateNotificationSettings: (state, action) => {
      let updatedSettings = action.payload.slice()
      let settingsList = []
      let alertTypes = state.preferences && state.preferences.alert_types && state.preferences.alert_types.length > 0 ? state.preferences.alert_types : []
      setActiveIfKeyIsPresent(updatedSettings, alertTypes)
      state.notificationSettings = updatedSettings
    },
    toggleEmailNotification: (state, action) => {
      state.toggleEmailNotification = action.payload
    },
    updateProjectRoleLookup: (state, action) => {
      state.rolesLookup = action.payload
      return state
    },
    setValidatePasswordResponse: (state, action) => {
      state.isCurrentPasswordValidated = action.payload
      return state
    },
    resetValidatePasswordResponse: (state, action) => {
      state.isCurrentPasswordValidated = null
      return state
    },
    updateDeveloperApiKey(state, action) {
      state.apiKey = action.payload;
    },
    updateIsDeveloper(state, action) {
      state.isDeveloper = action.payload;
    },
    updateIfUserHasApiKey(state, action) {
      state.hasApiKey = action.payload;
    },
  }
})

export const { updateUserPreferences, userSettingsInput, setValidatePasswordResponse, updateNotificationSettings,
  resetValidatePasswordResponse, updateProjectRoleLookup, toggleEmailNotification, updateDeveloperApiKey, updateIsDeveloper, updateIfUserHasApiKey} = settingSlice.actions

export const settingReducer = settingSlice.reducer