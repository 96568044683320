import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import { displayCurrency, getFormatedDate, isValidText } from '../../utils';
import DonutChart from './donutChart';
import Icon from '../common/js/icon';
import defaultResearcherImg from '../common/images/no-user-image.png'
import { PROJECTDETAILS } from '../../utils/tour';

const DetailCard = () => {
    const [cards, setCards] = useState([]);
    const project = useSelector(state => state.project)
    const cardListArray = ['project_first_published', 'project_value', 'project_id', 'project_next_recall', 'project_last_checked', 'project_researcher_full_name', 'project_siteProgression']

    const createCardObject = () => {
        let cardList = [];
        let projectDeatils = project && project.detailsPage && project.detailsPage;
        cardListArray.map((elem) => {
            if (projectDeatils.hasOwnProperty(elem)) {
                let cardObject = {
                    ["title"]: elem,
                    ["desc"]: projectDeatils[elem],
                    ["image"]: elem !== 'project_researcher_full_name' ? null : projectDeatils.project_researcher_image
                }
                cardList.push(cardObject)
            }
        })
        return cardList;
    };
    useEffect(() => {
        let cardList = createCardObject()
        setCards(cardList)
    }, [project])

    const addDefaultSrc = (ev) => {
        ev.target.src = defaultResearcherImg
    }

    return (
        <Row className="meta-data pt-2 pb-2 position-relative mx-1">
               <div className="hori-placeholder-tour left-zero top-zero mt-2"  data-intro={PROJECTDETAILS.metadata.intro}  data-position={PROJECTDETAILS.metadata.position} data-class={PROJECTDETAILS.metadata.class}>&nbsp;</div>
            {project && project.detailsPage && project.detailsPage.project_first_published ?
                (<Col className="pl-0">
                    <span>First published:</span> {getFormatedDate(project && project.detailsPage && project.detailsPage.project_first_published, 'DD-MMM-YYYY')}
                </Col>) : ('')
            }
            {isValidText(project && project.detailsPage && project.detailsPage.project_value) ? (<Col>
                <span>Project value:</span>
                {project && project.detailsPage && displayCurrency(project.detailsPage.project_value, project.detailsPage.project_value_estimated)}
            </Col>) : ('')}
            <Col><span>Project reference:</span> {project && project.detailsPage && project.detailsPage.project_id}</Col>
            {
                project && project.detailsPage && project.detailsPage.project_next_recall ? (<Col><span>Next call: </span>
                    {getFormatedDate(project && project.detailsPage && project.detailsPage.project_next_recall, 'DD-MMM-YYYY')}
                </Col>) : ('')
            }
            {project && project.detailsPage && project.detailsPage.project_last_checked ?
                (<Col>
                    <span>Last checked:</span> {getFormatedDate(project && project.detailsPage && project.detailsPage.project_last_checked, 'DD-MMM-YYYY')}
                </Col>) : ('')
            }
            {

                (<Col className="researcher">
                    <img onError={addDefaultSrc}  lassName="researcher-img" src={project && project.detailsPage && project.detailsPage.project_researcher_image} />
                    <span>Managed by:</span>
                    {project && project.detailsPage && project.detailsPage.project_researcher_full_name}
                </Col>)
            }
            {project && project.detailsPage && project.detailsPage.project_site_progression && project && project.detailsPage && project.detailsPage.project_site_progression !== 100 && project && project.detailsPage && project.detailsPage.project_build_phase === "active_on_site" ?
                (<Col className="site-progression">
                    <DonutChart value={project && project.detailsPage && project.detailsPage.project_site_progression} />
                    <div className="ms-1"><span>Site progress:</span>{(project && project.detailsPage && project.detailsPage.project_site_progression) + '%'}</div>
                </Col>) : ('')
            }
            {project && project.detailsPage && project.detailsPage.project_last_call || (project && project.detailsPage && project.detailsPage.project_last_call !== "" && project && project.detailsPage && project.detailsPage.project_last_call !== null && project && project.detailsPage && project.detailsPage.project_last_call !== undefined) ?
                <Col className="call-info-Added"><div class="d-flex me-2"><span className="call-info-added-icon"><Icon color="#ffffff" size={20} icon="icon-gen-call-info" /></span><p><span>Call info</span>Added</p></div> </Col> : ("")}
        </Row>
    )
}

export default DetailCard;