import React, { useEffect, useState,useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Container } from 'reactstrap'
import { AvField, AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { updateIsAddNewUser, updateIsUniqueUser } from './reducer';
import { createNewUser, findUniqueUser } from './apiclient';

const CreateUser = (props) => {
    const avFormRef = useRef();
    const useradminData = useSelector(state => state.useradmin)
    const [values,setValues]=useState({})
    const [uniqueUser,setUniqueUser]=useState(true)
    const [emailvalue,setEmailValue]=useState(null)
    const dispatch = useDispatch();

    const handleCancel = (event) => {
        event.preventDefault();
        dispatch(updateIsUniqueUser(true))
        setUniqueUser(true)
        dispatch(updateIsAddNewUser(false))
        setValues({})
        if (avFormRef.current) {
            avFormRef.current.reset(); 
          }
   
    };

   const handleSubmit=(event, errors, values)=> {
    setValues(values)
    if (errors.length === 0) {
        createNewUser(values,props?.searchTerm ,dispatch)
        handleCancel(event)
    }
    }


   const handleEmailChange=(event)=> {
    setEmailValue(event.target.value)
     }
 
    const handleUserNameChange=(event) =>{
        findUniqueUser(event.target.value,dispatch)
       
     }
     useEffect(() => {
		setUniqueUser(useradminData?.isUniqueUser)
	}, [useradminData?.isUniqueUser]);




    return (
        <div className="add-new-user">
            <Container className="pl-0 mr-0">
                <AvForm  ref={avFormRef}  onSubmit={handleSubmit}>
                    <Row>
                        <AvGroup className="col-lg-3 form-group">
                            <AvInput autoComplete="off" type="text" name="first_name" className="mr-1 mb-1" maxLength="30" placeholder="First name *" required />
                            <AvFeedback>Please enter first name</AvFeedback>
                        </AvGroup>
                        <AvGroup className="col-lg-3 form-group">
                            <AvInput autoComplete="off" type="text" name="last_name" className="mr-1 mb-1" maxLength="30" placeholder="Last name *" required />
                            <AvFeedback>Please enter last name</AvFeedback>
                        </AvGroup>
                    </Row>
                    <Row>
                        <AvGroup className="col-lg-6 form-group">
                            <AvInput
                                autoComplete="off"
                                onKeyUp={handleUserNameChange} 
                                onBlur={handleUserNameChange} 
                                type="text"
                                name="username"
                                className="mr-1 mb-1"
                                maxLength="30"
                                placeholder="Username *"
                                required />
                            <AvFeedback>Please enter username</AvFeedback>

                            {!uniqueUser ?
                                <div className="form-control-feedback user-exists">Username already exists</div> : ''
                            }
                        </AvGroup>
                    </Row>
                    <Row>
                        <AvGroup className="col-lg-6 email-icon form-group">
                            <AvField
                                autoComplete="off"
                                type="text"
                                name="email"
                                maxLength="60"
                                className="email mr-1 mb-1"
                                placeholder="Email *"
                            value={emailvalue} onBlur={handleEmailChange} onChange={handleEmailChange}
                            onKeyDown={e => {
                            if (e.keyCode === 32) return handleEmailChange
                            }}
                            // required validate={{
                            // pattern: { value: '^[\\w-_\.\'+]*[\\w-_\'\.]\@([\\w-]+\\.)+[a-z`A-Z]//{2,}$', errorMessage: 'Please enter valid email' },
                            // required: { value: '', errorMessage: 'Please enter email' },
                            // }}
                            />
                        </AvGroup>
                    </Row>
                    <Row>
                        <AvGroup className="col-lg-3 mobile-icon form-group">
                            <AvField autoComplete="off" type="text" name="mobile" className="mobile mr-1 mb-1" placeholder="Mobile number" />
                        </AvGroup>
                        <AvGroup className="col-lg-3 company-icon form-group">
                            <AvField autoComplete="off" type="text" name="phone" className="company-phone mr-1 mb-1" placeholder="Enter company phone"
                                minLength="8" maxLength="12" />
                        </AvGroup>
                    </Row>
                    <Row className="form-button-row">
                        <button className="primary-btn btn-add-user" >Add</button>
                        <button className="outline-btn btn-cancel-user" onClick={handleCancel}>Cancel</button>
                    </Row>
                </AvForm>
            </Container>
        </div>
    )
}

export default CreateUser;