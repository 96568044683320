import React from 'react'
import { Route, Routes, BrowserRouter, Navigate, useLocation, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getCookies } from '.'
import Login from '../components/login'
import ForgotPassword from '../components/login/forgotpass'
import Authentication from '../pages/auth'
import Landing from '../pages/landing'
import DetailsPage from '../pages/details'
import Savedsearch from '../pages/savedsearch'
import Masquerade from '../pages/masquerade'
import IntegrationsPage from '../pages/integrations'
import UsersettingsPage from '../pages/usersettings'
import Myleads from '../pages/myleads'
import Calendar from '../pages/calendar'
import LeagueTable from '../pages/leaguetable'
import { signOut } from '../redux/features/user/userslice'
import NotFoundPage from '../pages/notfound'
import ExportData from '../pages/export'
import NewExportPage from '../pages/export/newExport'
import TeamsPage from '../pages/teams'
import UserAdminPage from '../pages/useradmin'
import ResetPassword from '../pages/resetpassword'
import GoogleAnalytics from '../components/common/js/GoogleAnalytics'
import JuskAsk from '../pages/justask'

export function getRoutes() {


  function RequireAuth() {
    let location = useLocation()
    let cookies = getCookies()
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
    const dispatch = useDispatch();
    if (!cookies['validSession']) {
      dispatch(signOut())
      return <Navigate to="/" state={{ from: location }} />;
    }

    if (!isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
  }

  return (
    <BrowserRouter>
      <GoogleAnalytics />
      <Routes>
        <Route exact path="/" element={<Authentication />} />
        <Route path='/' element={<Login />}>
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        </Route>
        <Route exact path="/masquerade" element={<Masquerade />} />
        <Route exact path="/changepassword" element={<ResetPassword />} />
        <Route path="/app" element={<RequireAuth />}>
          <Route exact path="/app/list" element={<Landing />} />
          <Route exact path="/app/savedsearch" element={<Savedsearch />} />
          <Route exact path="/app/project/:id" element={<DetailsPage />} />
          <Route exact path="/app/company/:reno/:id" element={<DetailsPage />} />
          <Route exact path="/app/person/:id" element={<DetailsPage />} />
          <Route exact path="/app/integrations" element={<IntegrationsPage />} />
          <Route exact path="/app/settings" element={<UsersettingsPage />} />
          <Route exact path="/app/myleads" element={<Myleads />} />
          <Route exact path="/app/export/list" element={<ExportData/>} />
          <Route exact path="/app/export/create" element={<NewExportPage />} />
          <Route exact path="/app/teams" element={<TeamsPage/>} />
          <Route exact path="/app/leaguetable" element={<LeagueTable />} />
          <Route exact path="/app/calendar" element={<Calendar />} />
          <Route exact path="/app/useradmin" element={<UserAdminPage />} />
          <Route exact path="/app/justask" element={<JuskAsk />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
