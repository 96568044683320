import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from 'reactstrap'

import { fetchPeopleDetails } from '../apiclient'
import ContactRow from '../../list/contactList/contactRow'
import ProjectRow from '../../list/projectList/projectRow'
import { renderListHeaderForAllObject, renderListHeader } from '../utils'
import ColoredScrollbars from '../../common/js/coloredScrollbars'


const ContactHierarchy = (props) => {
  const search = useSelector((state) => state.search)
  const searchPersist = useSelector((state) => state.searchPersist)
  const dispatch = useDispatch()

  useEffect(() => {
    if (searchPersist.appliedContext === "people") {
      fetchPeopleDetails(search.objectId, dispatch)
    }
  }, [search && search.objectId])

  return (
    <div className={`search-list-content hierarchy-row ${(search && search.searchTextInformation && search.searchTextInformation[searchPersist.appliedContext]?.length !== 0) ? 'with-project-info' : ''}`}>
      <div className={`result-list-container content-panel ${searchPersist.isListView ? 'nestedListView' : ''}`}>
        <div className="result-list-wrapper">
        <ColoredScrollbars>
          <div className="listviewtable-block bggray lr-spaceHierachy">
            <div className="list-table">
              <div className="list-header d-flex">
                {renderListHeaderForAllObject(props.type)}
              </div>
            </div>
          </div>
          {search && search.peopleDetails != undefined && search.peopleDetails.length > 0 ?
            search && search.peopleDetails && search.peopleDetails.map((item, index) => (
              <ContactRow contact={item} index={index} isHierarchy={true} prevNextList={props.prevNextList} />
            ))
            : ""}
          <div className="listviewtable-block bggray lr-spaceHierachy">
            <div className="list-table">
              <div className="list-header d-flex">
                  {renderListHeader(props.type, searchPersist, false, search)}
                </div>
            </div>
          </div>
          {
            search && search.peopleProjectsList && search.peopleProjectsList.length > 0 ?
              search && search.peopleProjectsList && search.peopleProjectsList.map((proj, index) => (
                <ProjectRow project={proj} index={index} isHierarchy={true} prevNextList={props.prevNextList} />
              )) : ''
          }
          </ColoredScrollbars>
        </div>
      </div>
    </div>
  )
}

export default ContactHierarchy