import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createApiKey, deleteApiKey, fetchHasApiKey } from '../apiclient';
import { Col, Row, FormGroup, Label, Input, Button, InputGroup } from 'reactstrap';
import { messageAlert } from '../../../utils';

function Developer({ active }) {

    const {
        settings_container,
        userData
    } = useSelector((state) => {
        return {
            settings_container: state.settings,
            userData: state.user.user
        }
    });

    const dispatch = useDispatch();
    const [apiKey, setApiKey] = useState(null)
    const [hasApiKey, setHasApiKey] = useState(settings_container?.hasApiKey ? settings_container?.hasApiKey : false)

    useEffect(() => {
        setHasApiKey(settings_container?.hasApiKey ? settings_container?.hasApiKey : false)
        setApiKey(settings_container?.apiKey !== null ? settings_container?.apiKey : null)
    }, [settings_container?.hasApiKey, settings_container?.apiKey])

    useEffect(() => {
        fetchHasApiKey(userData?.client_id, dispatch)
    }, [])
    
    

    const handleCreateApiKey = () => {
        createApiKey(userData?.client_id, dispatch);
    };

    const handleDeleteApiKey = () => {
        deleteApiKey(userData?.client_id, dispatch);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(apiKey);
            messageAlert(dispatch, "API key copied to clipboard!", 'success');
            window.location.reload();
        } catch (error) {
            messageAlert(dispatch, "Failed to copy API key to clipboard!'", 'danger')
        }
    };


    return (
        <>
            <div className="developer-wrapper">
                <div className="content-panel p-1">
                    <div className="col-md-12 col-sm-12">
                        {hasApiKey ? (
                            <div>
                                {apiKey !== null ? (
                                    <div>
                                        <FormGroup>
                                            <div className={`icon-addon`}>
                                                <Label className='mt-3'>Your company API key</Label>
                                                <p className="mb-0">The key will only be displayed once and cannot be recovered.</p>
                                                <Row>
                                                    <Col xs="3" className='mt-3'>
                                                        <Input
                                                            autoFocus={true}
                                                            value={apiKey}
                                                            readOnly={true}
                                                        />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <button className={"primary-btn btn-save-cancel my-3"} onClick={async () => await copyToClipboard()}>Copy</button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </FormGroup>

                                    </div>
                                ) : (
                                    <>
                                        <div className='mb-3'>
                                            <FormGroup>
                                                <div className={`icon-addon`}>
                                                    <Label className='mt-3'>Existing key</Label>
                                                    <p className="mb-0">This key is still active, but can never be revealed.</p>
                                                    <Row>
                                                        <Col xs="3" className='mt-3' >
                                                            <Input
                                                                type={'password'}
                                                                autoFocus={true}
                                                                value={"****************"}
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <button className={"primary-btn btn-save-cancel my-3"} onClick={handleDeleteApiKey}>Delete</button>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div className='mt-3'>
                                            <Label className="font-bold">Create key</Label>
                                            <p className="mb-0">Click below to create an API key. It will only be displayed once.</p>
                                            <button className={"primary-btn btn-save-cancel my-3"} onClick={handleCreateApiKey}>Create</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                <label className="m-0 font-bold">Create key</label>
                                <p className="mb-0">Click below to create an API key. It will only be displayed once.</p>
                                <button className={"primary-btn btn-save-cancel my-3"} onClick={handleCreateApiKey}>Create</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Developer;