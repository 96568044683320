import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import { fetchExportTemplateFields, fetchTemplates } from '../apiclient';

const NewExport = () => {
    const searchPersist = useSelector((state) => state.searchPersist)
    const [currentPageNo, setCurrentPageNo] = useState(searchPersist?.currentPage);
    const dispatch = useDispatch();
    const exports = useSelector((state) => state.export)

    const currentPage = (data) => {
        setCurrentPageNo(data)
    }

    useEffect(() => {
        function fetchData() {
            if ((exports?.exportStaticData.projects === undefined || exports?.exportStaticData.projects?.length === 0) && currentPageNo === 1) {
                // await fetchTemplates()
                if (exports?.exportCriteria.setting.selectedTemplate) {
                    let selectedItem = exports?.exportStaticData.templates.find(
                        (x) => x.template_name === exports?.exportCriteria.setting.selectedTemplate
                    );
                    if (selectedItem && selectedItem.template_name) {
                        fetchExportTemplateFields(dispatch, selectedItem.template_id);
                    }
                }
            }
        }
        fetchData()
    }, [currentPageNo]);

    return (
        <>
            <div className="header d-flex position-relative">
                <Row className="page-title-bar pt-3 ps-4">
                    <Col col={12} sm={12}>
                        <h2 className="h5">New export</h2>
                    </Col>
                </Row>
                <div className='multi-step pt-4'>
                    <ol className="progtrckr">
                        <li className={`no-hl ${currentPageNo === 0 ? 'progtrckr-doing' : 'progtrckr-done'}`} value="0">
                            <em>1</em>
                            <span>Select a saved search or tag</span>
                        </li>
                        <li className={`no-hl ${currentPageNo === 0 ? 'progtrckr-todo' : currentPageNo === 1 ? 'progtrckr-doing' : 'progtrckr-done'}`} value="1">
                            <em>2</em>
                            <span>Select fields and roles</span>
                        </li>
                        <li className={`no-hl ${(currentPageNo === 0 || currentPageNo === 1) ? 'progtrckr-todo' : currentPageNo === 2 ? 'progtrckr-doing' : 'progtrckr-done'}`} value="2">
                            <em>3</em>
                            <span>Export</span>
                        </li>
                    </ol>
                </div>
            </div>
            <div className='content-wrapper p-3'>
                <Row>
                    <Col lg='3' md='3'>
                        <LeftPanel />
                    </Col>
                    <Col lg='9' md='9'>
                        <RightPanel
                            currentPage={currentPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default NewExport