import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import Icon from '../../../common/js/icon'
import _ from 'lodash';

import { fetchLabelDataForAllDetails } from '../../../details/apiclient';
import ArchiveModal from '../../../details/list/archiveModal'
import TagsModal from '../../../details/list/tagsModal'
import { fetchSearchListCount, selectorControllerArchive, updateSearchSelectorControlTags, updateSelectorControlAction } from '../apiclient'

import { fetchSearchListForAllObjectType, resetIncludeExcludeIds, resetRelationalEntititySelectionCount, rolesForProject, updateBackButtonCluster, updateBackClusterList, updateBackObjectId, updateClusterClickCount, updateClusterList, updateCompanyDetail, updateDropDownSelectedObjectType, updateGroupCompanyProjectsFav, updateHierarchyBack, updateIdsForShowRelatedEntities, updateIfMoreCompanyProjectsAvailable, updatelistOfSelectedObjectIds, updateNavigationBackJA, updateObjectDataJA, updateObjectId, updateObjectType, updatePeopleProjectsList, updateProjectsForCompanyId, updateSearchListSelection, updateSelectedSelectorControlValue, updateShowDetailPeople, updateShowHierarchyForJustAsk, updateIsShowProjectsOrCompanySelected, updateCompanyDetailJA, updateRemoveFilterToggleJA, updateClusterListJA, updateShowSelectedProjectsJA, updateShowSelectedCompaniesJA } from '../redux/reducer'
import { updateGroupCompaniesDetails, updateIsProjectSelected, updateListSelection, updateSelectedTagDetails } from '../../../details/redux/reducer'
import { useLocation, useNavigate } from 'react-router-dom'
import { preProcessorQuery } from '../filters/preProcessorQuery'
import { TOURCOMPANYLISTVIEW, TOURPROJECTLISTVIEW } from '../../../../utils/tour'
import { updateHandleViewForShowCompanyShowProjects, updateOffsetLimit, updateSelectedSearchType, updateSort } from '../filters/redux/reducer'
import { createNewExport } from '../../../export/apiclient'
import { createDownloadPdfPayload } from '../../../export/utils'
import { updateFilterQuery } from '../redux/searchPeristReducer'
import { MAX_PDF_DOWNLOAD_LIMIT } from '../../../../utils/settings';
import { checkIfTrialCustomerUserExportedDataForToday, formatNumber } from '../../../../utils';
import { checkAvailabilityOfLimit, checkAvailabilityOfOption, getUniqueSelectedListForGroupCompanyProjects, selectorControlValue, fetchSortValue } from '../../../../utils/commonFunctions';



const SearchSelectorDropdown = () => {
    const search = useSelector((state) => state.justAsk)
    const filter = useSelector((state) => state.justAskFilter)
    const project = useSelector((state) => state.project)
    const searchPersist = useSelector((state) => state.justAskPersist)
    const settings = useSelector((state) => state.settings)
    const user = useSelector((state) => state.user.user);
    const exports = useSelector((state) => state.export)
    const dispatch = useDispatch()
    const location = useLocation()
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        actionsDropDownOpen: false,
        isShowRelatedEntitiesMode: false,
        isModalOpen: false,
        modal: false,
        archive: false
    })

    const [listCount, setListCount] = useState(project.isProjectSelected ? search.groupCompanyProjectCount : search?.searchListCount)
    const [searchControlLimit, setSearchControlLimit] = useState(selectorControlValue(project?.isProjectSelected ? project?.groupCompanyProjectCount : search?.searchListCount))
    const [objectType, setObjectType] = useState('')
    const [isPdfActive] = useState(user.licenceFlag?.isExportLicence)
    const [isExportEnabled] = useState(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user))
    let statusList = (searchPersist.isListView ? 'active' : '')
    const navigate = useNavigate();

    useEffect(() => {
        setSearchControlLimit(selectorControlValue(project.isProjectSelected ? search.groupCompanyProjectCount : search?.searchListCount))
        if (project.isProjectSelected) {
            setBooleanData(prev => ({
                ...prev,
                isShowRelatedEntitiesMode: true
            }))
        }
        booleanData.isShowRelatedEntitiesMode ? setListCount(project.isProjectSelected ? search.groupCompanyProjectCount : search?.listOfSelectedObjectIdsCount) : setListCount(search?.searchListCount)
    }, [search.searchListCount, search.listOfSelectedObjectIdsCount, search.countOfSelectedObjectIds, search.groupCompanyProjectCount])

    useEffect(() => {
        setBooleanData(prev => ({
            ...prev,
            isShowRelatedEntitiesMode: false
        }))
    }, [searchPersist.appliedContext])

    useEffect(() => {
        if (filter.newSearchClick === true) {
            setBooleanData(prev => ({
                ...prev,
                isShowRelatedEntitiesMode: false
            }))
            dispatch(resetRelationalEntititySelectionCount())
            dispatch(updateSelectedSelectorControlValue(0))
            dispatch(updateSearchListSelection(0))
        }
    }, [filter.newSearchClick])

    useEffect(() => {
        if (filter.handleView === true) {
            setBooleanData(prev => ({
                ...prev,
                isShowRelatedEntitiesMode: false
            }))
            dispatch(updateIdsForShowRelatedEntities([]));
            dispatch(resetRelationalEntititySelectionCount())
            dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
            dispatch(
                fetchSearchListForAllObjectType({
                    leadsFlags: [],
                    count: 0,
                    filterType: searchPersist.appliedContext,
                })
            );
            dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }))
            dispatch(updateObjectId(undefined))
            dispatch(updateBackObjectId(undefined))
            dispatch(updateSelectedSelectorControlValue(0));
            dispatch(updateSearchListSelection(0))
            dispatch(resetIncludeExcludeIds([]))
            dispatch(updateHandleViewForShowCompanyShowProjects(false))
        }
    }, [filter.handleView])

    const handleDropDownItemClick = (value) => {
        if (statusList && value === '') {
            value = search.searchListCount
        }
        if (booleanData.isShowRelatedEntitiesMode) {
            value = { count: value, isSelectionRelatedEntities: true }
        } else {
            dispatch(resetRelationalEntititySelectionCount())
        }
        dispatch(updateSelectedSelectorControlValue(value))
        dispatch(updateSearchListSelection(value))
    }

    const handleItemSelect = (count) => {
        handleDropDownItemClick(count)
        setBooleanData(prev => ({ ...prev, dropdownOpen: false }))
    }

    const handleSelectorControlActionsClick = (action) => {
        let payload = {}
        let selectionEntityRelatedMode = false
        if (!project.isProjectSelected) {
            let type = !booleanData.isShowRelatedEntitiesMode ? searchPersist.appliedContext : search.showSelectedCompanies ? "company" : "project"
            selectionEntityRelatedMode = booleanData.isShowRelatedEntitiesMode
            payload = preProcessorQuery('', filter.filtersState, type, search, searchPersist)
            payload.query = JSON.parse(searchPersist?.query)
            payload["selected_records"] = fetchSelectedRecords()
            payload["favourite"] = action === 'Favourite' ? true : false
            payload["object_type"] = type
            if (payload['selected_records'] > 0) {
                if (search && search.includeIds && search.includeIds.length > 0) {
                    payload['object_id'] = search.includeIds
                }
                if (search && search.excludeIds && search.excludeIds.length > 0) {
                    payload['exclude_ids'] = search.excludeIds
                }
                payload['limit'] = payload['records']
                delete payload['records']
                delete payload['event_type']
            }
        } else {
            payload["favourite"] = action === 'Favourite' ? true : false
            payload["object_type"] = 'project'
            payload['project_id'] = getUniqueSelectedListForGroupCompanyProjects(search?.groupCompanyProjects)
            selectionEntityRelatedMode = false
        }
        if (booleanData.isShowRelatedEntitiesMode) {
            payload['selected_records'] = parseInt(search?.countOfSelectedObjectIds)
        }
        updateSelectorControlAction(payload, search, searchPersist, dispatch, selectionEntityRelatedMode)
        setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))
    }

    const handleSelectorControlTagsClick = (action) => {
        fetchLabelDataForAllDetails(dispatch)
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleArchive = () => {
        let payload = {}
        let selectionEntityRelatedMode = false
        if (!project.isProjectSelected) {
            payload = _.cloneDeep(searchPersist?.filterQuery)
            payload["selected_records"] = fetchSelectedRecords()
            selectionEntityRelatedMode = booleanData.isShowRelatedEntitiesMode
            if (payload['selected_records'] > 0) {
                if (search && search.includeIds && search.includeIds.length > 0) {
                    payload['project_id'] = search.includeIds
                    payload['limit'] = payload['records']
                    delete payload['query']
                    delete payload['offset']
                    delete payload['limit']
                    delete payload['selected_records']
                    delete payload['sort']
                }
                if (search && search.excludeIds && search.excludeIds.length > 0) {
                    payload['selected_records'] = parseInt(payload['selected_records']) + search.excludeIds.length
                    payload['exclude_ids'] = search.excludeIds
                    payload['limit'] = payload['records']
                }
                delete payload['records']
                delete payload['event_type']
            }
        } else {
            payload['project_id'] = getUniqueSelectedListForGroupCompanyProjects(search?.groupCompanyProjects)
        }
        if (booleanData.isShowRelatedEntitiesMode) {
            payload['selected_records'] = parseInt(search?.countOfSelectedObjectIds)
        }
        payload["archive"] = true
        payload["object_type"] = 'project'
        selectorControllerArchive(payload, search, searchPersist, dispatch, settings, project, filter, selectionEntityRelatedMode)
        handleCancelArchive()
    }

    const handleCancelArchive = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: false
        }))
    }

    const handleUnArchive = () => {
        let payload = {}
        let selectionEntityRelatedMode = false
        if (!project.isProjectSelected) {
            payload = _.cloneDeep(searchPersist?.filterQuery)
            payload["selected_records"] = fetchSelectedRecords()
            selectionEntityRelatedMode = booleanData.isShowRelatedEntitiesMode
            if (payload['selected_records'] > 0) {
                if (search && search.includeIds && search.includeIds.length > 0) {
                    payload['project_id'] = search.includeIds
                    payload['limit'] = payload['records']
                    delete payload['query']
                    delete payload['offset']
                    delete payload['limit']
                    delete payload['selected_records']
                    delete payload['sort']
                }
                if (search && search.excludeIds && search.excludeIds.length > 0) {
                    payload['selected_records'] = parseInt(payload['selected_records']) + search.excludeIds.length
                    payload['exclude_ids'] = search.excludeIds
                    payload['limit'] = payload['records']
                }
                delete payload['records']
                delete payload['event_type']
            }
        } else {
            payload['project_id'] = getUniqueSelectedListForGroupCompanyProjects(search?.groupCompanyProjects)
        }
        if (booleanData.isShowRelatedEntitiesMode) {
            payload['selected_records'] = parseInt(search?.countOfSelectedObjectIds)
        }
        payload["archive"] = false
        payload["object_type"] = 'project'
        payload["identifier"] = 'archive'
        delete payload['display']
        selectorControllerArchive(payload, search, searchPersist, dispatch, settings, project, filter, selectionEntityRelatedMode)
        handleCancelArchive()
    }

    const handleArchiveClick = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: !booleanData.isModalOpen,
            archive: true
        }))
    }

    const handleUnArchiveClick = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: !booleanData.isModalOpen,
            archive: false
        }))
    }


    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleTagsSubmit = () => {
        let payload = {}
        let selectionEntityRelatedMode = false
        if (!project.isProjectSelected) {
            let type = !booleanData.isShowRelatedEntitiesMode ? searchPersist.appliedContext : search.showSelectedCompanies ? "company" : "project"
            selectionEntityRelatedMode = booleanData.isShowRelatedEntitiesMode
            payload = _.cloneDeep(searchPersist?.filterQuery)
            payload["selected_records"] = parseInt(search.selectedCount)
            payload["object_type"] = type
            if (search && search.includeIds && search.includeIds.length > 0) {
                payload['object_id'] = search.includeIds
                payload['limit'] = payload['records']
                payload["selected_records"] = 0
            }
            if (search && search.excludeIds && search.excludeIds.length > 0) {
                payload['exclude_ids'] = search.excludeIds
                payload['limit'] = payload['records']
                delete payload['records']
                delete payload['event_type']
            }
            if (payload["selected_records"] === 0) {
                delete payload["limit"]
                delete payload["offset"]
                delete payload['records']
                delete payload["query"]
                delete payload["display"]
                delete payload["sort"]
            }
        } else {
            payload["object_type"] = 'project'
            payload['project_id'] = getUniqueSelectedListForGroupCompanyProjects(search?.groupCompanyProjects)
            selectionEntityRelatedMode = false
        }
        if (project?.selectedTagsDetails?.tagIds?.length > 1 && project?.selectedTagsDetails?.tagIds?.includes(0)) {
            let tag_name = []
            let tag_id = []
            _.map(project?.selectedTagsDetails?.tagIds, (tagId, index) => {
                if (tagId === 0) {
                    tag_name.push(project.selectedTagsDetails.tagDescriptions[index])
                } else {
                    tag_id.push(project.selectedTagsDetails.tagIds[index])
                }
            })
            if (tag_name.length) {
                payload['tag_name'] = tag_name
            }
            if (tag_id.length) {
                payload['tag_id'] = tag_id
            }
        } else if (project?.selectedTagsDetails?.tagIds?.includes(0)) {
            payload['tag_name'] = project.selectedTagsDetails.tagDescriptions
        } else {
            payload['tag_id'] = project.selectedTagsDetails.tagIds
        }
        // if (booleanData.isShowRelatedEntitiesMode) {
        //     payload['selected_records'] = parseInt(search?.countOfSelectedObjectIds)
        // }
        handleTagsCancel()
        updateSearchSelectorControlTags(payload, search, searchPersist, dispatch, selectionEntityRelatedMode)
    }

    const handleFilterExtension = (isFilterExtension, entityFlag) => {
        handleSelectorControlShowEntities({ isFilterExtension: isFilterExtension })
    }

    useEffect(() => {
        if (booleanData.isShowRelatedEntitiesMode) {
            let type = objectType !== '' ? objectType : search.objectType
            var formValues = preProcessorQuery('', filter.filtersState, type, search, searchPersist)
            dispatch(updateFilterQuery(formValues));
            dispatch(updateIdsForShowRelatedEntities(searchPersist?.appliedContext === 'project' ? formValues?.query?.project_id?.value : searchPersist?.appliedContext === 'company' ? formValues?.query?.company_id?.value : []))
            fetchSearchListCount(formValues, dispatch, search, settings, searchPersist.query)
        }
    }, [booleanData.isShowRelatedEntitiesMode])

    useEffect(() => {
        if (search.backObjectId && search.backObjectId !== undefined && search.backObjectId !== 0 && (search.showSelectedCompanies || search.showSelectedProjects)) {
            setBooleanData(prev => ({
                ...prev,
                isShowRelatedEntitiesMode: true
            }))
        }
    }, [search.backObjectId])

    const handleSelectorControlShowEntities = ({ offset = 0, sort, isFilterExtension }) => {
        let type = searchPersist.appliedContext === 'project' ? 'company' : 'project'
        dispatch(updateSelectedSearchType(type))
        let newSortOrder = fetchSortValue(searchPersist?.heirarchialSort[type]?.label?.charAt(0)?.toLowerCase() + searchPersist?.heirarchialSort[type]?.label?.slice(1), type);
        dispatch(updateSort(newSortOrder))
        setBooleanData(prev => ({
            ...prev,
            isShowRelatedEntitiesMode: true
        }))
        dispatch(updateDropDownSelectedObjectType(false))
        if (searchPersist.appliedContext === 'project') {
            dispatch(updateShowSelectedCompaniesJA(!booleanData.isShowRelatedEntitiesMode))
            dispatch(updateIsShowProjectsOrCompanySelected(true))
            setObjectType('company')
            dispatch(updateOffsetLimit({ offset: 0, records: 50, type: 'company' }))
        }
        else if (searchPersist.appliedContext === 'company' || searchPersist.appliedContext === 'people') {
            dispatch(updateShowSelectedProjectsJA(!booleanData.isShowRelatedEntitiesMode))
            dispatch(updateIsShowProjectsOrCompanySelected(true))
            setObjectType('project')
            dispatch(updateOffsetLimit({ offset: 0, records: 50, type: 'project' }))
        }
    }

    const goBack = (event) => {
        dispatch(updateSelectedSearchType(searchPersist.appliedContext))
        searchPersist.appliedContext === 'project' ? dispatch(updateShowSelectedCompaniesJA(false)) : dispatch(updateShowSelectedProjectsJA(false))
        setBooleanData(prev => ({
            ...prev,
            isShowRelatedEntitiesMode: false
        }))
        setListCount(search && search.searchListCount)
        if (search.showSelectedCompanies && !booleanData.isShowRelatedEntitiesMode || search.showSelectedProjects && !booleanData.isShowRelatedEntitiesMode) {
            dispatch(updateSelectedSelectorControlValue(search && search.selectedLeadsFlag && search.selectedLeadsFlag.length))
        }
        dispatch(updateShowHierarchyForJustAsk({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        dispatch(updateShowDetailPeople(false))
        dispatch(updateHierarchyBack(true))
        dispatch(updateProjectsForCompanyId([]))
        dispatch(updateIdsForShowRelatedEntities([]))
        dispatch(rolesForProject([]))
        dispatch(updatePeopleProjectsList([]))
        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }))
        dispatch(updateRemoveFilterToggleJA(true))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateObjectDataJA(undefined))
        dispatch(updateCompanyDetailJA(undefined))
        dispatch(updateObjectId(undefined))
        dispatch(updateBackObjectId(search.projectRowData?.project_id ? search.projectRowData.project_id :
            search.objectId ? search.objectId : undefined))
        dispatch(updateListSelection({ count: 0, entityType: 'groupedCompanies' }))
        dispatch(updateIfMoreCompanyProjectsAvailable(false))
        if (!search.showProjectHierarchy && project.isProjectSelected) {
            const routingState = {
                ...location.state?.previousState,
                activeTab: '7'
            }
            navigate(`/app/company/0/${project.objectId}`, { state: routingState })
            dispatch(updateIsProjectSelected({ isProjectSelected: false }))
            dispatch(updateGroupCompanyProjectsFav([]))
            dispatch(updateGroupCompaniesDetails([]))
        }
        dispatch(updateClusterClickCount(0))
        dispatch(updateClusterListJA({ leadsFlags: [] }))
        dispatch(updateBackButtonCluster({ back: false }))
        dispatch(updateBackClusterList([]))
        dispatch(updateNavigationBackJA(''))
    }

    const onClusterBack = () => {
        if (search.clusterClickCount >= 2) {
            dispatch(updateClusterClickCount(search.clusterClickCount - 1))
            dispatch(updateClusterListJA(search.backClusterList.slice(-2)[0]))
            let backClusterList = _.clone(search.backClusterList)
            backClusterList.pop()
            dispatch(updateBackClusterList(backClusterList))
        } else {
            dispatch(updateClusterClickCount(0))
            dispatch(updateClusterListJA({ leadsFlags: [] }))
            dispatch(updateBackButtonCluster({ back: false }))
            dispatch(updateBackClusterList([]))
            dispatch(updateNavigationBackJA(''))
        }
    }

    let buttonClass = "",
        buttonClass2 = "",
        searchListHeaderCSS = "";

    const pagedDataRows = [1, 2, 3, 4, 5] // array to loop 5 times
    let pagedOptions = [];
    pagedDataRows.map(row => {
        pagedOptions.push((''))
    })

    const handleSelectorControlBatchPrintClick = () => {
        let objectIds = getUniqueSelectedList()
        let type = search.showSelectedProjects || project.isProjectSelected ? 'Project' : search.showSelectedCompanies ? 'Company' : searchPersist.appliedContext
        let feedname = type.toLowerCase() === "project" ? "Projects PDF" : "Companies PDF"
        let payload = createDownloadPdfPayload(feedname, objectIds, type, user)
        handleDropDownItemClick(0)
        createNewExport(payload, dispatch, navigate)
    }

    function getUniqueSelectedList() {
        let objectIds = []
        let leadsFlags = search.showSelectedProjects || search.showSelectedCompanies ? search.listOfSelectedObjectIds
            : project.isProjectSelected ? search.groupCompanyProjects : search.searchList
        leadsFlags.map(obj => {
            if (obj.is_selected === true) {
                objectIds.push(obj.objectId)
            }
        })
        return objectIds
    }

    function fetchSelectedRecords() {
        let selectedRec;
        if (search.selectedSelectorControlValue && search.selectedSelectorControlValue.toString() !== "0"
            && ["50", "100", "250", "500", "1000", "2000"].includes(search.selectedSelectorControlValue)) {
            selectedRec = parseInt(search.selectedSelectorControlValue)
        } else if (search.selectedSelectorControlValue && search.selectedSelectorControlValue.toString() == "") {
            selectedRec = parseInt(search.listCount)
        } else {
            selectedRec = parseInt(search.selectedSelectorControlValue)
        }
        return selectedRec;

    }

    return (
        <>
            <div className="d-flex justify-content-start">
                <div className={`searchList-header ${searchListHeaderCSS}`}>
                    {
                        (searchPersist.appliedContext === 'project' && search.showSelectedCompanies) || (searchPersist.appliedContext === 'company' && search.showSelectedProjects) || (searchPersist.appliedContext === 'people' && search.showSelectedProjects) || (project.isProjectSelected) ?
                            <a onClick={goBack} color="primary" className={`${search.showSelectedProjects || search.showSelectedCompanies || project.isProjectSelected ? 'show' : 'hide'} map-back me-2`}>
                                back
                            </a> : ''
                    }
                </div>
                {
                    (searchPersist.appliedContext === 'project' && search.showProjectHierarchy) || (searchPersist.appliedContext === 'company' && search.showCompanyHierarchy) || (searchPersist.appliedContext === 'people' && search.showCompanyHierarchy) ?
                        (
                            <div className={`searchList-header ${searchListHeaderCSS}`}>
                                <a onClick={goBack} color="primary" className={`show map-back mb-2`}>
                                    back
                                </a>
                            </div>
                        ) : <>
                            {(!booleanData.isShowRelatedEntitiesMode && searchPersist.appliedContext === 'people' || !booleanData.isShowRelatedEntitiesMode && searchPersist.appliedContext === 'project' || !booleanData.isShowRelatedEntitiesMode && searchPersist.appliedContext === 'company' || booleanData.isShowRelatedEntitiesMode && searchPersist.appliedContext === 'company' || booleanData.isShowRelatedEntitiesMode && searchPersist.appliedContext === 'project') ?
                                search.clusterList?.leadsFlags?.length && !booleanData.isShowRelatedEntitiesMode ?
                                    <div className={`searchList-header ${searchListHeaderCSS}`}>
                                        <a onClick={onClusterBack} color="primary" className={`show map-back`}>
                                            back
                                        </a>
                                    </div> :
                                    <ButtonDropdown
                                        className={`select-drop-count me-2 ${buttonClass}`}
                                        isOpen={booleanData.dropdownOpen} toggle={() => setBooleanData(prev => ({ ...prev, dropdownOpen: !booleanData.dropdownOpen }))}>

                                        <DropdownToggle className={`${(search.selectedSelectorControlValue > 0) || (search.countOfSelectedObjectIds > 0) ? 'active' : ''}`} caret>
                                            {
                                                !booleanData.isShowRelatedEntitiesMode && !search.showSelectedProjects ?
                                                    parseInt(search.selectedSelectorControlValue) > 0 ? (
                                                        <p><Icon color="#292B2C" size={22} icon="icon-gen-tick-filled-skyblue" className="me-1" /> {search.selectedSelectorControlValue} selected</p>
                                                    ) : <><Icon color="#292B2C" size={22} icon="icon-tick-filled-grey" className="me-1" /></> :
                                                    parseInt(search.countOfSelectedObjectIds) > 0 ? (
                                                        <p><Icon color="#292B2C" size={22} icon="icon-gen-tick-filled-skyblue" className="me-1" /> {search.countOfSelectedObjectIds} selected</p>
                                                    ) : <><Icon color="#292B2C" size={22} icon="icon-tick-filled-grey" className="me-1" /></>
                                            }
                                        </DropdownToggle>
                                        {!booleanData.isShowRelatedEntitiesMode ?
                                            <DropdownMenu className="dropdown-menu-right select-drop-list">
                                                <DropdownItem className={`${search.selectedSelectorControlValue?.toString() == "0" ? 'active' : ''}`} onClick={() => handleItemSelect(0)}><Icon size={22} icon={`${search.selectedSelectorControlValue?.toString() == "0" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> None </DropdownItem>
                                                {listCount ? searchControlLimit && searchControlLimit.map((keyr, val) => (
                                                    Object.entries(keyr).map(([key, value]) => {
                                                        return checkAvailabilityOfLimit(listCount, key) ?
                                                            (<DropdownItem className={`${search.selectedSelectorControlValue === key ? 'active' : ''}`}
                                                                onClick={() => handleDropDownItemClick(key)}
                                                            ><Icon size={22} icon={`${search.selectedSelectorControlValue === key ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> {value} </DropdownItem>) : ('')
                                                    }))) : pagedOptions && pagedOptions.map(row => row)}
                                                {checkAvailabilityOfOption(listCount) ? (<DropdownItem className={`${search?.selectedSelectorControlValue?.toString() == "" ? 'active' : ''}`} onClick={() => handleDropDownItemClick(listCount)}><Icon size={22} icon={`${search?.selectedSelectorControlValue?.toString() == "" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />  All </DropdownItem>) : ('')}
                                            </DropdownMenu> :
                                            <DropdownMenu className="dropdown-menu-right select-drop-list">
                                                <DropdownItem className={`${search.countOfSelectedObjectIds?.toString() == "0" ? 'active' : ''}`} onClick={() => handleItemSelect(0)}><Icon size={22} icon={`${search.countOfSelectedObjectIds?.toString() == "0" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> None </DropdownItem>
                                                {listCount ? searchControlLimit && searchControlLimit.map((keyr, val) => (
                                                    Object.entries(keyr).map(([key, value]) => {
                                                        return checkAvailabilityOfLimit(listCount, key) ?
                                                            (<DropdownItem className={`${search.countOfSelectedObjectIds === key ? 'active' : ''}`}
                                                                onClick={() => handleDropDownItemClick(key)}
                                                            ><Icon size={22} icon={`${search.countOfSelectedObjectIds === key ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> {value} </DropdownItem>) : ('')
                                                    }))) : pagedOptions && pagedOptions.map(row => row)}
                                                {checkAvailabilityOfOption(listCount) && !project.isProjectSelected ?
                                                    (<DropdownItem className={`${search.countOfSelectedObjectIds?.toString() == "" ? 'active' : ''}`} onClick={() => handleDropDownItemClick(listCount)}><Icon size={22} icon={`${search.countOfSelectedObjectIds?.toString() == "" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />  All </DropdownItem>)
                                                    : ('')}
                                                {checkAvailabilityOfOption(listCount) && (project.isProjectSelected && search.groupCompanyProjects?.length === search.groupCompanyProjectCount) ?
                                                    (<DropdownItem className={`${search.countOfSelectedObjectIds?.toString() == "" ? 'active' : ''}`} onClick={() => handleDropDownItemClick(listCount)}><Icon size={22} icon={`${search.countOfSelectedObjectIds?.toString() == "" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />  All </DropdownItem>)
                                                    : ('')}
                                            </DropdownMenu>
                                        }
                                        <ArchiveModal
                                            isOpen={booleanData.isModalOpen}
                                            isArchive={booleanData.archive}
                                            multiple={search.selectedSelectorControlValue !== 1}
                                            handleArchive={handleArchive}
                                            handleCancelArchive={handleCancelArchive}
                                            handleUnArchive={handleUnArchive}
                                            isSelectorController={true}
                                        />
                                    </ButtonDropdown>
                                : ("")
                            }
                        </>
                }
                {
                    ((search.selectedSelectorControlValue !== undefined && search.selectedSelectorControlValue?.toString() !== "0" && !booleanData.isShowRelatedEntitiesMode)
                        || (search.countOfSelectedObjectIds?.toString() != "0" && booleanData.isShowRelatedEntitiesMode))
                        && ((searchPersist.appliedContext === 'project' && !search.showProjectHierarchy) || (searchPersist.appliedContext === 'company' && !search.showCompanyHierarchy))
                        && searchPersist.appliedContext !== 'people' ? (
                        <ButtonDropdown className={`select-actions ${buttonClass2}`} isOpen={booleanData.actionsDropDownOpen} toggle={() => setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))}>
                            <DropdownToggle caret className="active no-bg">
                                <Icon color="#292B2C" size={25} icon="icon-gen-threedot-ver" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right action-list">
                                <DropdownItem className='no-bg' onClick={() => handleSelectorControlActionsClick('Favourite')}>Favourite</DropdownItem>
                                <DropdownItem className='no-bg' onClick={() => handleSelectorControlActionsClick('Unfavourite')}>Unfavourite</DropdownItem>
                                <DropdownItem className='no-bg' onClick={(event) => handleSelectorControlTagsClick(event)}>Tags</DropdownItem>
                                {
                                    isPdfActive ? (
                                        parseInt(search.selectedSelectorControlValue) <= 50 ?
                                            (
                                                <DropdownItem className='no-bg' disabled={!isExportEnabled} onClick={() => handleSelectorControlBatchPrintClick()}>Download PDF</DropdownItem>
                                            ) : (
                                                <DropdownItem disabled={!isExportEnabled} className="disabled no-bg">Download PDF{(search.selectedSelectorControlValue <= MAX_PDF_DOWNLOAD_LIMIT) ? "" : " (max " + MAX_PDF_DOWNLOAD_LIMIT + ")"}</DropdownItem>
                                            )
                                    ) : ('')
                                }
                                {
                                    !booleanData.isShowRelatedEntitiesMode && !project?.isProjectSelected ?
                                        searchPersist && searchPersist.appliedContext == "company" || searchPersist && searchPersist.appliedContext == "project" ?
                                            (
                                                parseInt(search.selectedSelectorControlValue) > 50 ?
                                                    (<DropdownItem className='no-bg' disabled={`${search.selectedSelectorControlValue > 50 ? true : false}`}>Show {searchPersist && searchPersist.appliedContext == "project" ? "Companies" : "Projects"}</DropdownItem>) :
                                                    (<DropdownItem className='no-bg' onClick={() => handleFilterExtension(searchPersist && searchPersist.appliedContext === "company" ? true : false)} >Show {searchPersist && searchPersist.appliedContext == "project" ? "Companies" : "Projects"}</DropdownItem>)
                                            )
                                            : ('') :
                                        ('')
                                }
                                {
                                    ((searchPersist.appliedContext === 'project' && !search.showSelectedCompanies) || search.showSelectedProjects || project?.isProjectSelected) && settings && settings.preferences && settings.preferences.archiving_enabled ?
                                        <DropdownItem className='no-bg' onClick={handleArchiveClick}>Archive</DropdownItem>
                                        : ''
                                }
                                {
                                    (search.showSelectedProjects || project?.isProjectSelected) && settings && settings.preferences && settings.preferences.archiving_enabled ?
                                        <DropdownItem className='no-bg' onClick={handleUnArchiveClick}>Unarchive</DropdownItem>
                                        : ''
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    ) : ('')
                }
                {
                    ((search.selectedSelectorControlValue !== undefined && search.selectedSelectorControlValue?.toString() !== "0" && !booleanData.isShowRelatedEntitiesMode)
                        || (search.countOfSelectedObjectIds?.toString() != "0" && booleanData.isShowRelatedEntitiesMode))
                        && (searchPersist.appliedContext === 'people' && !search.showProjectHierarchy) ? (
                        <>
                            {!booleanData.isShowRelatedEntitiesMode ?
                                <ButtonDropdown className={`select-actions ${buttonClass2}`} isOpen={booleanData.actionsDropDownOpen} toggle={() => setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))}>
                                    <DropdownToggle caret className="active no-bg">
                                        <Icon color="#292B2C" size={25} icon="icon-gen-threedot-ver" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-right action-list">
                                        <DropdownItem className="no-bg" onClick={() => handleFilterExtension()}>Show Projects</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown> : ("")
                            }
                        </>
                    ) : ('')
                }
                {
                    search.appliedContext !== 'people' ?
                        (
                            <div className="button-blocks">
                                <TagsModal
                                    handleTagsCancel={handleTagsCancel}
                                    handleTagsSubmit={handleTagsSubmit}
                                    selectedTags={project && project.selectedTagsDetails}
                                    showModal={booleanData.modal}>
                                </TagsModal>
                            </div>) : ('')
                }
                <div className={`searchList-header ${searchListHeaderCSS}`}>
                    {
                        (searchPersist.appliedContext === 'project' && search.showSelectedCompanies) || (searchPersist.appliedContext === 'company' && search.showSelectedProjects) ||
                            (searchPersist.appliedContext === 'people' && search.showSelectedProjects) ? (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>Showing {search.showSelectedCompanies ? "companies" : "projects"} of multiple {search.showSelectedCompanies ? "projects" : (searchPersist.appliedContext === 'people' && search.showSelectedProjects) ? "contacts" : "companies"} selected</h1>
                                <p className={`result-count mb-0`}>
                                    {search && search.listOfSelectedObjectIdsCount}  {searchPersist.appliedContext === 'project' ? 'companies' : 'projects'}
                                </p>
                            </>
                        ) : (searchPersist.appliedContext === 'project' && search.showProjectHierarchy) || (searchPersist.appliedContext === 'company' && search.showCompanyHierarchy) || (searchPersist.appliedContext === 'people' && search.showCompanyHierarchy) ? (
                            <h1 className={`show h5 m-0 ps-3`}>{searchPersist.appliedContext === searchPersist.selectedSaveSearch?.object_type && searchPersist.selectedSaveSearch?.saved_search_name ? searchPersist.selectedSaveSearch?.saved_search_name : 'Search results'}</h1>
                        ) : project?.isProjectSelected ? (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>Projects of selected companies</h1>
                                <p className={`result-count mb-0`}>
                                    {search?.groupCompanyProjects?.length !== 0 ? search?.groupCompanyProjects?.length : 0} of All Projects
                                </p>
                            </>
                        ) : (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>{searchPersist.appliedContext === searchPersist.selectedSaveSearch?.object_type && searchPersist.selectedSaveSearch?.saved_search_name ? searchPersist.selectedSaveSearch?.saved_search_name : 'Search results'}</h1>
                                <p className={`result-count mb-0`}>
                                    {
                                        searchPersist.appliedContext !== 'people' &&
                                            (searchPersist.appliedContext === 'project'
                                                && filter.filtersState[searchPersist.appliedContext]?.location?.location !== ''
                                                && filter.filtersState[searchPersist.appliedContext]?.location?.radius) ?
                                            (<>{search.searchList?.length} of {formatNumber(search.searchListCount)} {' projects'}
                                                <span className="applied-miles">{filter.filtersState[searchPersist.appliedContext]?.location?.radius
                                                    ? ` within ${filter.filtersState[searchPersist.appliedContext]?.location?.radius} 
                                    ${filter.filtersState[searchPersist.appliedContext]?.location?.radius === 1 ? "mile" : "miles"}` : ""}</span></>) :
                                            searchPersist.appliedContext !== 'people' && (searchPersist.appliedContext === 'company'
                                                && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== ''
                                                && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.radius)
                                                ? (<>{search.searchList?.length} of {formatNumber(search.searchListCount)} {' companies'}
                                                    <span className="applied-miles">{filter.filtersState[searchPersist.appliedContext]?.companyLocation?.radius
                                                        ? ` within ${filter.filtersState[searchPersist.appliedContext]?.companyLocation?.radius} 
                                    ${filter.filtersState[searchPersist.appliedContext]?.companyLocation?.radius === 1 ? "mile" : "miles"}` : ""}</span></>) :
                                                ((filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active
                                                    || filter.filtersState[searchPersist.appliedContext]?.projectTown?.value.length > 0
                                                    || filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value.length > 0) && searchPersist.appliedContext === 'project' ||
                                                    (filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active
                                                        || filter.filtersState[searchPersist.appliedContext]?.companyTown?.value.length > 0 ||
                                                        filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value.length > 0) && searchPersist.appliedContext === 'company' ||
                                                    (filter.filtersState[searchPersist.appliedContext]?.personInformation?.active && searchPersist.appliedContext === 'people') ||
                                                    searchPersist.summaryTags?.length > 0) ?
                                                    (<>{search.searchList?.length} of {formatNumber(search.searchListCount)} {' '} {searchPersist.appliedContext === 'project' ? 'projects' : searchPersist.appliedContext === 'company' ? 'companies' : 'people'}</>)
                                                    : (<>{search.searchList?.length} of {formatNumber(search?.searchListCount)} {' '} {searchPersist.appliedContext === 'project' ? 'projects' : searchPersist.appliedContext === 'company' ? 'companies' : 'people'}</>)
                                    }
                                </p>
                                {
                                    searchPersist.appliedContext === 'project' && location.pathname === "/app/list" ?
                                        (<div className="hori-placeholder-tour" data-intro={!filter.isFilterActive ? TOURPROJECTLISTVIEW.selectorcontrol.intro : null} data-position={TOURPROJECTLISTVIEW.selectorcontrol.position} data-class={TOURPROJECTLISTVIEW.selectorcontrol.class}>&nbsp;</div>) :
                                        ('')
                                }
                                {
                                    searchPersist.appliedContext == 'company' && location.pathname === "/app/list" ?
                                        (<div className="hori-placeholder-tour" data-intro={!filter.isFilterActive ? TOURCOMPANYLISTVIEW.selectorcontrol.intro : null} data-position={TOURCOMPANYLISTVIEW.selectorcontrol.position} data-class={TOURCOMPANYLISTVIEW.selectorcontrol.class}>&nbsp;</div>) :
                                        ('')
                                }
                            </>

                        )
                    }
                </div>
            </div>
        </>
    )
}

export default SearchSelectorDropdown