import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import ColoredScrollbars from '../common/js/coloredScrollbars';
import { fetchAllRecipients } from '../details/apiclient';
import { fetchSaveSearchResults } from './apiclient';
import { savedsearchResults, updateIsLoaderActive, updateIsSharedSaveSearchActive, updateSavedSearchOffsetLimitValue } from './redux/reducer';
import './savedsearch.scss';
import MySavedSearches from './tabs/mySavedSearches';

const SavedsearchIndex = () => {

    const [activeTab, setActiveTab] = useState('1');
    const shareState = useSelector(state => state.savedsearch)
    const project = useSelector((state) => state.project)
    const userData = useSelector(state => state.user.user)
    const dispatch = useDispatch();

    const toggle = (tab) => {
        dispatch(savedsearchResults([]))
        dispatch(updateIsLoaderActive(true))
        dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));

        if (activeTab !== tab) {
            setActiveTab(tab)
        }
        if (tab === '1') {
            dispatch(updateIsSharedSaveSearchActive(true))
        } else {
            dispatch(updateIsSharedSaveSearchActive(false))

        }
    }

    useEffect(() => {
        if (project?.fetchShareRecipients === undefined) {
            fetchAllRecipients(userData, dispatch, project)
        }
        fetchSaveSearchResults(dispatch, shareState)
    }, [shareState?.isSharedSaveSearchActive])

    return (

        <div className="row">
            <div className="col-12 col-sm-12">
                <h1 className="h5">Saved searches</h1>
            </div>
            <div className="content-wrapper">
                <Row className="pt-3 ms-0 me-0 mb-3 savedSearch-tab-panel">
                    <Col className="p-0 savedSearch-tab-wrapper">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}>
                                    My saved searches
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggle('2')}>
                                    Saved searches shared with me
                                </NavLink>
                            </NavItem> 
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={activeTab} className="p-3"><MySavedSearches /></TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SavedsearchIndex;