import React from "react";
import AlertMessage from "../../components/alerts";
import TopHeader from "../../components/header";
import LeftPanel from "../../components/leftpanel";
import SavedsearchIndex from "../../components/savedsearch";

const Savedsearch = () => {
    return (
        <div id="container">
            <TopHeader />
            <div className="sidebar-mini">
                <LeftPanel />
                <div id="message-container">
                    <AlertMessage />
                </div>
                <div className="main-content pt-3 saved-search-page">
                    <SavedsearchIndex />
                </div>
            </div>
        </div>
    )
}

export default Savedsearch;