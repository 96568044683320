import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap';

const CheckboxlistItem = ({ item, index, onChange, parent }) => {
    return (
        <div>
            <FormGroup check key={`formgroup${index}`}>
                <div className="check-box-button">
                    <Input type="checkbox" checked={item.active} id={item.name} data-parent={parent ? parent : item.name} value={item.value} onChange={onChange} />
                    <Label check for={item.name}>{item.name}</Label>
                </div>
            </FormGroup>
        </div>
    )
}

export default CheckboxlistItem