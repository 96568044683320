import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import ColoredScrollbars from '../../../common/js/coloredScrollbars';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import CompanyRelationShips from './relationshipsTab/companyRelationships';
import { useLocation } from 'react-router-dom';
import { fetchCompanyRelatedRoles } from '../../apiclient';

const RelationshipTab = (sharedType, objectId, objectType) => {
    const [activeTab, setActiveTab] = useState('none');
    const [roles, setRoles] = useState()
    const [roleID, setRoleId] = useState()
    const details = useSelector((state) => state.project)
    //const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const location = useLocation().pathname;

    const toggle = (tab) => {
        if (activeTab !== tab.role_code) {
            setActiveTab(tab.role_code)
        }
        let roleId = [];
        if (parseInt(tab.role_code) === 0) {
            setRoles(details && details.companyProjectRoles)
            if (roles && roles.length > 0) {
                roles && roles.map(role => {
                    if (role.role_code !== 0) {
                        roleId.push(role.role_code)
                    }
                })
                setRoleId(roleId)
                fetchCompanyRelatedRoles(location.split('/')[4], tab.role_code, dispatch, details)
            }
            
        }
        else{
            fetchCompanyRelatedRoles(location.split('/')[4], tab.role_code, dispatch)
        }
        
    }

    useEffect(() => {
        let roleId = []
        if (activeTab === 0) {
            if (roles && roles.length > 0) {
                roles && roles.map(role => {
                    if (role.role_code !== 0) {
                        roleId.push(role.role_code)
                    }
                })
                setRoleId(roleId)
            }

        }

        setRoles(details && details.companyProjectRoles)
    }, [roles, details && details.companyProjectRoles])

    return (
        <>
            <div className="tab-pane active">
                <div className="box-view">
                    <Row className="m-0">
                        <Col sm="3" md="3" lg="3" className="p-0 nav-col height-400 overflow-hidden">
                            <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select a role for <strong>{details && details.detailsPage && details.detailsPage.company_name}</strong></p>
                            </div>
                            <ColoredScrollbars className="scroll-wrapper">
                                <Nav tabs vertical className="mb-5">
                                    {roles && roles.length > 0 &&
                                        roles.map(function (role, index) {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        className={classnames({ active: parseInt(activeTab) === role.role_code })}
                                                        onClick={() => { toggle(role); }}
                                                    >
                                                        {role.role_code_display} {role.role_code_display === 'Any Role' ? role.project_count ? <span>({role.project_count})</span> : <span>(0)</span> : role.count ? <span>({role.count})</span> : <span>(0)</span>}
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })
                                    }
                                </Nav>
                            </ColoredScrollbars>
                        </Col>
                        <Col sm="9" md="9" lg="9" className="p-0 detail-col">
                            <TabContent activeTab={activeTab} className="p-0">
                                {activeTab !== "none" ? 
                                <>
                                {roles && roles.length > 0 && roles.map((role, index) => (
                                    role.role_code === activeTab && (

                                        <TabPane tabId={role.role_code}>
                                            <CompanyRelationShips columnOneRole={role} />
                                        </TabPane>
                                    )
                                )) 
                                }
                                </> :
                                 <div className="p-0 select-roles-placeholder height-400">
                                    <span>Select a role for <b>{details.detailsPage.company_name}</b></span>
                                </div>
                            }
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default RelationshipTab;