import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { updateSearchTextObject, updateAdditionalQuery, updateExactSearchTextObject } from "../../redux/reducer"
import { createCommonQuery } from '../../../../utils/commonFunctions';
import { updateDisplayKey, updateFilterText } from '../redux/reducer';

const PersonTextSearch = (props) => {
    const dispatch = useDispatch()
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist);
    const settings = useSelector((state) => state.settings)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState('Fuzzy search');
    const [radioDisabled, setRadioDisabled] = useState(true);
    const [keyname, setKeyname] = useState('personInformationEditor');
    const [currentRadio, setCurrentRadio] = useState('personName');
    let activeFilterValue = searchPersist.appliedContext;
    const [searchTextObj, setSearchTextObj] = useState(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });
    const [textRetain, setTextRetain] = useState('');
    const [projectTextBlock, setProjectTextBlock] = useState(false);
    const filter = useSelector((state) => state.filter)
    const [searchData, setSearchData] = useState({
        isButtonClicked: false
    })

    const onEnterHandler = (event) => {
        if (event.keyCode == 13) {
            let payload1 = {
                value: textRetain,
                dataObj: event.target.value,
                active: textRetain !== '' ? true : false,
                keyname: "personInformation"
            }
            dispatch(updateFilterText(payload1))
            let payload = createPayload(activeFilterValue);
            dispatch(updateAdditionalQuery(payload))
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true
            }))
            props.variableHandler([(keyname === 'personInformationEditor' ? 'personInformation' : keyname), "personArray"], textRetain)
        }
    }

    const handleSelectedValue = (value) => {
        let updatedRadioDisabled = true;
        let updatedKeyname = 'personInformationEditor';
        if (value === 'Exact match') {
            updatedRadioDisabled = false;
            updatedKeyname = currentRadio;
        }
        setSelectedValue(value);
        setRadioDisabled(updatedRadioDisabled);
        setDropdownOpen(false);
        setProjectTextBlock(false);
        let payload = createPayload(activeFilterValue, value);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        props.variableHandler([(updatedKeyname === 'personInformationEditor' ? 'personInformation' : updatedKeyname), "personArray"], textRetain)
    };

    const dropDownToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleTextChange = (event, field) => {
        setTextRetain(event.target.value);
        setProjectTextBlock(true);
        let textObj = activeFilterValue === 'project' ? { project: event.target.value } : activeFilterValue === 'company' ? { company: event.target.value } : { people: event.target.value }
        setSearchTextObj(_.cloneDeep(textObj))
        dispatch(updateSearchTextObject(textObj))
    };

    const onBlurHandler = () => {
        if (textRetain) {
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true
            }))
            props.variableHandler([(keyname === 'personInformationEditor' ? 'personInformation' : keyname), "personArray"], textRetain)
        }
        else {
            setProjectTextBlock(false);
        }
    };

    const handleClearField = () => {
        searchTextObj[activeFilterValue] = []
        setSearchTextObj(searchTextObj)
        dispatch(updateSearchTextObject(searchTextObj))
        setTextRetain('');
        setProjectTextBlock(false);
        let payload = createPayload(activeFilterValue, selectedValue);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        dispatch(updateDisplayKey({ key: 'personInformation', text: '' }))
        dispatch(updateDisplayKey({ key: currentRadio, text: '' }))
    };

    const createPayload = (filterType, selectedDropdown) => {
        let query = {};
        let query1 = {};
        let result = {};
        if (selectedDropdown === "Fuzzy search") {
            if (searchTextObj && searchTextObj[filterType].length > 0) {
                query = createCommonQuery({
                    key: "person_text",
                    operator: "=",
                    value: searchTextObj[filterType][0].value
                })
            }
        }

        else {
            if (textRetain != "") {
                let key = currentRadio === "personName" ? "person_full_name" : "person_job_title"
                query = createCommonQuery({
                    key: key,
                    operator: "=",
                    value: textRetain
                })

            }
        }

        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    const handleRadioClick = (event, keyname) => {
        setCurrentRadio(keyname)
        let key = keyname === 'personName' ? 'personName' : 'personJobTitle'
        setKeyname(key)
        props.variableHandler([(key === 'personInformationEditor' ? 'personInformation' : key), "personArray"], textRetain)
        let payload = createPayload(activeFilterValue, selectedValue);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
    }

    useEffect(() => {
        let people = []
        if (filter.filtersState[searchPersist.appliedContext]?.personInformation?.value.length > 0) {
            people.push(filter.filtersState[searchPersist.appliedContext]?.personInformation?.value)
            setSearchTextObj(activeFilterValue === 'project' ? { project: people } : activeFilterValue === 'company' ? { company: people } : { people: people })
            setTextRetain(filter.filtersState[searchPersist.appliedContext]?.personInformation?.value)
        }
        if (filter.filtersState[activeFilterValue]?.personName?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.personName?.value
            setSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setTextRetain(filter.filtersState[searchPersist.appliedContext]?.personName?.value)
        }
        if (filter.filtersState[activeFilterValue]?.personJobTitle?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.personJobTitle?.value
            setSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setCurrentRadio('personJobTitle')
            setTextRetain(filter.filtersState[searchPersist.appliedContext]?.personJobTitle?.value)
        }
        if(filter.filtersState[activeFilterValue]?.personInformation?.value.length === 0 && filter.filtersState[activeFilterValue]?.personJobTitle?.value === '' && filter.filtersState[activeFilterValue]?.personName?.value === ''){
            setTextRetain("")
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
        }
    }, [filter.filtersState])

    useEffect(() => {
        if(props?.isClearAll){
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
            setTextRetain("")
        }  
    }, [props?.isClearAll])

    return (
        <div id="new_filter_personsearch">
            <div className='advanced-filter-grid'>
                <div className="filterHeading">
                    <h5 className="mb-3">Search for individual contacts</h5>
                </div>
                <div className={`filterBlock ${projectTextBlock === true ? "activeBlock" : ""}`}>
                    <div className="textcontent">
                        <Row className="align-items-center">
                            <Col xs={12} sm={8} md={8} lg={11}>Enter a word or phrase to search all fields, or select exact search and a specific field for more precision.</Col>
                        </Row>
                    </div>
                    <div className="sliderBlock">
                        <FormGroup id={currentRadio} name={currentRadio} className="m-0 px-0">
                            <Row>
                                <Col xs={12} sm={8} md={8} lg={6} xl={8} className="advanced-filter-field-container project-info-filter">
                                    <div className={`radio-btn-cntr`}>
                                        <input
                                            className="form-control"
                                            onChange={(event) => handleTextChange(event, 'field')}
                                            onBlur={onBlurHandler}
                                            onKeyDown={onEnterHandler}
                                            type="text"
                                            name={currentRadio}
                                            id={currentRadio}
                                            placeholder="For e.g. John Doe, Jane Smith"
                                            value={searchTextObj[activeFilterValue]}
                                            data-id={currentRadio === 'personInformationEditor' ? 'personInformation' : currentRadio}
                                        />
                                        <button
                                            className={`clear-button-advanced-filter clear-textfield ${textRetain && textRetain.length > 0 ? 'display-block' : 'display-none'}`}
                                            name="clearButton"
                                            data-id={currentRadio === 'personInformationEditor' ? 'personInformation' : currentRadio}
                                            onClick={handleClearField}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={8} md={8} lg={6} xl={4} className="advanced-filter-field-container pl-0">
                                    <div className="input-container">
                                        <div className="select-input-type">
                                            <ButtonDropdown isOpen={dropdownOpen} toggle={() => dropDownToggle()} className="w-100">
                                                <DropdownToggle caret className="w-100 text-left btn-project-search text-truncate">
                                                    {selectedValue}
                                                </DropdownToggle>
                                                <DropdownMenu className="w-100">
                                                    <DropdownItem onClick={() => handleSelectedValue('Fuzzy search')}>Fuzzy search</DropdownItem>
                                                    <DropdownItem onClick={() => handleSelectedValue('Exact match')}>Exact match</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </FormGroup>

                        <div className={`radio-btn-cntr ${radioDisabled ? "hide" : ""}`}>
                            <Row>
                                <div className="radio-buttons">
                                    <FormGroup className="form-check">
                                        <Input
                                            name="search-person-info"
                                            type="radio"
                                            id="personName"
                                            checked={currentRadio === 'personName' ? true : false}
                                            onChange={(e) => handleRadioClick(e, "personName")}
                                        />
                                        <Label for="personName" className="form-check-label">
                                            Person name
                                        </Label>
                                        <div className="check"></div>
                                    </FormGroup>
                                </div>
                                <div className="radio-buttons">
                                    <FormGroup className="form-check">
                                        <Input
                                            name="search-person-info"
                                            type="radio"
                                            id="personJobTitle"
                                            checked={currentRadio === 'personJobTitle' ? true : false}
                                            onChange={(e) => handleRadioClick(e, "personJobTitle")}
                                        />
                                        <Label for={"personJobTitle"} className="form-check-label">
                                            Job title
                                        </Label>
                                        <div className="check"></div>
                                    </FormGroup>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default PersonTextSearch;