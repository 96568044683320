import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Button, ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup } from 'reactstrap';
import EditUser from './edituser';
import { updateIsEditUser, modalOpenClose } from './reducer';
import { resendWelcomeMail } from './apiclient';
import { getSectorNo } from '../../utils';

const UserList = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const useradminData = useSelector(state => state.useradmin);
    const [isEditUsersetFlag, setIsEditUserFlag] = useState(true);
    const [isEditUser, setIsEditUser] = useState(props.isEditUser);
    const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    const toggle = () => {
        setDropdownOpen(prevOpen => !prevOpen);
    };

    const handleEdit = () => {
        setIsEditUserFlag(true);
        dispatch(updateIsEditUser(true));
        props.handleEditToggle(true, props.result.user_no)
        props.isEditFlag(true)
        setIsEditUser(true)
     
    };

    const handleIsEditFlag=(flag)=> {
		props.handleEditToggle(flag, props.result.user_no)
        setIsEditUser(flag);
	}

    const isEditUserFlag=(flag)=> {
        setIsEditUserFlag(flag)
		props.isEditFlag(flag)
	}

   

    const handleModal = (event) => {
        let flag = false
		for (let licenses of (props?.result?.license)) {
			if (licenses.license_display_name === "Admin") {
				flag = true
				break
			}
		}
        dispatch(modalOpenClose(true))
        props.handleDeleteModal(props.result.user_no,flag)
    }

   const handleResendClick=(licenses)=> {
			  let payload = {
				user_id: props.result.user_no,
				send_welcome_email: true
			}
			resendWelcomeMail(payload,dispatch)
		
	}

   const getStatus=(code)=> {
		let status = null
		switch (code) {
			case 0:
				status = { name: "Scheduled for delete", class: "scheduled" }
				break;
			case 1:
				status = { name: "In Progress", class: "in-progress" }
				break;
			case 2:
				status = { name: "User Deleted", class: "deleted" }
				break;
			default:
				status = { name: "Error", class: "error" }
				break;
		}
		return status
	}
    const handleRetry=() =>{
		props.handleDeleteRetryUser(props.result.user_no)
	}

   const handleReportThis=()=> {
		let sectorDetails = getSectorNo(window.location)
		let message = "Sector: " + sectorDetails.sectorName + "\nProduct: " + sectorDetails.productName +
			"\nUser being deleted: " + props.result.first_name + " " + props.result.last_name + " " + "(" + props.result.username + ")" + "\nCompany: " + props?.user?.customer?.company_name + " " +
			"\nType: Error ";
    
		// this.props.sendDeleteUserIssue({ 'userNo': user.user_no, 'message': message })
	}


    return (
        <>
        {isEditUser ? (
            <EditUser
                isEditUserFlag={isEditUserFlag}
                {...props}
                handleIsEditFlag={handleIsEditFlag}
            />
        ):

            <Row className={`m-0 ${props?.result?.delete_status?.status === 3 ? 'active' : ''}`}>
                <Col className="user-names" xs="12" sm="12" md="3">
                    <span className="user-icon">{props.result.initials}</span>
                    <div className="userActivityInfo">
                        <span className="fl-name">{props.result.first_name} {props.result.last_name}
                        </span>
                        {props.result.username ? (<span className="user-name">{props.result.username}</span>) : ('')}
                        <span className={`user-lastseen ${props.result.delete_status ? getStatus(props.result.delete_status.status).class : ''}`}>
                            {props.result.delete_status ? getStatus(props.result.delete_status.status).name : ''}
                            {props.result.delete_status && getStatus(props.result.delete_status.status == 1) ? (<div className="d-flex justify-content-center p-2 pb-3"><div className="warning-msg">Deletion in progress, please wait</div></div>) : ('')}
                            {props.result.delete_status && getStatus(props.result.delete_status.status).class == 'error' ? (
                                <span className="info-pop" data-tip data-for={props.result.initials}>
                                    <ReactTooltip id={props.result.initials} place="right" type='error' className="error-msg">
                                        <p>Oops, there was an error. Please try again or report </p>
                                    </ReactTooltip>
                                </span>
                            ) : ('')
                            }
                        </span>

                    </div>
                </Col>
                <Col className="user-email" xs="12" sm="12" md="4">
                    {props.result.email ? (<a href={"mailto:" + props.result.email} className="email-address"> {props.result.email} </a>) : ('')}
                </Col>
                <Col className="user-contacts" xs="12" sm="12" md="2">
                    {(props.result.mobile && props.result.mobile != "-") ? (<span className="user-mobile">{props.result.mobile}</span>) : ('')}
                    {(props.result.phone && props.result.phone != "-") ? (<span className="user-landline">{props.result.phone}</span>) : ('')}
                </Col>
                <Col className="user-licenses" xs="12" sm="12" md="3">
                    {props?.result?.license?.length > 0 ? (
                        <span className="user-license">
                            {props?.result?.license.map(function (elem, index) {
                                return (
                                    <span key={index}>{elem.license_display_name}</span>
                                );
                            })}
                        </span>
                    ) : (
                        ""
                    )}

                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>Actions</DropdownToggle>
                        {props?.result?.delete_status?.status === 3 ?
                            <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem className={`${props.result.user_no == user.user_id && user.licenceFlag.isAdminLicence ? 'disabled' : 'show'}`} onClick={handleRetry}>Retry delete</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem className={`${props.result.user_no == user.user_id && user.licenceFlag.isAdminLicence ? 'disabled' : 'show'}`} onClick={handleReportThis}>Report error</DropdownItem>
                          
                            </DropdownMenu> :
                            <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem onClick={handleEdit}>Edit</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem className={`${props.result.delete_status ? 'disabled' : 'show'}`} onClick={handleModal}>Delete User</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem className={`${props?.result?.license?.length == 0 ? 'disabled' : 'show'}`} onClick={() => handleResendClick(props?.result?.license)}>Resend welcome email</DropdownItem>
                            </DropdownMenu>}
                    </ButtonDropdown>
                </Col>
            </Row>
}
        </>
    )
}

export default UserList;