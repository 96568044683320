import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';

const Include = (props) => {

    const [checked, setChecked] = useState(props.checked);
    const [expanded, setExpanded] = useState([]);
    const [expand, setExpand] = useState([]);

    const {
        search_container,
    } = useSelector((state) => {
            return {
                search_container: state.search
            }
        });

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.expandValues.length > 0) {
            setExpand(props.expandValues)
        } else {
            setExpand([])
        }
    }, [props.expandValues])

    useEffect(() => {
       setChecked(props.checked)
    }, [props.checked])

    

    const onExpand = (newExpanded) => {
        if (props.expandValues.length > 0) {
            setExpand([]);
        }
        setExpanded(newExpanded);
        props.expandValue(newExpanded)
    };

    return (
        <CheckboxTree
            checked={checked}
            expanded={expand.length > 0 ? expand : expanded}
            nodes={props?.filteredNodes}
            onCheck={props.onCheck}
            onExpand={onExpand}
            // onClick={props.handleFilterTypeClick}
        />
    )
}

export default Include;