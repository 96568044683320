import React from 'react'
import { useSelector } from 'react-redux'

const CommonPlaceholder = () => {

  const searchPersist = useSelector((state) => state.searchPersist)

  return (
    <div className="tab-pane placeholder list-wrapper-other-pro">
      <div>
        <div className="result-list-container content-panel row placeholder">
          <div className="selector-placeholder">
            <span className="select-control"></span>
            <span className="sort-control"></span>
          </div>
          <div id="searchlistAccordion" className="p-0 col-12 col-lg-12 col-xl-12">
            <ul className="list-group">
              <li className="list-group-item">
                <a action="true" className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls="0">
                  <div className="result-row-icon-placeholder">
                    <span className="mb-0 icon-project"></span>
                  </div>
                  <div className="result-row-overview">
                    <h5 className="result-title-name mb-1"></h5>
                    <div className="src-location-placeholder"></div>
                    <div className="sub-resultdetails-content">
                      <span className="rs-client-name"><strong></strong></span>
                      <span className="rs-value "><strong></strong></span>
                    </div>
                  </div>
                  <div className="result-row-button-block">
                    <div className="right-block">
                      <button className="favourite-icon"></button>
                      <button className="navigate-icon" title="Navigate"></button>
                      <button className="share-icon" title="Share"></button>
                      <button className="addcontact-icon" title="Add Contact"></button>
                      <button className="viewdetails-icon" title="View full details"></button>
                    </div>
                  </div>
                </a>
              </li>
              <li className="list-group-item">
                <a action="true" className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls="0">
                  <div className="result-row-icon-placeholder">
                    <span className="mb-0 icon-project"></span>
                  </div>
                  <div className="result-row-overview">
                    <h5 className="result-title-name mb-1"></h5>
                    <div className="src-location-placeholder"></div>
                    <div className="sub-resultdetails-content">
                      <span className="rs-client-name"><strong></strong></span>
                      <span className="rs-value "><strong></strong></span>
                    </div>
                  </div>
                  <div className="result-row-button-block">
                    <div className="right-block">
                      <button className="favourite-icon"></button>
                      <button className="navigate-icon" title="Navigate"></button>
                      <button className="share-icon" title="Share"></button>
                      <button className="addcontact-icon" title="Add Contact"></button>
                      <button className="viewdetails-icon" title="View full details"></button>
                    </div>
                  </div>
                </a>
              </li>
              <li className="list-group-item">
                <a action="true" className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls="0">
                  <div className="result-row-icon-placeholder">
                    <span className="mb-0 icon-project"></span>
                  </div>
                  <div className="result-row-overview">
                    <h5 className="result-title-name mb-1"></h5>
                    <div className="src-location-placeholder"></div>
                    <div className="sub-resultdetails-content">
                      <span className="rs-client-name"><strong></strong></span>
                      <span className="rs-value "><strong></strong></span>
                    </div>
                  </div>
                  <div className="result-row-button-block">
                    <div className="right-block">
                      <button className="favourite-icon"></button>
                      <button className="navigate-icon" title="Navigate"></button>
                      <button className="share-icon" title="Share"></button>
                      <button className="addcontact-icon" title="Add Contact"></button>
                      <button className="viewdetails-icon" title="View full details"></button>
                    </div>
                  </div>
                </a>
              </li>
              <li className="list-group-item">
                <a action="true" className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls="0">
                  <div className="result-row-icon-placeholder">
                    <span className="mb-0 icon-project"></span>
                  </div>
                  <div className="result-row-overview">
                    <h5 className="result-title-name mb-1"></h5>
                    <div className="src-location-placeholder"></div>
                    <div className="sub-resultdetails-content">
                      <span className="rs-client-name"><strong></strong></span>
                      <span className="rs-value "><strong></strong></span>
                    </div>
                  </div>
                  <div className="result-row-button-block">
                    <div className="right-block">
                      <button className="favourite-icon"></button>
                      <button className="navigate-icon" title="Navigate"></button>
                      <button className="share-icon" title="Share"></button>
                      <button className="addcontact-icon" title="Add Contact"></button>
                      <button className="viewdetails-icon" title="View full details"></button>
                    </div>
                  </div>
                </a>
              </li>
              <li className="list-group-item">
                <a action="true" className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls="0">
                  <div className="result-row-icon-placeholder">
                    <span className="mb-0 icon-project"></span>
                  </div>
                  <div className="result-row-overview">
                    <h5 className="result-title-name mb-1"></h5>
                    <div className="src-location-placeholder"></div>
                    <div className="sub-resultdetails-content">
                      <span className="rs-client-name"><strong></strong></span>
                      <span className="rs-value "><strong></strong></span>
                    </div>
                  </div>
                  <div className="result-row-button-block">
                    <div className="right-block">
                      <button className="favourite-icon"></button>
                      <button className="navigate-icon" title="Navigate"></button>
                      <button className="share-icon" title="Share"></button>
                      <button className="addcontact-icon" title="Add Contact"></button>
                      <button className="viewdetails-icon" title="View full details"></button>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default CommonPlaceholder