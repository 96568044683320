import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroup, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import { capitalizeFirstLetter, isDST, generateCalendarLinks } from '../../../../utils/commonFunctions';
import CalendarModal from '../../../modal/calendar';
import { getFormatedDate } from '../../../../utils';
import { deleteEvent } from '../../apiclient';
import CommonPlaceholder from '../../framework/commonPlaceholder';
import noevent from '../../../common/images/no-events.svg'
import Icon from '../../../common/js/icon';

const ActivitiesCalendarEventDetails = (props) => {
    const [events, setEvents] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [isEditEvent, setIsEditEvent] = useState(false)
    const [eventDetails, setEventDetails] = useState({})
    const [rowIndex, setRowIndex] = useState('')
    const project = useSelector((state) => state.project)
    const [payload, setPayload] = useState({})
    const dispatch = useDispatch();
    const userData = useSelector(state=> state.user.user)

    useEffect(() => {
        if (project && project.events && project.events.length >= 0) {
            setEvents(project.events)
        }
    }, [project && project.events])

    useEffect(() => {
        if (showDeleteAlert) {
            const timer = setTimeout(() => {
                deleteEvent(payload.object_id, payload.object_type, payload.eventId, userData, dispatch)
                setShowDeleteAlert(false)
                setPayload({})
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [showDeleteAlert, payload])

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    const handleEdit = (eventDetails) => {
        setIsEditEvent(true)
        setEventDetails(eventDetails)
        toggleModal()
    }

    const handleAddEvent = () => {
        setIsEditEvent(false)
        toggleModal();
    }

    const undoDelete = () => {
        setShowDeleteAlert(false)
    }

    const handleDelete = (payload) => {
        if (payload.eventId !== 0) {
            setShowDeleteAlert(true)
            toggleModal()
            setPayload(payload)
        }
    }

    const toggleDropdown = (index) => {
        setDropDownOpen(!dropDownOpen)
        setRowIndex(index)
    }

    const handleDropdownLinkClick = (e) => {
        e.preventDefault();
        var url = e.currentTarget.getAttribute("href");

        if (url.startsWith("data") || url.startsWith("BEGIN")) {
            var filename = "download.ics";
            var blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

            if (typeof window !== "undefined" && window.navigator.msSaveOrOpenBlob && window.Blob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            window.open(url, "_blank");
        }
    }

    const generateCalendarEvent = (event) => {
        var eventDetails = {}
        if (event !== undefined) {
            eventDetails = {
                title: event.subject,
                description: additionalDetailsInDescription(event),
                location: event.location ? event.location : '',
                startTime: event.start_on ? isDST(event.start_on) ? moment(event.start_on).subtract('1', 'hour') : event.start_on :
                    event.end_on ? isDST(event.end_on) ? moment(event.end_on).subtract('2', 'hour') : moment(event.end_on).subtract('1', 'hour') : '',
                endTime: event.end_on ? isDST(event.end_on) ? moment(event.end_on).subtract('1', 'hour') : event.end_on :
                    event.start_on ? isDST(event.start_on) ? event.start_on : moment(event.start_on).add('1', 'hour') : ''
            }
        }
        return eventDetails
    }

    const additionalDetailsInDescription = (event) => {
        var description = ''
        description += event.description ? event.description + '\n\n' : ''
        if (event.object_type === 'project') {
            description += 'Related Project\n'
            description += '\nProject ID:           ' + event.id
            description += '\nProject:                ' + event.name
            description += event.value ? '\nValue:                  ' + event.value : ''
            description += event.projectLocation ? '\nLocation:             ' + event.projectLocation : ''
            description += event.lastPublished ? '\nLast published:  ' + getFormatedDate(event.lastPublished, 'DD-MMM-YYYY') : ''
        } else {
            description += 'Related Company\n'
            description += event.id ? '\nCompany ID:  ' + event.id : ''
            description += event.name ? '\nCompany:       ' + event.name : ''
            description += event.companyLocation ? '\nLocation:        ' + event.companyLocation : ''
            description += event.phone ? '\nTelephone:    ' + event.phone : ''
        }
        return description
    }

    return (
        <div className="event-list">
            <div className={"delete-alert " + (showDeleteAlert ? 'show' : 'hide')}>
                <span>Event deleted</span><button onClick={undoDelete}>Undo</button>
            </div>
            <CalendarModal isOpen={isOpen} toggleModal={toggleModal} isEditEvent={isEditEvent}
                eventDetails={eventDetails} handleDelete={handleDelete} />
            {
                events && Object.keys(events).length > 0 ? (<>
                    <div className="event-list-wrapper">
                        <div className="selected-col p-3 position-relative"><p className="m-0 col-heading">Manage your events on this {props.textPlaceholder}</p></div>
                        <InputGroup className="event-form add-event-button">
                            <button className='primary-btn' onClick={() => { handleAddEvent() }}>Add Event</button>
                        </InputGroup>
                    </div>
                    <div className="event-details-wrapper">
                        {
                            events.map((event, index) => {
                                return (
                                    event.subject ?
                                        (
                                            <div className="event-listing p-3" key={index}>
                                                <button className="edit-button" onClick={() => handleEdit(event)}><Icon color="#053874" size={20} icon="icon-gen-edit" className="me-1"/></button>
                                                <div className="d-flex justify-content-start align-items-center ">
                                                    <span className={"event-type-icon " + event.type.toLowerCase().replaceAll(' ', '-') + "-icon"}></span>
                                                    <div className="event-subject">{event.subject}</div>
                                                    {event.reminder_on ? <div className="event-startDate d-inline-flex align-items-center ps-2">{event.created_on ? <><Icon color="#053874" size={20} icon="icon-gen-reminder" className="me-1"/> {moment.utc(event.reminder_on).format('DD-MMM-YYYY HH:mm')}</> : ("")}</div> : ("")}
                                                </div>
                                                {event.description ? <p className="description">{event.description}</p> : ("")}
                                                <div className="event-details d-flex align-items-center">
                                                    <span className={event.priority}>{capitalizeFirstLetter(event.priority)} priority</span>
                                                    <ul className="d-inline-flex pl-2">
                                                        <li>{capitalizeFirstLetter(event.type)}</li>
                                                        <li>{capitalizeFirstLetter(event.status)}</li>
                                                    </ul>
                                                </div>
                                                {(event.start_on || event.end_on) ? <div class="event-scheduled-time d-flex align-items-center">
                                                    <span className="pe-3">{event.start_on ? moment.utc(event.start_on).format('DD-MMM-YYYY HH:mm') : ("")} {event.start_on && event.end_on ? 'to' : ''} {event.end_on ? moment.utc(event.end_on).format('DD-MMM-YYYY HH:mm') : ("")}</span>
                                                    {(event.start_on || event.end_on) ?
                                                        <Dropdown isOpen={dropDownOpen && index === rowIndex} toggle={() => toggleDropdown(index)}>
                                                            <DropdownToggle caret>Add to calendar</DropdownToggle>
                                                            <DropdownMenu>
                                                                {generateCalendarLinks(generateCalendarEvent(event)).map((optionItem) => {
                                                                    return <DropdownItem ><a href={optionItem.value} target="_blank" onClick={handleDropdownLinkClick}>{optionItem.title}</a></DropdownItem>
                                                                })}
                                                            </DropdownMenu>
                                                        </Dropdown> : ''}
                                                </div> : ("")}
                                            </div>
                                        ) :
                                        <CommonPlaceholder />
                                )
                            })
                        }
                    </div>
                </>
                ) : (
                    <div className="no-events text-center p-5">
                        <img src={noevent} />
                        <h6 className="mb-1 mt-4 text-bold">You have no events</h6>
                        <p>You can add events such as tasks and meetings, click 'Add Event' below.</p>
                        <button className='primary-btn' onClick={() => { handleAddEvent() }}>Add Event</button>
                    </div>
                )
            }
        </div>
    )
}
export default ActivitiesCalendarEventDetails