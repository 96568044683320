import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Label, Input } from 'reactstrap';
import { messageAlert } from '../../../utils';
import { ADMIN_LICENSE } from '../../../utils/settings';
import { updateAssignedLicences, updateLicenseCheckedStatus, updateTempCountOfuserLicences } from '../reducer';
import { getGrantRevokeInfo } from '../utils';

const UserAssignLicenseList = (props) => {
  const user = useSelector((state) => state.user.user);
  const useradminData = useSelector(state => state.useradmin);
  const [assignedLicences, setAssignedLicences] = useState(useradminData?.assignedLicences)
  const dispatch = useDispatch();

  useEffect(() => {
    if (useradminData?.assignedLicences) {
      setAssignedLicences(useradminData?.assignedLicences);
    }
  }, [useradminData?.assignedLicences]);

  const handleCheckboxChange = (event) => {
    event.stopPropagation();
    let originalAppliedUserLicenses = {};
    let selectedUserNo = props.userLicense.user_no;

    useradminData?.tempAdminUserList.forEach((user) => {
      if (user.user_no === selectedUserNo) {
        originalAppliedUserLicenses = user;
      }
    });

    if (Object.values(originalAppliedUserLicenses).length === 0) {
      props.userSearchList.data.forEach((user) => {
        if (user.user_no === selectedUserNo) {
          originalAppliedUserLicenses = user;
        }
      });
    }

    let licenseno = event.target.value.split('_')[1];
    var payload = {
      userno: event.target.value.split('_')[0],
      licenseno: event.target.value.split('_')[1],
      checkedStatus: event.target.checked,
      licenseStatus: props?.userLicense?.licenseStatus,
      grantRevokeLicense: {
        licenseno: parseInt(event.target.value.split('_')[1]),
        name: event.target.value.split('_')[2],
      },
    };

    let tempRecord = {};
    let dataTemp = props?.filteredAdminUserList
    dataTemp?.forEach((record) => {
      if (record.user_no === parseInt(payload.userno)) {
        tempRecord = { ...record };
      }
    });

    var licensesArray = [...tempRecord.license];
    var licenseStatus = { ...props?.userLicense?.licenseStatus };
    licenseStatus[payload.licenseno] = payload.checkedStatus;
    if (payload.checkedStatus) {
      licensesArray.push(payload.grantRevokeLicense);
    } else {
      let index = licensesArray.findIndex(
        (x) => x.licenseno === payload.grantRevokeLicense.licenseno
      );
      licensesArray.splice(index, 1);
    }

    let activeLicense = {};
    props.availableLicenses.forEach((available) => {
      if (available.licence_id === parseInt(licenseno)) {
        activeLicense = available;
      }
    });

    let checkedStatusPayload = {
      licensesArray: licensesArray,
      licenseStatus: licenseStatus,
      userno: payload.userno,
    };
    if (event.target.checked) {
      if (activeLicense.total > activeLicense.assigned) {
        activeLicense = { ...activeLicense, assigned: activeLicense.assigned + 1 }
        let assignLicenseInfo = getGrantRevokeInfo(
          assignedLicences,
          payload.userno,
          payload.licenseno,
          payload.checkedStatus,
          originalAppliedUserLicenses.license
        );
        dispatch(updateTempCountOfuserLicences(activeLicense));
        if (assignLicenseInfo) {
          dispatch(updateAssignedLicences(assignLicenseInfo));
        }
        dispatch(updateLicenseCheckedStatus(checkedStatusPayload));
      }
      else {
        messageAlert(dispatch, "No more licenses available to allocate. Please contact your account manager.", 'success')
      }
    } else {

      activeLicense = { ...activeLicense, assigned: activeLicense.assigned - 1 }
      let assignLicenseInfo = getGrantRevokeInfo(
        assignedLicences,
        payload.userno,
        payload.licenseno,
        payload.checkedStatus,
        originalAppliedUserLicenses.license
        
      );
      dispatch(updateTempCountOfuserLicences(activeLicense));
      if (assignLicenseInfo) {
        dispatch(updateAssignedLicences(assignLicenseInfo));
      }
      dispatch(updateLicenseCheckedStatus(checkedStatusPayload));
    }
  };

  return (
    <tr className="assign-row">
      <td className="left user-names">
        <span className="user-icon">{props.userLicense.initials}</span>
        <div className="user-detail-wrapper">
          <span className="fl-name">
            {props.userLicense.first_name + ' ' + props.userLicense.last_name}
          </span>
          {props.userLicense.username ? (
            <span className="user-name"> {props.userLicense.username}</span>
          ) : (
            ''
          )}
          {props.userLicense.email ? (
            <a href={'mailto:' + props.userLicense.email} className="email-address">
              {' '}
              {props.userLicense.email}{' '}
            </a>
          ) : (
            ''
          )}
        </div>
      </td>
      {props?.availableLicenses &&
        props?.availableLicenses?.map((available, index) => (
          <td className="center" key={`license-${index}`}>
            <div className="src-filter-field">
              {props.userLicense.user_no == user.user_no &&
                user.licenceFlag.isAdminLicence &&
                ADMIN_LICENSE.includes(props.availableLicenses[index].licence_id) ? (
                <div className="check-box-button disabled">
                  <Input
                    id={
                      'chk_licenser_' +
                      props.userLicense.user_no +
                      available.licence_id
                    }
                    value={
                      props.userLicense.user_no +
                      '_' +
                      available.licence_id +
                      '_' +
                      available.license_name
                    }
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={props?.userLicense?.licenseStatus[available.licence_id]}
                    disabled
                  />
                  <Label
                    className="form-check-label"
                    for={
                      'chk_licenser_' +
                      props.userLicense.user_no +
                      available.license_no
                    }
                  ></Label>
                </div>
              ) : (
                <div className="check-box-button">
                  <Input
                    id={
                      'chk_licenser_' +
                      props.userLicense.user_no +
                      available.licence_id
                    }
                    value={
                      props.userLicense.user_no +
                      '_' +
                      available.licence_id +
                      '_' +
                      available.license_name
                    }
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={props?.userLicense?.licenseStatus[available.licence_id]}
                  />
                  <Label
                    className="form-check-label"
                    for={
                      'chk_licenser_' +
                      props.userLicense.user_no +
                      available.licence_id
                    }
                  ></Label>
                </div>
              )}
            </div>
          </td>
        ))}
    </tr>
  );
};

export default UserAssignLicenseList;
