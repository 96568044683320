import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, Row, TabContent, TabPane } from 'reactstrap'
import { fetchGroupCompanies, updateSelectorControlTags, updateSelectorFavUnfav } from '../../../apiclient'
import CommonPlaceholder from '../../../framework/commonPlaceholder'
import SearchListRow from '../../../list/searchListRow'
import SearchSelector from '../../../list/searchSelector'
import TagsModal from '../../../list/tagsModal'
import { updateIsProjectSelected, updateListSelection, updateSelectedTagDetails, updateShowMoreOffsetLimit } from '../../../redux/reducer'
import { fetchSortValue, getUniqueSelectedList } from '../../../../../utils/commonFunctions'
import _ from 'lodash'

const GroupCompaniesTab = () => {
    const details = useSelector((state) => state.project)
    const [groupCompanies, setGroupCompanies] = useState(details && details.groupCompanies)
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const [activeSort, setActiveSort] = useState('Latest')
    const [modal, setModal] = useState(false)
    const [totalSelectedCount, setTotalSelectedCount] = useState(0)
    const userData = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const search = useSelector((state) => state.search)
    const location = useLocation()

    useEffect(() => {
        setGroupCompanies(details && details.groupCompanies)
    }, [details && details.groupCompanies])

    const handleCommonSort = (sort) => {
        dispatch(updateListSelection({ count: 0, entityType: 'groupedCompanies' }))
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        setActiveSort(sort == '-count_project_id' ? 'Projects' : sort == '-sum_project_value' ? 'Value' : 'Latest')
    }

    const toggle = () => {
        setDropDownOpen(!dropdownOpen)
    }

    useEffect(() => {
        setTotalSelectedCount(details && details.selectorControlSelectionCount === undefined ? totalSelectedCount : details && details.selectorControlSelectionCount)
    }, [details && details.selectorControlSelectionCount])

    const getTotalRows = () => {
        let totalCount = 0
        Object.keys(groupCompanies).map((role, index) => {
            totalCount += groupCompanies[role].length
        })
        return totalCount
    }

    const handleSortforGroupCompanies = (sort, type) => {
        let sortValue = ''
        if (sort == 'Projects') {
            sortValue = '-count_project_id'
        } else if (sort == 'Value') {
            sortValue = '-sum_project_value'
        } else if (type === 'project') {
            sortValue = '-project_last_published'
        }
        else {
            sortValue = '-max_project_last_published'
        }
        return sortValue
    }

    const handleFavChangeSelection = (action) => {
        let objectIds = getUniqueSelectedList(groupCompanies)
        if (objectIds.length > 0) {
            const payload = {
                object_id: objectIds,
                object_type: 'company',
                favourite: action === "Favourite" ? true : false,
            }
            if (details && details.selectorControlSelectionCount) {
                payload['selected_records'] = details && details.selectorControlSelectionCount
            }
            if (details.includeIds && details.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - details && details.includeIds.length
                payload['company_id'] = details && details.includeIds
            }
            if (details.excludeIds && details.excludeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] + details && details.excludeIds.length
                payload['exclude_ids'] = details && details.excludeIds
            }
            payload['identifier'] = 'group_companies'
            payload['query'] = { 'company_group_ids': { 'operator': '=', 'value': details && details.detailsPage.company_id } }
            if (activeSort) {
                payload['sort'] = handleSortforGroupCompanies(activeSort)
            }
            if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
                delete payload['selected_records']
            }
            else {
                payload.selected_records = parseInt(payload.selected_records)
            }
            updateSelectorFavUnfav('companies', details.detailsPage.company_id, payload, dispatch, details)
            handleDropDownItemClick(0)
        }
    }

    const handleDownloadPDFSelection = () => {

    }

    const handleDropDownItemClick = (count) => {
        dispatch(updateListSelection({ count, entityType: 'groupedCompanies' }))
        if (count) {
            setDropDownOpen(false)
        }
    }

    const handleTagsSelection = () => {
        setModal(!modal)
    }

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setModal(!modal)
        handleDropDownItemClick(0)
    }

    const handleTagsSubmit = () => {
        let type = 'companies'
        let objectIds = getUniqueSelectedList(groupCompanies)
        let id = details && details.detailsPage && details.detailsPage.company_id
        if (objectIds.length > 0) {
            let payload = {
                object_id: objectIds,
                object_type: 'company',
                listName: "groupCompanies"
            }
            if (details?.selectedTagsDetails?.tagIds?.length > 1 && details?.selectedTagsDetails?.tagIds?.includes(0)) {
                let tag_name = []
                let tag_id = []
                _.map(details?.selectedTagsDetails?.tagIds, (tagId, index) => {
                    if (tagId === 0) {
                        tag_name.push(details.selectedTagsDetails.tagDescriptions[index])
                    } else {
                        tag_id.push(details.selectedTagsDetails.tagIds[index])
                    }
                })
                if (tag_name.length) {
                    payload['tag_name'] = tag_name
                }
                if (tag_id.length) {
                    payload['tag_id'] = tag_id
                }
            } else if (details?.selectedTagsDetails?.tagIds?.includes(0)) {
                payload['tag_name'] = details.selectedTagsDetails.tagDescriptions
            } else {
                payload['tag_id'] = details.selectedTagsDetails.tagIds
            }
            if (details && details.selectorControlSelectionCount) {
                payload['selected_records'] = details && details.selectorControlSelectionCount
                if ('selected_records' in payload && payload['selected_records'] !== 0) {
                    payload['query'] = {
                        'project_id': { 'operator': '=', 'value': id },
                    }
                }
                if (details.includeIds && details.includeIds.length > 0) {
                    payload['selected_records'] = payload['selected_records'] - details.includeIds.length
                    payload['company_id'] = details.includeIds
                }
                if (details.excludeIds && details.excludeIds.length > 0) {
                    payload['selected_records'] = details && details.selectorControlSelectionCount + details.excludeIds
                    payload['exclude_ids'] = details.excludeIds
                }
                payload['identifier'] = 'group_companies'
                payload['query'] = { 'company_group_ids': { 'operator': '=', 'value': details && details.detailsPage.company_id } }
                if (activeSort) {
                    payload['sort'] = handleSortforGroupCompanies(activeSort)
                }
                if ('selected_records' in payload && payload['selected_records'] == 0) {
                    delete payload['selected_records']
                }
                else {
                    payload.selected_records = parseInt(payload.selected_records)
                }
            }

            handleTagsCancel()
            handleDropDownItemClick(0)
            updateSelectorControlTags(type, details.detailsPage.company_id, payload, dispatch, details)
        }
    }

    const handleShowGroupProjects = () => {
        dispatch(updateIsProjectSelected({ isProjectSelected: true, objectId: details.detailsPage.company_id }))
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }))
        navigate('/app/list', {state: {previousState: location.state}})
    }

    useEffect(() => {
        if (details.detailsPage.company_id) {
            let groupCompaniesPayload = {
                "id": details.detailsPage.company_id,
                "sort": fetchSortValue(activeSort.toLowerCase(), details.isJointProjectViewed ? "project" : "company"),
            }
            fetchGroupCompanies(groupCompaniesPayload, dispatch, details)
        }
    }, [details?.showMoreOffsetLimit?.offset, activeSort]);

    const handleShowMore = () => {
        let newOffset = details?.showMoreOffsetLimit?.offset + details?.showMoreOffsetLimit?.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details?.showMoreOffsetLimit?.records }));
    }

    return (
        <>
            {
                groupCompanies && Object.keys(groupCompanies).length ? (
                    <div className="empty-view">
                        <div className="box-view group-companies-container">
                            <Row className="m-0">
                                <TabContent activeTab={"group_companies"} className="p-0">
                                    <TabPane activeTab={"group_companies"} tabId={"group_companies"}>
                                        <div className="list-wrapper-other-pro px-3 py-2">
                                            <div className="company-groups-listing">
                                                <div className="selectorAndDropdown">
                                                    <div className="toggle-role-dropdown sort-results d-flex justify-content-between">
                                                        <div className="mb-1">
                                                            <SearchSelector
                                                                isGroupCompanies={true}
                                                                isProjectList={false}
                                                                handleFavChangeSelection={handleFavChangeSelection}
                                                                handleTagsSelection={handleTagsSelection}
                                                                handleDownloadPDFSelection={handleDownloadPDFSelection}
                                                                handleDropDownItemClick={handleDropDownItemClick}
                                                                totalSelectedCount={totalSelectedCount}
                                                                totalRows={getTotalRows()}
                                                                user={userData}
                                                                entitiesCount={getTotalRows()}
                                                                activeSort={activeSort}
                                                                getUniqueSelectedList={(groupCompanies) => getUniqueSelectedList(groupCompanies)}
                                                                handleSortforGroupCompanies={handleSortforGroupCompanies}
                                                                handleShowGroupProjects={handleShowGroupProjects}
                                                            >selector-placeholder
                                                            </SearchSelector>
                                                        </div>
                                                        <div className="sort-order-selector">
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className="me-2">Sort subsidiary companies by: </span>
                                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                                    <DropdownToggle caret>
                                                                        {activeSort}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => handleCommonSort('-max_project_last_published')} key={1} value={'Letter mail merge'}>
                                                                            Latest
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={() => handleCommonSort('-count_project_id')} key={2} value={'Letter mail merge'}>
                                                                            Projects
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={() => handleCommonSort('-sum_project_value')} key={3} value={'Letter mail merge'}>
                                                                            Value
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <TagsModal
                                                    handleTagsCancel={handleTagsCancel}
                                                    handleTagsSubmit={handleTagsSubmit}
                                                    selectedTags={details && details.selectedTagsDetails}
                                                    showModal={modal}
                                                >
                                                </TagsModal>
                                                {groupCompanies && Object.keys(groupCompanies).length > 0 ? (Object.keys(groupCompanies).map((role, index) => (
                                                    <div className="project-role-wrapper" key={`role${index}`}>
                                                        <span className="project-role-name">{role}</span>
                                                        <Row className="result-list-container content-panel" >
                                                            <Col xs="12" lg="12" xl="12" id="searchlistAccordion" className="p-0">
                                                                <ListGroup>
                                                                    {groupCompanies[role].map((entity, index) => {
                                                                        const newEntity = Object.assign({
                                                                            objectId: entity.company_id,
                                                                            objectType: "company", onFavClick: false
                                                                        }, entity);
                                                                        return (
                                                                            <SearchListRow entity={newEntity} index={index}
                                                                                prevNextList='groupedCompanies'
                                                                                objectType='company'
                                                                                key={`resultrow${index}`}
                                                                                sort={activeSort}
                                                                            />
                                                                        )
                                                                    })}
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                    </div >
                                                ))) :
                                                    <CommonPlaceholder />}
                                                {
                                                    details?.isGroupComapniesShowMoreActive ? (
                                                        <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                                            <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            groupCompanies && Object.keys(groupCompanies).length == 0 ?
                                (<span>No group companies attached</span>) :
                                <CommonPlaceholder />
                        }
                    </>
                )
            }

        </>
    )
}

export default GroupCompaniesTab