import { createSlice } from '@reduxjs/toolkit'
import { getIncludeExcludeIds } from '../../utils/commonFunctions';

const initialState = {
  searchList: [],
  leagueSavedSearchList: [],
  leagueNoResult: false,
  leagueSearchListCount: 0,
  countOfSelectedObjectIds: 0,
  selectedSelectorControlValue: 0,
  selectorControlSelectionCount: 0,
  selectedLeadsFlag: [],
  showCompanyHierarchy: false,
  removeFilterToggle: true,
  offsetLimitValue: {
    offset: 0,
    records: 50
  },
  isSharedSaveSearchActive: false,
  searchQuery: {},
  selectedCount: 0
}

const leagueTableSlice = createSlice({
  name: 'leagueTable',
  initialState,
  reducers: {
    updateLeagueSavedSearchList: (state, action) => {
      state.leagueSavedSearchList = action.payload;
      return state
    },
    updateSearchlist: (state, action) => {
      state.searchList = action.payload;
      return state
    },
    updateSelectedSelectorControlValue: (state, action) => {
      let currentCountSelectorControlValue = state.selectedSelectorControlValue;
      let currentCountSelectedObjectIds = state.countOfSelectedObjectIds
      if ((action.payload).toString() == "") {
        action.payload = state.searchList.length
      }

      if (action.payload.isSelectionRelatedEntities) {
        state.countOfSelectedObjectIds = action.payload.count
        state.selectedSelectorControlValue = currentCountSelectorControlValue
        state.selectedCount = currentCountSelectorControlValue
      }
      else {
        state.countOfSelectedObjectIds = currentCountSelectedObjectIds
        state.selectedSelectorControlValue = action.payload.toString() != "" ? action.payload : state.count
        state.selectedCount = action.payload.toString() != "" ? action.payload : state.count
      }

      return state
    },
    updateSearchListSelection: (state, action) => {
      let selectionCount = action.payload;
      let updatedLeadsFlags = []
      if (selectionCount > 0) {
        let leadsFlags = state.searchList
        leadsFlags.map(function (list, index) {
          if (index <= (selectionCount - 1)) {
            list['is_selected'] = true
            updatedLeadsFlags.push(list)
            state.selectedLeadsFlag.push(list)
          } else {
            list['is_selected'] = false
            updatedLeadsFlags.push(list)
            state.selectedLeadsFlag.push(list)
          }
        })
      }
      else {
        state.searchList && state.searchList.map(function (list, index) {
          list['is_selected'] = false
          updatedLeadsFlags.push(list)
          state.selectedLeadsFlag.push(list)
        })
      }
      state.selectedSelectorControlValue = selectionCount
      return state
    },
    updateIsSelectedToSpecificRowLeagueTable: (state, action) => {
      let leadsFlags = Object.assign({}, state.searchList);
      let updatedLeadsFlags = []
      let objectType = 'company_id'

      let currnetIndexes = parseInt(state.selectedSelectorControlValue)
      Object.values(leadsFlags).map((record) => {
        if (record[objectType] === action.payload.id) {
          if (action.payload.isSelected === true) {
            record['is_selected'] = true
            currnetIndexes++
          }
          else {
            record['is_selected'] = false
            currnetIndexes--
          }
        }
        updatedLeadsFlags.push(record)
      })
      state.searchList = updatedLeadsFlags
      state.selectedSelectorControlValue = currnetIndexes
      const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
      state.excludeIds = excludeIds
      state.includeIds = includeIds
      return state
    },
    updateLeagueShowHierarchy: (state, action) => {
      state.showCompanyHierarchy = action.payload.showCompanyHierarchy
      return state
    },
    updateProcessId: (state, action) => {
      state.processId = action.payload
      return state
    },
    resetIncludeExcludeIds: (state, action) => {
      state.excludeIds = action.payload
      state.includeIds = action.payload
      return state
    },
    updateLeagueNoResult: (state, action) => {
      state.leagueNoResult = action.payload;
      return state
    },
    updateLeagueTableOffsetLimitValue: (state, action) => {
      state.offsetLimitValue = action.payload
      return state
    },
    resetRelationalEntititySelectionCount: (state) => {
      state.countOfSelectedObjectIds = 0
      return state
    },
    updateLeagueSearchListCount: (state, action) => {
      state.leagueSearchListCount = action.payload;
      return state
    },
    updateLeagueRemoveFilterToggle: (state, action) => {
      state.removeFilterToggle = action.payload
      return state
    },
    updateShowMoreSearchList: (state, action) => {
      let leadsFlags = state.searchList.concat(action.payload);
      if (state.selectedSelectorControlValue && state.searchList.length < state.selectedSelectorControlValue) {
        leadsFlags = leadsFlags.map((item, index) => index < state.selectedSelectorControlValue ? { ...item, is_selected: true } : item)
      }
      state.searchList = leadsFlags
      return state
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      return state;
    }
  }
})

export const { updateLeagueSavedSearchList, updateLeagueTableOffsetLimitValue, updateSearchlist, updateLeagueNoResult,
  updateLeagueSearchListCount, updateSelectedSelectorControlValue, resetRelationalEntititySelectionCount,
  updateProcessId, updateSearchListSelection, updateIsSelectedToSpecificRowLeagueTable, updateLeagueShowHierarchy,
  resetIncludeExcludeIds, updateLeagueRemoveFilterToggle, updateShowMoreSearchList, updateSearchQuery } = leagueTableSlice.actions

export const leagueTableReducer = leagueTableSlice.reducer