import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";
import {
  getGoogleAnalyticsDetails,
  getPageName,
  getPageNameForDetailPage
} from "../../../utils";

function GoogleAnalytics() {
  const project = useSelector((state) => state.project)
  const location = useLocation();
  const { hash } = useLocation();
  const [oldPageName, setOldPageName] = useState("");

  useEffect(() => {
    let path = hash !== "" ? location.pathname + hash : location.pathname;
    let pageName = getPageName(path);
    let googleAnalyticsDetails = getGoogleAnalyticsDetails(window.location);
    if (
      pageName !== undefined &&
      pageName !== "" &&
      pageName !== "Project Locator" &&
      oldPageName !== pageName
    ) {
      ReactGA.send({
        hitType: "pageview",
        page: path,
        title: pageName,
        streamUrl: googleAnalyticsDetails?.streamUrl,
        streamName: googleAnalyticsDetails?.streamName,
        streamId: googleAnalyticsDetails?.streamId
      });
      setOldPageName(pageName);
    }
  }, [location]);


  useEffect(() => {
    let path = hash != "" ? location.pathname + hash : location.pathname;
    let pageName = getPageNameForDetailPage(path, document.title);
    let googleAnalyticsDetails = getGoogleAnalyticsDetails(window.location)
    // Send page view event to Google Analytics 4
    if (pageName !== undefined && pageName !== '' && pageName != "Project Locator" && oldPageName !== pageName) {
      ReactGA.send({
        hitType: "pageview", page: path, title: pageName, streamUrl: googleAnalyticsDetails?.streamUrl,
        streamName: googleAnalyticsDetails?.streamName,
        streamId: googleAnalyticsDetails?.streamId,
      });
      setOldPageName(pageName);
    }
  }, [document.title,project.detailPage]);



}

export default GoogleAnalytics;
