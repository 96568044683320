import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import MyLeadsSearchSelector from '../selectorDropdown'
import { useDispatch, useSelector } from 'react-redux';
import SearchListResult from '../../search/searchListResult';
import MapView from '../../map';
import { resetBadgeCount } from '../../header/apiclient';

const Updates = (props) => {
    const dispatch = useDispatch();
    const myleads = useSelector((state) => state.myleads)
    const searchPersist = useSelector((state) => state.searchPersist)
    const userData = useSelector((state) => state.user.user);
    const [listMapToggle] = useState(myleads.isListView)
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        actionsDropDownOpen: false,
        isShowRelatedEntitiesMode: false,
        isModalOpen: false,
        modal: false,
        archive: false
    })

    const getSearchListResult = () => {
        return (<SearchListResult
            currentOffset={0}
            type={searchPersist.leadsAppliedContext.updates}
            prevNextList='myLeadsUpdates'
            activeTab={props.activeTab}
            isListMapButtonFlag={listMapToggle}
        />)
    }
    useEffect(() => {
        resetBadgeCount(userData?.user_id, userData?.licenceFlag, dispatch);
    }, [])

    return (

        props.activeTab === 'updates' &&
        (<div className="search-list-content">
            <div className={`search-result ${myleads.isListView ? 'list-view-panel' : ''} `}>
                <div className="active page-title-bar">
                    <div className='top-5'>
                        <div className="only-listview d-flex justify-content-between me-3">
                            <MyLeadsSearchSelector
                                prevNextList='myLeadsUpdates'
                                activeTab={props.activeTab}
                                booleanData={booleanData} />
                        </div>
                    </div>
                    <Row className="me-0">
                        <Col className="ps-0">
                            {
                                myleads.isListView ?
                                    getSearchListResult() : <MapView prevNextList='myLeadsUpdates' activeTab={props.activeTab} mode='myLeadsUpdatesMapView' entityFlag={false} />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>)
    )
}

export default Updates