import React from 'react'
import { getFormatedDate } from '../../../utils'

const TagShareNoteCount = (props) => {
    return (
        <div className="showIconCounts mt-1">
            <ul>
                {
                    props.entity && props.entity.tags_count && props.entity.tags_count != 0 ?
                        (
                            <li>
                                <a href="javascript:void(0)" className="tagCountBx">
                                    {props.entity.tags_count}
                                </a>
                            </li>
                        ) :
                        ("")
                }
                {
                    props.entity && props.entity.shares_count && props.entity.shares_count != 0 ?
                        (
                            <li>
                                <a href="javascript:void(0)" className="shareCountBx">
                                    {props.entity.shares_count}
                                </a>
                            </li>
                        ) :
                        ("")
                }
                {
                    props.entity && props.entity.notes_count && props.entity.notes_count != 0 ?
                        (
                            <li>
                                <a href="javascript:void(0)" className="noteCountBx">
                                    {props.entity.notes_count}
                                </a>
                            </li>
                        ) :
                        ("")
                }
                {
                    props.entity && props.entity.mail && props.entity.mailed_on ?
                        (
                            <li>
                                <a href="javascript:void(0)" className="mailDateBx">
                                    {getFormatedDate(props && props.entity && props.entity.mailed_on, 'DD-MMM-YYYY')}
                                </a>
                            </li>
                        ) :
                        ("")
                }

            </ul>
        </div>
    )
}

export default TagShareNoteCount