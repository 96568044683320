import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { InputGroup, Button, Input, ModalHeader, Modal, ModalFooter, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, Row, Col } from 'reactstrap';
import CalendarModal from '../modal/calendar';
import CalendarHeader from './calendarHeader';
import CalendarTable from './calendarTable';


import './calendar.scss'
import { fetchCalendarEvents } from './apiclient';

const CalendarMain = (props) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const calendarEventState = useSelector(state => state.calendar)
    const dispatch = useDispatch()

    const handleUndoDelete = () => {
        setShowDeleteAlert(false);
    };

    return (
        <div>
            <div className="event-table-page pt-0 main-container container event-list">
                <div className={`delete-alert ${showDeleteAlert ? 'show' : ''}`}>
                    <span>Events deleted</span>
                    <button onClick={handleUndoDelete}>Undo</button>
                </div>
                <CalendarHeader {...props} />
                <CalendarTable {...props} />
            </div>
        </div>
    );
}

export default CalendarMain;