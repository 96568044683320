import _ from "lodash";
import { getDisplayProjectValue, getRelativeTimingMessage, getRelativeUpdatedMessage } from "../../utils";
import { convertDate, fetchSortLabel } from "../../utils/commonFunctions";
import { updateSelectedSearchType, toggleBuildPhaseFilter, toggleCalendarTimingFilter, toggleEndConstructionFilter, toggleFilterActiveState, toggleLastUpdatedFilter, toggleNewUpdatedFilter, toggleRoleAddedFilter, toggleStartConstructionFilter, updateAdvanceLocation, updateBuildPhaseValueReducer, updateCalenderEndFrom, updateCalenderEndTo, updateCalenderStartFrom, updateCalenderStartTo, updateCategoryLandTypeFilter, updateCategoryUsageTypeFilter, updateEndConstructionFilter, updateExcludeStageFilter, updateFilterText, updateLastUpdatedFilter, updateMeasures, updateNewPublishFilter, updatePrimaryCategoriesOnlyFlag, updateProjectFramework, updateRoleAddedFilter, updateSavedSearchCheckboxFilters, updateShowCompletedChecked, updateSort, updateStartConstructionFilter, updateValue } from "./search/filters/redux/reducer";
import { fetchSearchListForAllObjectType, rolesForProject, updateArchiveSearchList, updateIdsForShowRelatedEntities, updateIsShowProjectsOrCompanySelected, updatelistOfSelectedObjectIds, updateMaxApiCalls, updateNavigationBackJA, updateNoResult, updateObjectDataJA, updateObjectId, updatePeopleProjectsList, updateProjectsForCompanyId, updateSearchLoader, updateSelectedSelectorControlValue, updateShowHierarchyForJustAsk, updateShowSelectedCompanies, updateShowSelectedCompaniesJA, updateShowSelectedProjects, updateShowSelectedProjectsJA } from "./search/redux/reducer";
import { updateAppliedContext, updatedSelectedSavedSearch, updateSortSearch } from "./search/redux/searchPeristReducer";

export function initializeJustAskFilterState(justAskResponse, searchPersist, filter, dispatch) {
    justAskResponse.query = JSON.parse(justAskResponse.query)
    let defaultFilters = filter.defaultFilter[justAskResponse?.object_type]
    initializeSaveSearchContext(dispatch, justAskResponse)

    initializeProjectInformation(justAskResponse, dispatch)
    initializePersonInformation(justAskResponse, dispatch)
    initializePostcodeFilter(justAskResponse, dispatch)
    initializeTownCodeFilter(justAskResponse, dispatch)
    initializeProjectlocationFilter(justAskResponse, dispatch)
    initializeCompanylocationFilter(justAskResponse, dispatch)
    initializeProjectFramework(justAskResponse, searchPersist, dispatch)
    initializeProjectTitle(justAskResponse, dispatch)
    initializeProjectScheme(justAskResponse, dispatch)
    initializeCompanyInfo(justAskResponse, dispatch)
    initializeCompanyName(justAskResponse, dispatch)
    initializeCompanyId(justAskResponse, dispatch)
    initializeCompanyTownCodeFilter(justAskResponse, dispatch)
    initializeCompanyPostCodeFilter(justAskResponse, dispatch)
    initializeValue(justAskResponse, dispatch)
    initializeCalenderStartDate(justAskResponse, defaultFilters, dispatch)
    initializeCalenderEndDate(justAskResponse, defaultFilters, dispatch)
    initializeUpdated(justAskResponse, dispatch)
    initializeProjectRegion(justAskResponse, defaultFilters, dispatch)
    initializeCompanyRegion(justAskResponse, defaultFilters, dispatch)
    initializeBuildPhase(justAskResponse, dispatch)
    initializeBrandInformation(justAskResponse, dispatch)
    initializeMaterials(justAskResponse, defaultFilters, dispatch)
    initializeCategories(justAskResponse, defaultFilters, dispatch)
    initializeDevTypes(justAskResponse, defaultFilters, dispatch)
    initializeRoles(justAskResponse, defaultFilters, dispatch)
    initializeStages(justAskResponse, defaultFilters, dispatch)
    initializeMeasures(justAskResponse, dispatch)
    initializeProjectUsuage(justAskResponse, dispatch)
    initializeCategoryLandType(justAskResponse, dispatch)
    initializeSort(justAskResponse, dispatch, searchPersist)
}

export const initializeSort = (justAskResponse, dispatch, searchPersist) => {
    let updatedSortOrderObj = _.cloneDeep(searchPersist.defaultSort);
    let sort = ''
    if ('sort' in justAskResponse) {
        sort = justAskResponse.sort
    } else {
        let query = justAskResponse.query
        sort = (query && (query.project_text || query.project_title || query.project_scheme
            || query.company_text || query.company_name || query.company_id
            || query.person_text || query.person_full_name || query.person_job_title)) ? '' :
            (justAskResponse.object_type === 'people') ? 'person_first_name,person_last_name' : justAskResponse.object_type === "company" ? "-count_project_id" : "-project_last_published"
    }
    updatedSortOrderObj[justAskResponse?.object_type] = {
        label: fetchSortLabel(sort),
        value: sort,
    };
    dispatch(updateSortSearch(updatedSortOrderObj));
    dispatch(updateSort(sort))
    return sort
}

const initializeProjectInformation = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_text) {
        let payload1 = {
            value: justAskResponse?.query?.project_text?.value,
            dataObj: justAskResponse?.query?.project_text?.value,
            active: justAskResponse?.query?.project_text.value?.length > 0 ? true : false,
            keyname: "projectInformation",
            isAnyAll: justAskResponse?.project_search ? justAskResponse?.project_search : "any"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializePersonInformation = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.person_text) {
        let payload1 = {
            value: justAskResponse?.query?.person_text.value,
            dataObj: justAskResponse?.query?.person_text.value,
            active: justAskResponse?.query?.person_text.value !== '' ? true : false,
            keyname: "personInformation"
        }
        dispatch(updateFilterText(payload1))
    }
    if (justAskResponse?.query?.person_full_name) {
        let payload1 = {
            value: justAskResponse?.query?.person_full_name.value,
            dataObj: justAskResponse?.query?.person_full_name.value,
            active: justAskResponse?.query?.person_full_name.value !== '' ? true : false,
            keyname: "personName"
        }
        dispatch(updateFilterText(payload1))
    }

    if (justAskResponse?.query?.person_job_title) {
        let payload1 = {
            value: justAskResponse?.query?.person_job_title.value,
            dataObj: justAskResponse?.query?.person_job_title.value,
            active: justAskResponse?.query?.person_job_title.value !== '' ? true : false,
            keyname: "personJobTitle"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializePostcodeFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_postcode) {
        let payload = {
            value: justAskResponse?.query?.project_postcode?.value,
            dataObj: justAskResponse?.query?.project_postcode?.value,
            active: justAskResponse?.query?.project_postcode?.value.length > 0 ? true : false,
            keyname: "projectPostcode"
        }
        dispatch(updateFilterText(payload))
    }
}

const initializeTownCodeFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_town) {
        let payload = {
            value: justAskResponse?.query?.project_town?.value,
            dataObj: justAskResponse?.query?.project_town?.value,
            active: justAskResponse?.query?.project_town?.value.length > 0 ? true : false,
            keyname: "projectTown"
        }
        dispatch(updateFilterText(payload))
    }
}

const initializeProjectlocationFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_distance?.value) {
        var locationData = {}
        if (justAskResponse?.project_location_display) {
            locationData = {
                location: justAskResponse?.project_location_display,
                latitude: justAskResponse?.query?.project_latitude?.value,
                longitude: justAskResponse?.query?.project_longitude?.value,
                radius: justAskResponse?.query?.project_distance?.value,
                active: true,
                keyname: "location"
            }
        } else if (justAskResponse?.project_current_location) {
            locationData = {
                location: 'Current Location',
                latitude: justAskResponse?.query?.project_latitude?.value,
                longitude: justAskResponse?.query?.project_longitude?.value,
                radius: justAskResponse?.query?.project_distance?.value,
                active: true,
                keyname: "location"
            }
        } else {
            locationData = {
                location: 'Dropped pin',
                latitude: justAskResponse?.query?.project_latitude?.value,
                longitude: justAskResponse?.query?.project_longitude?.value,
                radius: justAskResponse?.query?.project_distance?.value,
                active: true,
                keyname: "location"
            }
        }
        dispatch(updateAdvanceLocation(locationData))
    }
}

const initializeCompanylocationFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_distance?.value) {
        var locationData = {}
        if (justAskResponse?.company_location_display) {
            locationData = {
                location: justAskResponse?.company_location_display,
                latitude: justAskResponse?.query?.company_latitude?.value,
                longitude: justAskResponse?.query?.company_longitude?.value,
                radius: justAskResponse?.query?.company_distance?.value,
                active: true,
                keyname: "companyLocation"
            }
        } else if (justAskResponse?.company_current_location) {
            locationData = {
                location: 'Current Location',
                latitude: justAskResponse?.query?.company_latitude?.value,
                longitude: justAskResponse?.query?.company_longitude?.value,
                radius: justAskResponse?.query?.company_distance?.value,
                active: true,
                keyname: "companyLocation"
            }
        } else {
            locationData = {
                location: 'Dropped pin',
                latitude: justAskResponse?.query?.company_latitude?.value,
                longitude: justAskResponse?.query?.company_longitude?.value,
                radius: justAskResponse?.query?.company_distance?.value,
                active: true,
                keyname: "companyLocation"
            }
        }
        dispatch(updateAdvanceLocation(locationData))
    }
}
const initializeProjectFramework = (justAskResponse, searchPersist, dispatch) => {
    if (justAskResponse?.query?.project_framework) {
        let frameworkArray = []
        searchPersist?.frameworkList.filter(item => justAskResponse?.query?.project_framework?.value.includes(item.id)).map(item =>
            frameworkArray.push({ id: item.id, name: item.description }))
        let frameworkdata = {
            value: justAskResponse?.query?.project_framework?.value,
            dataObj: frameworkArray,
            active: true,
            keyname: "projectFramework"
        }
        dispatch(updateProjectFramework(frameworkdata))
    }
}

const initializeProjectTitle = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_title) {
        let payload1 = {
            value: justAskResponse?.query?.project_title.value,
            dataObj: justAskResponse?.query?.project_title.value,
            active: justAskResponse?.query?.project_title.value !== '' ? true : false,
            keyname: "projectTitle"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializeProjectScheme = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_scheme) {
        let payload1 = {
            value: justAskResponse?.query?.project_scheme?.value,
            dataObj: justAskResponse?.query?.project_scheme?.value,
            active: justAskResponse?.query?.project_scheme?.value !== '' ? true : false,
            keyname: "projectScheme"
        }
        dispatch(updateFilterText(payload1))
    }
}
const initializeCompanyInfo = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_text) {
        let payload1 = {
            value: justAskResponse?.query?.company_text?.value,
            dataObj: justAskResponse?.query?.company_text?.value,
            active: justAskResponse?.query?.company_text?.value !== '' ? true : false,
            keyname: "companyInformation"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializeCompanyName = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_name) {
        let payload1 = {
            value: justAskResponse?.query?.company_name?.value,
            dataObj: justAskResponse?.query?.company_name?.value,
            active: justAskResponse?.query?.company_name?.value !== '' ? true : false,
            keyname: "companyName"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializeCompanyId = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_id) {
        let payload1 = {
            value: justAskResponse?.query?.company_id?.value,
            dataObj: justAskResponse?.query?.company_id?.value,
            active: justAskResponse?.query?.company_id?.value !== '' ? true : false,
            keyname: "companyId"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializeCompanyTownCodeFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_town) {
        let payload = {
            value: justAskResponse?.query?.company_town?.value,
            dataObj: justAskResponse?.query?.company_town?.value,
            active: justAskResponse?.query?.company_town?.value.length > 0 ? true : false,
            keyname: "companyTown"
        }
        dispatch(updateFilterText(payload))
    }
}
const initializeCompanyPostCodeFilter = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.company_postcode) {
        let payload = {
            value: justAskResponse?.query?.company_postcode?.value,
            dataObj: justAskResponse?.query?.company_postcode?.value,
            active: justAskResponse?.query?.company_postcode?.value.length > 0 ? true : false,
            keyname: "companyPostcode"
        }
        dispatch(updateFilterText(payload))
    }
}

const initializeValue = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_value) {
        let fromValue = justAskResponse.query.project_value.value1 ? justAskResponse.query.project_value.value1 : justAskResponse?.query?.project_value?.operator === ">=" ? justAskResponse?.query?.project_value.value : null
        let toValue = justAskResponse.query.project_value.value2 ? justAskResponse.query.project_value.value2 : justAskResponse?.query?.project_value?.operator === "<=" ? justAskResponse?.query?.project_value.value : null
        let value1 = justAskResponse?.query?.project_value?.operator === ".." ? getDisplayProjectValue(justAskResponse.query.project_value.value1) : justAskResponse?.query?.project_value?.operator === "<=" ? "" : getDisplayProjectValue(justAskResponse?.query?.project_value.value)
        let value2 = justAskResponse?.query?.project_value?.operator === ".." ? getDisplayProjectValue(justAskResponse.query.project_value.value2) : justAskResponse?.query?.project_value?.operator === ">=" ? "" : getDisplayProjectValue(justAskResponse?.query?.project_value.value)
        let payload = {
            from: fromValue,
            to: toValue,
            displayFrom: value1,
            displayTo: value2,
            active: value1 || value2 ? true : false,
            keyname: "Value"
        }
        dispatch(updateValue(payload))
    }
}

const initializeCalenderStartDate = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_start) {
        if (justAskResponse?.query?.project_start?.operator === "..") {
            if (typeof justAskResponse?.query?.project_start.value1 === 'number') {
                let value1 = justAskResponse?.query?.project_start.value1 > 0 ? '+' + justAskResponse?.query?.project_start.value1.toString() : justAskResponse?.query?.project_start.value1.toString()
                let startConstruction1 = stateDefaultFilter.startConstruction.options.filter(item => item.value === value1).map(item => item.name)
                let value2 = justAskResponse?.query?.project_start.value2 > 0 ? '+' + justAskResponse?.query?.project_start.value2.toString() : justAskResponse?.query?.project_start.value2.toString()
                let startConstruction2 = stateDefaultFilter.startConstruction.options.filter(item => item.value === value2).map(item => item.name)
                dispatch(toggleStartConstructionFilter(true))
                dispatch(updateStartConstructionFilter([startConstruction1[0], startConstruction2[0]]))
            } else {
                let date = convertDate(justAskResponse?.query?.project_start.value1)
                let date1 = convertDate(justAskResponse?.query?.project_start.value2)
                dispatch(updateCalenderStartFrom(date))
                dispatch(toggleCalendarTimingFilter(true))
                dispatch(updateCalenderStartTo(date1))
            }
        }
        else if (justAskResponse?.query?.project_start?.operator === ">=") {
            let date = convertDate(justAskResponse?.query?.project_start.value)
            dispatch(updateCalenderStartFrom(date))
            dispatch(toggleCalendarTimingFilter(true))
        }
        else if (justAskResponse?.query?.project_start?.operator === "<=") {
            let date = convertDate(justAskResponse?.query?.project_start.value)
            dispatch(updateCalenderStartTo(date))
        }
    }
}

const initializeCalenderEndDate = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_finish) {
        if (justAskResponse?.query?.project_finish?.operator === "..") {
            if (typeof justAskResponse?.query?.project_finish.value1 === 'number') {
                let value1 = justAskResponse?.query?.project_finish.value1 > 0 ? '+' + justAskResponse?.query?.project_finish.value1.toString() : justAskResponse?.query?.project_finish.value1.toString()
                let endConstruction1 = stateDefaultFilter.startConstruction.options.filter(item => item.value === value1).map(item => item.name)
                let value2 = justAskResponse?.query?.project_finish.value2 > 0 ? '+' + justAskResponse?.query?.project_finish.value2.toString() : justAskResponse?.query?.project_finish.value2.toString()
                let endConstruction2 = stateDefaultFilter.startConstruction.options.filter(item => item.value === value2).map(item => item.name)
                dispatch(toggleEndConstructionFilter(true))
                dispatch(updateEndConstructionFilter([endConstruction1[0], endConstruction2[0]]))
            } else {
                let date = convertDate(justAskResponse?.query?.project_finish.value1)
                let date1 = convertDate(justAskResponse?.query?.project_finish.value2)
                dispatch(updateCalenderEndFrom(date))
                dispatch(toggleCalendarTimingFilter(true))
                dispatch(updateCalenderEndTo(date1))
            }
        }
        else if (justAskResponse?.query?.project_finish?.operator === "<=") {
            let date = convertDate(justAskResponse?.query?.project_finish.value)
            dispatch(updateCalenderEndFrom(date))
        }
        else if (justAskResponse?.query?.project_finish?.operator === ">=") {
            let date = convertDate(justAskResponse?.query?.project_finish.value)
            dispatch(updateCalenderEndTo(date))
        }
    }
}

const initializeUpdated = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_is_new || justAskResponse?.query?.project_last_published) {
        if (justAskResponse?.query?.project_is_new?.value === undefined) {
            dispatch(toggleFilterActiveState({ key: "updated", value: true }))
            dispatch(toggleFilterActiveState({ key: "new", value: true }))
            dispatch(toggleNewUpdatedFilter(true))
            dispatch(toggleLastUpdatedFilter(true))
            let msg = "in the last" + getRelativeUpdatedMessage(justAskResponse?.query?.project_last_published?.value1)
            dispatch(updateNewPublishFilter(msg));
            dispatch(updateLastUpdatedFilter(msg));
        } else if (justAskResponse?.query?.project_is_new?.value === true) {
            dispatch(toggleFilterActiveState({ key: "new", value: true }))
            dispatch(toggleNewUpdatedFilter(true))
            let msg = "in the last" + getRelativeUpdatedMessage(justAskResponse?.query?.project_last_published?.value1)
            dispatch(updateNewPublishFilter(msg));
        } else {
            dispatch(toggleFilterActiveState({ key: "updated", value: true }))
            dispatch(toggleLastUpdatedFilter(true))
            let msg = "in the last" + getRelativeUpdatedMessage(justAskResponse?.query?.project_last_published?.value1)
            dispatch(updateLastUpdatedFilter(msg));
        }
    }
}

const initializeProjectRegion = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_geocode) {
        let regions = updateSaveSearchItemsFilter({ regions: stateDefaultFilter.regions }, { regions: justAskResponse?.query?.project_geocode.value }, 'regions', 'counties')
        dispatch(updateSavedSearchCheckboxFilters({ value: regions, title: regions.title }))

    }
}

const initializeCompanyRegion = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.company_geocode) {
        let companyRegions = updateSaveSearchItemsFilter({ companyRegions: stateDefaultFilter.companyRegions }, { companyRegions: justAskResponse?.query?.company_geocode.value }, 'companyRegions', 'counties')
        dispatch(updateSavedSearchCheckboxFilters({ value: companyRegions, title: companyRegions.title }))
    }
}

const initializeCategories = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_categories || justAskResponse?.query?.project_primary_category) {
        let categories = justAskResponse?.query?.project_categories ? justAskResponse?.query?.project_categories : justAskResponse?.query?.project_primary_category;
        _.map(categories, (i) => {
            if (i.operator === "=") {
                let primaryCategoriesOnly = Boolean(justAskResponse?.query?.project_primary_category)
                dispatch(updatePrimaryCategoriesOnlyFlag(primaryCategoriesOnly))
                let includeCategories = updateSaveSearchItemsFilter(stateDefaultFilter, { includeCategories: i.value }, 'includeCategories', 'subCategories')
                dispatch(updateSavedSearchCheckboxFilters({ value: includeCategories, title: includeCategories.title }))
            } else {
                let primaryCategoriesOnly = Boolean(justAskResponse?.query?.project_primary_category)
                dispatch(updatePrimaryCategoriesOnlyFlag(primaryCategoriesOnly))
                let excludeCategories = updateSaveSearchItemsFilter(stateDefaultFilter, { excludeCategories: i.value }, 'excludeCategories', 'subCategories')
                dispatch(updateSavedSearchCheckboxFilters({ value: excludeCategories, title: excludeCategories.title }))
            }
        })
    }
}

const initializeDevTypes = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_development_types) {
        _.map(justAskResponse?.query?.project_development_types, (i) => {
            if (i.operator === "=") {
                let includeDevelopmentTypes = updateSaveSearchItemsFilter(stateDefaultFilter, { includeDevelopmentTypes: i.value }, 'includeDevelopmentTypes', null)
                includeDevelopmentTypes.active = true
                dispatch(updateSavedSearchCheckboxFilters({ value: includeDevelopmentTypes, title: includeDevelopmentTypes.title }))
            } else {
                let excludeDevelopmentTypes = updateSaveSearchItemsFilter(stateDefaultFilter, { excludeDevelopmentTypes: i.value }, 'excludeDevelopmentTypes', null)
                excludeDevelopmentTypes.active = true
                dispatch(updateSavedSearchCheckboxFilters({ value: excludeDevelopmentTypes, title: excludeDevelopmentTypes.title }))
            }
        })
    }
}

const initializeStages = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_stages) {
        _.map(justAskResponse?.query?.project_stages, (i) => {
            if (i.operator === "=") {
                let stagesArr = i.value
                let includeStages = updateSaveSearchArrayFilter(stateDefaultFilter, { includeStages: stagesArr }, 'includeStages')
                includeStages = updateParentStages(includeStages, stagesArr)
                dispatch(updateSavedSearchCheckboxFilters({ value: includeStages, title: includeStages.title }))
            } else if (i.operator === "!=") {
                let exStages = [19, 20]
                let stagesArr = i.value
                if (exStages.every(item => !stagesArr.includes(item))) {
                    let excludeStages = _.cloneDeep(stateDefaultFilter.excludeStages)
                    excludeStages.active = true
                    excludeStages.toggleActive = true
                    dispatch(updateSavedSearchCheckboxFilters({ value: excludeStages, title: excludeStages.title }))
                }
                exStages = i.value.filter(stage => !exStages.includes(stage))
                if (exStages.length > 0) {
                    let advanceExcludeStages = updateSaveSearchArrayFilter(stateDefaultFilter, { advanceExcludeStages: exStages }, 'advanceExcludeStages')
                    advanceExcludeStages = updateParentStages(advanceExcludeStages, exStages)
                    dispatch(updateSavedSearchCheckboxFilters({ value: advanceExcludeStages, title: 'advanceExcludeStages' }))
                }
            } else {
                dispatch(updateExcludeStageFilter({ name: 'excludeStages', active: true }))
            }
        })
    } else {
        dispatch(updateExcludeStageFilter({ name: 'excludeStages', active: true }))
    }
}

const initializeBuildPhase = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_build_phase) {
        updateBuildPhase(justAskResponse.query.project_build_phase.value, dispatch)
        updateCompletedProject(justAskResponse.query.project_build_phase.value, dispatch)
    }
}

const initializeBrandInformation = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_brands) {
        let payload1 = {
            value: justAskResponse?.query?.project_brands?.value,
            dataObj: justAskResponse?.query?.project_brands?.value,
            active: justAskResponse?.query?.project_brands.value?.length > 0 ? true : false,
            keyname: "brands"
        }
        dispatch(updateFilterText(payload1))
    }
}

const initializeMaterials = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_materials) {
        let materials = updateFilterRecursively(stateDefaultFilter, { material: justAskResponse?.query?.project_materials.value }, 'materials')
        dispatch(updateSavedSearchCheckboxFilters({ value: materials, title: materials.title }))
    }
}

const initializeMeasures = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_units) {
        updateMeasure('noOfUnits', justAskResponse?.query?.project_units, dispatch)
    }
    if (justAskResponse?.query?.project_storeys_above) {
        updateMeasure('aboveGroundStoreys', justAskResponse?.query?.project_storeys_above, dispatch)
    }
    if (justAskResponse?.query?.project_storeys_below) {
        updateMeasure('belowGroundStoreys', justAskResponse?.query?.project_storeys_below, dispatch)
    }
    if (justAskResponse?.query?.project_site_area) {
        updateMeasure('siteArea', justAskResponse?.query?.project_site_area, dispatch)
    }
    if (justAskResponse?.query?.project_floor_area) {
        updateMeasure('groundFloorArea', justAskResponse?.query?.project_floor_area, dispatch)
    }
    if (justAskResponse?.query?.project_plan_area) {
        updateMeasure('totalFloorArea', justAskResponse?.query?.project_plan_area, dispatch)
    }
    if (justAskResponse?.query?.project_parking) {
        updateMeasure('parkingSpaces', justAskResponse?.query?.project_parking, dispatch)
    }
}

const initializeProjectUsuage = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_usage) {
        if (justAskResponse?.query?.project_usage?.value?.length > 0) {
            _.map(justAskResponse?.query?.project_usage?.value, (i) => {
                let payload = {
                    isSelected: true,
                    value: i,
                }
                dispatch(updateCategoryUsageTypeFilter(payload))
            })
        }

    }
}
const initializeCategoryLandType = (justAskResponse, dispatch) => {
    if (justAskResponse?.query?.project_land_type) {
        if (justAskResponse?.query?.project_land_type?.value?.length > 0) {
            _.map(justAskResponse?.query?.project_land_type?.value, (i) => {
                let payload = {
                    isSelected: true,
                    value: i,
                }
                dispatch(updateCategoryLandTypeFilter(payload))
            })
        }

    }

}

const initializeRoles = (justAskResponse, stateDefaultFilter, dispatch) => {
    if (justAskResponse?.query?.project_roles && !justAskResponse?.query?.role_updated) {
        _.map(justAskResponse?.query?.project_roles, (i) => {
            if (i.operator === "=") {
                let includeRoles = updateSaveSearchRoleFilter(stateDefaultFilter, { includeRoles: i.value }, 'includeRoles')
                dispatch(updateSavedSearchCheckboxFilters({ value: includeRoles, title: includeRoles.title }))
            } else {
                let excludeRoles = updateSaveSearchRoleFilter(stateDefaultFilter, { excludeRoles: i.value }, 'excludeRoles')
                dispatch(updateSavedSearchCheckboxFilters({ value: excludeRoles, title: excludeRoles.title }))
            }
        })
    }

    if (justAskResponse?.query?.role_code && justAskResponse?.query?.role_updated) {
        _.map(justAskResponse?.query?.role_code, (i) => {
            if (i.operator === "=") {
                let includeRoles = updateSaveSearchRoleFilter(stateDefaultFilter, { includeRoles: i.value }, 'includeRoles')
                dispatch(updateSavedSearchCheckboxFilters({ value: includeRoles, title: includeRoles.title }))
            } else {
                let excludeRoles = updateSaveSearchRoleFilter(stateDefaultFilter, { excludeRoles: i.value }, 'excludeRoles')
                dispatch(updateSavedSearchCheckboxFilters({ value: excludeRoles, title: excludeRoles.title }))
            }
        })
        dispatch(toggleFilterActiveState({ key: "roleAdded", value: true }))
        dispatch(toggleRoleAddedFilter(true))
        let msg = getRelativeTimingMessage(justAskResponse.query.role_updated?.value1)
        dispatch(updateRoleAddedFilter(msg.trim()))
    }
}

function initializeSaveSearchContext(dispatch, justAskResponse) {
    dispatch(updateSelectedSearchType(justAskResponse?.object_type))
    dispatch(updateAppliedContext(justAskResponse?.object_type))
    dispatch(updatedSelectedSavedSearch(justAskResponse))
    dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: justAskResponse?.object_type }))
    dispatch(updateSearchLoader(true))
    dispatch(updateNoResult(true))
    dispatch(updateMaxApiCalls(0))
    dispatch(updateArchiveSearchList(true))
    dispatch(updateIdsForShowRelatedEntities([]));
    dispatch(updateSelectedSelectorControlValue(0));
    dispatch(rolesForProject([]));
    dispatch(updatePeopleProjectsList([]));
    dispatch(updateProjectsForCompanyId([]));
    dispatch(updateShowHierarchyForJustAsk({ showProjectHierarchy: false, showCompanyHierarchy: false }))
    dispatch(updateObjectDataJA(undefined))
    dispatch(updateNavigationBackJA(''))
    dispatch(updateShowSelectedCompaniesJA(false));
    dispatch(updateShowSelectedProjectsJA(false));
    dispatch(updateIsShowProjectsOrCompanySelected(false))
    dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }));
    dispatch(updateObjectId(undefined));
}

function updateCompletedProject(buildPhaseValue, dispatch) {
    if (buildPhaseValue.includes("completed")) {
        dispatch(updateShowCompletedChecked(true))
    }
}

function updateBuildPhase(buildPhaseValue, dispatch) {
    if (!buildPhaseValue.includes("no_timing")) {
        let sliderValue = isExactMatch(['not_started'], buildPhaseValue) ? [0, 1]
            : isExactMatch(['on_site'], buildPhaseValue) ? [2, 3]
                : isExactMatch(['enabling'], buildPhaseValue) ? [1, 2]
                    : isExactMatch(['enabling', 'on_site'], buildPhaseValue) ? [1, 3]
                        : isExactMatch(['not_started', 'enabling'], buildPhaseValue) ? [0, 2]
                            : [0, 3]
        dispatch(toggleBuildPhaseFilter(true))
        dispatch(updateBuildPhaseValueReducer({ activePhase: buildPhaseValue, sliderValue: sliderValue }))
    }
}

// Function to check if arr1 is an exact match in arr2
const isExactMatch = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    return arr1.every((element, index) => element === arr2[index]);
};

function updateSaveSearchItemsFilter(stateDefaultFilter, savedSearchProfile, filterName, child) {
    let filter = _.cloneDeep(stateDefaultFilter[filterName])
    let filteredList = []
    filter.options.map(function (option) {
        let parsedOptionValue = ''
        if ((filterName === "regions" || filterName === "companyRegions")) {
            parsedOptionValue = option.value
        } else {
            parsedOptionValue = parseInt(option.value)
        }
        if ((filterName === "regions" || filterName === "companyRegions") && savedSearchProfile[filterName].includes(parsedOptionValue)) {
            option.active = true
        }
        if ((filterName !== "regions" || filterName !== "companyRegions") && savedSearchProfile[filterName].includes(parsedOptionValue)) {
            option.active = true
        }
        if (child != null) {
            let updatedChildren = []
            let activeChilds = []
            option[child].map(function (item, index) {
                let parsedItemValue = ''
                if ((filterName === "regions" || filterName === "companyRegions")) {
                    parsedItemValue = item.value.split(",")
                } else {
                    parsedItemValue = parseInt(item.value)
                }
                if ((filterName === "regions" || filterName === "companyRegions")) {
                    parsedItemValue.map(function (parsedItem, index) {
                        if (savedSearchProfile[filterName].includes(parsedItem)) {
                            item.active = true
                        }
                    })

                } else {
                    if (savedSearchProfile[filterName].includes(parsedItemValue)) {
                        item.active = true
                    }
                }
                activeChilds.push(item.active)
                updatedChildren.push(item)
            })
            if (activeChilds.every(element => element === true) && option.display) {
                option.active = true
                option.isChildSelected = false
            } else if (activeChilds.every(element => element === false)) {
                option.isChildSelected = false
            } else {
                option.active = false
                option.isChildSelected = true
            }
            option[child] = updatedChildren
        }
        filteredList.push(option)
    })
    let itemsFilter = {
        ...filter,
        options: filteredList,
        active: true
    }
    if ((filterName !== "regions" || filterName !== "companyRegions")) {
        itemsFilter.isAllSelected = filteredList.every(child => child.active)
    }
    return itemsFilter
}

function updateFilterRecursively(stateDefaultFilter, savedSearchProfile, filterName) {
    let filter = _.cloneDeep(stateDefaultFilter[filterName])
    let stages = savedSearchProfile.material;
    let filteredList = []
    filter.options.map(fLevel => {
        if (stages.includes(fLevel.value)) {
            fLevel.active = true
            fLevel.subParent.map(sLevel => {
                sLevel.active = true;
                sLevel.children.map(tLevel => {
                    tLevel.active = true
                })
            })
        } else {
            fLevel.subParent.map(sLevel => {
                if (stages.includes(sLevel.value)) {
                    sLevel.active = true
                    sLevel.children.map(tLevel => {
                        tLevel.active = true
                    })
                } else {
                    sLevel.children.map(tLevel => {
                        if (stages.includes(tLevel.value)) {
                            tLevel.active = true
                        }
                    })
                }
                if (sLevel.children.every(child => child.active)) {
                    sLevel.active = true
                    sLevel.isChildSelected = false
                } else if (sLevel.children.every(child => !child.active)) {
                    sLevel.active = false
                    sLevel.isChildSelected = false
                } else {
                    sLevel.active = false
                    sLevel.isChildSelected = true
                }
            })
        }
        if (fLevel.subParent.every(sLevel => sLevel.active)) {
            fLevel.active = true
            fLevel.isChildSelected = false
        } else if (fLevel.subParent.some(sLevel => sLevel.isChildSelected)) {
            fLevel.active = false
            fLevel.isChildSelected = true
        } else if (fLevel.subParent.every(sLevel => !sLevel.active)) {
            fLevel.active = false
            fLevel.isChildSelected = false
        } else {
            fLevel.active = false
            fLevel.isChildSelected = true
        }
        filteredList.push(fLevel)
    })
    return {
        ...filter,
        active: true,
        isAllSelected: filteredList.every(child => child.active)
    }
}

function updateSaveSearchRoleFilter(stateDefaultFilter, savedSearchProfile, filterName) {
    let filter = stateDefaultFilter[filterName]
    let rolesFilter = {
        ...filter,
        options: filter?.options?.map(option => (savedSearchProfile[filterName].includes(parseInt(option.value))) ? { ...option, active: true } : { ...option, active: false }),
        active: true
    }
    rolesFilter.isAllSelected = rolesFilter?.options?.every(child => child.active)
    return rolesFilter
}

function updateSaveSearchArrayFilter(stateDefaultFilter, savedSearchProfile, filterName) {
    let filter = _.cloneDeep(stateDefaultFilter[filterName])
    return {
        ...filter,
        options: filter.options.map(option => applyStageFilter(option, savedSearchProfile[filterName])),
        active: true
    }
}

function applyStageFilter(option, stages) {
    if (option.children) {
        for (let subStage = 0; subStage < option.children.length; subStage++) {
            applyStageFilter(option.children[subStage], stages);
        }
    }
    let valArr = Array.isArray(option.value) ? option.value : [option.value];
    if (stages.filter(x => valArr.includes(x)).length == valArr.length) {
        option.active = true
    } else {
        option.active = false
    }
    return option
}

function updateParentStages(stageFilter, stagesArr) {
    stageFilter.options.map(parentStage => {
        if (stagesArr.includes(parentStage.value)) {
            parentStage.active = true
            parentStage.children.map(tLevel => {
                tLevel.active = true
            }
            )
        } else {
            parentStage.children.map(tLevel => {
                if (stagesArr.includes(tLevel.value)) {
                    tLevel.active = true
                }
            }
            )
        }
        if (parentStage.children.every(child => child.active)) {
            parentStage.active = true
            parentStage.isChildSelected = false
        } else if (parentStage.children.every(child => !child.active)) {
            parentStage.active = false
            parentStage.isChildSelected = false
        } else {
            parentStage.active = false
            parentStage.isChildSelected = true
        }
    })
    stageFilter.isAllSelected = stageFilter.options.every(child => child.active)
    return stageFilter
}

function updateMeasure(filter, savedSearchProfile, dispatch) {
    let from = "";
    let to = ""
    if (savedSearchProfile.operator && (savedSearchProfile.operator === "<=" || savedSearchProfile.operator === "<")) {
        to = savedSearchProfile.value
    } else if (savedSearchProfile.operator && (savedSearchProfile.operator === ">=" || savedSearchProfile.operator === ">")) {
        from = savedSearchProfile.value
    } else if (savedSearchProfile.operator && savedSearchProfile.operator === "..") {
        from = savedSearchProfile.value1
        to = savedSearchProfile.value2
    }
    let payload = {
        [filter]: {
            from: from,
            to: to,
            active: from || to ? true : false
        }
    }
    dispatch(updateMeasures(payload))
}