import React from "react";
import AlertMessage from "../../components/alerts";
import LeftPanel from "../../components/leftpanel";
import TopHeader from "../../components/header";
import ExportDetail from "../../components/export";

const ExportData = () => {
    return (
        <div id="container">
            <TopHeader />
            <div className="sidebar-mini">
                <LeftPanel />
                <div id="message-container">
                    <AlertMessage />
                </div>
                <div className="main-content">
                    <div className="export-page pt-3 main-container container">
                        <ExportDetail />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExportData;