import React from 'react';
import { useLocation } from 'react-router-dom';
import Project from './project/project';
import Company from './company/company';
import Contact from './person/main';

const Details = () => {
	const location = useLocation().pathname;

	return (
		<div className="details">
			{
				location.split('/')[2] === 'project' ? <Project /> : location.split('/')[2] === 'company' ? <Company /> : <Contact />
			}
		</div>
	);
}

export default Details;