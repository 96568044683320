import _ from 'lodash'
import moment from 'moment'
import { setAlert } from '../redux/features/user/userslice'
import { ABI_URLS, CUSTOMER_TYPE, LOCAL_DOMAIN, MEASUREMENT_ID_PROD, MEASUREMENT_ID_STAGING, MEASURE_ID_PROD, MEASURE_ID_STAGING, PRODURL, PROD_DOMAIN, PROJECT_LOCATOR_URLS, STAGINGURl, STREAM_ID_PROD, STREAM_ID_STAGING, STREAM_NAME_PROD, STREAM_NAME_STAGING, UAT_DOMAIN } from './settings'


export function getSectorNo(domain) {
	let hostname = domain.hostname
	let appDetails = {}
	if (ABI_URLS.includes(hostname)) {
		appDetails = { sectorNo: 1, sectorName: "Construction", productName: "Barbour ABI" }
	}
	return appDetails
}

export function getCookies() {
	var cookies = {};
	for (let cookie of document.cookie.split(';')) {
		let [name, value] = cookie.split("=");
		cookies[name.trim()] = decodeURIComponent(value);
	}
	return cookies;
}

export function isValidText(text) {
	return (text && text !== ' ' && text !== '-')
}

export function checkIfProjectIsNew(lastRelease, firstPublished) {
	return lastRelease && firstPublished && moment().diff(lastRelease, 'days') <= 7 &&
		moment(lastRelease).isSame(firstPublished)
}

export function getFormatedDate(lastReleaseDate, dateFormatStr) {
	if (!lastReleaseDate) {
		return ""
	}
	else {
		var nowdate = moment(lastReleaseDate).format(dateFormatStr)
		/* Fix firefox moment.js issue */
		var newdate = moment(nowdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
		return (newdate).toLowerCase() === "invalid date" ? lastReleaseDate : newdate
	}
}

export function getProjectAddress(entityDetails) {
	var address = entityDetails.project_site1 && entityDetails.project_site1 !== "-" && entityDetails.project_site1 !== " " ? entityDetails.project_site1 : ""
	address += entityDetails.project_site2 && entityDetails.project_site2 !== "-" && entityDetails.project_site2 !== " " ? ", " + entityDetails.project_site2 : ""
	address += entityDetails.project_site3 && entityDetails.project_site3 !== "-" && entityDetails.project_site3 !== " " ? ", " + entityDetails.project_site3 : ""
	address += entityDetails.project_site4 && entityDetails.project_site4 !== "-" && entityDetails.project_site4 !== " " ? ", " + entityDetails.project_site4 : ""
	address += entityDetails.project_postcode && entityDetails.project_postcode !== "-" && entityDetails.project_postcode !== " " ? ", " + entityDetails.project_postcode : ""
	return address.length > 0 ? address : ""
}

export function messageAlert(dispatch, response, type) {
	dispatch(setAlert({
		visible: true,
		message: response,
		type: type,
		keyid: Math.random(),
		autoHide: true
	}))
}

export function getIntercomId(domain) {
	let hostname = domain.hostname
	let intercomId

	if (PROD_DOMAIN.includes(hostname)) {
		intercomId = "rtyout3c"
	}
	if (UAT_DOMAIN.includes(hostname)) {
		intercomId = "gi8ucum3"
	}
	return intercomId
}

export const filterTree = (node, filter, matcher = defaultMatcher) => {
	// If im an exact match then all my children get to stay
	if (matcher(filter, node) || !node.children) { return node; }
	// If not then only keep the ones that match or have matching descendants
	const filtered = node.children
		.filter(child => findNode(child, filter, matcher))
		.map(child => filterTree(child, filter, matcher));
	return Object.assign({}, node, { children: filtered });
};

// Helper functions for filtering
const defaultMatcher = (filterText, node) => {
	return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};

const defaultTag = (filterText, node) => {
	return node.tag_name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};


const findNode = (node, filter, matcher) => {
	return matcher(filter, node) || // i match
		(node.children && // or i have decendents and one of them match
			node.children.length &&
			!!node.children.find(child => findNode(child, filter, matcher)));
};

export const filterMaterials = (node, filter, matcher = defaultTextMatcher) => {
	// If im an exact match then all my children get to stay
	if (matcher(filter, node) || !node.children) { return node; }
	// If not then only keep the ones that match or have matching descendants
	const filtered = node.children
		.filter(child => matcher(filter, child))
		.map(child => filterTree(child, filter, matcher));
	return Object.assign({}, node, { children: filtered });
};

export const filterTags = (node, filter, matcher = defaultTagMatcher) => {
	// If im an exact match then all my children get to stay
	if (matcher(filter, node) || !node.children) { return node; }
	// If not then only keep the ones that match or have matching descendants
	const filtered = node.children
		.filter(child => matcher(filter, child))
		.map(child => filterTree(child, filter, matcher));
	return Object.assign({}, node, { children: filtered });
};

export const expandFilteredMaterials = (node, filter, matcher = defaultMatcher) => {
	let children = node.children;
	if (!children || children.length === 0) {
		return Object.assign({}, node, { toggled: false });
	}
	const childrenWithMatches = node.children.filter(child => matcher(filter, child));
	const shouldExpand = childrenWithMatches.length > 0;
	// If im going to expand, go through all the matches and see if thier children need to expand
	if (shouldExpand) {
		children = childrenWithMatches.map(child => {
			return expandFilteredMaterials(child, filter, matcher);
		});
	}
	return Object.assign({}, node, {
		children: children,
		toggled: shouldExpand
	});
};

export const expandFilteredTags = (node, filter, matcher = defaultTag) => {
	let children = node.children;
	if (!children || children.length === 0) {
		return Object.assign({}, node, { toggled: false });
	}
	const childrenWithMatches = node.children.filter(child => matcher(filter, child));
	const shouldExpand = childrenWithMatches.length > 0;
	// If im going to expand, go through all the matches and see if thier children need to expand
	if (shouldExpand) {
		children = childrenWithMatches.map(child => {
			return expandFilteredMaterials(child, filter, matcher);
		});
	}
	return Object.assign({}, node, {
		children: children,
		toggled: shouldExpand
	});
};

// Helper functions for filtering
const defaultTextMatcher = (filterText, node) => {
	return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
		(node.value && node.value !== '' ?
			node.value.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 : false);
};

const defaultTagMatcher = (filterText, node) => {
	return node.tag_name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
		(node.value && node.value !== '' ?
			node.value.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 : false);
};

export function getMaterialsHeirarchy(materials) {
	const material_list = materials && materials.split(";")
	let map_for_material = []
	for (var material in material_list) {
		if (material_list && material_list[material].indexOf('(') !== -1) {
			map_for_material.push({
				'name': material_list[material].substring(0, material_list[material].indexOf('(')).trim(),
				'value': material_list[material].substring(material_list[material].indexOf('(')).replace(/^\((.+)\)$/, '$1')
			})
		} else {
			map_for_material.push({ 'name': material_list[material].trim(), 'value': '' })
		}
	}
	return map_for_material
}

function getWeekDiff(dt2, dt1) {
	let diff = (dt2 - dt1) / 1000
	diff /= (60 * 60 * 24 * 7)
	return Math.abs(Math.round(diff))
}

export function groupNotesbyUpdatedDate(notes, prevNextList) {
	let notesList = {}
	let notesArray = []
	let sortBy = prevNextList === 'myLeadsUpdates' ? 'updated_on' : prevNextList === 'myLeadsHistory' ? 'activity_date' : 'updated_date'
	let notesSortOrder = notes && [...notes].sort((a, b) => b[sortBy] - a[sortBy])

	notesSortOrder && notesSortOrder.map(function (note, index) {
		if (note) {
			if (!note?.archive) {
				let updatedDateGroup = getHumanizeDateForNotes(note[sortBy])
				if (Object.keys(notesList).includes(updatedDateGroup)) {
					notesArray = notesList[updatedDateGroup]
					notesArray.push(note)
					notesList[updatedDateGroup] = notesArray
				} else {
					notesArray = []
					notesArray.push(note)
					notesList[updatedDateGroup] = notesArray
				}
			}
		}
	})
	return notesList
}

export function getHumanizeDateForNotes(lastReleaseDate) {
	if (lastReleaseDate) {
		var relativeTime = moment(lastReleaseDate)
		var lastDate = relativeTime.calendar(null, {
			sameDay: '[Today]',
			lastDay: '[Yesterday]',
			lastWeek: '[Last week]',
			sameElse: '[Older]'
		})
		var daysAgo = relativeTime.fromNow()
		var days = ["2 days ago", "3 days ago", "4 days ago", "5 days ago", "6 days ago"]
		if (days.includes(daysAgo) && moment(lastReleaseDate).isSame(new Date(), 'week') === true) {
			lastDate = daysAgo
		}
		if (!(['Today', 'Yesterday', 'Last week', '2 days ago', '3 days ago', '4 days ago', '5 days ago', '6 days ago'].some(el => lastDate.includes(el)))) {
			let currentTimeStamp = new Date().getTime()
			let diffWeek = getWeekDiff(currentTimeStamp, lastReleaseDate)

			if (diffWeek === 1) {
				lastDate = 'Last week'
			}
			if (diffWeek === 2) {
				lastDate = 'Last two weeks'
			} else if (diffWeek === 3) {
				lastDate = 'Last three weeks'
			} else if (diffWeek === 4 || diffWeek === 5 || diffWeek === 6 || diffWeek === 7) {
				lastDate = 'Last month'
			}
		}
		return lastDate
	} else {
		return "";
	}
}

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
	let children = node.children;
	if (!children || children.length === 0) {
		return Object.assign({}, node, { toggled: false });
	}
	const childrenWithMatches = node.children.filter(child => findNode(child, filter, matcher));
	const shouldExpand = childrenWithMatches.length > 0;
	// If im going to expand, go through all the matches and see if thier children need to expand
	if (shouldExpand) {
		children = childrenWithMatches.map(child => {
			return expandFilteredNodes(child, filter, matcher);
		});
	}
	return Object.assign({}, node, {
		children: children,
		toggled: shouldExpand
	});
};

export function getFormatedCopyrightDate(lastReleaseDate, dateFormatStr) {
	if (!lastReleaseDate) {
		return moment().format(dateFormatStr)
	}
	else {
		var nowdate = moment(lastReleaseDate).format(dateFormatStr)
		/* Fix firefox moment.js issue */
		var newdate = moment(nowdate, "DD-MMM-YYYY").format("DD-MMM-YYYY");
		return (newdate).toLowerCase() === "invalid date" ? lastReleaseDate : newdate
	}
}
export function getInitials(string) {
	var names = string.split(' '),
		initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

export function createTeamList(teamList) {
	let team,
		options = [];
	for (team in teamList) {
		options.push(teamList[team]);
	}
	options.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
	return options;
}

export function getActivitiesShareDetails(sharedArray, teamList, usersList, currentUser) {
	let modifySharedArray = []
	sharedArray.map(item => {
		let sharedObj = {}
		let sharedByObj = _.cloneDeep(usersList && Object.keys(usersList).length > 0 && usersList[item.shared_by])
		sharedByObj['id'] = sharedByObj.user_no
		sharedObj.sharedBy = sharedByObj
		sharedObj.sharedOn = moment(item.shared_on).valueOf()
		sharedObj.sharedMsg = item.shared_message
		let sharedWith = []
		let isDelete = false
		isDelete = item.shared_by === currentUser.user.user_id ? true : false

		item?.shared_with.map(subItem => {
			if (subItem?.share_type === "user") {
				subItem?.user_id.map(id => {
					if (usersList && Object.keys(usersList).length > 0) {
						let sharedWithObj = {}
						sharedWithObj.name = usersList[id] ? usersList[id].first_name + " " + usersList[id].last_name : ""
						sharedWithObj.id = id
						sharedWithObj.type = "U"
						sharedWithObj.email = usersList[id] ? usersList[id].email : ""
						sharedWithObj.initials = usersList[id] ? usersList[id].initials : ""
						// sharedWithObj.initials = usersList[id] ? getInitials(usersList[id].first_name + " " + usersList[id].last_name) : ""
						sharedWithObj.sharedOn = sharedObj ? sharedObj.sharedOn : ''
						sharedWithObj.is_delete = isDelete
						sharedWith.push(sharedWithObj)
					}
				})
			} else if (subItem?.share_type === "team") {
				subItem?.team_id.map(id => {
					if (teamList && Object.keys(teamList).length > 0) {
						let sharedWithObj = {}
						sharedWithObj.name = teamList[id] && teamList[id].name
						sharedWithObj.id = id
						sharedWithObj.type = "T"
						sharedWithObj.email = teamList[id] ? teamList[id].email : ""
						sharedWithObj.initials = teamList[id] ? teamList[id].initials : ""
						// sharedWithObj.initials = teamList[id] ? getInitials(teamList[id].name) : ""
						sharedWithObj.sharedOn = sharedObj ? sharedObj.sharedOn : ''
						sharedWithObj.is_delete = isDelete
						sharedWith.push(sharedWithObj)
					}
				})
			} else if (subItem?.share_type === "company") {
				let sharedWithObj = {}
				sharedWithObj.name = "Everyone"
				sharedWithObj.type = subItem.share_type
				sharedWithObj.id = "company"
				sharedWithObj.sharedOn = sharedObj ? sharedObj.sharedOn : ''
				sharedWithObj.is_delete = isDelete
				sharedWith.push(sharedWithObj)
			}
			sharedObj.shareWith = sharedWith
		})
		sharedObj.shareWith = sharedWith
		if (sharedObj && sharedObj.sharedBy) {
			modifySharedArray.push(sharedObj)
		}
	})
	return modifySharedArray
}

export function modifyReciepientsResponse(data) {
	var user, licence, team,
		userList = data.users ? data.users.users ? data.users.users : [] : [],
		teamList = data.teams ? data.teams.teams ? data.teams.teams : [] : [],
		lisceneList = data.licences ? data.licences.licences ? data.licences.licences : [] : [],
		updatedUsers = {};
	for (user = 0; user < userList.length; user++) {
		let matchingTeam = []
		for (team = 0; team < teamList.length; team++) {
			if (teamList[team].user_ids && teamList[team]['user_ids'].includes(userList[user]['user_id'])) {
				matchingTeam.push({
					created_on: teamList[team].created_on,
					name: teamList[team].team_name,
					team_no: teamList[team].team_no,
					users: teamList[team].user,
				})
			}
		}
		userList[user]['teams'] = matchingTeam

		let matching_liscene = [];

		for (licence = 0; licence < lisceneList.length; licence++) {
			if (userList[user]['licence_ids'] && userList[user]['licence_ids'].length > 0 && userList[user]['licence_ids'].includes(lisceneList[licence]['licence_id'])) {
				matching_liscene.push({
					license_no: lisceneList[licence].licence_id,
					license_name: lisceneList[licence].licence_name,
					valid_from: lisceneList[licence].valid_from,
					valid_to: lisceneList[licence].valid_to,
					order_no: lisceneList[licence].order_no
				})
			}
		}
		userList[user]['licences'] = matching_liscene

		let modifiedUser = {
			user_no: userList[user].user_id,
			username: userList[user].username,
			first_name: userList[user].first_name || "",
			last_name: userList[user].last_name || "",
			name: userList[user].first_name + userList[user].last_name,
			email: userList[user].email,
			job_title: userList[user].job_title,
			client_no: userList[user].client_no,
			is_developer_mode: userList[user].is_developer,
			license: userList[user].licences,
			teams: userList[user].teams
		}



		if (userList[user].mobile) {
			modifiedUser.mobile = userList[user].mobile

		}
		if (userList[user].phone) {
			modifiedUser.phone = userList[user].phone
		}

		if (modifiedUser.first_name && modifiedUser.last_name) {
			modifiedUser.initials = modifiedUser.first_name[0].toUpperCase() + modifiedUser.last_name[0].toUpperCase()
		}
		updatedUsers[modifiedUser.user_no] = modifiedUser
	}
	return updatedUsers;
}

export function getSharedListBySharedDate(shared) {

	let sharedList = {}
	let sharedArray = []
	let sharedSortOrder = shared && [...shared].sort((a, b) => b.sharedOn - a.sharedOn)
	sharedSortOrder.map(function (shared, index) {
		let sharedGroup = getHumanizeDateForNotes(shared.sharedOn)
		if (Object.keys(sharedList).includes(sharedGroup)) {
			sharedArray = sharedList[sharedGroup]
			sharedArray.push(shared)
			sharedList[sharedGroup] = sharedArray
		} else {
			sharedArray = []
			sharedArray.push(shared)
			sharedList[sharedGroup] = sharedArray
		}
	})
	return sharedList
}
export function getEventType(eventType) {
	let eventName = ""
	switch (eventType) {
		case 1:
			eventName = "favourite"
			break;
		case 2:
			eventName = "unfavourite"
			break;
		case 11:
			eventName = "read"
			break;
		case 20:
			eventName = "tag"
			break
		case 30:
			eventName = "share"
			break
		case 70:
			eventName = "notes"
			break
		case 31:
			eventName = "deleteShare"
			break
		case 71:
			eventName = "deleteNote"
			break
		case 72:
			eventName = "updateNote"
			break
		case 21:
			eventName = "deleteTag"
			break
		case 13:
			eventName = "previewed"
			break
		case 100:
			eventName = "mail"
			break
		case 110:
			eventName = "archived"
			break
		case 111:
			eventName = "unarchived"
			break
		default:
			break;
	}

	return eventName
}




export function modifyCollaboration(collabData, userList, teamList, tagsList) {
	let modifiedCollabData = []
	if (collabData && collabData.length > 0) {
		collabData.map(item => {
			let userObj = userList && userList[item.event_by]
			if (userObj) {
				let collabObject = {}
				collabObject['eventType'] = getEventType(item.event_type)
				collabObject['eventOn'] = item.event_on
				let userObj = userList[item.event_by]
				if (userObj) {
					collabObject['eventBy'] = {
						initials: userObj.initials,
						name: userObj.first_name + " " + userObj.last_name,
					}
				}

				let collabTag = []
				let collabNote = []
				let shareInfo = {}
				let collabDeletShare = {}
				let deleteNote = []
				let updateNote = []
				let deleteTag = []
				item.event_details.map(event => {
					if (item.event_type == 20) {
						let tag;
						if (tagsList?.length > 0) {
							tag = tagsList.find(value => value.id == event.object[0].id)

						}
						if (tag) {
							collabTag.push(tag)
						} else {
							collabTag.push({
								description: event.event_information,
								isLabelShared: false,
								id: event.object[0].id
							})
						}
					} else if (item.event_type == 21) {
						let tag;
						if (tagsList?.length > 0) {
							tag = tagsList.find(value => value.id == event.object[0].id)
						}
						if (tag) {
							deleteTag.push(tag)
						}
						else {
							collabTag.push({
								description: event.event_information,
								isLabelShared: false,
								id: event.object[0].id
							})
						}
					} else if (item.event_type == 70) {
						if (event.event_information) {
							let note = {}
							note.description = event.event_information
							note.id = event.object[0].id
							collabNote.push(note)
						} else {
							delete collabObject["eventType"]
							delete collabObject["eventOn"]
							delete collabObject["eventBy"]
						}
					} else if (item.event_type == 71) {
						if (event.event_information) {
							let note = {}
							note.description = event.event_information
							note.id = event.object[0].id
							deleteNote.push(note)
						} else {
							delete collabObject["eventType"]
							delete collabObject["eventOn"]
							delete collabObject["eventBy"]
						}
					} else if (item.event_type == 72) {
						if (event.event_information) {
							let note = {}
							note.description = event.event_information
							note.id = event.object[0].id
							updateNote.push(note)
						} else {
							delete collabObject["eventType"]
							delete collabObject["eventOn"]
							delete collabObject["eventBy"]
						}
					} else if (item.event_type == 30) {
						shareInfo.message = event.event_information
						let shareWith = []
						event.object.map(value => {
							if (value.type == "company") {
								let everyOne = {
									name: "Everyone",
									type: value.type
								}
								shareWith.push(everyOne)
							} else if (value.type == "user") {
								let userObj = userList[value.id]
								if (userObj) {
									shareWith.push({
										name: userObj.first_name + " " + userObj.last_name,
										id: userObj.id,
										type: value.type
									})
								}
							} else if (value.type == "team") {
								let teamObj = teamList[value.id]
								if (teamObj) {
									shareWith.push({
										name: teamObj.name,
										type: value.type,
										id: teamObj.team_no
									})
								}
							}
						})
						shareInfo['shareWith'] = shareWith
					} else if (item.event_type == 31) {
						let deleteShare = []
						event.object.map(obj => {
							if (obj.type == "user") {
								let userObj = userList[obj.id]
								if (userObj != null) {
									deleteShare.push({
										name: userObj.first_name + " " + userObj.last_name,
										type: obj.type,
										id: userObj.id
									})
								}
							} else if (obj.type == "team") {
								let teamObj = teamList[obj.id]
								if (teamObj) {
									deleteShare.push({
										name: teamObj.name,
										type: obj.type,
										id: obj.team_no
									})
								}
							} else if (obj.type == "company") {
								deleteShare.push({
									name: "Everyone",
									type: obj.type
								})
							}
						})
						collabDeletShare['shareDelete'] = deleteShare
					}
				})

				if (collabTag.length > 0) {
					collabObject['eventDetails'] = collabTag
				} else if (collabNote.length > 0) {
					collabObject['eventDetails'] = collabNote
				} else if (Object.keys(shareInfo).length > 0) {
					collabObject['eventDetails'] = shareInfo
				} else if (Object.keys(collabDeletShare).length > 0) {
					collabObject['eventDetails'] = collabDeletShare
				} else if (deleteNote.length > 0) {
					collabObject['eventDetails'] = deleteNote
				} else if (updateNote.length > 0) {
					collabObject['eventDetails'] = updateNote
				} else if (deleteTag.length > 0) {
					collabObject['eventDetails'] = deleteTag
				}
				if (Object.keys(collabObject).length > 0) {
					modifiedCollabData.push(collabObject)
				}
			}
		})

	}
	return modifiedCollabData
}

export function collaborationTimelineView(collaborationData) {
	let collaborationList = {}
	let collaborationArray = []
	let collaborationSorted = collaborationData && [...collaborationData].sort((a, b) => b.eventOn - a.eventOn)

	collaborationSorted && collaborationSorted.map(function (collab, index) {
		let collabGroup = getHumanizeDateForNotes(collab.eventOn)
		if (Object.keys(collaborationList).includes(collabGroup)) {
			collaborationArray = collaborationList[collabGroup]
			collaborationArray.push(collab)
			collaborationList[collabGroup] = collaborationArray
		} else {
			collaborationArray = []
			collaborationArray.push(collab)
			collaborationList[collabGroup] = collaborationArray
		}
	})
	return collaborationList
}

export function checkIfTrialCustomerUserExportedDataForToday(exportList, user) {
	let returnValue = false
	if (user.customer_type && user.customer_type === CUSTOMER_TYPE.TRIAL_CUSTOMER) {
		if (exportList && Array.isArray(exportList) && exportList.length > 0) {
			let filteredList = exportList.filter(item => {
				let createdDate = moment(item.created_on, 'DD/MM/YYYY HH:mm:ss').format('DD-MMM-YYYY')
				return moment(moment().format("DD-MMM-YYYY")).isSame(createdDate)
			})
			if (filteredList.length > 0) {
				returnValue = false
			} else {
				returnValue = true
			}
		} else {
			returnValue = true
		}
	} else if (user.customer_type && user.customer_type === CUSTOMER_TYPE.NORMAL_CUSTOMER || !user.customer_type) {
		returnValue = true
	}
	return returnValue
}

export function formatUrlWithProtocol(url) {
	let returnUrl = ""
	if (url.startsWith("https://")) {
		returnUrl = url
	} else if (url.startsWith("http://")) {
		returnUrl = url.replace("http://", "https://")
	} else if (url) {
		returnUrl = `https://${url}`
	}
	return returnUrl
}

export function getCompanyAddress(entityDetails) {
	var address = entityDetails.company_address1 && entityDetails.company_address1 != "-" && entityDetails.company_address1 != " " ? entityDetails.company_address1 : ""
	address += entityDetails.company_address2 && entityDetails.company_address2 != "-" && entityDetails.company_address2 != " " ? ", " + entityDetails.company_address2 : ""
	address += entityDetails.company_address3 && entityDetails.company_address3 != "-" && entityDetails.company_address3 != " " ? ", " + entityDetails.company_address3 : ""
	address += entityDetails.company_address4 && entityDetails.company_address4 != "-" && entityDetails.company_address4 != " " ? ", " + entityDetails.company_address4 : ""
	address += entityDetails.company_postCode && entityDetails.company_postCode != "-" && entityDetails.company_postCode != " " ? ", " + entityDetails.company_postCode : "" || entityDetails.company_postcode && entityDetails.company_postcode != "-" && entityDetails.company_postcode != " " ? ", " + entityDetails.company_postcode : ""
	return address.length > 0 ? address : ""
}

export function getFormatedPersonAddedDate(personAddedDate, dateFormatStr) {
	var nowdate = moment(personAddedDate).format(dateFormatStr)
	var newdate = moment(nowdate, dateFormatStr).format("DD-MMM-YYYY");
	return (newdate).toLowerCase() === "invalid date" ? personAddedDate : newdate
}

export function displayCurrency(value, isEstimated) {
	switch (value.toString().length) {
		case 1:
			return isEstimated ? `${value} (est)` : `${value}`
		case 2:
			return isEstimated ? `${value} (est)` : `${value}`
		case 3:
			return isEstimated ? numFormatter(value, 1000, "k (est)") : numFormatter(value, 1000, "k")
		case 4:
			return isEstimated ? numFormatter(value, 1000, "k (est)") : numFormatter(value, 1000, "k")
		case 5:
			return isEstimated ? numFormatter(value, 1000, "k (est)") : numFormatter(value, 1000, "k")
		case 6:
			return isEstimated ? numFormatter(value, 1000, "k (est)") : numFormatter(value, 1000, "k")
		case 7:
			return isEstimated ? numFormatter(value, 1000000, "m (est)") : numFormatter(value, 1000000, "m")
		case 8:
			return isEstimated ? numFormatter(value, 1000000, "m (est)") : numFormatter(value, 1000000, "m")
		case 9:
			return isEstimated ? numFormatter(value, 1000000, "m (est)") : numFormatter(value, 1000000, "m")
		case 10:
			return isEstimated ? numFormatter(value, 1000000000, "bn (est)") : numFormatter(value, 1000000000, "bn")
		case 11:
			return isEstimated ? numFormatter(value, 1000000000, "bn (est)") : numFormatter(value, 1000000000, "bn")
		case 12:
			return isEstimated ? numFormatter(value, 1000000000, "bn (est)") : numFormatter(value, 1000000000, "bn")
	}
}

function numFormatter(value, modular, suffix) {
	return '£' + (value / modular).toFixed(1).replace(/(\.0+|0+)$/, '') + suffix
}

export function getProjectFramework(primaryProject, associateProject) {
	let projectFramework = {}
	if (primaryProject) {
		projectFramework["Framework"] = primaryProject
	}
	if (associateProject && associateProject.length > 0) {
		projectFramework["Associated contracts"] = associateProject
	}
	return projectFramework
}

export function getFormatedStartOrEndDate(projectDate) {
	return projectDate ? moment(projectDate, 'DD/MM/YYYY', true).isValid()
		? convertDateFormat(projectDate, 'DD-MMM-YYYY', 'DD/MM/YYYY') : projectDate : 'TBC'
}

export function convertDateFormat(lastReleaseDate, dateFormatStr, actualDateFormat) {
	if (!lastReleaseDate) {
		return ""
	}
	else {
		var nowdate = moment(lastReleaseDate, actualDateFormat).format(dateFormatStr)
		return (nowdate).toLowerCase() === "invalid date" ? lastReleaseDate : nowdate
	}
}

export function getOnsiteBadge(onsiteValue, projectBuildPhase) {
	onsiteValue = onsiteValue != 0 && onsiteValue != 100 ? "(" + onsiteValue + "%)" : ""
	return projectBuildPhase == "active_on_site" ? ('Confirmed On-site ' + onsiteValue) : 'Estimated On-site'
}

export function displayExternalArea(number) {
	const formatter = number > 9999 ? Intl.NumberFormat("en", { notation: "standard", maximumFractionDigits: 2 })
		: Intl.NumberFormat("en", { notation: "standard" });
	var unit = number > 9999 ? ' ha' : number > 1 ? ' sq metres' : ' sq metre'
	return formatter.format(number > 9999 ? number / 10000 : number) + unit;
}

export function displayInternalArea(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard" });
	var unit = number > 1 ? ' sq metres' : ' sq metre'
	return formatter.format(number) + unit;
}

export function displayProjectLinear(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard", maximumFractionDigits: 2 });
	var unit = number > 999 ? ' km' : number > 1 ? ' metres' : ' metre'
	return formatter.format(number > 999 ? number / 1000 : number) + unit;
}

export function displayProjectVolume(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard" });
	return formatter.format(number) + ' cu m';
}

export function displayProjectUnitOrFlatsOrParking(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard" });
	return formatter.format(number);
}

export function displayDuration(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard" });
	var unit = number > 1 ? ' months' : ' month'
	return formatter.format(number) + unit;
}

export function formatNumber(number) {
	const formatter = Intl.NumberFormat("en", { notation: "standard" });
	return formatter.format(number);
}

export function groupRelatedProjectsByRole(companies, bySubsidiaries = false) {
	let rolesCompanies = {}
	let companyIdArray = []
	let companyIdRoleGroup = {}
	let rolesArray = []
	let subsidiariesArray = []
	let allCompanies = companies
	if (allCompanies && !(bySubsidiaries)) {
		allCompanies.map((company) => rolesArray.push(company.roleName))
		let uniqRolesArray = _.uniq(rolesArray).sort()
		allCompanies.sort((comp1, comp2) => comp2.lastRelease - comp1.lastRelease)
		uniqRolesArray.map(function (roleName, index) {
			let companyArray = []
			companyIdRoleGroup[roleName] = []
			allCompanies.map(function (comp, index) {
				if (roleName == comp.roleName) {
					if (!(companyIdRoleGroup[roleName] && companyIdRoleGroup[roleName].includes(comp.objectId))) {
						companyArray.push(comp)
					}
					companyIdRoleGroup[roleName].push(comp.objectId)
				}
			})
			if (roleName != undefined) {
				rolesCompanies[roleName] = companyArray
			}
		})
	}
	else if (allCompanies && bySubsidiaries) {
		allCompanies.map((company) => subsidiariesArray.push(company.company_group_type))
		let uniqRolesArray = _.uniq(subsidiariesArray).sort()
		uniqRolesArray.map(function (company_group_type, index) {
			let companyArray = []
			companyIdRoleGroup[company_group_type] = []
			allCompanies.map(function (comp, index) {
				if (company_group_type == comp.company_group_type) {
					if (!(companyIdRoleGroup[company_group_type] && companyIdRoleGroup[company_group_type].includes(comp.company_id))) {
						companyArray.push(comp)
					}
					companyIdRoleGroup[company_group_type].push(comp.company_id)
				}
			})
			if (company_group_type != undefined) {
				if (company_group_type == "subsidiary") {
					company_group_type = "Subsidiaries"
				}
				else if (company_group_type == "head_office") {
					company_group_type = "Head Office"
				}
				rolesCompanies[company_group_type] = companyArray
			}
		})
	}
	return rolesCompanies
}

export function getDisplayProjectValue(value) {
	let val,
		len = value.toString().length;
	value = Math.round(value)
	if (len <= 3) {
		val = value.toString()
	} else if (len <= 6) {
		val = (value / 1000).toString() + "k"
	} else if (len <= 9) {
		val = (value / 1000000).toString() + "m"
	} else {
		val = (value / 1000000000).toString() + "bn"
	}
	return '£' + val
}
export function getRelativeTimingMessage(value) {
	let keyValue = {
		"-1825": " 5 years",
		"-1460": " 4 years",
		'-1095': " 3 years",
		"-730": " 2 years",
		"-365": " 1 year",
		"-180": " 6 months",
		"-90": " 3 months",
		"-60": " 2 months",
		"-30": " 1 month",
		"-14": " 2 weeks",
		"-7": " 1 week",
		"-1": " 1 day",
		"0": " today",
		"30": " 1 month",
		"90": " 3 months",
		"180": " 6 months",
		"365": " 1 year",
		"730": " 2 years",
		"1095": " 3 years",
		"1460": " 4 year",
		"1825": " 5 year"
	}
	return keyValue[value]
}

export function getRelativeUpdatedMessage(value) {
	let keyValue = {
		"-1825": " 5 years",
		"-1460": " 4 years",
		'-1095': " 3 years",
		"-730": " 2 years",
		"-365": " year",
		"-180": " 6 months",
		"-90": " 3 months",
		"-60": " 2 months",
		"-30": " month",
		"-14": " 2 weeks",
		"-7": " week",
		"-1": " day",
		"0": " today",
		"30": " month",
		"90": " 3 months",
		"180": " 6 months",
		"365": " year",
		"730": " 2 years",
		"1095": " 3 years",
		"1460": " 4 year",
		"1825": " 5 year"
	}
	return keyValue[value]
}

export function checkSaveSearchById(searchFilter) {
	let value = false
	if ('project_text' in searchFilter.query) {
		if (searchFilter.query.project_text.value.length == 1 && !isNaN(searchFilter.query.project_text.value[0])
			&& searchFilter.query.project_text.value[0].length == 8 && searchFilter.query.project_text.value[0].startsWith('1')) {
			value = true
		}
	} else if ('company_text' in searchFilter.query) {
		if (!isNaN(searchFilter.query.company_text.value.toString()) && searchFilter.query.company_text.value.toString().length <= 7
			&& !searchFilter.query.company_text.value.toString().startsWith('0')) {
			value = true
		}
	} else if ('person_text' in searchFilter.query) {
		if (!isNaN(searchFilter.query.person_text.value.toString()) && searchFilter.query.person_text.value.toString().length <= 7
			&& !searchFilter.query.person_text.value.toString().startsWith('0')) {
			value = true
		}
	}
	return value
}


export function modifyUsersResponse(data) {
	var user, licence,
		userList = data.users ? data.users.users ? data.users.users : [] : [],
		lisceneList = data.licences ? data.licences.licences ? data.licences.licences : [] : [],
		updatedUsers = {};
	for (user = 0; user < userList.length; user++) {
		let matching_liscene = [];
		for (licence = 0; licence < lisceneList.length; licence++) {
			if (userList[user]['licence_ids'] && userList[user]['licence_ids'].length > 0 && userList[user]['licence_ids'].includes(lisceneList[licence]['licence_id'])) {
				matching_liscene.push({
					license_no: lisceneList[licence].licence_id,
					license_name: lisceneList[licence].licence_name,
					valid_from: lisceneList[licence].valid_from,
					valid_to: lisceneList[licence].valid_to,
					order_no: lisceneList[licence].order_no,
					license_display_name: lisceneList[licence].license_display_name
				})
			}
		}
		let licensesJson = {}
		lisceneList.map(function (license, index) {
			// keeping tempAssigned_qty to reset later
			license.tempAssigned_qty = license.assigned;
			if (userList[user]['licence_ids'].includes(license.licence_id)) {
				licensesJson[license.licence_id] = true
			} else {
				licensesJson[license.licence_id] = false
			}
		})
		userList[user]["licenseStatus"] = licensesJson
		userList[user]['licences'] = matching_liscene

		let modifiedUser = {
			user_no: userList[user].user_id,
			username: userList[user].username,
			first_name: userList[user].first_name || "",
			last_name: userList[user].last_name || "",
			name: userList[user].first_name + userList[user].last_name,
			email: userList[user].email,
			job_title: userList[user].job_title,
			client_no: userList[user].client_no,
			is_developer_mode: userList[user].is_developer,
			license: userList[user].licences,
			licenseStatus: userList[user].licenseStatus,
			delete_status: userList[user].delete_status
		}



		if (userList[user].mobile) {
			modifiedUser.mobile = userList[user].mobile

		}
		if (userList[user].phone) {
			modifiedUser.phone = userList[user].phone
		}

		if (modifiedUser.first_name && modifiedUser.last_name) {
			modifiedUser.initials = modifiedUser.first_name[0].toUpperCase() + modifiedUser.last_name[0].toUpperCase()
		}
		updatedUsers[modifiedUser.user_no] = modifiedUser
	}
	return updatedUsers;
}

export function getPageName(path) {
	switch (path) {
		case '/':
			return 'Barbour ABI - Sign in';
		case '/app/list':
			return 'Barbour ABI';
		case '/app/savedsearch':
			return 'Barbour ABI - Saved search';
		case '/app/leaguetable':
			return 'Barbour ABI - League table';
		case '/app/myleads#updates':
			return 'Barbour ABI - My leads - Updates';
		case '/app/myleads#favourite':
			return 'Barbour ABI - My leads - Favourites';
		case '/app/myleads#history':
			return 'Barbour ABI - My leads - History';
		case '/app/myleads#tags':
			return 'Barbour ABI - My leads - Tags';
		case '/app/myleads#notes':
			return 'Barbour ABI - My leads - Notes';
		case '/app/myleads#shares':
			return 'Barbour ABI - My leads - Shares';
		case '/app/myleads#archive':
			return 'Barbour ABI - My leads - Archive';
		case '/app/calendar':
			return 'Barbour ABI - Calendar';
		case '/app/export/list':
			return 'Barbour ABI - Export data';
		case '/app/export/create':
			return 'Barbour ABI - Export data - Create export';
		case '/app/teams#teams':
			return 'Barbour ABI - Teams';
		case '/app/teams#users':
			return 'Barbour ABI - Teams - Users';
		case '/app/useradmin#users':
			return 'Barbour ABI - Admin - Users';
		case '/app/useradmin#assignLicence':
			return 'Barbour ABI - Admin - Licenses';
		case '/app/useradmin#userActivity':
			return 'Barbour ABI - Admin - Activity report';
		case '/app/settings':
			return 'Barbour ABI - My settings';
		case '/app/settings#notifications':
			return 'Barbour ABI - My settings - Notifications';
		case '/app/settings#favouriteRoles':
			return 'Barbour ABI - My settings - Favourite roles';
		case '/app/settings#hints':
			return 'Barbour ABI - My settings - Hints';
		case '/app/settings#archiveProjects':
			return 'Barbour ABI - My settings - Archive projects';
		case '/app/settings#updatePassword':
			return 'Barbour ABI - My settings - Update password';
		default:
			return ""
	}
}

export function getGoogleAnalyticsMeasurementId(domain) {
	let hostname = domain.hostname
	let measurementId;
	if (PROD_DOMAIN.includes(hostname)) {
		measurementId = MEASUREMENT_ID_PROD
	}
	if (UAT_DOMAIN.includes(hostname)) {
		measurementId = MEASUREMENT_ID_STAGING
	}
	return measurementId
}

export function getGoogleAnalyticsDetails(domain) {
	let hostname = domain.hostname
	let result = {};
	if (PROD_DOMAIN.includes(hostname)) {
		result.streamUrl = PRODURL
		result.streamId = STREAM_ID_PROD
		result.streamName = STREAM_NAME_PROD

	}
	if (UAT_DOMAIN.includes(hostname)) {
		result.streamUrl = STAGINGURl
		result.streamId = STREAM_ID_STAGING
		result.streamName = STREAM_NAME_STAGING
	}
	return result
}

export function getPageNameForDetailPage(path, title) {
	// For dynamic pages like projects, companies, and persons
	if (path.includes('/app/project') && title !== 'Barbour ABI') {
		return title;
	} else if (path.includes('/app/company') && title !== 'Barbour ABI') {
		return title;
	} else if (path.includes('/app/person') && title !== 'Barbour ABI') {
		return title;
	} if (path.includes('/app/project') && title === 'Barbour ABI') {
		return '';
	} else if (path.includes('/app/company') && title === 'Barbour ABI') {
		return '';
	} else if (path.includes('/app/person') && title === 'Barbour ABI') {
		return '';
	} else {
		return '';
	}
}
