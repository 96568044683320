import React from "react";
import TopHeader from "../../components/header";
import TeamsDetails from "../../components/teams";
import LeftPanel from "../../components/leftpanel";
import AlertMessage from "../../components/alerts";
//import UserTabs from "../../components/teams";

const TeamsPage = () => {
    return (
      <div id="container">
        <TopHeader />
        <div className="sidebar-mini">
          <LeftPanel />
          <div id="message-container">
            <AlertMessage />
          </div>
          <div className="team-admin-page teams-main" >

            <TeamsDetails />
          </div>
          
        </div>
      </div>
    
    );
  };
  export default TeamsPage;