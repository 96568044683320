import { isNumber } from "lodash"
import { COMPANY_SEARCH_FIELDS, PEOPLE_SEARCH_FIELDS, PROJECT_SEARCH_FIELDS } from "../../utils/settings"

export function getBuildPhaseFilter(appliedFilter) {
    return {
        "title": "Build Phase",
        "active": appliedFilter.buildPhase && appliedFilter.buildPhase && appliedFilter.buildPhase.active ? appliedFilter.buildPhase.active : false,
        "isSearchApplied": false,
        "options": [
            {
                "title": "Not Started",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[0].active ? appliedFilter.buildPhase.options[0].active : true,
                "value": "not_started"
            },
            {
                "title": "Enabling works",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[1].active ? appliedFilter.buildPhase.options[1].active : true,
                "value": "enabling"
            },
            {
                "title": "On-site",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[2].active ? appliedFilter.buildPhase.options[2].active : true,
                "value": "on_site"
            },
            {
                "title": "No Timing",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[3].active ? appliedFilter.buildPhase.options[3].active : true,
                "value": "no_timing"
            }
        ]
    }
}

export const project_stages = [19, 20]

export const fetchSearchQuery = (searchObj, searchPersist, id, countPayload) => {
    let query1 = {}
    let payload = {}
    let sortValue;
    ({ sortValue, query1 } = searchQuery(searchObj, sortValue, query1, searchPersist, id, countPayload))
    payload = searchPayload(payload, searchObj, query1, sortValue, searchPersist)
    return payload
}

function searchPayload(payload, searchObj, query1, sortValue, searchPersist) {
    payload = {
        offset: searchObj.offsetLimitValue && searchObj.offsetLimitValue.offset,
        records: searchObj.offsetLimitValue && searchObj.offsetLimitValue.records,
        query: query1,
        sort: sortValue,
        event_type: "search"
    }
    if (searchObj.selectedSelectorControlValue && searchObj.selectedSelectorControlValue.toString() !== "0" && ["50", "100", "250", "500", "1000", "2000"].includes(searchObj.selectedSelectorControlValue)) {
        payload['selected_records'] = parseInt(searchObj.selectedSelectorControlValue)
    } else if (searchObj.selectedSelectorControlValue && searchObj.selectedSelectorControlValue.toString() == "") {
        payload['selected_records'] = parseInt(searchObj.listCount)
    } else {
        payload["selected_records"] = parseInt(searchObj.selectedSelectorControlValue)
    }
    if (searchPersist.additionalQuery.addAnyValue != undefined) {
        payload["project_search"] = searchPersist.additionalQuery.addAnyValue
    }
    if (searchObj.additionalQuery.addAnyValue != undefined) {
        payload["project_search"] = searchObj.additionalQuery.addAnyValue
    }
    if (searchObj.additionalQuery.brandSearch != undefined) {
        payload["brand_search"] = searchObj.additionalQuery.brandSearch
    }
    // if (searchObj.showSelectedCompanies || searchObj.showSelectedProjects) {
    //     payload['offset'] = 0
    //     payload['records'] = 50
    // }
    return payload
}

function searchQuery(searchObj, sortValue, query1, searchPersist, id, payload) {
    // if (searchObj.objectId !== undefined && searchObj.objectId && searchObj.objectId !== 0) {
    //     // From details back to update the searchlist without rerendering UI
    //     ({ sortValue, query1 } = createDetailBackQuery(searchObj, sortValue, query1))
    if (searchObj.showSelectedCompanies) {
        // Selected top projects and hierarchy from project list page
        ({ sortValue, query1 } = createCompanyHierarchicalQuery(sortValue, searchObj, query1, searchPersist))
    }
    else if (searchObj.showSelectedProjects) {
        // Selected top companies & people and hierarchy from company & people list page
        ({ sortValue, query1 } = createProjectHierarchicalQuery(sortValue, searchPersist, searchObj, query1))
    }
    else {
        // Main searchlist
        ({ sortValue, query1 } = createMainSearchQuery(sortValue, searchPersist, searchObj, query1, id, payload))
    }
    return { sortValue, query1 }
}

function createMainSearchQuery(sortValue, searchPersist, searchObj, query1, id, payload) {
    sortValue = searchPersist.defaultSort[searchPersist.appliedContext].value
    if (searchObj.removeFilterToggle) {
        query1 = {
            "project_stages": [{ "operator": "!=", "value": project_stages }],
            "project_build_phase": { "operator": "=", "value": ["not_started", "enabling", "on_site", "no_timing"] },
        }
    }
    // Query for 'Include completed projects' in filters
    if (searchObj.includeCompletedProjects) {
        query1 = {
            "project_stages": [{ "operator": "!=", "value": project_stages }],
            "project_build_phase": { "operator": "=", "value": ["not_started", "enabling", "on_site", "no_timing", "completed"] },
        }
    }
    // Query for 'Build phase' in filters
    if (searchObj.buildPhaseToggle && searchObj?.buildPhase?.length > 0) {
        query1 = {
            "project_stages": [{ "operator": "!=", "value": project_stages }],
            "project_build_phase": { "operator": "=", "value": searchObj?.buildPhase },
        }
    }
    if (searchObj.buildPhaseToggle && searchObj?.buildPhase?.length == 0) {
        query1 = {
            "project_stages": [{ "operator": "!=", "value": project_stages }],
            "project_build_phase": { "operator": "=", "value": ["not_started", "enabling", "on_site"] },
        }
    }
    if (searchPersist.additionalQuery !== undefined && searchPersist.additionalQuery.query && Object.keys(searchPersist.additionalQuery.query).length > 0) {
        query1 = Object.assign(query1, searchPersist.additionalQuery.query)
    }
    if (searchObj.additionalQuery !== undefined && searchObj.additionalQuery.query && Object.keys(searchObj.additionalQuery.query).length > 0) {
        query1 = Object.assign(query1, searchObj.additionalQuery.query)
    }
    if (payload) {
        query1 = Object.assign(query1, payload)
    }
    if (isNumber(id)) {
        query1 = Object.assign(query1, { "company_id": { "operator": "=", "value": id } })
        sortValue = '-project_last_published'
    }
    // if (searchObj.objectId !== undefined && searchObj.objectId && searchObj.objectId !== 0) {
    //     let ids = []
    //     ids.push(searchObj.objectId)
    //     let json = id === 'project' ? { 'project_id': { "operator": "=", "value": ids } } : { 'company_id': { "operator": "=", "value": ids } }
    //     query1 = Object.assign(query1, json)
    // }
    return { sortValue, query1 }
}

function createProjectHierarchicalQuery(sortValue, searchPersist, searchObj, query1) {
    sortValue = searchPersist?.appliedContext === "company" ? searchPersist?.heirarchialSort["project"].value : "project_last_published"
    if (searchPersist.appliedContext === "people") {
        if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
            let listOfSelectedEntities = searchObj.searchList.filter(item => {
                return item.is_selected
            }).map(item => item.person_id)
            query1 = {
                "person_id": { "operator": "=", "value": listOfSelectedEntities }
            }
        }
    } else {
        if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
            let listOfSelectedEntities = searchObj.searchList.filter(item => {
                return item.is_selected
            }).map(item => item.company_id)
            if (!searchObj.removeFilterToggle) {
                query1 = {
                    "company_id": { "operator": "=", "value": listOfSelectedEntities }
                }
            }
            else {
                query1 = {
                    "company_id": { "operator": "=", "value": listOfSelectedEntities },
                    "project_stages": [{ "operator": "!=", "value": project_stages }], "project_build_phase": { "operator": "=", "value": ["not_started", "enabling", "on_site", "no_timing"] }
                }
            }
        }
    }
    return { sortValue, query1 }
}

function createCompanyHierarchicalQuery(sortValue, searchObj, query1, searchPersist) {
    sortValue = searchPersist?.heirarchialSort["company"]?.value
    if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
        let listOfSelectedEntities = searchObj.searchList.filter(item => {
            return item.is_selected
        }).map(item => item.project_id)
        query1 = {
            "project_id": { "operator": "=", "value": listOfSelectedEntities }
        }
    }


    return { sortValue, query1 }
}

export const projectHeaderItems = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const peopleProjectsHeaderItems = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "People", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const peopleHeaderList = [
    { name: "Name", class: 'w-20' },
    { name: "Job Title", class: 'w-15' },
    { name: "Email", class: 'w-15' },
    { name: "Phone", class: 'w-10' },
    { name: "Company", class: 'w-20' },
    { name: "Address", class: 'w-20' },
]

export const companyHeaderItems = [
    { name: "Name", class: 'w-30' },
    // {name:'', class:'w-5'},
    { name: "Location", class: 'w-25' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    // {name: "Contract", class: 'w-15'},
    { name: "Published", class: 'w-10' }
]

export const MyLeadsUpdatesHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Updates", class: 'w-15' },
    { name: "Updated", class: 'w-10' }
]

export const MyLeadsUpdatesHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "Updates", class: 'w-20' },
    { name: "Updated", class: 'w-10' }
]

export const MyLeadsFavouritesHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsFavouritesHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "", class: 'w-10 ' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsHistoryHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Viewed", class: 'w-10' }
]

export const MyLeadsHistoryHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "", class: 'w-10 ' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsSharesHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsSharesHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "", class: 'w-10 ' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsArchiveHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const MyLeadsArchiveHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "Last Published", class: 'w-10' }
]


export const LeagueTableHeaderforCompany = [
    { name: "Name", class: 'w-35' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "Projects", class: 'w-10' },
    { name: "Value", class: 'w-10' }
]

export const MyLeadsTagssHeaderforCompany = [
    { name: "Name", class: 'w-25' },
    { name: "Location", class: 'w-20' },
    { name: "Phone", class: 'w-10' },
    { name: "Website", class: 'w-20' },
    { name: "", class: 'w-10 ' },
    { name: "Last Published", class: 'w-10' }
]
export const MyLeadsTagsHeaderforProjects = [
    { name: "Name", class: 'w-30' },
    { name: "", class: 'w-5' },
    { name: "Client", class: 'w-15' },
    { name: "Value", class: 'w-10' },
    { name: "Start Date", class: 'w-10' },
    { name: "End Date", class: 'w-10' },
    { name: "Stages", class: 'w-10' },
    { name: "Location", class: 'w-10' },
    { name: "Last Published", class: 'w-10' }
]

export const renderListHeader = (type, searchPersist, isNotHierarchy, search) => {

    let finalListRender
    let itemsToRender = type == "project" ? companyHeaderItems : type == "company" ? projectHeaderItems : isNotHierarchy ? peopleProjectsHeaderItems : projectHeaderItems

    if (search?.showSelectedCompanies) {
        finalListRender = itemsToRender.map((item, index) => {
            return (<div key={index} className={`list-col align-self-center ${item.class}`}><b>

                {type === 'project' ? item.name === 'Published' ? (searchPersist?.heirarchialSort["company"]?.label.toLowerCase() === 'projects') ? 'Projects'
                    : searchPersist?.heirarchialSort?.["company"].label.toLowerCase() === 'value' ? "Value" :
                        item.name : item?.name : item.name
                }</b></div>)
        })
        return finalListRender
    }
    else {
        finalListRender = itemsToRender.map((item, index) => {
            return (<div key={index} className={`list-col align-self-center ${item.class}`}><b>

                {type === 'project' ? item.name === 'Published' ? (searchPersist?.defaultSort[type]?.label.toLowerCase() === 'projects') ? 'Projects'
                    : searchPersist?.defaultSort?.[type].label.toLowerCase() === 'value' ? "Value" :
                        item.name : item?.name : item.name
                }</b></div>)
        })
        return finalListRender
    }
}



export const renderListHeaderForAllObject = (type, sort) => {
    let finalListRender
    let itemsToRender = type === "project" ? projectHeaderItems : type === "company" ? companyHeaderItems : peopleHeaderList
    finalListRender = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class}`}>
            <b>
                {
                    type === "company" ? (
                        item.name === 'Published' ? (
                            sort?.defaultSort[type]?.label.toLowerCase() === 'projects' ? (
                                'Projects'
                            ) : sort?.defaultSort[type]?.label === 'Value' ? (
                                'Value'
                            ) : sort && sort.defaultSort[type] && sort.defaultSort[type].label !== 'nearest' ? (
                                'Published'
                            ) : (
                                'Distance'
                            )
                        ) : (
                            item.name
                        )
                    ) : type === 'project' ? (
                        item.name === 'Last Published' ? (
                            sort && sort.defaultSort[type] && sort.defaultSort[type].label === 'nearest' ? (
                                'Distance'
                            ) : (
                                'Last Published'
                            )
                        ) : (
                            item.name
                        )
                    ) : (
                        item.name
                    )
                }
            </b>

        </div>)
    })
    return finalListRender
}

export const renderListHeaderForMyLeadsUpdates = (type, sort) => {
    let finalMyLeadsUpdates
    let itemsToRender = type === "project" ? MyLeadsUpdatesHeaderforProjects : MyLeadsUpdatesHeaderforCompany
    finalMyLeadsUpdates = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class}`}><b>{item.name}</b></div>)
    })
    return finalMyLeadsUpdates
}

export const renderListHeaderForMyLeadsFavourites = (type, sort, userGeolocationAccess) => {
    let finalMyLeadsFavourites
    let itemsToRender = type === "project" ? MyLeadsFavouritesHeaderforProjects : MyLeadsFavouritesHeaderforCompany
    finalMyLeadsFavourites = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class} ${((sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label.toLowerCase() === "latest" || sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label.toLowerCase() === "nearest")) && type === "company" && item.name === "" ? "hide" : ""}`}>
            <b>
                {
                    type === "company" ? (
                        item.name === "Last Published" ? (
                            sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label.toLowerCase() === "latest" ? (
                                'Last Published'
                            ) : sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : (
                                item.name
                            )
                        ) : item.name === "" ? (
                            sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label === 'Projects' ? (
                                'Projects'
                            ) : sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label === 'value' ? (
                                'Value'
                            ) : (
                                item.name
                            )
                        ) : (
                            item.name
                        )
                    ) : type === "project" ? (
                        item.name === 'Last Published' ? (
                            sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : sort?.leadsSort?.["favourite"]?.[sort.leadsAppliedContext.favourite]?.label.toLowerCase() === "latest" ? (
                                'Last Published'
                            ) : (
                                'Last Published'
                            )
                        ) : (
                            item.name
                        )
                    ) : (
                        item.name
                    )
                }
            </b>


        </div>)
    })
    return finalMyLeadsFavourites
}

export const renderListHeaderForMyLeadsHistory = (type, sort, userGeolocationAccess) => {
    let finalMyLeadsHistory
    let itemsToRender = type === "project" ? MyLeadsHistoryHeaderforProjects : MyLeadsHistoryHeaderforCompany
    finalMyLeadsHistory = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class} ${(sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === "latest" ||
            sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === "nearest" || sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label.toLowerCase() ===
            "last viewed") && type === "company" && item.name === "" ? "hide" : ""}`}>
            <b>
                {
                    type === "company" ? (
                        item.name === "Last Published" ? (
                            sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label.toLowerCase() === "last viewed" ? 'Last Viewed' :
                                sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === 'nearest' && userGeolocationAccess ? 'Distance' :
                                    item.name
                        ) : (
                            item.name === "" ? (
                                sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === 'Projects' ? 'Projects' :
                                    sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === 'value' ? "Value" :
                                        item.name
                            ) : item.name
                        )
                    ) : (
                        type === 'project' ? (
                            item.name === 'Last Viewed' ? (
                                sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label === 'nearest' && userGeolocationAccess ? 'Distance' :
                                    sort?.leadsSort?.["history"]?.[sort.leadsAppliedContext.history]?.label.toLowerCase() === "last viewed" ? 'Last Viewed' :
                                        "Last Published"
                            ) : item.name
                        ) : item.name
                    )
                }
            </b>

        </div>)
    })
    return finalMyLeadsHistory
}


export const renderListHeaderForMyLeadsShares = (type, sort, userGeolocationAccess) => {
    let finalMyLeadsShares
    let itemsToRender = type === "project" ? MyLeadsSharesHeaderforProjects : MyLeadsSharesHeaderforCompany
    finalMyLeadsShares = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class} ${((sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label.toLowerCase() === "latest" || sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label.toLowerCase() === "nearest")) && type === "company" && item.name === "" ? "hide" : ""}`}>
            <b>
                {
                    type === "company" ? (
                        item.name === "Last Published" ? (
                            sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label.toLowerCase() === "latest" ? (
                                'Last Published'
                            ) : sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : (
                                item.name
                            )
                        ) : item.name === "" ? (
                            sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label === 'Projects' ? (
                                'Projects'
                            ) : sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label === 'value' ? (
                                'Value'
                            ) : (
                                item.name
                            )
                        ) : (
                            item.name
                        )
                    ) : type === 'project' ? (
                        item.name === 'Last Published' ? (
                            sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : sort?.leadsSort?.["shares"]?.[sort.leadsAppliedContext.shares]?.label.toLowerCase() === "latest" ? (
                                'Last Published'
                            ) : (
                                'Last Published'
                            )
                        ) : (
                            item.name
                        )
                    ) : (
                        item.name
                    )
                }
            </b>

        </div>)
    })
    return finalMyLeadsShares
}

export const renderListHeaderForMyLeadsArchive = (type, sort) => {
    let finalMyLeadsArchive
    let itemsToRender = type === "project" ? MyLeadsArchiveHeaderforProjects : MyLeadsArchiveHeaderforCompany
    finalMyLeadsArchive = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class}`}><b>{item.name}</b></div>)
    })
    return finalMyLeadsArchive
}

export const renderListHeaderForMyLeadsTags = (type, sort, userGeolocationAccess) => {
    let finalMyTagHistory
    let itemsToRender = type === "project" ? MyLeadsTagsHeaderforProjects : MyLeadsTagssHeaderforCompany
    finalMyTagHistory = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class} } ${(sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label.toLowerCase() === "latest" ||
            sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label === "nearest")
            && type === "company" && item.name === "" ? "hide" : ""}`}>
            <b>
                {
                    type === "company" ? (
                        item.name === "" ? (
                            sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label === 'Projects' ? (
                                'Projects'
                            ) : sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label === 'value' ? (
                                'Value'
                            ) : (
                                item.name
                            )
                        ) : item.name === 'Last Published' ? (
                            sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : (
                                'Last Published'
                            )
                        ) : (
                            item.name
                        )
                    ) : type === 'project' ? (
                        item.name === 'Last Published' ? (
                            sort?.leadsSort?.["tags"]?.[sort.leadsAppliedContext.tags]?.label === 'nearest' && userGeolocationAccess ? (
                                'Distance'
                            ) : (
                                'Last Published'
                            )
                        ) : (
                            item.name
                        )
                    ) : (
                        item.name
                    )
                }
            </b>

        </div>)
    })
    return finalMyTagHistory
}

function fetchCountUrl(url, filterType, query) {
    let projectSearchVal = query.project_search !== undefined ? query.project_search : ""
    let brandSearchVal = query.brand_search !== undefined ? query.brand_search : ""
    let aggregrateField = filterType && filterType === "people" ? "people_count&aggregate_field=person_id" : "projects_count,companies_count&aggregate_field=project_id,company_id"
    url = `${filterType}?query=${JSON.stringify(query.query)}&aggregate_function=${aggregrateField}`
    url = projectSearchVal ? url + `&project_search=${projectSearchVal}` : url
    url = brandSearchVal ? url + `&brand_search=${brandSearchVal}` : url

    return url
}

function fetchSearchlistUrl(search, filterType, searchPersist, query) {
    let searchFileds = filterType === "projects" ? PROJECT_SEARCH_FIELDS : filterType === "companies" ? COMPANY_SEARCH_FIELDS : PEOPLE_SEARCH_FIELDS;
    if (search.objectId && search.objectId !== undefined && search.objectId !== 0 && search.objectType && search.objectType !== undefined) {
        filterType = search.objectType === 'company' ? 'companies' : 'projects'
        searchFileds = search.objectType === 'project' ? PROJECT_SEARCH_FIELDS : filterType === "companies" ? COMPANY_SEARCH_FIELDS : PEOPLE_SEARCH_FIELDS
    }
    if (searchPersist.appliedContext === 'people' && search.showSelectedProjects) {
        searchFileds += ',person_first_name,person_last_name'
    }
    let projectSearchVal = query.project_search !== undefined ? query.project_search : ""
    let url = `${filterType}?query=${JSON.stringify(query.query)}&fields=${searchFileds}&event_type=${query.event_type}&offset=${query.offset}&limit=${query.records}&project_search=${projectSearchVal}`
    if (query.sort != "R") {
        url = url + "&sort=" + query.sort
    }
    return { url, filterType, searchFileds }
}

export function convertMilesToYards(distance) {
    let MILES_YARDS_CONVERSION = 1760.0
    if (distance) {
        var miles = distance.toFixed(2)
        if (miles < 0.1) {
            let yards = distance * MILES_YARDS_CONVERSION
            if (yards) {
                return yards.toFixed(2) + " yards"
            }
        }
        return miles + " miles"
    } else {
        return ""
    }
}

export const renderListHeaderForLeagueTable = (type, sort) => {
    let finalLeagueTable
    let itemsToRender = type === "company" ? LeagueTableHeaderforCompany : ''
    finalLeagueTable = itemsToRender.map((item, index) => {
        return (<div key={index} className={`list-col align-self-center ${item.class}`}><b>{
            type === "company" ?
                item.name === 'Published' ? sort && sort.defaultSort[type] && sort.defaultSort[type].label === 'Projects' ? 'Projects' :
                    (sort && sort.locationSearchObj && Object.keys(sort.locationSearchObj[type]).length > 0) ? (sort && sort.locationSearchObj[type] && sort.locationSearchObj[type].location !== '') ?
                        sort && sort.defaultSort[type] && sort.defaultSort[type].label !== 'nearest' ? 'Published' : 'Distance' : 'Published' : 'Published' : item.name :
                type === 'project' ? item.name === 'Last Published' ? sort && sort.defaultSort[type] && sort.defaultSort[type].label === 'nearest' ? 'Distance' : 'Last Published' : item.name : item.name}</b></div>)
    })
    return finalLeagueTable
}

export function createCompanySelectedQuery(searchObj) {
    let query1
    if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
        let listOfSelectedEntities = searchObj.searchList.filter(item => {
            return item.is_selected
        }).map(item => item.project_id)
        query1 = {
            "project_id": { "operator": "=", "value": listOfSelectedEntities }
        }
    }
    return { query1 }
}

export function createProjectSelectedQuery(searchObj, searchPersist) {
    let query1
    if (searchPersist.appliedContext === "people") {
        if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
            let listOfSelectedEntities = searchObj.searchList.filter(item => {
                return item.is_selected
            }).map(item => item.person_id)
            query1 = {
                "person_id": { "operator": "=", "value": listOfSelectedEntities }
            }
        }
    } else {
        if (searchObj && searchObj.searchList && searchObj.searchList.length > 0) {
            let listOfSelectedEntities = searchObj.searchList.filter(item => {
                return item.is_selected
            }).map(item => item.company_id)
            if (!searchObj.removeFilterToggle) {
                query1 = {
                    "company_id": { "operator": "=", "value": listOfSelectedEntities }
                }
            }
            else {
                query1 = {
                    "company_id": { "operator": "=", "value": listOfSelectedEntities },
                    "project_stages": [{ "operator": "!=", "value": project_stages }], "project_build_phase": { "operator": "=", "value": ["not_started", "enabling", "on_site", "no_timing"] }
                }
            }
        }
    }
    return { query1 }
}