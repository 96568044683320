import React from 'react'
import moment from 'moment'

const IntervalSelector = (props) => {

    const getIntervalFromDate = (date) => {
        date = moment(date);
        let current = moment(new Date());
        let difference = ""
        let format = "d"
        if (current.diff(date, "d") > 7){
            format = "M"
        }
        difference = current.diff(date, format)
        
        let result = difference + "-" + format.toLocaleUpperCase()

        return result ? result : "7-D"
	}

    const intervalOptions = [
        {text: "7 Days", value: "7-D"},
        {text: "1 Month", value: "1-M"},
        {text: "2 Months", value: "2-M"},
        {text: "3 Months", value: "3-M"},
        {text: "4 Months", value: "4-M"},
        {text: "5 Months", value: "5-M"},
        {text: "6 Months", value: "6-M"},
        {text: "7 Months", value: "7-M"},
        {text: "8 Months", value: "8-M"},
        {text: "9 Months", value: "9-M"},
        {text: "10 Months", value: "10-M"},
        {text: "11 Months", value: "11-M"},
        {text: "12 Months", value: "12-M"},
    ]

    return (<div className="ms-3 d-inline-block">
        <select onChange={props.onChange}>
        {intervalOptions.map(option => <option selected={props.selectedValue && getIntervalFromDate(props.selectedValue) === option.value} value={option.value}>{option.text}</option>) }
    </select>
    </div>)
}

export default IntervalSelector