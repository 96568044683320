import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup } from 'reactstrap';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import CommonPlaceholder from '../../details/framework/commonPlaceholder';
import CompanyList from '../../list/companyList/companylist';
import Contactlist from '../../list/contactList/contactlist';
import ProjectList from '../../list/projectList/projectlist';
import { fetchSearchListCount } from './apiclient';
import { updateFirstJustAskCall, updateHierarchyBack, updateNoResult } from './redux/reducer';
import { renderListHeaderForAllObject } from './utils';
import no_result_img from "../../../components/common/images/empty_state_noresults.png";
import { getUniqueSelectedList } from '../../../utils/commonFunctions';
import { showGroupCompaniesProject } from '../../details/apiclient';
import { updateShowMoreOffsetLimit } from '../../details/redux/reducer';
import { preProcessorQuery } from './filters/preProcessorQuery';
import { updateOffsetLimit } from './filters/redux/reducer';

const SearchListResult = (props) => {
    const search = useSelector((state) => state.justAsk)
    const searchPersist = useSelector((state) => state.justAskPersist)
    const settings = useSelector((state) => state.settings)
    const [searchListData, setSearchListData] = useState(search.searchList);
    const [loading, setLoading] = useState(search.isSearchLoader);
    const details = useSelector((state) => state.project)
    const filter = useSelector((state) => state.justAskFilter)
    const dispatch = useDispatch()
    // useSelector to get the current Redux store value
    const currentValue = useSelector(state => state.justAskFilter.filtersState[searchPersist.appliedContext].offset);
    // useRef to store the previous Redux store value
    const previousValueRef = useRef();

    useEffect(() => {
        // Update the previous value ref
        if (search.firstJustAskCall) {
            previousValueRef.current = 0
            dispatch(updateFirstJustAskCall(false))
        }
    }, [search.firstJustAskCall])

    useEffect(() => {
        setLoading(true)
        dispatch(updateNoResult(false))
    }, [searchPersist.appliedContext]);

    useEffect(() => {
        if (props.prevNextList === 'jAskSearchList') {
            if (!search.hierarchyBack) {
                if (filter.filtersState[searchPersist.appliedContext].records > 50 && searchPersist.appliedContext !== 'project') {
                    dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
                }
                // Update the previous value ref
                previousValueRef.current = currentValue === 0 ? currentValue : previousValueRef.current;
                if (!searchPersist.login && filter.filtersState[searchPersist.appliedContext].offset > 0
                    && previousValueRef.current !== undefined && previousValueRef.current !== currentValue) {
                    var formValues = preProcessorQuery('', filter.filtersState, searchPersist.appliedContext)
                    previousValueRef.current = currentValue
                    fetchSearchListCount(formValues, dispatch, search, settings, searchPersist.query)
                }
            }
        }
    }, [filter.filtersState[searchPersist.appliedContext].offset, filter.profileStore]);

    useEffect(() => {
        // Update the previous value ref
        previousValueRef.current = currentValue
    }, [searchPersist.isListView])

    useEffect(() => {
        if (props?.prevNextList === 'jAskGroupedCompanyProjects') {
            showGroupCompaniesProject(getUniqueSelectedList(details?.groupCompanies), dispatch, details)
        }
    }, [details.showMoreOffsetLimit.offset]);

    useEffect(() => {
        if (search.backButtonCluster?.back) {
            setSearchListData(search.clusterList.leadsFlags)
        } else {
            fetchSearchListData(setSearchListData, search);
        }
    }, [search.searchList, search.backObjectId, search.noResult, search.backButtonCluster]);

    useEffect(() => {
        if (search.backButtonCluster?.back) {
            setSearchListData(search.clusterList.leadsFlags)
        }
    }, [search.backObjectId, search.backButtonCluster]);

    const handleShowMore = () => {
        let newOffset = ''
        if (props?.prevNextList === 'jAskGroupedCompanyProjects') {
            let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
            dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
        } else {
            newOffset = filter.filtersState[searchPersist.appliedContext].offset + filter.filtersState[searchPersist.appliedContext].records;
            dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[searchPersist.appliedContext].records }))
            dispatch(updateHierarchyBack(false))
        }
    }

    const renderShowMore = () => {
        return (
            <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
            </div>
        )
    }

    const fetchSearchListData = (setSearchListData, search) => {
        setLoading(true);
        setSearchListData(search?.searchList);
        if (search.backObjectId !== undefined && search?.searchList?.length > 0) {
            setLoading(false);
        } else if (search.searchList?.length > 0 && !search.noResult && searchPersist.appliedContext === search.searchList[0].objectType) {
            setLoading(false);
        } else if (search.noResult) {
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const renderList = () => {
        return (
            <>
                {
                    !details.isProjectSelected ?
                        (props.type === 'project' ? <ProjectList prevNextList={props.prevNextList} mode={props.mode} myHoverList={props.myHoverList} activeTab={props.activeTab} /> :
                            props.type === 'company' ? <CompanyList type={props.type} myHoverList={props.myHoverList} activeTab={props.activeTab} prevNextList={props.prevNextList} /> :
                                <Contactlist prevNextList={props.prevNextList} />) :
                        (<ProjectList prevNextList={props.prevNextList} />)

                }
            </>
        )
    }

    return (
        <div className={`result-wrapper ${!searchPersist?.isListView && props.type !== 'people' ? 'gridSearchView' : ''}`}>
            <div className={`result-list-container content-panel ${(props.type === 'project' || props.type === 'company') ? 'projectNameIcon' : ''}`}>
                <div className="result-list-wrapper">
                    <div className={`listviewtable-block bggray leftright-space ${searchPersist.isListView ? '' : 'd-none'}`}>
                        <div className={`list-table`}>
                            <div className="list-header d-flex">
                                {
                                    renderListHeaderForAllObject(props.type, searchPersist)
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`searchlist-wrapper ${(searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active
                        || searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active || searchPersist.appliedContext === 'people' && filter.filtersState[searchPersist.appliedContext]?.personInformation?.active) && filter.searchMode === 'basic' && props?.prevNextList === 'jAskSearchList' ? !searchPersist.isListView ? 'with-project-info mapViewMode' : 'with-project-info' : !searchPersist.isListView ? 'mapViewMode' : ''}`}>
                        {
                            loading ? <CommonPlaceholder /> :
                                searchListData?.length > 0 ?
                                    <ColoredScrollbars>
                                        <ListGroup className={`${!searchPersist.isListView ? 'gridSearchView' : ''}`}>
                                            {renderList()}
                                            {(search.clusterList?.leadsFlags?.length === 0 && (search.isShowMoreActive || search.isShowMoreActiveCompanyProjects)) ? renderShowMore() : ''}
                                        </ListGroup>
                                    </ColoredScrollbars> : (
                                        <div>
                                            {
                                                <div className="d-flex flex-column align-items-center no-result-emptystate">
                                                    <img src={no_result_img} />
                                                    <p className="text-center empty-info"><h6>No results.</h6>
                                                        {searchPersist.appliedContext === "project" ? <p>Try adjusting your search terms or filters. Read our Search Troubleshooter for more helpful tips: <a className="d-block" href="http://help.barbour-abi.com/en/articles/6808407-project-search-troubleshooter" target="_blank">Project Search</a></p>
                                                            : searchPersist.appliedContext === "company" ? <p>Try adjusting your search terms or filters. Read our Search Troubleshooter for more helpful tips: <a className="d-block" href="https://help.barbour-abi.com/en/articles/6877681-company-search-troubleshooter" target="_blank">Company Search</a></p>
                                                                : "Try adjusting your search terms or filters."}</p>
                                                </div>
                                            }
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchListResult
