import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash';
import { getIncludeExcludeIds } from '../../../utils/commonFunctions';

const initialState = {
  detailsPage: {},
  isPrint: false,
  summaryActivity: "tags",
  isProjectFrameWork: false,
  isNoCollaboration: false,
  noMoreCollab: false,
  isAssociateFrameworkShowMore: false,
  selectorControlSelectionCount: 0,
  selectedRecordsCount: 0,
  collaborationFilter: {},
  peopleWorkingOnRole: [],
  companyForPeopleWorkingOnProject: {},
  activityIsClicked: false,
  isProjectSelected: false,
  showMoreOffsetLimit: {
    offset: 0,
    records: 50
  },
  isJointProjectViewed: false,
  pageNotFoundFlag: false,
  worksWithCompanies: []
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    fetchDetailsPage: (state, action) => {
      state.detailsPage = action.payload;
      return state
    },
    updateTabDetails: (state, action) => {
      state.detailsUpdatesTab = action.payload
      return state
    },
    tagsDetailForObjectId: (state, action) => {
      state.labelTagsDetail = action.payload;
      return state
    },
    tagsDetailForAllObjectType: (state, action) => {
      state.labelTagsAllDetail = action.payload;
      return state
    },
    userActivityCountData: (state, action) => {
      state.userActivityCount = action.payload;
      return state
    },
    notesDetailForObjectId: (state, action) => {
      state.fetchNotesForParticularObjectIds = action.payload;
      return state
    },
    notesDetailForAllType: (state, action) => {
      state.fetchNotesForAllDetails = action.payload;
      return state
    },
    shareRecipientsDetail: (state, action) => {
      state.fetchShareRecipients = action.payload;
      return state
    },
    shareDetails: (state, action) => {
      state.fetchShareDetails = action.payload;
      return state
    },
    shareForAllEntity: (state, action) => {
      state.fetchShareForAllEntity = action.payload;
      return state
    },
    rolesForProject: (state, action) => {
      state.fetchRolesForProjects = action.payload;
      return state
    },
    initializeCollabFilter: (state, action) => {
      state.collaborationFilter = {
        ...state.collaborationFilter,
        [action.payload.objectType]: {
          ...state.collaborationFilter[action.payload.collabData],
          checkboxFilter: action.payload.collabData
        }

      }
      return state
    },
    updateCollabCheckboxFilter: (state, action) => {
      state.collaborationFilter = {
        ...state.collaborationFilter,
        [action.payload.objectType]: {
          ...state.collaborationFilter[action.payload.objectType],
          checkboxFilter: {
            ...state.collaborationFilter[action.payload.objectType].checkboxFilter,
            [action.payload.type]: {
              ...state.collaborationFilter[action.payload.objectType].checkboxFilter[action.payload.type],
              active: action.payload.checked
            }
          }
        }
      }
      return state
    },
    collabList: (state, action) => {
      state.collabList = action.payload;
      return state
    },
    isNoCollab: (state, action) => {
      state.isNoCollaboration = action.payload;
      return state
    },
    noMoreCollaboration: (state, action) => {
      state.noMoreCollab = action.payload;
      return state
    },
    updateFavListSelection: (state, action) => {
      let currnetIndexes = 0
      let newProjectRoles = Object.assign({}, state.fetchRolesForProjects)
      newProjectRoles.roleGroups.map(roleGroup => {
        roleGroup.roles.map(role => {
          if (action.payload.favRoles && action.payload.favRoles.includes(role.roleNo)) {
            role.companies.map(company => {
              if (action.payload.count === -1 || currnetIndexes < action.payload.count) {
                company.is_selected = true
              } else {
                company.is_selected = false
              }
              currnetIndexes += 1
            })
          }
        })
      })
      state.selectorControlSelectionCount = action.payload.count < 0 ? currnetIndexes : action.payload.count;
      state.companyForPeopleWorkingOnProject = {}
      state.peopleWorkingOnRole = []
      return state
    },
    updateListSelection: (state, action) => {
      let currentIndex = 0
      if (action.payload && action.payload.entityType === 'projectRoles') {
        let newProjectRoles = Object.assign({}, state.fetchRolesForProjects)
        newProjectRoles.roleGroups.map(roleGroup => {
          if (action.payload.selectedRole === 'All roles' || roleGroup.groupName === action.payload.selectedRole) {
            roleGroup.roles.map(role => {
              role.companies.map(company => {
                if (action.payload.count === -1 || currentIndex < action.payload.count) {
                  company.is_selected = true
                } else {
                  company.is_selected = false
                }
                currentIndex++
              })
            })
          }
        })
        state.selectorControlSelectionCount = action.payload.count < 0 ? currentIndex : action.payload.count;
        state.companyForPeopleWorkingOnProject = {}
        state.peopleWorkingOnRole = []
        return state
      }
      else if (action.payload.entityType === "groupedCompanies") {
        if (state.groupCompanies) {
          let groupedCompanies = cloneDeep(state.groupCompanies)
          Object.keys(groupedCompanies).map((role) => {
            groupedCompanies[role].map((entity) => {
              if (action.payload.count === -1 || currentIndex < action.payload.count) {
                entity.is_selected = true
              } else {
                entity.is_selected = false
              }
              currentIndex++
            })
          })
          state.selectorControlSelectionCount = action.payload.count < 0 ? currentIndex : action.payload.count
          state.groupCompanies = groupedCompanies
          state.includeIds = action.payload.count <= 0 ? [] : state.includeIds
          state.excludeIds = action.payload.count <= 0 ? [] : state.excludeIds
          return state
        }
      }
      else if (action.payload.entityType === 'companyProjects') {
        if (state.projectDataForRoleId) {
          let projectData = cloneDeep(state.projectDataForRoleId)
          projectData && projectData.map((entity) => {
            if (action.payload.count === -1 || currentIndex < action.payload.count) {
              entity.is_selected = true
            } else {
              entity.is_selected = false
            }
            currentIndex++
          })
          state.selectorControlSelectionCount = action.payload.count < 0 ? action.payload.count === -1 ? state.projectDataForRoleIdCount : currentIndex : action.payload.count
          state.projectDataForRoleId = projectData
          state.includeIds = action.payload.count <= 0 ? [] : state.includeIds
          state.excludeIds = action.payload.count <= 0 ? [] : state.excludeIds
          return state
        }
      }
      else if (action.payload.entityType === "worksWithCompanies") {
        if (state.worksWithCompanies && state.worksWithCompanies.companies && state.worksWithCompanies.companies.length > 0) {
          let workCompany = cloneDeep(state.worksWithCompanies.companies)
          workCompany && workCompany.map((entity) => {
            if (action.payload.count === -1 || currentIndex < action.payload.count) {
              entity.is_selected = true
            } else {
              entity.is_selected = false
            }
            currentIndex++
          })
          state.selectorControlSelectionCount = action.payload.count < 0 ? currentIndex : action.payload.count
          state.worksWithCompanies.companies = workCompany
          state.includeIds = action.payload.count <= 0 ? [] : state.includeIds
          state.excludeIds = action.payload.count <= 0 ? [] : state.excludeIds
          return state
        }
      }
      else if (action.payload.entityType === "relatedCompaniesJointProjects") {
        if (state.isJointProjectViewed) {
          if (state.jointProjects && state.jointProjects.projects && state.jointProjects.projects.length > 0) {
            let jointProject = cloneDeep(state.jointProjects.projects)
            jointProject && jointProject.map((entity) => {
              if (action.payload.count === -1 || currentIndex < action.payload.count) {
                entity.is_selected = true
              } else {
                entity.is_selected = false
              }
              currentIndex++
            })
            state.selectorControlSelectionCount = action.payload.count < 0 ? action.payload.count === -1 ? state.jointProjectsCount : currentIndex : action.payload.count
            state.jointProjects.projects = jointProject
            state.includeIds = action.payload.count <= 0 ? [] : state.includeIds
            state.excludeIds = action.payload.count <= 0 ? [] : state.excludeIds
            return state
          }
        }
        else {
          if (state.companyRelatedCompanies && state.companyRelatedCompanies && state.companyRelatedCompanies.length > 0) {
            let companyRelatedCompany = cloneDeep(state.companyRelatedCompanies)
            companyRelatedCompany && companyRelatedCompany.map((entity) => {
              if (action.payload.count === -1 || currentIndex < action.payload.count) {
                entity.is_selected = true
              } else {
                entity.is_selected = false
              }
              currentIndex++
            })
            state.selectorControlSelectionCount = action.payload.count < 0 ? action.payload.count === -1 ? state.companyRelatedCompaniesCount : currentIndex : action.payload.count
            state.companyRelatedCompanies = companyRelatedCompany
            state.includeIds = action.payload.count <= 0 ? [] : state.includeIds
            state.excludeIds = action.payload.count <= 0 ? [] : state.excludeIds
            return state
          }
        }
      }
      else {
        return state
      }
    },
    rolesData: (state, action) => {
      state.rolesResponsedata = action.payload
      return state
    },
    isPrint: (state, action) => {
      state.isPrint = action.payload
      return state
    },
    peopleWorkingOnRolesCompanies: (state, action) => {
      state.peopleWorkingOnRole = action.payload
      return state
    },
    companiesForPeopleWorkingOnProject: (state, action) => {
      state.companyForPeopleWorkingOnProject = action.payload
      return state
    },
    summaryActivityDetail: (state, action) => {
      state.summaryActivity = action.payload
      return state
    },
    isProjectFramework: (state, action) => {
      state.isProjectFrameWork = action.payload
      return state
    },
    updatePrimaryFramework: (state, action) => {
      state.primaryFramework = action.payload
      return state
    },
    updateAssociateFramework: (state, action) => {
      state.associateFramework = action.payload
      return state
    },
    updateIsAssociateFrameworkShowMore: (state, action) => {
      state.isAssociateFrameworkShowMore = action.payload
      return state
    },
    updateSelectedTagDetails: (state, action) => {
      state.selectedTagsDetails = action.payload
      return state
    },
    removeSelectedTagDetails: (state, action) => {
      let tagIndex = null
      state.selectedTagsDetails = {
        ...state.selectedTagsDetails,
        selectedTags: state.selectedTagsDetails && state.selectedTagsDetails.selectedTags.filter(function (record, index) {
          return index != action.payload['index']
        }),
        tagDescriptions: state.selectedTagsDetails && state.selectedTagsDetails.tagDescriptions.filter(function (record, index) {
          if (record != action.payload['description']) {
            tagIndex = index
          }
          return record != action.payload['description']
        }),
        tagIds: state.selectedTagsDetails && state.selectedTagsDetails.tagIds.filter(function (record, index) {
          if (action.payload['id'] != 0) {
            return record != action.payload['id']
          } else {
            return index != tagIndex
          }
        }),
        tagValues: state.selectedTagsDetails && state.selectedTagsDetails.tagValues.filter(function (record, index) {
          return record.label != action.payload['description']
        })
      }
      return state
    },
    updateEventInDetails: (state, action) => {
      let newState = {}
      if (action.payload.events.length === 0) {
        newState.events = []
      } else {
        newState.events = action.payload.events
      }
      state.events = newState.events
      return state
    },
    updateProcessId: (state, action) => {
      state.processId = action.payload
      return state
    },
    updateIsSelectedToSpecificRow: (state, action) => {
      let currnetIndexes = state.selectorControlSelectionCount
      if (action.payload.entityType === "projectRoles") {
        let newProjectRoles = Object.assign({}, state.fetchRolesForProjects)
        let updatedRole = newProjectRoles.roleGroups.map(roleGroup => {
          roleGroup.roles.map(role => {
            role.companies.map(company => {
              if (company.company_id === action.payload.id) {
                if (action.payload.isSelected === true) {
                  company.is_selected = true
                  currnetIndexes++
                }
                else {
                  company.is_selected = false
                  currnetIndexes--
                }
              }
            })
          })
        })
        const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
        state.selectorControlSelectionCount = currnetIndexes
        state.projectRoles = updatedRole
        state.excludeIds = excludeIds
        state.includeIds = includeIds
        return state
      }
      else if (action.payload.entityType === "groupedCompanies") {
        if (state.groupCompanies) {
          let groupedCompanies = cloneDeep(state.groupCompanies)
          Object.keys(groupedCompanies).map((role) => {
            groupedCompanies[role].map((entity) => {
              if (entity.company_id === action.payload.id) {
                if (action.payload.isSelected === true) {
                  entity.is_selected = true
                  currnetIndexes++
                }
                else {
                  entity.is_selected = false
                  currnetIndexes--
                }
              }
            })
          })
          const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
          state.selectorControlSelectionCount = currnetIndexes
          state.groupCompanies = groupedCompanies
          state.includeIds = includeIds
          state.excludeIds = excludeIds
          return state
        }
      }
      else if (action.payload.entityType === "companyProjects") {
        if (state.projectDataForRoleId) {
          let groupedProjects = cloneDeep(state.projectDataForRoleId)
          groupedProjects.map((entity) => {
            if (entity.project_id === action.payload.id) {
              if (action.payload.isSelected === true) {
                entity.is_selected = true
                currnetIndexes++
              }
              else {
                entity.is_selected = false
                currnetIndexes--
              }
            }
          })
          const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
          state.selectorControlSelectionCount = currnetIndexes
          state.projectDataForRoleId = groupedProjects
          state.includeIds = includeIds
          state.excludeIds = excludeIds
          return state
        }
      }
      else if (action.payload.entityType === "relatedCompaniesJointProjects") {

        if (state.jointProjects && state.jointProjects.projects && state.jointProjects.projects.length > 0) {
          let jointprojects = cloneDeep(state.jointProjects.projects)
          jointprojects && jointprojects.map((entity) => {
            if (entity.project_id === action.payload.id) {
              if (action.payload.isSelected === true) {
                entity.is_selected = true
                currnetIndexes++
              }
              else {
                entity.is_selected = false
                currnetIndexes--
              }
            }
          })
          const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
          state.selectorControlSelectionCount = currnetIndexes
          state.jointProjects.projects = jointprojects
          state.includeIds = includeIds
          state.excludeIds = excludeIds

        } else if (state?.companyRelatedCompanies?.length > 0) {
          let companyRelatedCompany = cloneDeep(state.companyRelatedCompanies)
          companyRelatedCompany.map((entity) => {
            if (entity.company_id === action.payload.id) {
              if (action.payload.isSelected === true) {
                entity.is_selected = true
                currnetIndexes++
              }
              else {
                entity.is_selected = false
                currnetIndexes--
              }
            }
          })
          const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
          state.selectorControlSelectionCount = currnetIndexes
          state.companyRelatedCompanies = companyRelatedCompany
          state.includeIds = includeIds
          state.excludeIds = excludeIds
        }
        return state
      }
      else if (action.payload.entityType === "worksWithCompanies") {
        if (state.worksWithCompanies && state.worksWithCompanies.companies && state.worksWithCompanies.companies.length > 0) {
          let workCompany = cloneDeep(state.worksWithCompanies.companies)
          workCompany && workCompany.map((entity) => {
            if (entity.company_id === action.payload.id) {
              if (action.payload.isSelected === true) {
                entity.is_selected = true
                currnetIndexes++
              }
              else {
                entity.is_selected = false
                currnetIndexes--
              }
            }
          })
          const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
          state.selectorControlSelectionCount = currnetIndexes
          state.worksWithCompanies.companies = workCompany
          state.includeIds = includeIds
          state.excludeIds = excludeIds
          return state
        }
      }

      else {
        return state
      }
    },
    resetIncludeExcludeIds: (state, action) => {
      state.excludeIds = action.payload
      state.includeIds = action.payload
      return state
    },
    updateSelectedRecordCount: (state, action) => {
      state.selectedRecordsCount = action.payload
      return state
    },
    updateActivityIsClicked: (state, action) => {
      state.activityIsClicked = action.payload
      return state
    },
    updateCompaniesRoles: (state, action) => {
      state.companyRoles = action.payload
      return state
    },
    updateCompanyProjectData: (state, action) => {
      state.companyProjects = action.payload
      return state
    },
    updateGroupCompaniesDetails: (state, action) => {
      state.groupCompanies = action.payload
      return state
    },
    updatePeopleOnOtherProject: (state, action) => {
      state.PeopleOnOtherProject = action.payload
      return state
    },
    updateOtherOrgRoles: (state, action) => {
      state.OtherOrgRoles = action.payload
      return state
    },

    updateIsProjectSelected: (state, action) => {
      state.isProjectSelected = action.payload.isProjectSelected
      state.objectId = action.payload.objectId
      return state
    },
    updateCompanyProjectRoles: (state, action) => {
      state.companyProjectRoles = action.payload
      return state
    },
    updateProjectDataForRoleId: (state, action) => {
      state.projectDataForRoleId = action.payload.projects
      state.projectDataForRoleIdCount = action?.payload?.projects?.length > 0 ? action?.payload?.aggregation?.project_count : 0
      return state
    },
    updateShowMoreOffsetLimit: (state, action) => {
      state.showMoreOffsetLimit = action.payload
      return state
    },
    updateCompanyRelatedRoles: (state, action) => {
      state.companyRelatedRoles = action.payload
      return state
    },
    updateCompanyRelatedCompanies: (state, action) => {
      state.companyRelatedCompanies = action.payload.companies
      state.companyRelatedCompaniesCount = action?.payload?.companies?.length > 0 ? action?.payload?.aggregation?.company_count : 0
      return state
    },
    updatePeopleProjectRoles: (state, action) => {
      state.peopleProjectRoles = action.payload
      return state
    },

    updatePeopleProjectDataForRoleId: (state, action) => {
      state.projectDataForPeopleRoleId = action.payload.projects
      return state
    },

    updateWorksWithCompaniesProjects: (state, action) => {
      state.worksWithCompaniesProjects = action.payload.projects
      state.worksWithCompaniesProjectsCount = action.payload.aggregation.project_count

      return state
    },
    updateWorksWithCompanies: (state, action) => {
      state.worksWithCompanies = action.payload
      state.worksWithCompaniesCount = action.payload?.aggregation ? action.payload.aggregation.company_count : 0
      return state
    },
    updateJointProjects: (state, action) => {
      state.jointProjects = action.payload
      state.jointProjectsCount = action.payload?.aggregation?.project_count
      return state
    },
    updateIsJointProjectViewed: (state, action) => {
      state.isJointProjectViewed = action.payload
      return state
    },
    updateTeams: (state, action) => {
      state.teams = action.payload
      return state
    },
    updateLicences: (state, action) => {
      state.licences = action.payload
      return state
    },
    fetchShowMoreListForWorkWithCompaniesProjects: (state, action) => {
      state.worksWithCompaniesProjects = state?.worksWithCompaniesProjects.concat(action?.payload?.projects)
      state.worksWithCompaniesProjectsCount = action.payload.aggregation.project_count
      return state;
    },
    fetchShowMoreListForGC: (state, action) => {
      const groupCompanies = state.groupCompanies
      const Subsidiaries = [...groupCompanies.Subsidiaries];
      const newCompanyList = action.payload.Subsidiaries;
      Subsidiaries.push(...newCompanyList);
      groupCompanies.Subsidiaries = Subsidiaries;
      state.groupCompanies = groupCompanies
      return state;
    },
    updateCompanyIdForJointProjects: (state, action) => {
      state.companyIdForJointProjects = action.payload
      return state
    },
    fetchNoDetailsPage: (state, action) => {
      state.pageNotFoundFlag = action.payload
    },
    updateIfMoreProjectDataForRoleIdAvailable: (state, action) => {
      state.isProjectDataForRoleIdShowMoreActive = action.payload
      return state;
    },
    updateIfShowMoreForGroupCompaniesAvailable: (state, action) => {
      state.isGroupComapniesShowMoreActive = action.payload
      return state;
    },
    updateIfMoreCompanyRelatedCompaniesAvailable: (state, action) => {
      state.isCompanyRelatedCompaniesShowMoreActive = action.payload
      return state;
    },
    updateRelationshipAnyRoleIdsFirsList: (state, action) => {
      state.relationshipRoleIdsFirstList = action.payload
    },
    updateRelationshipAnyRoleIdsSecondList: (state, action) => {
      state.relationshipRoleIdsSecondList = action.payload
    },
    updateSelectorControlSelectionCount: (state, action) => {
      state.selectorControlSelectionCount = action.payload
    },
    updateIfMoreJointProjectsAvailable: (state, action) => {
      state.isJointProjectsShowMoreActive = action.payload
      return state;
    }

  }
})

export const { fetchDetailsPage, tagsDetailForObjectId, tagsDetailForAllObjectType, userActivityCountData,
  notesDetailForObjectId, notesDetailForAllType, shareRecipientsDetail, shareDetails, shareForAllEntity, rolesForProject,
  initializeCollabFilter, updateCollabCheckboxFilter, collabList,
  isNoCollab, noMoreCollaboration, updateFavListSelection, updateListSelection, rolesData, isPrint, peopleWorkingOnRolesCompanies,
  companiesForPeopleWorkingOnProject, summaryActivityDetail, isProjectFramework, updatePrimaryFramework,
  updateAssociateFramework, updateIsAssociateFrameworkShowMore, updateSelectedTagDetails, removeSelectedTagDetails, updateTabDetails,
  updateEventInDetails, updateProcessId, updateIsSelectedToSpecificRow, resetIncludeExcludeIds, updateSelectedRecordCount,
  updateActivityIsClicked, updateCompaniesRoles, updateCompanyProjectData, updateGroupCompaniesDetails,
  updateIsProjectSelected, updateCompanyProjectRoles, updateProjectDataForRoleId, updateShowMoreOffsetLimit, updatePeopleOnOtherProject,
  updateOtherOrgRoles, updateCompanyRelatedRoles, updateCompanyRelatedCompanies, updateWorksWithCompaniesProjects, updateWorksWithCompanies,
  updateJointProjects, updateIsJointProjectViewed, updatePeopleProjectDataForRoleId, updatePeopleProjectRoles, updateTeams, updateLicences,
  updateCompanyIdForJointProjects, fetchNoDetailsPage, fetchShowMoreListForWorkWithCompaniesProjects, updateIfShowMoreForGroupCompaniesAvailable,
  updateIfMoreProjectDataForRoleIdAvailable, fetchShowMoreListForGC, updateIfMoreCompanyRelatedCompaniesAvailable,
  updateRelationshipAnyRoleIdsFirsList, updateRelationshipAnyRoleIdsSecondList, updateSelectorControlSelectionCount, updateIfMoreJointProjectsAvailable } = projectSlice.actions

export const projectReducer = projectSlice.reducer