import React, { useState } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';

import PeopleCurrentProject from "./peopleTab/peopleCurrentProject";
import { useLocation } from 'react-router-dom';
import { getSectorNo } from '../../../../utils';
import { sendFeedback } from '../../apiclient';
import CommonPlaceholder from '../../framework/commonPlaceholder';


const PeopleTab = () => {
    const location = useLocation().pathname;
    const [activeTab, setActiveTab] = useState(location.split('/')[3] == 0 ? '2' : '1');
    const [feedbackmodal, toggleFeedbackModal] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState('')
    const details = useSelector((state) => state.project)
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleSendFeedback = () => {
        let sectorDetails = getSectorNo(window.location)
        let description= `Sector:${sectorDetails && sectorDetails.sectorName}\nProduct:${sectorDetails && sectorDetails.productName}\nCompany: ${details && details.detailsPage.company_id}\nDetails:${window.location.href }\nType: Feedback \n====================== \n ${feedbackValue}`;
        toggleFeedbackModal(false)
        let userNo = user.user.user_details.user_id
        let payload = {
            object_type: "company", object_id: details && details.detailsPage.company_id,
            user_id: userNo, description
        }
        sendFeedback(payload, dispatch)
    }

    const toggleModal = () =>{
        toggleFeedbackModal(!feedbackmodal)
        setFeedbackValue('')
    }

    const handleChange = (event) =>{
        setFeedbackValue(event.target.value)
    }

    const renderDetails = () => {
        return (
            <>
                <div className="tab-pane active">
                    {location.split('/')[3] > 0 &&
                        details && details.peopleWorkingOnRole && details && details.peopleWorkingOnRole.length == 0 &&
                        details && details.PeopleOnOtherProject && details && details.PeopleOnOtherProject.length == 0 &&
                        details && details.OtherOrgRoles && details && details.OtherOrgRoles.length == 0 ?
                        <span>No people found working on this project </span>
                        :
                        <div className="box-view">
                            <Row className="m-0">
                                <Col sm="12" md="12" lg="3" className="p-0 nav-col">
                                    <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select to view people</p>
                                    </div>
                                    <Nav tabs vertical className="mb-5">
                                        <NavItem className={location.split('/')[3] == 0 ? 'hide' : ''}>
                                            <NavLink
                                                className={activeTab === '1' ? 'active' : ''}
                                                onClick={() => { toggle('1') }}
                                            >
                                                People working on this project
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === '2' ? 'active' : ''}
                                                onClick={() => { toggle('2') }}
                                            >
                                                People on other projects
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === '3' ? 'active' : ''}
                                                onClick={() => { toggle('3') }}
                                            >
                                                Other roles within this organisation
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col sm="12" md="12" lg="9" className="p-0 detail-col">
                                    <TabContent activeTab={activeTab} className="p-0">
                                        <TabPane tabId="1">
                                            <div className="selected-col p-3"><p className="m-0 col-heading">People working on the project</p></div>
                                            <PeopleCurrentProject peopleList={details && details.peopleWorkingOnRole} activeTab={activeTab} prevNextList="peopleWorkingOnRole" />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="selected-col p-3"><p className="m-0 col-heading">People working in this company</p></div>
                                            <PeopleCurrentProject peopleList={details && details.PeopleOnOtherProject} activeTab={activeTab} prevNextList="peopleOnOtherProject" />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <PeopleCurrentProject peopleList={details && details.OtherOrgRoles} activeTab={activeTab} prevNextList="peopleOtherOrgRoles" />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </div>}
                </div>
            </>
        );
    }

    const renderLoader = () => {
        return (
            <>
                {/* <div className="people-empty-message ml-3 text-center"> */}
                <div className="d-flex justify-content-center align-items-center h-100 flex-column people-empty-message">
                    <strong>No people attached to projects at this time,</strong>
                    <p>for more information submit your enquiry to research team <span className="company-enquiry-link" onClick={toggleModal}>Company enquiry</span></p>
                    {<div class="feedback-cta ml-2">
                        {/* <button class="user-ctrl-btn feedback-btn" onClick={toggleModal}><Icon color="#053874" size={21} icon="icon-gen-enquiry" className="me-1" />Company enquiry</button> */}
                        <div class={`feedback-panel ${feedbackmodal ? 'show' : 'hide'}`}>
                            <div class="note-list-wrapper" data-intro="Send an enquiry to our<br/> research team " data-position="right" data-class="cd-enquiry-info">
                                <div class="note-form input-group">
                                    <div class="note-input">
                                        <textarea maxLength="1834" rows="4" placeholder="Submit your enquiry to our research team" value={feedbackValue} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            {feedbackValue.length <= 0 ?
                                (<button className="primary-btn btn-send" disabled="disabled">Send</button>) :
                                (<button className="primary-btn btn-send" onClick={handleSendFeedback}>Send</button>)}
                            <button className="outline-btn btn-feedback-cancel" onClick={toggleModal}>Cancel</button>
                        </div>
                    </div>}
                     <div className="empty-people-image"></div>
                </div>
            </>
        )
    };

    return (
        details && details.PeopleOnOtherProject && details.PeopleOnOtherProject.length !== 0 ?
            renderDetails()
            :
            details && details.PeopleOnOtherProject && details.PeopleOnOtherProject.length === 0 ?
            renderLoader()
            :
            <CommonPlaceholder />
    )
}

export default PeopleTab;