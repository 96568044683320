import React from 'react';
import AlertMessage from '../../components/alerts';
import CalendarMain from '../../components/calendar';
import TopHeader from '../../components/header';
import LeftPanel from "../../components/leftpanel";

const Calendar = () => {

    return (

        <div id="container">
            <TopHeader />
            <div className="sidebar-mini">
                <LeftPanel />
                <div id="message-container">
                    <AlertMessage />
                </div>
                <CalendarMain />
            </div>
        </div>
    );
}

export default Calendar;