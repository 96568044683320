import React from "react";
import AlertMessage from "../../components/alerts";
import LeftPanel from "../../components/leftpanel";
import SearchFormIndex from "../../components/search";
import TopHeader from "../../components/header";


const Landing = () => {
    return (
        <div id="container">
            <TopHeader/>
            <div className="sidebar-mini">
                <LeftPanel/>
                <div id="message-container">
                    <AlertMessage />
                </div>
                <div className="main-content">
                    <SearchFormIndex />
                </div>
            </div>
        </div>
    )
}

export default Landing;