import _, { cloneDeep } from "lodash"
import { displayCurrency } from "../../utils"

export function setDataForProjectDetails(payload) {
    payload.objectType = 'project'
    payload.objectId = payload.project_id
    payload.longitude = payload.project_longitude ? payload.project_longitude : 0
    payload.latitude = payload.project_latitude ? payload.project_latitude : 0
    payload.name = payload.project_title
    payload.address3 = payload.project_site3
    return payload
}

export function getCategoriesProjectDetails(categories) {
    let finalCategory = ''
    categories.filter((category) => {
        finalCategory += ' ~ ' + category.description
        category.children.map((childCategory) => {
            finalCategory += ' ~ ' + childCategory.description
        })
    })
    return finalCategory.slice(3)
}

export function notesDataFormatter(payload) {
    payload.notes.filter((note) => {
        note.created_on = Date.parse(note.created_on)
        note.updated_on = note.updated_on ? Date.parse(note.updated_on) : null
        note.updated_date = note.updated_on ? note.updated_on : note.created_on
    })
    return payload
}

export function setProjectDataForCalendarEvents(projectData, events) {
    _.map(projectData, (project) => {
        _.map(events, (event) => {
            if (project.project_id === event.object_id) {
                event.name = project.project_title ? project.project_title : ''
                event.archive = project.archive ? project.archive : ''
                event.id = project.project_id ? project.project_id : ''
                event.value = project.project_value ? displayCurrency(project.project_value) : ''
                event.projectLocation = project.project_site3 ? project.project_site3 : ''
                event.projectLocation = project.project_postcode ? event.projectLocation + ', ' + project.project_postcode : event.projectLocation
                event.lastPublished = project.project_last_published ? project.project_last_published : ''
            }
        })
    })
    return events
}

export function rolesPostProcessing(roleGroups, response) {
    let roleDetails = []
    _.map(response.data, (value, key) => {
        let roles = [];
        value.map((role) => {
            let foundRole = roles.find((r) => r.roleNo === role.role_code && r.roleName === role.role_name);
            if (foundRole) {
                foundRole.companies.push(role);
            } else {
                roles.push({ 'roleNo': role.role_code, 'roleName': role.role_name, 'companies': [role] });
            }
            roleDetails.push({ 'company_id': role.company_id, 'role_name': role.role_name });
        });
        roleGroups.roleGroups.push({ 'groupName': key, 'roles': roles });
    });
    _.map(roleGroups.roleGroups, (value) => {
        value.roles.map((roleGroup) => {
            roleGroup.companies.map((company) => {
                var role_name = '';
                roleDetails.map((role) => {
                    if (company.company_id == role.company_id) {
                        role_name += role.role_name + ', ';
                    }
                });
                company.role_desc = role_name;
                setDataForCompanyDetails(company)
            });
        });
    });
    return roleGroups
}

export function teamsUsersDataMergeProcessing(users, teams_data) {
    let http_response = []
    _.map(teams_data, (team) => {
        let teams = {};
        teams.created_on = team.created_on
        teams.team_no = team.team_no
        teams.name = team.team_name
        if (team.user_ids) {
            let users_response = fetchUserObjects(users, team)
            if (users_response.length > 0)
                teams.users = users_response
        }
        http_response.push(teams)
    })
    return teamsDataPostProcessing(http_response)
}

function teamsDataPostProcessing(data) {
    let teams = {};
    for (let i = 0; i < data.length; i++) {
        let teamName = data[i].name.split();
        data[i].initials = teamName.length > 1 ? teamName[0][0].toUpperCase() + teamName[1][0].toUpperCase() : teamName[0][0].toUpperCase();
        teams[data[i].team_no] = data[i]
    }
    return teams
}

function fetchUserObjects(users, team) {
    let user_list = []
    _.map(team.user_ids, (user_id) => {
        _.map(users, (user) => {
            if (user_id === user.user_id) {
                let team_user = [user]
                if (team_user.length > 0)
                    user_list.push(team_user[0])
            }
        })
    })
    return user_list
}

export function addClients(final_response) {
    var data = cloneDeep(final_response)
    data.map(function (item) {
        item.objectId = item.company_id
        item.objectType = 'project'
        delete item.company_id
    })
    return data
}

export function setCompanyDataForCalendarEvents(companyData, events) {
    _.map(companyData, (company) => {
        _.map(events, (event) => {
            if (company.company_id === event.object_id) {
                event.name = company.company_name ? company.company_name : ''
                event.id = company.company_id ? company.company_id : ''
                event.phone = company.company_phone ? company.company_phone : ''
                event.companyLocation = company.company_address4 ? company.company_address4 : ''
                event.companyLocation = company.company_postcode ? event.companyLocation + ', ' + company.company_postcode : event.companyLocation
            }
        })
    })
    return events
}
export function setDataForCompanyDetails(payload) {
    payload.objectType = 'company'
    payload.objectId = payload.company_id
    payload.longitude = payload.company_longitude ? payload.company_longitude : 0
    payload.latitude = payload.company_latitude ? payload.company_latitude : 0
    payload.name = payload.company_name
    payload.address3 = payload.company_address3
    return payload
}

export function setDataForPeopleDetails(payload) {
    payload.objectType = 'people'
    payload.objectId = payload.person_id
    payload.longitude = payload.company_longitude
    payload.latitude = payload.company_latitude
    payload.name = payload.company_name
    payload.address3 = payload.company_address3
    return payload
}

export function processEvents(events) {
    let eventsArr=[];
    _.map(events, (event) => {
        event.id = event.object_id
        event.name = event.object_type == 'project' ? event.project_title ? event.project_title : '' : event.object_type == 'company' ? event.company_name ? event.company_name : '' : ""
        event.value = event.object_type == 'project' ? event.project_value ? displayCurrency(event.project_value) : '' : ""
        event.projectLocation = event.object_type == 'project' ? event.project_site3 ? event.project_site3 : '' : ""
        event.projectLocation = event.object_type == 'project' ? event.project_postcode ? event.projectLocation + ', ' + event.project_postcode : event.projectLocation : ""
        event.lastPublished = event.object_type == 'project' ? event.project_last_published ? event.project_last_published : '' : ""
        event.phone = event.object_type == 'company' ? event.company_phone ? event.company_phone : '' : ""
        event.companyLocation = event.object_type == 'company' ? event.company_address4 ? event.company_address4 : '' : ""
        event.companyLocation = event.object_type == 'company' ? event.company_postcode ? event.companyLocation + ', ' + event.company_postcode : event.companyLocation : ""
        eventsArr.push(event)
    })
 
    return eventsArr
}

export function setDataForContactDetails(payload) {
    payload.objectType = 'people'
    return payload
}