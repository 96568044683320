import React from 'react';

import MasqueradeUser from '../../components/masqueradeuser';

const Masquerade = () => {
	
	return (
		<div className="masquerade">
			<MasqueradeUser />
		</div>
	);
}

export default Masquerade;