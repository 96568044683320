import React, { useState, useEffect } from 'react'
import { Button, Input, InputGroup } from 'reactstrap'
import IcomoonReact from "react-icomoon";
import CreatableSelect from 'react-select'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import iconSet from "../../../common/selection.json"
import { addShare, deleteShare } from '../../apiclient';
import { createTeamList, getInitials, getSharedListBySharedDate } from '../../../../utils';

const ActivitiesShareDetails = () => {
  const [sharedMessage, setSharedMessage] = useState('')
  const [multiInputValue, setMultiInputValue] = useState([])
  const [shareDetails, setSharedDetails] = useState([])
  const [sharedUserId, setSharedUserId] = useState()
  const [teamList, setTeamList] = useState()
  const [options, setOptions] = useState({})
  const userData = useSelector(state => state.user.user)
  const project = useSelector(state => state.project)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleOnChange = (values) => {
    let options = []
    values.map(function (value) {
      if (!(value.value === 'Teams' || value.value === 'Users')) {
        options.push(value)
      }
    })
    setMultiInputValue(options)
  }

  useEffect(() => {
    let recipientOptions = []
    let shareDetails = []
    let teamListData = createTeamList(user?.teamListDetails)
    recipientOptions.push({ value: 'Everyone', label: 'Everyone', initials: '-', email: '-', sharedType: '-', className: 'everyone-class' })
    recipientOptions.push({ value: 'Teams', label: 'Teams', initials: '-', email: '-', sharedType: '-' })
    if (teamListData && teamListData.length > 0) {
      teamListData && teamListData.map(team => {
        recipientOptions.push({ value: team.team_no, label: team.name, initials: '-', email: '-', sharedType: 'T', className: 'teams-class' })
      })
    }
    recipientOptions.push({ value: 'Users', label: 'Users', initials: '-', email: '-', sharedType: '-' })
    if (project && project.fetchShareRecipients && project.fetchShareRecipients.length > 0) {
      project && project.fetchShareRecipients && project.fetchShareRecipients.map(user => {
        recipientOptions.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email, sharedType: 'U' })
      })
    }
    if ((project.detailsPage.objectType === "project"||project.detailsPage.objectType === "company") && (user?.shareInfoDetails?.sharedInfo)) {
      shareDetails = getSharedListBySharedDate(user?.shareInfoDetails?.sharedInfo)
    
    }
    setOptions(recipientOptions)
    setSharedDetails(shareDetails)
    setTeamList(teamList)
  }, [user, user.shareInfoDetails])

  const handleShareMessage = () => {
    let sharedWith = []
    let payload ={}
    var objectId = project.detailsPage.objectId
    var objectType = project.detailsPage.objectType
    if(multiInputValue.length > 0)
    {
      multiInputValue.map(function (value) {
        if (value.value === 'Everyone') {
          sharedWith.push({ share_type: "company" })
        }
      })
      let userIds = [] 
      multiInputValue.filter(value => value.sharedType === 'U').map(inputValue => userIds.push(inputValue.value))
      sharedWith.push({ share_type: 'user' , user_id: userIds })
      let teamIds = [] 
      multiInputValue.filter(value => value.sharedType === 'T').map(inputValue => teamIds.push(inputValue.value))
      sharedWith.push({ share_type: 'team' , team_id: teamIds })
       payload = {
        "shared_with": sharedWith
      }
      if (sharedMessage !== "") {
        payload["shared_message"] = sharedMessage
      }
      addShare(objectId, objectType, payload, dispatch, user, userData)
    }
    else{
       payload = {
        "shared_with": []
      }
    }
   
    setMultiInputValue([])
    setSharedMessage('')
  }

  const handleCancel = () => {
    setSharedUserId('')
  }

  const handleShareDelete = (shareDetails, event) => {

    let payload = {
        'shared_with': [
          shareDetails.shared_type === 'U' ?
            {
              'shared_type': 'user',
              'user_id': [shareDetails.shared_id]
            }
            :
            shareDetails.shared_type === 'T' ?
              {
                'shared_type': 'team',
                'team_id': [shareDetails.shared_id]
              } :
              {
                'share_type': 'company',
              }
        ]
      }
    deleteShare(project.detailsPage.objectId, project.detailsPage.objectType, payload, dispatch, user, userData)
  }

  const teamInitials = (value) => {
    var teamName = value.trim().split(" ")
    var teamInitial = teamName.length > 1 ? teamName[0][0] + teamName[1][0] : teamName[0][0]
    return teamInitial.toUpperCase()
  }

  const shareOptionsRenderer = (option) => {
    return (
      <div className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? `block-title ${option.label === "Everyone" ? ' everyone-icon' : ''}` : 'list-user-team'}`} key={option.value}>
        {option.initials != '-' ? (<span className="user-dropdown-icon">{option.initials}</span>) : ((<span className="team-member-name team-ss-icon">{teamInitials(option.label)}</span>))}
        <span className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? 'select-header' : option.email == '-' ? 'team-name-class users-teams-title' : 'users-teams-title'}`}>{option.label}
          {option.email != '-' ? (<a className="email-address"> {option.email} </a>) : ('')}</span>
      </div>
    )
  }
  return (
    <div className="share-details share-tabShareDetails">
      <div className="share-details-wrapper">
        <div className="shareTabSelector">
          <InputGroup>
          <IcomoonReact iconSet={iconSet} color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="me-2 circle-border"/>
            <div className="shareWithInput">
              <CreatableSelect
                options={options}
                isClearable
                isMulti 
                value={multiInputValue}
                formatOptionLabel={(option) => shareOptionsRenderer(option)}
                placeholder={<span className='label-placeholder'> Enter user or team to share </span>}
                onChange={(newValue) => handleOnChange(newValue)}
              />
              <div className="shareMessage"><Input placeholder="Enter message to recipient(s)"
                onChange={(e) => setSharedMessage(e.target.value)} value={sharedMessage} maxLength={500}
              ></Input></div>
            </div>
            <div className="shareButton"><button className="primary-btn btn-share" onClick={() => handleShareMessage()}>Share</button></div>
          </InputGroup>
        </div>
      </div>
      <div className="collaborationBox mt-3">
        <div className="collaborationBoxList px-3">
          <div className="collaborationUpdateBlock">
            {
              shareDetails && Object.keys(shareDetails).length > 0 && Object.keys(shareDetails).map(shared => {
                return (
                  <div className="collaborationBy">
                    <div className="collabtn-updated-date">{shared}</div>
                    {shareDetails[shared].map(sharedObj => {
                      return (
                        <div className="collabtnBox d-flex">
                          <div className="user-name-icon">
                            {sharedObj.sharedBy.initials}
                          </div>
                          <div className="collabtnContent">
                            <div className="update-time">{moment(sharedObj.sharedOn).format("DD-MMM-YYYY")}</div>
                            <div className="update-activityby">{sharedObj.sharedBy.first_name + " " + sharedObj.sharedBy.last_name} shared with</div>
                            <div className="shared-activity">
                              <ul>
                                {sharedObj.shareWith.map(item => {
                                  return (
                                    <li className={`${item.type === "U" ? "user-icon" : item.type === "T" ? "teams-icon" : "everyone-icon"} sharetoremove`}>
                                      {item.name}
                                      {
                                        item.is_delete ?
                                          (
                                            <div>
                                              <span className="deleteShare" onClick={(event) => setSharedUserId(item.id)}></span>
                                              <div className={`share ${sharedUserId === item.id ? "show" : "hide"}`}>
                                                <Button className="btn-label-cancel" id={`cancel`} onClick={handleCancel}>Cancel</Button><span></span>
                                                <Button className="btn-label-delete" id={`delete`} onClick={() => handleShareDelete({ shared_type: item.type, shared_id: item.id })}>Delete</Button>
                                              </div>
                                            </div>
                                          ) :
                                          ('')
                                      }
                                    </li>
                                  )
                                })}
                              </ul>
                              <div className="note-activity">
                                <div className="note-actitvyContent">
                                  <p>{sharedObj.sharedMsg}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivitiesShareDetails