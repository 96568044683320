import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';
import { fetchFrameworkSuggetions, fetchSearchCount } from '../apiclient';
import { createCommonQuery } from '../../../utils/commonFunctions';
import { updateProjectFramework } from './redux/reducer';
import { updateFrameworkTags } from '../redux/reducer';
import _, { filter } from 'lodash';

const FrameworkFilter = (props) => {
    const filter = useSelector((state) => state.filter)
    const [tags, setTags] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [frameworkBlock, setFrameworkBlock] = useState(false);
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)

    const dispatch = useDispatch();

    useEffect(() => {
        let queryData = '';
        fetchFrameworkSuggetions(queryData, dispatch)
    }, [])

    useEffect(() => {
        let suggestions = []
        if (search?.frameworkOptions?.length > 0) {
            search?.frameworkOptions?.map(sugg => {
                suggestions.push({ id: sugg.id, name: sugg.description })
            })
            setSuggestions(suggestions)
        }
    }, [search?.frameworkOptions])


    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
        const updatedTags = tags.slice(0);
        updatedTags.splice(i, 1);
        const frameworkArray1 = updatedTags.filter((item) => item.id).map((item) => item.id);
        let frameworkArray = []
        updatedTags.map(item => {
            if (item?.id) {
                frameworkArray.push(item.id)
            }
        })
        let frameworkdata = {
            value: frameworkArray,
            dataObj: updatedTags,
            active: true,
            keyname: "projectFramework"
        }
        dispatch(updateProjectFramework(frameworkdata))
        props.variableHandler('projectFramework')
        setTags(updatedTags);
        dispatch(updateFrameworkTags(updatedTags))
        setFrameworkBlock(frameworkdata.active);
        
    };

    useEffect(()=>{
        if (search?.frameworkTagsData?.length === 0) {
            setTags(_.cloneDeep(search?.frameworkTagsData))
        }
    },[search.frameworkTagsData])

    useEffect(()=>{
        setTags(filter.filtersState[filter.selectedSearchType].projectFramework.dataObj)
    },[filter.filtersState[filter.selectedSearchType].projectFramework])

    const handleAddition = (tag) => {
        let objectIds = []
        objectIds.push(tag.id)
        setTagIds(tagIds.concat(objectIds))
        const tagsData = [].concat(tags, tag)
        let frameworkArray = []
        for (var i = 0; i < tagsData.length; i++) {
            if (tagsData[i]?.id) {
                frameworkArray.push(tagsData[i].id)
            }
        }
        let frameworkdata = {
            value: frameworkArray,
            dataObj: tagsData,
            active: true,
            keyname: "projectFramework"
        }
        dispatch(updateProjectFramework(frameworkdata))
        props.variableHandler('projectFramework')
        setTags(tagsData);
        dispatch(updateFrameworkTags(tagsData))
        let payload = createCommonQuery({
            key: "project_framework",
            operator: "=",
            value: tagIds.concat(objectIds)
        })
        // props.fetchEntityCounts({initTags: props.initTags})
        // fetchSearchCount(searchPersist.appliedContext, search, searchPersist, dispatch, payload)
    };

    const handleChange = (query) => {
        if (query.length >= 3) {
            fetchFrameworkSuggetions(query, dispatch)
        }
    };

    return (
        <div className="advanced-filter-grid" id="new_filter_framework">
            <div className="filterHeading">
                <h5 className="mb-3">Frameworks</h5>
            </div>
            <div className={`filterBlock ${frameworkBlock ? "activeBlock" : ""}`}>
                <div className="textcontent">
                    <Row className="align-items-center">
                        <Col xs={12} sm={8} md={8} lg={11}>Enter the name(s) of the Framework below</Col>
                    </Row>
                </div>
                <div className="sliderBlock">
                    <Row>
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="framework-frames">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <FormGroup className='row position-relative'>
                                            <div className="col-sm-12">
                                                <ReactTags
                                                    tags={tags}
                                                    suggestions={suggestions}
                                                    onDelete={handleDelete}
                                                    onAddition={handleAddition}
                                                    minQueryLength={3}
                                                    placeholderText={tags?.length > 0 ? '' : 'Type to enter'}
                                                    onInput={handleChange}
                                                    autofocus={false}
                                                    maxSuggestionsLength={suggestions?.length}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )

}
export default FrameworkFilter;