import React, { useEffect, useState } from 'react'
import { Label, Input, Col } from 'reactstrap';
import 'rc-slider/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateIncludeCompletedProj } from '../redux/reducer';
import { updateShowCompletedChecked } from './redux/reducer';

const IncludeCompletedProjectsFilter = (props) => {
    const searchPersist = useSelector((state) => state.searchPersist);
    const filter = useSelector((state) => state.filter)
    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch();
    let activeFilterValue = searchPersist.appliedContext
   
    const handleCustomCheckbox = (e) => {
        dispatch(updateShowCompletedChecked(e.target.checked))
        dispatch(updateIncludeCompletedProj(e.target.checked))
        setChecked(e.target.checked)
    };

    useEffect(()=>{
        setChecked(filter.filtersState[activeFilterValue].completedProject.active)
    },[filter.filtersState[activeFilterValue].completedProject])

    useEffect(() => {
        // fetchSearchCount(activeFilterValue, search_container, searchPersist, dispatch)
        // props.fetchEntityCounts()
        if(checked) {
            props.sendData({id: 2, componentId:'new_filter_scp', status:'active'})
        } else {
            props.sendData({id: 2, componentId:'new_filter_scp', status:'inactive'})
        }
    }, [checked])

    return (
        <div className="advanced-filter-grid" id="new_filter_scp">
            <div className={`filterBlock ${checked ? 'activeBlock' : ''}`}>
                <div className="multiStageBlock">
                        <Col xs={12} sm={8} md={8} lg={12} className='ps-0'>
                                <div className={`check-box-button`}>
                                    <Input id="includeCompletedProjects" name="includeCompletedProjects" type="checkbox" checked={checked} onChange={e => handleCustomCheckbox(e)} />
                                    <Label check for="includeCompletedProjects">Include completed projects</Label>
                                </div>
                        </Col>
                </div>
            </div>

        </div>
    )
}
export default IncludeCompletedProjectsFilter;
