import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
const initialState = {

  userActivity: {
    from: moment().startOf('isoWeek').toDate(),
    to: moment().toDate()
  },
  selectedValue: 'User Activity Period',

}

const userAdminPersistSlice = createSlice({
  name: 'userAdminPersist',
  initialState,
  reducers: {
    updateSelectedValue: (state, action) => {
      state.selectedValue = action.payload
      return state;
    },

    updateFromAndToDate: (state, action) => {
      state.userActivity = action.payload
      return state;
    }

  }
})

export const { updateSelectedValue, updateFromAndToDate } = userAdminPersistSlice.actions

export const userAdminPersistReducer = userAdminPersistSlice.reducer