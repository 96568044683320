import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash';
import { getIncludeExcludeIds } from '../../../utils/commonFunctions';

const initialState = {
  detailsPage: [],
  offsetLimitValue: {
    offset: 0,
    records: 21
  },
  isSharedSaveSearchActive: true,
  isLoaderActive: true,
}

const savedsearchSlice = createSlice({
  name: 'savedsearch',
  initialState,
  reducers: {
    savedsearchResults: (state, action) => {
      state.detailsPage = action.payload;
      return state
    },
    shareForAllEntity: (state, action) => {
      state.fetchShareForAllEntity = action.payload;
      return state
    },
    shareAllDetails: (state, action) => {
      state.shareInfoDetails = action.payload;
      return state
    },
    updateSavedSearchOffsetLimitValue: (state, action) => {
      state.offsetLimitValue = action.payload
      return state
    },
    isShowMoreActiveForSavedSearch: (state, action) => {
      state.isSavedSearchShowMoreActive = action.payload
    },
    updateSavedSearchShowMoreList: (state, action) => {
      state.detailsPage = state.detailsPage.concat(action.payload)
      return state
    },
    updateIsSharedSaveSearchActive: (state, action) => {
      state.isSharedSaveSearchActive = action.payload
      return state
    },
    updateSavedSearch: (state, action) => {
      let saved_searches = _.cloneDeep(state.detailsPage)
      saved_searches = _.map(saved_searches, saved_search => {
        if (parseInt(saved_search.saved_search_id) === parseInt(action.payload.saved_search_id)) {
          return action.payload
        } else {
          return saved_search
        }
      })
      state.detailsPage = saved_searches
    },
  updateIsLoaderActive: (state, action) => {
    state.isLoaderActive = action.payload
    return state
  }
  }
})

export const { savedsearchResults, shareForAllEntity, shareAllDetails, updateSavedSearchOffsetLimitValue, isShowMoreActiveForSavedSearch,
  updateSavedSearchShowMoreList, updateIsSharedSaveSearchActive, updateSavedSearch,updateIsLoaderActive } = savedsearchSlice.actions

export const savedsearchReducer = savedsearchSlice.reducer