import React, { useState } from 'react';
import { FormGroup, Label, InputGroup } from 'reactstrap';

function SubscriptionCriteriaMsgModal(props) {
    const [subscriptionMsgModal, setSubscriptionMsgModal] = useState(props.subscriptionMsgModalShow);

    const handleRequestDataToggle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.handleRequestDataToggle(event);
    }

    return (
        <div className={`feedback-panel d-block ${subscriptionMsgModal ? 'show' : 'hide'} ${props.fromInput ? 'fromPosition' : 'toPosition'}`}>
            <div className="note-list-wrapper">
                {props.rangeName === 'value' ? <h6 className="mb-2">Some of the range is outside your data selection</h6> : <h6 className="mb-2">This {props.rangeName} range is outside of your data selection</h6>}
                <p>To access this {props.rangeName} range, please request this data from your account manager</p>
            </div>
            <button className="primary-btn btn-send" onClick={handleRequestDataToggle}>Request data</button>
        </div>
    );
}

export default SubscriptionCriteriaMsgModal;
