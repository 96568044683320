import _ from 'lodash';
import getAxios from '../../utils/baseApi';
import { getRecordCount, removeArchivedProjects } from '../../utils/commonFunctions';
import { handleError } from '../../utils/errorHandler';
import { COMPANY_SEARCH_FIELDS, ENTITY_TYPE, MAX_API_CALLS, MIN_PROJECTS_REQUIRED_FOR_DISPLAY, PEOPLE_SEARCH_FIELDS, PROJECT_SEARCH_FIELDS } from '../../utils/settings';
import { setDataForCompanyDetails, setDataForContactDetails, setDataForProjectDetails } from '../details/postprocessing';
import { updateOffsetLimit } from './search/filters/redux/reducer';
import { fetchJustAskSamples, fetchSearchListForAllObjectType, fetchShowMoreList, fetchShowMoreListOfSelectedObjectIds, setLoading, updateApiCall, updateArchiveSearchList, updateEmoji, updateFirstJustAskCall, updateHierarchyBack, updateIfMoreResultAvailable, updateInvalidRequest, updatelistOfSelectedObjectIds, updateMaxApiCalls, updateNoResult, updateSearchLoader } from './search/redux/reducer';
import { initializeJustAskFilterState, initializeSort } from './utils';
import { addMessage, updateErrrorInApi, updateJustAskQuery, updateNoResultForExamples } from './search/redux/searchPeristReducer';
import { messageAlert } from '../../utils';
var axios = getAxios()

export const getJustAskSamples = (dispatch) => {
    axios.get('/justask/examples').then((response) => {
        if (response.status === 200) {
            dispatch(fetchJustAskSamples(response.data))
        }
    }).catch((error) => {
        if (error.response?.status === 404) {
            dispatch(updateNoResultForExamples(true))
        }
        else {
            handleError(error, dispatch)
        }
    })
}

export const getJustAskResponse = (dispatch, searchPersist, settings, user_id, jAskState, filter, userTexts) => {
    axios.get(`topics/ABI?questions=${userTexts}&user_id=${user_id}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(updateErrrorInApi(false))
                var jAskResponse = response.data
                jAskResponse.query = jAskResponse.query.replace(/\s+/g, ' ').trim();
                dispatch(updateJustAskQuery(jAskResponse.query))
                jAskResponse.offset = 0
                jAskResponse.records = 50
                // TODO :: When actual search_context will be returned from response remove below line
                jAskResponse.search_context = 'projects'
                dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
                jAskResponse.sort = initializeSort(jAskResponse, dispatch, searchPersist)
                var type = jAskResponse.search_context === "projects" ? 'project' : jAskResponse.search_context === "companies" ? 'company' : 'people';
                var clonedJson = { ...jAskResponse, object_type: type }
                // initializeJustAskFilterState(clonedJson, searchPersist, filter, dispatch)
                fetchSearchResults(jAskResponse, jAskState, dispatch, settings, searchPersist)

            }
            else if (response.status === 204) {
                dispatch(addMessage({ text: "I do not have knowledge to answer your question.", type: 'ai' }));
                updateDefaultDispatch(dispatch, type, true, false)
                dispatch(updateErrrorInApi(true))
            }

        }).catch((error) => {
            dispatch(updateErrrorInApi(true))
            if (error?.response?.status === 400) {
                dispatch(addMessage({ text: "A system error occurred. This will be fixed by our technical team.", type: 'ai' }));
            }
            else if (error?.response?.status === 500) {
                dispatch(addMessage({ text: "Just Ask is not available at the moment. Please try again later.", type: 'ai' }));
            }
            else {
                handleError(error, dispatch)
            }

        })
}

export const fetchSearchResults = (jAskResponse, jAskState, dispatch, settings, searchPersist) => {
    const context = jAskResponse.search_context
    const searchFields = context === "projects" ? PROJECT_SEARCH_FIELDS : context === "companies" ? COMPANY_SEARCH_FIELDS : PEOPLE_SEARCH_FIELDS;
    const url = `${context}?query=${jAskResponse.query}&fields=${searchFields}&offset=${jAskResponse.offset}&limit=${jAskResponse.records}&sort=${jAskResponse.sort}&event_type=search`
    const type = jAskResponse.search_context === "projects" ? 'project' : jAskResponse.search_context === "companies" ? 'company' : 'people';
    if (jAskState.maxApiCalls <= MAX_API_CALLS && Object.keys(jAskResponse.query).length > 0) {
        axios.get(url).then((resp) => {
            if (resp.status === 200) {
                dispatch(updateErrrorInApi(false))
                dispatch(updateArchiveSearchList(false))
                dispatch(updateInvalidRequest(false))
                dispatch(setLoading(false));
                if (jAskState.showSelectedCompanies || jAskState.showSelectedProjects) {
                    var count = type === 'project' ? resp.data.aggregation.project_count : type === 'company' ? resp.data.aggregation.company_count : resp.data.aggregation.people_count
                    let leadsFlags = type === 'project' ? resp.data.projects : resp.data.companies
                    setObjectData(leadsFlags, type)
                    dispatch(updateApiCall(true))
                    if (jAskResponse.offset > 0) {
                        dispatch(fetchShowMoreListOfSelectedObjectIds({ leadsFlags: leadsFlags, count: count, filterType: type }))
                    } else {
                        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: leadsFlags, count: count, filterType: type }));
                    }
                } else {
                    if (jAskResponse.offset === 0) {
                        dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
                    }
                    dispatch(updateHierarchyBack(false));
                    dispatch(updateMaxApiCalls(0))
                    // var leadsFlags = type === 'project' ? resp.data.projects : type === 'company' ? resp.data.companies : resp.data.people
                    var leadsFlags = resp.data.projects
                    setObjectData(leadsFlags, type);
                    var count = type === 'project' ? resp.data.aggregation.project_count : type === 'company' ? resp.data.aggregation.company_count : resp.data.aggregation.people_count
                    const updatedNarrative = jAskResponse?.narrative.replace('{{project_count}}', count);
                    dispatch(addMessage({ text: updatedNarrative, type: 'ai', request_id: jAskResponse?.request_id, query: jAskResponse?.query }));
                    dispatch(updateEmoji(''))
                    var responseData = resp.data ? { leadsFlags: leadsFlags, count: count, filterType: type }
                        : { leadsFlags: [], count: 0, filterType: type }
                    if (searchPersist.justAskConversation?.length === 0) {
                        // Log intercom event
                        window.Intercom('trackEvent', 'Just Ask conversation')
                        // Log event in opensearch
                        let payload = {
                            event_type: 150
                        }
                        logEvent(payload, dispatch);
                    }
                    if (resp.data && leadsFlags?.length > 0) {
                        resultList(count, jAskResponse, responseData, jAskState, settings, type, dispatch)
                        dispatch(updateSearchLoader(false))
                        dispatch(updateNoResult(false))
                        dispatch(updateApiCall(true))
                    } else {
                        updateResponseData(dispatch, jAskResponse, responseData);
                        dispatch(updateIfMoreResultAvailable(false))
                        dispatch(updateApiCall(true))
                    }
                }
                dispatch(updateFirstJustAskCall(true))
            } else if (resp.status === 204) {
                dispatch(addMessage({ text: "I was unable to perform this search for you.", type: 'ai' }));
                updateDefaultDispatch(dispatch, type, true, false)
                dispatch(updateErrrorInApi(true))
            }
        }).catch((err) => {
            updateDefaultDispatch(dispatch, type, true, true)
            dispatch(updateErrrorInApi(true))
            if (err.response?.status === 422 || err.response?.status === 400 || err.response?.status === 500) {
                dispatch(addMessage({ text: "I was unable to perform this search for you.", type: 'ai' }));
            }
            else {
                handleError(err)
                dispatch(updateApiCall(true))
            }
        })
    } else {
        updateDefaultDispatch(dispatch, type, false, false, true)
    }
}

const resultList = (count, query, responseData, jAskState, settings, type, dispatch) => {
    if (count > (query.offset + query.records)) {
        // This condition is filtering archived projects and updating limit for records based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && settings && settings.preferences && settings.preferences.archiving_enabled) {
            responseData = removeArchivedProjects(query, responseData)
            query = getRecordCount(query, responseData)
        }
        if (responseData.leadsFlags.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY && jAskState.maxApiCalls <= MAX_API_CALLS) {
            dispatch(updateMaxApiCalls(jAskState.maxApiCalls + 1))
            updateResponseData(dispatch, query, responseData);
        } else {
            query.offset > 0 ? dispatch(fetchShowMoreList(responseData)) : dispatch(fetchSearchListForAllObjectType(responseData))
            dispatch(updateIfMoreResultAvailable(true))
        }
    } else {
        // This condition is filtering archived projects and updating limit for records based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && settings && settings.preferences && settings.preferences.archiving_enabled) {
            responseData = removeArchivedProjects(query, responseData)
            query = getRecordCount(query, responseData)
        }
        updateResponseData(dispatch, query, responseData);
        dispatch(updateIfMoreResultAvailable(false))
    }
}

function updateResponseData(dispatch, query, responseData) {
    dispatch(updateOffsetLimit({ offset: query.offset, records: query.records }));
    query.offset > 0 ? dispatch(fetchShowMoreList(responseData)) : dispatch(fetchSearchListForAllObjectType(responseData));
}

function setObjectData(leadsFlags, type) {
    _.map(leadsFlags, (payload) => {
        if (type === 'project') {
            setDataForProjectDetails(payload);
        } else if (type === 'company') {
            setDataForCompanyDetails(payload);
        } else if (type === 'people') {
            setDataForContactDetails(payload)
        }
    });
}

const updateDefaultDispatch = (dispatch, type, noResult, requestFlag, noQuery = false) => {
    dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
    dispatch(updateIfMoreResultAvailable(false))
    dispatch(updateSearchLoader(false))
    dispatch(updateNoResult(noResult))
    dispatch(updateInvalidRequest(requestFlag))
    dispatch(updateApiCall(true))
    if (noQuery) {
        dispatch(updateMaxApiCalls(0))
        dispatch(updateArchiveSearchList(true))
    }
}

export const sendFeedback = (dispatch, user_id, payload, selectedEmoji) => {
    axios.post(`/feedback?user_id=${user_id}`, payload).then((response) => {
        if (response.status === 200) {
            const message = response.data.message;
            messageAlert(dispatch, message, 'success')
            dispatch(updateEmoji(selectedEmoji))
        }
    }).catch((error) => {
        const message = error.response?.data?.message;
        if (message !== undefined && typeof message === 'object') {
            messageAlert(dispatch, message[0], 'danger')
        } else if (message !== undefined) {
            messageAlert(dispatch, message, 'danger')
        }
        handleError(error, dispatch)
    })
}

export function logEvent(payload, dispatch) {
    axios.post('log/request', JSON.stringify(payload))
        .catch(function (error) {
            handleError(error, dispatch)
        })
}