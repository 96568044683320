import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { MasqueradeContext } from './context';
import MasqueradeHeader from './header';
import MasqueradeLeftPanel from './leftpanel';
import MasqueradeUserList from './masquerade';
import MasqueradeLoader from './masqueradeloader';
import '../login/login.scss'

const MasqueradeUser = () => {
	const [customerUserList, setCustomerUserList] = useState([])
	const [isRedirectedUser, setIsRedirectedUser ] = useState(false)
	const [isMasqueradeAPILoading, setIsMasqueradeAPILoading ] = useState(false)

	const dispatchMasqueradeEvent = (actionType, payload) => {
		switch (actionType) {
			case 'MASQUERADE_USER_LIST':
				setCustomerUserList(payload);
			case 'REDIRECT_USER':
				setIsRedirectedUser(payload);
			case 'MASQUERADE_USER':
				setIsMasqueradeAPILoading(payload);
			default:
				return;
		}
	  };
	
	return (
		<MasqueradeContext.Provider value={{customerUserList, dispatchMasqueradeEvent}} >
			<div className="login-page">
			<MasqueradeHeader />
			{ isMasqueradeAPILoading ? (
                    <div>
                        <MasqueradeLoader />
                    </div>
                ) : isRedirectedUser ? (
                    <div>
                        <MasqueradeLoader />
                    </div>
                ) :
				<>
				<div id="maps"></div>
				<div className="loginContainer row">
					<Col className="container-fluid login-main-container">
						<Row className="main-row-container">
							<Col lg="12" className="align-items-top justify-content-center my-5 row pe-1">
								<Col lg="8" xl="8" className="pe-0 me-0">
									<Row>
										<MasqueradeLeftPanel />
										<MasqueradeUserList />
									</Row>
								</Col>
								
							</Col>
						</Row>
					</Col>
				</div>
				</>
				}
		</div>
		</MasqueradeContext.Provider>
	);
}

export default MasqueradeUser;