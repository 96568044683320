import React from "react";
import TopHeader from "../../components/header";
import Usersettings from "../../components/usersettings";
import LeftPanel from "../../components/leftpanel";
import AlertMessage from "../../components/alerts"

const UsersettingsPage = () => {
  return (
    <div id="container">
      <TopHeader />
      <div className="sidebar-mini">
        <LeftPanel />
        <div id="message-container">
          <AlertMessage />
        </div>
        <div className="main-content">
          <Usersettings />
        </div>
      </div>
    </div>
  );
};

export default UsersettingsPage;
