import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'reactstrap'
import { MasqueradeContext } from './context';
import nicp from '../common/images/nicp@2x.png';
import ons from '../common/images/ons@2x.png';

const MasqueradeLeftPanel = () => {
    const [ customerUserList, setCustomerUserList ] = useState({})

    const context = useContext(MasqueradeContext);

    useEffect(() => {
        if (context.customerUserList && context.customerUserList.user) {
            setCustomerUserList(context.customerUserList.user)
        }
    }, [context])

	return (
		<Col className="masquerade login-info col-12 col-md-6 px-5">
        <div className="logo-container">
        </div>
        <div className="info">
          {
            (
            <div>
                <h5>Hi {customerUserList && customerUserList.hasOwnProperty('cars_user_name') && customerUserList.cars_user_name}</h5>
                <p className="info-sub">
                    Select the user from the list and click "Masquerade". This will sign you into the app as that user.
                    All features and data will be consistent with the customer selection and user settings. 
                </p>
                <p><span className="imp">Important:</span>Any changes you make will be saved to the users account and logged for auditing purposes against your cars account.</p>
                <div className="row">
                    <div className="col-12 col-sm-6">
                    <img src={nicp} />
                    <p className="partner-info">Partner of the Infrastructure and Projects Authority in providing the National Infrastructure and Construction Pipeline</p>
                    </div>
                
                    <div className="col-12 col-sm-6">
                    <img src={ons} />
                    <p className="partner-info">Chosen provider of Construction New Orders estimates to the ONS</p>
                    </div>

                </div>
            </div>
            
            )
            }
    </div>
    </Col>
	);
}

export default MasqueradeLeftPanel;