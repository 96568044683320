import _, { cloneDeep } from 'lodash';
import getAxios from '../../utils/baseApi';
import { handleError } from '../../utils/errorHandler';
import { ENTITY_TYPE, HISTORY_COMPANY_FIELDS, MIN_PROJECTS_REQUIRED_FOR_DISPLAY } from '../../utils/settings';
import { updateIfMoreResultAvailable, updateSearchList, updateTagList, updateNoResult, updateLeadsSearchListCount, updateSelectedSelectorControlValue, resetRelationalEntititySelectionCount, updateSearchListSelection, resetIncludeExcludeIds, updateProcessId, updateMyLeadOffsetLimitValue, updateShowMoreSearchList, storeUpdatesInMyLeads, updateSearchListForUpdates, updateShowMoreSearchListForUpdates, updateInValidRequest, updateLeadsSearchListCountForUpdates } from './reducer';
import { messageAlert } from '../../utils';
import { COMPANY_SEARCH_FIELDS, PROJECT_SEARCH_FIELDS } from '../../utils/settings';
import { getRecordCountForMyleads } from '../../utils/commonFunctions';
import { setDataForCompanyDetails, setDataForProjectDetails } from '../details/postprocessing';
import { updateSearchLoader } from '../search/redux/reducer';

var axios = getAxios()

let abortController = new AbortController();

function updateSortQuery(activeTab, searchPersist, dispatch) {
    return new Promise((resolve, reject) => {
        let sortQuery = {};

        if (searchPersist?.leadsSort?.[activeTab]?.[searchPersist.leadsAppliedContext[activeTab]]?.label.toLowerCase() === "nearest") {
            navigator.geolocation.getCurrentPosition(position => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;

                const latitudeKey = searchPersist.leadsAppliedContext[activeTab] === "project" ? "project_latitude" : "company_latitude";
                const longitudeKey = searchPersist.leadsAppliedContext[activeTab] === "project" ? "project_longitude" : "company_longitude";

                sortQuery = {
                    [latitudeKey]: { "operator": "=", "value": parseFloat(lat).toString() },
                    [longitudeKey]: { "operator": "=", "value": parseFloat(lng).toString() }
                };

                resolve(sortQuery);
            }, error => {
                reject(error);
            });
        } else {
            resolve(sortQuery);
        }
    });
}

export const fetchUpdatesResults = (searchPersist, myleads, dispatch, settings) => {
    // Cancel previous ongoing fetch request
    abortController.abort();
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    const signal = controller.signal;

    // Store the controller for possible cancellation
    abortController = controller;

    let detailType = searchPersist.leadsAppliedContext.updates
    let fields = searchPersist.leadsAppliedContext.updates === 'project' ? PROJECT_SEARCH_FIELDS : COMPANY_SEARCH_FIELDS
    let url = `updates?object_type=${detailType}&sort=-updated_on&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}&fields=${fields}`
    axios.get(url, { signal })
        .then((response) => {
            if (response.status === 200) {
                if (myleads.offsetLimitValue.offset === 0) {
                    dispatch(updateSearchListForUpdates([]))
                    dispatch(updateLeadsSearchListCountForUpdates(0))
                }
                var leadsFlags = response.data.updates
                var count = detailType === 'project' ? response.data?.aggregation?.project_count : response.data?.aggregation?.company_count
                if (leadsFlags.length > 0) {
                    updatesResultList(count, myleads, leadsFlags, settings, detailType, dispatch)
                    dispatch(updateNoResult(false))
                } else {
                    updateResponseDataForUpdatesList(dispatch, myleads, leadsFlags, count, false);
                }
            }
            else if (response.status === 204) {
                dispatch(updateSearchListForUpdates([]))
                dispatch(updateLeadsSearchListCountForUpdates(0))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateSearchLoader(false))
                dispatch(updateNoResult(true))
            }

        }).catch((error) => {
            if (error.response?.status === 422) {
                dispatch(updateSearchListForUpdates([]))
                dispatch(updateLeadsSearchListCountForUpdates(0))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateSearchLoader(false))
                dispatch(updateNoResult(true))
                dispatch(updateInValidRequest(true))
            } else {
                handleError(error, dispatch)
            }
        })
}

export const fetchUpdatesResultsbyID = (id, searchPersist, dispatch) => {
    let detailType = searchPersist.leadsAppliedContext.updates === 'project' ? 'projects' : 'companies'
    axios.get(`${detailType}/${id}/updates?sort=-updated_on&offset=0&limit=1000`).then((response) => {
        if (response.status === 200) {
            let updatesPayload = {
                objectId: id,
                updates: response.data.updates
            }
            dispatch(storeUpdatesInMyLeads(updatesPayload))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchFavouritesResults = async (searchPersist, myleads, dispatch, settings) => {
    // Cancel previous ongoing fetch request
    abortController.abort();
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    const signal = controller.signal;

    // Store the controller for possible cancellation
    abortController = controller;

    let detailType = searchPersist.leadsAppliedContext.favourite === 'project' ? 'projects' : 'companies';
    let fields = searchPersist.leadsAppliedContext.favourite === 'project' ? PROJECT_SEARCH_FIELDS : COMPANY_SEARCH_FIELDS;
    let sort = searchPersist?.leadsSort?.["favourite"]?.[searchPersist.leadsAppliedContext.favourite]?.value;
    let url = `${detailType}?favourite=true&fields=${fields}&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}&sort=${sort}`;

    if (searchPersist?.leadsSort?.["favourite"]?.[searchPersist.leadsAppliedContext.favourite]?.label.toLowerCase() === "nearest") {
        try {
            let query = await updateSortQuery("favourite", searchPersist, dispatch);
            url = !_.isEmpty(query) ? url + `&query=${JSON.stringify(query)}` : url;
        } catch (error) {
            console.error("Geolocation error:", error);
        }
    }

    axios.get(url, { signal })
        .then((response) => {
            if (response.status === 200) {
                if (myleads.offsetLimitValue.offset === 0) {
                    dispatch(updateSearchList([]));
                    dispatch(updateLeadsSearchListCount(0));
                }
                var leadsFlags = searchPersist.leadsAppliedContext.favourite === 'project' ? response.data.projects : response.data.companies;
                var count = response.data?.aggregation?.favourite_count;
                if (leadsFlags.length > 0) {
                    resultList(count, myleads, leadsFlags, settings, searchPersist.leadsAppliedContext.favourite, dispatch);
                    dispatch(updateNoResult(false));
                } else {
                    updateResponseData(dispatch, myleads, leadsFlags, count, false);
                }
            }
            else if (response.status === 204) {
                dispatch(updateSearchList([]));
                dispatch(updateLeadsSearchListCount(0));
                dispatch(updateIfMoreResultAvailable(false));
                dispatch(updateNoResult(true));
            }
        }).catch((error) => {
            handleError(error, dispatch);
        });
}

export const fetchTagLists = (dispatch) => {
    axios.get('tags').then((response) => {
        if (response.status === 200) {
            dispatch(updateTagList(response.data.tags))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchTagDetailsById = async (id, myleads, searchPersist, dispatch, listType, settings) => {
    let type = listType ? listType : searchPersist.leadsAppliedContext.tags
    let detailType = type === 'project' ? 'projects' : 'companies'
    let fields = type === 'project' ? PROJECT_SEARCH_FIELDS : COMPANY_SEARCH_FIELDS
    let sort = searchPersist?.leadsSort?.["tags"]?.[searchPersist.leadsAppliedContext.tags]?.value
    let url = `${detailType}?tag_id=${id}&fields=${fields}&sort=${sort}&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}`

    if (searchPersist?.leadsSort?.["tags"]?.[searchPersist.leadsAppliedContext.tags]?.label.toLowerCase() === "nearest") {
        try {
            let query = await updateSortQuery("tags", searchPersist, dispatch);
            url = !_.isEmpty(query) ? url + `&query=${JSON.stringify(query)}` : url;
        } catch (error) {
            console.error("Geolocation error:", error);
        }
    }

    axios.get(url)
        .then((response) => {
            if (response.status === 200) {
                if (myleads.offsetLimitValue.offset === 0) {
                    dispatch(updateSearchList([]))
                    dispatch(updateLeadsSearchListCount(0))
                }
                var leadsFlags = type === 'project' ? response.data.projects : response.data.companies
                var count = type === 'project' ? response.data?.aggregation?.project_count : response.data?.aggregation?.company_count
                if (leadsFlags.length > 0) {
                    resultList(count, myleads, leadsFlags, settings, type, dispatch)
                    dispatch(updateNoResult(false))
                } else {
                    updateResponseData(dispatch, myleads, leadsFlags, count, false);
                }
            } else if (response.status === 204) {
                dispatch(updateSearchList([]))
                dispatch(updateLeadsSearchListCount(0))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateNoResult(true))
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export const deleteTag = (tagId, dispatch) => {
    axios.delete(`tags/${tagId}`).then((response) => {
        if (response.status === 200) {
            fetchTagLists(dispatch)
            messageAlert(dispatch, 'Tag deleted successfully', 'success')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchHistoryResults = async (searchPersist, myleads, dispatch, settings) => {
    // Cancel previous ongoing fetch request
    abortController.abort();
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    const signal = controller.signal;

    // Store the controller for possible cancellation
    abortController = controller;

    let type = searchPersist.leadsAppliedContext.history
    let detailType = type === 'project' ? 'projects' : 'companies'
    let fields = type === 'project' ? (`${PROJECT_SEARCH_FIELDS},activity_date`) : HISTORY_COMPANY_FIELDS;
    let sort = searchPersist?.leadsSort?.["history"]?.[searchPersist.leadsAppliedContext.history]?.value
    let url = `${detailType}?viewed=true&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}&fields=${fields}&sort=${sort}`

    if (searchPersist?.leadsSort?.["history"]?.[searchPersist.leadsAppliedContext.history]?.label.toLowerCase() === "nearest") {
        try {
            let query = await updateSortQuery("history", searchPersist, dispatch);
            url = !_.isEmpty(query) ? url + `&query=${JSON.stringify(query)}` : url;
        } catch (error) {
            console.error("Geolocation error:", error);
        }
    }

    axios.get(url, { signal })
        .then((response) => {
            if (response.status === 200) {
                if (myleads.offsetLimitValue.offset === 0) {
                    dispatch(updateSearchList([]))
                    dispatch(updateLeadsSearchListCount(0))
                }
                var leadsFlags = type === 'project' ? response.data.projects : response.data.companies
                var count = response.data?.aggregation?.viewed_count
                if (leadsFlags.length > 0) {
                    resultList(count, myleads, leadsFlags, settings, type, dispatch)
                    dispatch(updateNoResult(false))
                } else {
                    updateResponseData(dispatch, myleads, leadsFlags, count, false);
                }
            } else if (response.status === 204) {
                dispatch(updateSearchList([]))
                dispatch(updateLeadsSearchListCount(0))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateNoResult(true))
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export const fetchArchieveList = (searchPersist, myleads, dispatch) => {
    // Cancel previous ongoing fetch request
    abortController.abort();
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    const signal = controller.signal;

    // Store the controller for possible cancellation
    abortController = controller;

    axios.get(`projects?archive=true&sort=${searchPersist?.leadsSort?.["archive"]?.["project"]?.value}&offset=${myleads?.offsetLimitValue?.offset}&fields=${PROJECT_SEARCH_FIELDS}&limit=${myleads?.offsetLimitValue?.records}`, { signal })
        .then((response) => {
            if (response.status === 200) {
                if (myleads?.offsetLimitValue?.offset > 0) {
                    dispatch(updateShowMoreSearchList(response.data.projects))
                }
                else {
                    dispatch(updateSearchList(response.data.projects))
                    dispatch(updateLeadsSearchListCount(response.data?.aggregation?.archive_count))
                }
                let isLoadMore = ((myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records) < response.data?.aggregation?.archive_count) ? true : false
                if (isLoadMore) {
                    dispatch(updateIfMoreResultAvailable(true))
                } else {
                    dispatch(updateIfMoreResultAvailable(false))
                }
                dispatch(updateNoResult(false))
            }
            else if (response.status === 204) {
                dispatch(updateSearchList([]))
                dispatch(updateLeadsSearchListCount(0))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateNoResult(true))
            }
        })
        .catch((error) => {
            handleError(error, dispatch)
        })

}

export const updateMyLeadsSelectorControlTags = (payload, leads, searchPersist, dispatch) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    let payload1 = cloneDeep(payload)
    delete payload1['listName'];
    try {
        axios.post(`${objectType}/tags`, payload1)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    messageAlert(dispatch, `Selected ${objectType} have been tagged.`, 'success')
                    dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
                    dispatch(updateSearchList([]))
                    dispatch(updateSearchListForUpdates([]))
                    myLeadsListRefresh(payload, searchPersist, dispatch, leads);
                    if (leads.selectedSelectorControlValue !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, list: payload['listName'] }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const updateSelectorControlAction = (payload, myLeads, searchPersist, dispatch) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    let payload1 = cloneDeep(payload)
    delete payload1['listName'];
    try {
        axios.put(`${objectType}`, payload1)
            .then((resp) => {
                if (resp.status === 201 || resp.status === 200) {
                    dispatch(updateProcessId(resp.data.process_id))
                    let message = payload.favourite ? `Selected ${objectType} have been favourited` : `Selected ${objectType} have been unfavourited`
                    messageAlert(dispatch, message, 'success')
                    dispatch(updateSearchList([]))
                    dispatch(updateSearchListForUpdates([]))
                    dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
                    myLeadsListRefresh(payload, searchPersist, dispatch, myLeads);
                    dispatch(updateSelectedSelectorControlValue({ count: 0, list: payload['listName'] }))
                    if (myLeads.selectedSelectorControlValue !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, list: payload['listName'] }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const selectorControllerArchive = (payload, myLeads, searchPersist, dispatch, settings) => {
    let payload1 = cloneDeep(payload)
    delete payload1['listName'];
    try {
        axios.put(`projects`, payload1)
            .then((resp) => {
                if (resp.status === 201 || resp.status === 200) {
                    dispatch(updateProcessId(resp.data.process_id))
                    let message = payload.archive ? 'Projects archived successfully' : 'Projects unarchived successfully'
                    messageAlert(dispatch, message, 'success')
                    dispatch(updateSearchList([]))
                    dispatch(updateSearchListForUpdates([]))
                    dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
                    myLeadsListRefresh(payload, searchPersist, dispatch, myLeads, settings);
                    dispatch(updateSelectedSelectorControlValue({ count: 0, list: payload['listName'] }))
                    if (myLeads.selectedSelectorControlValue !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, list: payload['listName'] }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
};

function myLeadsListRefresh(payload, searchPersist, dispatch, myLeads, settings) {
    if (payload['listName'] === "myLeadsHistory") {
        fetchHistoryResults(searchPersist, myLeads, dispatch, settings);
    }
    else if (payload['listName'] === "myLeadsFavourites") {
        fetchFavouritesResults(searchPersist, myLeads, dispatch, settings);
    }
    else if (payload['listName'] === 'myLeadsUpdates') {
        fetchUpdatesResults(searchPersist, myLeads, dispatch, settings);
    }
    else if (payload['listName'] === "myLeadsArchive") {
        fetchArchieveList(searchPersist, myLeads, dispatch);
    }
    else if (payload['listName'] === 'myLeadsShares') {
        fetchSharesResults(searchPersist, myLeads, dispatch, settings);
    }
    else if (payload['listName'] === 'myLeadsTags') {
        fetchTagDetailsById(myLeads.tagId, myLeads, searchPersist, dispatch, searchPersist.leadsAppliedContext.tags, settings)
    }
}

export const fetchSharesResults = async (searchPersist, myleads, dispatch, settings) => {
    // Cancel previous ongoing fetch request
    abortController.abort();
    // Create a new AbortController instance for this request
    const controller = new AbortController();
    const signal = controller.signal;

    // Store the controller for possible cancellation
    abortController = controller;

    let type = searchPersist.leadsAppliedContext.shares
    let fields = type === 'project' ? (`${PROJECT_SEARCH_FIELDS}`) : HISTORY_COMPANY_FIELDS;
    let sort = searchPersist?.leadsSort?.["shares"]?.[searchPersist.leadsAppliedContext.shares]?.value
    let url = `shares?object_type=${type}&offset=${myleads?.offsetLimitValue?.offset}&limit=${myleads?.offsetLimitValue?.records}&fields=${fields}&sort=${sort}`

    if (searchPersist?.leadsSort?.["shares"]?.[searchPersist.leadsAppliedContext.shares]?.label.toLowerCase() === "nearest") {
        try {
            let query = await updateSortQuery("shares", searchPersist, dispatch);
            url = !_.isEmpty(query) ? url + `&query=${JSON.stringify(query)}` : url;
        } catch (error) {
            console.error("Geolocation error:", error);
        }
    }

    axios.get(url, { signal })
        .then((response) => {
            if (response.status === 200) {
                if (myleads.offsetLimitValue.offset === 0) {
                    dispatch(updateSearchList([]))
                    dispatch(updateLeadsSearchListCount(0))
                }
                var leadsFlags = type === 'project' ? response.data.projects : response.data.companies
                var count = type === 'project' ? response.data?.aggregation?.project_count : response.data?.aggregation?.company_count
                if (leadsFlags.length > 0) {
                    resultList(count, myleads, leadsFlags, settings, type, dispatch)
                    dispatch(updateNoResult(false))
                } else {
                    updateResponseData(dispatch, myleads, leadsFlags, count, false);
                }
            } else if (response.status === 204) {
                dispatch(updateSearchList([]))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateLeadsSearchListCount(0))
                dispatch(updateNoResult(true))
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

const resultList = (count, myleads, responseData, settings, type, dispatch) => {
    let query = _.cloneDeep(myleads)
    setObjectData(responseData, type)
    let isArchiveCheck = settings?.preferences?.archiving_enabled
    if (count > (query.offsetLimitValue.offset + query.offsetLimitValue.records)) {
        // This condition is filtering archived projects based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && isArchiveCheck) {
            responseData = responseData.filter(item => !item.archive)
            query = getRecordCountForMyleads(query, responseData)
        }
        if (responseData.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY) {
            updateResponseData(dispatch, query, responseData, count, true);
        } else {
            updateResponseData(dispatch, query, responseData, count, false);
            dispatch(updateIfMoreResultAvailable(true))
        }
    } else {
        if (type === ENTITY_TYPE.PROJECT && isArchiveCheck) {
            responseData = responseData.filter(item => !item.archive)
        }
        updateResponseData(dispatch, query, responseData, count, false);
        dispatch(updateIfMoreResultAvailable(false))
    }
}

function updateResponseData(dispatch, query, responseData, count, isUpdateOffset) {
    if (isUpdateOffset) {
        dispatch(updateMyLeadOffsetLimitValue({ offset: query.newOffset, records: query.newRecords }));
    }
    if (query.offsetLimitValue.offset > 0) {
        dispatch(updateShowMoreSearchList(responseData))
        dispatch(updateLeadsSearchListCount(count))
    } else {
        dispatch(updateSearchList(responseData))
        dispatch(updateLeadsSearchListCount(count))
    }
}

const updatesResultList = (count, myleads, responseData, settings, type, dispatch) => {
    let query = _.cloneDeep(myleads)
    setObjectData(responseData, type)
    let isArchiveCheck = settings?.preferences?.archiving_enabled
    if (count > (query.offsetLimitValue.offset + query.offsetLimitValue.records)) {
        // This condition is filtering archived projects based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && isArchiveCheck) {
            responseData = responseData.filter(item => !item.archive)
            query = getRecordCountForMyleads(query, responseData)
        }
        if (responseData.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY) {
            updateResponseDataForUpdatesList(dispatch, query, responseData, count, true);
        } else {
            updateResponseDataForUpdatesList(dispatch, query, responseData, count, false);
            dispatch(updateIfMoreResultAvailable(true))
        }
    } else {
        if (type === ENTITY_TYPE.PROJECT && isArchiveCheck) {
            responseData = responseData.filter(item => !item.archive)
        }
        updateResponseDataForUpdatesList(dispatch, query, responseData, count, false);
        dispatch(updateIfMoreResultAvailable(false))
    }
}

function updateResponseDataForUpdatesList(dispatch, query, responseData, count, isUpdateOffset) {
    if (isUpdateOffset) {
        dispatch(updateMyLeadOffsetLimitValue({ offset: query.newOffset, records: query.newRecords }));
    }
    if (query.offsetLimitValue.offset > 0) {
        dispatch(updateShowMoreSearchListForUpdates(responseData))
        dispatch(updateLeadsSearchListCountForUpdates(count))
    } else {
        dispatch(updateSearchListForUpdates(responseData))
        dispatch(updateLeadsSearchListCountForUpdates(count))
    }
}

function setObjectData(leadsFlags, type) {
    _.map(leadsFlags, (payload) => {
        if (type === 'project') {
            setDataForProjectDetails(payload);
        } else if (type === 'company') {
            setDataForCompanyDetails(payload);
        }
    });
}