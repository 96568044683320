import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonDropdown, Card, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap'
import { updateExportIncludeRolesFilter, updateExportRoles, updateExportSelectAllFavourites, updateRoleTextInputFilter, updateSelectUnselectAllRoleFilterOptions } from '../../reducer'

const RoleFilter = (props) => {
    const [dropdownOpenRoles, setDropdownOpenRoles] = useState(false)
    const [allItemsSelected, setAllItemsSelected] = useState(false)
    const [isAnyItemSelected, setIsAnyItemSelected] = useState(false)
    const [closeButton, setCloseButton] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const dispatch = useDispatch()

    function toggleRolesDropdown() {
        setDropdownOpenRoles(!dropdownOpenRoles)
    }

    function handleToggleSelection(type) {
        dispatch(updateSelectUnselectAllRoleFilterOptions(type))
        dispatch(updateExportRoles())
    }

    function handleClose() {
        setSearchInput('')
        setCloseButton(false)
        dispatch(updateRoleTextInputFilter(''))
    }

    function handleFilterChange(event) {
        setSearchInput(event.target.value)
        setCloseButton(event.target.value.length > 0 ? true : false)
        dispatch(updateRoleTextInputFilter(event.target.value))
    }

    function handleRoleFilter(event) {
        event.stopPropagation()
        dispatch(updateExportIncludeRolesFilter({
            value: event.target.id,
            active: event.target.checked
        }))
        dispatch(updateExportRoles())
    }

    function handleCheckboxSelectorChange(event) {
        event.stopPropagation()
        setAllItemsSelected(event.target.checked)
        handleToggleSelection(event.target.checked)
    }

    function handleSelectAllFavRoles() {
        dispatch(updateExportSelectAllFavourites(props.favouriteRoles))
        dispatch(updateExportRoles())
    }

    return (
        <>
            <Card block className="pl-0 pr-0 pt-0 pb-0 border-0 role-dropdown">
                <div className="subsearch-filters role-filter-block">
                    <div className="mb-0">
                        <fieldset className={`border-alto form-group m-0`}>
                            <div className="select-toggle roles-selector-section">
                                <ButtonDropdown isOpen={dropdownOpenRoles} toggle={toggleRolesDropdown}>
                                    <DropdownToggle caret className="no-background include-exclude-all" >
                                        <div className={`check-box-button no-margin ${!allItemsSelected && isAnyItemSelected ? 'child-selected' : ''}`}>
                                            <input checked={allItemsSelected} onChange={handleCheckboxSelectorChange} type="checkbox" name="RolesSelectorCheckbox" id="RolesSelectorCheckbox" className="form-check-input" />
                                            <label htmlFor='RolesSelectorCheckbox' className="form-check-label"></label>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => handleToggleSelection(true)} className="select-and-clear">Select all</DropdownItem>
                                        {props.favouriteRoles && props.favouriteRoles.length > 0 ? <DropdownItem onClick={handleSelectAllFavRoles} className="select-and-clear">Select favourites</DropdownItem> : ""}
                                        <DropdownItem onClick={() => handleToggleSelection(false)} className="select-and-clear">Clear all</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                            <div className="roles-search-list subsearch-filters-wrapper">
                                <Input type="text" placeholder="Search roles" onChange={handleFilterChange} className="form-control mb-2" value={searchInput} />
                                <Button className={`roles-search-filed-btn clear-textfield ${closeButton ? 'display-block' : 'display-none'}`} onClick={handleClose}>x</Button>
                                <div id="rolefilters" className="src-filter-list">
                                    <Card block className="p-0">
                                        <div className="include-stages" >
                                            {props.filter?.options && props.filter.options?.map(function (item, index) {
                                                if (item.display) {
                                                    return (<FormGroup check key={`formgroup${index}`}>
                                                        <div className="check-box-button">
                                                            <Input type="checkbox" checked={item.active} id={item.value} value={item.value} onChange={handleRoleFilter} />
                                                            <Label check for={item.value}>{item.name}</Label>
                                                        </div>
                                                    </FormGroup>)
                                                }
                                            })}
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default RoleFilter