import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import TeamsList from "./teamsList";
import { If, Then } from "react-if";
import ColoredScrollbars from "../common/js/coloredScrollbars";
import { fetchAllRecipients } from "../login/apiclient";
import { useDispatch, useSelector } from "react-redux";
import { createTeamList } from "../../utils";
import { addNewTeams } from "./apiclient";
import { updateTeamAlreadyExists } from "./reducer";

const TeamsItem = (props) => {
    const [teamId, setTeamId] = useState(null);
    const [isEditTeam, setIsEditTeam] = useState(false);
    const [editTeamId, setEditTeamId] = useState(null);
    const [isAddNewTeam, setIsAddNewTeam] = useState(false);
    const [isAddNewTeamMember, setIsAddNewTeamMember] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [teamName, setTeamName] = useState("");
    const [isAddMembers, setIsAddMembers] = useState(false);
    const [isAddMemberTeamId, setIsAddMemberTeamId] = useState(null);
    const [isDuplicateTeamName, setIsDuplicateTeamName] = useState(false);
    const [addNewTeam, setAddNewTeam] = useState(true);
    const userData = useSelector((state) => state.user.user);
    const user = useSelector((state) => state.user);
    const team = useSelector((state) => state.teams);
    const project = useSelector((state) => state.project);
    const dispatch = useDispatch();

    useEffect(() => {
        if (project?.fetchShareRecipients === undefined) {
            fetchAllRecipients(userData, dispatch);
        }
    }, [project]);

    useEffect(() => {
        let teamListData = createTeamList(user?.teamListDetails);
        setTeamList(teamListData);
    }, [user?.teamListDetails]);

    useEffect(() => {
        setIsEditTeam(false);
    }, [props?.activeTab]);

    useEffect(() => {
        setIsDuplicateTeamName(team?.isTeamAlreadyExists);
        if (
            team?.isTeamAlreadyExists != null &&
            team?.isTeamAlreadyExists === false
        ) {
            if (addNewTeam) {
                setIsAddNewTeam(false);
            }
            setTeamName("");
        }
    }, [team?.isTeamAlreadyExists]);

    useEffect(() => {
        setIsEditTeam(false);
    }, [props?.activeTab]);

    function handleNewTeamClick(event) {
        setTeamName("");
        setIsAddNewTeam(!isAddNewTeam);
        setTeamId(null);
        if (editTeamId) {
            setIsEditTeam(false);
            setEditTeamId(null);
        }
        dispatch(updateTeamAlreadyExists(null))
        setIsDuplicateTeamName(false)
    }

    function handleTeamSubmit() {
        let payload = {
            team_name: teamName,
            clientno: user?.user?.client_id,
        };
        if (teamName.trim().length > 0) {
            addNewTeams(payload, userData, dispatch);
            setAddNewTeam(true);
        }
    }

    function handleTeamNameChange(event) {
        setTeamName(event.target.value);
        if (event.target.value === "") {
            dispatch(updateTeamAlreadyExists(null))
            setAddNewTeam(false);
        }
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            handleTeamSubmit(event);
        }
    }

    function closeAddTeamOnEditClick(flag) {
        setIsAddNewTeam(flag);
        dispatch(updateTeamAlreadyExists(null))
    }

    function handleEditTeamToggle(flag, editTeamId, event) {
        setIsEditTeam(flag);
        setEditTeamId(editTeamId);
    }

    function handleAddMembersToTeamToggle(flag, teamId, event) {
        setIsAddMembers(flag);
        setEditTeamId(teamId);
        setIsEditTeam(flag);
        setIsAddMemberTeamId(teamId);
    }

    return (
        <div className="content-wrapper">
            <div className="team-tab">
                <div className="user-team-search-panel pt-0 media">
                    <div className="media-body">
                        <h4 className="media-heading">
                            <div className="src-button">
                                <button
                                    type="primary"
                                    class="outline-cta-btn add-user"
                                    onClick={handleNewTeamClick}
                                >
                                    New team
                                </button>
                            </div>
                            <div className="sort-results"></div>
                        </h4>
                    </div>
                </div>
                <div
                    className={`add-new-team-wrapper media ${isAddNewTeam ? "show" : "hide"}`}
                >
                    <a href="#" class="media-left"></a>
                    <div className="media-body">
                        <h4 className="team-input media-heading">
                            <Input
                                type="text"
                                name="teamName"
                                className="mr-1 mb-1"
                                maxLength="30"
                                placeholder="Enter team name"
                                value={teamName}
                                onChange={handleTeamNameChange}
                                onKeyDown={handleKeyDown}
                            />
                            <div className="team-button">
                                <button
                                    type="primary"
                                    className="primary-btn add-team"
                                    onClick={handleTeamSubmit}
                                >
                                    Add team
                                </button>
                                <button
                                    type="button"
                                    className="delete-button btn btn-secondary"
                                    onClick={handleNewTeamClick}
                                >
                                    delete
                                </button>
                            </div>

                            {isDuplicateTeamName ? (
                                <p className="text-error">Team name already exists</p>
                            ) : (
                                ""
                            )}
                        </h4>
                    </div>
                </div>
                <If condition={teamList?.length == 0}>
                    <Then>
                        <p>To add a team, click "New team"</p>
                    </Then>
                </If>

                <div className="list-wrapper mt-2 teams-list-wrapper">
                    <ColoredScrollbars>
                        {teamList?.length > 0
                            ? teamList?.map(function (team, index) {
                                return (
                                    <TeamsList
                                        {...props}
                                        team={team}
                                        index={index}
                                        key={index}
                                        closeAddTeamOnEditClick={closeAddTeamOnEditClick}
                                        handleEditTeamToggle={handleEditTeamToggle}
                                        teamId={team?.selectedTeamId}
                                        isEditTeam={
                                            editTeamId === team.team_no ? isEditTeam : false
                                        }
                                        isAddMemberTeam={
                                            isAddMemberTeamId === team.team_no ? isAddMembers : false
                                        }
                                        handleAddMembersToTeamToggle={handleAddMembersToTeamToggle}
                                    />
                                );
                            })
                            : "No Teams available"}
                    </ColoredScrollbars>
                </div>
            </div>
        </div>
    );
};

export default TeamsItem;
