import React, { useEffect, useState } from 'react'
import { Treebeard } from 'react-treebeard';
import SearchInput from 'react-search-input'
import ColoredScrollbars from '../../../common/js/coloredScrollbars';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Icon from '../../../common/js/icon';
import { expandFilteredNodes, filterTree } from '../../../../utils';

export const getCategories = (text) => {
  let categoryList = []
  if (text?.includes(';')) {
    text.split(';').map((item) => {
      categoryList.push(createCategoryJson(item));
    })
  } else {
    categoryList.push(createCategoryJson(text));
  }
  return categoryList
}

function createCategoryJson(item) {
  let list = {};
  let newItem = item.includes(':') ? item.split(':') : item;
  list = { name: newItem[0], toggled: true };
  list.children = [];
  if (newItem[1].includes('~')) {
    newItem[1].split('~').map((subItem) => {
      list.children.push({ name: subItem, toggled: true });
    });
  } else {
    list.children.push({ name: newItem[1], toggled: true });
  }
  return list
}

const CategroiesDetailTab = () => {
  const [cursor, setCursor] = useState(false);
  const [tempCatData, setTempMatData] = useState("");
  const [categoryList, setcategoryList] = useState([])
  const [defaultData, setDefaultData] = useState([])
  const project = useSelector((state) => state.project)
  let categories

  useEffect(() => {
    categories = project?.detailsPage?.project_categories_text ? getCategories(project?.detailsPage?.project_categories_text) : []
    setDefaultData(categories)
    setcategoryList(categories)
  }, [project.detailsPage.project_categories_text])

  const onToggle = (node, toggled) => {
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(!cursor);
  }

  const onFilterMouseUp = (e) => {
    const filter = e.target.value.trim()
    setTempMatData(filter)
    if (!filter) {
      return setcategoryList(defaultData)
    }
    var nData = { 'name': 'Categories', children: defaultData }
    var filtered = filterTree(nData, filter)
    filtered = expandFilteredNodes(filtered, filter)
    setcategoryList(filtered.children)
  }

  const handleCategoryDataClose = () => {
    setTempMatData("")
    setcategoryList(defaultData)
  }


  return (
    <>
      {
        project?.detailsPage?.project_categories_text !== '' ?
          (
            <div className="category">
              <div className="input-group textfield-wrapper">
                <Icon color="#ced4da" size={30} icon="icon-sb--search" className="search-icon" />
                <SearchInput placeholder="Search categories"
                  className="form-control"
                  type="text"
                  onKeyUp={onFilterMouseUp}
                  value={tempCatData} />
                <Button className={`clear-textfield btn btn-secondary ${tempCatData ? 'd-block' : 'd-none'}`} onClick={handleCategoryDataClose} tabIndex="-1">x</Button>
              </div>
              <div className="tree-list">
                <ColoredScrollbars className="material-list category-list-container">
                  <Treebeard
                    data={categoryList}
                    onToggle={onToggle}
                  />
                </ColoredScrollbars>
              </div>
            </div>
          ) :
          (
            <div className="input-group">
              No category found on the project
            </div>
          )
      }

    </>
  );
}

export default CategroiesDetailTab