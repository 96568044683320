import React, { useEffect, useRef, useState } from 'react';
import { Type } from 'react-bootstrap-icons';
import { BigPlayButton, Player } from 'video-react';
import { useSelector } from 'react-redux';
import { Slide } from 'react-slideshow-image';

import { PROJECT_IMG_URL, VIDEO_EXTENSION } from '../../utils/settings';
import { renderMapboxImage } from '../../utils/commonFunctions';
import  Icon from '../common/js/icon';
import 'react-slideshow-image/dist/styles.css'

const SliderCarousel = (props) => {
    const [ source, setSource ] = useState({});
	const [ counter, setCounter ] = useState(0);
    const [ imagesHtml , setImageHtml] = useState([]);
    const [ imageArray, setImageArray ]  = useState(null);
    const project = useSelector((state) => state.project)

    const fetchGalleryImage = (url, imageUrl) => {
        return checkVideoOrImage(url) || url.indexOf("api.mapbox.com") !== -1 || url.indexOf(PROJECT_IMG_URL) !== -1 ?
            url : imageUrl?.thumbnail_urls ? imageUrl.thumbnail_urls.large : imageUrl.large
    }

    const fetchCarouselImage = (url, imageUrl, staticImage) => {
        return checkVideoOrImage(url) ? url.substr(0, url.lastIndexOf(".")) + "_" + Type + ".jpg" : (url.indexOf("api.mapbox.com") !== -1 || url.indexOf(PROJECT_IMG_URL) !== -1) ?
            url : imageUrl ? imageUrl.thumbnail_urls ? imageUrl.thumbnail_urls.medium : imageUrl.medium : staticImage
    }

    const checkVideoOrImage = (media) => {
        let extension = media.split('/[#?]/')[0].split('.').pop().trim()
        return VIDEO_EXTENSION.includes(extension)
    }

    const onClickImage = (e, index) => {
        e.preventDefault();
        setSourceAndCounter(index)
        document.getElementById('overlay-cntr').classList.add("openOverlay");
    }

    const setSourceAndCounter = (index) => {
        let imageObj = {...project && project.detailsPage && imageArray[index],
            url: fetchGalleryImage(imageArray[index].url,imageArray[index].thumbnail_urls )
        }
        setSource(imageObj)
    }

    const goToSlide = (n) => {
        let cnt = ((project && project.detailsPage && imageArray.length) + n) % (project && project.detailsPage && imageArray.length)
        setCounter(cnt);
        setSourceAndCounter(cnt);
    }

    const closeOverlayBox = () => {
        document.getElementById('overlay-cntr').classList.remove("openOverlay");
        setSource({})
    }

    const nextSlide = () => {
        goToSlide(counter + 1);
    }

    const prevSlide = () => {
        goToSlide(counter - 1);
    }

    const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: false,
        indicators: false,
        arrows: true,
        autoplay: false,
        onChange: (oldIndex, newIndex) => {
        }
    }

    const sliderHtml = (images) => {
        let imageSliderHTML = []
        if ((project && project.detailsPage) && (images)) {
            images && images.carouselImages.map((image, index) => {
                let currentImage = (
                    <div className={(checkVideoOrImage(image.url) ? "video-slide " : "") + "each-slide location-projectImg"} >
                        <div onClick={(e) => { onClickImage(e, index) }} style={{ backgroundImage: "url('" + image + "'), " + "url('" + project && project.detailsPage && project.detailsPage.project_image_url + "')" }}>
                            <img src={fetchCarouselImage(image.url, image.thumbnail_urls, image.staticImage)} value={image.url} ></img>
                            {(image.url.indexOf("api.mapbox.com") !== -1) ?
                                (<div className="location-name-distance">
                                    {project.detailsPage.project_site3 ||  project.detailsPage.address3 &&
                                        <div className="proj-location"><Icon color="#ffffff" size={20} icon="icon-gen-map" className="me-1"/> <span>{props.objectType === 'project' ? project.detailsPage.project_site3 : project.detailsPage.address3}</span></div>
                                    }
                                </div>)
                                : image.url.indexOf(PROJECT_IMG_URL) !== -1 ? (
                                    <div className="location-name-distance" title={project && project.detailsPage && project.detailsPage.project_image_source_url}>
                                        Image source : {project && project.detailsPage && project.detailsPage.project_image_source_url}
                                    </div>) :
                                    <div className="img-src-attribution">
                                        {image.title &&
                                            <div className="img-name">{image.title}</div>
                                        }
                                        {image.attribution &&
                                            <div className="img-attribution">{image.attribution}</div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                )
                imageSliderHTML.push(currentImage)
            })
            setImageHtml(imageSliderHTML)
        }
    }

    useEffect(() => {
        sliderHtml(renderMapboxImage(project.detailsPage, props.objectType))
        setImageArray(props.imageArray)
	}, [project.detailsPage, props])

        return (
            <div>
                <div id="overlay-cntr" className="overlayCntr">
                    <div className="overlayBox d-flex">
                        <div className="overlayBody align-self-center text-center">
                            <div className="overlayContent animated fadeIn">
                                <div className="closeoverlay" onClick={closeOverlayBox}>×</div>
                                <div className="fill-img">
                                    <a href="javascript:void(0)" className="prev" onClick={prevSlide}> </a>
                                    <div className="overlayimg">
                                        {source && source.url && checkVideoOrImage(source.url) ?
                                            <Player key={source.url} autoPlay>
                                                <BigPlayButton position="center" />
                                                <source src={source.url || ''} />
                                            </Player> :
                                            <img id="displayed-img" alt='' src={source.url || ''}></img>
                                        }
                                        <div className="img-src-attribution">
                                            {source.title &&
                                                <div className="img-name">
                                                    {source.title}
                                                </div>
                                            }
                                            {source.attribution &&
                                                <div className="img-attribution">
                                                    {source.attribution}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" className="next" onClick={nextSlide}> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="slideshow">
                    {imagesHtml && imagesHtml.length ? 
                    <Slide {...properties}>
                        {
                           imagesHtml && imagesHtml.map(img => {
                                return (img)
                            })
                        }
                    </Slide>
                    : ""}
                </div>

            </div>
        );
    
}
export default SliderCarousel