import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, InputGroup } from 'reactstrap'
import _ from 'lodash'
import Icon from '../common/js/icon';
import ColoredScrollbars from '../common/js/coloredScrollbars';
import CreatableSelect from 'react-select/creatable';
import { createTeamList, getInitials, getSharedListBySharedDate } from '../../utils';
import { addShare, deleteShare } from './apiclient';
import { updateSavedSearchOffsetLimitValue } from './redux/reducer';
import './savedsearch.scss';



const ShareModal = (props) => {
    const [multiInputValue, setMultiInputValue] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState({})
    const [shareDetails, setSharedDetails] = useState([])
    const [sharedUserId, setSharedUserId] = useState('')
    const [isAddShareApiCall, setAddShareApiCall] = useState(false)
    const [isDeleteApiCall,setDeleteApiCall]=useState(false)
    const [AddPayload, setAddPayload] = useState({})
    const [deletePayload,setDeletePayload]=useState({})
   

    const project = useSelector(state => state.project)
    const user = useSelector(state => state.user)
    const userData = useSelector(state=> state.user.user.user_details)
    const savedsearch = useSelector((state) => state.savedsearch)
    const dispatch = useDispatch();

	const handleOnChange = (values) => {
    let options = []
        values.map(function (value, index) {
          if (!(value.value === 'Teams' || value.value === 'Users')) {
            options.push(value)
          }
        })  
        setMultiInputValue(options)
      }

      const teamInitials = (value) => {
        var teamName = value && value.trim().split(" ")
        var teamInitial = teamName && teamName.length > 1 ? teamName && teamName[0] && teamName[0][0] + teamName && teamName[1] && teamName[1][0] : teamName && teamName[0] && teamName[0][0]
        return teamInitial.toUpperCase()
      }
    
      const shareOptionsRenderer = (option) => {
        return (
          <div className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? `block-title ${option.label === "Everyone" ? ' everyone-icon' : ''}` : 'list-user-team'}`} key={option.value}>
            {option.initials != '-' ? (<span className="user-dropdown-icon">{option.initials}</span>) : ((<span className="team-member-name team-ss-icon">{teamInitials(option.label)}</span>))}
            <span className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? 'select-header' : option.email == '-' ? 'team-name-class users-teams-title' : 'users-teams-title'}`}>{option.label}
              {option.email != '-' ? (<a className="email-address"> {option.email} </a>) : ('')}</span>
          </div>
        )
      }

      useEffect(() => {
        let recipientOptions = []
        let shareDetails = []
        let teamListData = createTeamList(user && user.teamListDetails)
        recipientOptions.push({ value: 'Everyone', label: 'Everyone', initials: '-', email: '-', sharedType: '-', className: 'everyone-class' })
        recipientOptions.push({ value: 'Teams', label: 'Teams', initials: '-', email: '-', sharedType: '-' })
        if (teamListData && teamListData.length > 0) {
          teamListData && teamListData.map(team => {
            recipientOptions.push({ value: team.team_no, label: team.name, initials: '-', email: '-', sharedType: 'T', className: 'teams-class' })
          })
        }
        recipientOptions.push({ value: 'Users', label: 'Users', initials: '-', email: '-', sharedType: '-' })
        if (project && project.fetchShareRecipients && project.fetchShareRecipients.length > 0) {
          project && project.fetchShareRecipients && project.fetchShareRecipients.map(user => {
            recipientOptions.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email, sharedType: 'U' })
          })
        }
       
        if (savedsearch && savedsearch.shareInfoDetails && savedsearch.shareInfoDetails.sharedInfo) {
          shareDetails = getSharedListBySharedDate(savedsearch && savedsearch.shareInfoDetails && savedsearch.shareInfoDetails.sharedInfo)
        }
        setOptions(recipientOptions)

        let shareDetailsData = []
        if (shareDetails && Object.keys(shareDetails).length > 0) {
            Object.keys(shareDetails).map(shared => {
              shareDetails[shared].map(sharedObj => {
                    const sharedOnObj = { sharedOn: sharedObj.sharedOn };
                    const updatedSharedObj = { ...sharedObj.sharedBy, ...sharedOnObj };
                    shareDetailsData.push(updatedSharedObj)
                    sharedObj.shareWith.map(item => {
                        shareDetailsData.push(item)
                    })
                })
            }
            )
        }
        let uniqData = _.uniqBy(shareDetailsData, (obj) => obj.id)
        setSharedDetails(uniqData)

      }, [project, savedsearch.shareInfoDetails, project.teamListDetails])

      useEffect(()=>{
        if(isAddShareApiCall){
        addShare(props.shareDetail, AddPayload, dispatch, project, userData, savedsearch)
        setAddShareApiCall(false)
        setMultiInputValue([])
        props.handleCancelDelete()
        }
    },[isAddShareApiCall])


    useEffect(()=>{
      if(isDeleteApiCall){
      deleteShare(props.shareDetail.saved_search_id, props.shareDetail.objectType, deletePayload, dispatch, project, userData,savedsearch)
      setDeleteApiCall(false)
      setSharedUserId('')
      props.handleCancelDelete()
      }
  },[isDeleteApiCall])

      const handleShareMessage = () => {
        let sharedWith = []
        let payload ={}
        var objectId = props.shareDetail.saved_search_id
        var objectType = props.shareDetail.objectType
        if(multiInputValue.length > 0)
        {
          multiInputValue.map(function (value) {
            if (value.value === 'Everyone') {
              sharedWith.push({ share_type: "company" })
            }
          })
          let userIds = [] 
          multiInputValue.filter(value => value.sharedType === 'U').map(inputValue => userIds.push(inputValue.value))
          if (userIds?.length > 0) {
            sharedWith.push({ share_type: 'user', user_id: userIds })
          }

          let teamIds = []
          multiInputValue.filter(value => value.sharedType === 'T').map(inputValue => teamIds.push(inputValue.value))
          if (teamIds?.length > 0) {
            sharedWith.push({ share_type: 'team', team_id: teamIds })
          }
        
           payload = {
            "shared_with": sharedWith
          }
           setAddPayload(payload)
           dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
           setAddShareApiCall(!isAddShareApiCall)
        }
        else{
           payload = {
            "shared_with": []
          }
          setMultiInputValue([])
          props.handleCancelDelete()
        }
   
      }

      const handleShareDelete = (shareDetails, event) => {
        var objectId = props.shareDetail.saved_search_id
        var objectType = props.shareDetail.objectType
        let payload = {
            'shared_with': [
              shareDetails.shared_type === 'U' ?
                {
                  'shared_type': 'user',
                  'user_id': [shareDetails.shared_id]
                }
                :
                shareDetails.shared_type === 'T' ?
                  {
                    'shared_type': 'team',
                    'team_id': [shareDetails.shared_id]
                  } :
                  {
                    'share_type': 'company',
                  }
            ]
          }
          setDeletePayload(payload)
          dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
          setDeleteApiCall(true)
         
      }

      const handleCancel = () => {
        setSharedUserId('')
      }

      const handleDeleteButtonClick = (event, itemId) => {
        event.preventDefault();
        const buttonId = event.currentTarget.id;
        if (buttonId === 'confirm-delete-'+itemId) {
          setSharedUserId(itemId)
        }  
    }

  return (
    <Modal isOpen={props.isOpen} id="shareModal" className='shareModal'>
        <ModalHeader className='d-flex' toggle={props.handleCancelDelete}>
            Share a saved search
        </ModalHeader>
        <ModalBody className='mt-2 mb-3'>
            <div className="d-flex">
                <div className="input-group">
                    <div className="input-group-text">
                        <Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="circle-border" />
                    </div>
                    <InputGroup className='share-input-box'>
                    <CreatableSelect
                        options={options}
                        isClearable
                        isMulti
                        value={multiInputValue}
                        formatOptionLabel={(option) => shareOptionsRenderer(option)}
                        placeholder={<span className='label-placeholder'> Enter user or team to share </span>}
                        onChange={(newValue) => handleOnChange(newValue)}
                        onInputChange={(newValue) => setInputValue(newValue)}
                        isValidNewOption={() => false}
                    />
                    </InputGroup>
                    <button className="primary-btn btn-share" onClick={() => handleShareMessage()}>Share</button>
                </div>
            </div>
        <div className="collaborationBox mt-3">
          <div className="collaborationBoxList">
            <div className="collaborationUpdateBlock">
              {

                shareDetails?.map((item, index) => {
                  return (
                    <div className="collaborationBy">
                      <div className="collabtnBox d-flex">
                        <div className="user-name-icon">
                          {item.initials}
                        </div>
                        <div className="shared-activity">
                          <span className={`${item.type == "U" || index === 0 ? "user-icon" : item.type == "T" ? "teams-icon" : item?.name === "Everyone" ? "everyone" : ""} sharetoremove`}></span>
                          <span className={`${item.email ? 'name-class' : 'team-name-class'}`}>{item.first_name && item.last_name ? item.first_name + " " + item.last_name : item.name} {item.email ? (<p className="email-address">{item.email}</p>) : ('')}</span>
                          {
                            item?.is_delete ?
                              (
                                <div>
                                  <Icon size={20} icon="icon-gen-trash" id={`confirm-delete-${item.id}`} className={`delete-icon ${sharedUserId === item.id ? "hide" : "show"}`} onClick={(e) => handleDeleteButtonClick(e, item.id)} />
                                  <div className={`share ${sharedUserId === item.id ? "show" : "hide"}`}>
                                    <Button className="btn-label-cancel" id={`cancel`} onClick={handleCancel}>Cancel</Button><span></span>
                                    <Button className="btn-label-delete" id={`delete`} onClick={() => handleShareDelete({ shared_type: item.type, shared_id: item.id })}>Delete</Button>
                                  </div>
                                </div>
                              ) :
                              ('')
                          }

                        </div>
                      </div>
                    </div>
                  )
         
                })
              }
            </div>
          </div>
        </div>

        </ModalBody>
    </Modal>
    )
}

export default ShareModal;