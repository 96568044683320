import React, { useState, useEffect } from 'react'
import { Table, Col, Nav, NavItem, Row, Button, TabContent, TabPane, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import Icon from '../../common/js/icon';
import { appendFieldDisplayNames } from '../utils';

const LeftPanel = (props) => {
    let entityLimit = 2000
    const exports = useSelector((state) => state.export)
    const [state, setState] = useState({
        exportName: exports?.exportCriteria?.feedname,
        exportType: exports?.selectedType,
        saveSearchName: exports?.selectedTypeName,
        saveSearchCount: exports?.saveSearchCount,
        sortBy: exports?.exportCriteria?.sortBy === "V" ? "Project value" : exports?.exportCriteria?.type === "Contacts" ? "Alphabetical" : "Latest",
        projectFields: exports?.exportStaticData?.projects && exports?.isProjectsChecked ? appendFieldDisplayNames(exports?.exportStaticData?.projects) : null,
        companyFields: exports?.exportStaticData?.companies && exports?.isCompaniesChecked ? appendFieldDisplayNames(exports?.exportStaticData?.companies) : null,
        contactFields: exports?.exportStaticData?.contacts && exports?.isContactsChecked ? appendFieldDisplayNames(exports?.exportStaticData?.contacts) : null,
        includeRoles: exports?.exportFilters?.includeRoles ? appendFieldDisplayNames(exports?.exportFilters?.includeRoles?.options) : null,
        microcopyMsg: exports?.microcopyMsg || '',
        microcopySavesearchMsg: exports?.microcopySavesearchMsg || '',
        displayType: exports?.exportCriteria?.type,
        excludeContactWithNoConfirmedName: exports.exportCriteria.setting.excludeContactWithNoConfirmedName,
        excludeContactWithNoEmail: exports.exportCriteria.setting.excludeContactWithNoEmail,
        templateName:
            exports?.exportCriteria?.selectedTemplate && exports?.exportCriteria?.currentStep !== 0
                ? exports?.exportCriteria?.templates.find((item) => item.template_name === exports?.exportCriteria?.selectedTemplate)?.template_name || ''
                : '',
    });

    useEffect(() => {
        // Update the state here if needed
        setState((prevState) => ({
            ...prevState, // Keep previous state values
            exportName: exports?.exportCriteria?.feedname, // Update the desired state property
            exportType: exports?.selectedType, // Update another state property
            saveSearchName: exports?.selectedTypeName,
            saveSearchCount: exports?.saveSearchCount,
            projectFields: exports.exportStaticData.projects && exports.isProjectsChecked ? appendFieldDisplayNames(exports.exportStaticData.projects) : null,
            companyFields: exports.exportStaticData.companies && exports.isCompaniesChecked ? appendFieldDisplayNames(exports.exportStaticData.companies) : null,
            contactFields: exports.exportStaticData.contacts && exports.isContactsChecked ? appendFieldDisplayNames(exports.exportStaticData.contacts) : null,
            includeRoles: exports.exportFilters?.includeRoles ? appendFieldDisplayNames(exports.exportFilters?.includeRoles?.options) : null,
            microcopyMsg: exports.microcopyMsg ? exports.microcopyMsg : '',
            displayType: exports.exportCriteria.setting.type,
            excludeContactWithNoConfirmedName: exports.exportCriteria.setting.excludeContactWithNoConfirmedName,
            excludeContactWithNoEmail: exports.exportCriteria.setting.excludeContactWithNoEmail,
            templateName: exports.exportCriteria.setting.selectedTemplate && exports.currentStep !== 0 ? exports.exportStaticData.templates.find(item => item.template_name == exports.exportCriteria?.setting?.selectedTemplate)?.template_name : "",
            microcopySavesearchMsg: exports?.microcopySavesearchMsg ? exports?.microcopySavesearchMsg : '',
            sortBy: exports?.exportCriteria?.setting?.sortBy === "V" ? "Project value" : exports?.exportCriteria?.setting?.type === "Contacts" ? "Alphabetical" : "Latest",
        }));
    }, [exports, exports.exportCriteria, exports.exportStaticData]);

    return (
        <>
            <div className='export-summary'>
                <span className="export-title"><Icon size={30} icon="icon-gen-info-white" className="me-2" />Export summary</span>
                <div className="export-summary-wrapper">
                    <ColoredScrollbars className="details-panel-scrollbar">
                        <div className='export-summary-page p-2'>
                            <div className="export-save-search px-2">
                                <strong>{state.exportName}</strong>
                            </div>
                            <div className="export-content p-0 mt-2">
                                <div className="d-flex">
                                    <div className="col p-2 br-1 w-50">
                                        <div className="saved-search-name p2">
                                            <strong>{state.exportType}</strong>
                                            <div>{state.saveSearchName === '' ? '' : state.saveSearchName}</div>
                                        </div>
                                    </div>
                                    <div className="col p-2">
                                        <div className={`${exports.exportCriteria.setting.exportRecordsCount < 0 ? 'hide' : 'export-records'}`}>
                                            <strong>{state.microcopyMsg && state.microcopyMsg.charAt(0).toUpperCase() + state.microcopyMsg.slice(1)} in export</strong>
                                            <div className="max-count-msg">{`${exports.exportCriteria.setting.exportRecordsCount > entityLimit ? 'Maximum of ' + entityLimit + ' will be exported' : ''}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex bb-1">
                                    <div className="col px-2 pb-2 br-1">
                                        <div className="saved-search-name p2">
                                            {
                                                state.saveSearchCount > -1 ? (
                                                    <div>
                                                        {/* <span className="counts">{new Intl.NumberFormat('en-UK').format(state.saveSearchCount) + (" " + state.microcopyMsg)}</span> */}
                                                        <h5 className="counts m-0">{new Intl.NumberFormat('en-UK').format(state.saveSearchCount)}</h5>
                                                        <strong>{(state.microcopySavesearchMsg)}</strong>
                                                    </div>
                                                ) : (
                                                    ''
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col px-2 pb-2">
                                        <div className={`${exports.exportCriteria.setting.exportRecordsCount < 0 ? 'hide' : 'export-records'}`}>
                                            <h5 className={`${exports.exportCriteria.setting.exportRecordsCount > entityLimit ? 'red m-0' : 'green m-0'}`}>{new Intl.NumberFormat('en-UK').format(exports.exportCriteria.setting.exportRecordsCount)}</h5>
                                            <strong>{" " + state.microcopyMsg}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <strong>Template</strong>{state.templateName}
                                </div>
                                <div className={`export-select-fields bb-1 ${state.projectFields || state.companyFields || state.contactFields ? '' : 'hide'}`}>
                                    {
                                        state.displayType === "Projects" ? (
                                            state.projectFields ? (
                                                <div className="p-2"><strong>Projects: </strong> {state.projectFields}</div>
                                            ) : ('')
                                        ) : ('')
                                    }
                                    {
                                        (state.displayType === "Companies" || state.displayType === "Projects") && state.companyFields ? (
                                            <div className="p-2"><strong>Companies: </strong>{state.companyFields}</div>
                                        ) : ('')
                                    }
                                    {
                                        state.contactFields ? (
                                            <div className="p-2"><strong>Contacts: </strong>{state.contactFields}</div>
                                        ) : ('')
                                    }
                                    {
                                        (state.displayType !== "Companies" && state.displayType === "Contacts") && state.companyFields ? (
                                            <div className="p-2"><strong>Companies: </strong>{state.companyFields}</div>
                                        ) : ('')
                                    }
                                    {
                                        state.displayType != "Projects" ? (
                                            state.projectFields ? (
                                                <div className="p-2"><strong>Projects: </strong> {state.projectFields}</div>
                                            ) : ('')
                                        ) : ('')
                                    }
                                    {
                                        state.excludeContactWithNoConfirmedName ? (
                                            <div className="p-2 bt-1"><strong>Exclude companies with no confirmed contact name</strong></div>
                                        ) : ('')
                                    }
                                    {
                                        state.excludeContactWithNoEmail ? (
                                            <div className="p-2 bt-1"><strong>Exclude contacts with no personal email address</strong></div>
                                        ) : ('')
                                    }
                                </div>
                                {
                                    state.includeRoles ? (
                                        <div className="include-roles-wrapper p-2"><strong>Include Roles: </strong>{state.includeRoles}</div>
                                    ) : ('')
                                }
                                <div className="export-select-fields bb-1 hide"></div>
                                <div className="p-2">
                                    <strong>Sort by:</strong>
                                    <span> {state.sortBy}</span>
                                </div>
                            </div>
                        </div>
                    </ColoredScrollbars>
                </div>
            </div>
        </>
    )
}

export default LeftPanel