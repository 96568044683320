import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    chubPreferences: {}
}

const integrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    updateCustomerPreferences(state, action) {
        state.chubPreferences = action.payload
        return state
    }
  }
})

export const { updateCustomerPreferences } = integrationSlice.actions

export const integrationReducer = integrationSlice.reducer