import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { resetAlert } from '../../redux/features/user/userslice';

const AlertMessage = () => {

  const alert = useSelector((state) => state.user.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        onDismiss()
      }, 5000);
      return () => clearTimeout(timer)
    }
  }, [alert.visible]);

  const onDismiss = () => {
    dispatch(resetAlert())
  }

  const processMessage = (message) => {
    if (message && typeof message == "string") {
        let msg = message.split('#')
        return (<span className="message"><strong>{msg[0]}</strong><span>{msg[1]}</span></span>)
      }
      else {
        return ""
      }
  }

  return (
    <div>
      { alert.message && alert.message.length > 0 ? (<Alert key={alert.keyid} color={alert.type} isOpen={alert.visible} toggle={onDismiss}> {processMessage(alert.message)} </Alert>) : ('') }
    </div>
  )
}

export default AlertMessage;