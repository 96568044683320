import React, { useEffect, useState } from 'react'
import { InputGroup } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';

import { removeSelectedTagDetails, updateSelectedTagDetails } from '../redux/reducer';
import Icon from '../../common/js/icon';

const SelectorControlAddTag = () => {
  const project = useSelector((state) => state.project)
  const [multiInputValue, setMultiInputValue] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [labelOptionList, setLabelOptionList] = useState([])
  const [labelNames, setLabelNames] = useState([])
  const [labelIds, setLabelIds] = useState([])
  const [labels, setLabels] = useState(project && project.labelTagsDetail)
  const dispatch = useDispatch();

  const handleKeyDown = (event, value) => {
    if (event.keyCode === 13) {
      addTagButton()
    }
  };

  const handleLinkLabelChange = (values) => {
    let labelValues = []
    let labelId = []
    let flag = false
    let labelArray = []
    setMultiInputValue(values)
    values && values.map(value => {
      labelArray.push(value.value)
      labelValues.push({ label: value.label, value: value.value, id: value.tag_id ? value.tag_id : 0 })
      labelId.push(value.tag_id ? value.tag_id : 0)
    })
    setLabelNames(labelArray)
    setLabelIds(labelId)
    setInputValue('')
  }

  useEffect(() => {
    let labelsList = []
    if (project && project.labelTagsAllDetail && project.labelTagsAllDetail.length > 0) {
      project && project.labelTagsAllDetail.map(function (label) {
        labelsList.push({ value: label.tag_name, label: label.tag_name, isLabelShared: label.is_shared, tag_id: label.tag_id })
      })
    }
    setLabelOptionList(labelsList)
    setLabels(project && project.selectedTagsDetails && project.selectedTagsDetails.selectedTags)
  }, [project])

  const addTagButton = () => {
    let selectedTagsDetailsPayload = {}
    let selectedTags = []
    let tagIds = project && project.selectedTagsDetails && project.selectedTagsDetails['tagIds'] && project.selectedTagsDetails['tagIds'].length > 0 ? labelIds.concat(project && project.selectedTagsDetails['tagIds']) : labelIds
    let tagNames = project && project.selectedTagsDetails && project.selectedTagsDetails['tagDescriptions'] && project.selectedTagsDetails['tagDescriptions'].length > 0 ? [...new Set(labelNames.concat(project && project.selectedTagsDetails['tagDescriptions']))] : labelNames
    let multiValues = project && project.selectedTagsDetails && project.selectedTagsDetails['tagValues'] && project.selectedTagsDetails['tagValues'].length > 0 ? [...new Set(multiInputValue.concat(project && project.selectedTagsDetails['tagValues']))] : multiInputValue
    selectedTagsDetailsPayload['tagIds'] = tagIds
    selectedTagsDetailsPayload['tagDescriptions'] = tagNames
    selectedTagsDetailsPayload['tagValues'] = multiValues
    labelOptionList && labelOptionList.map(function (label, index) {
      if (tagIds.includes(label.tag_id)) {
        selectedTags.push(label)
      }
    })
    tagIds.map(function (id, index) {
      if (id === 0) {
        selectedTags.push({ id: id, description: tagNames[index], isLabelShared: false, value: tagNames[index], label: tagNames[index] })
      }
    })
    selectedTagsDetailsPayload['selectedTags'] = selectedTags
    dispatch(updateSelectedTagDetails(selectedTagsDetailsPayload))
    setLabelNames([])
    setLabelIds([])
    setMultiInputValue([])
    setInputValue('')
  }

  const handleUnLinkTags = (value, index) => {
    dispatch(removeSelectedTagDetails({id: value.tag_id ? value.tag_id : value.id, description: value.value, index: index}))
  }

  const shareOptionsRenderer = (option) => {
    return (
        <div id={`option-${option.value}`} key={`key-${option.value}`} className={`${option.isLabelShared ? "shared-label-block" : "label-block"}`}>
            <span><Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1"/> {option.label}</span>
        </div>
    )
  }

  return (
    <div className="share-details label-list">
      <div className="share-details-wrapper">
        <InputGroup>
          <Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="me-2 circle-border" />
          <CreatableSelect
            options={labelOptionList}
            promptText={(label) => `${label}`}
            inputValue={inputValue}
            isClearable
            formatOptionLabel={(option) => shareOptionsRenderer(option)}
            isMulti
            value={multiInputValue}
            placeholder={<span className='label-placeholder'> Create new tag </span>}
            onChange={(newValue) => handleLinkLabelChange(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onInputKeyDown={handleKeyDown}
          />
          <div><button className="primary-btn btn-share" onClick={addTagButton}>Add tag</button></div>
        </InputGroup>
      </div>
      <div className="label-details-wrapper">
        {
          labels && labels.map(function (label, index) {
            return (
              <span key={`label-list-${index}`} className={`${label.is_shared ? 'shared-labels-list-wrapper' : 'labels-list-wrapper'}`}>
                <a className={`${label.is_shared ? 'shared-labels-list' : 'labels-list'}`} id={`label-${index}`}>
                <Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1"/> 
                  {label.value}
                </a>
                <span className="remove-label" aria-hidden="true" onClick={() => { handleUnLinkTags(label, index) }}>×</span>
              </span>)
          })
        }
      </div>
    </div>
  )
}

export default SelectorControlAddTag