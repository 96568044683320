import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import { updateUserSettings, fetchUserSettings } from "../apiclient"
function Hints({ active }) {
    const settings_container = useSelector((state) => state.settings);
    const userData = useSelector((state) => state.user.user);

    const [isChecked, setIsChecked] = useState(settings_container.preferences.hint_text)
    const dispatch = useDispatch();

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked)
    };

    const handleSubmit = () => {
        updateUserSettings({ 'hint_text': isChecked }, userData.user_id, dispatch, 'hints')
    }

    useEffect(() => {
        fetchUserSettings(userData.user_id, false, dispatch)
        if (active) {
            setIsChecked(settings_container.preferences.hint_text)
        }
    }, [active])

    return (
        <div className="hints-wrapper">
            <div className="content-panel p-1 pt-2">
                <div className="col-md-12 col-sm-12">
                    <label className="font-bold">Enable/Disable hints on icons</label>
                    <span className="display-block"></span>
                    <FormGroup className="notification-shares" check >
                        <div className="check-box-button">
                            <Input
                                type="checkbox"
                                id="hints-checkbox"
                                name="hints"
                                checked={isChecked}
                                onChange={e => handleCheckboxChange(e)} />
                            <Label for="hints-checkbox">Show Hints</Label>
                        </div>
                    </FormGroup>
                </div>
            </div>
            <button className={"primary-btn btn-save-cancel mt-3"} onClick={() => handleSubmit()}>Save</button>
        </div>
    );
}

export default Hints;