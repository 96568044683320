import React, { useEffect, useState } from 'react'
import { Marker, ZoomControl } from "react-mapbox-gl"
import mapboxgl from "mapbox-gl"
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector } from 'react-redux';

import { Map, MAPBOX_TOKEN } from '../../utils/settings'

var MapboxDirections = require('@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions')
const { styles: { basic, flyToOptions } } = require('../../utils/config.json')

var directions = {}

let geolocate = new mapboxgl.GeolocateControl({
  positionOptions: {
    enableHighAccuracy: true
  },
  fitBoundsOptions: {
    maxZoom: 9.5
  },
  trackUserLocation: false,
  style: basic
});

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapDirections = (props) => {

  const [mapStatus, setMapStatus] = useState(false)
  const project = useSelector((state) => state.project)
  const [mapContainer, setMapcontainer] = useState({})

  directions = new MapboxDirections({
    accessToken: MAPBOX_TOKEN,
    unit: 'imperial',
    profile: 'mapbox/driving',
    placeholderOrigin: 'Starting point',
    placeholderDestination: `${project && project.detailsPage.objectType === "project" ? 'Project' : 'Company'} address`,
    interactive: false,
    controls: { profileSwitcher: false }
  })

  const getCurrentLocation = () => {
    var lat = null, lng = null
    var fitBox;
    navigator.geolocation.getCurrentPosition(function (position) {
      lat = position.coords.latitude
      lng = position.coords.longitude
      if (lng !== null && lat !== null) {
        directions.setOrigin([position.coords.longitude, position.coords.latitude])
        fitBox = [[project && project.detailsPage.longitude, project && project.detailsPage.latitude], [lng, lat]]
        mapContainer.fitBounds(fitBox, {
          speed: 4,
          padding: {
            top: 60,
            bottom: 30,
            left: 30,
            right: 30
          }
        })
      }
      var mapOrigin = document.getElementById('mapbox-directions-origin-input')
      if (mapOrigin !== null) {
        mapOrigin.getElementsByClassName('geocoder-icon-close')[0].classList.add('active')
        mapOrigin.getElementsByTagName('div')[0].getElementsByTagName('input')[0].value = 'Current location'
      }
    })
  }

  const onStyleLoad = (map) => {
    setMapcontainer(map)
    const { onStyleLoad } = project;
    map.addControl(geolocate)
    if (props.mapDirectionFlag) {
      setMapStatus(props.mapDirectionFlag)
    }
    return onStyleLoad && onStyleLoad(map);
  }

  const onLoad = (map) => {
    setMapcontainer(map)
    const { onLoad } = project;
    map.addControl(directions, 'top-left')
    return onLoad && onLoad(map);
  }

  useEffect(() => {
    if (props.mapDirectionFlag && mapContainer && Object.keys(mapContainer).length !== 0) {
      getCurrentLocation()
    }
    setMapStatus(props.mapDirectionFlag)
  }, [props])

  const onZoom = (map) => {
    if (typeof (map) !== 'undefined' && map !== null && typeof (map.style) !== 'undefined' && typeof (map.transform) != 'undefined') {
      map.style.z >= 15.5 ? map.transform.pitch = 60 : map.transform.pitch = 0
    }
  }

  useEffect(() => {
    if (props.mapDirectionFlag && (mapContainer && Object.keys(mapContainer).length !== 0)) {
      var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      if (typeof (mapCanvas) !== 'undefined' && mapCanvas !== null) {
        mapCanvas.style.width = '100%'
        getCurrentLocation()
      }
      directions.setDestination([project && project.detailsPage.longitude, project && project.detailsPage.latitude])
      setTimeout(() => {
        var destination = document.getElementById('mapbox-directions-destination-input')
        if (destination !== null) {
          destination.getElementsByTagName('div')[0].getElementsByTagName('input')[0].setAttribute('readonly', true)
          destination.getElementsByTagName('div')[0].getElementsByTagName('input')[0].setAttribute('disabled', true)
          destination.getElementsByClassName('geocoder-icon-close')[0].classList.remove('active')
          destination.getElementsByTagName('div')[0].getElementsByTagName('input')[0].value = project && project.detailsPage && project.detailsPage.name.concat(', ', project && project.detailsPage.address3)
        }
      }, 300);
      onLoad(mapContainer)
    }
  }, [props.mapDirectionFlag, project.detailsPage, mapContainer])

  return (
    <div className="direction-map-wrapper">
      {(project && project.detailsPage.longitude !== 0 && project && project.detailsPage.longitude !== undefined) ||
        (project && project.detailsPage.latitude !== 0 && project && project.detailsPage.latitude !== undefined) ?
        (
          mapStatus ? (
            <div className="map-view">
              <Map
                style={basic}
                onStyleLoad={onStyleLoad}
                onZoom={onZoom}
                flyToOptions={flyToOptions}
                movingMethod="jumpTo"
                trackResize={true}
                center={[project && project.detailsPage.longitude, project && project.detailsPage.latitude]}
              >
                <ZoomControl style={{ top: 65 }} className='zoomCtrlclass' position="top-right" />
                <Marker
                  className={`map-${project && project.detailsPage.objectType} `}
                  coordinates={[project && project.detailsPage.longitude, project && project.detailsPage.latitude]}
                >
                </Marker>
              </Map>
            </div>) : ('')) : (
          <div>
            {
              project && project.detailsPage.objectType === "project" ?
                'This project does not have lat-long, please contact your account manager for more information.' :
                project && project.detailsPage.objectType === "company" ? 'This company does not have lat-long, please contact your account manager for more information.' :
                  'This contact company does not have lat-long, please contact your account manager for more information'
            }
          </div>
        )
      }

    </div>
  );
}

export default MapDirections;
