import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ForgotPassword from './forgotpass';
import SignInHeader from './header';
import SignInLeftPanel from './leftpanel';
import SignIn from './signin';
import AlertMessage from '../alerts';
import './login.scss'
import { useNavigate } from 'react-router-dom';

const Login = () => {
	let navigate = useNavigate()
	const component = useSelector((state) => state.user.component)
    const user = useSelector((state) => state.user);

	useEffect(()=>{
		if(user?.isAuthenticated){
			navigate("app/list")
		}
	},[])
	
	return (
		<div className="login-page">
			<SignInHeader />
			<div id="maps"></div>
			<div id="message-container">
				<AlertMessage />
			</div>
			<div className="loginContainer row">
				<Col className="container-fluid login-main-container">
					<Row className="main-row-container">
						<Col lg="12" className="align-items-top justify-content-center my-5 row pe-1">
							<Col lg="8" xl="8" className="pe-0 me-0">
								<Row>
									<SignInLeftPanel />
									{ component === 'forgotpassword' ? <ForgotPassword/> : <SignIn/> }
								</Row>
							</Col>
							
						</Col>
					</Row>
				</Col>
			</div>
		</div>
	);
}

export default Login;