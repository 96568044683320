import React, { useState, useEffect } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import ColoredScrollbars from '../../../../common/js/coloredScrollbars';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import CompanyRelationshipRoles from './companyRelationshipRoles';
import { fetchCompanyRelatedCompanies } from '../../../apiclient';
import { useLocation } from 'react-router-dom';
import { updateIsJointProjectViewed, updateListSelection ,updateShowMoreOffsetLimit} from '../../../redux/reducer';

const CompanyRelationShips = ({ columnOneRole }) => {
    const [activeTab, setActiveTab] = useState('none');
    const [relatedRoles, setRelatedRoles] = useState()
    const [roleID, setRoleId] = useState()
    const [buttonClicked,setButtonClicked]=useState(false)
    const details = useSelector((state) => state.project)
    const dispatch = useDispatch();
    const location = useLocation().pathname;

    const toggle = (tab) => {
        if (activeTab !== tab.role_code) {
            setActiveTab(tab.role_code)
        }
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateIsJointProjectViewed(false))
        dispatch(updateListSelection({ count:0, entityType: 'relatedCompaniesJointProjects' }))
        setButtonClicked(true)
    }
  
    useEffect(() => {
        let roleId = []
        if (activeTab === 0) {
            if (relatedRoles && relatedRoles.length > 0) {
                relatedRoles && relatedRoles.map(role => {
                    if (role.role_code !== 0) {
                        roleId.push(role.role_code)
                    }
                })
                setRoleId(roleId)
            }
        }

        setRelatedRoles(details && details.companyRelatedRoles)

    }, [relatedRoles, details && details.companyRelatedRoles, details && details.companyRelatedCompanies])
   
    useEffect(() => {
        if (buttonClicked) {
            fetchCompanyRelatedCompanies(location.split('/')[4], columnOneRole.role_code, activeTab, dispatch, '-count_project_id', details)
            setButtonClicked(false)
        }
    }, [buttonClicked]);

    return (
        <>
            <div className="tab-pane pb-3 active">
                <div className="box-view">
                    <Row className="m-0">
                        <Col sm="4" md="4" lg="4" className="p-0 nav-col height-400 overflow-hidden">
                            <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Show companies that work with <strong>{details && details.detailsPage && details.detailsPage.company_name}</strong></p>
                            </div>
                            <ColoredScrollbars className="scroll-wrapper">
                                <Nav tabs vertical className="mb-5">
                                    {relatedRoles && relatedRoles.length > 0 &&
                                        relatedRoles.map(function (role, index) {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        className={classnames({ active: parseInt(activeTab) === role.role_code })}
                                                        onClick={() => { toggle(role); }}
                                                    >
                                                        {role.role_code_display} {role.role_code_display === 'Any Role' ? role.project_count ? <span>({role.project_count})</span> : <span>(0)</span> : role.count ? <span>({role.count})</span> : <span>(0)</span>}
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })
                                    }
                                </Nav>
                            </ColoredScrollbars>
                        </Col>
                        <Col sm="8" md="8" lg="8" className="p-0 detail-col height-400">
                            <TabContent activeTab={activeTab} className="p-0">
                                {
                                    activeTab !== 'none' ? 
                                    relatedRoles && relatedRoles.length > 0 && relatedRoles.map((role, index) => (
                                        role.role_code === activeTab && (
                                            <TabPane tabId={role.role_code}>
                                                <div className="selected-col p-3"><p className="m-0 col-heading"><strong>{role.role_code_display}</strong> that work with <strong>{details && details.detailsPage && details.detailsPage.company_name}</strong> in their role as an <strong>{columnOneRole && columnOneRole.role_code_display}</strong></p></div>
                                                <CompanyRelationshipRoles columnOneRole={columnOneRole.role_code} columnTwoRole={activeTab}/>
                                            </TabPane>
                                        )
                                    )) :
                                    <div className="p-0 select-roles-placeholder height-400">
                                        <span>Show companies that work with <strong>{details && details.detailsPage && details.detailsPage.company_name}</strong> in their role as an <strong>{columnOneRole && columnOneRole.role_code_display}</strong></span>
                                    </div>
                                }
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default CompanyRelationShips;