import React, { useEffect, useState } from 'react';
import IncludeExcludeGrid from './includeExcludeGrid';
import {
    FormGroup,
    Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateTextInputFilter } from './redux/reducer';

const DevTypeFilter = (props) => {
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [data, setData] = useState(searchPersist?.customerProfile['project_development_types']?.values)
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_development_types']?.all_subscribed)
    const [showSubscribed, setShowSubscribed] = useState(false)
    const [isClearAll , setIsClearAll] = useState(props?.isClearAll)
    const dispatch = useDispatch()

    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
    }, [props?.isClearAll])

    useEffect(() => {
        dispatch(updateTextInputFilter(getDefaultInputTextFilterPayload()))
    }, [])

    function getDefaultInputTextFilterPayload() {
        return {
            text: '',
            filter: props.dataMappings ? props.dataMappings.filterType : '',
            child: props.dataMappings ? props.dataMappings.subFilterType : '',
            isIncludeExclude: props?.dataMappings?.isIncludeExclude,
            includeKey: props.dataMappings?.includeKey,
            excludeKey: props.dataMappings?.excludeKey
        }
    }

    // useEffect(() => {
    //     props.initTags()
    // }, [filter?.filtersState])

    return (
        <>
            {
                props.checkboxListFilters?.map(mapping => (
                    props.currentAdvancedSearch && (props.currentAdvancedSearch[mapping.includeKey] || props.currentAdvancedSearch[mapping.excludeKey]) ? (
                        <>
                            <div className="advanced-filter-grid" id="new_filter_devtype">
                                <div className="filterHeading">
                                    <h5 className="mb-3">Development type</h5>
                                </div>
                                <div className="filterBlock">
                                    <div className="textcontent">
                                        Include or exclude by project development type
                                    </div>
                                    <div className="checkBoxFilterBx">
                                        <FormGroup id="developmentTypeFilter" name="developmentTypeFilter" row className="pb-0 mb-0 px-0 row form-group">
                                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                                <IncludeExcludeGrid
                                                    filterData={data}
                                                    filterType="project_development_types"
                                                    allSubscribed={allSubscribed}
                                                    showAll={showAll}
                                                    dataMappings={mapping}
                                                    currentFilters={props.currentAdvancedSearch[mapping.filter]}
                                                    includeFilters={props.currentAdvancedSearch[mapping.includeKey]}
                                                    excludeFilters={props.currentAdvancedSearch[mapping.excludeKey]}
                                                    variablesHandler={props.variablesHandler}
                                                    initTags={props.initTags}
                                                    fetchEntityCounts={props.fetchEntityCounts}
                                                    filterTitle={'Development type'}
                                                    showSubscribed={showSubscribed}
                                                    isClearAll={isClearAll}
                                                    handleSearchText={props.handleSearchText}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </>) : ('')
                ))
            }
        </>
    )
}
export default DevTypeFilter;
