import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Media, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup, Input } from 'reactstrap';
import SearchInput, { createFilter } from 'react-search-input';
import Select from 'react-select';
import CreateUser from '../createuser';
import UserList from '../userList';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { updateIsAddNewUser, modalOpenClose, updateIsEditUser, updateAdminUserOffsetLimitValue, updateIsUniqueUser, updateUserSearchTerm } from '../reducer';
import _ from 'lodash'
import { deleteUserWithoutTransfering, deleteUserWitTransfering, fetchAllUserListDeleteWithTransfer, fetchAllUsersForAdmin } from '../apiclient';
import { createReceipientUserList } from '../utils';
import { getInitials } from '../../../utils';

const Users = (props) => {
  const useradminData = useSelector(state => state.useradmin)
  const [searchTerm, setSearchTerm] = useState(useradminData?.userSearchTerm);
  const [isAddNewUser, setIsAddNewUser] = useState(props.isAddNewUser);
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen);
  const[isEditFlag,setIsEditFlag]=useState(false)
  const [isEditUser, setIsEditUser] = useState(false);

  const [flag, setFlag] = useState(false);
  const [modal, setModal] = useState(useradminData.isModalOpen);
  const [isDeleteWithTransfer, setIsDeleteWithTransfer] = useState(true);
  const [multiValue, setMultiValue] = useState({});
  const [options, setOptions] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
	const [filteredAdminUserList, setFilteredAdminUserList] = useState([]);
  const [deletedId,setDeletedId]=useState(null)
  const [showMoreClicked, setShowMoreClicked] = useState(false)
  const [selectedUserValue, setSelectedUserValue] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [isShowMoreActive,setIsShowMoreActive]=useState(useradminData?.isUserAdminShowMoreActive)
  const [userListDeleteWithTransfer, setUserListDeleteWithTransfer] = useState([useradminData?.userListDeleteWithTransfer]);

   const dispatch = useDispatch();

  const toggle = () => {
    setModal(true);
  };

  const handleDeleteWithTransferChange = (e, value) => {
    setIsDeleteWithTransfer(value);
  };

  const handleChange = (selectedOption) => {
    setSelectedUserValue(selectedOption.value);
    setSelectedUserName(selectedOption.label);
    setMultiValue({value:selectedOption.value,label:selectedOption.label});

  };


  const handleDeleteUser = (e, transfer) => {
    deleteUserWithoutTransfering(deletedId,searchTerm,dispatch)
    dispatch(modalOpenClose(false))
  };

  const handleEditToggle = (flag, editUserId, event) => {
    setIsEditUser(flag);
    setEditUserId(editUserId);
  };

  
  const EditFlag = (flag) => {
    setIsEditFlag(flag)
    dispatch(updateIsEditUser(flag))
  }


  const teamInitials = (value) => {
    var teamName = value.split(" ")
    var teamInitial = teamName.length > 1 ? teamName[0][0] + teamName[1][0] : teamName[0][0]
    return teamInitial.toUpperCase()
  }

  const handleCancel = () => {
    dispatch(modalOpenClose(false))
    setModal(!modal)
    setMultiValue({})
    setSelectedUserValue("")
  };
  

  const transferOptionsRenderer = (option) => {
    return (
      <div className={`${(option.label === 'Teams' || option.label === 'Users') ? 'block-title' : 'list-user-team'}`} key={option.value}>
        {option.initials != '-' ? (<a className="user-dropdown-icon">{option.initials}</a>) : ""}
        <span className={`${(option.label === 'Teams' || option.label === 'Users') ? 'select-header' : option.email == '-' ? 'team-name-class users-teams-title' : 'users-teams-title'}`}>{option.label}
          {option.email != '-' ? (<a className="email-address"> {option.email} </a>) : ('')}</span>
      </div>
    )
  };

  const handleAdminUserSearch = (term) => {
    setSearchTerm(term);
    dispatch(updateUserSearchTerm(term))
  };

  const handleUserListClose = (event) => {
    event.preventDefault()
    setSearchTerm("")
    dispatch(updateUserSearchTerm(""))
    setShowMoreClicked(true)
  }

  const handleAddNewUser = () => {
    setIsAddNewUser({ isAddNewUser: true });
    dispatch(updateIsAddNewUser(true))
    dispatch(updateIsUniqueUser(true))
  };

  const handleDeleteModal = (userId,flag) => {
    setFlag(flag)
    setModal(!modal)
    setDeletedId(userId)
  }

  const handleShowMore = () => {
    let newOffset = useradminData?.offsetLimitValue?.offset + useradminData?.offsetLimitValue?.records;
    dispatch(updateAdminUserOffsetLimitValue({ offset: newOffset, records: useradminData?.offsetLimitValue?.records }));
    setShowMoreClicked(true)
    let paylaod = {
      offset: newOffset,
      limit: useradminData?.offsetLimitValue?.records,
      searchTerm: ""
    }
    fetchAllUsersForAdmin(paylaod, dispatch, useradminData)
}
  const handleSetTransferUserOption = (userList) => {
    let options = []
    if(userList?.length>0){
      userList = userList && userList.filter(item => ((item.user_id !== deletedId) && !(item.delete_status)))
      userList.map(function (user, index) {
        options.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email })
      })
      setOptions(options)
    }
  }
  const handleDeleteUserWithTransfer = () => {
    deleteUserWitTransfering(deletedId, selectedUserValue, searchTerm, dispatch)
    setSelectedUserValue("")
    setMultiValue({})
    dispatch(modalOpenClose(false))
    setModal(!modal)
  }

  const handleDeleteRetryUser = (id) => {
    deleteUserWithoutTransfering(id, useradminData, dispatch)
    dispatch(modalOpenClose(false))
  }

  
  useEffect(() => {
    if (props?.activeTab === "users") {
      if (searchTerm === "") {
        dispatch(updateAdminUserOffsetLimitValue({ offset: 0, records: 50 }));
       }
      let paylaod = {
        offset: 0,
        limit: 50,
        searchTerm: searchTerm
      }
      fetchAllUsersForAdmin(paylaod, dispatch, useradminData)
      if (!showMoreClicked) {
        fetchAllUserListDeleteWithTransfer(dispatch)
      }

    }
  }, [props?.activeTab,searchTerm]);

  useEffect(() => {
    if (props?.activeTab === "users") {
      let sortedUsersList = createReceipientUserList(useradminData?.allUserDetails);
      setFilteredAdminUserList(sortedUsersList);
    }
  }, [useradminData?.allUserDetails, searchTerm]);
  useEffect(() => {
    setIsShowMoreActive(useradminData?.isUserAdminShowMoreActive)
  }, [useradminData?.isUserAdminShowMoreActive])

  useEffect(() => {
    if (useradminData?.isModalOpen) {
      handleSetTransferUserOption(userListDeleteWithTransfer)
    }

  }, [useradminData?.isModalOpen])

  useEffect(()=>{
    setUserListDeleteWithTransfer(useradminData?.userListDeleteWithTransfer)
  },[useradminData?.userListDeleteWithTransfer])

  useEffect(()=>{
    setSearchTerm(useradminData?.userSearchTerm)
  },[useradminData?.userSearchTerm])

  return (

    props.activeTab === 'users' &&
    (<div className="content-wrapper">
      <div className="user-tab user-list-body">
        <Media className="user-search-panel">
          <Media body>
            <Media heading>
              <div className="p-0 textfield-wrapper col col-sm-9 col-md-6 col-lg-3">
                <SearchInput
                  placeholder="Find user"
                  className="label-search search-user-textbox"
                  type="text"
                  onChange={handleAdminUserSearch}
                  value={searchTerm}
                />
                <Button
                  className={`clear-textfield ${searchTerm ? 'display-block' : 'display-none'}`}
                  onClick={handleUserListClose}
                  tabIndex="-1"
                >x</Button>
              </div>
              <div className="src-button">
                <button
                  type="button"
                  className="outline-cta-btn add-user"
                  onClick={() => handleAddNewUser(true)}
                >New User</button>
              </div>
              <div className="sort-results"></div>
            </Media>
          </Media>
        </Media>

        <Media className={`add-new-user-wrapper ${useradminData.isAddNewUser ? 'show' : 'hide'}`}>
          <Media left href="#">
          </Media>
          <Media body>
            <Media heading>
              Add New User
            </Media>
            <CreateUser {...props} handleAddNewUser={handleAddNewUser} searchTerm={searchTerm} />
          </Media>
        </Media>

        <table className={`table ${useradminData.isAddNewUser ? 'drag-down' : ''}`}>
          <thead className="table-head">
            <tr>
              <Row className="list-header">
                <Col className="user-names" xs="12" sm="12" md="3">Name</Col>
                <Col className="user-email" xs="12" sm="12" md="4">Email</Col>
                <Col className="user-contacts" xs="12" sm="12" md="2">Contact number</Col>
                <Col className="user-license" xs="12" sm="12" md="3">Licence</Col>
              </Row>
            </tr>
          </thead>
          <tbody className="main-body">
            <tr>
              <div className="list-wrapper mt-2 users-list-wrapper">
                <ColoredScrollbars className="details-panel-scrollbar">
                  <div className="user-list-container mr-0">
                    <div className="user-list-block">
                    {filteredAdminUserList?.length > 0 ? (
												filteredAdminUserList.map(function (user, index) {
													return <UserList {...props}
                          handleDeleteModal={handleDeleteModal}
                          handleEditToggle={handleEditToggle}
                          handleDeleteRetryUser={handleDeleteRetryUser} 
                          searchTerm={searchTerm}
                           isEditFlag={EditFlag}
                           isEditUser={editUserId === user.user_id ? isEditUser : false}
                             result={user} 
                             index={index} 
                             key={index} />
												})) : ""}
                     
                    </div>
                  </div>

                  {
                    isShowMoreActive && filteredAdminUserList?.length > 0 && searchTerm === "" ? (
                      <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                        <Button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</Button>
                      </div>
                    ) : ''
                  }
                
                </ColoredScrollbars>
              </div>
            </tr>
          </tbody>
        </table>

 
    
        {!flag ? (
          <Modal isOpen={useradminData.isModalOpen}>
            <ModalHeader> Are you sure you want to delete the user?
              <a className="save-cancel-top" onClick={handleCancel}>Cancel</a>
            </ModalHeader>
            <div className="info-patch">
              Deleting a user will also delete their notes, tags and other data.
              To proceed, select an option to transfer their data to another user or delete it.
              Otherwise click cancel. This action cannot be undone.
            </div>
            <ModalBody className="save-model-body">
              <FormGroup className="radio-grid-wrapper mb-0">
                <Row className={`radio-list-item m-0 pt-2`}>
                  <Col sm={12}>
                    <fieldset className="form-group mb-0 radio-buttons pb-1">
                      <FormGroup check>
                        <Input
                          type="radio"
                          id="radio-1"
                          name="delete-user"
                          checked={isDeleteWithTransfer}
                          onChange={(e) => handleDeleteWithTransferChange(e, true)}
                        />
                        <Label check for={"radio-1"}>
                          Transfer data to another user
                        </Label>
                      </FormGroup>
                    </fieldset>

                    {isDeleteWithTransfer && (
                      <div className="share-details transfer-selectshare pt-0 pb-2 pr-0">
                        <div className="share-details-wrapper">
                          <InputGroup>
                            <Select
                              cache={false}
                              multi={false}
                              options={options}
                              formatOptionLabel={(option) => transferOptionsRenderer(option)}
                              onChange={handleChange}
                              value={multiValue}
                              placeholder={<span className='label-placeholder'>Select User</span>}
                            />
                          </InputGroup>
                        </div>
                      </div>)}
                  </Col>
                  <Col sm={12}>
                    <fieldset className="form-group mb-0 radio-buttons pb-0">
                      <FormGroup check>
                        <Input
                          checked={!isDeleteWithTransfer}
                          onChange={(e) => handleDeleteWithTransferChange(e, false)}
                          name="delete-user"
                          type="radio"
                          id="radio-2" />
                        <Label check for={"radio-2"}>
                          Delete without transferring data
                        </Label>
                        <div className="check"></div>
                      </FormGroup>
                    </fieldset>
                  </Col>
                </Row>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {isDeleteWithTransfer && (<>
                <Button
                  className="outline-btn btn-save-cancel"
                  onClick={handleCancel}>Cancel</Button>
                {
                  selectedUserValue !="" ? <Button
                    className="primary-btn btn-save-delete  mr-3" onClick={handleDeleteUserWithTransfer}
                  >Transfer and Delete</Button> : <Button
                    className="primary-btn btn-save-delete disabled mr-3" 
                  >Transfer and Delete</Button>
                }
              </>)}

              {!isDeleteWithTransfer && (<>
                <Button
                  className="outline-btn btn-save-cancel"
                  onClick={handleCancel}>Cancel</Button>

                <Button
                  className="primary-btn btn-save-delete mr-3"
                  onClick={(e) => handleDeleteUser(e, false)}
                >Delete now</Button></>)}
            </ModalFooter>
          </Modal>
        )
          :
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>
              <a className="save-cancel-top"onClick={handleCancel}>Cancel </a>
            </ModalHeader>
            <div className="info-patch admin">
              You cannot delete Admin user. Remove Admin licence and try again.
            </div>
            <ModalFooter>
              <Button className="outline-btn btn-save-cancel" onClick={handleCancel}>Ok</Button>
            </ModalFooter>
          </Modal>
        }

      </div>
    </div>)
  );
};

export default Users;