import { createBrowserHistory } from "@remix-run/router"
import _ from "lodash"
import { setAlert } from "../../redux/features/user/userslice"
import { messageAlert } from "../../utils"
import getAxios from "../../utils/baseApi"
import { handleError } from "../../utils/errorHandler"
import {
    fetchShowMoreListOfexports, udpateRerunExportList, updateExportList, updateExportTemplateData, updateIsExportCountAPILoaded,
    updateIfShowMoreAvailable, updateExportSaveSearch, updateExportRecordsCount, updateSavedSearchCount, updateExportFieldsForCompanies,
    updateExportFieldsForProjects, updateExportFieldsForContacts, exportTemplateSelectionHandler, exportTemplateIdSelectionHandler,
    updateExistingValues, resetDataExportCriteria, updateExportCriteria, updateCopyExport, updateFilteredExportList
} from "./reducer"
import { getExportTemplateStaticFields } from "./utils"
import { encodeValues } from "../../utils/commonFunctions"

var axios = getAxios()
export const fetchExportList = (userId, dispatch, exports) => {
    axios.get(`users/${userId}/exports?limit=${exports?.showMoreOffsetLimit?.records}&offset=${exports?.showMoreOffsetLimit?.offset}&sort=-created_on`).then((res) => {
        if (res.status === 200) {
            if (exports?.showMoreOffsetLimit?.offset > 0) {
                fetchShowMoreListOfexports(res?.data)
            }
            else {
                dispatch(updateExportList(res.data))
            }
            if (res?.data?.export_count > (parseInt(exports?.showMoreOffsetLimit?.offset) + parseInt(exports?.showMoreOffsetLimit?.records))) {
                dispatch(updateIfShowMoreAvailable(true))
            } else {
                dispatch(updateIfShowMoreAvailable(false))
            }

        }
        else if (res.status === 201 || res.status === 204) {
            dispatch(updateExportList(res.data))
            dispatch(updateIfShowMoreAvailable(false))
        }
    }).catch((err) => {
        handleError(err)
    })
}

export const fetchRerunFeed = (payload, dispatch) => {
    axios.put(`users/${payload.userno}/exports`, payload).then((res) => {
        if (res.status === 202) {
            dispatch(udpateRerunExportList(payload.deliveryno))
            messageAlert(dispatch, "Export rerun request scheduled", 'success');
        }
    }).catch((err) => {
        handleError(err)
    })
}

export async function fetchTemplates(dispatch, payload) {
    axios.get('templates')
        .then(function (response) {
            dispatch(updateExportTemplateData(response.data != '' ? response.data : []))
            if (payload !== undefined) {
                dispatch(exportTemplateSelectionHandler(payload.template_name))
                let templateId = payload.id ? payload.id : _.filter(response.data, item => item.template_name === payload.template_name)?.[0]?.template_id
                if (templateId !== undefined) {
                    dispatch(exportTemplateIdSelectionHandler(templateId))
                }
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function exportSaveSearch(dispatch) {
    axios.get('saved_searches')
        .then(function (response) {
            dispatch(updateExportSaveSearch(response.data != '' ? response.data.saved_searches : []))
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchExportRecordsCount = async (payload, dispatch, saveseachFlag, copyFeedPayload) => {
    let type = payload.object_type === 'project' ? 'projects' : payload.object_type === 'company' ? 'companies' : 'people'
    let url = `${type}`
    if (payload.key === 'saved_search') {
        if (payload.query) {
            const validKeys = ["project_title", "project_text", "project_scheme", "company_text", "person_text", "company_name", "person_full_name", "person_job_title"];
            let encodedQuery = encodeValues(payload.query, validKeys)
            url += `?query=${JSON.stringify(encodedQuery)}`
        }
        if (payload.project_search) {
            url += `&project_search=${payload.project_search}`
        }
        if (payload.brand_search) {
            url += `&brand_search=${payload.brand_search}`
        }
        url = extractMailedUrl(payload, url)
        if (url.includes('?')) {
            url += `&limit=0`
        } else {
            url += `?limit=0`
        }
    }
    else if (payload.key === 'tags') {
        if (payload.query) {
            url += `?query=${JSON.stringify(payload.query)}`
        }
        if (url.includes('?')) {
            url += `&tag_id=${payload.tag_id}`
        } else {
            url += `?tag_id=${payload.tag_id}`
        }
        url = extractMailedUrl(payload, url)
        if (url.includes('?')) {
            url += `&limit=0`
        } else {
            url += `?limit=0`
        }
    }
    await axios.get(url).then((response) => {
        dispatch(updateExistingValues(payload))
        dispatch(updateIsExportCountAPILoaded(false))
        let count = response?.data?.aggregation ? type === 'projects' ? response?.data?.aggregation.project_count :
            type === 'companies' ? response?.data?.aggregation.company_count : response?.data?.aggregation.people_count : 0
        if (payload.key === 'saved_search') {
            dispatch(updateSavedSearchCount({ count: count, object_type: type, savesearchFlag: saveseachFlag }))
        }
        else {
            dispatch(updateExportRecordsCount({ count: count, object_type: type, savesearchFlag: saveseachFlag }))
        }
        if (copyFeedPayload !== undefined) {
            copyFeedPayload = {
                ...copyFeedPayload,
                recordsCount: count
            }
            dispatch(updateExportCriteria(copyFeedPayload))
            dispatch(updateCopyExport(true))
        }
    })
        .catch(function (error) {
            dispatch(updateIsExportCountAPILoaded(false))
            // handleError(error, dispatch)
        })
}

export const fetchExportTemplateFields = async (dispatch, id) => {
    let fieldId = id === undefined ? 3 : id
    await axios.get(`templates/${fieldId}/fields`)
        .then(async function (response) {
            await dispatch(updateExportFieldsForProjects(getExportTemplateStaticFields(response.data.projects)))
            await dispatch(updateExportFieldsForCompanies(getExportTemplateStaticFields(response.data.companies)))
            dispatch(updateExportFieldsForContacts(getExportTemplateStaticFields(response.data.contacts)))
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

function extractMailedUrl(payload, url) {
    if (payload.excludeMailedProjects) {
        if (url.includes('?')) {
            url += `&excludeMailedProjects=1&excludeMailedProjectsInterval=${payload.excludeMailedProjectsInterval}`
        } else {
            url += `?excludeMailedProjects=1&excludeMailedProjectsInterval=${payload.excludeMailedProjectsInterval}`
        }
    }
    if (payload.excludeMailedCompanies) {
        if (url.includes('?')) {
            url += `&excludeMailedCompanies=1&excludeMailedCompaniesInterval=${payload.excludeMailedCompaniesInterval}`
        } else {
            url += `?excludeMailedCompanies=1&excludeMailedCompaniesInterval=${payload.excludeMailedCompaniesInterval}`
        }
    }
    if (payload.excludeMailedContacts) {
        if (url.includes('?')) {
            url += `&excludeMailedContacts=1&excludeMailedContactsInterval=${payload.excludeMailedContactsInterval}`
        } else {
            url += `?excludeMailedContacts=1&excludeMailedContactsInterval=${payload.excludeMailedContactsInterval}`
        }
    }
    return url
}

export async function createNewTemplate(payload, dispatch) {
    var jsonData = JSON.stringify(payload)
    axios.post('templates', jsonData)
        .then(function (response) {
            fetchTemplates(dispatch, payload)
            messageAlert(dispatch, response.data.message,
                response.data.isError ? "danger" : "success")
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export async function deleteTemplate(id, dispatch) {
    axios.delete('templates/' + id)
        .then((response) => {
            fetchTemplates(dispatch)
            messageAlert(dispatch, response.data.message,
                response.data.isError ? "danger" : "success")
        })
        .catch((error) => {
            handleError(error, dispatch)
        });
}

export async function updateTemplate(id, payload, dispatch) {
    axios.put('templates/' + id, payload)
        .then(async function (response) {
            payload['id'] = id
            fetchTemplates(dispatch, payload)
            messageAlert(dispatch, response.data.message,
                response.data.isError ? "danger" : "success")

        })
        .catch((error) => {
            handleError(error, dispatch)
        });
}

export function createNewExport(payload, dispatch, navigate) {
    var jsonData = JSON.stringify(payload)
    axios.post(`users/${payload.user_no}/exports`, jsonData).then((res) => {
        if (res.status === 200) {
            dispatch(resetDataExportCriteria())
            messageAlert(dispatch, 'New export created – please wait for it to download', 'success');
            window.Intercom('trackEvent', 'Create export', { 'export_name': payload.feedname })
            navigate('/app/export/list')
        }
    }).catch((error) => {
        handleError(error, dispatch)
    });
}

export const fetchFilteredExportList = (dispatch, feedno) => {
    let jsonData = JSON.stringify({ "ids": { "operator": "=", "value": feedno } })
    axios.get(`/exports?query=${jsonData}`).then((response) => {
        if (response.data) {
            dispatch(updateFilteredExportList(response.data))
        }
    }).catch((err) => {
        handleError(err)
    })
}