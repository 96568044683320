import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import FavouriteRoles from './tabs/favouriteRoles';
import Hints from './tabs/hints';
import UpdatePassword from './tabs/updatePassword';
import ArchiveProjects from './tabs/archiveProjects';
import { fetchHasApiKey, fetchNotificationSettings, fetchUserSettings } from './apiclient';
import Notifications from './tabs/notifications/notifications';
import Developer from './tabs/developer';
import { DEVELOPER_LICENSE } from '../../utils/settings';

const UsersettingsDetail = () => {
    const userData = useSelector(state => state.user.user)
    const settings_container = useSelector((state) => state.settings);
    const location = useLocation().hash;
    const [activeTab, setActiveTab] = useState(location != "" ? location && location.split('#')[1] : "notifications");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState({
        notifications: false,
        favRoles: false,
        hints: false,
        archive: false,
        developer: false,
        changePass: false
    })

    const [isDeveloper, setIsDeveloper] = useState(settings_container?.isDeveloper ? settings_container?.isDeveloper : false)

    useEffect(() => {
        setIsDeveloper(settings_container?.isDeveloper ? settings_container?.isDeveloper : false)
    }, [settings_container?.isDeveloper])


    const toggle = (tab) => {
        setActiveTab(tab)
        if (tab === 'notifications') {
            fetchNotificationSettings(dispatch)
            setIsActive({
                notifications: true,
                favRoles: false,
                hints: false,
                archive: false,
                developer: false,
                changePass: false
            })
        }
        else if (tab === "hints") {
            fetchUserSettings(userData.user_id, false, dispatch)
            setIsActive({
                notifications: false,
                favRoles: false,
                hints: true,
                archive: false,
                developer: false,
                changePass: false
            })
        }
        else if (tab === "favouriteRoles") {
            setIsActive({
                notifications: false,
                favRoles: true,
                hints: false,
                archive: false,
                developer: false,
                changePass: false
            })
        }
        else if (tab === "archiveProjects") {
            setIsActive({
                notifications: false,
                favRoles: false,
                hints: false,
                archive: true,
                developer: false,
                changePass: false
            })
        }
        else if (tab === "developer") {
            fetchHasApiKey(userData?.client_id, dispatch)
            setIsActive({
                notifications: false,
                favRoles: false,
                hints: false,
                archive: false,
                developer: true,
                changePass: false
            })
        }
        else if (tab === "updatePassword") {
            setIsActive({
                notifications: false,
                favRoles: false,
                hints: false,
                archive: false,
                developer: false,
                changePass: true
            })
        }
        navigate('/app/settings#' + tab)
    }

    useEffect(() => {
        setActiveTab(location != "" ? location && location.split('#')[1] : "notifications")
    }, [location])

    return (
        <Row className="pt-2 pb-2 ms-0 me-0 mb-3 project-tab-panel">
            <Col className="p-0 project-tab-wrapper">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab === 'notifications' ? 'active' : ''} onClick={() => toggle('notifications')}>
                            Notifications
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'favouriteRoles' ? 'active' : ''} onClick={() => toggle('favouriteRoles')}>
                            Favourite roles
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'hints' ? 'active' : ''} onClick={() => toggle('hints')}>
                            Hints
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'archiveProjects' ? 'active' : ''} onClick={() => toggle('archiveProjects')}>
                            Archive projects
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === 'updatePassword' ? 'active' : ''} onClick={() => toggle('updatePassword')}>
                            Change your password
                        </NavLink>
                    </NavItem>
                    {isDeveloper ? (
                        <NavItem>
                            <NavLink className={activeTab === 'developer' ? 'active' : ''} onClick={() => toggle('developer')}>
                                Developer
                            </NavLink>
                        </NavItem>
                    ) : ''}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="notifications" className="p-3"><Notifications active={isActive.notifications} /></TabPane>
                    <TabPane tabId="favouriteRoles" className="p-3"><FavouriteRoles active={isActive.favRoles} /></TabPane>
                    <TabPane tabId="hints" className="p-3"><Hints active={isActive.hints} /></TabPane>
                    <TabPane tabId="archiveProjects" className="p-3"><ArchiveProjects active={isActive.archive} /></TabPane>
                    {isDeveloper ? (
                        <TabPane tabId="developer" className="p-3"><Developer active={isActive.developer} /></TabPane>
                    ) : ''}
                    <TabPane tabId="updatePassword" className="p-3"><UpdatePassword active={isActive.changePass} /></TabPane>
                </TabContent>
            </Col>
        </Row>
    );
}

export default UsersettingsDetail;