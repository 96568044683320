import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CommonPlaceholder from '../../details/framework/commonPlaceholder'
import CompanyRow from './companyRow'
import { groupNotesbyUpdatedDate } from '../../../utils'

const CompanyList = (props) => {
  const search = useSelector((state) => state.search)
  const justask = useSelector((state) => state.justAsk)
  const myleads = useSelector((state) => state.myleads)
  const leagueTableState = useSelector((state) => state.leagueTable)
  const searchPersist = useSelector((state) => state.searchPersist)
  const [myLeadsListUpdatedDate, setMyLeadsListUpdatedDate] = useState([])
  const isLeagueTable = props?.prevNextList === 'LeagueTableList' ? true : false;

  useEffect(() => {
    if (props?.prevNextList === "myLeadsUpdates" && !myleads?.isListView) {
      setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.updatesSearchList?.length > 0 ? myleads?.updatesSearchList : [], props?.prevNextList))
    } else if (props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label === "last viewed" && !myleads?.isListView) {
      setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.searchList?.length > 0 ? myleads?.searchList : [], props?.prevNextList))
    } else {
      setMyLeadsListUpdatedDate([])
    }
  }, [myleads?.searchList, myleads?.isListView, myleads?.updatesSearchList])

  return (
    <>
      {
        props?.prevNextList === 'searchList' && !isLeagueTable || props?.prevNextList === 'selectedSearchList' && !isLeagueTable ?
          props.entityFlag ? search && search.listOfSelectedObjectIds && search.listOfSelectedObjectIds.length !== 0 ? (
            search && search.listOfSelectedObjectIds.map((company, index) => (
              <li className="list-group-item" id={company.company_id}>
                <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
              </li>
            ))
          ) : <CommonPlaceholder /> :
            search?.clusterList?.leadsFlags?.length !== 0 && !isLeagueTable ? (
              search.clusterList.leadsFlags.map((company, index) => (
                <li className="list-group-item" id={company.company_id}>
                  <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} isHierarchy={true} />
                </li>
              ))
            ) :
              search && search.searchList && search.searchList.length !== 0 && !isLeagueTable ?
                (
                  search && search.searchList.map((company, index) => (
                    <li className="list-group-item" id={company.company_id}>
                      <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                    </li>
                  ))
                ) : <CommonPlaceholder /> :
          search?.clusterList?.leadsFlags?.length !== 0 && !isLeagueTable ? (
            search.clusterList.leadsFlags.map((company, index) => (
              <li className="list-group-item" id={company.company_id}>
                <CompanyRow company={company} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
              </li>
            ))
          ) :
            props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" && !isLeagueTable ?
              props.entityFlag ? justask && justask.listOfSelectedObjectIds && justask.listOfSelectedObjectIds.length !== 0 ? (
                justask && justask.listOfSelectedObjectIds.map((company, index) => (
                  <li className="list-group-item" id={company.company_id}>
                    <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                  </li>
                ))
              ) : <CommonPlaceholder /> :
                justask?.clusterList?.leadsFlags?.length !== 0 && !isLeagueTable ? (
                  justask.clusterList.leadsFlags.map((company, index) => (
                    <li className="list-group-item" id={company.company_id}>
                      <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} isHierarchy={true} />
                    </li>
                  ))
                ) :
                  justask && justask.searchList && justask.searchList.length !== 0 && !isLeagueTable ?
                    (
                      justask && justask.searchList.map((company, index) => (
                        <li className="list-group-item" id={company.company_id}>
                          <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                        </li>
                      ))
                    ) :
                    <CommonPlaceholder /> :
              justask?.clusterList?.leadsFlags?.length !== 0 && !isLeagueTable ? (
                justask.clusterList.leadsFlags.map((company, index) => (
                  <li className="list-group-item" id={company.company_id}>
                    <CompanyRow company={company} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                  </li>
                ))
              ) :

                myleads?.searchList?.length !== 0 && myLeadsListUpdatedDate?.length === 0 && !isLeagueTable && props?.prevNextList !== "myLeadsUpdates" ?
                  (
                    myleads?.searchList?.map((company, index) => (
                      <li className="list-group-item" id={company.company_id}>
                        <CompanyRow company={company} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                      </li>
                    ))
                  ) : myleads?.updatesSearchList?.length !== 0 && props?.prevNextList === "myLeadsUpdates" && myLeadsListUpdatedDate.length === 0 ?
                    (
                      myleads?.updatesSearchList?.map((company, index) => (
                        <li className="list-group-item" id={company.company_id}>
                          <CompanyRow company={company} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                        </li>
                      ))
                    ) :
                    myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsUpdates' && !myleads?.isListView && !isLeagueTable || myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label === "last viewed" && !isLeagueTable ?
                      (
                        <>
                          {Object.entries(myLeadsListUpdatedDate).map(([key, array]) => (
                            <div className="note-listing pb-2" key={key}>
                              <span className="update-date">{key}</span>
                              {array.map((company, index) => (
                                <li className="list-group-item" id={company.company_id}>
                                  <CompanyRow company={company} prevNextList={props.prevNextList} activeTab={props.activeTab} isShowRelatedEntitiesMode={true} index={index} myHoverList={props.myHoverList} />
                                </li>
                              ))}
                            </div>
                          ))}
                        </>
                      ) : isLeagueTable && leagueTableState?.searchList?.length !== 0 ? (
                        leagueTableState?.searchList?.map((company, index) => (
                          <li className="list-group-item" id={company.company_id}>
                            <CompanyRow company={company} prevNextList={props.prevNextList} isShowRelatedEntitiesMode={false} index={index} myHoverList={props.myHoverList} />
                          </li>
                        ))
                      ) : <CommonPlaceholder />

      }
    </>
  )
}

export default CompanyList