import React, { useEffect, useState } from "react";
import { Media, Row, Col, Button } from "reactstrap";
import SearchInput, { createFilter } from "react-search-input";
import {  useSelector } from "react-redux";
import _ from "lodash";
import ColoredScrollbars from "../common/js/coloredScrollbars";

function Users(props) {
	const user = useSelector((state) => state.user);
	const [userList, setUserList] = useState([user?.allRecipientDetails]);
	const [sortedUserList, setSortedUserList] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(()=>{
		setUserList([user?.allRecipientDetails])
	},[user?.allRecipientDetails])

	const handleUserListClose = (event) => {
		event.preventDefault();
		setSearchTerm("");
	};

	useEffect(()=>{
	setSearchTerm("")
	},[props?.activeTab])

	const searchUpdated= (term)=> {
		setSearchTerm(term)
	  }
	const createReceipientUserList = (recipientsUserList) => {
		let options = [];
		const KEYS_TO_FILTERS = [
			"first_name",
			"last_name",
			"username",
			"teams.name",
		];
		_.map(recipientsUserList, (item) => {
			_.map(item, (elem) => {
				options.push(elem);
			});
		});
		if(searchTerm!=""){
			options = options.filter(createFilter(searchTerm, KEYS_TO_FILTERS));
		}
		options.sort((user1, user2) => {
			if (!user1.first_name) {
				return 1;
			}
			if (!user2.first_name) {
				return -1;
			}
			return user1.first_name.toUpperCase() < user2.first_name.toUpperCase()
				? -1
				: 1;
		});
		return options;
	};

	useEffect(() => {
		if (props?.activeTab === "users") {
			let sortedUsersList = createReceipientUserList(userList);
			setSortedUserList(sortedUsersList);
		}
	}, [props?.activeTab,searchTerm]);

	
	return (
		<div className="content-wrapper">
			<div className="team-user-tab">
				<div className="user-team-search-panel media">
					<div className="media-body">
						<h4 className="media-heading">
							<div className="p-0 textfield-wrapper col col-sm-9 col-md-6 col-lg-3">
								<SearchInput
									placeholder="Find user"
									className="label-search search-user-textbox"
									type="text"
									onChange={searchUpdated}
									value={searchTerm}
								/>
								<Button
									className={`clear-textfield ${searchTerm ? "display-block" : "display-none"
										}`}
									onClick={handleUserListClose}
									tabIndex="-1"
								>
									x
								</Button>
							</div>
						</h4>
					</div>
				</div>
				<div className="list-header row">
					<div className="user-names col-12 col-sm-12 col-md-4">Name</div>
					<div class="user-contacts col-12 col-sm-12 col-md-2">
						Contact number
					</div>
					<div class="user-licenses col-12 col-sm-12 col-md-3">Licence</div>
					<div class="user-teamnames col-12 col-sm-12 col-md-3">Team</div>
				</div>

				<div className="list-wrapper mt-2 users-list-wrapper">
					<ColoredScrollbars className="details-panel-scrollbar">
						<div className="team-user-list-container">
							{sortedUserList && sortedUserList.length > 0 ?
								sortedUserList.map((user) => {
									return (
										<div className="team-user-block">
											<div className="m-0 row">
												<div className="user-names col-12 col-sm-12 col-md-4">
													<span class="user-icon">{user.initials}</span>
													<div className="user-detail-wrapper">
														<span className="fl-name">
															{user.first_name + "" + user.last_name}
														</span>
														<span className="user-name">{user.username}</span>
														<a
															href="mailto:aaradhya.acharya@mailinator.com"
															class="email-address"
														>
															{" "}
															{user.email}{" "}
														</a>
													</div>
												</div>
												<div className="user-contacts col-12 col-sm-12 col-md-2">
													{user?.mobile && user?.mobile != "-" ? (
														<span className="user-mobile">{user?.mobile}</span>
													) : (
														""
													)}
													{user?.phone && user?.phone != "-" ? (
														<span className="user-landline">{user?.phone}</span>
													) : (
														""
													)}
												</div>
												<div className="user-licenses col-12 col-sm-12 col-md-3">
													{user?.license?.length > 0 ? (
														<span className="user-license">
															{user.license.map(function (elem, index) {
																return (
																	<span key={index}>{elem.license_name}</span>
																);
															})}
														</span>
													) : (
														""
													)}
												</div>
												<div className="user-teamnames col-12 col-sm-12 col-md-3">
													{user?.teams?.length > 0 ? (
														<span className="user-team-name">
															{user.teams.map(function (team, index) {
																return <span key={index}>{team.name}</span>;
															})}{" "}
														</span>
													) : (
														""
													)}
												</div>
											</div>
										</div>
									)

								})
								:
								("No records found")}

						</div>
					</ColoredScrollbars>
				</div>
			</div>
		</div>
	);
}
export default Users;
