import React, { useState, useEffect } from 'react'
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { fetchWorksWithCompanies, fetchWorksWithCompaniesProjects } from '../../../apiclient';
import CompanyList from './companyList';
import ColoredScrollbars from '../../../../common/js/coloredScrollbars';
import { updateListSelection, updateShowMoreOffsetLimit } from '../../../redux/reducer';

const WorksWithCompanies = () => {
    const [activeTab, setActiveTab] = useState(0);
    const project = useSelector((state) => state.project);
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const [companyList, setCompanyList] = useState(project && project.worksWithCompanies && project.worksWithCompanies.companies)
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    useEffect(() => {
        if (project ?.worksWithCompaniesProjects ?.length > 0) {
            setActiveTab(project.worksWithCompaniesProjects[0].project_id)
            let worksWithCompaniesPayload = {
                "objectId": location.split('/')[4],
                "ptno": project.worksWithCompaniesProjects[0].project_id,
                "offset": 0,
                "records": 50,
                "sort": '-max_project_last_published'
            }
            fetchWorksWithCompanies(worksWithCompaniesPayload, dispatch)
        }
    }, [project && project.worksWithCompaniesProjects])


    useEffect(() => {
        setCompanyList(project && project.worksWithCompanies && project.worksWithCompanies.companies)

    }, [project && project.worksWithCompanies, activeTab])

    const toggle = (tab) => {
        fetchWorksWithCompaniesFunction(tab, true)
        if (parseInt(activeTab) !== tab) {
            setActiveTab(tab)
        }
        dispatch(updateListSelection({ count: 0, entityType: 'worksWithCompanies' }))
    }


    const fetchWorksWithCompaniesFunction = (id, isToggle) => {
        if (project ?.worksWithCompaniesProjects ?.length > 0) {
            let worksWithCompaniesPayload = {
                "objectId": location.split('/')[4],
                "ptno": id,
                "offset": 0,
                "records": 50,
                "sort": '-max_project_last_published'
            }
            fetchWorksWithCompanies(worksWithCompaniesPayload, dispatch)
        }
    }

    const handleShowMore = () => {
        let newOffset = project ?.showMoreOffsetLimit ?.offset + project ?.showMoreOffsetLimit ?.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: project ?.showMoreOffsetLimit ?.records }));
        setShowMoreClicked(true)
    }
    useEffect(() => {
        if (showMoreClicked) {
            let worksWithCompaniesProjectsPayload = {
                "objectId": location.split('/')[4],
                "offset": project ?.showMoreOffsetLimit ?.offset,
                "records": project ?.showMoreOffsetLimit ?.records,
            }
            fetchWorksWithCompaniesProjects(worksWithCompaniesProjectsPayload, dispatch)
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);



    return (
        <div className="box-view">
            <Row className="m-0">
                <Col sm="12" md="12" lg="3" className="p-0 nav-col">
                    <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select project to view companies</p>
                    </div>
                    <div className="height-400">
                        <ColoredScrollbars className="scroll-wrapper">
                            {project ?.worksWithCompaniesProjects ?.length > 0 && project.worksWithCompaniesProjects.map((project, index) => (
                                <Nav tabs vertical>
                                    <NavItem key={index}>
                                        <NavLink
                                            className={classnames({ active: parseInt(activeTab) === project.project_id })}
                                            onClick={() => { toggle(project.project_id) }}
                                        >
                                            {project && project.project_title}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            ))
                            }
                            {
                                project ?.worksWithCompaniesProjects ?.length !== project ?.worksWithCompaniesProjectsCount ? (
                                    <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                        <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                    </div>
                                ) : ''
                            }
                        </ColoredScrollbars>
                    </div>
                </Col>

                <Col sm="12" md="12" lg="9" className="p-0 detail-col">
                    <CompanyList tab={activeTab} companyList={companyList} />
                </Col>
            </Row>

        </div>
    )
}

export default WorksWithCompanies;