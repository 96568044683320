import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Input, Label, Row } from 'reactstrap'
import { TOURPROJECTLISTVIEW } from '../../../utils/tour'
import Icon from '../../common/js/icon'
import MapView from '../../map'
import { fetchRolesForCompanyId, fetchSearchListCount } from './../search/apiclient'
import { updateRemoveFilterToggleJA } from './../search/redux/reducer'
import { updateIsListView } from './../search/redux/searchPeristReducer'
import SearchList from './../search/searchlist'
import SearchListResult from './../search/searchListResult'
import Sort from './searchSort'
import "../search/searchPanel.scss"
import '../search/sort.scss'
import SearchSelector from './selectorDropdown'
import { preProcessorQuery } from './filters/preProcessorQuery'
import ProjectHierarchy from './hierarchyList/projectHierarchy'
import CompanyHierarchy from './hierarchyList/companyHierarchy'
import ContactHierarchy from './hierarchyList/contactHierarchy'

const SearchFormIndex = () => {
  const searchPersist = useSelector((state) => state.justAskPersist)
  const search = useSelector((state) => state.justAsk)
  const project = useSelector((state) => state.project)
  const filter = useSelector((state) => state.justAskFilter)
  const dispatch = useDispatch()
  const [listMapToggle, setListMapToggle] = useState(searchPersist.isListView)
  const [entityFlag, setEntityFlag] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const settings = useSelector((state) => state.settings)
  const [listName, setListName] = useState(project.isProjectSelected ? "jAskGroupedCompanyProjects" : 'jAskSearchList')


  useEffect(() => {
    // To update document title
    document.title = 'Barbour ABI'
  }, [])

  useEffect(() => {
    if (search?.listOfSelectedObjectIds?.length > 0
      || search?.fetchRolesForProjects?.roleGroups?.length > 0
      || search?.projectsForCompanyId?.length > 0 || search?.peopleProjectsList?.length > 0
      || search?.groupCompanyProjects?.length > 0) {
      setEntityFlag(true)
      setPrevNextList()
    }
  }, [search.listOfSelectedObjectIds, search.fetchRolesForProjects, search.projectsForCompanyId, search.peopleProjectsList, search.groupCompanyProjects])


  const handleListView = () => {
    setListMapToggle(!listMapToggle)
    dispatch(updateIsListView(!listMapToggle))
  }

  useEffect(() => {
    project.isProjectSelected ? setEntityFlag(true) : setEntityFlag(false)
  }, [project.isProjectSelected, search.groupCompanyProjects])

  const handleToggleChange = () => {
    setButtonClicked(true)
    dispatch(updateRemoveFilterToggleJA(!search.removeFilterToggle))
  }

  useEffect(() => {
    if (buttonClicked) {
      if (search.showCompanyHierarchy) {
        fetchRolesForCompanyId(search.objectId, search, searchPersist, dispatch)
      } else {
        var formValues = preProcessorQuery('', filter.filtersState, search?.showSelectedProjects ? "project" : searchPersist.appliedContext, search, searchPersist)
        fetchSearchListCount(formValues, dispatch, search, settings, searchPersist.query);
      }
      setButtonClicked(false)
    }
  }, [buttonClicked])

  useEffect(() => {
    if (search?.listOfSelectedObjectIds?.length > 0
      || search?.fetchRolesForProjects?.roleGroups?.length > 0
      || search?.projectsForCompanyId?.length > 0 || search?.peopleProjectsList?.length > 0
      || search?.groupCompanyProjects?.length > 0) {
      setEntityFlag(true)
    }
  }, [search.backObjectId])

  useEffect(() => {
    setPrevNextList()
  }, [search.hierarchyBack, search.showProjectHierarchy, search.showCompanyHierarchy])

  useEffect(() => {
    setListMapToggle(searchPersist.isListView)
  }, [searchPersist.isListView])

  const setPrevNextList = () => {
    setListName(search?.listOfSelectedObjectIds?.length > 0 ? 'selectedjAskSearchList'
      : search.navigationBack === 'relationClick' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ? 'projectHierarchicalSearchListJA'
        : search?.projectsForCompanyId?.length > 0 ? 'companyHierarchicalSearchListJA'
          : project.isProjectSelected ? "jAskGroupedCompanyProjects" : 'jAskSearchList')
  }

  const getSearchListResult = () => {
    return (<SearchListResult
      currentOffset={0}
      type={project.isProjectSelected ? 'project' : searchPersist.appliedContext}
      isListMapButtonFlag={listMapToggle}
      prevNextList={project.isProjectSelected ? "jAskGroupedCompanyProjects" : 'jAskSearchList'}
    />)
  }

  return (
    <div className="sidebar-mini sidebar-collapse h-100">
      <Row className='h-100'>
        <div className="main-container">
          <div className="search-list-content">
            <div className={`search-result ${searchPersist.isListView ? 'list-view-panel' : ''} `}>
              <div className="active page-title-bar">
                <div className='top-5'>
                  <div className="only-listview d-flex justify-content-between me-3">
                    <SearchSelector />
                    <div className="d-flex justify-content-end">
                      {
                        (searchPersist.appliedContext === 'company' && search.showSelectedProjects) || (searchPersist.appliedContext === 'company' && search.showCompanyHierarchy) ? (
                          <div className="switch-panel-project-tab mt-2 me-3">
                            <div className="switch-project-tab d-flex align-items-center">
                              <span className="me-2">Remove filters</span>
                              <Input id="switch-apply-filter" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                checked={search.removeFilterToggle}
                                onClick={handleToggleChange}
                              />
                              <Label for="switch-apply-filter" className="mb-0"></Label>
                            </div>
                          </div>
                        ) : ''
                      }
                      <div className="maplist-container me-2">
                        <button onClick={handleListView} className={`listview outline-btn ${searchPersist.appliedContext === 'people' ? 'disabled' : ''}`} disabled={searchPersist.appliedContext === 'people'} data-intro={(searchPersist.appliedContext != "people" && !filter.isFilterActive) ? TOURPROJECTLISTVIEW.listmapview.intro : null} data-position={TOURPROJECTLISTVIEW.listmapview.position} data-class={TOURPROJECTLISTVIEW.listmapview.class}>
                          {listMapToggle ?
                            <span><Icon color="#ED1C24" size={20} icon="icon-gen-map" /> Map view </span> :
                            <span><Icon color="#ED1C24" size={20} icon="icon-gen-list" /> List view </span>}
                        </button>
                      </div>
                      <Sort />
                    </div>
                  </div>
                </div>
              </div>
              <Row className="me-0 h-100">
                <Col className='h-100 pe-0'>
                  {
                    searchPersist.isListView ?
                      entityFlag ?  // check nested and hierarchical 
                        ((search.showSelectedCompanies || search.showSelectedProjects) && search?.listOfSelectedObjectIds?.length > 0) ?
                          <SearchList entityFlag={entityFlag} prevNextList={project.isProjectSelected ? "jAskGroupedCompanyProjects" : 'selectedjAskSearchList'} /> :
                          (
                            <>
                              {(search.showProjectHierarchy && searchPersist.appliedContext === 'project') || (search.showProjectHierarchy && searchPersist.appliedContext === 'company') && search?.fetchRolesForProjects?.roleGroups?.length > 0 ?
                                <ProjectHierarchy listMapToggle={listMapToggle} type={searchPersist.appliedContext} prevNextList="projectHierarchicalSearchListJA" /> :
                                search.showCompanyHierarchy && searchPersist.appliedContext === "company" && search?.projectsForCompanyId?.length > 0 ?
                                  <CompanyHierarchy type={searchPersist.appliedContext} prevNextList="companyHierarchicalSearchListJA" /> :
                                  search.showCompanyHierarchy && search?.peopleProjectsList?.length > 0 ?
                                    <ContactHierarchy listMapToggle={listMapToggle} type={searchPersist.appliedContext} prevNextList="contactHierarchicalSearchList" />
                                    :
                                    getSearchListResult()}
                            </>
                          ) :
                        getSearchListResult() : <MapView prevNextList={listName} entityFlag={entityFlag} />
                  }
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default SearchFormIndex