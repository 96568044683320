import React from 'react'
import ContactRow from './contactRow'
import { useSelector } from 'react-redux';
import CommonPlaceholder from '../../details/framework/commonPlaceholder';

const Contactlist = (props) => {
  const search = useSelector((state) => state.search)
  return (
    <>
      {
        search && search.searchList?.length !== 0 ?
          (
            search && search.searchList?.map((contact, index) => (
                <ContactRow contact={contact} index={index} prevNextList={props.prevNextList} />
            ))
          ) :
          <CommonPlaceholder />
      }
    </>
  )
}

export default Contactlist