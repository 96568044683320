import React, { useState, useRef, useEffect } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row } from 'reactstrap';
import CompanyHierarchy from '../search/hierarchyList/companyHierarchy';
import { useDispatch, useSelector } from 'react-redux';
import SearchListResult from '../search/searchListResult';
import SearchList from '../search/searchlist'
import SearchSelector from '../details/list/searchSelector';

const LeagueTable = (props) => {

    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [entityFlag, setEntityFlag] = useState(false)
    const project = useSelector((state) => state.project)

    const getSearchListResult = () => {
        return (<SearchListResult
            currentOffset={0}
            type={'company'}
            prevNextList='LeagueTableList'
            isListMapButtonFlag={searchPersist.isListView}
        />)
    }

    return (
        <div>
            <Row>
                <div className={`search-result list-view-panel`}>
                    <div className={`result-wrapper`}>
                        {
                            search.showCompanyHierarchy ?
                                <CompanyHierarchy type={'company'} prevNextList="companyHierarchicalLeagueTableList" /> :
                                getSearchListResult()
                        }

                    </div>
                </div>
            </Row>
        </div>
    )

}

export default LeagueTable;