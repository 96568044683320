import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ColoredScrollbars from '../../common/js/coloredScrollbars'
import CompanyRow from '../../list/companyList/companyRow'
import ProjectRow from '../../list/projectList/projectRow'
import { renderListHeaderForAllObject } from '../utils'
import { updateProjectHierarchicalRoles } from '../redux/reducer';
import { setDataForCompanyDetails } from '../../details/postprocessing'
import { MYLEADS_PREVNEXT_HIERARCHY_LIST } from '../../../utils/settings'

const ProjectHierarchy = (props) => {
    const search = useSelector((state) => state.search)
    const myleads = useSelector((state) => state.myleads)
    const project = useSelector((state) => state.project)
    const searchPersist = useSelector((state) => state.searchPersist)
    let roleList = []
    let ifMyleadsExist = MYLEADS_PREVNEXT_HIERARCHY_LIST.includes(props.prevNextList) ? true : false

    let dispatch = useDispatch()

    useEffect(() => {
        let roles = []
        if (search?.fetchRolesForProjects?.roleGroups?.length > 0) {
            search.fetchRolesForProjects.roleGroups.map((roleGroup) => {
                if (roleGroup?.roles?.length > 0) {
                    roleGroup.roles.map((role) => {
                        if (role?.companies?.length > 0) {
                            role.companies.map(company => {
                                let payload = _.clone(company)
                                payload = setDataForCompanyDetails(payload)
                                roles.push(payload)
                            })
                        }
                    })
                }
            })
        }
        dispatch(updateProjectHierarchicalRoles(roles))
    }, [search?.fetchRolesForProjects])

    return (
        <div className={`search-list-content hierarchy-row ${(search && search.searchTextInformation && search.searchTextInformation[searchPersist.appliedContext]?.length !== 0) ? 'with-project-info' : ''}`}>
            <ColoredScrollbars>
                <div className='result-list-container content-pane'>
                    <div className={`listviewtable-block bggray lr-spaceHierachy leftright-bothspace ${!searchPersist.isListView || ifMyleadsExist && !myleads.isListView ? 'd-none' : ""}`}>
                        <div className="list-table">
                            <div className="list-header d-flex">
                                {renderListHeaderForAllObject(props.type, searchPersist)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`result-list-container content-panel ${ifMyleadsExist ? myleads.isListView ? 'nestedListView' : '' : searchPersist.isListView ? 'nestedListView' : ''}`}>
                    <div className="result-list-wrapper pt-0">
                        <ProjectRow project={search && search.projectRowData} prevNextList={props.prevNextList} isHierarchy={true} index={0} myHoverList={props.myHoverList} />
                        <div className={`listviewtable-block bggray lr-spaceHierachy ${!searchPersist.isListView || !myleads.isListView && ifMyleadsExist ? 'd-none' : ""}`}>
                            <div className="list-table">
                                <div className="list-header d-flex">
                                    <div className="list-col align-self-center list-name w-25"><b>Name</b></div>
                                    <div className="list-col align-self-center list-name w-5"><b>&nbsp;</b></div>
                                    <div className="list-col align-self-center list-client w-25"><b>Role</b></div>
                                    <div className="list-col align-self-center list-value w-10"><b>Phone</b></div>
                                    <div className="list-col align-self-center list-planing w-20"><b>Website</b></div>
                                    <div className="list-col align-self-center list-location w-10"><b>{searchPersist.isListView || myleads.isListView ? 'Published' : 'Updated'}</b></div>
                                </div>
                            </div>
                        </div>
                        {
                            search && search.fetchRolesForProjects && search.fetchRolesForProjects.roleGroups && search.fetchRolesForProjects.roleGroups.length > 0 ? (
                                search.fetchRolesForProjects.roleGroups.map((role, index) => {
                                    return (
                                        <div className={`${ifMyleadsExist ? myleads.isListView ? 'related-Company-list' : 'related-Company' : searchPersist.isListView ? 'related-Company-list' : 'related-Company'}`}>
                                            <a className="company-group-name"><span>{role.groupName}</span></a>
                                            <div>
                                                {role && role.roles && role.roles.length > 0 &&
                                                    role.roles.map(function (role, index) {
                                                        return (
                                                            <div>
                                                                <div className={`pro-related-company ${ifMyleadsExist ? myleads.isListView ? 'nestedListInside' : '' : searchPersist.isListView ? 'nestedListInside' : ''}`}>
                                                                    {role && role.companies && role.companies.length > 0 &&
                                                                        role.companies.map(function (company, index1) {
                                                                            roleList.push(company.company_id.toString() + role.roleNo + index1)
                                                                            return (
                                                                                <CompanyRow company={company} prevNextList={props.prevNextList} isHierarchy={true} ptno={project.detailsPage && project.detailsPage.objectId} roleNo={role.roleNo} roleName={role.roleName} index={parseInt(_.findKey(roleList, function (v) { return v === (company.company_id.toString() + role.roleNo + index1); }))} myHoverList={props.myHoverList} />
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    )
                                })) : (<div className="related-Company"><a className="company-group-name"><span>No Roles Available</span></a></div>)
                        }

                    </div>
                </div>
            </ColoredScrollbars>
        </div>
    )
}

export default ProjectHierarchy