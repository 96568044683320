import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ColoredScrollbars from '../../common/js/coloredScrollbars'

import CompanyRow from '../../list/companyList/companyRow'
import ProjectRow from '../../list/projectList/projectRow'
import { fetchCompanyDetails, fetchRolesForCompanyId } from '../apiclient'
import { updateOffsetLimitValue } from '../redux/reducer'
import { renderListHeader, renderListHeaderForAllObject, renderListHeaderForLeagueTable } from '../utils'

const CompanyHierarchy = (props) => {
  const search = useSelector((state) => state.search)
  const searchPersist = useSelector((state) => state.searchPersist)
  const dispatch = useDispatch()
  const [buttonClicked, setButtonClicked] = useState(false)
  let isLeagueTable = props.prevNextList === 'companyHierarchicalLeagueTableList'

  useEffect(() => {
    if (search && search.objectId) {
      fetchCompanyDetails(search.objectId, dispatch);
    }
  }, [search?.objectId]);


  useEffect(() => {
    if (buttonClicked) {
      fetchRolesForCompanyId(search.objectId, search, searchPersist, dispatch)
      setButtonClicked(false)
    }
  }, [buttonClicked]);

  const fetchMoreProjects = () => {
    setButtonClicked(true)
    let newOffset = search.offsetLimitValue.offset + search.offsetLimitValue.records;
    dispatch(updateOffsetLimitValue({ offset: newOffset, records: search.offsetLimitValue.records }));
    // let newOffset = filter.filtersState[type].offset + filter.filtersState[type].records;
    // dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[type].records, type: type }));
  }

  return (
    <div className={`forNestedprojects hierarchy-row ${(search && search.searchTextInformation && search.searchTextInformation[searchPersist.appliedContext]?.length !== 0) ? 'with-project-info' : ''}`}>
      <ColoredScrollbars>
        <div className={`result-list-container content-panel ${isLeagueTable ? 'nestedListView' : searchPersist.isListView ? 'nestedListView' : ''}`}>
          <div className="result-list-wrapper">
            <div className={`listviewtable-block bggray lr-spaceHierachy ${isLeagueTable ? '' : !searchPersist.isListView ? 'd-none' : ""}`}>
              <div className="list-table">
                <div className="list-header d-flex">
                  {isLeagueTable ? renderListHeaderForLeagueTable(props.type, searchPersist)
                    :
                    renderListHeaderForAllObject(props.type, searchPersist)}
                </div>
              </div>
            </div>
            <CompanyRow company={search && search?.companyDetails} prevNextList={props.prevNextList} isHierarchy={true} index={0} myHoverList={props.myHoverList} />
            <div className={`listviewtable-block bggray lr-spaceHierachy ${isLeagueTable ? '' : !searchPersist.isListView ? 'd-none' : ""}`}>
              <div className="list-table">
                <div className="list-header d-flex">
                  {renderListHeader(props.type, searchPersist, false, search)}
                </div>
              </div>
            </div>
            {
              search && search.projectsForCompanyId && search.projectsForCompanyId.length > 0 ?
                <div className={isLeagueTable ? '' : searchPersist.isListView ? "" : "related-projects"} >
                  {!isLeagueTable && !searchPersist.isListView ? <a className="project-group-name"><span>{"Projects"}</span></a> : ''}
                  {search && search.projectsForCompanyId && search.projectsForCompanyId.map((proj, index) => (
                    <ProjectRow project={proj} index={index} isHierarchy={true} prevNextList={props.prevNextList} myHoverList={props.myHoverList} />
                  ))}
                </div> : ('')
            }
          </div>

          {search?.isShowMoreActiveCompanyProjects ? (<div className={`show d-flex justify-content-center pt-3 pb-3 showmore-result`}>
            <button className="btn btn-block btn-primary" onClick={fetchMoreProjects}>Show more</button>
          </div>) : (<span></span>)}
        </div>
      </ColoredScrollbars>
    </div>
  )
}

export default CompanyHierarchy