import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    eventList: [],
    selectedEvents: [],
    calendarEventListCount: 0,
    selectedStatus: null,
    selectedType: null,
    selectedSortOrder: null,
    isRefine: false,
    offsetLimitValue: {
        offset: 0,
        records: 50
    },
}

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        updateEventList: (state, action) => {
            state.eventList = action.payload;
            return state
        },
        addSelectedEvent: (state, action) => {
            state.selectedEvents.push(action.payload);
        },
        removeSelectedEvent: (state, action) => {
            const eventIdToRemove = action.payload;
            if (state?.selectedEvents?.includes(eventIdToRemove)) {
                const index = state?.selectedEvents?.indexOf(eventIdToRemove);
                if (index !== -1) {
                    state?.selectedEvents?.splice(index, 1);
                }
            }
        },
        resetSelectedEvents: (state, action) => {
            state.selectedEvents = action.payload;
            return state
        },
        updateCalendarEventListCount: (state, action) => {
            state.calendarEventListCount = action.payload;
            return state
        },
        updateCalendarOffsetLimitValue: (state, action) => {
            state.offsetLimitValue = action.payload
            return state
        },
        updateShowMoreEventList: (state, action) => {
            state.eventList = state.eventList.concat(action.payload)
            return state
        },
        updateSelectedStatus: (state, action) => {
            state.selectedStatus = action.payload;
            return state
        },
        updateSelectedType: (state, action) => {
            state.selectedType = action.payload;
            return state
        },
        updateSelectedSortOrder: (state, action) => {
            state.selectedSortOrder = action.payload;
            return state
        },
        resetDropdown: (state, action) => {
            state.selectedSortOrder = action.payload;
            state.selectedStatus = action.payload;
            state.selectedType = action.payload;
            return state
        },
        updateIsRefine: (state, action) => {
            state.isRefine = action.payload;
            return state
        },
    }
})

export const { updateEventList, addSelectedEvent, removeSelectedEvent, resetSelectedEvents, updateCalendarEventListCount, updateCalendarOffsetLimitValue, updateShowMoreEventList, updateSelectedStatus, updateSelectedType, updateSelectedSortOrder, resetDropdown, updateIsRefine } = calendarSlice.actions

export const calendarReducer = calendarSlice.reducer