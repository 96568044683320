import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LeagueHeader from './leagueHeader';
import LeagueTable from './leagueTable';
import "./leaguetable.scss"
import { fetchSaveSearchResults } from '../savedsearch/apiclient';

const LeaguaTableMain = (props) => {
    const leagueTableState = useSelector(state => state.leagueTable)
    const dispatch = useDispatch()

    useEffect(() => {
        fetchSaveSearchResults(dispatch, leagueTableState, true)
    }, [])

    return (
        <div className="league-table-page pt-0 main-container container">
            <LeagueHeader {...props} />
            <LeagueTable {...props} />
        </div>
    );
}

export default LeaguaTableMain;