import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'

const SubHeaderTabs = (props) => {
    const [activetab, setActiveTab] = useState('materials')

    const toggle = (tab) => {
        setActiveTab(tab)
        if (activetab !== tab) {
            setActiveTab(tab)
        }
        props.toggle(tab)
    }
    return (
        <Row className="">
            <Col className="p-0 settings-tab-wrapper"> 
                <a className={`${activetab === 'materials' ? 'active' : "" }`} onClick={() => { toggle('materials'); }}>
                    Materials
                </a> 
                <a className={`${activetab === 'categories' ? 'active' : "" }`} onClick={() => { toggle('categories'); }} >
                    Categories
                </a> 
            </Col>
        </Row>
    )
}

export default SubHeaderTabs