import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { Button, Input, Row, Col } from 'reactstrap';

import { getCompanyAddress, isValidText } from '../../../utils';
import { updateBackObjectId, updateHierarchyBack, updateIsSelectedToSpecificRow, updateObjectId, updateShowDetailPeople, updateShowHierarchy } from '../../search/redux/reducer'
import { fetchProjectsForPeople } from '../../search/apiclient'

function ContactRow(props) {
    const project = useSelector((state) => state.project)
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        let page = document.getElementsByTagName('body')[0];
        if (search.backObjectId && search.backObjectId !== undefined && search.backObjectId !== 0) {
            let elmnt = document.getElementById(search.backObjectId);
            if (elmnt && elmnt != undefined) {
                elmnt.scrollIntoView()
                page.classList.add('noscroll');
                dispatch(updateBackObjectId(undefined))
            }
            else {
                page.classList.remove('noscroll');
            }
            dispatch(updateHierarchyBack(false))
        }
    }, [search.backObjectId])

    function getFullName() {
        let fullName = '';
        if (props.contact.person_first_name) {
            fullName = `${props.contact.person_first_name}`
        }
        if (props.contact.person_last_name) {
            fullName = `${fullName} ${props.contact.person_last_name}`
        }

        return fullName
    }
    const handleContactHierarchy = (id) => {
        fetchProjectsForPeople(id, search, dispatch)
        dispatch(updateObjectId(id))
        dispatch(updateShowDetailPeople(true))
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: true }))
    }

    const handleDetails = (event, personId) => {
        event.preventDefault();
        let routingState = {
            prevNextList: props.prevNextList,
            showMoreResult: true,
            index: props.index,
            list: props.prevNextList === 'searchList' ? search?.searchList
                : props.prevNextList === 'contactHierarchicalSearchList' ? search.peopleDetails
                    : []
        }
        navigate(`/app/person/${personId}`, { state: routingState });
    }

    const handleCheckboxChange = (value) => {
        let payload = {
            id: value,
            isSelected: !props.contact.is_selected,
            filterType: 'people',
            isShowRelatedEntitiesMode: props?.isShowRelatedEntitiesMode,
            entityType: props?.prevNextList
        }
        dispatch(updateIsSelectedToSpecificRow(payload))
    }

    return (
        <li key={props.index} className="list-group-item" id={props.contact.person_id} >
            {props && props.isHierarchy ? ("") :
                <label className='container'>
                    <Input
                        value={props.contact.person_id}
                        id={props && props.contact.person_id}
                        type="checkbox"
                        onChange={() => handleCheckboxChange(props.contact.person_id)}
                        checked={props.contact.is_selected}
                    />
                    <span className="checkmark">

                    </span>
                </label>
            }
            <div className="people-container">
                {
                    search.showSelectedProjects && searchPersist.appliedContext === 'people' || props && props.isHierarchy ? '' :
                        <Button className="related-Company-btn" onClick={() => handleContactHierarchy(props.contact.person_id)}></Button>
                }
                <div className="linkWrapper">
                    <Link className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" onClick={(event) => { handleDetails(event, props && props.contact && props.contact.person_id) }} to={`/app/person/${props && props.contact && props.contact.person_id}`}>
                        <div className="listviewtable-block">
                            <div className="list-table">
                                <div className="list-tablerow d-flex">
                                    <div className={`list-col list-contact-name w-20`}>
                                        {(getFullName() != '') &&
                                            <span className="user-icon">{(getFullName().match(/\b\w/g).join('')).toUpperCase().slice(0, 2)}</span>
                                        }
                                        <h5 className="result-title-name mb-1">{getFullName()}</h5>
                                    </div>
                                    <div className={`list-col list-title w-15`}>
                                        <h5 className="result-title-name mb-1">{props.contact.person_job_title}</h5>
                                    </div>
                                    <div className={`list-col list-email w-15`}>
                                        <h5 className="result-title-name mb-1">{props.contact.person_email}</h5>
                                    </div>
                                    <div className={`list-col list-email w-10`}>
                                        {
                                            props.contact.person_phone || props.contact.person_mobile ?
                                                <h5 className="result-title-name mb-1">{props.contact.person_phone}
                                                    {
                                                        props.contact.person_phone_tps ?
                                                            (<span className="tps-badge">TPS</span>)
                                                            : ("")
                                                    }
                                                    <br />
                                                    {props.contact.person_mobile}
                                                    {
                                                        props.contact.person_mobile_tps ?
                                                            (<span className="tps-badge">TPS</span>)
                                                            : ("")
                                                    }
                                                </h5> :
                                                <h5 className="result-title-name mb-1">
                                                    {props.contact.company_phone
                                                    }
                                                    {
                                                        props.contact.company_phone_tps ?
                                                            (<span className="tps-badge">TPS</span>)
                                                            : ("")
                                                    }
                                                </h5>
                                        }
                                    </div>
                                    <div className={`list-col list-email w-20`}>
                                        <h5 className="result-title-name mb-1">{props.contact.company_name}</h5>
                                    </div>
                                    <div className={`list-col list-email w-20`}>
                                        {
                                            <Row className={`com-address-print`}>
                                                {isValidText(props.contact.company_address1)
                                                    || isValidText(props.contact.company_address2)
                                                    || isValidText(props.contact.company_address3)
                                                    || isValidText(props.contact.company_address4)
                                                    || isValidText(props.contact.company_postcode) ?
                                                    (<Col
                                                        sm={`${project.isPrint ? '12' : '12'}`}
                                                        md={`${project.isPrint ? '12' : '12'}`}
                                                        lg={`${isValidText(props.contact.company_phone) ? '12' : '12'}`}
                                                        className="col comp-address">
                                                        <div className="field-value">
                                                            {getCompanyAddress(props && props.contact)}
                                                        </div>
                                                    </Col>) : ('')}
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </li>
    );
}

export default ContactRow;