import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated: false,
    isMasqueradeUser: false,
    isRedirectedUser: false,
    onlyDeveloperLicenses: false,
    user: null,
    component: '',
    appDetails: {
        sectorName: 'Construction',
        sectorNo: 1,
        productName: 'Barbour ABI'
    },
    alert: {
        visible: false,
        message: "",
        type: "info",
        keyid: 0
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signIn(state, action) {
            state.user = action.payload
            state.isAuthenticated = true
        },
        signOut(state) {
            state.user = null
            state.isAuthenticated = false
        },
        setAppDetails(state, action) {
            state.appDetails = action.payload
        },
        setAlert(state, action) {
            state.alert = action.payload
        },
        resetAlert(state) {
            state.alert = initialState.alert
        },
        component(state, action) {
            state.component = action.payload
        },
        teamDetails: (state, action) => {
            state.teamListDetails = action.payload;
            return state
        },
        recipientDetails: (state, action) => {
            state.allRecipientDetails = action.payload;
            return state
        },
        shareInformationDetails: (state, action) => {
            state.shareInfoDetails = action.payload;
            return state
        },
        updateUniverseCount(state, action) {
            state.universeCount = action.payload;
        },
        updatePreferenceSetting(state, action) {
            state.preferences = action.payload
        },
        updateIsMasqueradeUser(state, action) {
            state.isMasqueradeUser = action.payload
        },
        updateIsRedirectedUser(state, action) {
            state.isRedirectedUser = action.payload
        },
        updateOnlyDeveloperLicenses(state, action) {
            state.onlyDeveloperLicenses = action.payload
        }
    }
})

export const { signIn, signOut, setAppDetails, setAlert, resetAlert, component, updateUniverseCount, updatePreferenceSetting,
    teamDetails, recipientDetails, shareInformationDetails, updateIsMasqueradeUser, updateIsRedirectedUser, updateOnlyDeveloperLicenses } = userSlice.actions

export const userReducer = userSlice.reducer