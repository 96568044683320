import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isTeamAlreadyExists:null,
    selectedTeamId:null
}

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    updateTeamAlreadyExists: (state, action) => {
      state.isTeamAlreadyExists = action.payload;
      return state
    },
    updateSelectedTeamId:(state,action)=>{
      state.selectedTeamId=action.payload
      return state;
    }
   
    
  }
})

export const { updateTeamAlreadyExists,updateSelectedTeamId} = teamsSlice.actions

export const teamsReducer = teamsSlice.reducer