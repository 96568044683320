import _ from 'lodash';
import getAxios from '../../utils/baseApi';
import { messageAlert, getActivitiesShareDetails } from '../../utils';
import { handleError } from '../../utils/errorHandler';
import { savedsearchResults, shareForAllEntity, shareAllDetails, isShowMoreActiveForSavedSearch, updateSavedSearchShowMoreList, updateSavedSearch, updateIsLoaderActive } from './redux/reducer';
import { updateLeagueSavedSearchList, updateSavedSearchList } from '../leaguetable/reducer';
import { updatedSelectedSavedSearch, updateLeagueTable, updateNotificationRedirectSaveSearch } from '../search/redux/searchPeristReducer';
import { initializeSavedSearchFilterState } from './tabs/utils';

var axios = getAxios()
export const fetchSaveSearchResults = (dispatch, state, isLeagueTable, searchPersist) => {
    axios.get(`/saved_searches?offset=${state?.offsetLimitValue?.offset ? state?.offsetLimitValue?.offset : '0'}&limit=${isLeagueTable ? '10000' : state?.offsetLimitValue?.records}${!isLeagueTable ? `&created_by_me=${state?.isSharedSaveSearchActive}` : ''}`).then((response) => {
        if (response.status === 200) {
            if (isLeagueTable) {
                dispatch(updateLeagueSavedSearchList(response?.data.saved_searches))
                if (!_.isEmpty(searchPersist) && searchPersist?.leagueTable?.selectedSavedSearch?.query !== null) {
                    const updateLeagueTableSavedSearch = response?.data.saved_searches?.find(search => search?.saved_search_name === searchPersist?.leagueTable?.selectedSavedSearch?.saved_search_name);
                    dispatch(updateLeagueTable({ dropdown: "selectedSavedSearch", value: updateLeagueTableSavedSearch }))
                }
            } else {
                if (response?.data?.saved_searches?.length < 21) {
                    updateResponse(dispatch, state, response, false);
                }
                else {
                    response.data.saved_searches.splice(-1);
                    updateResponse(dispatch, state, response, true);
                }
            }
        }
        else {
            dispatch(updateIsLoaderActive(false))
            dispatch(savedsearchResults({ saved_searches: [] }))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const editDefaultSavedSearch = (payload, id, dispatch, state) => {
    let jsonData = JSON.stringify(payload)
    axios.put(`/saved_searches/${id}`, jsonData).then((response) => {
        if (response.status === 201) {
            fetchSaveSearchResults(dispatch, state)
            const message = payload.is_default ? payload.saved_search_name + ' has been set as your default search.' : payload.saved_search_name + ' has been removed as your default search'
            messageAlert(dispatch, message, 'success')
            window.Intercom('trackEvent', 'Saved search default', { 'search_name': payload.saved_search_name })
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}
export const deleteSaveSearch = (id, deleteName, dispatch, state) => {
    axios.delete('/saved_searches/' + id)
        .then(function (response) {
            if (response.status === 200) {
                fetchSaveSearchResults(dispatch, state)
                const message = "Saved search " + deleteName + " deleted"
                messageAlert(dispatch, message, 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const addShare = (item, payload, dispatch, state, user, savedSearch) => {
    axios.put('/saved_searches/' + item.saved_search_id, payload).then((response) => {
        if (response.status === 201) {
            fetchSaveSearchResults(dispatch, savedSearch)
            fetchShareForObjectId(item.saved_search_id, dispatch, state, user)
            messageAlert(dispatch, 'This Saved Search has been shared successfully', 'success')
            window.Intercom('trackEvent', 'Share saved search', { 'search_name': item.saved_search_name })
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const deleteShare = (id, type, payload, dispatch, state, user, savedSearch) => {
    let jsonData = JSON.stringify(payload)
    axios.delete('/saved_searches/' + id, { data: jsonData }).then((response) => {
        if (response.status === 200) {
            fetchSaveSearchResults(dispatch, savedSearch)
            messageAlert(dispatch, 'Share deleted successfully', 'success')
            fetchShareForObjectId(id, type, dispatch, state, user)

        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchShareForObjectId = (id, dispatch, state) => {
    axios.get('/saved_searches/' + id + '/shares').then((response) => {
        if (response.status === 200 && response?.data?.shares?.length > 0) {
            let usersList = state && state.allRecipientDetails
            let teamList = state && state.teamListDetails;
            let sharedInfo = getActivitiesShareDetails(response.data.shares, teamList, usersList, state)
            dispatch(shareForAllEntity(response.data))
            dispatch(shareAllDetails({ sharedInfo: sharedInfo }))
        } else {
            dispatch(shareAllDetails({ sharedInfo: [] }))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}
export const updateFeedSaveSearch = (payload, dispatch, state) => {
    let jsonData = JSON.stringify(payload)
    axios.post('/feed', jsonData)
        .then(function (response) {
            if (response.status === 200) {
                let saved_searches = _.cloneDeep(state?.detailsPage);
                saved_searches = saved_searches.map(function (saved_search) {
                    if (parseInt(saved_search.saved_search_id) === parseInt(payload.saved_search_id)) {
                        if (saved_search?.feed?.id) {
                            saved_search.feed = {
                                'configuration': payload.schedule,
                                'id': saved_search.feed.id
                            }
                            dispatch(updateSavedSearch(saved_search))
                        } else {
                            axios.get('/saved_searches/' + saved_search.saved_search_id)
                                .then(function (single_response) {
                                    saved_search = {
                                        ...saved_search,
                                        feed: {
                                            'configuration': payload.schedule,
                                            'id': single_response.data.feed.id
                                        }
                                    };
                                    dispatch(updateSavedSearch(saved_search))
                                })
                                .catch(function (error) {
                                    handleError(error, dispatch)
                                });
                        }
                    }
                    return saved_search;
                })
                messageAlert(dispatch, response.data.message, 'success')
                let savedSearchName = _.filter(state.detailsPage, savesearch => savesearch.saved_search_id === payload.saved_search_id)?.[0]?.saved_search_name
                window.Intercom('trackEvent', 'Saved search alert', { 'search_name': savedSearchName })
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })

        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const deleteFeedSaveSearch = (feedId, dispatch, state) => {
    axios.delete('/feed/' + feedId)
        .then(function (response) {
            if (response.status === 200) {
                fetchSaveSearchResults(dispatch, state)
                messageAlert(dispatch, "feed deleted successfully", 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}


export const addNewSavedSearch = (payload, dispatch, state) => {
    let jsonData = JSON.stringify(payload)
    axios.post(`/saved_searches`, jsonData).then((response) => {
        if (response.status === 201) {
            fetchSaveSearchResults(dispatch, state)
            dispatch(updatedSelectedSavedSearch(payload))
            messageAlert(dispatch, `"${payload['saved_search_name']}" is saved successfully`, 'success')
            window.Intercom('trackEvent', 'Create saved search', {
                'search_name': payload.saved_search_name
            })
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const editSavedSearch = (payload, id, dispatch, state, searchPersist) => {
    let jsonData = JSON.stringify(payload)
    axios.put(`/saved_searches/${id}`, jsonData).then((response) => {
        if (response.status === 201) {
            fetchSaveSearchResults(dispatch, state)
            if (!_.isEmpty(searchPersist)) {
                fetchSaveSearchResults(dispatch, null, true, searchPersist)
            }
            messageAlert(dispatch, `"${payload['saved_search_name']}" is saved successfully`, 'success')
            window.Intercom('trackEvent', 'Create saved search', {
                'search_name': payload.saved_search_name
            })
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchSingleSavedSearch = (id, searchPersist, filter, dispatch, navigate) => {
    axios.get(`/saved_searches/${id}`).then((response) => {
        if (response.status === 200) {
            initializeSavedSearchFilterState(response.data, searchPersist, filter, dispatch)
            dispatch(updateNotificationRedirectSaveSearch(true))
            navigate("/app/list");
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

function updateResponse(dispatch, state, response, showMoreFlag) {
    dispatch(isShowMoreActiveForSavedSearch(showMoreFlag));
    dispatch(updateIsLoaderActive(false))
    if (state?.offsetLimitValue?.offset > 0) {
        dispatch(updateSavedSearchShowMoreList(response.data.saved_searches));
    }
    else {
        dispatch(savedsearchResults(response.data.saved_searches));
    }
}
