import React from 'react'
import { Button } from 'reactstrap'
import ColoredScrollbars from '../common/js/coloredScrollbars'

const ModalDetails = (props) => {

    return (
        <>
            <div className="label-container" >
                <div className="label-container-wrapper">
                    <div className="fixed-container">
                        <span>Fields & roles</span>
                        <Button className="export-label-close" onClick={props.handleCloseClick}>close</Button>
                    </div>
                    {
                        <div className="column-list-container">
                            <ColoredScrollbars className="details-panel-scrollbar">
                                {
                                    props.displayType === "Projects" ? (
                                        <div className="export-label-list">
                                            {
                                                props.fields && props.fields.hasOwnProperty('projects') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Project</li>
                                                        <ul>
                                                            {
                                                                props.fields.projects.map(function (projs, index) {
                                                                    return (<li key={index}>{projs.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                            {
                                                props.fields && props.fields.hasOwnProperty('companies') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Company</li>
                                                        <ul>
                                                            {
                                                                props.fields.companies.map(function (cmpns, index) {
                                                                    return (<li key={index}>{cmpns.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                            {
                                                props.fields && props.fields.hasOwnProperty('contacts') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Contact</li>
                                                        <ul>
                                                            {
                                                                props.fields.contacts.map(function (cntcs, index) {
                                                                    return (<li key={index}>{cntcs.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                        </div>
                                    ) : (
                                        <div className="export-label-list">
                                            {
                                                props.fields && props.fields.hasOwnProperty('companies') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Company</li>
                                                        <ul>
                                                            {
                                                                props.fields.companies.map(function (cmpns, index) {
                                                                    return (<li key={index}>{cmpns.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                            {
                                                props.fields && props.fields.hasOwnProperty('contacts') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Contact</li>
                                                        <ul>
                                                            {
                                                                props.fields.contacts.map(function (cntcs, index) {
                                                                    return (<li key={index}>{cntcs.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                            {
                                                props.fields && props.fields.hasOwnProperty('projects') ? (
                                                    <ul className="ul-list">
                                                        <li className="li-list">Project</li>
                                                        <ul>
                                                            {
                                                                props.fields.projects.map(function (projs, index) {
                                                                    return (<li key={index}>{projs.display_name}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </ul>
                                                ) : ('')
                                            }
                                        </div>
                                    )
                                }
                                {
                                    props.setting.excludeContactWithNoConfirmedName ? (
                                        <div className="export-label-list">
                                            <span>Exclude companies with no confirmed contact name</span>
                                        </div>) : ("")
                                }
                                {
                                    props.setting.excludeContactWithNoEmail ? (
                                        <div className="export-label-list">
                                            <span>Exclude contacts with no personal email address</span>
                                        </div>) : ("")
                                }
                                {
                                    props.setting.includeRoles && props.setting.includeRoles.length > 0 ? (
                                        <div className="export-label-list">
                                            <ul className="ul-list">
                                                <li className="li-list">Include roles</li>
                                                <ul>
                                                    {
                                                        props.rolesFilter.options.map(function (roles, index) {
                                                            if (props.setting.includeRoles.indexOf(parseInt(roles.value)) > -1) {
                                                                return (<li key={index}>{roles.name}</li>)
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </ul>
                                        </div>) : ("")
                                }
                                {
                                    props.setting.excludeContactWithNoEmail ? (
                                        <div className="export-label-list">
                                            <ul className="ul-list">
                                                <li className="li-list">Sort by</li>
                                                <ul>
                                                    <li >{props.setting.sortBy === "V" ? "Project value" : "Latest"}</li>
                                                </ul>
                                            </ul>
                                        </div>) : ("")
                                }
                            </ColoredScrollbars>
                        </div>
                    }
                </div>
            </div >
        </>
    )
}

export default ModalDetails