import React, { useState } from 'react'
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap'

const ArchiveModal = (props) => {
  return(
    <Modal isOpen={props.isOpen} id="archiveModal">
        <ModalHeader>
            {(props.isArchive ? "Archive " : "Unarchive") + (props.isSelectorController ? " Selected" : "") + (props.multiple ? " Projects" : " Project")}
        </ModalHeader>
        <div className="archive-patch">
            Do you want to {props.isArchive ? " archive " : " unarchive "} {props.multiple ? " these projects" : " this project"}?
        </div>
        <ModalFooter className="archive-footer">
            <Button id="primaryBtn" className="primary-btn btn-save-cancel" onClick={props.isArchive ? props.handleArchive : props.handleUnArchive} autoFocus={true}>Yes</Button>
            <Button className="outline-btn btn-save-delete" onClick={props.handleCancelArchive}>No</Button>
        </ModalFooter>
    </Modal>
)
}

export default ArchiveModal