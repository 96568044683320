import React, { useEffect, useState } from 'react'
import ColoredScrollbars from '../../../common/js/coloredScrollbars';
import SearchInput from 'react-search-input'
import { Button } from 'reactstrap';

import { expandFilteredMaterials, filterMaterials, getMaterialsHeirarchy } from '../../../../utils';
import Icon from '../../../common/js/icon';

const MaterialDetailsTab = (props) => {
  const [searchValue, setSearchValue] = useState("")
  const [materialData, setMaterialData] = useState(getMaterialsHeirarchy(props.materials))
  let materialsData = getMaterialsHeirarchy(props.materials)

  const onFilterMouseUp = (e) => {
    const filter = e.target.value.trim()
    setSearchValue(filter)
    if (!filter) {
      return setMaterialData(materialsData)
    }
    var nData = { 'name': 'Materials', children: materialsData }
    var filtered = filterMaterials(nData, filter)
    filtered = expandFilteredMaterials(filtered, filter)
    setMaterialData(filtered.children)
  }

  const handleMaterialsDataClose = () => {
    setSearchValue("")
    setMaterialData(getMaterialsHeirarchy(props.materials))
  }

  useEffect(() => {
    setMaterialData(getMaterialsHeirarchy(props.materials))
  }, [props.materials])

  return (
    <>
    {!props.isPrint ?
      <>
      {props.materials ?
        (
          <div className="material">
            <div className="input-group textfield-wrapper">
              <Icon color="#ced4da" size={30} icon="icon-sb--search" className="search-icon" />
              <SearchInput placeholder="Search materials"
                className="form-control"
                type="text"
                onKeyUp={onFilterMouseUp}
                value={searchValue} />
              <Button className={`clear-textfield btn btn-secondary ${searchValue ? 'd-block' : 'd-none'}`} onClick={(e) => handleMaterialsDataClose(e)} tabIndex="-1">x</Button>
            </div>
            <div className="tree-list">
              <ColoredScrollbars className="material-list">
                <ul className="material-list-group">
                  {materialData.map((material) => (
                    <li className="d-flex flex-column">
                      <div className="hr-material">
                        {material.name}
                      </div>
                      <div className="material-brand">
                        {material.value}
                      </div>
                    </li>
                  ))}
                </ul>
              </ColoredScrollbars>
            </div>
          </div>
        ) :

        <div className="input-group">
          No materials found on the project
              </div>
      }
      </>
      :
      <>
      {props.materials ?
        (<div className="material-print-wrapper">
          <strong>Materials</strong>
          <div>
            <ul className="material-list-group">
              {
                materialData && materialData.map(function (materials) {
                  return (
                    <li className="d-flex flex-column">
                      <div className="hr-material">
                        {materials.name}
                      </div>
                      <div className="material-brand">
                        {materials.value}
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>)
        : ('')
      }
      </>
    }
    </>
  )

}

export default MaterialDetailsTab