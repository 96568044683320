import _, { has } from "lodash"
import moment from "moment"
import { YEAR, MONTH, WEEK } from "../../../../utils/settings"
import { resetObjectToDefaultAtKey, toggleFilterActiveState } from "./redux/reducer"

export function initializeFilterState(framework, profile, appliedFilter) {
    let filter = {}
    let date = getSliderNoOfDays()
    filter.projectInformation = initializeProjectInformation(appliedFilter)
    filter.companyInformation = initializeCompanyInformation(appliedFilter)
    filter.personInformation = initializePersonInformation(appliedFilter)
    filter.projectScheme = getScheme(appliedFilter)
    filter.projectTitle = getTitle(appliedFilter)
    filter.companyId = initializeCompanyId(appliedFilter)
    filter.companyName = initializeCompanyName(appliedFilter)
    filter.personName = initializePersonName(appliedFilter)
    filter.personJobTitle = initializePersonJobTitle(appliedFilter)
    // Radial Search for project & company
    filter.location = getAdvanceLocation(appliedFilter, "project")
    filter.companyLocation = getAdvanceLocation(appliedFilter, "company")
    // Postcode and Town filter for project & company
    filter.projectPostcode = getPostcodeFilter(appliedFilter, "project")
    filter.projectTown = getTownFilter(appliedFilter, "project")
    filter.companyPostcode = getPostcodeFilter(appliedFilter, "company")
    filter.companyTown = getTownFilter(appliedFilter, "company")
    // Project Framework
    filter.projectFramework = getFramework(framework, appliedFilter)
    // Product Specifications 
    filter.brands = getBrands(appliedFilter)
    // Include Completed Projects

    filter.completedProject = getCompletedProjectFilter(appliedFilter)
    // Build phase 
    filter.buildPhase = getBuildPhaseFilter(appliedFilter)
    // measures
    filter.measure = getMeasureFilter(appliedFilter)
    filter.new = getNewFilter(profile?.project_last_published?.value ? getNoOfDays(profile?.project_last_published?.value) : date['lastYear'], appliedFilter)
    //updated filter
    filter.updated = getUpdateFilter(profile?.project_last_published?.value ? getNoOfDays(profile?.project_last_published?.value) : date['lastYear'], appliedFilter)
    //project calendar timeline
    filter.calendarEndDate = getCalendarEndDateFilter(appliedFilter)
    filter.calendarStartDate = getCalendarStarDateFilter(appliedFilter)
    // categories
    if (profile?.project_categories?.values?.length > 0) {
        filter.includeCategories = getCategoriesFilter(JSON.parse(JSON.stringify(profile?.project_categories?.values)), appliedFilter, "includeCategories")
        filter.excludeCategories = getCategoriesFilter(JSON.parse(JSON.stringify(profile?.project_categories?.values)), appliedFilter, "excludeCategories")
    }
    // development type
    if (profile?.project_development_types?.values?.length > 0) {
        filter.includeDevelopmentTypes = getDevCatTypesFilter(profile?.project_development_types?.values, appliedFilter, "includeDevelopmentTypes")
        filter.excludeDevelopmentTypes = getDevCatTypesFilter(profile?.project_development_types?.values, appliedFilter, "excludeDevelopmentTypes")
    }
    // Roles
    if (profile?.project_roles?.values?.length > 0) {
        filter.includeRoles = getRolesFilter(JSON.parse(JSON.stringify(profile?.project_roles?.values)), appliedFilter, "includeRoles")
        filter.excludeRoles = getRolesFilter(JSON.parse(JSON.stringify(profile?.project_roles?.values)), appliedFilter, "excludeRoles")
    }
    // Region and counties
    if (profile?.project_geocode?.values?.length > 0 && profile?.company_geocode?.values?.length > 0) {
        filter.regions = getRegionsAndCountiesFilter(profile?.project_geocode?.values, appliedFilter, "project")
        filter.companyRegions = getRegionsAndCountiesFilter(profile?.company_geocode?.values, appliedFilter, "company")
    }
    // Materials
    if (profile?.project_materials?.values?.length > 0) {
        filter.materials = getMaterialsFilter(profile?.project_materials?.values, appliedFilter)
    }
    // Stages
    if (profile?.project_stages?.values?.length > 0) {
        filter.includeStages = getIncludeStageFilter(profile.project_stages?.values, appliedFilter)
        filter.advanceExcludeStages = getExcludeStageFilter(profile.project_stages?.values, appliedFilter)
    } else {
        filter.includeStages = getCompleteIncludeStageFilter(appliedFilter)
    }

    filter.excludeStages = getCompleteExcludeStageFilter(appliedFilter)
    // Project Timeline
    filter.startConstruction = getStartConstructionTimingFilter(appliedFilter)
    filter.endConstruction = getEndConstructionTimingFilter(appliedFilter)
    //  Values
    filter.value = getValueFilter(appliedFilter, profile?.project_value ? profile?.project_value : "")
    // Project Usage
    filter.projectUsage = getUsageTypes(appliedFilter, 'projectUsage')
    // project landType
    filter.projectLandType = getLandTypeFilter(appliedFilter, 'projectLandType')
    // Role Added 
    filter.roleAdded = getRoleAddedFilter(appliedFilter)
    filter.offset = 0
    filter.records = 50

    return filter
}

function getUsageTypes(appliedFilter, keyName) {
    return {
        "title": keyName,
        "active": appliedFilter[keyName] ? appliedFilter[keyName].active : false,
        "value": appliedFilter[keyName] ? appliedFilter[keyName].value : []
    }
}

function getRoleAddedFilter(appliedFilter) {
    let options = getRoleAddedFilterOption(appliedFilter)
    let updateFilter = {
        "title": "roleAdded",
        "active": appliedFilter.roleAdded ? appliedFilter.roleAdded.active : false,
        "options": options,
        "toggleActive": appliedFilter.roleAdded ? appliedFilter.roleAdded.toggleActive : false
    }
    return updateFilter
}

function getLandTypeFilter(appliedFilter, keyName) {
    return {
        "title": keyName,
        "active": appliedFilter[keyName] ? appliedFilter[keyName].active : false,
        "value": appliedFilter[keyName] ? appliedFilter[keyName].value : []
    }
}

export function getRoleAddedFilterOption(appliedFilter) {
    let options = []
    options.push({
        "value": YEAR,
        "name": "1 year",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("1 year", appliedFilter.roleAdded.options) : false,
        sliderValue: [-7]
    })
    options.push({
        "value": MONTH * 6,
        "name": "6 months",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("6 months", appliedFilter.roleAdded.options) : false,
        sliderValue: [-6]
    })
    options.push({
        "value": MONTH * 3,
        "name": "3 months",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("3 months", appliedFilter.roleAdded.options) : false,
        sliderValue: [-5]
    })
    options.push({
        "value": MONTH * 2,
        "name": "2 months",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("2 months", appliedFilter.roleAdded.options) : false,
        sliderValue: [-4]
    })
    options.push({
        "value": MONTH,
        "name": "1 month",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("1 month", appliedFilter.roleAdded.options) : false,
        sliderValue: [-3]
    })
    options.push({
        "value": 14,
        "name": "2 weeks",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("1 year", appliedFilter.roleAdded.options) : false,
        sliderValue: [-2]
    })
    options.push({
        "value": 7,
        "name": "1 week",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("1 week", appliedFilter.roleAdded.options) : false,
        sliderValue: [-1]
    })
    options.push({
        "value": 1,
        "name": "1 day",
        "active": (appliedFilter.roleAdded && appliedFilter.roleAdded.active) ?
            isAppliedFilterActive("1 day", appliedFilter.roleAdded.options) : false,
        sliderValue: [0]
    })
    return options
}

function getValueFilter(appliedFilter, subscribedRange) {
    return {
        "title": "value",
        "active": appliedFilter.value ? appliedFilter.value.active : false,
        "from": appliedFilter.value && appliedFilter.value.from ? appliedFilter.value.from : '',
        "to": appliedFilter.value && appliedFilter.value.to ? appliedFilter.value.to : '',
        "displayFrom": appliedFilter.value && appliedFilter.value.displayFrom ? appliedFilter.value.displayFrom : '',
        "displayTo": appliedFilter.value && appliedFilter.value.displayTo ? appliedFilter.value.displayTo : '',
        'subscribedFrom': subscribedRange && subscribedRange.from ? subscribedRange.from : '',
        'subscribedTo': subscribedRange && subscribedRange.to ? subscribedRange.to : '',
    }
}

function getStartConstructionTimingFilter(appliedFilter) {
    return {
        "title": "startConstruction",
        "active": appliedFilter.startConstruction ? appliedFilter.startConstruction.active : false,
        "options": getConstructionFilterOptions(appliedFilter.startConstruction, true)
    }
}

function getEndConstructionTimingFilter(appliedFilter) {
    return {
        "title": "endConstruction",
        "active": appliedFilter.endConstruction ? appliedFilter.endConstruction.active : false,
        "options": getConstructionFilterOptions(appliedFilter.endConstruction, false)
    }
}

function getConstructionFilterOptions(appliedFilter, isStartConstruction) {

    let options = [{
        "value": "-" + YEAR * 2,
        "name": "PAST2Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("PAST2Y", appliedFilter.options) : false
    },
    {
        "value": "-" + YEAR,
        "name": "PAST1Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("PAST1Y", appliedFilter.options) : false
    },
    {
        "value": "-" + MONTH * 6,
        "name": "P6M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("P6M", appliedFilter.options) : false
    },
    {
        "value": "-" + MONTH * 3,
        "name": "P3M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("P3M", appliedFilter.options) : false
    },
    {
        "value": "-" + MONTH,
        "name": "P1M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("P1M", appliedFilter.options) : false
    },
    {
        "value": "0",
        "name": "TODAY",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("TODAY", appliedFilter.options) : true
    },
    {
        "value": "+" + MONTH,
        "name": "1M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("1M", appliedFilter.options) : true
    },
    {
        "value": "+" + MONTH * 3,
        "name": "3M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("3M", appliedFilter.options) : false
    },
    {
        "value": "+" + MONTH * 6,
        "name": "6M",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("6M", appliedFilter.options) : false
    },
    {
        "value": "+" + YEAR,
        "name": "FUTURE1Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("FUTURE1Y", appliedFilter.options) : false
    },
    {
        "value": "+" + YEAR * 2,
        "name": "FUTURE2Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("FUTURE2Y", appliedFilter.options) : false
    },
    {
        "value": "+" + YEAR * 3,
        "name": "FUTURE3Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("FUTURE3Y", appliedFilter.options) : false
    },
    {
        "value": "+" + YEAR * 4,
        "name": "FUTURE4Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("FUTURE4Y", appliedFilter.options) : false
    },
    {
        "value": "+" + YEAR * 5,
        "name": "FUTURE5Y",
        "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("FUTURE5Y", appliedFilter.options) : false
    }]

    if (isStartConstruction) {
        options.unshift({
            "value": "-" + YEAR * 5,
            "name": "PAST5Y",
            "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("PAST5Y", appliedFilter.options) : false
        },
            {
                "value": "-" + YEAR * 4,
                "name": "PAST4Y",
                "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("PAST4Y", appliedFilter.options) : false
            },
            {
                "value": "-" + YEAR * 3,
                "name": "PAST3Y",
                "active": (appliedFilter && appliedFilter.active) ? isAppliedFilterActive("PAST3Y", appliedFilter.options) : false
            })
    }

    return options
}


export function getCompleteIncludeStageFilter(appliedFilter) {
    return {
        "title": "includeStages",
        "active": appliedFilter.includeStages ? appliedFilter.includeStages.active : false,
        "options": [
            { "value": [0, 1], "name": "Pre-planning", "active": appliedFilter.includeStages ? isAppliedFilterActive("Pre-planning", appliedFilter.includeStages.options) : false },
            { "value": [2, 3], "name": "Plans submitted", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans submitted", appliedFilter.includeStages.options) : false },
            { "value": [10, 11], "name": "Plans approved", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans approved", appliedFilter.includeStages.options) : false },
            { "value": [15, 16], "name": "Plans refused", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans refused", appliedFilter.includeStages.options) : false },
            { "value": [5], "name": "Pre-tender", "active": appliedFilter.includeStages ? isAppliedFilterActive("Pre-tender", appliedFilter.includeStages.options) : false },
            { "value": [6], "name": "Main contract tender", "active": appliedFilter.includeStages ? isAppliedFilterActive("Main contract tender", appliedFilter.includeStages.options) : false },
            { "value": [7], "name": "Main contract awarded", "active": appliedFilter.includeStages ? isAppliedFilterActive("Main contract awarded", appliedFilter.includeStages.options) : false },
            { "value": [22], "name": "Sub-contract awarded", "active": appliedFilter.includeStages ? isAppliedFilterActive("Sub-contract awarded", appliedFilter.includeStages.options) : false },
            { "value": [30], "name": "On hold", "active": appliedFilter.includeStages ? isAppliedFilterActive("On hold", appliedFilter.includeStages.options) : false },
        ],
        "isAllSelected": false
    }
}

export function isAppliedFilterActive(name, appliedFilterOptions, l2 = false, l2ObjName = null) {
    let active = false
    appliedFilterOptions.map(function (item, index) {
        if (l2 && l2ObjName != null) {
            appliedFilterOptions.l2ObjName.map(function (l2Item, key) {
                if (l2Item.name === name) {
                    active = l2Item.active
                }
            })
        } else {
            if (item.name === name) {
                active = item.active
            }
        }
    })
    return active
}

export function getExcludeStageFilter(stages, appliedFilter) {
    let options = []

    stages.map(stageObj => {
        let stage = {}
        let excludeOption = []
        stage.name = stageObj.label
        stage.isChildSelected = false
        stage.display = true
        stage.active = false
        stage.subscribed = true

        let stageIds = stageObj.children && stageObj.children.map(function (stage) { return stage.value.toString() }).join(',')
        let stageSubscribed = stageObj.children.map(function (stage) { return stage.subscribed })
        if (stageObj.label === "Planning Stages" && stageObj.children) {
            stage.value = 100
            let plansRefusedValue = getPlansRefusedStage(stageIds)
            let plansRefused = stageObj.children.filter(stage => stage.label === 'Plans refused')
            if (plansRefusedValue.length > 0) {
                excludeOption.push({ "value": plansRefusedValue, "name": "Plans refused", "active": appliedFilter.excludeStages ? isAppliedFilterActive("Plans refused", appliedFilter.excludeStages.options) : false, display: true, subscribed: plansRefused[0].subscribed })
            }
        } else if (stageObj.label === "Contract Stages") {
            stage.value = 200
            let preTenderValue = getPreTenderStage(stageIds)
            let preTender = stageObj.children.filter(stage => stage.label === 'Pre-tender')
            if (preTenderValue.length > 0) {
                excludeOption.push({ "value": preTenderValue, "name": "Pre-tender", "active": appliedFilter.excludeStages ? isAppliedFilterActive("Pre-tender", appliedFilter.excludeStages.options) : false, display: true, subscribed: preTender[0].subscribed })
            }

            let mainContractTenderValue = getMainContractTenderStage(stageIds)
            let mainContractTender = stageObj.children.filter(stage => stage.label === 'Main contract tender')
            if (mainContractTenderValue.length > 0) {
                excludeOption.push({ "value": mainContractTenderValue, "name": "Main contract tender", "active": appliedFilter.excludeStages ? isAppliedFilterActive("Main contract tender", appliedFilter.excludeStages.options) : false, display: true, subscribed: mainContractTender[0].subscribed })
            }

            let mainContractAwardedValue = getMainContractAwardedStage(stageIds)
            let mainContractAwarded = stageObj.children.filter(stage => stage.label === 'Main contract awarded')
            if (mainContractAwardedValue.length > 0) {
                excludeOption.push({ "value": mainContractAwardedValue, "name": "Main contract awarded", "active": appliedFilter.excludeStages ? isAppliedFilterActive("Main contract awarded", appliedFilter.excludeStages.options) : false, display: true, subscribed: mainContractAwarded[0].subscribed })
            }

            let subContractAwardedValue = getSubContractAwardedStage(stageIds)
            let subContractAwarded = stageObj.children.filter(stage => stage.label === 'Sub-contract awarded')
            if (subContractAwardedValue.length > 0) {
                excludeOption.push({ "value": subContractAwardedValue, "name": "Sub-contract awarded", "active": appliedFilter.excludeStages ? isAppliedFilterActive("Sub-contract awarded", appliedFilter.excludeStages.options) : false, display: true, subscribed: subContractAwarded[0].subscribed })
            }
        } else if (stageObj.label === "Stopped" && stageObj.children) {
            stage.value = 300
            let onHoldValue = getOnHoldStage(stageIds)
            if (onHoldValue.length > 0) {
                excludeOption.push({ "value": onHoldValue, "name": "On hold", "active": appliedFilter.excludeStages ? isAppliedFilterActive("On hold", appliedFilter.excludeStages.options) : false, display: true, subscribed: stageSubscribed[stageIds.indexOf(30)] })
            }
        }
        if (excludeOption.length > 0) {
            stage.children = excludeOption;
            options.push(stage)
        }
    })
    return {
        "title": "excludeStages",
        "active": appliedFilter.advanceExcludeStages ? appliedFilter.advanceExcludeStages.active : false,
        "options": options,
        "isAllSelected": false
    }
}

export function getIncludeStageFilter(stages, appliedFilter) {
    let option = []
    stages && stages.map(stageObj => {
        let stage = {}
        stage.name = stageObj.label
        stage.isChildSelected = false
        stage.display = true
        stage.active = false
        stage.subscribed = true

        let stageIds = stageObj.children && stageObj.children.map(function (stage) { return stage.value.toString() }).join(',')
        let stageSubscribed = stageObj.children && stageObj.children.map(function (stage) { return stage.subscribed })
        let includeOptions = []
        if (stageObj.label === "Planning Stages" && stageObj.children) {
            stage.value = 100
            let preFastPlanValue = getFastPlanStage(stageIds)
            let fastPlan = stageObj.children.filter(stage => stage.label === 'FastPlan')
            if (preFastPlanValue.length > 0) {
                includeOptions.push({ "value": preFastPlanValue, "name": "FastPlan", "active": appliedFilter.includeStages ? isAppliedFilterActive("FastPlan", appliedFilter.includeStages.options) : false, display: true, subscribed: fastPlan[0].subscribed })
            }
            let prePlanningValue = getPrePlanningStage(stageIds)
            let prePlanning = stageObj.children.filter(stage => stage.label === 'Pre-planning')
            if (prePlanningValue.length > 0) {
                includeOptions.push({ "value": prePlanningValue, "name": "Pre-planning", "active": appliedFilter.includeStages ? isAppliedFilterActive("Pre-planning", appliedFilter.includeStages.options) : false, display: true, subscribed: prePlanning[0].subscribed })
            }

            let plansSubmitValue = getPlansSubmitStage(stageIds)
            let plansSubmitted = stageObj.children.filter(stage => stage.label === 'Plans submitted')
            if (plansSubmitValue.length > 0) {
                includeOptions.push({ "value": plansSubmitValue, "name": "Plans submitted", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans submitted", appliedFilter.includeStages.options) : false, display: true, subscribed: plansSubmitted[0].subscribed })
            }

            let plansApprovedValue = getPlansApprovedStage(stageIds)
            let plansApproved = stageObj.children.filter(stage => stage.label === 'Plans approved')
            if (plansApprovedValue.length > 0) {
                includeOptions.push({ "value": plansApprovedValue, "name": "Plans approved", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans approved", appliedFilter.includeStages.options) : false, display: true, subscribed: plansApproved[0].subscribed })
            }

            let plansRefusedValue = getPlansRefusedStage(stageIds)
            let plansRefused = stageObj.children.filter(stage => stage.label === 'Plans refused')
            if (plansRefusedValue.length > 0) {
                includeOptions.push({ "value": plansRefusedValue, "name": "Plans refused", "active": appliedFilter.includeStages ? isAppliedFilterActive("Plans refused", appliedFilter.includeStages.options) : false, display: true, subscribed: plansRefused[0].subscribed })
            }
        } else if (stageObj.label === "Contract Stages" && stageObj.children) {
            stage.value = 200
            let preTenderValue = getPreTenderStage(stageIds)
            let preTender = stageObj.children.filter(stage => stage.label === 'Pre-tender')
            if (preTenderValue.length > 0) {
                includeOptions.push({ "value": preTenderValue, "name": "Pre-tender", "active": appliedFilter.includeStages ? isAppliedFilterActive("Pre-tender", appliedFilter.includeStages.options) : false, display: true, subscribed: preTender[0].subscribed })
            }

            let mainContractTenderValue = getMainContractTenderStage(stageIds)
            let mainContractTender = stageObj.children.filter(stage => stage.label === 'Main contract tender')
            if (mainContractTenderValue.length > 0) {
                includeOptions.push({ "value": mainContractTenderValue, "name": "Main contract tender", "active": appliedFilter.includeStages ? isAppliedFilterActive("Main contract tender", appliedFilter.includeStages.options) : false, display: true, subscribed: mainContractTender[0].subscribed })
            }

            let mainContractAwardedValue = getMainContractAwardedStage(stageIds)
            let mainContractAwarded = stageObj.children.filter(stage => stage.label === 'Main contract awarded')
            if (mainContractAwardedValue.length > 0) {
                includeOptions.push({ "value": mainContractAwardedValue, "name": "Main contract awarded", "active": appliedFilter.includeStages ? isAppliedFilterActive("Main contract awarded", appliedFilter.includeStages.options) : false, display: true, subscribed: mainContractAwarded[0].subscribed })
            }

            let subContractAwardedValue = getSubContractAwardedStage(stageIds)
            let subContractAwarded = stageObj.children.filter(stage => stage.label === 'Sub-contract awarded')
            if (subContractAwardedValue.length > 0) {
                includeOptions.push({ "value": subContractAwardedValue, "name": "Sub-contract awarded", "active": appliedFilter.includeStages ? isAppliedFilterActive("Sub-contract awarded", appliedFilter.includeStages.options) : false, display: true, subscribed: subContractAwarded[0].subscribed })
            }
        } else if (stageObj.label === "Stopped" && stageObj.children) {
            stage.value = 300
            let onHoldValue = getOnHoldStage(stageIds)
            if (onHoldValue.length > 0) {
                includeOptions.push({ "value": onHoldValue, "name": "On hold", "active": appliedFilter.includeStages ? isAppliedFilterActive("On hold", appliedFilter.includeStages.options) : false, display: true, subscribed: stageSubscribed[stageIds.indexOf(30)] })
            }
        }
        if (includeOptions.length > 0) {
            stage.children = includeOptions
            option.push(stage)
        }
    })
    return {
        "title": "includeStages",
        "active": appliedFilter.includeStages ? appliedFilter.includeStages.active : false,
        "options": option,
        "isAllSelected": false
    }
}

function getFastPlanStage(stageIds) {
    let preFastPlanValue = []
    if (stageIds) {
        if (stageIds.indexOf(40) > -1) {
            preFastPlanValue.push(40)
        }
        if (stageIds.indexOf(41) > -1) {
            preFastPlanValue.push(41)
        }
    }
    return preFastPlanValue
}

function getPrePlanningStage(stageIds) {
    let prePlanningValue = []
    if (stageIds) {
        if (stageIds.indexOf(0) > -1) {
            prePlanningValue.push(0)
        }
        if (stageIds.indexOf(1) > -1) {
            prePlanningValue.push(1)
        }
    }
    return prePlanningValue
}

function getPlansSubmitStage(stageIds) {
    let plansSubmitValue = []
    if (stageIds.indexOf(2) > -1) {
        plansSubmitValue.push(2)
    }
    if (stageIds.indexOf(3) > -1) {
        plansSubmitValue.push(3)
    }
    return plansSubmitValue
}

function getPlansApprovedStage(stageIds) {
    let plansApprovedValue = []
    if (stageIds.indexOf(10) > -1) {
        plansApprovedValue.push(10)
    }
    if (stageIds.indexOf(11) > -1) {
        plansApprovedValue.push(11)
    }
    return plansApprovedValue
}

function getPlansRefusedStage(stageIds) {
    let plansRefusedValue = []
    if (stageIds.indexOf(15) > -1) {
        plansRefusedValue.push(15)
    }
    if (stageIds.indexOf(16) > -1) {
        plansRefusedValue.push(16)
    }
    return plansRefusedValue
}

function getPreTenderStage(stageIds) {
    let preTenderValue = []
    if (stageIds.indexOf(5) > -1) {
        preTenderValue.push(5)
    }
    return preTenderValue
}

function getMainContractTenderStage(stageIds) {
    let mainContractTenderValue = []
    if (stageIds.indexOf(6) > -1) {
        mainContractTenderValue.push(6)
    }
    return mainContractTenderValue
}

function getMainContractAwardedStage(stageIds) {
    let mainContractAwardedValue = []
    if (stageIds.indexOf(7) > -1) {
        mainContractAwardedValue.push(7)
    }
    return mainContractAwardedValue
}

function getSubContractAwardedStage(stageIds) {
    let subContractAwardedValue = []
    if (stageIds.indexOf(22) > -1) {
        subContractAwardedValue.push(22)
    }
    return subContractAwardedValue
}

function getOnHoldStage(stageIds) {
    let onHoldValue = []
    if (stageIds.indexOf(30) > -1) {
        onHoldValue.push(30)
    }
    return onHoldValue
}

function getMaterialsFilter(materials, appliedFilter) {
    let options = []
    materials.map(mainParent => {
        let material = {}
        let subParent = []
        material.value = mainParent.value
        material.name = mainParent.label
        material.active = false
        material.isChildSelected = false
        material.display = true
        material.subscribed = true
        if (mainParent.children) {
            mainParent.children.map(childParent => {
                let subMat = []
                let subChild = {}
                subChild.value = childParent.value
                subChild.name = childParent.label
                subChild.active = false
                subChild.isChildSelected = false
                subChild.display = true
                if (childParent.children) {
                    childParent.children.map(child => {
                        let childMat = {}
                        childMat.value = child.value
                        childMat.name = child.label
                        childMat.active = false
                        childMat.display = true
                        childMat.subscribed = true
                        subMat.push(childMat)
                        subChild.children = subMat
                    })
                }
                subParent.push(subChild)
            })
            material.subParent = subParent
        }
        options.push(material)
    })

    let appliedOptions = appliedFilter.materials ? appliedFilter.materials.options : options
    return {
        "title": "materials",
        "active": appliedFilter.materials ? appliedFilter.materials.active : false,
        "isChildSelected": false,
        "options": appliedOptions,
        "isAllSelected": false
    }
}

function getRegionsAndCountiesFilter(locations, appliedFilter, type) {
    let options = []

    locations.forEach(function (location) {
        var region = {}
        region.value = location.value
        region.name = location.label
        region.label = location.label
        region.active = false
        region.display = true
        region.isChildSelected = false
        region.type = type
        region.subscribed = location.subscribed

        let counties = []
        location.children.forEach(function (subRegion) {
            let subReg = {}
            subReg.value = subRegion.value
            subReg.name = subRegion.label
            subReg.label = subRegion.label
            subReg.active = false
            subReg.display = true
            subReg.type = type
            subReg.subscribed = subRegion.subscribed
            counties.push(subReg)
        })
        region.counties = counties

        options.push(region)
    })
    let regionsObj = type === 'project' ? appliedFilter.regions ? appliedFilter.regions.options : options : appliedFilter.companyRegionsAndCounties ? appliedFilter.companyRegionsAndCounties.options : options
    return {
        "title": type === "project" ? "regions" : "companyRegions",
        "active": type === "project" ? appliedFilter.regions ? appliedFilter.regions.active : false : appliedFilter.companyRegions ? appliedFilter.companyRegions.active : false,
        "options": regionsObj,
        "isAllSelected": false
    }
}

function getCategoriesFilter(categories, appliedFilter, categoryType) {
    let options = []
    categories.forEach(function (categoryObj) {
        let category = {}
        let subcats = []
        category.value = categoryObj.value
        category.name = categoryObj.label
        category.active = appliedFilter.categories && appliedFilter.categories.active ? isAppliedFilterActive(categoryObj.name, appliedFilter.categories.options) : false
        category.display = true
        category.isChildSelected = false
        category.subscribed = categoryObj.subscribed
        categoryObj?.children?.forEach(function (subcatObj) {
            let subcat = {}
            subcat.value = subcatObj.value
            subcat.name = subcatObj.label
            subcat.active = appliedFilter.categories && appliedFilter.categories.active ? isAppliedFilterActive(subcatObj.name, appliedFilter.categories.options, true, 'subCategories') : false
            subcat.display = true
            subcat.subscribed = subcatObj.subscribed
            subcats.push(subcat)
        }
        )
        category.subCategories = subcats
        options.push(category)
    }
    )
    return {
        "title": categoryType,
        "active": appliedFilter[categoryType] ? appliedFilter[categoryType].active : false,
        "options": options,
        "isAllSelected": false
    }
}

function getDevCatTypesFilter(devCatObj, appliedFilter, devCatType) {
    let options = []
    devCatObj?.forEach(function (categoryObj) {
        let category = {}
        category.value = categoryObj.value
        category.name = categoryObj.label
        category.active = appliedFilter[devCatType] && appliedFilter[devCatType].active ? isAppliedFilterActive(categoryObj.label, appliedFilter[devCatType].options) : false
        category.display = true
        category.subscribed = categoryObj.subscribed
        options.push(category)
    })
    let appliedOptions = appliedFilter[devCatType] ? appliedFilter[devCatType].options : options
    return {
        "title": devCatType,
        "active": appliedFilter[devCatType] ? appliedFilter[devCatType].active : false,
        "options": appliedOptions,
        "isAllSelected": false
    }
}

function getRolesFilter(roles, appliedFilter, roleType) {
    let options = []
    roles.forEach(function (roleObj) {
        let role = {}
        role.value = roleObj.value
        role.name = roleObj.label
        role.label = roleObj.label
        role.active = false
        role.display = true
        role.subscribed = true
        options.push(role)
    })
    return {
        "title": roleType,
        "active": appliedFilter[roleType] ? appliedFilter[roleType].active : false,
        "options": options
    }
}

function initializeProjectInformation(appliedFilter) {
    return {
        "title": "projectInformation",
        "active": appliedFilter.projectInformation ? appliedFilter.projectInformation.active : false,
        "dataObj": appliedFilter.projectInformation && appliedFilter.projectInformation.dataObj ? appliedFilter.projectInformation.dataObj : [],
        "value": appliedFilter.projectInformation && appliedFilter.projectInformation.value ? appliedFilter.projectInformation.value : [],
        "isAnyAll": appliedFilter.projectInformation && appliedFilter.projectInformation.value &&
            appliedFilter.projectInformation.value.length > 2 ? appliedFilter.projectInformation &&
                appliedFilter.projectInformation.isAnyAll ? "all" : "any" : false
    }
}

function initializeCompanyInformation(appliedFilter) {
    return {
        "title": "companyInformation",
        "active": appliedFilter.companyInformation ? appliedFilter.companyInformation.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.companyInformation ? appliedFilter.companyInformation.value : ''
    }
}

function initializePersonInformation(appliedFilter) {
    return {
        "title": "personInformation",
        "active": appliedFilter.personInformation ? appliedFilter.personInformation.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.personInformation ? appliedFilter.personInformation.value : ''
    }
}

function getScheme(appliedFilter) {
    return {
        "title": "projectScheme",
        "active": appliedFilter.projectScheme ? appliedFilter.projectScheme.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.projectScheme ? appliedFilter.projectScheme.value : ''
    }
}

function getTitle(appliedFilter) {
    return {
        "title": "projectTitle",
        "active": appliedFilter.projectTitle ? appliedFilter.projectTitle.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.projectTitle ? appliedFilter.projectTitle.value : ""
    }
}

function initializeCompanyName(appliedFilter) {
    return {
        "title": "companyName",
        "active": appliedFilter.companyName ? appliedFilter.companyName.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.companyName ? appliedFilter.companyName.value : ''
    }
}

function initializeCompanyId(appliedFilter) {
    return {
        "title": "companyId",
        "active": appliedFilter.companyId ? appliedFilter.companyId.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.companyId ? appliedFilter.companyId.value : ''
    }
}
function initializePersonName(appliedFilter) {
    return {
        "title": "personName",
        "active": appliedFilter.personName ? appliedFilter.personName.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.personName ? appliedFilter.personName.value : ''
    }
}

function initializePersonJobTitle(appliedFilter) {
    return {
        "title": "personJobTitle",
        "active": appliedFilter.personJobTitle ? appliedFilter.personJobTitle.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.personJobTitle ? appliedFilter.personJobTitle.value : ''
    }
}

function getAdvanceLocation(appliedFilter, type) {
    let locationObj = type === 'project' ? appliedFilter.location : appliedFilter.companyLocation
    return {
        "title": type === "project" ? "location" : "CompanyLocation",
        "location": locationObj && locationObj.location ? locationObj.location : '',
        "latitude": locationObj && locationObj.latitude ? locationObj.latitude : 0,
        "longitude": locationObj && locationObj.longitude ? locationObj.longitude : 0,
        "active": locationObj && locationObj.active ? locationObj.active : false,
        "radius": locationObj && locationObj.radius ? locationObj.radius : 25
    }
}

function getPostcodeFilter(appliedFilter, type) {
    let postcodeObj = type === 'project' ? appliedFilter.projectPostcode ? appliedFilter.projectPostcode : {} : appliedFilter.companyPostcode ? appliedFilter.companyPostcode : {}
    return {
        "title": type === "project" ? "projectPostcode" : "companyPostcode",
        "active": postcodeObj && has(postcodeObj, 'active') ? postcodeObj.active : false,
        "dataObj": postcodeObj.dataObj ? postcodeObj.dataObj : [],
        "value": postcodeObj.value ? postcodeObj.value : []
    }
}

function getTownFilter(appliedFilter, type) {
    let townObj = type === 'project' ? appliedFilter.projectTown ? appliedFilter.projectTown : {} : appliedFilter.companyTown ? appliedFilter.companyTown : {}
    return {
        "title": type === "project" ? "projectTown" : "companyTown",
        "active": townObj && has(townObj, 'active') ? townObj.active : false,
        "dataObj": townObj.dataObj ? townObj.dataObj : [],
        "value": townObj.value ? townObj.value : []
    }
}

function getFramework(framework, appliedFilter) {
    let frameworkObj = appliedFilter.projectFramework ? appliedFilter.projectFramework : {}
    let options = []
    let frameworkData = _.cloneDeep(framework)
    frameworkData?.map(item => {
        if (!options?.some(data => data.id === item.id)) {
            item.active = false
            options.push(item)
        }
    })
    return {
        "title": "projectFramework",
        "active": frameworkObj && has(frameworkObj, 'active') ? frameworkObj.active : false,
        "dataObj": frameworkObj.dataObj ? frameworkObj.dataObj : [],
        "value": frameworkObj.value ? frameworkObj.value : [],
        "options": options
    }
}

function getBrands(appliedFilter) {
    return {
        "title": "brands",
        "active": appliedFilter.brands ? appliedFilter.brands.active : false,
        "isSearchApplied": false,
        "value": appliedFilter.brands ? appliedFilter.brands.value : ''
    }
}

function getCompletedProjectFilter(appliedFilter) {
    return {
        title: "Show Completed Project",
        active: appliedFilter.completedProject && appliedFilter.completedProject.active ? true : false,
        value: "completed"
    }
}

function getBuildPhaseFilter(appliedFilter) {
    return {
        "title": "Build Phase",
        "active": appliedFilter.buildPhase && appliedFilter.buildPhase && appliedFilter.buildPhase.active ? appliedFilter.buildPhase.active : false,
        "isSearchApplied": false,
        "sliderValue": [0, 3],
        "options": [
            {
                "title": "Not Started",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[0].active ? appliedFilter.buildPhase.options[0].active : true,
                "value": "not_started"
            },
            {
                "title": "Enabling works",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[1].active ? appliedFilter.buildPhase.options[1].active : true,
                "value": "enabling"
            },
            {
                "title": "On-site",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[2].active ? appliedFilter.buildPhase.options[2].active : true,
                "value": "on_site"
            },
            {
                "title": "No Timing",
                "active": appliedFilter.buildPhase && appliedFilter.buildPhase.options[3].active ? appliedFilter.buildPhase.options[3].active : true,
                "value": "no_timing"
            }
        ]
    }
}


function getMeasureFilter(appliedFilter) {
    let options = {
        "totalFloorArea": {
            "name": "totalFloorArea",
            "from": appliedFilter.measure && appliedFilter.measure.totalFloorArea ? appliedFilter.measure.totalFloorArea.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.totalFloorArea ? appliedFilter.measure.totalFloorArea.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.totalFloorArea ? Boolean(appliedFilter.measure.totalFloorArea.active) : false
        },
        "groundFloorArea": {
            "name": "groundFloorArea",
            "from": appliedFilter.measure && appliedFilter.measure.groundFloorArea ? appliedFilter.measure.groundFloorArea.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.groundFloorArea ? appliedFilter.measure.groundFloorArea.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.groundFloorArea ? Boolean(appliedFilter.measure.groundFloorArea.active) : false
        },
        "siteArea": {
            "name": "siteArea",
            "from": appliedFilter.measure && appliedFilter.measure.siteArea ? appliedFilter.measure.siteArea.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.siteArea ? appliedFilter.measure.siteArea.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.siteArea ? Boolean(appliedFilter.measure.siteArea.active) : false
        },
        "aboveGroundStoreys": {
            "name": "aboveGroundStoreys",
            "from": appliedFilter.measure && appliedFilter.measure.aboveGroundStoreys ? appliedFilter.measure.aboveGroundStoreys.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.aboveGroundStoreys ? appliedFilter.measure.aboveGroundStoreys.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.aboveGroundStoreys ? Boolean(appliedFilter.measure.aboveGroundStoreys.active) : false
        },
        "belowGroundStoreys": {
            "name": "belowGroundStoreys",
            "from": appliedFilter.measure && appliedFilter.measure.belowGroundStoreys ? appliedFilter.measure.belowGroundStoreys.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.belowGroundStoreys ? appliedFilter.measure.belowGroundStoreys.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.belowGroundStoreys ? Boolean(appliedFilter.measure.belowGroundStoreys.active) : false
        },
        "noOfUnits": {
            "name": "noOfUnits",
            "from": appliedFilter.measure && appliedFilter.measure.noOfUnits ? appliedFilter.measure.noOfUnits.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.noOfUnits ? appliedFilter.measure.noOfUnits.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.noOfUnits ? Boolean(appliedFilter.measure.noOfUnits.active) : false
        },
        "parkingSpaces": {
            "name": "parkingSpaces",
            "from": appliedFilter.measure && appliedFilter.measure.parkingSpaces ? appliedFilter.measure.parkingSpaces.from : '',
            "to": appliedFilter.measure && appliedFilter.measure.parkingSpaces ? appliedFilter.measure.parkingSpaces.to : '',
            "active": appliedFilter.measure && appliedFilter.measure.parkingSpaces ? Boolean(appliedFilter.measure.parkingSpaces.active) : false
        }
    }

    let appliedOptions = appliedFilter.measure ? appliedFilter.measure.options : options

    return {
        "title": "measure",
        "active": appliedFilter.measure ? appliedFilter.measure.active : false,
        "options": appliedOptions
    }
}

export const STATE_KEYS = {
    BUILD_START_DATE: "buildStartDate",
    CALENDAR_END_DATE: "calendarEndDate",
    CALENDAR_START_DATE: "calendarStartDate",
    COMPANY_INFORMATION: "companyInformation",
    INCLUDE_CATEGORIES: "includeCategories",
    EXCLUDE_CATEGORIES: "excludeCategories",
    COMPANY_LOCATION: "companyLocation",
    REGIONS: "regions",
    COMPANY_REGIONS: "companyRegions",
    COMPANY_TOWN: "companyTown",
    COMPANY_POST_CODE: "companyPostcode",
    COMPANY_ID: "companyId",
    COMPANY_NAME: "companyName",
    INCLUDE_DEVELOPMENT_TYPES: "includeDevelopmentTypes",
    EXCLUDE_DEVELOPMENT_TYPES: "excludeDevelopmentTypes",
    END_CONSTRUCTION: "endConstruction",
    INCLUDE_ROLES: "includeRoles",
    EXCLUDE_ROLES: "excludeRoles",
    INCLUDE_STAGES: "includeStages",
    ADVANCE_EXCLUDE_STAGES: "advanceExcludeStages",
    EXCLUDE_STAGES: "excludeStages",
    LOCATION: "location",
    MATERIALS: "materials",
    MEASURE: "measure",
    NEW: "new",
    NO_OF_COVERS: "noOfCovers",
    OPENING_DATE: "openingDate",
    PERSON_NAME: "personName",
    PERSON_JOB_TITLE: "personJobTitle",
    PROJECT_ID: "projectId",
    PROJECT_SCHEME: "projectScheme",
    PROJECT_TITLE: "projectTitle",
    PROJECT_INFORMATION: "projectInformation",
    PERSON_INFORMATION: "personInformation",
    SIZE: "size",
    START_CONSTRUCTION: "startConstruction",
    STOREYS: "storeys",
    PROJECT_TOWN: "projectTown",
    PROJECT_POST_CODE: "projectPostcode",
    COMPANY_TOWN: "companyTown",
    COMPANY_POST_CODE: "companyPostcode",
    UNITS: "units",
    UPDATED: "updated",
    VALUE: "value",
    BUILD_PHASE: "buildPhase",
    // primaryCategoriesOnly: "primaryCategoriesOnly",
    PROJECT_USAGE: "projectUsage",
    PROJECT_FRAMEWORK: "projectFramework",
    INCLUSIONS: "inclusions",
    TOTAL_FLOOR_AREA: "totalFloorArea",
    GROUND_FLOOR_AREA: "groundFloorArea",
    SITE_AREA: "siteArea",
    ABOVE_GROUND_STOREYS: "aboveGroundStoreys",
    BELOW_GROUND_STOREYS: "belowGroundStoreys",
    NO_OF_UNITS: "noOfUnits",
    PARKING_SPACES: "parkingSpaces",
    CATEGORIES: "categories",
    STAGES: "stages",
    ROLES: "roles",
    DEVELOPMENT_TYPES: "developmentTypes",
    COMPLETED_PROJECT: "completedProject",
    RELATIVE_TIMING: "relativeTimings",
    CALENDAR_TIMING: "calendarTiming",
    CATERING: "cateringTypes",
    INCLUDE_CATERING: "includeCateringTypes",
    EXCLUDE_CATERING: "excludeCateringTypes",
    PROJECT_LAND_TYPE: "projectLandType",
    BRANDS: "brands"
}

export const ENTITY_TYPE = {
    PROJECT: 'project',
    COMPANY: 'company',
    PEOPLE: 'people'
}

export const MEASURE_FILTER_KEYS = [
    "totalFloorArea",
    "groundFloorArea",
    "siteArea",
    "aboveGroundStoreys",
    "belowGroundStoreys",
    "noOfUnits",
    "parkingSpaces"
]

export const getRelatedKeys = (key) => {
    if (key === STATE_KEYS.UPDATED || key === STATE_KEYS.NEW) {
        return [STATE_KEYS.UPDATED, STATE_KEYS.NEW]
    } else if (key === STATE_KEYS.INCLUDE_CATEGORIES || key === STATE_KEYS.EXCLUDE_CATEGORIES || key === STATE_KEYS.CATEGORIES) {
        return [STATE_KEYS.INCLUDE_CATEGORIES, STATE_KEYS.EXCLUDE_CATEGORIES]
    } else if (key === STATE_KEYS.INCLUDE_STAGES || key === STATE_KEYS.ADVANCE_EXCLUDE_STAGES || key === STATE_KEYS.STAGES) {
        return [STATE_KEYS.INCLUDE_STAGES, STATE_KEYS.ADVANCE_EXCLUDE_STAGES]
    } else if (key === STATE_KEYS.INCLUDE_DEVELOPMENT_TYPES || key === STATE_KEYS.EXCLUDE_DEVELOPMENT_TYPES || key === STATE_KEYS.DEVELOPMENT_TYPES) {
        return [STATE_KEYS.INCLUDE_DEVELOPMENT_TYPES, STATE_KEYS.EXCLUDE_DEVELOPMENT_TYPES]
    } else if (key === STATE_KEYS.INCLUDE_ROLES || key === STATE_KEYS.EXCLUDE_ROLES || key === STATE_KEYS.ROLES) {
        return [STATE_KEYS.INCLUDE_ROLES, STATE_KEYS.EXCLUDE_ROLES]
    } else if (key === STATE_KEYS.CALENDAR_START_DATE || key === STATE_KEYS.CALENDAR_END_DATE) {
        return [STATE_KEYS.CALENDAR_START_DATE, STATE_KEYS.CALENDAR_END_DATE]
    } else if (key === STATE_KEYS.START_CONSTRUCTION || key === STATE_KEYS.END_CONSTRUCTION) {
        return [STATE_KEYS.START_CONSTRUCTION, STATE_KEYS.END_CONSTRUCTION]
    } else if (key === STATE_KEYS.RELATIVE_TIMING) {
        return [STATE_KEYS.START_CONSTRUCTION, STATE_KEYS.END_CONSTRUCTION]
    } else if (key === STATE_KEYS.CALENDAR_TIMING) {
        return [STATE_KEYS.CALENDAR_START_DATE, STATE_KEYS.CALENDAR_END_DATE]
    } else if (key === STATE_KEYS.INCLUDE_CATERING || key === STATE_KEYS.EXCLUDE_CATERING || key === STATE_KEYS.CATERING) {
        return [STATE_KEYS.INCLUDE_CATERING, STATE_KEYS.EXCLUDE_CATERING]
    }
    else {
        return Object.values(STATE_KEYS).filter(x => x === key)
    }
}

export function toggleFilterActiveStateForElement(payload, dispatch) {
    let listOfKeysToUpdate = getRelatedKeys(payload.key)
    listOfKeysToUpdate?.forEach(k => {
        dispatch(toggleFilterActiveState({ key: k, value: payload.value }))
        dispatch(resetObjectToDefaultAtKey({ key: k }))
    })
    if (payload.fetchEntityCounts && payload.initTags) {
        payload.fetchEntityCounts({ initTags: payload.initTags, isRemove: payload.isRemove, updateRemoveCount: payload.updateRemoveCount })
    } else if (!payload.isRemove) {
        // let filtersState = selectState(getState(), STATE_SLICE_KEYS.FILTERS_STATE)
        //AB-566 - sort order reset on tile removal, discussion pending
        // let sort = commonFunctions.fetchHandleSubmitSort(filtersState.display, filtersState)
        // filtersState.sort = sort
        // filtersState.records = getDefaultRecordCount(getState().common.archiveCount, filtersState.display)
        // dispatch(fetchResult(filtersState))
    }
}

export function updateActiveStatusOnParentAndChildren(lstOptions, isActive, payload) {
    // if parentId is not null, that means we are searching for a particular child element
    // if currentId is not null, that means we are searching for a particular parent and all its children
    // if both are null, that would mean that this operation should effect all records in the list
    // if both are not null, that would mean that this operation is for the child element with a particular parent
    let newList = JSON.parse(JSON.stringify(lstOptions))
    newList.filter(item => item.subscribed).map(option => {
        if ((payload?.value && payload?.value == option.value && option.display) || (!payload?.value && option.display) || (payload?.value && !option.value && option.display)) {
            option.active = isActive
        } else {
            option.active = option.active // keep it the same if the above conditions for change don't match
        }
        if (payload?.child != null && option[payload?.child].length) {
            let updatedChildren = []
            if ((payload?.filter === "includeCategories" || payload?.filter === "excludeCategories") && payload?.selectedData?.length > 0 && payload?.selectedData.includes(option.value) && option.display && option[payload.child].every(child => !child.display)) {
                option[payload.child].map(item => {
                    item.active = isActive
                    updatedChildren.push(item)
                })
            }
            else if ((payload?.filter === "includeStages" || payload.filter === "advanceExcludeStages") && payload?.selectedData?.length > 0 && payload?.selectedData.flat().includes(option.value) && option.display && option[payload.child].every(child => !child.display)) {
                option[payload.child].map(item => {
                    item.active = isActive
                    updatedChildren.push(item)
                })
            }
           
            else if ((payload?.filter === "includeStages" || payload.filter === "advanceExcludeStages") && !isActive && option.display && option[payload.child].every(child => !child.display)) {
                option[payload.child].map(item => {
                    item.active = isActive
                    updatedChildren.push(item)
                })
            }
            else if ((payload?.filter === "includeCategories" || payload?.filter === "excludeCategories") && !isActive && option.display && option[payload.child].every(child => !child.display)) {
                option[payload.child].map(item => {
                    item.active = isActive
                    updatedChildren.push(item)
                })
            }

            else {
                option[payload?.child].map(item => {
                    if (payload?.selectedData?.length > 0 && isActive) {
                        const isSubscribed = item.subscribed;
                        const hasCurrentId = payload?.value;
                        const isChild = payload?.isChild;
                        const currentIdMatchesItem = hasCurrentId && payload.value == item.value;
                        const currentIdMatchesOption = hasCurrentId && payload.value == option.value;
                        const displayCondition = item.display;
                        const filterConditionStages = (payload?.filter === "includeStages" || payload.filter === "advanceExcludeStages") && item.value.every(val => payload?.selectedData.flat().includes(val));
                        const filterConditionCategories = (payload?.filter === "includeCategories" || payload?.filter === "excludeCategories") && payload?.selectedData.includes(item.value);
                        const arrayCurrentIdMatch = Array.isArray(payload?.value) && payload?.value.every((element, index) => element === item.value[index]);
                        if (
                            (isSubscribed && currentIdMatchesItem && displayCondition && isChild) ||
                            (isSubscribed && currentIdMatchesOption && displayCondition && (filterConditionStages || filterConditionCategories)) ||
                            (isSubscribed && !hasCurrentId && displayCondition && (filterConditionStages || filterConditionCategories)) ||
                            (isSubscribed && displayCondition && isChild && hasCurrentId && arrayCurrentIdMatch)
                        ) {
                            item.active = isActive
                        }
                    }

                    else if ((item.subscribed && payload?.value && payload?.isChild && payload?.value == item.value && item.display) ||
                        (item.subscribed && payload?.value && payload?.value == option.value && item.display) ||
                        (item.subscribed && payload?.value && payload?.value == option.value && option.display) ||
                        (item.subscribed && !payload?.value && item.display) ||
                        (item.subscribed && item.display && payload?.isChild && payload?.value && Array.isArray(payload?.value)
                            && payload?.value.every((element, index) => element === item.value[index]))
                    ) {
                        item.active = isActive
                    } else {
                        item.active = item.active // keep it the same if the above conditions for change don't match
                    }
                    updatedChildren.push(item)
                })
            }

            option[payload?.child] = updatedChildren
            if (option[payload?.child].every(item => item.active)) {
                option.active = true
                option.isChildSelected = false
            } else if (option[payload?.child].every(item => !item.active)) {
                option.active = false
                option.isChildSelected = false
            } else {
                option.active = false
                option.isChildSelected = true
            }
        }
    })
    return newList
}

export function updateMaterialInput(value, isActive, option, updatedActive, selectedData) {
    let newList = JSON.parse(JSON.stringify(option))
    newList.map(item => {
        if (item.value === value || Array.isArray(value) && value.length > 0 && value?.includes(item.value)) {
            let isAllSubParentSelected = true;
            item.active = item.display ? isActive : item.active
            if (item.subParent.every(subPar => !subPar.display)) {
                isAllSubParentSelected = false
            }
            item.subParent.map(subItem => {
                subItem.active = subItem.display || item.display ? isActive : subItem.active
                if (item.display && !isAllSubParentSelected && subItem.children.every(child => !child.display)) {
                    subItem.children.map(child => {
                        child.active = isActive
                    })
                }
                else {
                    subItem.children.map(child => {
                        if (selectedData?.length > 0 && isActive) {
                            child.active = (selectedData.includes(child.value) && (child.display || subItem.display || item.display)) ? isActive : child.active
                        }
                        else {
                            child.active = (child.display || subItem.display || item.display) ? isActive : child.active
                        }
                    })
                }
                if (subItem.children.every(child => child.active)) {
                    subItem.active = true
                    subItem.isChildSelected = false
                    updatedActive.isActive = true
                } else if (subItem.children.every(child => !child.active)) {
                    subItem.active = false
                    subItem.isChildSelected = false
                } else {
                    subItem.active = false
                    subItem.isChildSelected = true
                    updatedActive.isActive = true
                }
            })
        }
        else {
            item.subParent.map(subItem => {
                if (subItem.value === value ||  Array.isArray(value) && value.length > 0 && value?.includes(subItem.value)) {
                    subItem.active = subItem.display || item.display ? isActive : subItem.active
                    subItem.children.map(child => {
                        if(selectedData?.length > 0 && isActive) {
                        child.active = (selectedData.includes(child.value) && (child.display || subItem.display || item.display)) ? isActive : child.active
                        }
                        else{
                            child.active = (child.display || subItem.display || item.display) ? isActive : child.active
                        }
                    })
                } else {
                    subItem.children.map(child => {
                        if (child.value === value || Array.isArray(value) && value.length > 0 && value?.includes(child.value)) {
                            child.active = child.display ? isActive : child.active
                        }
                    })
                }
                if (subItem.children.every(child => child.active)) {
                    subItem.active = true
                    subItem.isChildSelected = false
                    updatedActive.isActive = true
                } else if (subItem.children.every(child => !child.active)) {
                    subItem.active = false
                    subItem.isChildSelected = false
                } else {
                    subItem.active = false
                    subItem.isChildSelected = true
                    updatedActive.isActive = true
                }
            })
        }

        if (item.subParent.every(subItem => subItem.active)) {
            item.active = true
            item.isChildSelected = false
            updatedActive.isActive = true
        } else if (item.subParent.some(subItem => subItem.isChildSelected)) {
            item.active = false
            item.isChildSelected = true
            updatedActive.isActive = true
        } else if (item.subParent.every(subItem => !subItem.active)) {
            item.active = false
            item.isChildSelected = false
        } else {
            item.active = false
            item.isChildSelected = true
            updatedActive.isActive = true
        }
    })
    return newList
}

function getUpdateFilter(noOfDays, appliedFilter) {
    let options = getUpdateOrNewfilterOption(noOfDays, appliedFilter)
    let updateFilter = {
        "title": "updated",
        "active": appliedFilter.updated ? appliedFilter.updated.active : false,
        "options": options['options'],
        "toggleActive": appliedFilter.updated ? appliedFilter.updated.toggleActive : false,
        "limit": options['sliderLimit']
    }
    return updateFilter
}

export function getNoOfDays(last_published) {
    let date1 = new Date();
    // let date1 = moment(now, 'DD MM YYYY hh:mm:ss')
    let date2 = new Date(last_published)

    // To calculate the time difference of two dates
    let Difference_In_Time = date1.getTime() - date2.getTime();

    // To calculate the no. of days between two dates
    let Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24));
    return Difference_In_Days
}

export function getSliderNoOfDays() {
    let date = {}

    let daysInLastFiveYear = YEAR * 5
    let daysInLastFourYear = YEAR * 4
    let daysInLastThreeYear = YEAR * 3
    let daysInLastTwoYear = YEAR * 2
    let daysInYear = YEAR
    let daysInSixMonths = MONTH * 6
    let daysInThreeMonths = MONTH * 3
    let daysInTwoMonths = MONTH * 2
    let daysInOneMonth = MONTH
    let daysInTwoWeeks = WEEK * 2
    let daysInOneWeek = WEEK
    date = {
        lastFiveYear: daysInLastFiveYear,
        lastFourYear: daysInLastFourYear,
        lastThreeYear: daysInLastThreeYear,
        lastTwoYear: daysInLastTwoYear,
        lastYear: daysInYear,
        sixMonths: daysInSixMonths,
        threeMonths: daysInThreeMonths,
        twoMonths: daysInTwoMonths,
        oneMonth: daysInOneMonth,
        twoWeeks: daysInTwoWeeks,
        oneWeek: daysInOneWeek,
        lastDay: 1
    }
    return date
}

function getDate(noOfDays) {
    let subscriptionDate = moment().subtract(noOfDays, 'days').format('DD MMMM YYYY')
    return " since: " + subscriptionDate
}

function getUpdateOrNewfilterOption(noOfDays, appliedFilter) {
    let date = getSliderNoOfDays()
    let options = []
    let sliderValues = {}
    let sliderMarks = ''
    if (noOfDays > date['lastFourYear']) {
        options.push({
            "value": YEAR * 5,
            // "disabled": (noOfDays <= date['lastFiveYear'] && noOfDays > date['lastFourYear']),
            "disabled": false,
            "name": (noOfDays <= date['lastFiveYear'] && noOfDays > date['lastFourYear']) ? getDate(noOfDays) : "in the last 5 years",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 5 years", appliedFilter.updated.options) :
                (noOfDays > date['lastFourYear'] && noOfDays <= date['lastFiveYear']) ? true : false,
            restrictedLimit: 11,
            sliderValue: [-11]
        })
        sliderMarks = { value: "5 years", key: "-11" }
    }
    if (noOfDays > date['lastThreeYear']) {
        options.push({
            "value": YEAR * 4,
            "disabled": (noOfDays <= date['lastFourYear'] && noOfDays > date['lastThreeYear']),
            "name": (noOfDays <= date['lastFourYear'] && noOfDays > date['lastThreeYear']) ? getDate(noOfDays) : "in the last 4 years",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 4 years", appliedFilter.updated.options) :
                (noOfDays > date['lastThreeYear'] && noOfDays <= date['lastFourYear']) ? true : false,
            restrictedLimit: 10,
            sliderValue: [-10]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "4 years", key: "-10" }
    }
    if (noOfDays > date['lastTwoYear']) {
        options.push({
            "value": YEAR * 3,
            "disabled": (noOfDays <= date['lastThreeYear'] && noOfDays > date['lastTwoYear']),
            "name": (noOfDays <= date['lastThreeYear'] && noOfDays > date['lastTwoYear']) ? getDate(noOfDays) : "in the last 3 years",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 3 years", appliedFilter.updated.options) :
                (noOfDays > date['lastTwoYear'] && noOfDays <= date['lastThreeYear']) ? true : false,
            restrictedLimit: 9,
            sliderValue: [-9]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "3 years", key: "-9" }
    }
    if (noOfDays > date['lastYear']) {
        options.push({
            "value": YEAR * 2,
            "disabled": (noOfDays <= date['lastTwoYear'] && noOfDays > date['lastYear']),
            "name": (noOfDays <= date['lastTwoYear'] && noOfDays > date['lastYear']) ? getDate(noOfDays) : "in the last 2 years",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 2 years", appliedFilter.updated.options) :
                (noOfDays > date['lastYear'] && noOfDays <= date['lastTwoYear']) ? true : false,
            restrictedLimit: 8,
            sliderValue: [-8]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "2 years", key: "-8" }
    }
    if (noOfDays > date['sixMonths']) {
        options.push({
            "value": YEAR,
            "disabled": (noOfDays <= date['lastYear'] && noOfDays > date['sixMonths']),
            "name": (noOfDays <= date['lastYear'] && noOfDays > date['sixMonths']) ? getDate(noOfDays) : "in the last year",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last year", appliedFilter.updated.options) :
                (noOfDays > date['sixMonths'] && noOfDays <= date['lastYear']) ? true : false,
            restrictedLimit: 7,
            sliderValue: [-7]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "1 year", key: "-7" }
    }
    if (noOfDays > date['threeMonths']) {
        options.push({
            "value": MONTH * 6,
            "disabled": (noOfDays <= date['sixMonths'] && noOfDays > date['threeMonths']),
            "name": (noOfDays <= date['sixMonths'] && noOfDays > date['threeMonths']) ? getDate(noOfDays) : "in the last 6 months",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 6 months", appliedFilter.updated.options) :
                (noOfDays > date['threeMonths'] && noOfDays <= date['sixMonths']) ? true : true,
            restrictedLimit: 6,
            sliderValue: [-6]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "6 months", key: "-6" }
    }
    if (noOfDays > date['twoMonths']) {
        options.push({
            "value": MONTH * 3,
            "disabled": (noOfDays <= date['threeMonths'] && noOfDays > date['twoMonths']),
            "name": (noOfDays <= date['threeMonths'] && noOfDays > date['twoMonths']) ? getDate(noOfDays) : "in the last 3 months",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 3 months", appliedFilter.updated.options) :
                (noOfDays <= date['threeMonths'] && noOfDays > date['twoMonths']) ? true : false,
            restrictedLimit: 5,
            sliderValue: [-5]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "3 months", key: "-5" }
    }
    if (noOfDays > date['oneMonth']) {
        options.push({
            "value": MONTH * 2,
            "disabled": (noOfDays <= date['twoMonths'] && noOfDays > date['oneMonth']),
            "name": (noOfDays <= date['twoMonths'] && noOfDays > date['oneMonth']) ? getDate(noOfDays) : "in the last 2 months",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 2 months", appliedFilter.updated.options) :
                (noOfDays <= date['twoMonths'] && noOfDays > date['oneMonth']) ? true : false,
            restrictedLimit: 4,
            sliderValue: [-4]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "2 months", key: "-4" }
    }
    if (noOfDays > date['twoWeeks']) {
        options.push({
            "value": MONTH,
            "disabled": (noOfDays <= date['oneMonth'] && noOfDays > date['twoWeeks']),
            "name": (noOfDays <= date['oneMonth'] && noOfDays > date['twoWeeks']) ? getDate(noOfDays) : "in the last month",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last month", appliedFilter.updated.options) :
                (noOfDays <= date['oneMonth'] && noOfDays > date['twoWeeks']) ? true : false,
            restrictedLimit: 3,
            sliderValue: [-3]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "1 month", key: "-3" }
    }
    if (noOfDays > date['oneWeek']) {
        options.push({
            "value": 14,
            "disabled": (noOfDays <= date['twoWeeks'] && noOfDays > date['oneWeek']),
            "name": (noOfDays <= date['twoWeeks'] && noOfDays > date['oneWeek']) ? getDate(noOfDays) : "in the last 2 weeks",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last 2 weeks", appliedFilter.updated.options) :
                (noOfDays <= date['twoWeeks'] && noOfDays > date['oneWeek']) ? true : false,
            restrictedLimit: 2,
            sliderValue: [-2]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "2 weeks", key: "-2" }
    }
    if (noOfDays > date['lastDay']) {
        options.push({
            "value": 7,
            "disabled": (noOfDays <= date['oneWeek'] && noOfDays > date['lastDay']),
            "name": (noOfDays <= date['oneWeek'] && noOfDays > date['lastDay']) ? getDate(noOfDays) : "in the last week",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last week", appliedFilter.updated.options) : (noOfDays <= date['oneWeek']) ? true : false,
            restrictedLimit: 1,
            sliderValue: [-1]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "1 week", key: "-1" }
    }
    if (noOfDays >= date['lastDay']) {
        options.push({
            "value": 1,
            "disabled": (noOfDays == date['lastDay']),
            "name": (noOfDays == date['lastDay']) ? getDate(noOfDays) : "in the last day",
            "active": (appliedFilter.updated && appliedFilter.updated.active) ?
                isAppliedFilterActive("in the last day", appliedFilter.updated.options) :
                (noOfDays == date['lastDay']) ? true : false,
            restrictedLimit: 0,
            sliderValue: [0]
        })
        sliderMarks = sliderMarks ? sliderMarks : { value: "1 day", key: "0" }
    }
    sliderValues = {
        'options': options,
        'sliderLimit': sliderMarks
    }
    return sliderValues
}

function getNewFilter(noOfDays, appliedFilter) {
    let options = getUpdateOrNewfilterOption(noOfDays, appliedFilter);
    let newFilter = {
        "title": "new",
        "active": appliedFilter.new ? appliedFilter.new.active : false,
        "options": options['options'],
        "toggleActive": appliedFilter.new ? appliedFilter.new.toggleActive : false,
        "limit": options['sliderLimit']
    }
    return newFilter
}

function getCalendarStarDateFilter(appliedFilter) {
    return {
        "title": "calendarStartDate",
        "active": appliedFilter.calendarStartDate ? appliedFilter.calendarStartDate.active : false,
        "from": appliedFilter.calendarStartDate ? appliedFilter.calendarStartDate.from : "",
        "to": appliedFilter.calendarStartDate ? appliedFilter.calendarStartDate.to : ""
    }
}

function getCalendarEndDateFilter(appliedFilter) {
    return {
        "title": "calendarEndDate",
        "active": appliedFilter.calendarEndDate ? appliedFilter.calendarEndDate.active : false,
        "from": appliedFilter.calendarEndDate ? appliedFilter.calendarEndDate.from : "",
        "to": appliedFilter.calendarEndDate ? appliedFilter.calendarEndDate.to : ""
    }
}

function getCompleteExcludeStageFilter(appliedFilter) {
    return {
        "title": "excludeStages",
        "active": appliedFilter.excludeStages ? appliedFilter.excludeStages.active : false,
        "toggleActive": appliedFilter.excludeStages ? appliedFilter.excludeStages.toggleActive : false,
        "options": [
            { "value": [19, 20], "name": "withdrawn and abandoned", "active": appliedFilter.excludeStages && appliedFilter.excludeStages.options ? appliedFilter.excludeStages.options[0].active : true }
        ],
        "isAllSelected": false
    }
}

export function updateMaterialFilterActiveState(isActive, options) {
    let newList = JSON.parse(JSON.stringify(options))
    newList.map(item => {
        item.active = item.display ? isActive : item.active
        item.isChildSelected = false
        item.subParent.map(subItem => {
            subItem.active = subItem.display ? isActive : subItem.active
            subItem.isChildSelected = false
            subItem.children.map(child => {
                child.active = child.display ? isActive : child.active
            })
        })
    })
    return newList;
}

export function clearAllRolesBeforeSettingFavouriteRoles(clearallRolesList) {
    let listOfOptions = clearallRolesList.options
    let newclearallRolesList = JSON.parse(JSON.stringify(listOfOptions))
    newclearallRolesList.map(option => {
        option.active = false
    })
    clearallRolesList.options = newclearallRolesList
    return JSON.parse(JSON.stringify(clearallRolesList))
}

export function updateActiveStatusOnFavouriteRoles(listOfOptions, isActive, listOfItemsToSelect) {
    let newList = JSON.parse(JSON.stringify(listOfOptions))
    newList.map(option => {
        if (listOfItemsToSelect.includes(option.value)) {
            option.active = isActive
        }
    })
    return newList
}