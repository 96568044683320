import React, { useState, useRef, useEffect } from 'react';
import { Col, ListGroup, Dropdown, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, InputGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, isDST, generateCalendarLinks } from '../../utils/commonFunctions';
import ColoredScrollbars from '../common/js/coloredScrollbars';
import Icon from '../common/js/icon';
import noevent from '../common/images/no-events.svg'
import CalendarModal from '../modal/calendar';
import moment from 'moment';
import { displayCurrency, getFormatedDate } from '../../utils';
import { deleteEvent } from '../details/apiclient';
import { addSelectedEvent, removeSelectedEvent, updateCalendarOffsetLimitValue, updateIsRefine } from './reducer';
import { fetchCalendarEvents } from './apiclient';
import { summaryActivityDetail, updateActivityIsClicked } from '../details/redux/reducer';

const CalendarTable = (props) => {

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [events, setEvents] = useState([])
    const project = useSelector((state) => state.project)
    const [isEditEvent, setIsEditEvent] = useState(false)
    const [eventDetails, setEventDetails] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [payload, setPayload] = useState({})
    const [selectedValue, setSelectedValue] = useState("Add to calendar");
    const [rowIndex, setRowIndex] = useState('')
    const userData = useSelector(state => state.user.user)
    const calendarEventState = useSelector(state => state.calendar)
    const dispatch = useDispatch();
    let navigate = useNavigate()
    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    // const toggleDropdown = (index) => {
    //     setDropDownOpen(!dropDownOpen);
    // };

    const toggleDropdown = (index) => {
        setDropDownOpen(!dropDownOpen)
        setRowIndex(index)
    }

    const handleEdit = (eventDetails) => {
        setIsEditEvent(true)
        setEventDetails(eventDetails)
        toggleModal()
    }

    const handleDelete = (payload) => {
        if (payload.eventId !== 0) {
            setShowDeleteAlert(true)
            toggleModal()
            setPayload(payload)
        }
    }

    const addToCalendar = (value) => {
        setSelectedValue(value);
    }

    const handleDropdownLinkClick = (e) => {
        e.preventDefault();
        var url = e.currentTarget.getAttribute("href");

        if (url.startsWith("data") || url.startsWith("BEGIN")) {
            var filename = "download.ics";
            var blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

            if (typeof window !== "undefined" && window.navigator.msSaveOrOpenBlob && window.Blob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            window.open(url, "_blank");
        }
    }

    const generateCalendarEvent = (event) => {
        var eventDetails = {}
        if (event !== undefined) {
            eventDetails = {
                title: event?.subject,
                description: additionalDetailsInDescription(event),
                location: event?.location ? event?.location : '',
                startTime: event?.start_on ? isDST(event?.start_on) ? moment(event?.start_on).subtract('1', 'hour') : event?.start_on :
                    event?.end_on ? isDST(event?.end_on) ? moment(event?.end_on).subtract('2', 'hour') : moment(event?.end_on).subtract('1', 'hour') : '',
                endTime: event?.end_on ? isDST(event?.end_on) ? moment(event?.end_on).subtract('1', 'hour') : event?.end_on :
                    event?.start_on ? isDST(event?.start_on) ? event?.start_on : moment(event?.start_on).add('1', 'hour') : ''
            }
        }
        return eventDetails
    }

    const additionalDetailsInDescription = (event) => {
        var description = ''
        description += event?.description ? event?.description + '\n\n' : ''
        if (event?.object_type === 'project') {
            description += 'Related Project\n'
            description += '\nProject ID:           ' + event?.project_id
            description += '\nProject:                ' + event?.project_title
            description += event?.project_value ? '\nValue:                  ' + displayCurrency(event?.project_value, event?.project_value_estimated) : ''
            description += event?.project_site3 ? '\nLocation:             ' + `${event?.project_site3}, ${event?.project_postcode}` : ''
            description += event?.project_last_published ? '\nLast published:  ' + getFormatedDate(event?.project_last_published, 'DD-MMM-YYYY') : ''
        } else {
            description += 'Related Company\n'
            description += event?.company_id ? '\nCompany ID:  ' + event?.company_id : ''
            description += event?.company_name ? '\nCompany:       ' + event?.company_name : ''
            description += event?.company_address4 ? '\nLocation:        ' + `${event?.company_address4}, ${event?.company_postcode}` : ''
            description += event?.company_phone ? '\nTelephone:    ' + event?.company_phone : ''
        }
       
        return description
    }

    const handleAddEvent = () => {
        setIsEditEvent(false)
        toggleModal();
    }

    const toggleEventSelection = (eventId) => {
        if (calendarEventState?.selectedEvents?.includes(eventId)) {
            dispatch(removeSelectedEvent(eventId));
        } else {
            dispatch(addSelectedEvent(eventId));
        }
    };

    useEffect(() => {
        fetchCalendarEvents(calendarEventState, dispatch)
    }, [calendarEventState?.offsetLimitValue])

    useEffect(() => {
        if (calendarEventState?.eventList?.length >= 0) {
            setEvents(calendarEventState?.eventList)
        }
    }, [calendarEventState?.eventList])

    useEffect(() => {
        if (showDeleteAlert) {
            const timer = setTimeout(() => {
                deleteEvent(payload.object_id, payload.object_type, payload.eventId, userData, dispatch, calendarEventState)
                setShowDeleteAlert(false)
                setPayload({})
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [showDeleteAlert, payload])

    const undoDelete = () => {
        setShowDeleteAlert(false)
    }

    const handleViewDetails = (e, calendarEvent) => {
        e.preventDefault()
        let routingState = {
            prevNextList: "calendarEvents",
            showMoreResult: false,
            index: 0,
            list: [],
            activeTab: calendarEvent.object_type === "project" ? '4' : '6'
        }
        dispatch(summaryActivityDetail("calendar_events"))
        dispatch(updateActivityIsClicked(true))
        if (calendarEvent.object_id && calendarEvent.object_type) {
            if (calendarEvent.object_type === "project") {
                navigate(`/app/project/` + calendarEvent.object_id, { state: routingState })
            } else if (calendarEvent.object_type === "company") {
                navigate(`/app/company/0/` + calendarEvent.object_id, { state: routingState })
            }
        }
    }


    const handleShowMore = () => {
        dispatch(updateIsRefine(true))
        let newOffset = calendarEventState?.offsetLimitValue?.offset + calendarEventState?.offsetLimitValue?.records;
        dispatch(updateCalendarOffsetLimitValue({ offset: newOffset, records: calendarEventState?.offsetLimitValue?.records }));
    }

    const renderShowMore = () => {
        return (
            calendarEventState?.calendarEventListCount > events?.length ? (
                <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                </div>
            ) : ('')
        )
    }

    const renderListItems = () => {
        return (
            <>
                {
                    events?.length > 0 ?
                        events?.map((event, index) => (
                            <li className="list-group-item" key={event?.event_id}>
                                <div className="calendar-container">
                                    <label className='container'>
                                        <Input type="checkbox" value={event?.event_id} id={'event_' + event?.event_id}
                                            onChange={() => toggleEventSelection(event?.event_id)} checked={calendarEventState?.selectedEvents?.includes(event?.event_id)} />
                                        <span class="checkmark" for={'event_' + event?.event_id}></span>
                                    </label>
                                    <div className="listviewtable-block">
                                        <div className="list-table">
                                            <div className="list-tablerow d-flex">
                                                <div className="list-col align-self-top d-inline-flex w-30">
                                                     <span class={"event-type-icon " + event.type.toLowerCase().replaceAll(' ','-') +"-icon"}></span>
                                                    <div>
                                                        <h5 className="result-title-name mb-0 text-wrap">{event?.subject}</h5>
                                                        <p class="calendar-name">
                                                            <Link
                                                                onClick={(e) => handleViewDetails(e, event)}>
                                                                {event.object_type == 'project' ? event?.project_title : event?.company_name}
                                                            </Link>
                                                        </p>
                                                        <p class="calendar-description">{event?.description}</p>
                                                    </div>
                                                </div>
                                                <div className="list-col align-self-center w-10">{capitalizeFirstLetter(event?.type)}</div>
                                                <div className="list-col align-self-center w-10"><span className={event?.priority + "-priority priority-tag"}>{capitalizeFirstLetter(event?.priority)}</span></div>
                                                <div className="list-col align-self-center w-10">{capitalizeFirstLetter(event?.status)}</div>
                                                <div className="list-col align-self-center w-20">
                                                    <div className="d-flex item-event-time justify-content-between align-items-center">
                                                        <div>
                                                            {(event?.start_on && event?.end_on && moment.utc(event?.start_on).format('DD-MMM-YYYY') === moment.utc(event?.end_on).format('DD-MMM-YYYY')) ?
                                                                <>
                                                                    <p className="mb-0">{event?.start_on ? moment.utc(event?.start_on).format('DD-MMM-YYYY') : ("")}  </p>
                                                                    <p>{event?.start_on ? moment.utc(event?.start_on).format('HH:mm') : ("")}{event?.start_on && event?.end_on ? ' to ' : ''}{event?.end_on ? moment.utc(event?.end_on).format('HH:mm') : ("")}</p>
                                                                </>
                                                                :
                                                                <>
                                                                    <p className="mb-0">{event?.start_on ? moment.utc(event?.start_on).format('DD-MMM-YYYY HH:mm') : ("")}
                                                                        {event?.start_on && event?.end_on ? ' to' : ''} </p>

                                                                    <p className="mb-0">{event?.end_on ? moment.utc(event?.end_on).format('DD-MMM-YYYY HH:mm') : ("")}
                                                                    </p></>}
                                                        </div>

                                                        {(event?.start_on || event?.end_on) ?
                                                            <div class="event-scheduled-time d-flex align-items-center">
                                                                {(event?.start_on || event?.end_on) ?
                                                                    <Dropdown isOpen={dropDownOpen && index === rowIndex} toggle={() => toggleDropdown(index)}>
                                                                        <DropdownToggle caret>Add to calendar</DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {generateCalendarLinks(generateCalendarEvent(event)).map((optionItem) => {
                                                                                return <DropdownItem ><a href={optionItem.value} target="_blank" onClick={handleDropdownLinkClick}>{optionItem.title}</a></DropdownItem>
                                                                            })}
                                                                        </DropdownMenu>
                                                                    </Dropdown> : ''}
                                                            </div> : ("")}
                                                    </div>
                                                </div>
                                                <div className="list-col align-self-center text-center w-15">{event?.reminder_on ? <div class="d-inline-flex align-items-center mr-1"><span className="clock-icon"></span> {moment.utc(event?.reminder_on).format('DD-MMM-YYYY HH:mm')} </div> : ""}</div>
                                                <div className="list-col align-self-center text-center w-5"><button className="edit-button" onClick={() => handleEdit(event)}><Icon color="#053874" size={20} icon="icon-gen-edit" className="me-1" /></button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))
                        : (
                            <div className="no-events text-center p-5">
                                <img className="mt-4" src={noevent} />
                                <h6 className="mb-1 mt-4 text-bold">You have no events</h6>
                                <p>You can add events such as tasks and meetings in<br></br> project and company details.</p>
                            </div>
                        )
                }

            </>
        )
    }

    return (
        <>
            <div className={"delete-alert " + (showDeleteAlert ? 'show' : 'hide')}>
                <span>Event deleted</span><button onClick={undoDelete}>Undo</button>
            </div>
            <div>
                <CalendarModal isOpen={isOpen} toggleModal={toggleModal} isEditEvent={isEditEvent}
                    eventDetails={eventDetails} handleDelete={handleDelete} prevNextList="calendarPage" />
                <Row>
                    <div className='search-result list-view-panel'>
                        <div className='result-wrapper'>
                            <div className="result-list-container content-panel">
                                <div className="listviewtable-block bggray lr-spaceHierachy">
                                    <div className="list-table">
                                        <div className="list-header d-flex">
                                            <div className="list-col align-self-center w-30"><b>Subject/Description</b></div>
                                            <div className="list-col align-self-center w-10"><b>Type</b></div>
                                            <div className="list-col align-self-center w-10"><b>Priority</b></div>
                                            <div className="list-col align-self-center w-10"><b>Status</b></div>
                                            <div className="list-col align-self-center w-20"><b>Timing</b></div>
                                            <div className="list-col align-self-center text-center w-15"><b>Reminder</b></div>
                                            <div className="list-col align-self-center text-center w-5"><b>Action</b></div>
                                        </div>
                                    </div>
                                </div>

                                <ListGroup style={{ marginBottom: '10px' }}>
                                    <ColoredScrollbars>
                                        {renderListItems()}
                                        {renderShowMore()}
                                    </ColoredScrollbars>
                                </ListGroup>

                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    )
}

export default CalendarTable;

