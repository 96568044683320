import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../common/js/icon';
import { updateMyLeadOffsetLimitValue } from '../../myleads/reducer';
import { fetchSearchListCount } from '../../search/apiclient';
import { preProcessorQuery } from '../../search/filters/preProcessorQuery';
import { updateOffsetLimit } from '../../search/filters/redux/reducer';
import { updateBackObjectId, updateHierarchyBack } from '../../search/redux/reducer';
import { updateShowMoreOffsetLimit } from '../redux/reducer';


const NavigationTab = () => {
    const search = useSelector((state) => state.search)
    const myleads = useSelector((state) => state.myleads)
    const details = useSelector((state) => state.project)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const filter = useSelector((state) => state.filter)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [index] = useState(location.state?.index);
    const [fetchNext, setFetchNext] = useState(false);
    const [showMoreResult, setShowMoreResult] = useState(false);
    const [nextButton, setNextButton] = useState(false)
    const [leadFlags, setLeadFlags] = useState(location.state?.list)
    const [showMoreActive, setShowMoreActive] = useState(false)

    const handleBack = () => {
        if (['searchList', 'contactHierarchicalSearchList'].includes(location.state?.prevNextList)) {
            dispatch(updateBackObjectId(location.pathname.split('/')[3]))
            dispatch(updateHierarchyBack(true))
            navigate('/app/list')
        } else {
            const routingState = {
                ...location.state?.previousState,
                activeTab: location.state?.tab
            };
            if (location.state?.prevNextList === 'projectPeopleList') {
                navigate(`/app/project/${location.state?.project_id}`, { state: routingState })
            } else {
                navigate(`/app/company/${location.state?.project_id}/${location.state?.company_id}`, { state: routingState })
            }
        }
    }

    useEffect(() => {
        handleListAndShowMore(false)
    }, [location])

    useEffect(() => {
        if (showMoreResult) {
            var formValues = preProcessorQuery('', filter.filtersState, searchPersist.appliedContext, search, searchPersist)
            fetchSearchListCount(formValues, dispatch, search, settings);
            setShowMoreResult(false)
        }
    }, [filter.filtersState[searchPersist.appliedContext].offset]);

    useEffect(() => {
        if (fetchNext) {
            handleListAndShowMore(true)
        }
    }, [search.searchList])

    useEffect(() => {
        if (fetchNext) {
            handleNext()
        }
        setFetchNext(false);
    }, [leadFlags])

    useEffect(() => {
        if (fetchNext) {
            manageMoreResult();
        }
    }, [fetchNext]);

    const handleListAndShowMore = (isListRefresh) => {
        let list = leadFlags
        if (isListRefresh) {
            list = location.state?.prevNextList === 'searchList' ? search?.searchList
                : location.state?.prevNextList === 'contactHierarchicalSearchList' ? search.peopleDetails
                    : location.state?.prevNextList === 'projectPeopleList' ? details.peopleWorkingOnRole
                        : location.state?.prevNextList === 'peopleWorkingOnRole' ? details.peopleWorkingOnRole
                            : location.state?.prevNextList === 'peopleOnOtherProject' ? details.PeopleOnOtherProject
                                : location.state?.prevNextList === 'peopleOtherOrgRoles' ? details.OtherOrgRoles
                                    : []
            setLeadFlags(list)
        }
        let showMoreActiveFlag = location.state?.prevNextList === 'searchList' ? search.isShowMoreActive : false
        setShowMoreActive(showMoreActiveFlag)
        setNextButton(list?.length === 0 || list === undefined ? true :
            (!showMoreActiveFlag && list?.length > 0
                && list[list.length - 1].person_id?.toString() === location.pathname.split('/')[3])
            && list.length - 1 === location.state?.index)
    }

    const handlePrevious = () => {
        let prevObject = {}

        if (leadFlags && leadFlags[index - 1] != undefined) {
            prevObject = leadFlags[index - 1]
            const routingState = {
                ...location.state,
                index: index - 1
            };
            navigate(`/app/person/${prevObject.person_id}`, { state: routingState })
        }
    }

    const handleNext = () => {
        let nextObject = {};

        if (leadFlags && leadFlags[index + 1] !== undefined) {
            nextObject = leadFlags[index + 1];
            const routingState = {
                ...location.state,
                index: index + 1
            };
            navigate(`/app/person/${nextObject.person_id}`, { state: routingState })
        } else {
            if (showMoreActive) {
                setFetchNext(true);
                setShowMoreResult(true)
            }
        }
    };

    const manageMoreResult = () => {
        let newOffset = ''
        if (['groupedCompanyProjects', 'jAskGroupedCompanyProjects'].includes(location.state?.prevNextList)) {
            let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
            dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
        } else if (location.state?.prevNextList === 'myLeadsFavourites' || location.state?.prevNextList === 'myLeadsTags' || location.state?.prevNextList === 'myLeadsHistory' || location.state?.prevNextList === 'myLeadsArchive' || location.state?.prevNextList === 'myLeadsShares' || location.state?.prevNextList === 'myLeadsUpdates') {
            newOffset = myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records;
            dispatch(updateMyLeadOffsetLimitValue({ offset: newOffset, records: myleads.offsetLimitValue.records }));
        } else {
            newOffset = filter.filtersState[searchPersist.appliedContext].offset + filter.filtersState[searchPersist.appliedContext].records;
            dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[searchPersist.appliedContext].records }))
            dispatch(updateHierarchyBack(false))
        }
    }

    return (
        <div className="dp-nav-buttons-wrapper d-flex justify-content-end">
            <div>
                <button
                    className='user-ctrl-btn back-to-resultpage-button' disabled={index === undefined } onClick={handleBack}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" />Back</button>
            </div>
            <div className="right-side-button d-inline-flex">
                <button
                    className='user-ctrl-btn dp-prev-button' disabled={index === 0 || index === undefined } onClick={handlePrevious}><Icon color="#053874" size={20} icon="icon-gen-chevron" className="me-1 flip-vertically" />Previous</button>
                <button
                    className='user-ctrl-btn dp-next-button' disabled={nextButton} onClick={handleNext}>Next<Icon color="#053874" size={20} icon="icon-gen-chevron" className="ms-1" /></button>
            </div>
        </div>
    );
}

export default NavigationTab;