import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { getFormatedDate } from '../../utils';
import CommonPlaceholder from './framework/commonPlaceholder';
import Icon from '../common/js/icon';
import { fetchDetailUpdatesData } from './apiclient';

const UpdateTab = (props) => {
    const project = useSelector((state) => state.project)
    const [updateList, setUpdateList] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!updateList && (props.activeTab === 3 && project?.detailsPage.objectType === 'project' || project?.detailsPage.objectType === 5 && props.type === 'company')) {
            fetchDetailUpdatesData(project?.detailsPage.objectId, project?.detailsPage.objectType, dispatch)
        }
    }, [])

    useEffect(() => {
        setUpdateList(project.detailsUpdatesTab)
    }, [project.detailsUpdatesTab])

    return (
        <>
            {
                project && project.detailsUpdatesTab && project.detailsUpdatesTab.length ? (
                    <div className="updates-details">
                        {

                            project && project.detailsUpdatesTab && project.detailsUpdatesTab.map(detail => (
                                <div className="update-list">
                                    <span className="update-desc"><Icon color="#b0b9c6" size={30} icon='icon-gen-bell-1' className="me-1" /> {detail.description}</span>
                                    <span className="time">{getFormatedDate(detail.updated_on, 'DD-MMM-YYYY')}</span>
                                </div>
                            ))
                        }
                    </div>
                )

                    :
                    updateList === false ?
                        <CommonPlaceholder /> :
                        (<span> {project.detailsPage.objectType === 'project' ?
                            'No updates have been made to this project since publication.' :
                            'No updates have been made to this company since publication.'}</span>)
            }

        </>
    )
}

export default UpdateTab