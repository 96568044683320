import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button } from 'reactstrap'
import SearchInput from 'react-search-input'
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import Icon from '../../common/js/icon';
import { expandFilteredTags, filterTags } from '../../../utils';
import SearchListResult from '../../search/searchListResult';
import MyLeadsSearchSelector from '../selectorDropdown'
import MapView from '../../map';
import { updateTagId } from '../reducer';
import { deleteTag, fetchTagDetailsById } from '../apiclient';
import ProjectHierarchy from '../../search/hierarchyList/projectHierarchy';
import { updateLeadsAppliedContext } from '../../search/redux/searchPeristReducer';
import { fetchShareForObjectId } from '../../details/apiclient';


const Tags = (props) => {

    const user = useSelector((state) => state.user)
    const userData = useSelector((state) => state.user.user)
    const searchPersist = useSelector((state) => state.searchPersist)
    const project = useSelector((state) => state.project)
    const myleads = useSelector((state) => state.myleads)
    const settings = useSelector((state) => state.settings)
    const search = useSelector((state) => state.search)

    const [searchValue, setSearchValue] = useState("")
    const [defaultTagLists, setDefaultTagLists] = useState([])
    const [tagsdata, setTagsData] = useState([])
    const [entityFlag, setEntityFlag] = useState(false)
    const [isLabelShared, setIsLabelShared] = useState([])
    const [isLabelMyLabels, setIsLabelMyLabels] = useState([])
    const [deleteUserId, setDeleteUserId] = useState('')
    const [currentTagLabel, setCurrentTagLabel] = useState(myleads.tagName ? myleads.tagName : '')
    const [listMapToggle] = useState(myleads.isListView)
    const dispatch = useDispatch();

    useEffect(() => {
        setDefaultTagLists(myleads.tagList)
        setTagsData(myleads.tagList)
    }, [myleads.tagList])

    useEffect(() => {
        if (search?.fetchRolesForProjects?.roleGroups?.length > 0) {
            setEntityFlag(true)
        }
    }, [search.fetchRolesForProjects])

    const getSearchListResult = () => {
        return (<SearchListResult
            currentOffset={0}
            type={searchPersist.leadsAppliedContext.tags}
            prevNextList='myLeadsTags'
            activeTab={props.activeTab}
            isListMapButtonFlag={listMapToggle}
        />)
    }

    const onFilterMouseUp = (e) => {
        const filter = e.target.value.trim()
        setSearchValue(filter)
        if (!filter) {
            return setTagsData(defaultTagLists)
        }
        var nData = { 'tag_name': 'Tags', children: tagsdata }
        var filtered = filterTags(nData, filter)
        filtered = expandFilteredTags(filtered, filter)
        setTagsData(filtered.children)
    }

    const handleMaterialsDataClose = () => {
        setSearchValue("")
        setTagsData(defaultTagLists)
    }

    const handleDeleteButtonClick = (event, tagId) => {
        event.preventDefault();
        setDeleteUserId(tagId)
    }

    const handleCancel = () => {
        setDeleteUserId('')
    }

    const handletagDelete = (tagId) => {
        deleteTag(tagId, dispatch)
    }

    const handleTagClick = (e, id, label, tagcount, createdBy) => {
        props.onClickStatus(true)
        if (id) {
            dispatch(updateTagId({ id: id, createdByLoggedInUser: createdBy === userData.user_id, label: label }))
            setCurrentTagLabel(label)
            let detailType = tagcount > 0 ? 'project' : 'company'
            dispatch(updateLeadsAppliedContext({ activeTab: props?.activeTab, context: detailType }));
            fetchTagDetailsById(id, myleads, searchPersist, dispatch, detailType, null, settings)
            fetchShareForObjectId(id, 'tags', dispatch, user, userData)
        }
    }

    useEffect(() => {
        let isLabelShared = [];
        let isLabelMyLabels = [];

        tagsdata?.map(function (label, index) {
            if (label.is_shared) {
                isLabelShared.push(label.is_shared)
                setIsLabelShared(isLabelShared)
            }
            else {
                isLabelMyLabels.push(label.is_shared)
                setIsLabelMyLabels(isLabelMyLabels)
            }
        })
    }, [tagsdata])


    return (
        <>
            <div className={`main-label-wrapper tag-tab ${props.tagDetailStatus ? 'd-none' : 'tag-default'}`}>
                <Row className='searchBox mt-3'>
                    <Col xs="12" md='3' lg='3' sm="3">
                        <div className="input-group textfield-wrapper">
                            <Icon color="#ced4da" size={30} icon="icon-sb--search" className="search-icon" />
                            <SearchInput
                                placeholder="Find tags"
                                className="form-control border-top-0 border-bottom-0 border-end-0"
                                type="text"
                                onKeyUp={onFilterMouseUp}
                                value={searchValue} />
                            <Button className={`clear-textfield btn btn-secondary ${searchValue ? 'd-block' : 'd-none'}`} onClick={(e) => handleMaterialsDataClose(e)} tabIndex="-1">x</Button>
                        </div>
                    </Col>
                </Row>
                <div className="details-panel-scrollbar">
                    <ColoredScrollbars>
                        <div class="shared-labels-list">
                            <div className='tag-heading mb-2'>Shared tags</div>
                            <div className="list-wrapper">
                                <div>
                                    <div className='all-tags-div'>
                                        {
                                            isLabelShared.length > 0 ? (
                                                <div>
                                                    {tagsdata.length > 0 ?
                                                        (tagsdata && tagsdata.map(function (label, index) {
                                                            if (label.is_shared)
                                                                return (
                                                                    <span key={`label-list-${index}`} className='labels-list-wrapper'>
                                                                        <a className={`${label.is_shared ? 'shared-labels-list' : 'labels-list'}`} id={`label-${index}`} onClick={(e) => handleTagClick(e, label.tag_id, label.tag_name, label.count, label.created_by)}>
                                                                            <Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1" /> {label.tag_name} {(label.count) ? (' (' + label.count + ')') : ''}
                                                                        </a>
                                                                        {
                                                                            label.created_by === userData.user_id ?
                                                                                (
                                                                                    <div className='delete-cancel'>
                                                                                        <span className="remove-label" aria-hidden="true" onClick={(e) => handleDeleteButtonClick(e, label.tag_id)}></span>
                                                                                        <div className={`label-${index} ${deleteUserId === label.tag_id ? 'show' : 'hide'}`}>
                                                                                            <Button className="btn-label-cancel" onClick={handleCancel}>Cancel</Button><span></span>
                                                                                            <Button className="btn-label-delete" onClick={() => handletagDelete(label.tag_id)}>Delete</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : ('')
                                                                        }
                                                                    </span>)
                                                        })) : ("No tags found matching your search.")}
                                                </div>) : ('There are no shared tags')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mytags-labels-list mt-4">
                            <div className='tag-heading mb-2'>My tags</div>
                            <div className="list-wrapper">
                                <div>
                                    <div className='all-tags-div'>
                                        {
                                            isLabelMyLabels.length > 0 ? (<div>
                                                {tagsdata.length > 0 ?
                                                    (tagsdata && tagsdata.map(function (label, index) {
                                                        if (!label.is_shared)
                                                            return (
                                                                <span key={`label-list-${index}`} className='labels-list-wrapper'>
                                                                    <a className={`${label.is_shared ? 'shared-labels-list' : 'labels-list'}`} id={`label-${index}`} onClick={(e) => handleTagClick(e, label.tag_id, label.tag_name, label.count, label.created_by)}>
                                                                        <Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1" /> {label.tag_name} {(label.count) ? (' (' + label.count + ')') : ''}
                                                                    </a>
                                                                    {
                                                                        label.created_by === userData.user_id ?
                                                                            (
                                                                                <div className='delete-cancel'>
                                                                                    <span className="remove-label" aria-hidden="true" onClick={(e) => handleDeleteButtonClick(e, label.tag_id)}></span>
                                                                                    <div className={`label-${index} ${deleteUserId === label.tag_id ? 'show' : 'hide'}`}>
                                                                                        <Button className="btn-label-cancel" onClick={handleCancel}>Cancel</Button><span></span>
                                                                                        <Button className="btn-label-delete" onClick={() => handletagDelete(label.tag_id)}>Delete</Button>
                                                                                    </div>
                                                                                </div>
                                                                            ) : ('')
                                                                    }
                                                                </span>)
                                                    })) : ("No tags found matching your search.")}
                                            </div>) : ('You have no tags. To create one, click "Add tag" on projects and companies')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ColoredScrollbars>
                </div>
            </div>

            {props.activeTab === 'tags' && props.tagDetailStatus &&
                (<div className={`search-list-content`}>
                    <div className={`search-result ${myleads.isListView ? 'list-view-panel' : ''} `}>
                        <div className={`active page-title-bar pb-2 ${!myleads.isListView ? 'map-view-header' : ''}`}>
                            <div className='top-5'>
                                <div className="only-listview d-flex justify-content-between me-3">
                                    <MyLeadsSearchSelector prevNextList='myLeadsTags' activeTab={props.activeTab} />
                                    <div class="float-right tagNameCntr mr-3">
                                        <span>Tag name</span>
                                        <div class="tagNameTxt">{currentTagLabel}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row className="me-0">
                            <Col>
                                {
                                    myleads.isListView ?
                                        entityFlag ?  // check nested and hierarchical 
                                            (
                                                myleads.showProjectHierarchy && searchPersist.leadsAppliedContext.tags === 'project' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ?
                                                    <ProjectHierarchy listMapToggle={listMapToggle} type={searchPersist.leadsAppliedContext.tags} prevNextList="projectHierarchicalMyLeadsTags" />
                                                    : getSearchListResult()
                                            ) :
                                            getSearchListResult() : <MapView activeTab={props.activeTab} entityFlag={entityFlag}
                                                prevNextList={myleads.showProjectHierarchy && searchPersist.leadsAppliedContext.favourite === 'project' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ? 'projectHierarchicalMyLeadsTags' : 'myLeadsTags'} />
                                }
                            </Col>
                        </Row>
                    </div>
                </div>)
            }
        </>
    )
}

export default Tags