import React, { useEffect, useState } from 'react'
import classnames from 'classnames';
import _ from 'lodash';
import { Col, ListGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { useDispatch, useSelector } from 'react-redux';

import SearchSelector from '../list/searchSelector';
import { updateSelectorControlTags, updateSelectorFavUnfav } from '../apiclient';
import SearchListRow from '../list/searchListRow';
import { formatUrlWithProtocol, getFormatedPersonAddedDate } from '../../../utils';
import TagsModal from '../list/tagsModal';
import { companiesForPeopleWorkingOnProject, peopleWorkingOnRolesCompanies, updateFavListSelection, updateListSelection, updateSelectedTagDetails } from '../redux/reducer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { updateProjectHierarchicalRoles } from '../../search/redux/reducer';
import { setDataForCompanyDetails } from '../postprocessing';

const RoleCompanyTabs = (props) => {
    const project = useSelector((state) => state.project)
    const [roles, setRoles] = useState(project && project.fetchRolesForProjects)
    const [modal, setModal] = useState(false)
    const [totalSelectedCount, setTotalSelectedCount] = useState(0)
    const [isPdfActive, setIsPdfActive] = useState()
    const [peopleOnThisProject, setPeopleOnThisProject] = useState(project && project.peopleWorkingOnRole)
    const [companyForPeopleWorkingOnProject, setCompanyForPeopleWorkingOnProject] = useState({})
    let rolesData = project && project.fetchRolesForProjects
    const userData = useSelector(state => state.user.user)
    const settings = useSelector(state => state.settings)
    const [activeTab, setActiveTab] = useState()
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();

    const toggleTab = (tab) => {
        setActiveTab(tab)
        handleDropDownItemClick(0)
    }

    useEffect(() => {
        dispatch(peopleWorkingOnRolesCompanies([]))
        dispatch(companiesForPeopleWorkingOnProject({}))
    }, [])

    useEffect(() => {
        setRoles(project && project.fetchRolesForProjects)
        if (rolesData && rolesData.roleGroups && rolesData.roleGroups.length > 0) {
            let roleData = JSON.parse(JSON.stringify(rolesData));
            let allRoles = { 'groupName': 'All roles', roles: [] };
            allRoles.roles = roleData && roleData.roleGroups.reduce((acc, cur) => acc.concat(cur.roles), []);
            roleData.roleGroups.unshift(allRoles);
            if (settings && settings.preferences.hasOwnProperty('favourite_roles') && settings && settings.preferences.favourite_roles.length > 0) {
                let favRoles = { 'groupName': 'Favourite roles', roles: [] };
                allRoles.roles.forEach(x => {
                    if (settings && settings.preferences.favourite_roles.includes(x.roleNo)) {
                        favRoles.roles.push(x);
                    }
                });
                roleData.roleGroups.unshift(favRoles);
            }
            rolesData = roleData
            setRoles(rolesData)
            if (!activeTab) {
                setActiveTab(roleData ? roleData.roleGroups[0].groupName : '')
            }
            if (!activeTab) {
                setPeopleOnThisProject([])
                setCompanyForPeopleWorkingOnProject({})
            } else {
                setCompanyForPeopleWorkingOnProject(project && project.companyForPeopleWorkingOnProject)
                setPeopleOnThisProject(project && project.peopleWorkingOnRole)
            }
            setTotalSelectedCount(project && project.selectorControlSelectionCount === undefined ? totalSelectedCount : project && project.selectorControlSelectionCount)
        }

        let roles = []
        if (project?.fetchRolesForProjects?.roleGroups?.length > 0) {
            project.fetchRolesForProjects.roleGroups.map((roleGroup) => {
                if (roleGroup?.roles?.length > 0) {
                    roleGroup.roles.map((role) => {
                        if (role?.companies?.length > 0) {
                            role.companies.map(company => {
                                let payload = _.clone(company)
                                payload = setDataForCompanyDetails(payload)
                                roles.push(payload)
                            })
                        }
                    })
                }
            })
        }
        dispatch(updateProjectHierarchicalRoles(roles))
    }, [activeTab, project.fetchRolesForProjects, project.peopleWorkingOnRole, settings.preferences])

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setModal(false)
        handleDropDownItemClick(0)
    }

    const handleTagsSubmit = () => {
        let type = 'companies'
        let id = project && project.detailsPage && project.detailsPage.objectId
        let payload = {
            object_type: 'company',
            listName: "projectRoles"
        }
        if (project.selectedTagsDetails && project.selectedTagsDetails.tagIds && project.selectedTagsDetails && project.selectedTagsDetails.tagIds.length > 1) {
            if (!project.selectedTagsDetails && project.selectedTagsDetails.tagIds.includes(0)) {
                payload['tag_id'] = project && project.selectedTagsDetails && project.selectedTagsDetails.tagIds
            }
            else {
                payload['tag_name'] = project && project.selectedTagsDetails && project.selectedTagsDetails.tagDescriptions
            }
        }
        else if (project.selectedTagsDetails && project.selectedTagsDetails.tagIds.includes(0)) {
            payload['tag_name'] = project && project.selectedTagsDetails && project.selectedTagsDetails.tagDescriptions
        }
        else {
            payload['tag_id'] = project && project.selectedTagsDetails && project.selectedTagsDetails.tagIds
        }
        if (project && project.selectorControlSelectionCount) {
            payload['selected_records'] = project && project.selectorControlSelectionCount
            payload['identifier'] = 'project_roles_companies'
            let rolePayload = []
            roles && roles.roleGroups.map(item => {
                if (item.groupName === activeTab) {
                    item.roles.map(role => {
                        rolePayload.push(role.roleNo)
                    })
                }
            })
            if ('selected_records' in payload && payload['selected_records'] !== 0) {
                payload['query'] = {
                    'project_id': { 'operator': '=', 'value': id },
                    'company_roles': { 'operator': '=', 'value': rolePayload }
                }
            }
            if (project.includeIds && project.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - project.includeIds.length
                payload['company_id'] = project.includeIds
            }
            if (project.excludeIds && project.excludeIds.length > 0) {
                payload['selected_records'] = project && project.selectorControlSelectionCount + project.excludeIds
                payload['exclude_ids'] = project.excludeIds
            }
            if ('selected_records' in payload && payload['selected_records'] == 0) {
                delete payload['selected_records']
            }
            if (project && project.isProjectFrameWork) {
                payload['ignore_customer_profile'] = true
            }
        }
        handleTagsCancel()
        handleDropDownItemClick(0)
        updateSelectorControlTags(type, id, payload, dispatch)
    }

    const handleFavChangeSelection = (action, count) => {
        let object_type = 'companies'
        let id = project && project.detailsPage && project.detailsPage.objectId
        const payload = {
            object_type: 'company',
            favourite: action === "Favourite" ? true : false,
            // selected_records: project && project.selectedRecordsCount,
            // exclude_ids: project && project.excludeIds,
            'offset': 0
        }
        if (project && project.selectorControlSelectionCount) {
            payload['selected_records'] = project && project.selectorControlSelectionCount
            let rolePayload = []
            roles && roles.roleGroups.map(item => {
                if (item.groupName === activeTab) {
                    item.roles.map(role => {
                        rolePayload.push(role.roleNo)
                    })
                }
            })
            if (project.includeIds && project.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - project.includeIds.length
                payload['company_id'] = project.includeIds
            }
            if (project.excludeIds && project.excludeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] + project.excludeIds.length
                payload['exclude_ids'] = project.excludeIds
            }
            if ('selected_records' in payload && payload['selected_records'] !== 0) {
                payload['query'] = {
                    'project_id': { 'operator': '=', 'value': id },
                    'company_roles': { 'operator': '=', 'value': rolePayload }
                }
            }
            if ('selected_records' in payload && payload['selected_records'] == 0) {
                delete payload['selected_records']
            }
            if (project && project.isProjectFrameWork) {
                payload['ignore_customer_profile'] = true
            }
            payload['identifier'] = 'project_roles_companies'
        }
        updateSelectorFavUnfav(object_type, id, payload, dispatch)
        handleDropDownItemClick(0)
    }

    const handleTagsSelection = () => {
        setModal(!modal)
    }

    const handleDownloadPDFSelection = () => {
        let objectIds = getUniqueSelectedList()
        if (objectIds.length > 0) {
            let payload = {
                "feedname": "Companies PDF",
                "setting": {
                    "collection": {
                        "key": objectIds,
                        "name": "",
                        "type": "sc"
                    },
                    "exportRecordsCount": objectIds.length,
                    "mediaType": "pdf",
                    "schedule": {
                        "frequency": "once"
                    },
                    "sizeOfDelivery": objectIds.length,
                    "sortBy": "Latest",
                    "type": "Companies"
                },
                "user_no": userData ? userData.user_id : 0
            }
        }
    }

    const handleDropDownItemClick = (count) => {
        const selectedRole = activeTab ? activeTab : 'All roles'
        if (activeTab === "Favourite roles") {
            let payload = {
                count: count,
                favRoles: settings && settings.preferences.favourite_roles
            }
            dispatch(updateFavListSelection(payload))
        } else {
            dispatch(updateListSelection({ count, selectedRole, entityType: 'projectRoles' }))
        }
    }

    const getTotalRows = () => {
        let totalCount = 0
        if (roles && roles.roleGroups) {
            roles && roles.roleGroups && roles.roleGroups.map(roleGroup => {
                if (roleGroup.groupName === activeTab) {
                    roleGroup.roles.map(role => {
                        role.companies.map(company => {
                            totalCount++
                        })
                    })
                }
            })
        }
        return totalCount
    }

    const getUniqueSelectedList = () => {
        let objectIds = []
        if (roles && roles.roleGroups) {
            roles.roleGroups.map(roleGroup => {
                roleGroup.roles.map(role => {
                    role.companies.map(company => {
                        if (company.is_selected == true) {
                            objectIds.push(company.id)
                        }
                    })
                })
            })
        }
        objectIds = [...new Set(objectIds)]
        return objectIds
    }

    const handleDetails = (event, id, routingState) => {
        event.preventDefault();
        navigate(`/app/person/${id}`, { state: routingState });
    }

    const loadRolesList = () => {
        return (
            <TabContent activeTab={activeTab} className="p-0">
                <div className={`selector-ctrl-fav show pt-3 ps-3 ${activeTab == 'Favourite roles' && roles?.roleGroups[0]?.roles?.length <= 0 ? 'hide' : ''}`}>
                    {roles && roles.roleGroups && roles.roleGroups.length > 0 &&
                        <SearchSelector
                            isRolesAndCompanies={true}
                            handleFavChangeSelection={handleFavChangeSelection}
                            handleTagsSelection={handleTagsSelection}
                            // handleDownloadPDFSelection={handleDownloadPDFSelection}
                            handleDropDownItemClick={handleDropDownItemClick}
                            totalSelectedCount={totalSelectedCount}
                            totalRows={getTotalRows()}
                            user={userData}
                            entitiesCount={getTotalRows()}>
                        </SearchSelector>
                    }
                </div>
                {
                    roles && roles.roleGroups && roles.roleGroups.length > 0 ? roles && roles.roleGroups && roles.roleGroups.map(function (roleGroup, index) {
                        let roleIndex = 0
                        return (
                            <TabPane tabId={roleGroup.groupName} key={`${roleGroup.groupName}${index}`}>
                                <div className="height-400">
                                    <ColoredScrollbars className="collab-wrapper">
                                        <div className="pt-4 pe-3">
                                            {roleGroup.roles.length > 0 ? roleGroup.roles.map(function (role, index) {
                                                return (
                                                    <div className="project-role-wrapper" key={`role${index}`}>
                                                        <span className="project-role-name">{role.roleName}</span>
                                                        <Row className="result-list-container content-panel" >
                                                            <Col xs="12" lg="12" xl="12" id="searchlistAccordion" className="p-0">
                                                                <ListGroup>
                                                                    {role && role.companies && role.companies.map(function (entity, index) {
                                                                        const newEntity = Object.assign({
                                                                            objectId: entity.company_id,
                                                                            objectType: "company", onFavClick: false
                                                                        }, entity);
                                                                        let cmpIndex = roleIndex
                                                                        roleIndex++
                                                                        return (<SearchListRow
                                                                            prevNextList='projectRoles'
                                                                            roleGroupName={roleGroup.groupName}
                                                                            role={role}
                                                                            entity={newEntity}
                                                                            objectType='company'
                                                                            index={cmpIndex}
                                                                            key={`resultrow${index}`}
                                                                            length={roles && roles.roleGroups.find(item => item.groupName === activeTab ? roleGroup.roles.length : 0)}
                                                                            sort={'Latest'}
                                                                        />)
                                                                    })}
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }) : roleGroup.groupName == 'Favourite roles' ? ('None of your favourite roles are attached to this project') : ('')}
                                        </div>
                                    </ColoredScrollbars>
                                </div>
                            </TabPane>
                        )
                    }) : ('')
                }
                <TagsModal
                    handleTagsCancel={handleTagsCancel}
                    handleTagsSubmit={handleTagsSubmit}
                    selectedTags={project && project.selectedTagsDetails}
                    showModal={modal}>
                </TagsModal>
            </TabContent>
        )
    }

    const loadPeopleList = () => {
        return (
            peopleOnThisProject !== undefined && peopleOnThisProject && peopleOnThisProject.length > 0 ?
                <>
                    <div className="selected-col p-3">People working on this project <b>({companyForPeopleWorkingOnProject && companyForPeopleWorkingOnProject.companyName})</b></div>
                    <div className="height-400">
                        <ColoredScrollbars className="collab-wrapper">
                            <div className="div-table mt-4 mr-4">
                                <div className="div-table-header d-flex">
                                    <div className="p-2 align-self-center list-name w-65"><b>Contact details</b></div>
                                    <div className="p-2 align-self-center list-phone w-35"><b>Date added</b></div>
                                    <div className="p-2 align-self-center list-phone w-25"></div>
                                </div>
                                {peopleOnThisProject && peopleOnThisProject.map(function (entity, index) {
                                    let routingState = {
                                        prevNextList: "projectPeopleList",
                                        index: index,
                                        previousState: location.state,
                                        project_id: project.detailsPage.objectId,
                                        list: peopleOnThisProject,
                                        tab: '2'
                                    }
                                    return (
                                        <>
                                            <Link
                                                id={entity?.person_id}
                                                to={`/app/person/${entity?.person_id}`}
                                                onClick={(e) => handleDetails(e, entity?.person_id, routingState)}
                                                className="contact-panel" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls={index}>
                                                <div className="div-table-row d-flex">
                                                    <div className={`p-2 list-name-designationn w-65`}>
                                                        <div className="person-name">{entity?.person_first_name} {entity?.person_last_name}</div>
                                                        <div classNam
                                                            e="person-designation">{entity?.person_job_title}</div>
                                                        {
                                                            entity?.person_phone || entity?.mobile ?
                                                                <span className="person-phone mb-1">{entity?.person_phone}
                                                                    {
                                                                        entity?.person_phone_tps ?
                                                                            (<span className="tps-badge">TPS</span>)
                                                                            : ("")
                                                                    }
                                                                    <br />
                                                                    {entity?.mobile}
                                                                    {
                                                                        entity?.personMobileTps ?
                                                                            (<span className="tps-badge">TPS</span>)
                                                                            : ("")
                                                                    }
                                                                </span> :
                                                                <span className="person-telephone mb-1">
                                                                    {companyForPeopleWorkingOnProject.telephone}
                                                                    {
                                                                        companyForPeopleWorkingOnProject.companyPhoneTps ?
                                                                            (<span className="tps-badge">TPS</span>)
                                                                            : ("")
                                                                    }
                                                                </span>
                                                        }
                                                        <div className="person-email text-break">

                                                            <a href={`mailto:${entity.person_email ? entity.person_email : companyForPeopleWorkingOnProject.email}`}>{entity.person_email ? entity.person_email : companyForPeopleWorkingOnProject.email}</a>

                                                        </div>
                                                    </div>
                                                    <div className={`p-2 list-phone-number w-35`}>
                                                        {entity.person_added ?
                                                            (<div className="person-date-added text-break">
                                                                {getFormatedPersonAddedDate(entity.person_added, "YYYY-MM-DD")}
                                                            </div>) : null}
                                                    </div>
                                                    <div className={`p-2 w-25 content-linkedIn`}>
                                                        {
                                                            entity.person_linkedin ?
                                                                (
                                                                    <a className="linkedin-icon" target="_blank"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        href={formatUrlWithProtocol(entity.person_linkedin)}
                                                                    >
                                                                    </a>
                                                                ) :
                                                                ('')
                                                        }

                                                    </div>
                                                </div>
                                            </Link>
                                        </>)
                                })
                                }
                            </div>
                        </ColoredScrollbars>
                    </div >
                </>
                :
                peopleOnThisProject !== undefined && peopleOnThisProject && peopleOnThisProject.length === 0 &&
                    companyForPeopleWorkingOnProject !== {} && Object.keys(companyForPeopleWorkingOnProject).length > 0 ?
                    <div className="d-flex justify-content-center align-items-center h-100 flex-column people-empty-message">
                        <div className="empty-people-image">
                            <div className="font-weight-bold p-3 text-center">No people found currently working on this project - click on the company to view other people</div>
                        </div>
                    </div> :
                    <div className="d-flex justify-content-center align-items-center h-100 contact-container">
                        <div className="select-toview-placeholder">
                            <span>Click on "chevron" <i className="chevron-right"></i> in company block to <br />see People working on this project</span>
                        </div>
                    </div>
        )
    }

    return (
        <div className="tab-pane active">
            <div className="box-view">
                {!project.isPrint ?
                    <Row className="m-0">
                        <Col lg="3" className="p-0 nav-col">
                            <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select a role to view companies</p></div>
                            <Nav tabs vertical className="mb-5">
                                {
                                    roles && roles.roleGroups && roles.roleGroups.map(function (roleGroups, index) {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    className={classnames({ active: activeTab === roleGroups.groupName })}
                                                    onClick={() => { toggleTab(roleGroups.groupName); }}
                                                >
                                                    {roleGroups.groupName}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col lg="4" className="p-0 second-col">
                            <div className="selected-col p-3"><p className="m-0 col-heading">Select a company to view the details</p></div>
                            {loadRolesList()}
                        </Col>
                        <Col lg="5" className="p-0 third-col">
                            {loadPeopleList()}
                        </Col>
                    </Row> :
                    (
                        <div className="Project-print-wrapper px-2">
                            <strong>Roles & Companies</strong>
                            {roles && roles.roleGroups ?
                                roles && roles.roleGroups && roles.roleGroups.map(function (roleGroup, index) {
                                    let roleIndex = 0
                                    return (
                                        <TabPane tabId={roleGroup.groupName} className={`${roleGroup.groupName !== "All roles" && project.isPrint ? 'hide' : ''}`} key={`${roleGroup.groupName}${index}`}>
                                            {roleGroup.roles.map(function (role, index) {
                                                return (
                                                    <div className="project-role-wrapper" key={`role${index}`}>
                                                        <div className="d-flex prw-heading">
                                                            <span className="project-role-name col">{role.roleName}</span>
                                                            <span className="people-on-proj-title col">People on this project</span>
                                                        </div>
                                                        <Row className="result-list-container content-panel" >
                                                            <Col id="searchlistAccordion">
                                                                <ListGroup>
                                                                    {role && role.companies && role.companies.map((entity, index) => {
                                                                        const newEntity = Object.assign({
                                                                            objectId: entity.company_id,
                                                                            objectType: "company"
                                                                        }, entity);
                                                                        //    let entity_updated = { ...entity,
                                                                        //     objectId = entity.company_id,
                                                                        //     objectType = "company"}
                                                                        // entity['objectId'] = entity.company_id
                                                                        // entity['objectType'] = "company"
                                                                        let cmpIndex = roleIndex
                                                                        roleIndex++
                                                                        return (<SearchListRow
                                                                            prevNextList='projectRoles'
                                                                            roleGroupName={roleGroup.groupName}
                                                                            roleNo={role.roleNo}
                                                                            roleName={role.roleName}
                                                                            entity={newEntity}
                                                                            index={cmpIndex}
                                                                            key={`resultrow${index}`}
                                                                            sort={'Latest'} />)
                                                                    })}
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })}
                                        </TabPane>
                                    )
                                }) : ('')
                            }
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default RoleCompanyTabs