import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Col,
    FormGroup,
} from 'reactstrap';
import IncludeExcludeGrid from '../includeExcludeGrid';

const CompanyLocationRegionsCounties = (props) => {
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [data, setData] = useState(searchPersist?.customerProfile['company_geocode']?.values)
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['company_geocode']?.all_subscribed)
    const [showSubscribed, setShowSubscribed] = useState(false)
    const [isClearAll , setIsClearAll] = useState(props?.isClearAll)

    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
    }, [props?.isClearAll])

    return (
        <div className="advanced-filter-grid" id="new_filter_crcs">
            <div className="filterHeading">
                <h5 className="mb-3">Company Regions & Counties</h5>
            </div>
            <div className="filterBlock">
                <div className="textcontent">
                    Filter by selecting company regions and counties
                </div>
                <div className="checkBoxFilterBx">
                    <FormGroup id="company-region-filter" name="category-filter" row className="pb-0 mb-0 px-0">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <IncludeExcludeGrid filter={props.isExclude} filterData={data} filterType="company_geocode"
                                allSubscribed={allSubscribed} initTags={props.initTags}
                                showAll={showAll} regionType={'companyRegions'}
                                variablesHandler={props.variablesHandler}
                                fetchEntityCounts={props.fetchEntityCounts}
                                includeFilters={props.currentAdvancedSearch['companyRegions']}
                                showSubscribed={showSubscribed}
                                isClearAll={isClearAll}
                                handleSearchText={props.handleSearchText}
                            />
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}
export default CompanyLocationRegionsCounties;
