import React from 'react';
import { useLocation } from 'react-router-dom';
import CompanyMain from "./main";
import './companyDetails.scss';

const Company = () => {
	const location = useLocation().pathname;

	return (
		<div className="companydetails">
			<CompanyMain></CompanyMain>
		</div>
	);
}

export default Company;