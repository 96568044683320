import React, { useState, useEffect } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ActivitiesShareDetails from './shareTab';
import ActivitiesTagDetails from './tagsTab';
import ActivitiesNotesDetail from './notesTab';
import ActivitiesCalendarEventDetails from './calendarEventsTab';
import ActivitiesCollabDetails from './collabTab';
import { fetchNotesForParticularObjectId, fetchEvents, fetchShareForObjectId, fetchShareForAllEntity, fetchAllRecipients, fetchShareTeams, fetchAllCollabs } from '../apiclient';
import { summaryActivityDetail } from '../redux/reducer';

const ActivitiesTab = (props) => {
    let textPlaceholder = (props.sharedType === 'project') ? 'project' : (props.sharedType === 'company') ? 'company' : 'Add a note'
    const project = useSelector((state) => state.project)
    const [activeTab, setActiveTab] = useState(project.summaryActivity)
    const userData = useSelector(state => state.user.user)
    const user = useSelector(state => state.user)
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    const toggleTab = (activeTab) => {
        dispatch(summaryActivityDetail(activeTab))
    }

    const fetchAPIForTabs = (activeTab) => {
        if (activeTab === "notes") {
            fetchNotesForParticularObjectId(props.objectId, props.sharedType, dispatch)
        } else if (activeTab === "calendar_events" && userData && userData.licenceFlag && userData.licenceFlag.isCalendarLicence === true) {
            fetchEvents(props.objectId, props.sharedType, dispatch)
        } else if (activeTab === "shares") {
            fetchAllRecipients(userData, dispatch, project)
            fetchShareForObjectId(props.objectId, props.sharedType, dispatch, user, userData)
            if (user?.teamListDetails === undefined || Object.keys(user?.teamListDetails).length === 0) {
                fetchShareTeams(dispatch, project);
            }
        } else if (activeTab === 'collab') {
            // fetchAllRecipients(userData, dispatch, project)
            const payload = {
                id: props.objectId,
                type: props.sharedType,
                offset: 0
            }
            fetchAllCollabs(payload, dispatch, project, settings, user)
        }
    }

    useEffect(() => {
        setActiveTab(project?.summaryActivity)
        fetchAPIForTabs(project?.summaryActivity)
    }, [project?.summaryActivity])

    useEffect(() => {
        if (activeTab === "tags") {
            setActiveTab("tags")
        }
    }, [activeTab])
    return (
        <div className="tab-pane active">
            <div className="box-view">
                <Row className="m-0">
                    <Col sm="12" md="12" lg="3" className="p-0 nav-col">
                        <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select an action to perform on the {textPlaceholder}</p>
                        </div>
                        <Nav tabs vertical className="mb-5">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "tags" })}
                                    onClick={() => { toggleTab("tags"); }}
                                >
                                    My Tags
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "shares" })}
                                    onClick={() => { toggleTab("shares"); }}
                                >
                                    My Shares
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "notes" })}
                                    onClick={() => { toggleTab("notes"); }}
                                >
                                    My Notes
                                </NavLink>
                            </NavItem>
                            {userData && userData.licenceFlag && userData.licenceFlag.isCalendarLicence === true ?
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "calendar_events" })}
                                        onClick={() => { toggleTab("calendar_events"); }}
                                    >
                                        Calendar Events
                                    </NavLink>
                                </NavItem>
                                : ""}
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "collab" })}
                                    onClick={() => { toggleTab("collab"); }}
                                >
                                    Collaborations
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm="12" md="12" lg="9" className="p-0 detail-col">
                        <TabContent activeTab={activeTab} className="p-0">
                            <TabPane tabId="tags">
                                <div className="selected-col p-3"><p className="m-0 col-heading">Manage your tags on this {textPlaceholder}</p></div>
                                <ActivitiesTagDetails />
                            </TabPane>
                            <TabPane tabId="shares">
                                <div className="selected-col p-3"><p className="m-0 col-heading">Share this  {textPlaceholder} with others in your organisation</p></div>
                                <ActivitiesShareDetails />
                            </TabPane>
                            <TabPane tabId="notes">
                                <div className="selected-col p-3"><p className="m-0 col-heading">Manage your notes on this {textPlaceholder}</p></div>
                                <ActivitiesNotesDetail sharedType={props.sharedType} />
                            </TabPane>

                            {userData && userData.licenceFlag && userData.licenceFlag.isCalendarLicence === true ?
                                <TabPane tabId="calendar_events">
                                    <ActivitiesCalendarEventDetails textPlaceholder={textPlaceholder} />
                                </TabPane> : ""}

                            <TabPane tabId="collab">
                                <div className="selected-col p-3"><p className="m-0 col-heading">Activities performed by all collaborators</p></div>
                                <ActivitiesCollabDetails objectType={props.sharedType} />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ActivitiesTab