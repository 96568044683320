import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup } from 'reactstrap';
import ColoredScrollbars from '../common/js/coloredScrollbars';
import CommonPlaceholder from '../details/framework/commonPlaceholder';
import CompanyList from '../list/companyList/companylist';
import Contactlist from '../list/contactList/contactlist';
import ProjectList from '../list/projectList/projectlist';
import { fetchSearchListCount } from './apiclient';
import { updateHierarchyBack, updateIfMoreResultAvailable, updateNoResult } from './redux/reducer';
import { renderListHeaderForAllObject, renderListHeaderForMyLeadsUpdates, renderListHeaderForMyLeadsFavourites, renderListHeaderForMyLeadsHistory, renderListHeaderForMyLeadsShares, renderListHeaderForMyLeadsArchive, renderListHeaderForLeagueTable, renderListHeaderForMyLeadsTags } from './utils';
import no_result_img from "../../components/common/images/empty_state_noresults.png";
import league_empty_state from "../../components/common/images/league-empty-state.png";
import { fetchArchieveList, fetchFavouritesResults, fetchHistoryResults, fetchSharesResults, fetchTagDetailsById, fetchUpdatesResults } from '../myleads/apiclient';
import { updateMyLeadOffsetLimitValue } from '../myleads/reducer';
import { getUniqueSelectedList } from '../../utils/commonFunctions';
import { showGroupCompaniesProject } from '../details/apiclient';
import { updateShowMoreOffsetLimit } from '../details/redux/reducer';
import { fetchLeagueTableResults } from '../leaguetable/apiclient';
import { updateLeagueOffsetLimitValue } from './redux/searchPeristReducer';
import { MYLEADS_PREVNEXT_LIST } from '../../utils/settings';
import { preProcessorQuery } from './filters/preProcessorQuery';
import { updateOffsetLimit } from './filters/redux/reducer';

const SearchListResult = (props) => {
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const myleads = useSelector((state) => state.myleads)
    const leagueTableState = useSelector((state) => state.leagueTable)
    const [searchListData, setSearchListData] = useState(search.searchList);
    const [leadsData, setLeadsData] = useState(myleads.searchList);
    const [leagueData, setLeagueData] = useState(leagueTableState?.searchList);
    const [loading, setLoading] = useState(search.isSearchLoader);
    const [userGeolocationAccess, setUserGeolocationAccess] = useState(false);
    const details = useSelector((state) => state.project)
    const filter = useSelector((state) => state.filter)
    const dispatch = useDispatch()
    let ifMyleadsExist = MYLEADS_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
    let isLeagueTable = props.prevNextList === 'LeagueTableList' ? true : false
    // useSelector to get the current Redux store value
    const currentValue = useSelector(state => state.filter.filtersState[searchPersist.appliedContext].offset);

    // useRef to store the previous Redux store value
    const previousValueRef = useRef();
    useEffect(() => {
        setLoading(true)
        dispatch(updateNoResult(false))
    }, [searchPersist.appliedContext]);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Successfully obtained position
                    setUserGeolocationAccess(true);
                },
                (error) => {
                    // Failed to obtain position
                    setUserGeolocationAccess(false);
                }
            );
        } else {
            // Geolocation API not supported
            setUserGeolocationAccess(false);
        }
    }, [searchPersist?.leadsSort]);


    useEffect(() => {
        if (props.prevNextList === 'searchList') {
            if (!search.hierarchyBack) {
                if (filter.filtersState[searchPersist.appliedContext].records > 50 && searchPersist.appliedContext !== 'project') {
                    dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
                }
                // Update the previous value ref
                previousValueRef.current = currentValue === 0 ? currentValue : previousValueRef.current;
                if (!searchPersist.login && filter.filtersState[searchPersist.appliedContext].offset > 0 && previousValueRef.current !== undefined && previousValueRef.current !== currentValue) {
                    var formValues = preProcessorQuery('', filter.filtersState, searchPersist.appliedContext)
                    previousValueRef.current = currentValue
                    fetchSearchListCount(formValues, dispatch, search, settings)
                }
            }
        }
    }, [filter.filtersState[searchPersist.appliedContext].offset, filter.profileStore]);

    useEffect(() => {
        // Update the previous value ref
        previousValueRef.current = currentValue
    }, [searchPersist.isListView])

    useEffect(() => {
        if (props.prevNextList === 'LeagueTableList') {
            fetchLeagueTableResults(searchPersist, dispatch)
        }
    }, [searchPersist?.leagueOffsetLimitValue])

    useEffect(() => {
        if (props?.prevNextList === 'groupedCompanyProjects') {
            showGroupCompaniesProject(getUniqueSelectedList(details?.groupCompanies), dispatch, details)
        }
    }, [details.showMoreOffsetLimit.offset]);

    useEffect(() => {
        if (search.backButtonCluster?.back) {
            setSearchListData(ifMyleadsExist ? [] : search.clusterList.leadsFlags)
            setLeadsData(ifMyleadsExist ? search.clusterList.leadsFlags : [])
        } else {
            fetchSearchListData(setSearchListData, search, setLeadsData, ifMyleadsExist, leadsData);
        }
    }, [search.searchList, search.backObjectId, search.noResult, search.backButtonCluster]);

    useEffect(() => {
        if (ifMyleadsExist) {
            if (search.backButtonCluster?.back) {
                setSearchListData(ifMyleadsExist ? [] : search.clusterList.leadsFlags)
                setLeadsData(ifMyleadsExist ? search.clusterList.leadsFlags : [])
            } else {
                fetchMyleadsData(setLeadsData, myleads, search, setLoading, setSearchListData, dispatch);
            }
        }
    }, [myleads.searchList, myleads.noResult, search.backObjectId, search.backButtonCluster, myleads.updatesSearchList]);

    useEffect(() => {
        if (isLeagueTable) {
            setLeagueData(leagueTableState?.searchList)
            if (search.backObjectId !== undefined && leagueTableState.searchList.length > 0) {
                setLoading(false);
            } else if (leagueTableState?.searchList?.length > 0) {
                setLoading(false)
                setSearchListData([])
                dispatch(updateIfMoreResultAvailable(false))
            } else if (leagueTableState?.leagueNoResult) {
                setLoading(false)
            } else {
                setLoading(true)
            }
        }
    }, [leagueTableState?.searchList, leagueTableState?.noResult, search.backObjectId]);

    useEffect(() => {
        if (!search.hierarchyBack && myleads.isActiveTabChanged && myleads.offsetLimitValue.offset === 0) {
            fetchMyleadsResults();
        } else if (!search.hierarchyBack && !myleads.isActiveTabChanged) {
            fetchMyleadsResults();
        }
    }, [myleads.offsetLimitValue.offset]);

    const fetchMyleadsResults = () => {
        if (props?.prevNextList === 'myLeadsUpdates') {
            fetchUpdatesResults(searchPersist, myleads, dispatch, settings);
        }
        if (props?.prevNextList === 'myLeadsFavourites') {
            fetchFavouritesResults(searchPersist, myleads, dispatch, settings);
        }
        if (props?.prevNextList === 'myLeadsHistory') {
            fetchHistoryResults(searchPersist, myleads, dispatch, settings);
        }
        if (props?.prevNextList === 'myLeadsShares') {
            fetchSharesResults(searchPersist, myleads, dispatch, settings);
        }
        if (props?.prevNextList === 'myLeadsTags' && myleads.tagId !== 0) {
            fetchTagDetailsById(myleads.tagId, myleads, searchPersist, dispatch, '', settings);
        }
        if (props?.prevNextList === "myLeadsArchive") {
            fetchArchieveList(searchPersist, myleads, dispatch);
        }
    }

    const handleShowMore = () => {
        let newOffset = ''
        if (props?.prevNextList === 'groupedCompanyProjects') {
            let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
            dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
        } else if (props.prevNextList === 'LeagueTableList') {
            let newOffset = searchPersist?.leagueOffsetLimitValue.offset + searchPersist?.leagueOffsetLimitValue.records;
            dispatch(updateLeagueOffsetLimitValue({ offset: newOffset, records: searchPersist.leagueOffsetLimitValue.records }));
        } else if (props?.prevNextList === 'myLeadsFavourites' || props?.prevNextList === 'myLeadsTags' || props?.prevNextList === 'myLeadsHistory' || props?.prevNextList === 'myLeadsArchive' || props?.prevNextList === 'myLeadsShares' || props?.prevNextList === 'myLeadsUpdates') {
            newOffset = myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records;
            dispatch(updateMyLeadOffsetLimitValue({ offset: newOffset, records: myleads.offsetLimitValue.records }));
        } else {
            newOffset = filter.filtersState[searchPersist.appliedContext].offset + filter.filtersState[searchPersist.appliedContext].records;
            dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[searchPersist.appliedContext].records }))
            dispatch(updateHierarchyBack(false))
        }
    }

    const renderShowMore = () => {
        return (
            <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
            </div>
        )
    }

    const fetchMyleadsData = (setLeadsData, myleads, search, setLoading, setSearchListData, dispatch) => {
        let list = props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList : myleads.searchList
        setLeadsData(list);
        if (search.backObjectId !== undefined && list.length > 0) {
            setLoading(false);
        } else if (list.length > 0) {
            setLoading(false);
            setSearchListData([]);
            dispatch(updateIfMoreResultAvailable(false));
        } else if (myleads.noResult) {
            setLoading(false);
            setSearchListData([]);
        } else {
            setLoading(true);
        }
    }

    const fetchSearchListData = (setSearchListData, search, setLeadsData, ifMyleadsExist, leadsData) => {
        setLoading(true);
        setSearchListData(search?.searchList);
        setLeadsData(!ifMyleadsExist ? [] : leadsData);
        if (search.backObjectId !== undefined && search?.searchList?.length > 0) {
            setLoading(false);
        } else if (search.searchList?.length > 0 && !search.noResult && searchPersist.appliedContext === search.searchList[0].objectType) {
            setLoading(false);
        }
        else if (search.noResult) {
            setLoading(false);
        }
        else {
            setLoading(!ifMyleadsExist || !isLeagueTable ? true : false);
        }
    }

    const renderList = () => {
        return (
            <>
                {
                    !details.isProjectSelected ?
                        (props.type === 'project' ? <ProjectList prevNextList={props.prevNextList} mode={props.mode} myHoverList={props.myHoverList} activeTab={props.activeTab} /> :
                            props.type === 'company' ? <CompanyList type={props.type} myHoverList={props.myHoverList} activeTab={props.activeTab} prevNextList={props.prevNextList} /> :
                                <Contactlist prevNextList={props.prevNextList} />) :
                        (<ProjectList prevNextList={props.prevNextList} />)

                }
            </>
        )
    }

    return (
        <div className={`result-wrapper ${!ifMyleadsExist && !isLeagueTable && !searchPersist?.isListView && props.type !== 'people' ? 'gridSearchView'
            : ifMyleadsExist && !myleads?.isListView && !['myLeadsArchive'].includes(props?.prevNextList) ? 'gridSearchView' : ''}`}>
            <div className={`result-list-container content-panel ${(props.type === 'project' || props.type === 'company') ? 'projectNameIcon' : ''}`}>
                <div className="result-list-wrapper">
                    {
                        (
                            <div className={`listviewtable-block bggray leftright-space ${ifMyleadsExist ? myleads?.isListView || !myleads?.isListView && props?.prevNextList === 'myLeadsArchive' ? '' : 'd-none' : isLeagueTable ? '' : searchPersist.isListView ? '' : 'd-none'}`}>
                                <div className={`list-table ${(ifMyleadsExist && leadsData?.length === 0) ? 'd-none' : ''}`}>
                                    <div className="list-header d-flex">
                                        {
                                            (props.prevNextList === 'myLeadsUpdates') ? renderListHeaderForMyLeadsUpdates(props.type, searchPersist)
                                                : (props.prevNextList === 'myLeadsFavourites') ? renderListHeaderForMyLeadsFavourites(props.type, searchPersist, userGeolocationAccess)
                                                    : (props.prevNextList === 'myLeadsHistory') ? renderListHeaderForMyLeadsHistory(props.type, searchPersist, userGeolocationAccess)
                                                        : (props.prevNextList === 'myLeadsShares') ? renderListHeaderForMyLeadsShares(props.type, searchPersist, userGeolocationAccess)
                                                            : (props.prevNextList === 'myLeadsArchive') ? renderListHeaderForMyLeadsArchive(props.type, searchPersist)
                                                                : (props.prevNextList === 'myLeadsTags') ? renderListHeaderForMyLeadsTags(props.type, searchPersist, userGeolocationAccess)
                                                                    : (props.prevNextList === 'LeagueTableList') ? renderListHeaderForLeagueTable(props.type, searchPersist)
                                                                        : renderListHeaderForAllObject(props.type, searchPersist)
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className={`searchlist-wrapper ${(searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active
                        || searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active || searchPersist.appliedContext === 'people' && filter.filtersState[searchPersist.appliedContext]?.personInformation?.active) && filter.searchMode === 'basic' && props?.prevNextList === 'searchList' ? !searchPersist.isListView ? 'with-project-info mapViewMode' : 'with-project-info' : !searchPersist.isListView ? 'mapViewMode' : ''}`}>
                        {
                            loading ? <CommonPlaceholder /> :
                                searchListData?.length > 0 || leadsData?.length > 0 || leagueData?.length > 0 ?
                                    <ColoredScrollbars>
                                        <ListGroup className={`${!searchPersist.isListView && props?.prevNextList !== 'myLeadsArchive' ? 'gridSearchView' : !myleads.isListView && props?.prevNextList !== 'myLeadsArchive' ? 'gridSearchView' : ''}`}>
                                            {renderList()}
                                            {(search.clusterList?.leadsFlags?.length === 0 && ((!isLeagueTable && myleads.isShowMoreActive && props?.prevNextList === 'myLeadsUpdates' && myleads.updatesSearchListCount > 50) || (!isLeagueTable && ifMyleadsExist && myleads.isShowMoreActive && props?.prevNextList !== 'myLeadsUpdates' && myleads.leadSearchListCount > 50) || !isLeagueTable && search.isShowMoreActive || !isLeagueTable && search.isShowMoreActiveCompanyProjects || isLeagueTable && searchPersist?.leagueTable?.isShowMoreActive && searchPersist?.leagueTable?.selectedTopSearch?.label === 'Show all')) ? renderShowMore() : ''}
                                        </ListGroup>
                                    </ColoredScrollbars> : leadsData?.length === 0 || leagueData?.length === 0 ?
                                        (
                                            <div>
                                                {
                                                    props.prevNextList === "myLeadsTags" ? "There are no items with this tag, switch the type to check for more items." :
                                                        props.prevNextList === "myLeadsHistory" ? (<p><strong>You have no history.</strong></p>) :
                                                            props.prevNextList === "myLeadsUpdates" && myleads?.isInValidReq ? (<p className="py-3">
                                                                <strong>There are too many updates to process and display.</strong><br />
                                                            </p>) :
                                                                props.prevNextList === "myLeadsUpdates" && myleads.noResult ? (<p className="py-3">
                                                                    <strong>You do not have any updates.</strong><br />
                                                                    You will receive updates on projects and companies you have favourited, added a note, tagged or shared.
                                                                </p>) :
                                                                    props.prevNextList === "myLeadsFavourites" ?
                                                                        (<p className="py-3">
                                                                            <strong>You have no favourites.</strong><br />
                                                                            To create one, click "Add favourite" on projects and companies
                                                                        </p>) :
                                                                        props.prevNextList === "myLeadsShares" ? "You have no shares.To create one, click 'Share' on projects and companies" :
                                                                            props.prevNextList === "myLeadsArchive" ? "You have no projects archived." :
                                                                                isLeagueTable ? (
                                                                                    <div className="mt-5 py-3 text-center league-empty-state">
                                                                                        <h5 className="text-uppercase">League table</h5>
                                                                                        <p>No results. Try adjusting your criteria in the drop down menus.</p>
                                                                                        <img src={league_empty_state} />
                                                                                    </div>) :
                                                                                    <div className="d-flex flex-column align-items-center no-result-emptystate">
                                                                                        <img src={no_result_img} />
                                                                                        <p className="text-center empty-info"><h6>No results.</h6>
                                                                                            {searchPersist.appliedContext === "project" ? <p>Try adjusting your search terms or filters. Read our Search Troubleshooter for more helpful tips: <a className="d-block" href="http://help.barbour-abi.com/en/articles/6808407-project-search-troubleshooter" target="_blank">Project Search</a></p>
                                                                                                : searchPersist.appliedContext === "company" ? <p>Try adjusting your search terms or filters. Read our Search Troubleshooter for more helpful tips: <a className="d-block" href="https://help.barbour-abi.com/en/articles/6877681-company-search-troubleshooter" target="_blank">Company Search</a></p>
                                                                                                    : "Try adjusting your search terms or filters."}</p>
                                                                                    </div>
                                                }
                                            </div>
                                        ) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchListResult
