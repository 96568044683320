import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { groupNotesbyUpdatedDate } from '../../../utils';
import NotesList from '../../details/activitytabs/notesTab/notelist';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { updateMyLeadOffsetLimitValue } from '../reducer';
import { fetchNotesForAllDetails } from '../../details/apiclient';

const NotesDetails = (props) => {
  const [notes, setNotes] = useState([])
  const searchPersist = useSelector((state) => state.searchPersist)
  const myleads = useSelector((state) => state.myleads)
  let activeFilterValue = searchPersist.leadsAppliedContext.notes;
  const [displayShowMore, setDisplayShowMore] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props?.activeTab === "notes") {
      if (myleads?.notesList?.length > 0) {
        setNotes(groupNotesbyUpdatedDate(myleads?.notesList ? myleads?.notesList : []))
      }
      setDisplayShowMore((myleads?.notesList && (myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records) < myleads.notesCount) ? true : false)
    }
  }, [myleads.notesList])

  useEffect(() => {
    if (props?.activeTab === "notes") {
      fetchNotesForAllDetails(searchPersist.leadsAppliedContext.notes, myleads, dispatch)
    }
  }, [myleads?.offsetLimitValue?.offset, props?.activeTab])

  const handleShowMore = () => {
    let newOffset = myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records;
    dispatch(updateMyLeadOffsetLimitValue({ offset: newOffset, records: myleads.offsetLimitValue.records }));
  }

  return (
    <div className="note-list px-3 pb-3 position-relative">
      {
        myleads?.notesList?.length > 0 ? (
          <div className="note-details-wrapper">
            <ColoredScrollbars className="details-panel-scrollbar">
              {
                notes && Object.keys(notes).map(function (updatedDate, index) {
                  return (
                    <div className="note-listing pb-2" key={index}>
                      <span className="notes-updated-date">{updatedDate}</span>
                      {
                        notes[updatedDate].map(function (note, notesIndex) {
                          return (
                            note.note ?
                              <NotesList prevNextList='myLeadsNotes' key={notesIndex} note={note} index={notesIndex} />
                              : ""
                          )
                        })
                      }
                    </div>
                  )
                })
              }
              {notes && Object.keys(notes).length > 0 ? (
                <div className={`show d-flex justify-content-center pt-3 pb-3 showmore-result`}>
                  {displayShowMore ? <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button> : ("")}
                </div>
              ) : (<span></span>)}
            </ColoredScrollbars>
          </div>
        ) : myleads?.isAllNotesArchive ? (
          <div><p className="py-3">
            Your list includes projects you have archived, click 'Archive' to view.
          </p></div>
        ) : myleads?.noResultNotesTextSearch ? (
          <div className="notes-unavailable"><p>There is no record of a note with this information, please try another search</p></div>
        ) : (
          <div className="notes-unavailable">
            <p><strong>You have no notes</strong><br />To create one, click "Add note" on {activeFilterValue}</p>
          </div>
        )
      }
    </div>
  )
}

export default NotesDetails