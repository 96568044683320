import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import sha256 from 'js-sha256'
import { Col, Row, FormGroup, Label, Input, Button, InputGroup } from 'reactstrap';
import { validatePassword, changePassword } from "../apiclient"
import { resetValidatePasswordResponse } from "../../../redux/features/settings/settingslice";

function UpdatePassword({ active }) {
  const defaultValues = {
    type: 'password',
    newPasswordType: 'password',
    confirmPasswordType: 'password',
    confirm_password: '',
    password: '',
    validationMsg: null,
    isCurrentPasswordValidated: null,
    isPasswordUpdated: false,
    current_pass: '',
  }
  const [state, setState] = useState(defaultValues);
  const {
    settings_container,
    userData
  } = useSelector((state) => {
    return {
      settings_container: state.settings,
      userData: state.user.user
    }
  });

  useEffect(() => {
    if (active) {
      setState((prev => ({
        ...prev,
        confirm_password: '',
        password: '',
        current_pass: ''
      })))
    }
  }, [active])

  const dispatch = useDispatch();
  const showHide = (value) => {
    if (value === "newPassword") {
      setState({
        ...state, newPasswordType: state.newPasswordType === 'input' ? 'password' : 'input'
      })
    } else if (value === "confirmPassword") {
      setState({
        ...state, confirmPasswordType: state.confirmPasswordType === 'input' ? 'password' : 'input'
      })
    } else {
      setState({
        ...state, type: state.type === 'input' ? 'password' : 'input'
      })
    }

  }

  const handleConfirmPasswordChange = (event) => {
    setState({ ...state, confirm_password: event.target.value })
  }

  const handlePasswordChange = (event) => {
    setState({ ...state, password: event.target.value })
  }

  const handleSubmit = () => {
    if (state.password.trim().length === 0 || state.confirm_password.trim().length === 0) {
      setState({ ...state, validationMsg: "All fields are mandatory" })
      return false
    }
    if (state.password.length < 8 || state.confirm_password.length < 8) {
      setState({ ...state, validationMsg: "New/Confirm password should be more than or equal to 8" })
      return false
    }

    if (state.password === state.confirm_password && (state.password != "" || state.confirm_password != "")) {
      // Set password from here
      let payload = {
        userno: userData.user_id,
        userName: userData.username,
        password: sha256(state.password),
        confirmPassword: sha256(state.confirm_password),
      }
      changePassword(payload, dispatch)
    } else {
      setState({ ...state, validationMsg: "New and confirm password do not match" })
    }

  }

  const validateCurrentPassword = (event) => {
    if (event.target.value.trim() == '') {
    }
    else {
      let payload = {
        user_id: userData.user_id,
        password: sha256(event.target.value)
      }
      validatePassword(payload, dispatch)
    }
  }
  useEffect(() => {
    setState({ ...state, isCurrentPasswordValidated: settings_container.isCurrentPasswordValidated })
  }, [settings_container.isCurrentPasswordValidated]);

  useEffect(() => {
    dispatch(resetValidatePasswordResponse())
  }, []);


  return (
    <div className="updatePassword-wrapper">
      <div className="content-panel p-1 pt-2">
        <div className="col-md-4 col-sm-12">
          <FormGroup>
            <div className={`icon-addon ${state.type === 'password' ? 'hide-password' : 'show-password'}`}>
              <Label>Current Password</Label>
              <Input type={state.type}
                name="current_pass"
                id="currentPassword"
                placeholder="Current Password"
                autoComplete="new-password"
                autoFocus={true}
                value={state.current_pass}
                onChange={(e)=> setState((prev=> ({...prev, current_pass: e.target.value})))}
                onBlur={(e)=>validateCurrentPassword(e)}
              />
              <span className="display-password" onClick={() => showHide()}>{state.type === 'input' ? 'Hide' : 'Show'}</span>
              {state.isCurrentPasswordValidated == false ? (<span className="error">Current password is incorrect</span>) : ('')}
            </div>
          </FormGroup>
          <FormGroup>
            <div className={`icon-addon ${state.newPasswordType === 'password' ? 'hide-password' : 'show-password'}`}>
              <Label>New Password</Label>
              <Input type={state.newPasswordType}
                id="newPassword"
                name="password"
                onChange={handlePasswordChange}
                value={state.password}
                placeholder="New Password" />
              <span className="display-password" onClick={() => showHide("newPassword")}>{state.newPasswordType === 'input' ? 'Hide' : 'Show'}</span>
            </div>
          </FormGroup>
          <FormGroup>
            <div className={`icon-addon ${state.confirmPasswordType === 'password' ? 'hide-password' : 'show-password'}`}>
              <Label>Confirm Password</Label>
              <Input
                type={state.confirmPasswordType}
                id="confirmPassword"
                name="confirm_password"
                value={state.confirm_password}
                onChange={handleConfirmPasswordChange}
                className="mr-1 mb-1 form-control"
                placeholder="Confirm Password"
              />
              <Label for="confirmPassword" className="sr-only fa fa-lock" title="Confirm Password"></Label>
              <span className="display-password" onClick={() => showHide("confirmPassword")}>{state.confirmPasswordType === 'input' ? 'Hide' : 'Show'}</span>
            </div>
            <span className="error">{state.validationMsg}</span>
          </FormGroup>
          {
            state.isCurrentPasswordValidated ? (
              <button className={`m-0 mb-3 btn-save-cancel primary-btn`} onClick={() => handleSubmit()}>Update</button>
            ) : (
              <button disabled className={`m-0 mb-3 btn-save-cancel primary-btn`}>Update</button>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;