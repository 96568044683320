import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, FormGroup, Input, Label } from 'reactstrap'
import { updateClearAllExportFields, updateExportFieldsTextInputFilter, updateFieldsFilter, updateSelectAllExportFields, updateUserDefinedCheckedFlags } from '../../reducer'
import { appendFieldDisplayNames, getSelectedFields, getSelectedFieldsFalse } from '../../utils'

const SelectFieldsComponent = (props) => {
  const exports = useSelector((state) => state.export)
  const dispatch = useDispatch()
  const [fieldsText, setFieldsText] = useState(null)
  const [isFieldsDropdown, setIsFieldsDropdown] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [closeButton, setCloseButton] = useState(false)
  const [clearAll, setClearAll] = useState(true)
  const [selectAll, setSelectAll] = useState(true)
  const [fields, setFields] = useState(props.type === "projects" ? exports.exportStaticData.projects : (props.type === "companies" ?
    exports.exportStaticData.companies : exports.exportStaticData.contacts))

  const node = useRef();
  useEffect(() => {
    setFields(
      props.type === 'projects'
        ? exports.exportStaticData.projects
        : props.type === 'companies'
        ? exports.exportStaticData.companies
        : exports.exportStaticData.contacts
    );

    setFieldsText(appendFieldDisplayNames(fields, false));

    if (fields) {
      if (getSelectedFieldsFalse(fields)) {
        setSelectAll(true);
        setClearAll(false);
      } else if (!getSelectedFields(fields)) {
        setSelectAll(true);
        setClearAll(true);
      } else if (getSelectedFields(fields)) {
        setSelectAll(false);
        setClearAll(true);
      }
    }
  }, [props.type, exports.exportStaticData.projects, exports.exportStaticData.companies, exports.exportStaticData.contacts]);


  const handleDropDownClick = () => {
    setIsFieldsDropdown(!isFieldsDropdown);
  };

  const handleOutsideClick = () => {
    setIsFieldsDropdown(!isFieldsDropdown);
  };


  useEffect(() => {
    if (!isFieldsDropdown) {
      dispatch(updateExportFieldsTextInputFilter({ fields: fields, type: props.type }));
    }
  }, [isFieldsDropdown, props.type]);

  function checkComponentFlagsOnSelectAll(type, component) {
    if (component === 'projects') {
      if (type === 'projects') {
        dispatch(updateUserDefinedCheckedFlags({
          isProjectsChecked: true,
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected),
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected),
          isCompaniesCheckActive: true
        }))
      } else if (type === 'companies') {
        dispatch(updateUserDefinedCheckedFlags({
          isCompaniesChecked: true,
          isContactsCheckActive: true,
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected),
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected),
        }))
      } else {
        dispatch(updateUserDefinedCheckedFlags({
          isContactsChecked: true,
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected),
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected),
        }))
      }
    } else if (component === 'companies') {
      if (type === 'companies') {
        dispatch(updateUserDefinedCheckedFlags({
          isCompaniesChecked: true,
          isContactsCheckActive: true,
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected),
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected)
        }))
      } else if (type === 'contacts') {
        dispatch(updateUserDefinedCheckedFlags({
          isContactsChecked: true,
          isProjectsCheckActive: true,
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected),
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected)
        }))
      } else {
        dispatch(updateUserDefinedCheckedFlags({
          isProjectsChecked: true,
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected),
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected)
        }))
      }
    } else {
      if (type === 'contacts') {
        dispatch(updateUserDefinedCheckedFlags({
          isContactsChecked: true,
          isCompaniesCheckActive: true,
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected),
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected)
        }))
      } else if (type === 'companies') {
        dispatch(updateUserDefinedCheckedFlags({
          isCompaniesChecked: true,
          isProjectsCheckActive: true,
          isProjectsChecked: exports.exportCriteria.setting.fields["projects"] && exports.exportCriteria.setting.fields["projects"].some(item => item.is_selected),
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected)
        }))
      } else {
        dispatch(updateUserDefinedCheckedFlags({
          isProjectsChecked: true,
          isContactsChecked: exports.exportCriteria.setting.fields["contacts"] && exports.exportCriteria.setting.fields["contacts"].some(item => item.is_selected),
          isCompaniesChecked: exports.exportCriteria.setting.fields["companies"] && exports.exportCriteria.setting.fields["companies"].some(item => item.is_selected)
        }))
      }
    }
  }

  function checkComponentFlagsOnClearAll(type, fieldlist) {
    if (type === 'projects') {
      dispatch(updateUserDefinedCheckedFlags({
        isProjectsChecked: fieldlist?.length < exports.exportStaticData.projects?.length
      }))
    } else if (type === 'companies') {
      dispatch(updateUserDefinedCheckedFlags({
        isCompaniesChecked: fieldlist?.length < exports.exportStaticData.companies?.length
      }))
    } else {
      dispatch(updateUserDefinedCheckedFlags({
        isContactsChecked: fieldlist.length < exports.exportStaticData.contacts?.length
      }))
    }
  }


  function handleFieldsFilter(event) {
    event.stopPropagation()
    dispatch(updateFieldsFilter({name: event.target.id, active: event.target.checked, type: props.type}))
    if (event.target.checked) {
      checkComponentFlagsOnSelectAll(props.type, props.component)
    } else {
      let itemActive = []
      exports.exportCriteria.setting.fields[props.type].map(item =>
        item.is_selected ? itemActive.push(item) : '')
      if (itemActive.length === 0) {
        checkComponentFlagsOnClearAll(props.type, props.component)
      }
    }
  }

  const handleSelectAll = () => {
    let selectList = []
    setSelectAll(false)
    setClearAll(true)
    fields?.map(function (item, index) {
      if (item.display) {
        item = {
          ...item, active: true
        }
        selectList.push(item.value)
      }
    })
    dispatch(updateSelectAllExportFields({ data: selectList, type: props.type }))
    checkComponentFlagsOnSelectAll(props.type, props.component)
  }

  const handleClearAll = () => {
    let clearList = []
    setSelectAll(true)
    setClearAll(false)
    fields?.map(function (item, index) {
      if (item.display) {
        clearList.push(item.value)
        item = {
          ...item, active: false
        }
      }
    })
    dispatch(updateClearAllExportFields({ data: clearList, type: props.type }))
    checkComponentFlagsOnClearAll(props.type, props.component)
  }

  function setCheckUncheckState(options) {
    let unique = []
    if (options) {
      let checkedUnchecked = options?.map(option => option.active)
      unique = checkedUnchecked?.filter(function (elem, pos) {
        return checkedUnchecked.indexOf(elem) == pos
      })
    }
    return unique
  }

  const handleFilterChange = (event) => {
    let filterValue = event.target.value
    setSearchInput(event.target.value)
    let filteredList = []
    if (filterValue) {
      fields?.map(function (option) {
        if (option.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
          filteredList.push(option)
        }
      })
    }
    else {
      filteredList = fields
    }
    let unique = setCheckUncheckState(fields)
    if (unique.length > 1) {
      setSelectAll(true)
      setClearAll(true)
    }
    setCloseButton(event.target.value.length > 0 ? true : false)
    dispatch(updateExportFieldsTextInputFilter({ fields: filteredList, type: props.type }))
  }

  const handleClose = () => {
    setSelectAll(true)
    setClearAll(true)
    setCloseButton(false)
    setSearchInput('')
    dispatch(updateExportFieldsTextInputFilter({ fields: fields, type: props.type }))
  }

  return (
    <>
      {isFieldsDropdown ? (<div className='dropdownOverlay' onClick={handleOutsideClick} />) : null}
      <div className="field-panel" ref={node}>
        <div id="fields" onClick={handleDropDownClick} className="auto-complete">
          {fieldsText ? fieldsText : ''}
        </div>
        {isFieldsDropdown ? (
        <Card block className="pl-0 pr-0 pt-0 pb-0 border-0 role-dropdown">
          <div className="subsearch-filters role-filter-block">
            <div className="mb-0">
              <fieldset className={`form-group m-0 active`}>
                <legend className="sr-only">Fields</legend>
                <div className="select-toggle">
                  <a className={`toggle-link ${selectAll ? 'active' : 'disabled'}`} onClick={selectAll ? (handleSelectAll) : ''}>Select all</a>
                  <a className={`toggle-link ${clearAll ? 'active' : 'disabled'}`} onClick={clearAll ? (handleClearAll) : ''}>Clear all</a>
                </div>
                <div className="roles-search-list textfield-wrapper">
                  <Input type="text" placeholder="Search fields" onChange={handleFilterChange} className="form-control mb-2" value={searchInput} />
                  <Button className={`roles-search-filed-btn clear-textfield ${closeButton ? 'display-block' : 'display-none'}`} onClick={handleClose}>x</Button>
                  <div id="rolefilters" className="src-filter-list">
                    <Card block className="p-0">
                      <div className="include-stages" >
                        {fields && fields.map(function (item, index) {
                          if (item.display) {
                            return (<FormGroup check key={`formgroup${index}`}>
                              {
                                item.mandatory ? (
                                  <div className="check-box-button">
                                    <Input disabled className={`${item.mandatory ? 'disabled' : ''}`} type="checkbox" checked={item.active} id={item.name} value={item.value} onChange={handleFieldsFilter} />
                                    <Label disabled className={`${item.mandatory ? 'disabled' : ''}`} check for={item.name}>{item.name} <span>(required)</span> </Label>
                                  </div>
                                ) : (
                                  <div className="check-box-button">
                                    <Input type="checkbox" checked={item.active} id={item.name} value={item.value} onChange={handleFieldsFilter} />
                                    <Label check for={item.name}>{item.name}</Label>
                                  </div>
                                )
                              }
                            </FormGroup>)
                          }
                        })}
                      </div>
                    </Card>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </Card>
         ) : ('')
        } 
      </div>
    </>
  )
}

export default SelectFieldsComponent