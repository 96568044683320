import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, validateLink } from './apiclient';
import {  setAlert } from '../../redux/features/user/userslice';
//import './reset.scss';
import './login.scss'
import sha256 from 'js-sha256'
import AlertMessage from '../alerts';

const ResetPasswordMain = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const userNo = new URLSearchParams(search).get("un")
  const userData = useSelector((state) => state.user)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (password === "" || confirmPassword === "") {
      dispatch(setAlert({
        visible: true,
        message: "Validation error#New/confirmation password should not be empty",
        type: "danger",
        keyid: Math.random()
      }))
    }

    else if (password.length < 8 && confirmPassword.length < 8) {
      dispatch(setAlert({
        visible: true,
        message: "Validation error#New/confirmation password should be more than or equal to 8",
        type: "danger",
        keyid: Math.random()
      }))

    }

   else if (password === confirmPassword && (password !== "" || confirmPassword !== "")) {
      let licenseNos = []
      if(userData?.user?.user_details && userData?.user?.user_details?.licence_ids) {
        userData?.user.user_details.licence_ids.map(function(license, index){
          licenseNos.push(license.licence_id)
        })
      }
      let payload = {
        user_no: userNo,
        password: sha256(password),
        stno: userData?.appDetails?.sectorNo,
        username: userData?.user?.user_details?.username,
        licenses: licenseNos,
        token: token,
        appliedFilter: {}
      }
      changePassword(payload, dispatch, navigate)
    }
    else {
      dispatch(setAlert({
        visible: true,
        message: "Validation error#Your confirmation password did not match the new password, please try again.",
        type: "danger",
        keyid: Math.random()
      }))
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event);
    }
  };

  useEffect(() => {

    validateLink({ token: token, user_no: userNo }, dispatch,navigate)
  }, [])


  return (
    <div id="container" class="container">
      <div>
        <div>
          <div className="reset-password">
            <div id="maps"></div>
            <Row className="loginContainer">
              <div id="message-container">
              <AlertMessage />
              </div>
              <Col className="container-fluid login-main-container">
                <Row className="main-row-container">
                  <Col lg="12" className="align-items-top justify-content-center mt-0 pt-0 pt-md-5 row pr-1">
                    <Col xl="6" lg="8" md="10" sm="10" xs="12" className="pr-0 mr-0">
                      <Row>
                        <Col className="resetForm">
                          <div className="logo-container"></div>
                          <p className="mt-4">Reset password</p>
                          <Form onKeyDown={handleKeyDown}>
                            <FormGroup>
                              <div className="icon-addon addon-lg">
                                <Input
                                  type="password"
                                  name="password"
                                  id="password"
                                  placeholder="New password"
                                  onChange={handlePasswordChange}
                                />

                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="icon-addon addon-lg">
                                <Input
                                  type="password"
                                  name="confirm_password"
                                  id="confirm_password"
                                  placeholder="Confirm password"
                                  onChange={handleConfirmPasswordChange}
                                />

                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Col lg="12" className="mt-3 p-0">
                                <button onClick={handleSubmit} id="change_password" className="btn-block primary-btn">
                                  Change password
                                </button>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordMain;