import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { displayCurrency, formatUrlWithProtocol, getCompanyAddress, getFormatedDate, isValidText } from '../../../utils'
import { favourite } from '../../details/apiclient'
import TagShareNoteCount from '../../details/list/tagShareNoteCount'
import { fetchRolesForCompanyId } from '../../search/apiclient'
import { updateBackObjectId, updateCompanyDetail, updateIsSelectedToSpecificRow, updateObjectId, updateRemoveFilterToggle, updateShowHierarchy } from '../../search/redux/reducer'
import { convertMilesToYards } from '../../search/utils'
import Icon from '../../common/js/icon'
import { updateIsSelectedToSpecificRowMyLeads, updateMyLeadsShowHierarchy } from '../../myleads/reducer'
import { fetchUpdatesResultsbyID } from '../../myleads/apiclient'
import ColoredScrollbars from '../../common/js/coloredScrollbars'
import { summaryActivityDetail, updateActivityIsClicked } from '../../details/redux/reducer'
import { updateIsSelectedToSpecificRowLeagueTable, updateLeagueShowHierarchy } from '../../leaguetable/reducer'
import { fetchLeagueRolesForCompanyId } from '../../leaguetable/apiclient'
import { MYLEADS_PREVNEXT_HIERARCHY_LIST, MYLEADS_PREVNEXT_LIST } from '../../../utils/settings'
import { TOURCOMPANYLISTVIEW } from '../../../utils/tour'
import { updateCompanyDetailJA, updateIsSelectedToSpecificRowForJustAsk, updateRemoveFilterToggleJA, updateShowHierarchyForJustAsk, updateBackObjectId as jAskupdateBackObjectId } from '../../justask/search/redux/reducer'

const CompanyRow = (props) => {
  let ifMyleadsExist = MYLEADS_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
  let isLeagueTable = props.prevNextList === 'LeagueTableList' || props.prevNextList === 'companyHierarchicalLeagueTableList' ? true : false
  let ifMyleadsHierarchyExist = MYLEADS_PREVNEXT_HIERARCHY_LIST.includes(props.prevNextList) ? true : false
  const search = useSelector((state) => state.search)
  const searchPersist = useSelector((state) => state.searchPersist)
  const justAskPersist = useSelector((state) => state.justAskPersist)
  const { hash } = useLocation()
  const projects = useSelector((state) => state.project)
  const justask = useSelector((state) => state.justAsk)
  const myleads = useSelector((state) => state.myleads)
  const leagueTableState = useSelector((state) => state.leagueTable)
  const settings = useSelector((state) => state.settings)
  const userData = useSelector((state) => state.user)
  const filter = useSelector((state) => state.filter)
  const [searchListView, setSearchListView] = useState(searchPersist?.isListView)
  const [myLeadsListView, setMyLeadsListView] = useState(myleads?.isListView)
  const [justAskListView, setJustAskListView] = useState(justAskPersist?.isListView)
  const [companyShowMore, setCompanyShowMore] = useState(false);
  const [allUpdateCount, setAllUpdateCount] = useState(props.company?.update_count)
  const [allUpdateList, setAllUpdateList] = useState([])
  const [activeTab, setActiveTab] = useState(ifMyleadsExist ? hash.replace('#', '') : "")
  const [currentID, setCurrentId] = useState(null)
  const location = useLocation()
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const activeFilterValue = (props.prevNextList === 'myLeadsTags' || props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsArchive') ? searchPersist.leadsAppliedContext[props?.activeTab] : isLeagueTable ? 'company' : props?.prevNextList === "jAskSearchList" ? justAskPersist.appliedContext : searchPersist?.appliedContext
  useEffect(() => {
    let page = document.getElementsByTagName('body')[0];
    if (search.backObjectId && search.backObjectId !== undefined && search.backObjectId !== 0) {
      let elmnt = document.getElementById(search.backObjectId);
      if (elmnt && elmnt != undefined) {
        elmnt.scrollIntoView()
        page.classList.add('noscroll');
        dispatch(updateBackObjectId(undefined))
      }
      else {
        page.classList.remove('noscroll');
      }
    }
  }, [search.backObjectId])

  useEffect(() => {
    let page = document.getElementsByTagName('body')[0];
    if (justask.backObjectId && justask.backObjectId !== undefined && justask.backObjectId !== 0) {
      let elmnt = document.getElementById(justask.backObjectId);
      if (elmnt && elmnt != undefined) {
        elmnt.scrollIntoView()
        page.classList.add('noscroll');
        dispatch(jAskupdateBackObjectId(undefined))
      }
      else {
        page.classList.remove('noscroll');
      }
    }
  }, [justask.backObjectId])

  const handleFavourite = (event, project) => {
    event.preventDefault()
    event.stopPropagation()
    let ifMyleadsFavExist = (props.prevNextList === 'myLeadsTags' || props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsArchive') ? true : false
    let searchListFav = ifMyleadsFavExist ? 'myLeadsFav' : isLeagueTable ? 'leagueTableFav' : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" || props.prevNextList === 'projectHierarchicalSearchListJA' || props.prevNextList === 'companyHierarchicalSearchListJA' ? "jaskListFav" : 'searchListFav'
    let payload = {
      favourite: project && project.favourite ? false : true,
      objectId: project && project.company_id,
      objectType: 'company',
    }

    favourite(payload, dispatch, ifMyleadsFavExist ? myleads : isLeagueTable ? leagueTableState : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" || props.prevNextList === 'projectHierarchicalSearchListJA' || props.prevNextList === 'companyHierarchicalSearchListJA' ? justask : search, userData, searchListFav, settings, null, null, search, props.prevNextList)
  }

  const handleRolesForCompanyId = (company) => {
    isLeagueTable ? fetchLeagueRolesForCompanyId(company.company_id, search, searchPersist, dispatch, leagueTableState?.removeFilterToggle) : fetchRolesForCompanyId(company.company_id, props?.prevNextList === "jAskSearchList" ? justask : search, searchPersist, dispatch)

    if (props?.prevNextList === 'jAskSearchList') {
      dispatch(updateCompanyDetailJA(company))
      dispatch(updateRemoveFilterToggleJA(true))
    }
    else {
      dispatch(updateCompanyDetail(company))
      dispatch(updateRemoveFilterToggle(true))
    }

    if (ifMyleadsExist) {
      dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }))
    }
    else if (isLeagueTable) {
      dispatch(updateShowHierarchy({ showCompanyHierarchy: true }))
      dispatch(updateLeagueShowHierarchy({ showCompanyHierarchy: true }))
    }
    else if (props?.prevNextList === 'jAskSearchList') {
      dispatch(updateShowHierarchyForJustAsk({ showProjectHierarchy: true, showCompanyHierarchy: false }))

    }
    else {
      dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: true }))
    }
  }

  const handleDetails = (event, companyId) => {
    event.preventDefault();
    dispatch(updateActivityIsClicked(false))
    dispatch(summaryActivityDetail("tags"))
    let routingState = {
      prevNextList: props.prevNextList,
      showMoreResult: true,
      index: props.index,
      lastRelease: getFormatedDate(props?.company?.max_project_last_published, 'DD-MMM-YYYY'),
      dist: convertMilesToYards(props?.company?.company_distance),
      list: props.prevNextList === 'searchList' ? search?.searchList
        : props.prevNextList === 'selectedSearchList' ? search?.listOfSelectedObjectIds : props?.prevNextList === "selectedjAskSearchList" ? justask.listOfSelectedObjectIds
          : props.prevNextList === 'companyHierarchicalSearchList' || props.prevNextList === 'companyHierarchicalSearchListJA' ? [props.company]
            : props.prevNextList === 'projectHierarchicalSearchList' || ifMyleadsHierarchyExist ? search.projectHierarchicalRoles : props.prevNextList === 'projectHierarchicalSearchListJA' ? justask.projectHierarchicalRoles
              : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList : myleads.searchList
                : isLeagueTable ? leagueTableState?.searchList
                  : [],
      activeTab: props.prevNextList === 'myLeadsUpdates' ? '5' : '2'
    }
    navigate(`/app/company/0/${companyId}`, { state: routingState });
  }

  const handleCheckboxChange = (value) => {
    let payload = {
      id: value,
      isSelected: !props.company.is_selected,
      filterType: 'company',
      isShowRelatedEntitiesMode: props.isShowRelatedEntitiesMode,
      entityType: props.prevNextList
    }
    if (props.prevNextList === 'searchList' || props.prevNextList === 'selectedSearchList') {
      dispatch(updateIsSelectedToSpecificRow(payload))
    }
    else if (isLeagueTable) {
      dispatch(updateIsSelectedToSpecificRowLeagueTable(payload))
    }
    else if (props.prevNextList === "jAskSearchList" || props.prevNextList === "selectedjAskSearchList") {
      dispatch(updateIsSelectedToSpecificRowForJustAsk(payload))
    }
    else {
      dispatch(updateIsSelectedToSpecificRowMyLeads(payload))
    }
  }

  const mouseEvent = (data) => {
    if (props.myHoverList != undefined) {
      props.myHoverList(data);
    }
  }

  const toggleCompanyShowMore = (id) => {
    setCompanyShowMore(!companyShowMore);
    setCurrentId(id)
    fetchUpdatesResultsbyID(id, searchPersist, dispatch);
  }

  useEffect(() => {
    if (ifMyleadsExist) {
      if (myleads.isListView) {
        setSearchListView(false)
        setMyLeadsListView(true)
      } else {
        setMyLeadsListView(false)
        setSearchListView(false)
      }
    } else {
      if (searchPersist.isListView) {
        setMyLeadsListView(false)
        setSearchListView(true)
      }
      else if (justAskPersist.isListView) {
        setJustAskListView(true)
        setMyLeadsListView(false)
      }
      else {
        setMyLeadsListView(false)
        setSearchListView(false)
        setJustAskListView(false)
      }
    }

  }, [searchPersist.isListView, myleads.isListView, justAskPersist.isListView])

  useEffect(() => {
    let newarray = [];
    if (currentID !== null && newarray.length === 0) {
      if (myleads.myLeadsAllUpdates.length > 1) {
        newarray = myleads?.myLeadsAllUpdates && myleads?.myLeadsAllUpdates.filter(elem => elem.objectId === currentID);
      }
      else {
        newarray = myleads.myLeadsAllUpdates
      }
    }
    setAllUpdateList(newarray)

  }, [myleads.myLeadsAllUpdates, currentID])


  return (
    <div className="position-relative">
      {props && props.isHierarchy ? '' :
        <label className='container'>
          <Input
            value={props.company?.company_id}
            id={props.company?.company_id}
            type="checkbox" onChange={() => handleCheckboxChange(props.company?.company_id)}
            checked={props.company?.is_selected}
          />
          <span className="checkmark" for={props.company?.company_id}></span>
        </label>
      }
      <div className={`company-container ${props.company?.read ? '' : 'read-block'} ${ifMyleadsExist ? myleads.isListView ? '' : 'mapViewRow' : isLeagueTable ? '' : searchPersist.isListView ? '' : 'mapViewRow'}`}>
        {
          props.company?.company_linkedin ?
            (
              <a href={formatUrlWithProtocol(props.company.company_linkedin)} className={`linkedin-icon ${ifMyleadsExist ? myleads.isListView ? 'linkedin-list-view' : '' : searchPersist.isListView ? 'linkedin-list-view' : ''} ${isLeagueTable ? 'linkedin-list-view' : ''}`} target="_blank"></a>
            ) :
            ('')
        }
        {
          search.showSelectedCompanies && searchPersist.appliedContext === 'project' || justask.showSelectedCompanies && justAskPersist.appliedContext === 'project' || props?.isHierarchy || (ifMyleadsExist && searchPersist.leadsAppliedContext[props?.activeTab] === 'company') ? '' :
            <Button className="related-Company-btn" onClick={() => handleRolesForCompanyId(props.company)}></Button>
        }

        {
          props?.company?.update_count >= 2 ? (
            <Button className={`toggleButtonAllUpdate ${companyShowMore ? 'updateDetailsEnable' : ''}`} onClick={() => toggleCompanyShowMore(props.company.company_id)}></Button>
          )
            : ""
        }

        {props.index == 0 && location.pathname == "/app/list" ?
          (<div className={`hori-placeholder-tour ${!searchPersist.isListView ? "left-right-zero" : ""}`} data-intro={!filter.isFilterActive ? TOURCOMPANYLISTVIEW.listview.intro : null} data-position={TOURCOMPANYLISTVIEW.listview.position} data-class={TOURCOMPANYLISTVIEW.listview.class}>&nbsp;</div>) :
          ('')}
        <div className="linkWrapper">
          <Link onMouseOver={() => mouseEvent(props.company)} onMouseOut={() => mouseEvent({})} className="result-block pl-0 pr-0" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls={props?.company?.company_id} onClick={(event) => { handleDetails(event, props?.company?.company_id) }} to={`/app/company/0/${props?.company?.company_id}`}>
            <div>
              {
                searchListView || myLeadsListView || isLeagueTable || justAskListView ?
                  <div className={`listviewtable-block ${props.prevNextList === 'LeagueTableList' ? '' : 'ps-20'}`}>
                    <div className="list-table">
                      <div className="list-tablerow d-flex">
                        <div className={`list-col list-name ${props.prevNextList === 'LeagueTableList' ? 'w-35' : props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsTags' ? 'w-25' : 'w-30'}`}>
                          <div className="listNameIcon">
                            <div className="result-row-icon-placeholder">
                              <span className="mb-0 icon-company"><Icon size={35} icon="icon-gen-company-list" className="me-2 company-icon" /></span>
                              <div className="result-row-button-block ml-2">
                                <div className="right-block">
                                  <div className="">
                                    <button onClick={(e) => handleFavourite(e, props && props.company)} className='user-ctrl-btn label-favourite-icon'>
                                      {
                                        (<>
                                          {
                                            props && props.company && props.company.favourite ?
                                              <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                              <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                          }
                                        </>
                                        )
                                      }
                                      {
                                      }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h5 className="result-title-name mb-1">{props && props.company && props.company.company_name}</h5>
                            {
                              props && props.company && props.company.tags_count != 0 ||
                                props && props.company && props.company.shares_count != 0 ||
                                props && props.company && props.company.mail && props.company.mailed_on ||
                                props && props.company && props.company.notes_count != 0 ?
                                (<TagShareNoteCount entity={props.company} />) : ("")
                            }
                          </div>
                          {
                            <Row className={`com-address-print ${projects.isPrint ? '' : 'hide'}`}>
                              {isValidText(props && props.company && props.company.company_address1)
                                || isValidText(props && props.company && props.company.company_address2)
                                || isValidText(props && props.company && props.company.company_address3)
                                || isValidText(props && props.company && props.company.company_address4)
                                || isValidText(props && props.company && props.company.company_postcode) ?
                                (<Col
                                  sm={`${projects.isPrint ? '12' : '12'}`}
                                  md={`${projects.isPrint ? '12' : '12'}`}
                                  lg={`${isValidText(props && props.company && props.company.company_phone) ? '12' : '12'}`}
                                  className="col comp-address">
                                  <div className="field-value">
                                    {getCompanyAddress(props && props.company)}
                                  </div>
                                </Col>) : ('')}
                            </Row>
                          }
                        </div>
                        <div className={`list-col list-client ${props.prevNextList === 'LeagueTableList' ? 'w-20' : props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsTags' ? 'w-20' : 'w-25'}`}>
                          {props.roleName === undefined ?
                            (<div className={`sub-resultdetails-content`}>
                              {props && props.company && props.company.company_address1 || props && props.company && props.company.company_address2 || props && props.company && props.company.company_address3 || props && props.company && props.company.company_address4 || props && props.company && props.company.postCode ?
                                (
                                  <span className="rs-address">{props && props.company && props.company.company_address1 && props && props.company && props.company.company_address1 + ", "} {props && props.company && props.company.company_address2 && props && props.company && props.company.company_address2 + ", "} {props && props.company && props.company.company_address3 && props && props.company && props.company.company_address3 + ", "} {props && props.company && props.company.company_address4 && props && props.company && props.company.company_address4 + ", "} {props && props.company && props.company.company_postcode && props && props.company && props.company.company_postcode}</span>
                                ) : ("")
                              }
                            </div>) :
                            (
                              <div className='sub-resultdetails-content'>
                                <div className="rs-roles">
                                  {isValidText(props.company.role_desc) ? props.company.role_desc.trim().replace(/,*$/, '') : ''}
                                </div>
                              </div>
                            )
                          }
                        </div>
                        <div className='list-col list-value w-10'>
                          {
                            props && props.company && props.company.company_phone ?
                              (
                                <span className="rs-address rs-phone">
                                  {props && props.company && props.company.company_phone}
                                  {
                                    props && props.company && props.company.company_phone_tps ?
                                      (
                                        <span className="tps-badge">TPS</span>
                                      )
                                      : ("")
                                  }
                                </span>
                              )
                              : ("-")
                          }
                        </div>
                        <div className='list-col list-value w-20'>
                          {
                            props && props.company && props.company.company_website ? (<span className="rs-phone">
                              {props && props.company && props.company.company_website}
                            </span>) : ("-")
                          }
                        </div>
                        {
                          props?.prevNextList === 'myLeadsUpdates' ?
                            (<div className="list-col list-updated w-20">
                              <span className="src-date">{props.company.description}</span>
                              <span className="updatesTxt font-weight-bold">{props.company.update_count ? props.company.update_count - 1 == 0 ? '' : '+' + (props.company.update_count - 1) + ' more' : ''} </span>
                            </div>) : ('')
                        }
                        {
                          !isLeagueTable && ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'Projects'
                            ? (
                              <div className={`list-col list-contract w-10`}>
                                {props?.company?.count_project_id ?
                                  (<span className={`src-date`}><span className="src-latest">{props && props.company && props.company.count_project_id}</span></span>) :
                                  ""}

                              </div>
                            ) : !isLeagueTable && ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'value'
                              ? (
                                <div className={`list-col list-contract w-10`}>
                                  {props?.company?.sum_project_value ?
                                    (<span className={`src-value`}><span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span></span>) :
                                    ""}
                                </div>
                              ) : ""


                        }




                        {
                          !ifMyleadsExist && (search?.showSelectedCompanies && searchPersist?.heirarchialSort?.["company"].label.toLowerCase() === 'projects' || justask?.showSelectedCompanies && justAskPersist?.heirarchialSort?.["company"].label.toLowerCase() === 'projects') ?
                            (
                              <div className={`list-col list-contract w-10`}>
                                {props && props.company && props.company.count_project_id ?
                                  (<span className={`src-date`}><span className="src-latest">{props && props.company && props.company.count_project_id}</span></span>) :
                                  ""}
                              </div>
                            ) : !ifMyleadsExist && (search?.showSelectedCompanies && searchPersist?.heirarchialSort?.["company"].label.toLowerCase() === 'value' || justask?.showSelectedCompanies && justAskPersist?.heirarchialSort?.["company"].label.toLowerCase() === 'value') ?
                              (
                                <div className={`list-col list-contract w-10`}>
                                  {props && props.company && props.company.sum_project_value ?
                                    (<span className={`src-value`}><span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span></span>) :
                                    ""}
                                </div>
                              ) :
                              !isLeagueTable && !ifMyleadsExist && (searchPersist?.defaultSort[activeFilterValue]?.label.toLowerCase() === 'projects' || justAskPersist?.defaultSort[activeFilterValue]?.label.toLowerCase() === 'projects') ? (
                                <div className={`list-col list-contract w-10`}>
                                  {props && props.company && props.company.count_project_id ?
                                    (<span className={`src-date`}><span className="src-latest">{props && props.company && props.company.count_project_id}</span></span>) :
                                    ""}
                                </div>
                              )
                                : !isLeagueTable && !ifMyleadsExist && (searchPersist?.defaultSort[activeFilterValue]?.label === 'Value' || justAskPersist?.defaultSort[activeFilterValue]?.label === 'Value') ?
                                  (
                                    <div className={`list-col list-contract w-10`}>
                                      {props && props.company && props.company.sum_project_value ?
                                        (<span className={`src-value`}><span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span></span>) :
                                        ""}
                                    </div>
                                  )

                                  :
                                  (!isLeagueTable && !ifMyleadsExist && (searchPersist.defaultSort[activeFilterValue].label === 'nearest' && !search?.showSelectedCompanies && props?.company?.company_distance || justAskPersist.defaultSort[activeFilterValue].label === 'nearest' && !justask?.showSelectedCompanies && props?.company?.company_distance)) ? (
                                    <div className={`list-col list-contract w-10`}>
                                      {props && props.company && props.company.company_distance ?
                                        (<span className={`src-date}`}><span className="src-latest">{convertMilesToYards(props && props.company && props.company.company_distance)}</span></span>) :
                                        ""}
                                    </div>
                                  ) :

                                    (!isLeagueTable && ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'nearest' && !search?.showSelectedCompanies && props?.company?.company_distance) ? (
                                      <div className={`list-col list-contract w-10`}>
                                        {props && props.company && props.company.company_distance ?
                                          (<span className={`src-date}`}><span className="src-latest">{convertMilesToYards(props && props.company && props.company.company_distance)}</span></span>) :
                                          ""}
                                      </div>
                                    )


                                      : isLeagueTable ? (
                                        <>
                                          <div className={`list-col list-contract w-10`}>
                                            {props?.company?.count_project_id ?
                                              (<span className={`src-date`}><span className="src-latest">{props?.company?.count_project_id}</span></span>) :
                                              ""}
                                          </div>
                                          <div className={`list-col list-contract w-10`}>
                                            {props?.company?.sum_project_value ?
                                              (<span className={`src-date`}><span className="src-latest">{displayCurrency(props?.company?.sum_project_value, props?.company?.any_project_value_estimated)}</span></span>) :
                                              ""}
                                          </div>
                                        </>
                                      )
                                        : (
                                          <div className={`list-col list-contract w-10`}>
                                            {props.prevNextList !== 'myLeadsUpdates' && props.prevNextList !== 'myLeadsHistory' && props && props.company && props.
                                              company.max_project_last_published ?
                                              (<span className='src-date'><span className="src-latest">{getFormatedDate(props && props.company && props.company.max_project_last_published, 'DD-MMM-YYYY')}</span></span>) :
                                              ifMyleadsExist && props && props.company && props.company?.updated_on && props.prevNextList === 'myLeadsUpdates' ?
                                                (<span className='src-date'><span className="src-latest">{getFormatedDate(props && props.company && props.company.updated_on, 'DD-MMM-YYYY')}</span></span>) :
                                                props && props.company?.activity_date && props.prevNextList === 'myLeadsHistory' ?
                                                  (<span className='src-date'><span className="src-latest">{getFormatedDate(props.company.activity_date, 'DD-MMM-YYYY')}</span></span>) :

                                                  props && props.company && props.company?.project_last_published ?
                                                    (<span className='src-date'><span className="src-latest">{getFormatedDate(props && props.company && props.company.project_last_published, 'DD-MMM-YYYY')}</span></span>) : ""}
                                          </div>
                                        )
                        }
                      </div>
                    </div>
                  </div>
                  :
                  (
                    <>
                      <div className="result-row-icon-placeholder">
                        <span className="mb-0 icon-company"><Icon size={35} icon="icon-gen-company-list" className="me-2 company-icon" /></span>
                        <div className="result-row-button-block ml-2">
                          <div className="right-block">
                            <div className="">
                              <button onClick={(e) => handleFavourite(e, props && props.company)} className='user-ctrl-btn label-favourite-icon'>
                                {
                                  <>
                                    {
                                      props && props.company && props.company.favourite ?
                                        <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                        <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                    }
                                  </>

                                }
                                {
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`result-row-overview`}>
                        <h5 className="result-title-name mb-1">{props && props.company && props.company.company_name}</h5>


                        <div className="sub-resultdetails-content">
                          {props.roleName === undefined ? "" :
                            <div className="rs-roles">
                              {isValidText(props.company.role_desc) ? props.company.role_desc.trim().replace(/,*$/, '') : ''}
                            </div>
                          }

                          {props?.prevNextList !== 'myLeadsUpdates' ?

                            props && props.company && props.company.company_address1 || props && props.company && props.company.company_address2 || props && props.company && props.company.company_address3 || props && props.company && props.company.company_address4 || props && props.company && props.company.postCode ?
                              (
                                <span className={`rs-address ${!searchPersist.isListView && props.isHierarchy ? 'hide' : ""}`}>{props && props.company && props.company.company_address1 && props && props.company && props.company.company_address1 + ", "} {props && props.company && props.company.company_address2 && props && props.company && props.company.company_address2 + ", "} {props && props.company && props.company.company_address3 && props && props.company && props.company.company_address3 + ", "} {props && props.company && props.company.company_address4 && props && props.company && props.company.company_address4 + ", "} {props && props.company && props.company.company_postcode && props && props.company && props.company.company_postcode}</span>
                              ) : ("")

                            : ""}

                          {props?.prevNextList !== 'myLeadsUpdates' &&
                            props && props.company && props.company.company_phone ?

                            <span className={`rs-address rs-phone ${!searchPersist.isListView && props.isHierarchy ? 'hide' : ""}`}>
                              {props && props.company && props.company.company_phone}
                              {
                                props && props.company && props.company.company_phone_tps ?
                                  (
                                    <span className="tps-badge">TPS</span>
                                  )
                                  : ("")
                              }
                            </span>

                            : ("")
                          }
                          {props?.prevNextList !== 'myLeadsUpdates' &&
                            props && props.company && props.company.company_website ? (<span className={`rs-phone ${!searchPersist.isListView && props.isHierarchy ? 'hide' : ""}`}>
                              {props && props.company && props.company.company_website}
                            </span>) : ("")
                          }
                        </div>
                        {props?.prevNextList === 'myLeadsUpdates' ? (<div className="rs-address">
                          {isValidText(props.company.description) ? (<span>{props.company.description} </span>) :
                            (<span>-</span>)
                          }
                          {
                            <span className="updatesTxt font-weight-bold">{props?.company?.update_count ? props.company.update_count - 1 == 0 ? '' : '+' + (props.company.update_count - 1) + ' more' : ''} </span>
                          }
                        </div>) : ""}
                        {props?.prevNextList !== 'myLeadsUpdates' ?
                          props && props.company && props.company.company_address1 || props && props.company && props.company.company_address2 || props && props.company && props.company.company_address3 || props && props.company && props.company.company_address4 || props && props.company && props.company.postCode ?
                            <span className="src-location-placeholder">{props && props.company && props.company.company_address3 && props && props.company && props.company.company_address3}</span>
                            : ("")
                          : ""}

                        {!ifMyleadsExist && searchPersist?.defaultSort[activeFilterValue]?.label === 'nearest' && !search?.showSelectedCompanies && props?.company?.company_distance ? (
                          <span className={`src-date`}>
                            <span className="src-miles">
                              <Icon color="#292B2C" size={20} icon='icon-gen-arrow-cross' className="me-1" />
                              {convertMilesToYards(props?.company?.company_distance)}
                            </span>
                          </span>
                        )
                          :

                          search?.showSelectedCompanies && searchPersist?.heirarchialSort["company"]?.label === 'Projects' || justask?.showSelectedCompanies && justAskPersist?.heirarchialSort["company"]?.label === 'Projects' ? (
                            <span className='src-date'>
                              <span className="src-count">{props?.company?.count_project_id}</span>
                            </span>
                          ) :

                            search?.showSelectedCompanies && searchPersist?.heirarchialSort["company"]?.label === 'Value' && props?.company?.sum_project_value || justask?.showSelectedCompanies && justAskPersist?.heirarchialSort["company"]?.label === 'Value' && props?.company?.sum_project_value ? (
                              <span className='src-date'>
                                <span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span>
                              </span>
                            ) :

                              !ifMyleadsExist && (searchPersist?.defaultSort[activeFilterValue]?.label.toLowerCase() === 'projects' || justAskPersist?.defaultSort[activeFilterValue]?.label.toLowerCase() === 'projects') ? (
                                <span className='src-date'>
                                  <span className="src-count">{props?.company?.count_project_id}</span>
                                </span>
                              ) :

                                !ifMyleadsExist && (searchPersist?.defaultSort[activeFilterValue]?.label === 'Value' && props?.company?.sum_project_value || justAskPersist?.defaultSort[activeFilterValue]?.label === 'Value' && props?.company?.sum_project_value) ? (
                                  <span className='src-date'>
                                    <span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span>
                                  </span>
                                ) :

                                  ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'Projects' ? (
                                    <span className='src-date'>
                                      <span className="src-count">{props?.company?.count_project_id}</span>
                                    </span>
                                  ) :

                                    ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'value' && props?.company?.sum_project_value ? (
                                      <span className='src-date'>
                                        <span className="src-value">{displayCurrency(props?.company?.sum_project_value)}</span>
                                      </span>
                                    ) :

                                      ifMyleadsExist && searchPersist?.leadsSort?.[activeTab]?.[activeFilterValue]?.label === 'nearest' && !search?.showSelectedCompanies && props?.company?.company_distance ? (
                                        <span className={`src-date`}>
                                          <span className="src-miles">
                                            <Icon color="#292B2C" size={20} icon='icon-gen-arrow-cross' className="me-1" />
                                            {convertMilesToYards(props?.company?.company_distance)}
                                          </span>
                                        </span>
                                      ) :

                                        props?.prevNextList !== 'myLeadsUpdates' && props?.prevNextList !== 'myLeadsHistory' && props.company?.max_project_last_published && searchPersist?.defaultSort[activeFilterValue]?.label !== 'Value' && searchPersist?.defaultSort[activeFilterValue]?.label.toLowerCase() !== 'projects' ? (
                                          <span className='src-date'>
                                            <span className="src-latest">
                                              {getFormatedDate(props?.company?.max_project_last_published, 'DD-MMM-YYYY')}
                                            </span>
                                          </span>
                                        ) :
                                          props && props.isHierarchy && props.company && props.company.role_added ? (
                                            <span className='src-date'>
                                              <span className="src-latest role-added">
                                                {getFormatedDate(props.company.role_added, 'DD-MMM-YYYY')}
                                              </span>
                                            </span>
                                          ) :

                                            props?.prevNextList === 'myLeadsUpdates' && props.company?.updated_on ? (
                                              <span className='src-date'>
                                                <span className="src-latest">
                                                  {getFormatedDate(props?.company?.updated_on, 'DD-MMM-YYYY')}
                                                </span>
                                              </span>
                                            ) :
                                              props && props.company?.activity_date && props.prevNextList === 'myLeadsHistory' ? (
                                                <span className='src-date'>
                                                  <span className="src-latest">
                                                    {getFormatedDate(props.company.activity_date, 'DD-MMM-YYYY')}
                                                  </span>
                                                </span>
                                              ) :
                                                ifMyleadsExist && props && props.company?.project_last_published ? (
                                                  <span className='src-date'>
                                                    <span className="src-latest">
                                                      {getFormatedDate(props.company.project_last_published, 'DD-MMM-YYYY')}
                                                    </span>
                                                  </span>
                                                ) : ""
                        }

                        {
                          props && props.company && props.company.tags_count != 0 ||
                            props && props.company && props.company.shares_count != 0 ||
                            props && props.company && props.company.mail && props.company.mailed_on ||
                            props && props.company && props.company.notes_count != 0 ?
                            (<TagShareNoteCount entity={props.company} />) : ("")
                        }



                      </div>

                    </>
                  )
              }
            </div>

          </Link>
          {

            ((props?.prevNextList === 'myLeadsUpdates') && companyShowMore) && (
              <>
                {props.company.update_count >= 2 && myleads.myLeadsAllUpdates.length !== 0 ?
                  props.company.update_count < 6 ?
                    <div className="updates-details">
                      {allUpdateList[0]?.updates.map(function (update, index) {
                        if (index <= allUpdateCount - 1 && index > 0) {
                          return (
                            <div className="update-list" key={index}>
                              <span className="update-desc">{update.description}</span>
                              <span className="time">{getFormatedDate(update.updated_on, 'DD-MMM-YYYY')}</span>
                            </div>
                          )
                        }
                      })}
                    </div> :
                    <div className="updates-details scrollbar">
                      <ColoredScrollbars>
                        {allUpdateList[0]?.updates.map(function (update, index) {
                          if (index <= allUpdateCount - 1 && index > 0) {
                            return (
                              <div className="update-list" key={index}>
                                <span className="update-desc">{update.description}</span>
                                <span className="time">{getFormatedDate(update.updated_on, 'DD-MMM-YYYY')}</span>
                              </div>
                            )
                          }
                        })}
                      </ColoredScrollbars>
                    </div> : ("")
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default CompanyRow