import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { InputGroup, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import IncludeExcludeGrid from '../includeExcludeGrid';

const ProjectRegionsCountiesFilter = (props) => {
    const searchPersist = useSelector((state) => state.searchPersist)
    const filter = useSelector((state) => state.filter)
    const [projectRegion, setProjectRegion] = useState(searchPersist?.customerProfile['project_geocode']?.values)
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_geocode']?.all_subscribed)
    const [showSubscribed, setShowSubscribed] = useState(false)
    const [isClearAll , setIsClearAll] = useState(props?.isClearAll)

    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
    }, [props?.isClearAll])


    return (
        <div className="advanced-filter-grid" id="new_filter_prcs">
            <div className="filterHeading">
                <h5 className="mb-3">Project Regions & Counties</h5>
            </div>
            <div className="filterBlock">
                <div className="textcontent">
                    Filter by selecting project regions and counties
                </div>
                <div className="checkBoxFilterBx">
                    <FormGroup id="project-region-filter" name="category-filter" row className="pb-0 mb-0">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container px-0">
                            <IncludeExcludeGrid filter={props.isExclude} filterData={projectRegion}
                                allSubscribed={allSubscribed} initTags={props.initTags}
                                showAll={showAll} filterType="project_geocode" regionType={'regions'}
                                filterTitle={'Project regions'}
                                includeFilters={props.currentAdvancedSearch['regions']}
                                variablesHandler={props.variablesHandler} 
                                fetchEntityCounts={props.fetchEntityCounts}
                                showSubscribed={showSubscribed}
                                isClearAll={isClearAll}
                                handleSearchText={props.handleSearchText}
                            />
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

export default ProjectRegionsCountiesFilter;
