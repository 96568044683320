import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroup } from 'reactstrap';
import { sendIntercomRequest } from '../apiclient';

function RequestDataModal(props) {
    const [requestDataModal, setRequestDataModal] = useState(props.requestDataModalShow);
    const [requestDataValue, setRequestDataValue] = useState('');
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const handleRequestDataChange = (event) => {
        setRequestDataValue(event.target.value.length > 0 ? event.target.value : '');
    };

    const handleRequestDataToggle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRequestDataModal(!requestDataModal);
        setRequestDataValue('');
        props.handleRequestDataToggle(event);
    };

    const sendIntercomRequestData = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let payload = 
            {
                body: `Customer request data\nUsername: ${userData.user.username}\nCompany: ${userData.user.company_name}\nClientNo: ${userData.user.client_id}\nFilter: ${props.filterTitle}\n======================\n ${requestDataValue}`,
                from: {
                    "type": "user",
                    "user_id": userData.user.user_id
                },
                filterType: props.filterTitle
            }
        sendIntercomRequest(payload, dispatch);
        handleRequestDataToggle(event);
    };


    return (
        <div className={`feedback-panel requestdata-modal d-block ${requestDataModal ? 'show' : 'hide'}`}>
            <div className="note-list-wrapper">
                <h6 className="mb-2">Request data</h6>
                <InputGroup className="note-form">
                    <div className="note-input">
                        <textarea maxLength="4000" rows="4" placeholder="Add optional message and submit" value={requestDataValue} onChange={handleRequestDataChange}></textarea>
                    </div>
                </InputGroup>
            </div>
            <button className="primary-btn btn-send" onClick={(e) => sendIntercomRequestData(e)}>Send</button>
            <button className="outline-btn btn-feedback-cancel" onClick={handleRequestDataToggle}>Cancel</button>
        </div>
    );
}

export default RequestDataModal;