import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, Row, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import SearchSelector from '../../../list/searchSelector';
import { fetchWorksWithCompanies, updateSelectorControlTags, updateSelectorFavUnfav } from '../../../apiclient';
import SearchListRow from '../../../list/searchListRow';
import TagsModal from '../../../list/tagsModal';
import { updateListSelection, updateSelectedTagDetails, updateShowMoreOffsetLimit } from '../../../redux/reducer';
import CommonPlaceholder from '../../../framework/commonPlaceholder';
import ColoredScrollbars from '../../../../common/js/coloredScrollbars';

const CompanyList = (props) => {
    const project = useSelector((state) => state.project)
    const [roles, setRoles] = useState(project && project.worksWithCompanies && project.worksWithCompanies.companies)
    const [modal, setModal] = useState(false)
    const [totalSelectedCount, setTotalSelectedCount] = useState(0)
    const userData = useSelector(state => state.user.user)
    const [activeTab, setActiveTab] = useState('')
    const [activeSort, setActiveSort] = useState('Latest')
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    const search = useSelector(state => state.search)
    const dispatch = useDispatch();

    const toggleTab = (tab) => {
        setActiveTab(tab)
    }

    useEffect(() => {
        setRoles(project && project.worksWithCompanies && project.worksWithCompanies.companies)
    }, [props, props.activeTab, project && project.worksWithCompanies])

    useEffect(() => {
        setTotalSelectedCount(project && project.selectorControlSelectionCount === undefined ? totalSelectedCount : project && project.selectorControlSelectionCount)
    }, [project && project.selectorControlSelectionCount])

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setModal(false)
        handleDropDownItemClick(0)
    }

    const handleTagsSubmit = () => {
        let objectIds = getUniqueSelectedList()
        if (objectIds && objectIds.length > 0) {
            let type = 'companies'
            let id = project && project.detailsPage && project.detailsPage.company_id
            const payload = {
                listName: 'work_with_companies',
                object_type: 'company',
                company_id: objectIds,
                objectId: id,
                ptno: props.tab,
                sort: activeSort === 'Latest' ? '-max_project_last_published' : activeSort,
                offset: 0,
                records: 50
            }
            if (project?.selectedTagsDetails?.tagIds?.length > 1 && project?.selectedTagsDetails?.tagIds?.includes(0)) {
                let tag_name = []
                let tag_id = []
                _.map(project?.selectedTagsDetails?.tagIds, (tagId, index) => {
                    if (tagId === 0) {
                        tag_name.push(project.selectedTagsDetails.tagDescriptions[index])
                    } else {
                        tag_id.push(project.selectedTagsDetails.tagIds[index])
                    }
                })
                if (tag_name.length) {
                    payload['tag_name'] = tag_name
                }
                if (tag_id.length) {
                    payload['tag_id'] = tag_id
                }
            } else if (project?.selectedTagsDetails?.tagIds?.includes(0)) {
                payload['tag_name'] = project.selectedTagsDetails.tagDescriptions
            } else {
                payload['tag_id'] = project.selectedTagsDetails.tagIds
            }
            handleTagsCancel()
            handleDropDownItemClick(0)
            updateSelectorControlTags(type, id, payload, dispatch)
        }
    }

    const handleFavChangeSelection = (action, count) => {
        let objectIds = getUniqueSelectedList()
        if (objectIds && objectIds.length > 0) {
            let object_type = 'companies'
            let id = project && project.detailsPage && project.detailsPage.company_id
            const payload = {
                identifier: 'work_with_companies',
                object_type: 'company',
                favourite: action === "Favourite" ? true : false,
                company_id: objectIds,
                objectId: id,
                ptno: props.tab,
                sort: activeSort === 'Latest' ? '-max_project_last_published' : activeSort,
                offset: 0,
                records: 50
            }
            updateSelectorFavUnfav(object_type, id, payload, dispatch)
            handleDropDownItemClick(0)
        }
    }

    const handleTagsSelection = () => {
        setModal(!modal)
    }

    const handleDownloadPDFSelection = () => {
        let objectIds = getUniqueSelectedList()
        if (objectIds.length > 0) {
            let payload = {
                "feedname": "Companies PDF",
                "setting": {
                    "collection": {
                        "key": objectIds,
                        "name": "",
                        "type": "sc"
                    },
                    "exportRecordsCount": objectIds.length,
                    "mediaType": "pdf",
                    "schedule": {
                        "frequency": "once"
                    },
                    "sizeOfDelivery": objectIds.length,
                    "sortBy": "Latest",
                    "type": "Companies"
                },
                "user_no": userData ? userData.user_no : 0
            }
        }
    }

    const handleDropDownItemClick = (count) => {
        dispatch(updateListSelection({ count, entityType: 'worksWithCompanies' }))
        if (count) {
            setDropDownOpen(false)
        }
    }

    const getTotalRows = () => {
        let totalCount = 0
        if (roles && roles.length > 0) {
            roles && roles.map(roleGroup => {
                totalCount++
            })
        }
        return totalCount
    }

    const getUniqueSelectedList = () => {
        let objectIds = []
        if (roles && roles.length > 0) {
            roles && roles.map(roleGroup => {
                if (roleGroup.is_selected == true) {
                    objectIds.push(roleGroup.company_id)
                }
            })
        }
        objectIds = [...new Set(objectIds)]
        return objectIds
    }

    const toggle = () => {
        setDropDownOpen(!dropdownOpen)
    }

    const handleCommonSort = (sort) => {
        setActiveSort(sort == '-count_project_id' ? 'Projects' : sort == '-sum_project_value' ? 'Value' : 'Latest')
        let worksWithCompaniesPayload = {
            "objectId": project.detailsPage.company_id,
            "ptno": props.tab,
            "offset": 0,
            "records": 50,
            "sort": sort
        }
        fetchWorksWithCompanies(worksWithCompaniesPayload, dispatch)
    }

    useEffect(() => {
        if (showMoreClicked) {
            let worksWithCompaniesPayload = {
                "objectId": project.detailsPage.company_id,
                "ptno": props.tab,
                "offset": project?.showMoreOffsetLimit?.offset,
                "records": project?.showMoreOffsetLimit?.records,
                "sort": activeSort
            }
            fetchWorksWithCompanies(worksWithCompaniesPayload, dispatch)
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);

    const handleShowMore = () => {
        let newOffset = project?.showMoreOffsetLimit?.offset + project?.showMoreOffsetLimit?.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: project?.showMoreOffsetLimit?.records }));
        setShowMoreClicked(true)
    }

    const loadRolesList = () => {
        return (
            <>
                {
                    roles && Object.keys(roles).length ? (
                        <div className="empty-view">
                            <div className="selected-col p-3"><p className="m-0 col-heading"><strong>{project && project.detailsPage && project.detailsPage.name}</strong> works with</p></div>
                            <div className="box-view group-companies-container">
                                <Row className="m-0">
                                    <TabContent activeTab={"group_companies"} className="p-0">
                                        <TabPane activeTab={"group_companies"} tabId={"group_companies"}>
                                            <div className="list-wrapper-other-pro px-3 py-2">
                                                <div className="company-groups-listing">
                                                    <div className="selectorAndDropdown">
                                                        <div className="toggle-role-dropdown sort-results d-flex justify-content-between">
                                                            <div className="mb-1">
                                                                <SearchSelector
                                                                    isWorkWithCompanies={true}
                                                                    handleFavChangeSelection={handleFavChangeSelection}
                                                                    handleTagsSelection={handleTagsSelection}
                                                                    handleDownloadPDFSelection={handleDownloadPDFSelection}
                                                                    handleDropDownItemClick={handleDropDownItemClick}
                                                                    totalSelectedCount={totalSelectedCount}
                                                                    totalRows={getTotalRows()}
                                                                    user={userData}
                                                                    entitiesCount={getTotalRows()}
                                                                    activeSort={activeSort}
                                                                    getUniqueSelectedList={getUniqueSelectedList}
                                                                // handleSortforGroupCompanies={handleSortforGroupCompanies}
                                                                // handleShowGroupProjects={handleShowGroupProjects}
                                                                >selector-placeholder
                                                                </SearchSelector>
                                                            </div>
                                                            <div className="sort-order-selector">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                                        <DropdownToggle caret>
                                                                            {activeSort}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => handleCommonSort('-max_project_last_published')} key={1} value={'Letter mail merge'}>
                                                                                Latest
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={() => handleCommonSort('-count_project_id')} key={2} value={'Letter mail merge'}>
                                                                                Projects
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={() => handleCommonSort('-sum_project_value')} key={3} value={'Letter mail merge'}>
                                                                                Value
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <TagsModal
                                                        handleTagsCancel={handleTagsCancel}
                                                        handleTagsSubmit={handleTagsSubmit}
                                                        selectedTags={project && project.selectedTagsDetails}
                                                        showModal={modal}
                                                    >
                                                    </TagsModal>
                                                    <div className="height-350">
                                                        <ColoredScrollbars className="scroll-wrapper">
                                                            <Row className="result-list-container content-panel" >
                                                                <Col xs="12" lg="12" xl="12" id="searchlistAccordion" className="p-0">
                                                                    <ListGroup>
                                                                        {roles.map((entity, index) => {
                                                                            const newEntity = Object.assign({
                                                                                objectId: entity.company_id,
                                                                                objectType: "company", onFavClick: false
                                                                            }, entity);
                                                                            return (
                                                                                <SearchListRow entity={newEntity} index={index}
                                                                                    prevNextList='worksWithCompanies'
                                                                                    objectType='company'
                                                                                    key={`resultrow${index}`}
                                                                                    sort={activeSort}
                                                                                    ptno={props.tab}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </ListGroup>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                project?.worksWithCompanies?.companies?.length !== project?.worksWithCompaniesCount ? (
                                                                    <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                                                        <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                                    </div>
                                                                ) : ''
                                                            }
                                                        </ColoredScrollbars>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </Row>
                            </div>
                        </div>
                    ) : (
                        <>
                            {
                                project && project.worksWithCompanies && project.worksWithCompanies.length == 0 ?
                                    <div className="selected-col p-3"><p className="m-0 col-heading"><strong>{project && project.detailsPage && project.detailsPage.name}</strong> does not work with other companies on this project</p></div>
                                    :
                                    <CommonPlaceholder />
                            }
                        </>
                    )
                }
            </>
        )
    }

    return (
        <Row className="m-0">
            <Col lg="12" className="p-0 second-col">
                {loadRolesList()}
            </Col>
        </Row>
    )
}

export default CompanyList