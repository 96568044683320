import React from "react";
import TopHeader from "../../components/header";
import LeftPanel from "../../components/leftpanel";
import AlertMessage from "../../components/alerts";
import UserAdmin from "../../components/useradmin";

const UserAdminPage = () => {
  return (
    <div id="container">
      <TopHeader />
      <div className="sidebar-mini">
        <LeftPanel />
        <div id="message-container">
          <AlertMessage />
        </div>
        <div className="user-admin-page pt-3 main-container container">
          <UserAdmin />
        </div>

      </div>
    </div>

  );
};
export default UserAdminPage;