import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import SliderCarousel from "../sliderCarousel";
import ColoredScrollbars from "../../common/js/coloredScrollbars";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllRecipients,
  fetchLabelData,
  fetchLabelDataForAllDetails,
  fetchDataForDetailsPage,
  fetchRolesForProjects,
  fetchShareTeams,
  fetchUserDetailsActivity,
  favourite,
  sendFeedback,
} from "../apiclient";
import {
  getFormatedDate,
  isValidText,
  displayCurrency,
  getCompanyAddress,
  formatUrlWithProtocol,
  getSectorNo,
} from "../../../utils";
import NavigationTab from "./navigationTab";
import Icon from "../../common/js/icon";
import {
  initializeCollaborationFilters,
  renderMapboxImage,
} from "../../../utils/commonFunctions";
import { getFormatedCopyrightDate } from "../../../utils/index";
import {
  summaryActivityDetail,
  updateActivityIsClicked,
  initializeCollabFilter,
  isPrint,
  fetchDetailsPage,
  fetchNoDetailsPage,
  updateCompanyProjectRoles,
} from "../redux/reducer";
import { fetchRolesLookUp } from "../../../components/usersettings/apiclient";
import PeopleCurrentProject from "./tabs/peopleTab/peopleCurrentProject";

import "../projectDetails.scss";
import CompanyTabDetail from "./details";
import { HttpErrorHandler } from "../httpErrorHandler";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { COMPANYDETAILS } from "../../../utils/tour";
import { ABI_LOGO, PROJECT_IMG_URL } from "../../../utils/settings";

const CompanyMain = () => {
  const [isPrintOrNot, setisPrintOrNot] = useState(false);
  const sliderImageArray = useRef(null);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab ? location.state.activeTab : "2"
  );
  const user = useSelector((state) => state.user.user);
  const project = useSelector((state) => state.project);
  const userData = useSelector((state) => state.user);
  const [summaryActivity, setSummaryActivity] = useState(null);
  const [feedbackmodal, toggleFeedbackModal] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [sliderBackgroundImg, setSliderBackgroundImg] = useState({});
  const [printSrc, setPrintSrc] = useState("");
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDetailsPage({}));
    dispatch(fetchNoDetailsPage(false));
    dispatch(updateCompanyProjectRoles([]))
    fetchDataForDetailsPage(
      location.pathname.split("/")[4],
      dispatch,
      false,
      location.pathname.split("/")[2]
    );
    fetchUserDetailsActivity(
      location.pathname.split("/")[4],
      location.pathname.split("/")[2],
      dispatch
    );
    fetchLabelData(
      location.pathname.split("/")[4],
      location.pathname.split("/")[2],
      dispatch
    );
    fetchLabelDataForAllDetails(dispatch);
    if (project && project.teams !== undefined) {
      fetchShareTeams(dispatch, project);
      fetchAllRecipients(user, dispatch, project);
    }
    fetchRolesLookUp(dispatch);
    if (
      project &&
      project.detailsPage &&
      !("company" in project?.collaborationFilter)
    ) {
      let data = initializeCollaborationFilters("company");
      dispatch(
        initializeCollabFilter({ collabData: data, objectType: "company" })
      );
    }
  }, [location]);

  useEffect(() => {
    if (project && project.detailsPage && project.detailsPage.objectId) {
      let images = renderMapboxImage(project.detailsPage);
      setSliderBackgroundImg({ backgroundImage: `url("${images.carouselImages[0].url}")` });
      setPrintSrc(images.carouselImages[0].url)

      // To update document title with company name
      document.title =
        project && project.detailsPage && project.detailsPage.company_name
          ? project.detailsPage.company_name
          : document.title;
    }
  }, [project && project.detailsPage]);

  const handleSummary = (event, activityName) => {
    event.preventDefault();
    setSummaryActivity(activityName);
    dispatch(summaryActivityDetail(activityName));
    dispatch(updateActivityIsClicked(true));
  };

  const handleFavourite = (event) => {
    event.preventDefault();
    let entity = {
      objectId: project.detailsPage.company_id,
      objectType: "company",
      favourite: project.detailsPage.favourite ? false : true,
      objectName: project.detailsPage.company_name,
      url: location,
    };
    favourite(entity, dispatch, project, userData);
  };
  const toggleModal = () => {
    toggleFeedbackModal(!feedbackmodal);
    setFeedbackValue("");
  };
  const handleChange = (event) => {
    setFeedbackValue(event.target.value);
  };

  const handleSendFeedback = () => {
    let sectorDetails = getSectorNo(window.location);
    let description = `Sector:${sectorDetails && sectorDetails.sectorName
      }\nProduct:${sectorDetails && sectorDetails.productName}\nCompany: ${project && project.detailsPage.company_id
      }\nDetails:${window.location.href
      }\nType: Feedback \n====================== \n ${feedbackValue}`;
    toggleFeedbackModal(false);
    let userNo = user.user_id;
    let payload = {
      object_type: "company",
      object_id: project && project.detailsPage.company_id,
      user_id: userNo,
      description,
    };
    sendFeedback(payload, dispatch);
  };
  const enablePrint = () => {
    let page = document.getElementsByTagName('body')[0];
    setisPrintOrNot(!isPrintOrNot);
    if (!isPrintOrNot) {
      page.classList.remove('noscroll');
      document.body.classList.add("print");
      dispatch(isPrint(true));
      let routingState = location.state;
      routingState.activeTab = activeTab;
      navigate(location.pathname, { state: routingState });
    } else {
      page.classList.add('noscroll');
      document.body.classList.remove("print");
      dispatch(isPrint(false));
    }
  };
  const makePrint = () => {
    window.print();
  };
  const renderLoader = () => {
    return (
      <>
        <div className="details-page project-details placeholder">
          <span className="preloader"></span>
          <div className="detail-page-wrapper">
            <div className="details-panel-scrollbar">
              <Row className="header-section pb-2 m-0">
                <Col md={9} className="ps-4 pt-3">
                  <Row className="d-flex justify-content-between">
                    <Col className="project-overview mt-2 ">
                      <span className="icon-project"></span>
                      <h2></h2>
                      <div className="address"></div>
                    </Col>
                    <Col className="pl-3">
                      <div className="dp-nav-buttons-wrapper d-flex justify-content-end">
                        <button
                          type="button"
                          className="back-to-resultpage-button btn btn-secondary"
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="dp-prev-button btn btn-secondary"
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="dp-next-button btn btn-secondary"
                        >
                          Next
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="meta-data pt-2 ps-3 d-flex">
                    <Col className="pl-0">
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                  </Row>

                  <div className="pr-3">
                    <Row className="label-sharing-cta ps-2 justify-content-between mb-3">
                      <Col className="d-inline-flex">
                        <div>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Calendar
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Share
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Notes
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-2"
                          >
                            Tag
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Archieve
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Favourite
                          </button>
                        </div>
                      </Col>
                      <Col className="d-inline-flex justify-content-end">
                        <button
                          type="button"
                          className="print-btn btn btn-secondary"
                        >
                          Push to CHUB
                        </button>
                        <button
                          type="button"
                          className="print-btn btn btn-secondary"
                        >
                          Print
                        </button>
                        <button
                          type="button"
                          className="feedback-icon btn btn-secondary"
                        >
                          Project enquiry
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={3} className="pr-0 image-curosal">
                  <div className="map-view" >
                    {/* <img src={src} alt={alt} /> */}
                  </div>
                  <div className="location-name-distance ml-3">
                    <div className="hori-placeholder-tour left-right-zero top-zero" data-intro={COMPANYDETAILS.companyimage.intro} data-position={COMPANYDETAILS.companyimage.position} data-class={COMPANYDETAILS.companyimage.class}>&nbsp;</div>

                    <span className="proj-location"></span>
                    <span className="distance"></span>
                  </div>
                </Col>
              </Row>
              <div className="mt-3 ms-0 me-0 mb-3 ps-3 project-information row">
                <div className="project-info-wrapper col">
                  <div className="row p-2">
                    <div className="proj-address col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-type col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-scheme col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-devtype col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-url col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const showMoreTagsCount = () => {
    var Count = 0;
    var parentItem = document.getElementById("tag-lable-listing");
    var showMoreButton = document.getElementById("label-show-more");
    if (showMoreButton != null) {
      showMoreButton.classList.add("hide");
    }
    if (parentItem != undefined && parentItem.childNodes.length > 0) {
      var topOffset = parentItem.childNodes[0].offsetTop;
      for (var i = 0; i < parentItem.childNodes.length; i++) {
        if (
          (parentItem.childNodes[i].classList + "").indexOf(
            "labels-list-wrapper"
          ) == 0 &&
          topOffset != parentItem.childNodes[i].offsetTop
        ) {
          break;
        } else {
          Count++;
        }
      }
      if (
        parentItem != null &&
        parentItem.childNodes[Count - 1] != undefined &&
        showMoreButton != null
      ) {
        showMoreButton.style.left =
          parentItem.childNodes[Count - 1].offsetLeft +
          parentItem.childNodes[Count - 1].offsetWidth +
          "px";
      }
      if (showMoreButton != null) {
        showMoreButton.classList.remove("hide");
      }
      return parentItem.childNodes.length - Count;
    }
    if (showMoreButton != null) {
      showMoreButton.classList.remove("hide");
    }
    return 0;
  };

  useEffect(() => {
    showMoreTagsCount();
  }, [project && project.labelTagsDetail]);

  return (
    <>
      {project && project.isPrint === false ? (
        <div>
          {project && Object.keys(project.detailsPage).length !== 0 ? (
            <div className="details-page project-details">
              <div className="detail-page-wrapper">
                <ColoredScrollbars className="details-panel-scrollbar">
                  <Row className="header-section flex-row-reverse pb-2">
                    <Col lg="3" md="12" sm="12" className="pt-2">
                      <div className="map-view" style={sliderBackgroundImg}>
                        <div className="location-name-distance ml-3">
                          <div className="hori-placeholder-tour left-right-zero top-zero" data-intro={COMPANYDETAILS.companyimage.intro} data-position={COMPANYDETAILS.companyimage.position} data-class={COMPANYDETAILS.companyimage.class}>&nbsp;</div>
                          <div className="comp-location">{project?.detailsPage.company_address3}</div>

                        </div>

                      </div>

                    </Col>
                    <Col lg="9" md="12" sm="12">
                      <div className="project-overview mt-2">
                        <Row className="justify-content-between project-info">
                          <Col lg="3" md="12" sm="12" className="ps-0">
                            <NavigationTab activeTab={activeTab}></NavigationTab>
                          </Col>
                          <Col
                            sm="12"
                            md="6"
                            lg="8"
                            className="project-details pe-0"
                          >
                            <h2>
                              <Icon
                                color="#053874"
                                size={40}
                                icon="icon-gen-company-list"
                                className="me-2 company-icon"
                              />
                              <strong>
                                {project &&
                                  project.detailsPage &&
                                  project.detailsPage.company_name}
                              </strong>
                            </h2>
                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_address1
                            ) ||
                              isValidText(
                                project &&
                                project.detailsPage &&
                                project.detailsPage.company_address2
                              ) ||
                              isValidText(
                                project &&
                                project.detailsPage &&
                                project.detailsPage.company_address3
                              ) ||
                              isValidText(
                                project &&
                                project.detailsPage &&
                                project.detailsPage.company_address4
                              ) ||
                              isValidText(
                                project &&
                                project.detailsPage &&
                                project.detailsPage.company_postcode
                              ) ? (
                              <div className="address">
                                <span>Address: </span>
                                {getCompanyAddress(
                                  project && project.detailsPage
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>

                          <div className="d-flex align-content-start flex-wrap mt-1 contact-details">
                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_phone
                            ) ||
                              isValidText(
                                project &&
                                project.detailsPage &&
                                project.detailsPage.company_phone
                              ) ? (
                              <div className="co-phone">
                                <span>Company phone: </span>
                                <a
                                  href={`tel:${project &&
                                    project.detailsPage &&
                                    project.detailsPage.company_phone.replace(
                                      / /g,
                                      ""
                                    )
                                    }`}
                                >
                                  {project &&
                                    project.detailsPage &&
                                    project.detailsPage.company_phone}
                                </a>
                                {isValidText(
                                  project &&
                                  project.detailsPage &&
                                  project.detailsPage.company_phone_tps
                                ) &&
                                  project &&
                                  project.detailsPage &&
                                  project.detailsPage.company_phone_tps ? (
                                  <span className="tps-badge">TPS</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_website
                            ) ? (
                              <div className="co-website">
                                <span>Website: </span>
                                <a
                                  href={`http://${project &&
                                    project.detailsPage &&
                                    project.detailsPage.company_website
                                    }`}
                                  target="__blank"
                                >
                                  {project &&
                                    project.detailsPage &&
                                    project.detailsPage.company_website}
                                </a>
                              </div>
                            ) : (
                              ""
                            )}

                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_email
                            ) ? (
                              <div className="co-email">
                                <span>Email: </span>
                                <a
                                  href={`mailto:${project?.detailsPage?.company_email}`}
                                >
                                  {project?.detailsPage?.company_email}
                                </a>
                              </div>

                            ) : (
                              ""
                            )}
                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_linkedin
                            ) ? (
                              <a
                                href={formatUrlWithProtocol(
                                  project &&
                                  project.detailsPage &&
                                  project.detailsPage.company_linkedin
                                )}
                                className="linkedin-icon"
                                target="__blank"
                              ></a>
                            ) : (
                              ""
                            )}
                            {isValidText(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.company_registration_number
                            ) ? (
                              <div className="co-houseNo">
                                <a
                                  href={
                                    project &&
                                    project.detailsPage &&
                                    project.detailsPage.company_registration_url
                                  }
                                  target="__blank"
                                >
                                  <span>Companies House</span>
                                  {project &&
                                    project.detailsPage &&
                                    project.detailsPage
                                      .company_registration_number}
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Row>
                        <div className="meta-data d-flex pt-2 pb-2 mb-1 position-relative">
                          <Col className="pl-0">
                            <span> Published: </span>
                            {getFormatedDate(
                              project &&
                              project.detailsPage &&
                              project.detailsPage.max_project_last_published,
                              "DD-MMM-YYYY"
                            )}
                          </Col>

                          <Col>
                            <span>Projects: </span>
                            {project &&
                              project.detailsPage &&
                              project.detailsPage.count_project_id}
                          </Col>

                          {isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.sum_project_value
                          ) ? (
                            <Col>
                              <span>Project value:</span>
                              {project &&
                                project.detailsPage &&
                                displayCurrency(
                                  project.detailsPage.sum_project_value,
                                  project.detailsPage.project_value_estimated
                                )}
                            </Col>
                          ) : (
                            ""
                          )}
                        </div>

                        {project && project.labelTagsDetail ? (
                          <Row className="tag-listing  mb-3">
                            <Col className="tag-listing-container px-0 d-flex">
                              <div
                                className={`label-details-wrapper ${showMoreTagsCount() > 0
                                  ? "show-less-lables"
                                  : ""
                                  }`}
                                id="tag-lable-listing"
                              >
                                {project &&
                                  project.labelTagsDetail &&
                                  project &&
                                  project.labelTagsDetail.map(function (
                                    label,
                                    index
                                  ) {
                                    return (
                                      <span
                                        key={`label-list-${index}`}
                                        className={`${label.is_shared
                                          ? "shared-labels-list-wrapper"
                                          : "labels-list-wrapper"
                                          }`}
                                      >
                                        <a
                                          onClick={(e) => {
                                            handleSummary(e, "tags");
                                          }}
                                          className={`${label.is_shared
                                            ? "shared-labels-list"
                                            : "labels-list"
                                            }`}
                                          id={`label-${index}`}
                                        >
                                          <Icon
                                            color="#053874"
                                            size={15}
                                            icon="icon-gen-tag"
                                            className="me-1"
                                          />{" "}
                                          {label.tag_name}
                                        </a>
                                      </span>
                                    );
                                  })}
                              </div>
                              {showMoreTagsCount() > 0 && (
                                <span
                                  id="label-show-more"
                                  onClick={(e) => {
                                    handleSummary(e, "tags");
                                  }}
                                >
                                  +{showMoreTagsCount()} more
                                </span>
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        <div className="py-2">
                          <div className="pe-3 ps-0 action-button-list mt-1">
                            <Row className="label-sharing-cta justify-content-between">
                              <Col className="position-relative">
                                <Row className="d-flex justity-content-start">
                                  <Col className="d-inline-flex" xs="auto">
                                    <p
                                      className="mb-1 label-count-icon user-ctrl-btn"
                                      data-tooltip-id="my-tag"
                                      data-tooltip-hidden={
                                        !settings.preferences.hint_text
                                      }
                                    >
                                      <Link
                                        onClick={(e) => {
                                          handleSummary(e, "tags");
                                        }}
                                      >
                                        <Icon
                                          color="#053874"
                                          size={21}
                                          icon="icon-gen-tag"
                                        />{" "}
                                        {project &&
                                          project.userActivityCount &&
                                          project.userActivityCount.tags_count > 0
                                          ? project &&
                                          project.userActivityCount &&
                                          project.userActivityCount.tags_count
                                          : ""}
                                      </Link>
                                    </p>
                                    <p
                                      className="mb-1 share-count-icon user-ctrl-btn"
                                      data-tooltip-id="my-shares"
                                      data-tooltip-hidden={
                                        !settings.preferences.hint_text
                                      }
                                    >
                                      <Link
                                        onClick={(e) => {
                                          handleSummary(e, "shares");
                                        }}
                                      >
                                        <Icon
                                          color="#053874"
                                          size={21}
                                          icon="icon-gen-share"
                                        />
                                        {project &&
                                          project.userActivityCount &&
                                          project.userActivityCount.shares_count >
                                          0
                                          ? project &&
                                          project.userActivityCount &&
                                          project.userActivityCount
                                            .shares_count
                                          : ""}
                                      </Link>
                                    </p>
                                    <p
                                      className="mb-1 notes-count-icon user-ctrl-btn"
                                      data-tooltip-id="my-notes"
                                      data-tooltip-hidden={
                                        !settings.preferences.hint_text
                                      }
                                    >
                                      <Link
                                        onClick={(e) => {
                                          handleSummary(e, "notes");
                                        }}
                                      >
                                        <Icon
                                          color="#053874"
                                          size={21}
                                          icon="icon-gen-notes"
                                        />
                                        {project &&
                                          project.userActivityCount &&
                                          project.userActivityCount.notes_count >
                                          0
                                          ? project &&
                                          project.userActivityCount &&
                                          project.userActivityCount
                                            .notes_count
                                          : ""}
                                      </Link>
                                    </p>
                                    {project &&
                                      project.detailsPage &&
                                      project.detailsPage.mail &&
                                      project &&
                                      project.detailsPage &&
                                      project.detailsPage.mailed_on ? (
                                      <p
                                        className="mb-1 mail-date-icon user-ctrl-btn"
                                        data-tooltip-id="Mailed-on"
                                        data-tooltip-hidden={
                                          !settings.preferences.hint_text
                                        }
                                      >
                                        <Icon
                                          color="#053874"
                                          size={21}
                                          icon="icon-gen-email"
                                        />
                                        {getFormatedDate(
                                          project &&
                                          project.detailsPage &&
                                          project.detailsPage.mailed_on,
                                          "DD-MMM-YYYY"
                                        )}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {user &&
                                      user.licenceFlag &&
                                      user.licenceFlag.isCalendarLicence ===
                                      true ? (
                                      <p
                                        className="mb-1 label-calendar-icon user-ctrl-btn"
                                        data-tooltip-id="my-calendar"
                                        data-tooltip-hidden={
                                          !settings.preferences.hint_text
                                        }
                                      >
                                        <Link
                                          onClick={(e) => {
                                            handleSummary(e, "calendar_events");
                                          }}
                                        >
                                          <Icon
                                            color="#053874"
                                            size={21}
                                            icon="icon-gen-calendar"
                                          />
                                          {project &&
                                            project.userActivityCount &&
                                            project.userActivityCount
                                              .calendar_events_count > 0
                                            ? project &&
                                            project.userActivityCount &&
                                            project.userActivityCount
                                              .calendar_events_count
                                            : ""}
                                        </Link>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col className="d-inline-flex">
                                    <a
                                      data-tooltip-id="favourite-project"
                                      data-tooltip-hidden={
                                        !settings.preferences.hint_text
                                      }
                                      className="user-ctrl-btn label-favourite-icon"
                                      onClick={handleFavourite}
                                    >
                                      <Icon
                                        color="#053874"
                                        size={21}
                                        icon={
                                          project &&
                                            project.detailsPage &&
                                            project.detailsPage.favourite
                                            ? "icon-gen-star-active"
                                            : "icon-gen-star-inactive"
                                        }
                                      />
                                    </a>
                                  </Col>
                                </Row>
                                <div className="hori-placeholder-tour left-right-zero bottom-zero" data-intro={COMPANYDETAILS.useractivities.intro} data-position={COMPANYDETAILS.useractivities.position} data-class={COMPANYDETAILS.useractivities.class}>
                                  &nbsp;
                                </div>
                                {user &&
                                  user.licenceFlag &&
                                  user.licenceFlag.isCalendarLicence === true ? (
                                  <ReactTooltip
                                    id="my-calendar"
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                  >
                                    <p className="tool-tip-sidebar">Calendar</p>
                                  </ReactTooltip>
                                ) : (
                                  ""
                                )}
                                <ReactTooltip
                                  id="favourite-project"
                                  place="bottom"
                                  type="dark"
                                  effect="solid"
                                >
                                  <p className="tool-tip-sidebar">Favourite</p>
                                </ReactTooltip>
                                <ReactTooltip
                                  id="my-tag"
                                  place="bottom"
                                  type="dark"
                                  effect="solid"
                                >
                                  <p className="tool-tip-sidebar">My Tags</p>
                                </ReactTooltip>
                                <ReactTooltip
                                  id="my-shares"
                                  place="bottom"
                                  type="dark"
                                  effect="solid"
                                >
                                  <p className="tool-tip-sidebar">My Shares</p>
                                </ReactTooltip>
                                <ReactTooltip
                                  id="my-notes"
                                  place="bottom"
                                  type="dark"
                                  effect="solid"
                                >
                                  <p className="tool-tip-sidebar">My Notes</p>
                                </ReactTooltip>
                                <ReactTooltip
                                  id="Mailed-on"
                                  place="bottom"
                                  type="dark"
                                  effect="solid"
                                >
                                  <p className="tool-tip-sidebar">Mailed On</p>
                                </ReactTooltip>
                              </Col>
                              <Col className="d-inline-flex justify-content-end detail-action-buttons">
                                <button
                                  class="user-ctrl-btn print-btn ml-2"
                                  onClick={enablePrint}
                                >
                                  <Icon
                                    color="#053874"
                                    size={21}
                                    icon="icon-gen-print"
                                    className="me-1"
                                  />{" "}
                                  Print view
                                </button>
                                <div class="feedback-cta ml-2">
                                  <button
                                    class="user-ctrl-btn feedback-btn"
                                    onClick={toggleModal}
                                  >
                                    <Icon
                                      color="#053874"
                                      size={21}
                                      icon="icon-gen-enquiry"
                                      className="me-1"
                                    />
                                    Company enquiry
                                  </button>
                                  <div
                                    class={`feedback-panel ${feedbackmodal ? "show" : "hide"
                                      }`}
                                  >
                                    <div class="note-list-wrapper">
                                      <div class="note-form input-group">
                                        <div class="note-input">
                                          <textarea
                                            maxLength="1834"
                                            rows="4"
                                            placeholder="Submit your enquiry to our research team"
                                            value={feedbackValue}
                                            onChange={handleChange}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                    {feedbackValue.length <= 0 ? (
                                      <button
                                        className="primary-btn btn-send"
                                        disabled="disabled"
                                      >
                                        Send
                                      </button>
                                    ) : (
                                      <button
                                        className="primary-btn btn-send"
                                        onClick={handleSendFeedback}
                                      >
                                        Send
                                      </button>
                                    )}
                                    <button
                                      className="outline-btn btn-feedback-cancel"
                                      onClick={toggleModal}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <CompanyTabDetail
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </ColoredScrollbars>
              </div>
            </div>
          ) : project.pageNotFoundFlag ? (
            <HttpErrorHandler />
          ) : (
            renderLoader()
          )}
        </div>
      ) : (
        <div>
          <div className="details-page company-details" id="print-container">
            <div className="detail-page-wrapper">
              <img className='logo-images' src={`${PROJECT_IMG_URL}${ABI_LOGO}`} />
              <Row className="top-info">
                <div className="map-view p-0" >
                  <img src={printSrc} />
                </div>
                <Col className="col company-overview">
                  <h2>
                    {project &&
                      project.detailsPage &&
                      project.detailsPage.company_name}
                  </h2>
                  <strong>
                    {project &&
                      project.detailsPage &&
                      project.detailsPage.company_address3}
                  </strong>
                  {
                    <p className="mb-1 proj-ref">
                      <span>
                        <strong>Projects: </strong>
                      </span>
                      {project &&
                        project.detailsPage &&
                        project.detailsPage.count_project_id}
                    </p>
                  }

                  <p className="mb-1 proj-ref">
                    <span>
                      <strong>Published: </strong>
                      {getFormatedDate(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.max_project_last_published,
                        "DD-MMM-YYYY"
                      )}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="copyright">
                <div>
                  Copyright © {getFormatedCopyrightDate(null, "YYYY")}. Barbour
                  ABI Limited. All rights reserved. <br />
                  <span>
                    This Barbour ABI data was printed by {user.first_name}{" "}
                    {user.last_name} at {user.company_name} on{" "}
                    {getFormatedCopyrightDate(null, "DD-MMM-YYYY")}
                  </span>
                </div>
              </Row>
              <Row className="ms-0 me-0 company-information">
                <Col className="company-info-wrapper">
                  <Row className={`${project && project.isPrint ? "m-0" : ""}`}>
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.company_address1
                    ) ||
                      isValidText(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.company_address2
                      ) ||
                      isValidText(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.company_address3
                      ) ||
                      isValidText(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.company_address4
                      ) ||
                      isValidText(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.company_postcode
                      ) ? (
                      <Col
                        sm={`${project && project.isPrint ? "8" : "12"}`}
                        md={`${project && project.isPrint ? "8" : "12"}`}
                        lg={`${isValidText(
                          project &&
                          project.detailsPage &&
                          project.detailsPage.company_phone
                        )
                          ? "8"
                          : "12"
                          }`}
                        className="col comp-address"
                      >
                        <div className="field-name">Address</div>
                        <div className="field-value">
                          {getCompanyAddress(project && project.detailsPage)}
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.company_phone
                    ) ||
                      isValidText(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.company_phone
                      ) ? (
                      <Col
                        sm={`${project && project.isPrint ? "4" : "12"}`}
                        md={`${project && project.isPrint ? "4" : "12"}`}
                        lg={`${isValidText(
                          project &&
                          project.detailsPage &&
                          project.detailsPage.address1
                        ) ||
                          isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.address2
                          ) ||
                          isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.address3
                          ) ||
                          isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.address4
                          ) ||
                          isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.postCode
                          )
                          ? "4"
                          : "12"
                          }`}
                        className="col comp-phone"
                      >
                        <div className="field-name">Company phone</div>
                        <div className="field-value">
                          {project &&
                            project.detailsPage &&
                            project.detailsPage.company_phone}
                          {isValidText(
                            project &&
                            project.detailsPage &&
                            project.detailsPage.company_phone_tps
                          ) &&
                            project &&
                            project.detailsPage &&
                            project.detailsPage.company_phone_tps ? (
                            <span className="tps-badge">TPS</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className={`${project.isPrint ? "m-0" : ""}`}>
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.company_website
                    ) ? (
                      <Col
                        sm={`${project.isPrint ? "8" : "12"}`}
                        md={`${project.isPrint ? "8" : "12"}`}
                        lg={`${isValidText(
                          project &&
                          project.detailsPage &&
                          project.detailsPage.company_email
                        )
                          ? "8"
                          : "12"
                          }`}
                        className="col comp-url"
                      >
                        <div className="field-name">Website</div>
                        <div className="field-value">
                          <a
                            href={`http://${project &&
                              project.detailsPage &&
                              project.detailsPage.company_website
                              }`}
                            target="__blank"
                          >
                            {project &&
                              project.detailsPage &&
                              project.detailsPage.company_website}
                          </a>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.company_email
                    ) ? (
                      <Col
                        sm={`${project.isPrint ? "4" : "12"}`}
                        md={`${project.isPrint ? "4" : "12"}`}
                        lg={`${isValidText(
                          project &&
                          project.detailsPage &&
                          project.detailsPage.company_website
                        )
                          ? "4"
                          : "12"
                          }`}
                        className="col comp-email"
                      >
                        <div className="field-name">Email</div>
                        <div className="field-value">
                          <a
                            href={
                              "mailto:" + project &&
                              project.detailsPage &&
                              project.detailsPage.company_email
                            }
                          >
                            {project &&
                              project.detailsPage &&
                              project.detailsPage.company_email}
                          </a>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.company_registration_number
                    ) ? (
                      <Col
                        sm={`${project && project.isPrint ? "4" : "12"}`}
                        md={`${project && project.isPrint ? "4" : "12"}`}
                        lg={`${isValidText(
                          project &&
                          project.detailsPage &&
                          project.detailsPage.company_registration_number
                        )
                          ? "8"
                          : "12"
                          }`}
                        className="col co-houseNo"
                      >
                        <div className="field-name">Companies House</div>
                        <div className="field-value">
                          {project &&
                            project.detailsPage &&
                            project.detailsPage.company_registration_number}
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row>
                    {isValidText(
                      project &&
                      project.detailsPage &&
                      project.detailsPage.sum_project_value
                    ) ? (
                      <Col sm="12" md="12" lg="8" className="col proj-value">
                        <div className="field-name">Value</div>
                        {project &&
                          project.detailsPage &&
                          displayCurrency(
                            project.detailsPage.sum_project_value,
                            project.detailsPage.project_value_estimated
                          )}
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3 ms-0 me-0 mb-3 company-tab-panel">
                <div className="company-contact-print">
                  <PeopleCurrentProject></PeopleCurrentProject>
                </div>
              </Row>
            </div>
          </div>
          <div className="overlay-print">
            <NavigationTab />
            <button className="user-ctrl-btn print-btn" onClick={makePrint}>
              Print
            </button>
            <button className="user-ctrl-btn back-btn" onClick={enablePrint}>
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyMain;