import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
const ColoredScrollbars = (props) => {
  const [top, setTop] = useState(0);
  const handleUpdate = (values) => {
    const { top } = values;
    setTop(top);
  };
  const renderView = ({ style, ...props }) => {
    const viewStyle = {
        color: 'rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})'
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        backgroundColor: '#053874',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderTrackVertical = ({ style, ...props }) =>{
    const scrollTrack = {
        backgroundColor: '#fff',
        boxShadow:'0 0 2px 0px rgba(0,0,0,0.25)',
        width:'15px',
        height:'100%',
        position:'absolute',
        right:'1px',
        top:'0px',
        paddingRight:'2px',
        paddingLeft:'2px',
        zIndex:'999'
    };
    return (
        <div className="scrollbarTracker"
        style={{ ...style, ...scrollTrack }}
            {...props}/>
    );
  
}
  

  return (
    <Scrollbars
      renderView={renderView}
      renderThumbHorizontal={renderThumb}
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumb}
      onUpdate={handleUpdate}
      {...props}
    />
  );
};
export default ColoredScrollbars;