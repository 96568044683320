import React, { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, InputGroup } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import Icon from '../../../common/js/icon';
import { updateUserSettings } from '../../apiclient';
import CheckboxlistItem from './checkboxlistItem';
import { useDispatch, useSelector } from 'react-redux';

const CheckboxList = (props) => {
    return (
        <>
            {props && props.item ? (
                <div className="checklist-container">
                    <>
                    {(props.item.name === "All project stage changes") ?
                        <div>
                            <div className="headerCheck">
                             <CheckboxlistItem
                                item={props.item}
                                onChange={(e) => {
                                    props.itemChangeHandler(e, false);
                                }}
                            />
                            </div>
                            <div className="checkList">
                            {props && props.item && props.item.children ? (
                                        <ul className="notiCheckList">
                                            {props.item.children.map(child => (
                                                <li>
                                                    <CheckboxlistItem
                                                        parent={props.item.name}
                                                        item={{ ...child }}
                                                        onChange={(e) => {
                                                            props.itemChangeHandler(e, true);
                                                        }}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                ) : (
                                    ""
                                )}
                        </div>
                        </div>
                        :
                        props.item.name === "Project roles added" ? 
                            <div>
                                <div className="headerCheck">
                                    <CheckboxlistItem
                                        item={props.item}
                                        onChange={(e) => {
                                            props.itemChangeHandler(e, false);
                                        }}
                                    />
                                </div>
                        {(props.item.value === "ABI-PT-ROLE" || props.item.value === "CAT-PT-ROLE") && props.item.active ? (
                        <div className="notoficatnSelector checkList">
                            <ul className="mb-0">
                                <li>
                                    <div className="share-details label-list">
                                        <div className="share-details-wrapper">
                                            <InputGroup>
                                                <Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="me-2 circle-border" />
                                                <CreatableSelect
                                                    options={props.roleData.options}
                                                    isClearable
                                                    isMulti
                                                    value={props.roleData.selectedOptions}
                                                    onChange={props.roleData.handleRoleChange}
                                                    menuPosition="fixed"
                                                    placeholder={
                                                        <span className="label-placeholder"> Enter the roles you wish to receive updates on (if left blank all roles will be selected) </span>
                                                    }
                                                    onInputKeyDown={props.roleData.onRoleInputKeyDown}
                                                    isValidNewOption={() => false}
                                                    inputProps={{autoComplete: 'random-string', autofill:'off' }}
                                                />
                                                <div><button className="primary-btn btn-share" onClick={props.roleData.handleAddRoles} disabled={!(props.roleData.selectedOptions !== null && props.roleData.selectedOptions.length !== 0 && props.roleData.selectedOptions !== undefined)}>Add roles</button></div>
                                            </InputGroup>
                                        </div>
                                        <div className="label-details-wrapper">
                                            {props.roleData.selectedRoles &&
                                                props.roleData.selectedRoles.map(function (role, index) {
                                                    return (
                                                        <span key={`label-list-${index}`} className="labels-list-wrapper">
                                                            <a id={`label-${index}`} className="labels-list">
                                                                {role.label}
                                                            </a>
                                                            {
                                                                <span
                                                                    className="remove-label"
                                                                    aria-hidden="true"
                                                                    onClick={()=>props.roleData.handleRemoveRoles(role.id)}
                                                                >
                                                                    ×
                                                                </span>
                                                            }
                                                        </span>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ) : ('')}
                            </div>
                         : 
                         <div className="headerCheck">
                         <CheckboxlistItem
                             item={props.item}
                             onChange={(e) => {
                                 props.itemChangeHandler(e, false);
                             }}
                         />
                     </div> }
                    </>
                    
                </div>
            ) : (
                <span></span>
            )
            }
        </>
    )
}

export default CheckboxList