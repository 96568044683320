import React, { useEffect, useState } from 'react'
import Icon from '../../common/js/icon';

const SummaryTiles = (props) => {
    const [tagList, setTagList] = useState(props.tagList)

    useEffect(()=>{
        setTagList(props.tagList)
    },[props.tagList])

    return (
        <div className='summary-section'>
           <ul className="summeryTagsList ps-0 pe-3 me-3">
          { tagList && tagList.map((tag, index) => (
            <li className={`tag-element mb-2 me-2 ${tag.gotoComponentID === 'new_filter_prcs' || tag.gotoComponentID === 'new_filter_stages' || tag.gotoComponentID === 'new_filter_category' 
            || tag.gotoComponentID === 'new_filter_devtype' || tag.gotoComponentID === 'new_filter_material' || tag.gotoComponentID === 'new_filter_roles' || tag.gotoComponentID === 'new_filter_crcs'
             ? 'bigSummaryTruncated' : ''}`} key={index} onClick={() => props.gotoComponent(tag.gotoComponentID)}>
              <span className="tag-title" >{tag?.name} :</span> <span className="tag-content ms-1" title={tag.value}>{tag?.value}</span>
              <span class="tag-close-button ms-2" onClick={(e) => { props.addRemoveTag(tag.title, false, false, e ); }}><Icon color="#053874" size={10} icon="icon-gen-cross" className="me-1"/></span>
            </li>
          ))}
        </ul>
        </div>
      )
  }
  
  export default SummaryTiles;