import React from 'react';
import AlertMessage from '../../components/alerts';
import ResetPasswordMain from '../../components/login/resetpassword';
import TopHeader from '../../components/header';
import LeftPanel from "../../components/leftpanel";


const ResetPassword = () => {

    return (

        <div id="container">
                <ResetPasswordMain/>
        </div>
    );
}

export default ResetPassword;