import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import sha256 from 'js-sha256'

import { ABI_FREE_TRIAL_LINK, ABI_IOS_APP, ABI_PLAY_APP, ABILIVE, } from './../../utils/settings'
import { signInUser } from './apiclient';

import { component, setAlert } from '../../redux/features/user/userslice';

const SignIn = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [type, setType] = useState('password');
	const [previousUrl, setPreviousUrl] = useState('')
	let navigate = useNavigate();
	const appDetails = useSelector((state) => state.user.appDetails);
	const dispatch = useDispatch();

	const handleSubmit = (event) => {
		event.preventDefault()
		if (username !== '' && password !== '') {
			let user = {
				username: username.trim(),
				password: sha256(password),
				stno: appDetails.sectorNo
			}
			let appliedFilter = {}
			signInUser(user, navigate, dispatch, appliedFilter, null, previousUrl)
		} else {
			dispatch(setAlert({
				visible: true,
				message: "Sign in failed #Enter your username and password",
				type: "danger",
				keyid: Math.random(),
				autoHide: true
			}))
		}
	};

	const forgotPassword = () => {
		dispatch(component("forgotpassword"))
		navigate("/forgotpassword");
	}

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			handleSubmit(event)
		}
	}

	const handleChange = (event) => {
		setUsername(event.target.value);
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	}

	const showHide = () => {
		setType(type === 'input' ? 'password' : 'input')
	}

	return (
		<>
			<Col className="loginForm col-12 col-md-6 px-5">
				<p className="d-none">Sign in</p>
				<Form onKeyDown={handleKeyDown} >
					<FormGroup className="form-group">
						<div className="icon-addon addon-lg">
							<Input type="Text"
								name="username"
								id="username"
								autoCapitalize="off"
								placeholder="Username"
								onChange={handleChange}
								autoComplete="off"
								value={username} />
							<Label for="username" className="sr-only fa fa-user" title="Username"></Label>
						</div>
					</FormGroup>
					<FormGroup className="form-group">
						<div className={`icon-addon addon-lg ${type === 'password' ? 'hide-password' : 'show-password'}`} >
							<Input type={type}
								name="password"
								id="password"
								placeholder="Password"
								autoComplete="off"
								onChange={handlePasswordChange}
								value={password} />
							<Label for="password" className="sr-only fa fa-lock" title="Password"></Label>
							<span className="display-password"
								onClick={showHide}
							>{type === 'input' ? 'Hide' : 'Show'}</span>
						</div>
					</FormGroup>
					<FormGroup className="form-group">
						<Col lg="12" className="p-0 my-4">
							<button type="button" className="btn-block primary-btn" onClick={handleSubmit}>Sign in</button>
						</Col>
						<Col lg="12" className="p-0">
							<button type="button" className="btn btn-link btn-block btn-forgotpassword" onClick={forgotPassword}>Forgot password?</button>
						</Col>
					</FormGroup>
					{(
						<>
							<p className="info">You'll need an active subscription to use the Barbour ABI app. To learn why we're the UK's leading supplier of construction intelligence, <a href={ABILIVE} target="_blank">visit our site</a>. </p>
							<p className="info"><a className='text-decoration-underline' href={ABI_FREE_TRIAL_LINK} target="_blank"> <b>Not a Barbour ABI client yet?</b></a> Request a free trial.</p>
						</>
					)
					}
					<div className="app-btn">
						<a className='ios' target="_blank" href={ABI_IOS_APP}></a>
						<a className='android' target="_blank" href={ABI_PLAY_APP}></a>
					</div>
				</Form>
			</Col>
		</>
	);
}

export default SignIn;