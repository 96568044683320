import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup
} from 'reactstrap';
import 'react-accessible-accordion/dist/fancy-example.css';
import IncludeExcludeGrid from './includeExcludeGrid';
import { useDispatch, useSelector } from 'react-redux';
import { updateTextInputFilter } from './redux/reducer';
import RequestDataModal from './requestDataModal';

const CategoryTypeFilter = (props) => {
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [categoryProject, setCategoryProject] = useState(searchPersist?.customerProfile['project_categories']?.values)
    const dispatch = useDispatch()
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_categories']?.all_subscribed)
    const [showSubscribed, setShowSubscribed] = useState(false)
    const [isBuildToRentEnabled , setIsBuildToRentEnabled] = useState(props.isBuildToRentEnabled)
    const [buildToRent , setBuildToRent] = useState(props.buildToRent)
    const [siteToBeSold , setSiteToBeSold] = useState(props.siteToBeSold)
    const [greenField , setGreenField] = useState(props.greenFieldChecked)
    const [brownField , setBrownField] = useState(props.brownFieldChecked)
    const [isClearAll , setIsClearAll] = useState(props?.isClearAll)

    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }

    useEffect(() => {
        dispatch(updateTextInputFilter(getDefaultInputTextFilterPayload()))
    }, [])

    function getDefaultInputTextFilterPayload() {
        return {
            text: '',
            filter: props.dataMappings ? props.dataMappings.filterType : '',
            child: props.dataMappings ? props.dataMappings.subFilterType : '',
            isIncludeExclude: props?.dataMappings?.isIncludeExclude,
            includeKey: props.dataMappings?.includeKey,
            excludeKey: props.dataMappings?.excludeKey
        }
    }

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
        setBuildToRent(props?.buildToRent)
        setSiteToBeSold(props?.siteToBeSold)
        setIsBuildToRentEnabled(props?.isBuildToRentEnabled)
        setBrownField(props.brownFieldChecked)
        setGreenField(props.greenFieldChecked)
    }, [props])

    return (
        <>
            {
                props.checkboxListFilters?.map(mapping => (
                    props.currentAdvancedSearch && (props.currentAdvancedSearch[mapping.includeKey] || props.currentAdvancedSearch[mapping.excludeKey]) ? (
                        <>
                            <div className="advanced-filter-grid" id="new_filter_category">
                                <div className="filterHeading">
                                    <h5 className="mb-3">{mapping.title}</h5>
                                </div>
                                <div className={`filterBlock pb-0 ${props.currentAdvancedSearch[mapping.includeKey].active || props.currentAdvancedSearch[mapping.excludeKey].active ||
                                    (mapping.advanceFilterStateKey == "categoryType" && props.siteToBeSold == 11) ? "activeBlock" : ""}`}>
                                    <div className="textcontent">
                                        {mapping.filterStatictext}
                                    </div>
                                    <div className="checkBoxFilterBx">
                                        <FormGroup id="categoryTypeFilter" name="category-filter" row className="pb-0 mb-0">
                                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                                <IncludeExcludeGrid
                                                    commontext={true}
                                                    filterData={categoryProject}
                                                    filterType="categories"
                                                    subFilterType="children"
                                                    allSubscribed={allSubscribed}
                                                    filter={mapping ? props.currentAdvancedSearch[mapping.includeKey] : props.currentAdvancedSearch[mapping.excludeKey]}
                                                    showAll={showAll}
                                                    dataMappings={mapping}
                                                    currentFilters={props.currentAdvancedSearch[mapping.filter]}
                                                    includeFilters={props.currentAdvancedSearch[mapping.includeKey]}
                                                    excludeFilters={props.currentAdvancedSearch[mapping.excludeKey]}
                                                    variablesHandler={props.variableHandler}
                                                    initTags={props.initTags}
                                                    fetchEntityCounts={props.fetchEntityCounts}
                                                    filterTitle={'Categories'}
                                                    showSubscribed={showSubscribed}
                                                    isClearAll = {isClearAll}
                                                    handleSearchText={props.handleSearchText}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <FormGroup id="categoryTypeFilter mb-0" row>
                                        <Col xs={12} sm={8} md={8} lg={8} className="advanced-filter-field-container mt-3">
                                            <div class="slider-frames form-group mb-0">
                                                <div class={`check-box-button pb-2 ${!isBuildToRentEnabled ? 'disabled' : ''}`}>
                                                    <input type="checkbox" name="buildToRent" id="buildToRent" class="form-check-input" 
                                                    onChange={e => props.variableHandler("projectUsage", e)} disabled={!isBuildToRentEnabled} 
                                                    checked={buildToRent} />
                                                    <label for="buildToRent" class="form-check-label">Build to rent</label>
                                                </div>
                                                <div class="check-box-button mb-0">
                                                    <input type="checkbox" name="siteToBeSold" id="siteToBeSold" class="form-check-input"
                                                    onChange={e => props.variableHandler("projectUsage", e)} checked={siteToBeSold}  />
                                                    <label for="siteToBeSold" class="form-check-label">Site to be sold</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} className="advanced-filter-field-container mt-3">
                                            <div class="slider-frames form-group mb-0">
                                                <div class="check-box-button pb-2">
                                                    <input type="checkbox" name="greenField" id="greenField" class="form-check-input" 
                                                    onChange={e => props.variableHandler("projectLandType", e)} checked={greenField}/>
                                                    <label for="greenField" class="form-check-label">Greenfield</label>
                                                </div>
                                                <div class="check-box-button mb-0">
                                                    <input type="checkbox" name="brownField" id="brownField" class="form-check-input" 
                                                    onChange={e => props.variableHandler("projectLandType", e)} checked={brownField}/>
                                                    <label for="brownField" class="form-check-label">Brownfield</label>
                                                </div>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                </div>
                            </div>
                        </>
                    ) : ('')
                ))
            }
        </>
    )
}

export default CategoryTypeFilter;