import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';
import { updateBuildPhase, updateBuildPhaseSwitch } from '../redux/reducer';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { fetchSearchCount } from '../apiclient';
import { createCommonQuery, getBuildPhaseValue } from '../../../utils/commonFunctions';
import { toggleBuildPhaseFilter, updateBuildPhaseValueReducer } from './redux/reducer';

const BuildPhase = (props) => {
    const searchPersist = useSelector((state) => state.searchPersist);
    const filter = useSelector((state) => state.filter)
    const search = useSelector((state) => state.search)
    const [buildPhaseSliderValue, setBuildPhaseSliderValue] = useState(filter.filtersState[filter.selectedSearchType].buildPhase.sliderValue)
    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch();

    const handleCustomCheckbox = (event) => {
            dispatch(updateBuildPhaseSwitch(event.target.checked))
            dispatch(toggleBuildPhaseFilter(event.target.checked))
        setChecked(event.target.checked)
    };

    useEffect(()=>{
        setChecked(search.buildPhaseSwitch)
    },[search.buildPhaseSwitch])

    useEffect(()=>{
        setBuildPhaseSliderValue(filter.filtersState[filter.selectedSearchType].buildPhase.sliderValue)
        setChecked(filter.filtersState[filter.selectedSearchType].buildPhase.active)
    },[filter.filtersState[filter.selectedSearchType].buildPhase])

    const updateBuildPhaseValue = (e) => {
        let activePhase = []
        let updatedSummaryTags = []
        if (e[0] === e[1]) {
            if (e[0] > 0) {
                e[0] = e[0] - 1
            } else {
                e[1] = e[1] + 1
            }
        }
        if (e[0] <= 0 && e[1] >= 1) {
            activePhase.push("not_started")
            updatedSummaryTags.push("Not Started")
        }
        if (e[0] <= 1 && e[1] >= 2) {
            activePhase.push("enabling")
            updatedSummaryTags.push("Enabling works")
        }
        if (e[0] <= 2 && e[1] >= 3) {
            activePhase.push("on_site")
            updatedSummaryTags.push("On-site")
        }
        dispatch(updateBuildPhase(activePhase))
        setBuildPhaseSliderValue(e)
        let payload = {
            activePhase: activePhase,
            sliderValue: e
        }
        dispatch(updateBuildPhaseValueReducer(payload))
    }

    const createPayload = (tags) => {
        let query = {};
        let query1 = {};
        let result = {};
        let values = tags.length > 0 ? tags : []

        query = createCommonQuery({
            key: "project_build_phase",
            operator: "=",
            value: values
        })

        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    // useEffect(() => {
    //     if (filter?.filtersState[filter.selectedSearchType]?.active && filter?.filtersState[filter.selectedSearchType]?.buildPhase?.sliderValue?.length !== 0) {
    //         // fetchSearchCount(searchPersist.appliedContext, filter, searchPersist, dispatch)
    //         props.fetchEntityCounts()
    //     }
    // }, [filter?.filtersState[filter.selectedSearchType]?.buildPhase?.active])


    return (
        <div className="advanced-filter-grid buildPhase-frames" id="new_filter_bp">
            <div className="filterHeading">
                <h5 className="mb-3">Build phase</h5>
            </div>
            <div className={`filterBlock ${checked ? 'activeBlock' : ''}`}>
                <div className="textcontent">
                    <Row className="align-items-center mt-1 mb-3">
                        <Col xs={12} sm={10} md={10} lg={10}>Select at which point in the build you want your projects (dual slide)</Col>
                        <Col xs={12} sm={2} md={2} lg={2}>
                            <div className="switch-panel">
                                <div className="switch-notification">
                                    <Input id="buildPhaseToggle" type="checkbox" name="buildPhaseToggle" className="cmn-toggle cmn-toggle-round" checked={checked} onChange={e => handleCustomCheckbox(e)} />
                                    <Label for="buildPhaseToggle"></Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className={`sliderBlock ${checked === false ? 'disableBlock' : ''}`}>
                        <FormGroup name="buildPhaseFilter" row className="">
                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                <div className="update-location-slider-frames">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <div className={`relative-slider-loc px-3 mt-3`}>
                                                <Slider
                                                    range
                                                    min={0}
                                                    max={3}
                                                    onChange={updateBuildPhaseValue}
                                                    value={buildPhaseSliderValue}
                                                    allowCross={false} 
                                                    />
                                                <div className="buildPhaseIndicator pt-2">
                                                    <Row>
                                                        <Col sm={4} className="text-red text-center">Not started</Col>
                                                        <Col sm={4} className="text-yellow text-center">Enabling works</Col>
                                                        <Col sm={4} className="text-green text-center">On-site</Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BuildPhase;