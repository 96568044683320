import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, FormGroup } from 'reactstrap';
import { getConstructionHubSettings, saveConstructionHubSettings } from "../apiclient"
import '../settings.scss';


const ConstructionsHub = () => {
    const userData = useSelector(state => state.user.user)
    const integrations = useSelector(state => state.integrations)
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('1');
    const [constuctionHubData, setConstuctionHubData] = useState({
        gdpAddress: '',
        tenantId: '',
        clientId: '',
        clientAddress: ''

    })

    const toggle = (tab) => {
        setActiveTab(tab)

    }

    const handleSubmit = () => {
        saveConstructionHubSettings({
            "chub_gdp_address": constuctionHubData.gdpAddress,
            "chub_tenant": constuctionHubData.tenantId,
            "chub_client_id": constuctionHubData.clientId,
            "chub_client_secret": constuctionHubData.clientAddress
        }, userData.client_id
            , dispatch)
    }

    useEffect(() => {
        getConstructionHubSettings(userData && userData.client_id, dispatch)
        if (integrations && integrations.chubPreferences && Object.keys(integrations.chubPreferences).length > 0) {
            setConstuctionHubData((prevState) => {
                return {
                    ...prevState,
                    gdpAddress: integrations.chubPreferences.chub_gdp_address,
                    tenantId: integrations.chubPreferences.chub_tenant,
                    clientId: integrations.chubPreferences.chub_client_id,
                    clientAddress: integrations.chubPreferences.chub_client_secret
                }
            })
        }
    }, [integrations && integrations.chubPreferences && integrations.chubPreferences.chub_gdp_address, integrations.chubPreferences.chub_tenant, integrations.chubPreferences.chub_client_id, integrations.chubPreferences.chub_client_secret])

    return (
        <>
            <Row>
                <Col className="p-0 settings-tab-wrapper">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}>Construction Hub</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="content-wrapper">
                                <div className="content-panel">
                                    <div className="col-md-4 col-sm-12">
                                        <FormGroup>
                                            <div className="icon-addon">
                                                <label className="">GDP-Address</label>
                                                <input name="gdpAddress"
                                                    id="gdpAddress"
                                                    placeholder="GDP-Address"
                                                    autocomplete="off"
                                                    maxlength="4000"
                                                    type="text"
                                                    className="form-control"
                                                    value={constuctionHubData.gdpAddress}
                                                    onChange={(e) => setConstuctionHubData(prev => ({ ...prev, gdpAddress: e.target.value }))}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="icon-addon">
                                                <label className="">TenantId</label>
                                                <input id="tenantId"
                                                    name="tenantId"
                                                    placeholder="TenantId"
                                                    maxlength="4000"
                                                    type="text"
                                                    className="form-control"
                                                    value={constuctionHubData.tenantId}
                                                    onChange={(e) => setConstuctionHubData(prev => ({ ...prev, tenantId: e.target.value }))}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="icon-addon">
                                                <label className="">ClientId</label>
                                                <input id="clientId"
                                                    name="clientId"
                                                    placeholder="ClientId"
                                                    maxlength="4000"
                                                    type="text"
                                                    className="mr-1 mb-1 form-control form-control"
                                                    value={constuctionHubData.clientId}
                                                    onChange={(e) => setConstuctionHubData(prev => ({ ...prev, clientId: e.target.value }))}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="icon-addon">
                                                <label className="">ClientSecret</label>
                                                <input id="clientSecret"
                                                    name="clientAddress"
                                                    placeholder="ClientSecret"
                                                    maxlength="4000"
                                                    type="password"
                                                    className="mr-1 mb-1 form-control form-control"
                                                    value={constuctionHubData.clientAddress}
                                                    onChange={(e) => setConstuctionHubData(prev => ({ ...prev, clientAddress: e.target.value }))}
                                                />
                                            </div>
                                        </FormGroup>
                                        {
                                            constuctionHubData.gdpAddress && constuctionHubData.tenantId && constuctionHubData.clientId && constuctionHubData.clientAddress ? (
                                                <button className={`m-0 mb-3 btn-save-cancel primary-btn`} onClick={handleSubmit}>Save</button>
                                            ) : (
                                                <button disabled className={`m-0 mb-3 btn-save-cancel primary-btn`}>Save</button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    )
}

export default ConstructionsHub;