import React from 'react'
import '../common/layouts/_pagenotfound.scss'

const MasqueradeLoader = () => {

    return (
      <div>
        <div className="row">
          <div className="col">
            <nav role="navigation" className="navbar fixed-top navbar-toggleable-md navbar-light bg-faded top-navigation navbar">
                <a href="#" className="logo-placeholder navbar-brand cursor-default"></a>
            </nav>
          </div>
        </div>
        <div className="row not-found-msg">
          <div className="col">
            <h3>Loading ....</h3>
          </div>
        </div>
      </div>
    )
  }


export default MasqueradeLoader;
