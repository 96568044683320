import React, { useState, useRef, useEffect } from 'react';
import { Col, Dropdown, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom'
import { selectorControlValue } from '../../utils/commonFunctions'
import { updateLeagueOffsetLimitValue, updateLeagueTable } from '../search/redux/searchPeristReducer';
import { updateShowHierarchy } from '../search/redux/reducer';
import LeagueSearchSelector from './selectorDropdown';
import { updateLeagueRemoveFilterToggle } from './reducer';
import { fetchLeagueRolesForCompanyId } from './apiclient';

const LeagueHeader = (props) => {

    const searchPersist = useSelector((state) => state.searchPersist)
    const [sortOrderObj, setSortOrderObj] = useState(searchPersist.defaultSort)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [buttonClicked, setButtonClicked] = useState(false)
    const shareState = useSelector(state => state.savedsearch)
    const leagueTableState = useSelector(state => state.leagueTable)
    const search = useSelector((state) => state.search)
    const filter = useSelector((state) => state.filter)
    const settings = useSelector((state) => state.settings)

    const [searchControlLimit, setSearchControlLimit] = useState(selectorControlValue(search && search.searchListCount))
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        isShowRelatedEntitiesMode: false,
        isModalOpen: false,
        modal: false,
        archive: false
    })
    const [listCount, setListCount] = useState(search && search.searchListCount)
    const [dropdownOpenRoles, setDropdownOpenRoles] = useState(false);
    const [dropdownOpenCategories, setDropdownOpenCategories] = useState(false);
    const [dropdownOpenRegions, setDropdownOpenRegions] = useState(false);
    const [dropdownOpenTime, setDropdownOpenTime] = useState(false);
    const [dropdownOpenSavedSearch, setDropdownOpenSavedSearch] = useState(false);
    const [dropdownOpenTopList, setDropdownOpenTopList] = useState(false);
    const [dropdownOpenSort, setDropdownOpenSort] = useState(false);
    const [dropdownSelection, setDropdownSelection] = useState(false)
    const [showDynamicLabel, setShowDynamicLabel] = useState(false)


    const [selectedRolesValue, setSelectedRolesValue] = useState(searchPersist?.leagueTable?.selectedRolesValue?.label ? searchPersist?.leagueTable?.selectedRolesValue?.label : { "label": "Roles", value: null });
    const [selectedCategoriesValue, setSelectedCategoriesValue] = useState(searchPersist?.leagueTable?.selectedCategoriesValue?.label ? searchPersist?.leagueTable?.selectedCategoriesValue?.label : { "label": "Categories", value: null });
    const [selectedRegionsValue, setSelectedRegionsValue] = useState(searchPersist?.leagueTable?.selectedRegionsValue?.label ? searchPersist?.leagueTable?.selectedRegionsValue?.label : { "label": "Regions", value: null });
    const [selectedTimeValue, setSelectedTimeValue] = useState(searchPersist?.leagueTable?.selectedTimeValue?.label ? searchPersist?.leagueTable?.selectedTimeValue?.label : { label: 'Time period', value: null });
    const [selectedSavedSearch, setSelectedSavedSearch] = useState(searchPersist?.leagueTable?.selectedSavedSearch?.saved_search_name ? searchPersist?.leagueTable?.selectedSavedSearch?.saved_search_name : { "saved_search_name": "Saved Search", query: null });
    const [selectedTopSearch, setSelectedTopSearch] = useState(searchPersist?.leagueTable?.selectedTopSearch?.label ? searchPersist?.leagueTable?.selectedTopSearch?.label : { label: 'Top 50', value: 50 });
    const [selectedSortValue, setSelectedSortValue] = useState(searchPersist?.leagueTable?.selectedSortValue ? searchPersist?.leagueTable?.selectedSortValue : "Value");

    const dropDownToggleRoles = () => { setDropdownOpenRoles(!dropdownOpenRoles) }
    const dropDownToggleCategories = () => { setDropdownOpenCategories(!dropdownOpenCategories) }
    const dropDownToggleRegions = () => { setDropdownOpenRegions(!dropdownOpenRegions) }
    const dropDownToggleTime = () => { setDropdownOpenTime(!dropdownOpenTime) }
    const dropDownToggleSavedSearch = () => { setDropdownOpenSavedSearch(!dropdownOpenSavedSearch) }
    const dropDownToggleTopList = () => { setDropdownOpenTopList(!dropdownOpenTopList) }
    const dropDownToggleSort = () => { setDropdownOpenSort(!dropdownOpenSort) }

    const [dropdownItemsOpenRoles, setDropdownItemsOpenRoles] = useState([{ "label": "Roles", value: null }])
    const [dropdownItemsCategories, setDropdownItemsCategories] = useState([{ "label": "Categories", value: null }])
    const [dropdownItemsRegions, setDropdownItemsRegions] = useState([{ "label": "Regions", value: null }])
    const [dropdownItemsSavedSearch, setDropdownItemsSavedSearch] = useState(['Saved Search'])
    const [dropdownItemsTime, setDropdownItemsTime] = useState([{ label: 'Time period', value: null }, { label: '6 months', value: -180 }, { label: '12 months', value: -365 }, { label: '18 months', value: -540 }, { label: '2 years', value: -730 }, { label: '5 years', value: -1825 }])
    const [dropdownItemsTopList, setdropdownItemsTopList] = useState([{ label: 'Top 50', value: 50 }, { label: 'Top 100', value: 100 }, { label: 'Top 250', value: 250 }, { label: 'Show all', value: searchPersist?.leagueOffsetLimitValue?.records }])

    const project = useSelector((state) => state.project)
    const [roles, setRoles] = useState(project && project.worksWithCompanies && project.worksWithCompanies.companies)
    const [isFilterApplied, setIsFilterApplied] = useState(selectedRolesValue === "Roles" && selectedCategoriesValue === "Categories" && selectedRegionsValue === "Regions" && selectedTimeValue === "Time period" && selectedSavedSearch === "Saved Search" ? false : true)

    const [rolesSearch, setRolesSearch] = useState({})
    const [categoriesSearch, setCategoriesSearch] = useState({})
    const [regionSearch, setRegionSearch] = useState({})
    const [timeSearch, setTimeSearch] = useState({})
    const [savedSearch, setSavedSearch] = useState({})

    useEffect(() => {
        searchPersist?.customerProfile?.project_roles?.values && (setDropdownItemsOpenRoles([{ "label": "Roles", value: null }, { "label": "All Roles" }, ...searchPersist?.customerProfile?.project_roles?.values]))
        searchPersist?.customerProfile?.project_categories?.values && (setDropdownItemsCategories([{ "label": "Categories", value: null }, { "label": "All Categories" }, ...searchPersist?.customerProfile?.project_categories?.values]))
        searchPersist?.customerProfile?.company_geocode?.values && (setDropdownItemsRegions([{ "label": "Regions", value: null }, { "label": "All Regions" }, ...searchPersist?.customerProfile?.company_geocode?.values]))
    }, [searchPersist?.customerProfile])

    useEffect(() => {
        setSearchControlLimit(selectorControlValue(search && search.searchListCount))
        booleanData.isShowRelatedEntitiesMode ? setListCount(search && search.listOfSelectedObjectIdsCount) : setListCount(search && search.searchListCount)
    }, [search.searchListCount, search.listOfSelectedObjectIdsCount], search.countOfSelectedObjectIds)

    useEffect(() => {
        if (selectedRolesValue === "Roles" && selectedCategoriesValue === "Categories" && selectedRegionsValue === "Regions" && selectedTimeValue === "Time period" && selectedSavedSearch === "Saved Search") {
            setIsFilterApplied(false)
        } else {
            setIsFilterApplied(true)
        }

        if (dropdownSelection) {
            setShowDynamicLabel(true)
            setDropdownSelection(false)
        }
    }, [selectedRolesValue, selectedCategoriesValue, selectedRegionsValue, selectedTimeValue, selectedSavedSearch])

    useEffect(() => {
        setShowDynamicLabel(false)
    }, [leagueTableState?.searchList])

    useEffect(() => {
        let savedSearchArray = []
        leagueTableState?.leagueSavedSearchList?.length > 0 && (savedSearchArray = (leagueTableState?.leagueSavedSearchList))
        function sortSavedSearch(a, b) {
            const nameA = String(a.saved_search_name).toLowerCase();
            const nameB = String(b.saved_search_name).toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }
        // Sort the array based on the "saved_search_name" property
        savedSearchArray?.length > 0 && (savedSearchArray = savedSearchArray?.slice().sort(sortSavedSearch))
        setDropdownItemsSavedSearch([{ "saved_search_name": "Saved Search", query: null }, ...savedSearchArray])
    }, [leagueTableState?.leagueSavedSearchList])

    const handleSelectedValue = (item, dropdown) => {
        setDropdownSelection(true)
        if (dropdown === 'Rolessearch') {
            let rolesValue = []
            setSelectedRolesValue(item.label);

            if (item?.label === 'All Roles') {
                const allRolesValue = searchPersist?.customerProfile?.project_roles?.values?.map(role => role?.value)
                rolesValue = allRolesValue
            } else {
                rolesValue = item?.value
            }
            setRolesSearch({ dropdown: 'selectedRolesValue', value: { label: item?.label, value: rolesValue } })
        }

        if (dropdown === 'Categoriessearch') {
            let categoriesValue = []
            setSelectedCategoriesValue(item?.label);

            if (item?.label === 'All Categories') {
                const allCategoriesArray = searchPersist?.customerProfile?.project_categories?.values?.reduce((result, item) => {
                    // Push the current item's value into the result array
                    result.push(item.value);
                    // If the current item has children, push their values as well
                    if (Array.isArray(item.children)) {
                        item.children.forEach(child => {
                            result.push(child.value);
                        });
                    }
                    return result;
                }, []);

                categoriesValue = allCategoriesArray;
            } else if (item?.label === 'Categories') {
                categoriesValue = item?.value
            } else {
                item?.children?.map((child) => categoriesValue?.push(child.value))
            }
            setCategoriesSearch({ dropdown: 'selectedCategoriesValue', value: { label: item?.label, value: categoriesValue } })
        }

        if (dropdown === 'Regionsearch') {
            let regionsValue = []
            setSelectedRegionsValue(item?.label);
            if (item?.label === 'All Regions') {
                const allRegionsArray = searchPersist?.customerProfile?.company_geocode?.values?.reduce((result, item) => {
                    // Push the current item's value into the result array
                    result.push(item.value);
                    // If the current item has children, push their values as well
                    if (Array.isArray(item.children)) {
                        item.children.forEach(child => {
                            result.push(child.value);
                        });
                    }
                    return result;
                }, []);

                regionsValue = allRegionsArray;
            } else if (item?.label === 'Regions') {
                regionsValue = item?.value
            } else {
                item?.children?.map((child) => regionsValue?.push(child.value))
            }
            setRegionSearch({ dropdown: 'selectedRegionsValue', value: { label: item?.label, value: regionsValue } })
        }

        if (dropdown === 'Timesearch') {
            setSelectedTimeValue(item?.label);
            setTimeSearch({ dropdown: 'selectedTimeValue', value: item })
        }

        if (dropdown === 'Savedsearch') {
            setSelectedSavedSearch(item?.saved_search_name);
            setSavedSearch({ dropdown: 'selectedSavedSearch', value: item })
        }

        if (dropdown === 'Topsearch') {
            setSelectedTopSearch(item?.label);
            dispatch(updateLeagueTable({ dropdown: 'selectedTopSearch', value: item }))
            dispatch(updateLeagueTable({ dropdown: rolesSearch?.dropdown, value: rolesSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: categoriesSearch?.dropdown, value: categoriesSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: regionSearch?.dropdown, value: regionSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: timeSearch?.dropdown, value: timeSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: savedSearch?.dropdown, value: savedSearch?.value }))

            if (item?.label === "Show all") {
                let isLoadMore = ((searchPersist?.leagueOffsetLimitValue?.offset + searchPersist?.leagueOffsetLimitValue?.records) < leagueTableState?.leagueSearchListCount) ? true : false
                if (isLoadMore) {
                    dispatch(updateLeagueTable({ dropdown: 'isShowMoreActive', value: true }))
                } else {
                    dispatch(updateLeagueTable({ dropdown: 'isShowMoreActive', value: false }))
                }
                dispatch(updateLeagueOffsetLimitValue({ offset: searchPersist?.leagueOffsetLimitValue?.offset, records: searchPersist?.leagueOffsetLimitValue?.records }))
            } else {
                dispatch(updateLeagueOffsetLimitValue({ offset: 0, records: item?.value }))
                dispatch(updateLeagueTable({ dropdown: 'isShowMoreActive', value: false }))
            }
            setRolesSearch({})
            setCategoriesSearch({})
            setRegionSearch({})
            setTimeSearch({})
            setSavedSearch({})
        }

        if (dropdown === 'Sort') {
            setSelectedSortValue(item);
            dispatch(updateLeagueTable({ dropdown: 'selectedSortValue', value: item }))
            dispatch(updateLeagueTable({ dropdown: rolesSearch?.dropdown, value: rolesSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: categoriesSearch?.dropdown, value: categoriesSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: regionSearch?.dropdown, value: regionSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: timeSearch?.dropdown, value: timeSearch?.value }))
            dispatch(updateLeagueTable({ dropdown: savedSearch?.dropdown, value: savedSearch?.value }))

            dispatch(updateLeagueOffsetLimitValue({ offset: 0, records: searchPersist?.leagueOffsetLimitValue?.records }))

            setRolesSearch({})
            setCategoriesSearch({})
            setRegionSearch({})
            setTimeSearch({})
            setSavedSearch({})
        }

    }

    const goBack = (event) => {
        dispatch(updateShowHierarchy({ showCompanyHierarchy: false }))
    }

    const handleToggleChange = () => {
        setButtonClicked(true)
        dispatch(updateLeagueRemoveFilterToggle(!leagueTableState?.removeFilterToggle))
    }

    useEffect(() => {
        if (buttonClicked) {
            fetchLeagueRolesForCompanyId(search?.objectId, search, searchPersist, dispatch, leagueTableState?.removeFilterToggle)
            setButtonClicked(false)
        }
    }, [buttonClicked])

    return (
        <div className="league-header">
            <Row className="page-title-bar">
                <Col className="py-2">
                    <h2 className="h5 pl-3 mb-0">League table</h2>
                    {leagueTableState?.leagueSearchListCount > 0 && !leagueTableState?.showCompanyHierarchy && !search.showCompanyHierarchy ?
                        <span class="pl-3">
                            {searchPersist?.leagueTable?.selectedTopSearch?.label !== 'Show all' && searchPersist?.leagueTable?.selectedTopSearch?.value == leagueTableState?.searchList?.length ?
                                `Top ${searchPersist?.leagueTable?.selectedTopSearch?.value} of ` : ''}{`${leagueTableState?.leagueSearchListCount} ${leagueTableState?.leagueSearchListCount === 1 ? 'company' : 'companies'}`}
                        </span>
                        : ""
                    }
                </Col>
            </Row>
            <div className="d-flex justify-content-start">

                <div className="pl-4 mr-auto d-flex flex-wrap">
                    <div className="pr-0 mr-2 mb-2">
                        {/* {
                            (search?.showCompanyHierarchy) ?
                                <a onClick={goBack} color="primary" className={`${search.showCompanyHierarchy ? 'show' : 'hide'} map-back me-2`}>
                                    back
                                </a> : ''
                        } */}

                        <LeagueSearchSelector
                            pageSize={50}
                            prevNextList='LeagueTableList'
                        ></LeagueSearchSelector>
                    </div>

                    <div className="pr-0 mr-2 mb-2">
                        <Dropdown isOpen={dropdownOpenRoles} toggle={dropDownToggleRoles}>
                            <DropdownToggle caret>
                                {selectedRolesValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsOpenRoles.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Rolessearch')}>{item.label}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="pr-0 mr-2 mb-2">
                        <Dropdown isOpen={dropdownOpenCategories} toggle={dropDownToggleCategories}>
                            <DropdownToggle caret>
                                {selectedCategoriesValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsCategories.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Categoriessearch')}>{item.label}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="pr-0 mr-2 mb-2">
                        <Dropdown isOpen={dropdownOpenRegions} toggle={dropDownToggleRegions}>
                            <DropdownToggle caret>
                                {selectedRegionsValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsRegions.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Regionsearch')}>{item.label}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="pr-0 mr-2 mb-2">
                        <Dropdown isOpen={dropdownOpenTime} toggle={dropDownToggleTime}>
                            <DropdownToggle caret>
                                {selectedTimeValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsTime.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Timesearch')}>{item?.label}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="pr-0 mr-2 mb-2">
                        <Dropdown isOpen={dropdownOpenSavedSearch} toggle={dropDownToggleSavedSearch}>
                            <DropdownToggle caret>
                                {selectedSavedSearch}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsSavedSearch.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Savedsearch')}>{item?.saved_search_name}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="d-flex pr-0 mb-2 btn-button-dropdown">
                        <Dropdown isOpen={dropdownOpenTopList} toggle={dropDownToggleTopList}>
                            <DropdownToggle caret>
                                {selectedTopSearch}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dropdownItemsTopList?.map((item, index) => (
                                    <DropdownItem key={index} onClick={() => handleSelectedValue(item, 'Topsearch')}>{item?.label}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {(showDynamicLabel) ? (
                        <div className="switch-panel-project-tab mt-2 me-3 ms-2">
                            <div className="switch-project-tab d-flex align-items-center">
                                <span className="me-2">{`Click ${searchPersist?.leagueTable?.selectedTopSearch?.label ? searchPersist?.leagueTable?.selectedTopSearch?.label : 50} to refresh the list`}</span>
                            </div>
                        </div>
                    ) : ''}
                </div>

                <Row className="pr-4 ml-auto sort-league">
                    <Col className="d-flex">
                        {
                            (leagueTableState?.showCompanyHierarchy && search.showCompanyHierarchy && isFilterApplied) ? (
                                <div className="switch-panel-project-tab mt-2 me-3">
                                    <div className="switch-project-tab d-flex align-items-center">
                                        <span className="me-2">Remove filters</span>
                                        <Input id="switch-apply-filter" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                            checked={!leagueTableState?.removeFilterToggle}
                                            onClick={handleToggleChange}
                                        />
                                        <Label for="switch-apply-filter" className="mb-0"></Label>
                                    </div>
                                </div>
                            ) : ''
                        }
                        <Dropdown isOpen={dropdownOpenSort} toggle={dropDownToggleSort}>
                            <DropdownToggle caret>
                                {selectedSortValue}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => handleSelectedValue('Value', 'Sort')}>Value
                                </DropdownItem>
                                <DropdownItem onClick={() => handleSelectedValue('Projects', 'Sort')}>Projects
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default LeagueHeader;