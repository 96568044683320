import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Media, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup, Input } from 'reactstrap';
import SearchInput, { createFilter } from 'react-search-input';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { createReceipientUserList, getAvailableLicenses } from '../utils';
import UserAssignLicenseList from './userAssignLicenseList';
import { fetchAllUsersForAdmin, grantUserLicenses } from '../apiclient';
import { resetAdminUserList, resetUserSearchList, updateAdminUserList, updateAdminUserOffsetLimitValue, updateAssignLicenseSearchTerm,  } from '../reducer';

const AssignLicence = (props) => {
  const useradminData = useSelector(state => state.useradmin);
  const [searchTerm, setSearchTerm] = useState(useradminData?.assignLicenceSearchTerm);
  const [availableLicenses, setAvailableLicenses] = useState(useradminData?.userLicences);
  const [displaySave, setDisplaySave] = useState(true);
  const [showMoreFlag, setShowMoreFlag] = useState(false);
  const [filteredAdminUserList, setFilteredAdminUserList] = useState(useradminData?.adminUserList);
  const [assignedLicences, setAssignedLicences] = useState(useradminData?.assignedLicences)
  const [isShowMoreActive,setIsShowMoreActive]=useState(useradminData?.isUserAdminShowMoreActive)
  const [showMoreClicked, setShowMoreClicked] = useState(false)
  const dispatch = useDispatch();

  const handleAdminUserSearch = (term) => {
    setSearchTerm(term);
    dispatch(updateAssignLicenseSearchTerm(term))
    setShowMoreClicked(true)
  
  };

  const handleUserListClose = (event) => {
    event.preventDefault()
    setSearchTerm("")
    dispatch(updateAssignLicenseSearchTerm(""))
  }

  const handleSubmit = (event) => {
    if (assignedLicences?.length > 0) {
      setDisplaySave(false)
      grantUserLicenses(assignedLicences,searchTerm, dispatch)
    }
  }
  const handleShowMore = () => {
    let newOffset = useradminData?.offsetLimitValue?.offset + useradminData?.offsetLimitValue?.records;
    dispatch(updateAdminUserOffsetLimitValue({ offset: newOffset, records: useradminData?.offsetLimitValue?.records }));
    setShowMoreClicked(true)
    let paylaod = {
      offset: newOffset,
      limit: useradminData?.offsetLimitValue?.records,
      searchTerm: ""
    }
    fetchAllUsersForAdmin(paylaod, dispatch, useradminData)
}


useEffect(() => {
  if (props?.activeTab === "assignLicence") {
     dispatch(resetUserSearchList([]))
     dispatch(resetAdminUserList([]))
     if (searchTerm === "") {
      dispatch(updateAdminUserOffsetLimitValue({ offset: 0, records: 50 }));
     }
    let paylaod = {
      offset: 0,
      limit: 50,
      searchTerm: searchTerm
    }
    fetchAllUsersForAdmin(paylaod, dispatch, useradminData)
  }
}, [searchTerm,props?.activeTab ]);

  useEffect(() => {
    if (useradminData?.userLicences?.length > 0) {
      setAvailableLicenses(useradminData?.userLicences);
    }
  }, [useradminData?.userLicences]);

  useEffect(() => {
    if (useradminData?.adminUserList) {
      setFilteredAdminUserList(useradminData?.adminUserList);
    }
  }, [useradminData?.adminUserList]);

  useEffect(() => {
    let sortedUsersList = createReceipientUserList(useradminData?.allUserDetails);
    // setFilteredAdminUserList(sortedUsersList);
    dispatch(updateAdminUserList(sortedUsersList))

  }, [useradminData?.allUserDetails, searchTerm]);

  useEffect(() => {
    if (useradminData?.assignedLicences) {
      setAssignedLicences(useradminData?.assignedLicences);
    }
  }, [useradminData?.assignedLicences]);

  useEffect(() => {
    setIsShowMoreActive(useradminData?.isUserAdminShowMoreActive)
  }, [useradminData?.isUserAdminShowMoreActive])

  useEffect(()=>{
    setSearchTerm(useradminData?.assignLicenceSearchTerm)
  },[useradminData?.assignLicenceSearchTerm])

  return (
    props.activeTab === 'assignLicence' &&
    (<div className="content-wrapper">
      <div className="user-tab">
        <Media className="user-search-panel">
          <Media body>
            <Media heading>
              <div className="p-0 textfield-wrapper col col-sm-12 col-md-6 col-lg-3">
                <SearchInput
                  placeholder="Find user"
                  type="text"
                  className="label-search search-user-textbox"
                  onChange={handleAdminUserSearch}
                  value={searchTerm}
                />
                <Button
                  className={`clear-textfield ${searchTerm ? 'display-block' : 'display-none'}`}
                  onClick={handleUserListClose}
                  tabIndex="-1">x</Button>
              </div>
            </Media>
          </Media>
        </Media>
        <div className="save-button-row">

          <button onClick={handleSubmit} className={`outline-cta-btn ${displaySave ? '' : 'disabled'}`}>Save</button>

        </div>
        <div className="assign-user-panel">
          <div className="row">
            <table className="table">
              <thead>
                <tr className="main-header">
                  <th class="left">Licence summary</th>
                  {availableLicenses?.length > 0 ? (
                    availableLicenses?.map(function (license, index) {
                      return <th className="center" key={index}>{license.license_display_name}</th>
                    })) : ("")}
                </tr>
                <tr className="inside-header">
                  <td className="left">Available licences / Total licences</td>
                  {availableLicenses.length > 0 ? (
                    availableLicenses.map(function (available, index) {
                      return <td className="center" key={index}><span className="circle">{getAvailableLicenses(available.total, available.assigned)} / {available.total}</span></td>
                    })) : ("")}
                </tr>
              </thead>
              <tbody className="main-body">
                <ColoredScrollbars className="details-panel-scrollbar"
                >
                  <div>
                    {filteredAdminUserList.length > 0 ? (
                      filteredAdminUserList.map(function (user, index) {
                        return <UserAssignLicenseList {...props}
                          userLicense={user}
                          index={index}
                          key={index}
                          filteredAdminUserList={filteredAdminUserList}
                          availableLicenses={availableLicenses}
                        />
                      })) : (<p className="no-user-found">No users found</p>)}
                  </div>
                  {
                    isShowMoreActive && filteredAdminUserList?.length > 0 && searchTerm === "" ? (
                      <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                        <Button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</Button>
                      </div>
                    ) : ''
                  }
                </ColoredScrollbars>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>)
  );
};
export default AssignLicence;