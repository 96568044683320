import React from 'react';
import Login from '../../components/login';

const Authentication = () => {
	
	return (
		<div className="Authentication">
			<Login />
		</div>
	);
}

export default Authentication;