import React from 'react';
import { Col } from 'reactstrap'
import nicp from '../common/images/nicp@2x.png';
import ons from '../common/images/ons@2x.png';

const SignInLeftPanel = () => {
	return (
    <Col className="login-info col-12 col-md-6 px-5">
        <div className="logo-container">
        </div>
        <div className="info">
          {
            (
            <div>
              <p className="info-sub">Barbour ABI exists to help your business find more work within the construction industry. Because our intelligence is shaped by relationships rather than algorithms, our info is sharper and our insights are deeper – always. <br/><br/>
              Sign in for your latest personalised, actionable leads.</p>
              <hr/>
              <p>We’re a trusted partner of organisations with the most stringent standards in the UK.</p>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <img src={nicp} />
                  <p className="partner-info">Partner of the Infrastructure and Projects Authority in providing the National Infrastructure and Construction Pipeline</p>
                </div>
            
                <div className="col-12 col-sm-6">
                  <img src={ons} />
                  <p className="partner-info">Chosen provider of Construction New Orders estimates to the ONS</p>
                </div>

              </div>
            </div>
            )
          }
        </div>
        </Col>
	);
}

export default SignInLeftPanel;