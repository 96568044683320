import React, { useState, useEffect } from 'react';
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { searchFilterInput} from '../redux/reducer';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css'
import { fetchSearchCount, logEventForRequestData } from '../apiclient';
import { getUpdateFilterValue } from '../../../utils/commonFunctions';
import SubscriptionCriteriaMsgModal from './subscriptionCriteriaMsgModal';
import RequestDataModal from './requestDataModal';
import { toggleFilterActiveState, updateLastUpdatedFilter, updateNewPublishFilter } from './redux/reducer';

const UpdatedFilter = (props) => {
    const userData = useSelector((state) => state.user)
    const filter = useSelector((state) => state.filter)
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [marks, setMarks] = useState(filter?.filtersState?.[filter.selectedSearchType]?.new?.limit ? {'-11': '5 years', '0': 'Now', [filter?.filtersState[filter.selectedSearchType]?.new.limit.key]: filter?.filtersState[filter.selectedSearchType]?.new.limit.value} : {'-11': '5 years', '0': 'Now'});
    const [newOrUpdatedSlider, setNewOrUpdatedSlider] = useState(-6);
    const [newOrUpdatedSliderMsg, setNewOrUpdatedSliderMsg] = useState("in the last 6 months");
    const [subscriptionMsgModal, setSubscriptionMsgModal] = useState(false);
    const [requestDataModal, setRequestDataModal] = useState(false);
    const [toggleActive, setToggleActive] = useState(false);

    const {
        search_container,
    } = useSelector((state) => {
        return {
            search_container: state.search
        }
    });

    const dispatch = useDispatch();

    const handleToggle = (e) => {
        setToggleActive(e.target.checked)
        if(!e.target.checked){
            if(filter?.filtersState[filter.selectedSearchType]?.updated.active){
               dispatch(toggleFilterActiveState({ key: "updated", value: e.target.checked }))
            }
            if(filter?.filtersState[filter.selectedSearchType]?.new.active){
                dispatch(toggleFilterActiveState({ key: "new", value: e.target.checked }))
            }
        }
    }

    const handleCustomCheckbox = (event) => {
        const { checked, name } = event.target;
        
        dispatch(searchFilterInput({
            key: name,
            value: checked
        }))
        

        if((name === 'updated' && checked === false) && (search_container.new === false)) {
            dispatch(searchFilterInput({
                key: 'updated',
                value: false
            }))
        }
        if((name === 'new' && checked === false) && (search_container.updated === false)) {
            dispatch(searchFilterInput({
                key: 'updated',
                value: false
            }))
        }
        if((name === 'new' && checked === false) && (name === 'updated' && checked === false)) {
            dispatch(searchFilterInput({
                key: 'updated',
                value: false
            }))
        }
        props.variableHandler(event.target.name,event)
        // props.fetchEntityCounts({initTags: props.initTags})
    };

    useEffect(() => {
        if (newOrUpdatedSliderMsg === "") {
          onSliderChangeforUpdate(getUpdateFilterValue(filter?.filtersState[filter.selectedSearchType]?.updated.options.filter((c) => c.active)));
        }
        if (filter?.filtersState[filter.selectedSearchType]?.new?.toggleActive) {
          filter?.filtersState[filter.selectedSearchType]?.new.options.map((item) => {
            if (item.active) {
              onSliderChangeforUpdate(item.restrictedLimit * -1, false);
            }
          });
        } else if (filter?.filtersState[filter.selectedSearchType]?.updated?.toggleActive) {
          filter?.filtersState[filter.selectedSearchType]?.updated.options.map((item) => {
            if (item.active) {
              onSliderChangeforUpdate(item.restrictedLimit * -1, false);
            }
          });
        }
        if(filter?.filtersState[filter.selectedSearchType]?.updated && filter?.filtersState[filter.selectedSearchType]?.new){
            setToggleActive(filter?.filtersState[filter.selectedSearchType]?.updated.active || filter?.filtersState[filter.selectedSearchType]?.new.active)
        }
        if (
          filter?.filtersState[filter.selectedSearchType]?.new?.active &&
          filter?.filtersState[filter.selectedSearchType]?.updated?.active &&
          !filter?.filtersState[filter.selectedSearchType]?.new?.toggleActive &&
          !filter?.filtersState[filter.selectedSearchType]?.updated?.toggleActive &&
          (filter?.filtersState[filter.selectedSearchType]?.new.options.filter((c) => c.active).length === 0 ||
            filter?.filtersState[filter.selectedSearchType]?.new?.options.filter((c) => c.active).length > 1) &&
          (filter?.filtersState[filter.selectedSearchType]?.updated?.options.filter((c) => c.active).length === 0 ||
            filter?.filtersState[filter.selectedSearchType]?.updated?.options.filter((c) => c.active).length > 1)
        ) {
          onSliderChangeforUpdateAfter(-6);
        }

        
      }, [filter]);


    const onSliderChangeforUpdate = (e, slideFlag) => {
        var sliderValue = {};
        let sliderOriginalValue = {
            "1825": "-11",
            "1460": "-10",
            "1095": "-9",
            "730": "-8",
            "365": "-7",
            "180": "-6",
            "90": "-5",
            "60": "-4",
            "30": "-3",
            "14": "-2",
            "7": "-1",
            "1": "0"
        }
        for (var i = 0; i < filter?.filtersState[filter.selectedSearchType]?.new.options.length; i++) {
            sliderValue[sliderOriginalValue[filter?.filtersState[filter.selectedSearchType]?.new.options[i].value]] = filter?.filtersState[filter.selectedSearchType]?.new.options[i].name
        }
        if (sliderValue[e] != undefined) {
            setNewOrUpdatedSlider(e)
            setNewOrUpdatedSliderMsg(sliderValue[e])
            if (slideFlag) {
                setSubscriptionMsgModal(false)
            }
        } else {
            setSubscriptionMsgModal(true)
        }
    }
    const onSliderChangeforUpdateAfter = (e) =>{
        var sliderValue = {};
        let sliderOriginalValue = {
            "1825": "-11",
            "1460": "-10",
            "1095": "-9",
            "730": "-8",
            "365": "-7",
            "180": "-6",
            "90": "-5",
            "60": "-4",
            "30": "-3",
            "14": "-2",
            "7": "-1",
            "1": "0"
        }
        for (var i = 0; i < filter?.filtersState[filter.selectedSearchType]?.new.options.length; i++) {
            sliderValue[sliderOriginalValue[filter?.filtersState[filter.selectedSearchType]?.new.options[i].value]] = filter?.filtersState[filter.selectedSearchType]?.new.options[i].name
        }
        if (sliderValue[e] != undefined){
            setNewOrUpdatedSlider(e)
            setNewOrUpdatedSliderMsg(sliderValue[e])
            dispatch(updateNewPublishFilter(sliderValue[e]));
            dispatch(updateLastUpdatedFilter(sliderValue[e]));
            // props.fetchEntityCounts({initTags: props.initTags})
            
        } else {
            setSubscriptionMsgModal(true)
        }
    }

    const handleRequestDataToggle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRequestDataModal(!requestDataModal);
        setSubscriptionMsgModal(false);
        if (event.target.innerText.toLowerCase().indexOf("request data") !== -1)
            logEventOnRequestData()
      };

      const logEventOnRequestData = () => {
          let payload = {filterName: 'Updated'}
        logEventForRequestData(payload, dispatch, )
    }

    return (
        <div className="advanced-filter-grid" id="new_filter_updated">
            <div className="filterHeading">
                <h5 className="mb-3">Updated</h5>
            </div>
            <div className={`filterBlock ${toggleActive ? 'activeBlock' : ''}`}>
                <div className="textcontent">
                    <Row className="align-items-center">
                        <Col xs={12} sm={8} md={8} lg={11}>Filter by published or updated date</Col>
                        <Col xs={12} sm={8} md={8} lg={1} className="pl-2 pr-0">
                            <div className="switch">
                                <Input id="switch-updated" name="updateToggle" type="checkbox" className="cmn-toggle cmn-toggle-round" checked={toggleActive} onChange={e => handleToggle(e)} />
                                <Label for="switch-updated" className="mb-0"></Label>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={`sliderBlock ${toggleActive === false ? 'disableBlock' : ''}`}>
                    <FormGroup id="updatedFilter" name="updatedFilter" row className="">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="slider-frames update-location-slider-frames">
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <FormGroup>
                                            <div className="check-box-button">
                                                <Input name="updated" type="checkbox" id="Updated" checked={filter?.filtersState[filter.selectedSearchType]?.updated?.active} onChange={e => handleCustomCheckbox(e)} />
                                                <Label check for="Updated">Updated</Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <FormGroup>
                                            <div className="check-box-button">
                                                <Input name="new" type="checkbox" id="New" checked={filter?.filtersState[filter.selectedSearchType]?.new?.active} onChange={e => handleCustomCheckbox(e)} />
                                                <Label check for="New">New</Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="textcontent">
                                            <span>Show new projects updated projects<strong> {newOrUpdatedSliderMsg}</strong></span>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className={`position-relative relative-slider-loc px-3 mb-4 ${((search_container.udpated === false) && (search_container.new === false)) ? 'disableBlock' : ''}`}>
                                            <Slider
                                                max={0}
                                                min={-11}
                                                marks={marks}
                                                value={newOrUpdatedSlider}
                                                onChange={(e) => onSliderChangeforUpdate(e, true)}
                                                onAfterChange={onSliderChangeforUpdateAfter}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        subscriptionMsgModal ? 
                                        <SubscriptionCriteriaMsgModal
                                        subscriptionMsgModalShow={subscriptionMsgModal}
                                        handleRequestDataToggle = {handleRequestDataToggle}
                                        rangeName = {'date'}
                                        /> : ("")
                                    }
                                    {
                                            requestDataModal ? 
                                            <RequestDataModal
                                                requestDataModalShow={requestDataModal}
                                                handleRequestDataToggle = {handleRequestDataToggle}
                                                filterName={'Updated'}
                                            /> : ("")
                                    }
                                </Row>
                            </div>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )

}
export default UpdatedFilter;