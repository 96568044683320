import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  fetchDetailUpdatesData,
  fetchLabelData,
  fetchLabelDataForAllDetails,
  fetchGroupCompanies,
  fetchCompanyProjectRoles,
  fetchPeopleWorkingOnThisProjects,
  fetchWorksWithCompaniesProjects,
} from "../../details/apiclient";
import PeopleTab from "./tabs/people";
import ProjectTab from "./tabs/projectsTab/project";
import RelationshipTab from "./tabs/relationshipsRoles";
import UpdateTab from "../../details/updatetab";
import ActivitiesTab from "../../details/activitytabs";
import MapDirections from "../../details/map";
import {
  updateActivityIsClicked,
  updateCompanyProjectRoles,
  updateGroupCompaniesDetails,
  updateIfMoreProjectDataForRoleIdAvailable,
  updateListSelection,
  updateProjectDataForRoleId,
  updateShowMoreOffsetLimit,
  updateWorksWithCompanies,
} from "../redux/reducer";
import GroupCompaniesTab from "./tabs/groupCompaniesTab";
import WorksWithCompanies from "./tabs/worksWithCompaniesTab/worksWithCompanies";
import { COMPANYDETAILS } from "../../../utils/tour";

const CompanyTabDetail = (props) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(props.activeTab);
  const [mapDirectionFlag, setMapDirectionFlag] = useState(false);
  const details = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const [objectId] = useState(location.pathname.split("/")[4]);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  useEffect(() => {
    if (!details?.isPrint && activeTab === "8") {
      setMapDirectionFlag(true);
    }
  }, [details?.isPrint]);

  useEffect(() => {
    if (details.activityIsClicked === false) {
      // if (location?.state?.prevNextList === "projectRoles") {
      //   props.setActiveTab(
      //     location.state?.tab ? location.state.tab : "2"
      //   );
      // } else {
      props.setActiveTab(
        location.state?.activeTab ? location.state?.activeTab : "2"
      );
      // }
      if (!details.companyProjectRoles?.length) {
        fetchCompanyProjectRoles(location.pathname.split("/")[4], dispatch);
      }
      if (
        location.pathname.split("/")[2] === "company" &&
        location.pathname.split("/")[3] != 0 &&
        location.pathname.split("/")[4].length > 0
      ) {
        let projectIdForPeople = location.pathname.split("/")[3];
        let companyIdForPeople = location.pathname.split("/")[4];
        let peopleWorkingOnProjectPayload = {
          companyId: companyIdForPeople,
          projectId: projectIdForPeople,
        };
        fetchPeopleWorkingOnThisProjects(
          peopleWorkingOnProjectPayload,
          dispatch
        );
      }
    }
  }, [location]);

  const resetSelection = () => {
    dispatch(
      updateListSelection({
        count: 0,
        entityType: "relatedCompaniesJointProjects",
      })
    );
    dispatch(
      updateListSelection({ count: 0, entityType: "worksWithCompanies" })
    );
    dispatch(updateListSelection({ count: 0, entityType: "companyProjects" }));
    dispatch(updateListSelection({ count: 0, entityType: "groupedCompanies" }));
  };
  const toggle = (tab) => {
    dispatch(updateActivityIsClicked(false));
    dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
    dispatch(updateIfMoreProjectDataForRoleIdAvailable(false));
    dispatch(updateProjectDataForRoleId({ projects: [] }))
    dispatch(updateWorksWithCompanies({ companies: [] }))
    dispatch(updateGroupCompaniesDetails([]));

    if (activeTab !== tab) {
      props.setActiveTab(tab);
    }
    if (tab === "4") {
      let worksWithCompaniesProjectsPayload = {
        objectId: location.pathname.split("/")[4],
        offset: 0,
        records: 50,
      };
      fetchWorksWithCompaniesProjects(
        worksWithCompaniesProjectsPayload,
        dispatch
      );
      resetSelection();
      dispatch(
        updateListSelection({ count: 0, entityType: "worksWithCompanies" })
      );
    } else if (tab === "5") {
      fetchDetailUpdatesData(
        details && details.detailsPage.objectId,
        details && details.detailsPage.objectType,
        dispatch
      );
      resetSelection();
    } else if (tab === "6") {
      fetchLabelData(
        details && details.detailsPage.objectId,
        details && details.detailsPage.objectType,
        dispatch
      );
      fetchLabelDataForAllDetails(dispatch);
    } else if (tab === "2" || tab === "3") {
      fetchCompanyProjectRoles(
        details && details.detailsPage.company_id,
        dispatch
      );
      resetSelection();
    } else if (tab === "7") {
      let groupCompaniesPayload = {
        id: location.pathname.split("/")[4],
        sort: "-max_project_last_published",
      };
      dispatch(updateGroupCompaniesDetails([]));
      fetchGroupCompanies(groupCompaniesPayload, dispatch, details);
      resetSelection();
    } else if (tab === "8") {
      setMapDirectionFlag(true);
    }
  };

  useEffect(() => {
    if (details.activityIsClicked === true) {
      if (
        details?.summaryActivity === "tags" ||
        details?.summaryActivity === "notes" ||
        details?.summaryActivity === "shares" ||
        details?.summaryActivity === "calendar_events" ||
        details?.summaryActivity === "collab"
      ) {
        props.setActiveTab("6");
      } else {
        props.setActiveTab("1");
      }
    }
  }, [details.summaryActivity, details.activityIsClicked]);

  return (
    <Row className="pt-3 ms-0 me-0 mb-3 project-tab-panel">
      <Col className="p-0 company-tab-wrapper">
        <Nav tabs>
          <span className="d-flex" data-intro={COMPANYDETAILS.tabs.intro} data-position={COMPANYDETAILS.tabs.position} data-class={COMPANYDETAILS.tabs.class}>

            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggle("1")}
              >
                People
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => toggle("2")}
              >
                Projects
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => toggle("3")}
              >
                Relationships
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "4" ? "active" : ""}
                onClick={() => toggle("4")}
              >
                Works with Companies
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "5" ? "active" : ""}
                onClick={() => toggle("5")}
              >
                Updates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "6" ? "active" : ""}
                onClick={() => toggle("6")}
              >
                Activities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "7" ? "active" : ""}
                onClick={() => toggle("7")}
              >
                Group Companies
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "8" ? "active" : ""}
                onClick={() => toggle("8")}
              >
                Map
              </NavLink>
            </NavItem>
          </span>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="p-3">
            <PeopleTab />
          </TabPane>
          <TabPane tabId="2" className="p-3">
            <ProjectTab />
          </TabPane>
          <TabPane tabId="3" className="p-3">
            <RelationshipTab />
          </TabPane>
          <TabPane tabId="4" className="p-3">
            <WorksWithCompanies />
          </TabPane>
          <TabPane tabId="5" className="p-3">
            <UpdateTab activeTab={activeTab} />
          </TabPane>
          <TabPane tabId="6" className="p-3">
            <ActivitiesTab sharedType="company" objectId={objectId} />
          </TabPane>
          <TabPane tabId="7" className="p-3">
            <GroupCompaniesTab />
          </TabPane>
          <TabPane tabId="8">
            <MapDirections mapDirectionFlag={mapDirectionFlag} />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default CompanyTabDetail;
