import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import 'rc-slider/assets/index.css';
import IncludeExcludeGrid from '../filters/includeExcludeGrid'

const StagesFilter = (props) => {
    const searchPersist = useSelector((state) => state.searchPersist)
    const filter = useSelector((state) => state.filter)
    const [data, setData] = useState(searchPersist?.customerProfile['project_stages'].values)
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_stages']?.all_subscribed)
    const [checkboxFilterList, setCheckboxFilterList] = useState([{
        title: 'Stages',
        advanceFilterStateKey: 'stages',
        filterType: 'includeStages',
        subFilterType: "children",
        isIncludeExclude: true,
        includeKey: 'includeStages',
        excludeKey: 'advanceExcludeStages'
    }])
    const [toggleActiveExclude, setToggleActiveExclude] = useState(filter.filtersState[searchPersist.appliedContext]?.excludeStages?.toggleActive ? filter.filtersState[searchPersist.appliedContext].excludeStages.toggleActive : false)
    const [isClearAll , setIsClearAll] = useState(props?.isClearAll)
    const [showSubscribed, setShowSubscribed] = useState(true)
    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }
    
    useEffect(() => {
        setIsClearAll(props?.isClearAll)
    }, [props?.isClearAll])

    useEffect(() => {
        setToggleActiveExclude(filter.filtersState[searchPersist.appliedContext]?.excludeStages?.toggleActive)
    }, [filter.filtersState[searchPersist.appliedContext].excludeStages])

    return (
        <div className="advanced-filter-grid" id="new_filter_stages">
            <div className="filterHeading">
                <h5 className="mb-3">Stages</h5>
            </div>
            <div className="filterBlock">
                <div className="textcontent">
                    Select the stages you want to include or exclude. By default, we include on hold and exclude withdrawn and abandoned.
                </div>
                <div className="multiStageBlock">
                    {
                        checkboxFilterList.map(mapping => {
                            return props.currentAdvancedSearch && (props.currentAdvancedSearch[mapping.includeKey] || props.currentAdvancedSearch[mapping.excludeKey]) ? (
                                <>
                                    <div className="checkBoxFilterBx stageCheckboxTree">
                                        <FormGroup row id="Filter">
                                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                                <IncludeExcludeGrid
                                                    filterType="project_stages"
                                                    filterData={data}
                                                    allSubscribed={allSubscribed}
                                                    initTags={props.initTags}
                                                    showAll={showAll}
                                                    fetchEntityCounts={props.fetchEntityCounts}
                                                    dataMappings={mapping}
                                                    subFilterType="children"
                                                    currentFilters={props.currentAdvancedSearch[mapping.filter]}
                                                    includeFilters={props.currentAdvancedSearch[mapping.includeKey]}
                                                    excludeFilters={props.currentAdvancedSearch[mapping.excludeKey]}
                                                    variablesHandler={props.variableHandler}
                                                    filterTitle={'Stages'}
                                                    isClearAll={isClearAll}
                                                    showSubscribed={showSubscribed}
                                                    handleSearchText={props.handleSearchText} />

                                            </Col>
                                        </FormGroup>
                                    </div>
                                </>) : ('')
                        })
                    }
                    <FormGroup id="includeOthersFilter" name="includeOthersFilter" row className="mt-0 show row px-0 form-group">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <FormGroup className={"slider-frames"} >
                                <div className={`check-box-button`}>
                                    <Input name="Include withdrawn and abandoned" type="checkbox" id="IncludeOthers" value="IncludeOthers" onChange={e => props.variableHandler("IncludeOthers", e)} checked={toggleActiveExclude} />
                                    <Label check for="IncludeOthers">Include withdrawn and abandoned</Label>
                                </div>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}
export default StagesFilter;
