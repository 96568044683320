import React, { useEffect, useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { checkSearchByIdFilter } from '../../../utils/commonFunctions';
import { TOURFILTERVIEW } from '../../../utils/tour';

const FilterMenu = (props) => {
    const menuItems = [
        { title: "Project search", gotocomponent: "new_filter_spi", id: "f-sperin-link", keyname: "projectInformation" },
        { title: "Updated", id: "f-updated-link", gotocomponent: "new_filter_updated", keyname: "updated" },
        { title: "Project location", id: "f-pl-link", gotocomponent: "new_filter_pl", keyname: "location" },
        { title: "Project Regions & Counties", id: "f-prc-link", gotocomponent: "new_filter_prcs", keyname: "regions" },
        { title: "Categories", id: "f-categories-link", gotocomponent: "new_filter_category", keyname: "multiple" },
        { title: "Development type", id: "f-dt-link", gotocomponent: "new_filter_devtype", keyname: "multiple" },
        { title: "Value", id: "f-value-link", gotocomponent: "new_filter_value", keyname: "multiple" },
        { title: "Frameworks", id: "f-frameworks-link", gotocomponent: "new_filter_framework", keyname: "projectFramework" },
        { title: "Project Timeline", id: "f-pt-link", gotocomponent: "new_filter_pt", keyname: "multiple" },
        { title: "Stages", id: "f-stages-link", gotocomponent: "new_filter_stages", keyname: "multiple" },
        { title: "Build phase", id: "f-bh-link", gotocomponent: "new_filter_bp", keyname: "buildPhase" },
        { title: "Include completed projects", id: "show-cp", gotocomponent: "new_filter_scp", keyname: "" },
        { title: "Product specifications", id: "f-materials-link", gotocomponent: "new_filter_material", keyname: "brands" },
        { title: "Measures", id: "f-measures-link", gotocomponent: "new_filter_measure", keyname: "multiple" },
        { title: "Roles", id: "f-roles-link", gotocomponent: "new_filter_roles", keyname: "multiple" },
        { title: "Company search", id: "f-sci-link", gotocomponent: "new_filter_sci", keyname: "companyInformation" },
        { title: "Company location", id: "f-cl-link", gotocomponent: "new_filter_cl", keyname: "companyLocation" },
        { title: "Company Regions & Counties", id: "f-crc-link", gotocomponent: "new_filter_crcs", keyname: "companyRegions" },
        { title: "Search for individual contacts", id: "f-spi-link", gotocomponent: "new_filter_personsearch", keyname: "personInformation" }
    ];

    const [menuLists, setMenuLists] = useState(menuItems)
    const [activeId, setActiveId] = useState('');

    const handleClick = (event, componentname, id) => {
        setActiveId('');
        // for(var i = 0; i < event.target.parentElement.parentElement.childElementCount; i++){
        //     if(event.target.parentElement.parentElement.children[i].children[0].className === "currentFilter"){
        //         event.target.parentElement.parentElement.children[i].children[0].className = ""
        //     }
        // }
        if (event.target.className !== "currentFilter active") {
            event.target.className = "currentFilter"
        }

        if (event.target.id === id) {
            setActiveId(event.target.id);
            //event.target.className = "active"
        }
        props.gotoComponent(componentname)
    }

    useEffect(() => {
        let advancedFilterStateValue = props.advancedFilterState;
        setMenuLists([
            {
                title: "Project search",
                gotocomponent: "new_filter_spi",
                id: "f-sperin-link",
                // keyname: advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectInformation.active ||
                //     advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectId.active ||
                //     advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectScheme.active ||
                //     advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectTitle.active
                //     ? "currentFilter active"
                //     : "",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectInformation ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectScheme ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectTitle)) &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectInformation?.active ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectScheme?.active ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectTitle?.active)
                    ? "currentFilter active"
                    : "",
            },
            {
                title: "Updated",
                id: "f-updated-link",
                gotocomponent: "new_filter_updated",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].updated ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].new)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.updated?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.new?.active)
                    ? "currentFilter active" : "",
            },
            {
                title: "Project location",
                id: "f-pl-link",
                gotocomponent: "new_filter_pl",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].location || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectPostcode || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectTown)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.location?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.projectPostcode?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.projectTown?.active)
                    ? "currentFilter active" : "",
            },
            {
                title: "Project Regions & Counties",
                id: "f-prc-link",
                gotocomponent: "new_filter_prcs",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].regions)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.regions?.active)
                    ? "currentFilter active" : "",
            },
            {
                title: "Categories",
                id: "f-categories-link",
                gotocomponent: "new_filter_category",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].includeCategories && advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType].includeCategories?.active) ||
                    advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].excludeCategories && advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.excludeCategories?.active ||
                    advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectUsage && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectUsage && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectUsage.value && Array.isArray(advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectUsage.value) && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectUsage.value.length > 0 ||
                    advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectLandType && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectLandType && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectLandType.value && Array.isArray(advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectLandType.value) && advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].projectLandType.value.length > 0)
                    ? "currentFilter active" : ""
            },
            {
                title: "Development type",
                id: "f-dt-link",
                gotocomponent: "new_filter_devtype",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].includeDevelopmentTypes || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].excludeDevelopmentTypes)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.includeDevelopmentTypes?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.excludeDevelopmentTypes?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Value",
                id: "f-value-link",
                gotocomponent: "new_filter_value",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].value)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.value?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Frameworks",
                id: "f-frameworks-link",
                gotocomponent: "new_filter_framework",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.projectFramework?.active)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.projectFramework?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Project Timeline",
                id: "f-pt-link",
                gotocomponent: "new_filter_pt",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].calendarStartDate ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].calendarEndDate ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].startConstruction ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].endConstruction)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.calendarStartDate?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.calendarEndDate?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.startConstruction?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.endConstruction?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Stages",
                id: "f-stages-link",
                gotocomponent: "new_filter_stages",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].includeStages ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].advanceExcludeStages ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].excludeStages)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.includeStages?.active || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].advanceExcludeStages?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.excludeStages?.active) &&
                    !checkSearchByIdFilter(advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType])
                    ? "currentFilter active" : ""
            },
            {
                title: "Build phase",
                id: "f-bh-link",
                gotocomponent: "new_filter_bp",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].buildPhase)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.buildPhase?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Include completed projects",
                id: "show-cp",
                gotocomponent: "new_filter_scp",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].completedProject)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.completedProject?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Product specifications",
                id: "f-materials-link",
                gotocomponent: "new_filter_material",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].materials ||
                        advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].brands)) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.materials?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.brands?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Measures",
                id: "f-measures-link",
                gotocomponent: "new_filter_measure",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].measure)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.measure?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Roles",
                id: "f-roles-link",
                gotocomponent: "new_filter_roles",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].includeRoles || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].excludeRoles
                       )) && (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.includeRoles?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.excludeRoles?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Company search",
                id: "f-sci-link",
                gotocomponent: "new_filter_sci",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyInformation?.active || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyId?.active || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyName?.active)) 
                    ? "currentFilter active" : ""
            },
            {
                title: "Company location",
                id: "f-cl-link",
                gotocomponent: "new_filter_cl",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyLocation || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyTown || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyPostcode)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.companyLocation?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.companyTown?.active || advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.companyPostcode?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Company Regions & Counties",
                id: "f-crc-link",
                gotocomponent: "new_filter_crcs",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].companyRegions)) &&
                    (advancedFilterStateValue.filtersState?.[advancedFilterStateValue.selectedSearchType]?.companyRegions?.active)
                    ? "currentFilter active" : ""
            },
            {
                title: "Search for individual contacts",
                id: "f-spi-link",
                gotocomponent: "new_filter_personsearch",
                keyname: (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType] &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personInformation || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personName || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personJobTitle)) &&
                    (advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personInformation?.isSearchApplied || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personName?.isSearchApplied || advancedFilterStateValue.filtersState[advancedFilterStateValue.selectedSearchType].personJobTitle?.isSearchApplied)
                    ? "currentFilter active" : ""
            }
        ]);
    }, [props.advancedFilterState]);

    return (
        <div className='filter-menu-section'>
            <ColoredScrollbars className="advanced-filter-panel-scroller">
                <div className="FiltermenuListlinks">
                    <ListGroup  data-intro={TOURFILTERVIEW.selectfilter.intro}     data-position={TOURFILTERVIEW.selectfilter.position} data-class={TOURFILTERVIEW.selectfilter.class}>
                        {menuLists && menuLists.map(item => (
                            <ListGroupItem className='p-0 d-flex border-0'>
                                <Link id={item.id} className={item.id === activeId ? item.keyname + ' currentFilter' : item.keyname} onClick={(e) => handleClick(e, item.gotocomponent, item.id)}>{item.title}</Link>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
            </ColoredScrollbars>
        </div>
    )
}

export default FilterMenu;