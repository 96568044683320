import _ from 'lodash';
import moment from 'moment';
import getAxios from "../../utils/baseApi";
import { handleError } from "../../utils/errorHandler";
import {updateNotificationList,updateBadgeCount,updateCalendarNotificationList,updateUpdatesBadgeCount}from "../../redux/features/notifications/notificationslice"
import { processEvents} from "../../components/details/postprocessing"
import {EVENTS_FIELD_QUERY,EVENTS_COMPANY_FIELD_QUERY} from "../../utils/settings"
var axios = getAxios()

export function getNotifications( id,dispatch) {
    axios.get("users/"+id+"/notifications")
    .then((response) => {
           if(response.status===200){
            dispatch(updateNotificationList(response.data))
           }
        
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export function fetchBadgeCount( id,licenceFlag,dispatch) {
    axios.get("users/"+id+"/badges")
    .then((response) => {
           if(response.status===200){
            dispatch(updateUpdatesBadgeCount(response.data.badge_count))
            if(licenceFlag.isCalendarLicence===true){
                getCalendarNotifications(response.data,dispatch)
            }
            else{
                dispatch(updateBadgeCount(response.data&&response.data.badge_count))
            }
           }
        
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export const getCalendarNotifications =  (badgeData,dispatch) => {
    axios.get(`events?filter_by=date&fields=${EVENTS_FIELD_QUERY},${EVENTS_COMPANY_FIELD_QUERY}`)
        .then(async (response) => {
            if (response.status === 200) {
                if (response.data && response.data.events.length > 0) {
                    let processEventsArr = processEvents(response.data.events)
                    let eventlist = processCalendarNotifications(processEventsArr)
                    eventlist.calenderBadge = response.data.aggregation.event_count
                    dispatch(updateCalendarNotificationList(eventlist))
                }
            }
            else{
                dispatch(updateCalendarNotificationList([]))
            }
            let badgeCount = badgeData.badge_count ? badgeData.badge_count : 0
            dispatch(updateBadgeCount(badgeCount  + (response.data != '' ? response.data.aggregation.event_count : 0)))
        
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export function processCalendarNotifications(events_list) {
    let past_events = []
    let upcoming_events = []
    _.map(events_list, (event) => {
        event.sortDate = event.reminder_on ? Date.parse(event.reminder_on) : event.start_on ?
        Date.parse(event.start_on) : event.end_on ? Date.parse(event.end_on) : ''
    })
    let eventsSortOrder = events_list && [...events_list].sort((a, b) => a.sortDate - b.sortDate)

    _.map(eventsSortOrder, (event) => {
        event.sortDate < new Date().getTime() ? past_events.push(event) : upcoming_events.push(event)
    })
    _.map(past_events, (event) => {
        upcoming_events.push(event)
    })
    return upcoming_events;
}


export function resetBadgeCount( id,licenceFlag,dispatch) {
    axios.put("users/"+id+"/badges")
    .then((response) => {        
            dispatch(updateUpdatesBadgeCount(0))
         if(licenceFlag.isCalendarLicence===true){
             getCalendarNotifications([],dispatch)
         }
         else{
            dispatch(updateBadgeCount(0))
         }
        
     
     }).catch(function (error) {
         handleError(error, dispatch)
     })
  }


