import React from 'react';
import AlertMessage from '../../components/alerts';
import Details from '../../components/details';
import TopHeader from '../../components/header';
import LeftPanel from "../../components/leftpanel";

const DetailsPage = () => {
	
	return (
		
	<div id="container">
		<TopHeader />
		<div className="sidebar-mini">
		<LeftPanel />
		<div id="message-container">
			<AlertMessage />
		</div>
		<Details />
		</div>
	</div>
	);
}

export default DetailsPage;