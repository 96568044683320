import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'

import { displayCurrency, formatUrlWithProtocol, getCompanyAddress, getFormatedDate, isValidText } from '../../../utils'
import { TOURCOMPANYLISTVIEW } from '../../../utils/tour'
import { favourite, fetchJointProjects, fetchPeopleWorkingOnThisProjects } from '../apiclient';
import CommonPlaceholder from '../framework/commonPlaceholder';
import Icon from '../../common/js/icon';
import TagShareNoteCount from './tagShareNoteCount';
import { updateCompanyIdForJointProjects, updateGroupCompaniesDetails, updateIsJointProjectViewed, updateJointProjects, updateListSelection, updateProjectDataForRoleId, updateWorksWithCompanies } from '../redux/reducer';
import { fetchSortValue } from '../../../utils/commonFunctions';
import { convertMilesToYards } from '../../search/utils';

const CompanySearchList = (props) => {
  const location = useLocation();
  const project = useSelector((state) => state.project)
  const userData = useSelector((state) => state.user)
  const search = useSelector((state) => state.search)
  const filter = useSelector((state) => state.filter)
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const getRoles = (rolesValue) => {
    var i,
      data = [],
      roles = [];
    if (rolesValue != undefined) {
      if (Array.isArray(rolesValue)) {
        for (i = 0; i < rolesValue.length; i++) {
          if (rolesValue[i].key === "phone" || rolesValue[i].key === "website") {
            if (roles.length > 1) {
              roles[roles.length - 1] = (<span className={"rs-address"} key={`roles${i - 1}`}>{rolesValue[i - 1].value}</span>)
            } else {
              roles.push(<span className={"rs-address"} key={`roles-0`}></span>)
              roles.push(<span key={`roles${i}`}>{rolesValue[i].value}</span>)
            }
          }
          roles.push(<span key={`roles${i}`}>{rolesValue[i].value}</span>)
        }

      } else {
        data = rolesValue.split(',')
        for (i = 0; i < data.length; i++) {
          roles.push(<span key={`roles${i}`}>{data[i]}</span>)
        }
      }
      return roles
    }
  }

  const fetchPeopleList = () => {
    let peopleWorkingOnProjectPayload = {
      companyId: props.entity.objectId,
      projectId: project && project.detailsPage.objectId,
      companyName: props.entity.company_name,
      email: props.entity.company_email,
      telephone: props.entity.company_phone,
      companyPhoneTps: props.entity.company_phone_tps
    }
    fetchPeopleWorkingOnThisProjects(peopleWorkingOnProjectPayload, dispatch)
  }

  const handleFavourite = (event) => {
    event.preventDefault()
    event.stopPropagation()
    let payload = {
      favourite: props.entity.favourite ? false : true,
      objectId: props.entity.objectId,
      objectType: "company",
      objectName: props.entity.company_name,
      prevNextList: props.prevNextList,
      ptno: props.ptno,
      columnOneRole: props.columnOneRole,
      columnTwoRole: props.columnTwoRole,
      sort: fetchSortValue(props?.sort?.toLowerCase(), "company"),
      companyId: location.pathname.split('/')[4]
    }
    favourite(payload, dispatch, project, userData)
  }

  const handleDetails = (event, projectId, companyId) => {
    event.preventDefault();
    let routingState = {
      prevNextList: props.prevNextList,
      showMoreResult: true,
      index: props.prevNextList === 'groupedCompanies' ? 0 : props.index,
      lastRelease: getFormatedDate(props.entity?.max_project_last_published, 'DD-MMM-YYYY'),
      dist: convertMilesToYards(props.entity?.company_distance),
      previousState: location.state,
      project_id: location.pathname.split('/')[3] ? location.pathname.split('/')[3] : '',
      company_id: location.pathname.split('/')[4] ? location.pathname.split('/')[4] : '',
      list: props.prevNextList === 'projectRoles' ? search.projectHierarchicalRoles
        : props.prevNextList === 'relatedCompaniesJointProjects' ? project.companyRelatedCompanies
          : props.prevNextList === 'worksWithCompanies' ? project.worksWithCompanies?.companies
            : props.prevNextList === 'groupedCompanies' ? [props.entity] : [],
      tab: props.prevNextList === 'projectRoles' ? '2'
        : props.prevNextList === 'relatedCompaniesJointProjects' ? '3'
          : props.prevNextList === 'worksWithCompanies' ? '4'
            : props.prevNextList === 'groupedCompanies' ? '7' : null,
      activeTab: props.prevNextList === 'projectRoles' ? '1'
        : props.prevNextList === 'relatedCompaniesJointProjects' ? '2'
          : props.prevNextList === 'worksWithCompanies' ? '2'
            : props.prevNextList === 'groupedCompanies' ? '2' : null
    }
    if (props.prevNextList === "worksWithCompanies" || props.prevNextList === "groupedCompanies") {
      navigate(`/app/company/0/${companyId}`, { state: routingState })
    }
    else if (props.prevNextList === "projectRoles" || props.prevNextList === "relatedCompaniesJointProjects") {
      navigate(`/app/company/${projectId}/${companyId}`, { state: routingState });
    }
  }

  const handleJoinProjects = (e, view_company_id) => {
    e.preventDefault();
    e.stopPropagation();
    let count = 0;
    dispatch(updateProjectDataForRoleId({ projects: [] }))
    dispatch(updateWorksWithCompanies({ companies: [] }))
    dispatch(updateGroupCompaniesDetails([]));
    dispatch(updateListSelection({ count, entityType: 'relatedCompaniesJointProjects' }))
    dispatch(updateJointProjects([]))
    fetchJointProjects(location.pathname.split('/')[4], view_company_id, props.columnOneRole, props.columnTwoRole, dispatch, project)
    dispatch(updateIsJointProjectViewed(true))
    dispatch(updateCompanyIdForJointProjects(view_company_id))
  }

  return (
    <>
      {props && props.entity ?
        <div className={`company-container ps-0 ${!props.entity.read ? 'read-block' : 0} `}>
          {
            props.entity.company_linkedin ?
              (
                <a href={formatUrlWithProtocol(props.entity.company_linkedin)} className={`linkedin-icon linkedin-list-view`} target="_blank"></a>
              ) :
              ('')
          }
          {props.index == 0 && location.pathname == "/app/list" ?
            (<div className={`hori-placeholder-tour`} data-intro={!filter.isFilterActive ? TOURCOMPANYLISTVIEW.listview.intro : null} data-position={TOURCOMPANYLISTVIEW.listview.position} data-class={TOURCOMPANYLISTVIEW.listview.class}>&nbsp;</div>) :
            ('')}
          <div className="linkWrapper">
            <Link className="result-block pl-0 pr-0" aria-expanded="false"
              // aria-controls={props.index} data-toggle="collapse" data-parent="#searchlistAccordion"
              onClick={(event) => { handleDetails(event, project && project.detailsPage.objectId, props && props.entity.objectId) }}
              to={props && props.prevNextList === "worksWithCompanies" || props.prevNextList === "groupedCompanies" ? `/app/company/0/${props && props.entity && props.entity.objectId}` : `/app/company/${project && project.detailsPage.objectId}/${props && props.entity && props.entity.objectId}`}
            >
              {
                <div className="listviewtable-block">
                  <div className="list-table">
                    <div className="list-tablerow d-flex">
                      <div className={`list-col list-name`}>
                        <div className="listNameIcon">
                          <div className={`result-row-icon-placeholder ${project.isPrint ? 'hide' : ''}`}>
                            <span className={`mb-0 icon-company ${project.isPrint ? 'hide' : ''}`}><Icon size={35} icon="icon-gen-company-list" className="me-2 company-icon" /></span>
                            <div className={`result-row-button-block ml-2 ${project.isPrint ? 'hide' : ''}`}>
                              <div className="right-block">
                                <div className="">
                                  <button onClick={handleFavourite} className='user-ctrl-btn label-favourite-icon'>
                                    {
                                      (<>
                                        {
                                          props.entity && props.entity.favourite ?
                                            <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                            <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                        }
                                      </>
                                      )
                                    }
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="result-row-overview">
                            <h5 className="result-title-name mb-1">{props.entity.company_name}</h5>
                            {
                              <Row className={`com-address-print ${project.isPrint ? '' : 'hide'}`}>
                                {isValidText(props.entity.company_address1)
                                  || isValidText(props.entity.company_address2)
                                  || isValidText(props.entity.company_address3)
                                  || isValidText(props.entity.company_address4)
                                  || isValidText(props.entity.company_postcode) ?
                                  (<Col
                                    sm={`${project.isPrint ? '12' : '12'}`}
                                    md={`${project.isPrint ? '12' : '12'}`}
                                    lg={`${isValidText(props.entity.company_phone) ? '12' : '12'}`}
                                    className="col comp-address">
                                    <div className="field-value mb-1">
                                      {getCompanyAddress(props.entity)}
                                    </div>
                                  </Col>) : ('')}
                              </Row>
                            }
                            {
                              <div className={`com-address-print ${project.isPrint ? 'd-flex flex-wrap' : 'hide'}`}>
                                {isValidText(props.entity.company_phone)
                                  || isValidText(props.entity.company_phone) ?
                                  (<div className="comp-data">
                                    {props.entity.company_phone}
                                    {props.entity.company_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}
                                  </div>) : ('')}
                                {isValidText(props.entity.company_website) ?
                                  (<div className="comp-data">
                                    {props.entity.company_website}
                                  </div>) : ('')}
                                {isValidText(props.entity.company_email) ?
                                  (<div className="comp-data">
                                    {props.entity.company_email}
                                  </div>) : ('')}
                              </div>
                            }
                            <div className={`list-col list-client`}>
                              {
                                !props.entity.role_desc ?
                                  (<div className={`sub-resultdetails-content ${project.isPrint || props.prevNextList === 'worksWithCompanies' ? 'hide' : ''}`}>
                                    {props.entity.company_address1 || props.entity.company_address2 || props.entity.company_address3 || props.entity.company_address4 || props.entity.company_postcode ?
                                      (
                                        <span className="rs-address">{props.entity.company_address1 && props.entity.company_address1 + ", "} {props.entity.company_address2 && props.entity.company_address2 + ", "} {props.entity.company_address3 && props.entity.company_address3 + ", "} {props.entity.company_address4 && props.entity.company_address4 + ", "} {props.entity.company_postcode && props.entity.company_postcode}</span>
                                      ) : ("")
                                    }
                                    {isValidText(props.entity.company_phone)
                                      || isValidText(props.entity.company_phone) ?
                                      (<span className="rs-address">
                                        {props.entity.company_phone}
                                        {props.entity.company_phone_tps ? (<span className="tps-badge">TPS</span>) : ""}
                                      </span>) : ('')}
                                    {isValidText(props.entity.company_website) ?
                                      (<span className="rs-address">
                                        {props.entity.company_website}
                                      </span>) : ('')}
                                    {isValidText(props.entity.company_email) ?
                                      (<span className="rs-phone">
                                        {props.entity.company_email}
                                      </span>) : ('')}
                                  </div>) : isValidText(props.entity.role_desc) ?
                                    (
                                      <div className={`sub-resultdetails-content ${project.isPrint ? 'hide' : ''}`}>
                                        <div className="rs-roles">
                                          <strong>Roles:</strong>
                                          {getRoles(props.entity && props.entity.role_desc && props.entity.role_desc.trim().replace(/,*$/, ''))}
                                        </div>
                                      </div>
                                    ) : ""
                              }
                            </div>
                            {props.prevNextList === 'worksWithCompanies' ?
                              <div className={`list-col list-client`}>
                                {
                                  props.entity.company_roles_display ?
                                    isValidText(props.entity.company_roles_display) ?
                                      (
                                        <div className={`sub-resultdetails-content ${project.isPrint ? 'hide' : ''}`}>
                                          <div className="rs-roles">
                                            <strong>Roles:</strong>
                                            {props?.entity?.company_roles_display?.join(', ')}
                                          </div>
                                        </div>
                                      ) : "" : ""
                                }
                              </div> : ("")}
                            <div className={`list-col list-value w-10 hide`}>
                              {
                                props.entity.company_phone ?
                                  (
                                    <span className="rs-address rs-phone">
                                      {props.entity.company_phone}
                                      {
                                        props.entity.company_phone_tps ?
                                          (
                                            <span className="tps-badge">TPS</span>
                                          )
                                          : ("")
                                      }
                                    </span>
                                  )
                                  : ("-")
                              }
                            </div>
                            <div className={`list-col list-value w-20 hide`}>
                              {
                                props.entity.company_website ? (<span className="rs-phone">
                                  {props.entity.company_website}
                                </span>) : ("-")
                              }
                            </div>
                            <div className={`list-col list-contract ${props.entityFlag == true && project.isPrint ? 'hide' : ''}`}>
                              {isValidText(props.entity.company_address3) ? (<span className={`src-location-placeholder ${project.isPrint ? 'hide' : ''}`}>{props.entity.company_address3}</span>) : ""}
                              {props.entity.role_added ?
                                (<span className={`src-date ${project.isPrint ? 'hide' : ''}`}><span className="src-latest role-added">{getFormatedDate(props.entity.role_added, 'DD-MMM-YYYY')}</span></span>) : ("")}
                              {
                                props.sort === "Projects" ? (<span className={`src-date ${project.isPrint ? 'hide' : ''}`}><span className="src-count">{props.entity.count_project_id}</span></span>) :
                                  props.sort === "Value" ? (<span className={`src-date ${project.isPrint ? 'hide' : ''}`}><span className="src-value">{displayCurrency(props.entity.sum_project_value)}</span></span>) :
                                    !(props.entity.role_added) ? (<span className={`src-date ${project.isPrint ? 'hide' : ''}`}><span className="src-latest">{getFormatedDate(props.entity.max_project_last_published, 'DD-MMM-YYYY')}</span></span>) : ("")
                              }
                              {
                                props.prevNextList == "relatedCompaniesJointProjects"
                                  ? (<Button onClick={(e) => handleJoinProjects(e, props.entity.company_id)} className="view-joint-projects">View joint projects
                                    ({props.entity.count_project_id})
                                  </Button>)
                                  : ('')
                              }
                            </div>
                            {
                              props.entity && props.entity.tags_count != 0 ||
                                props.entity && props.entity.shares_count != 0 ||
                                props.entity && props.entity.mail ||
                                props.entity && props.entity.notes_count != 0 ?
                                (<TagShareNoteCount entity={props.entity} />) : ("")
                            }
                          </div>
                        </div>
                      </div>
                      <div className={`team-contact-list ${project.isPrint ? 'col' : 'hide'}`}>
                        {project.isPrint ? (
                          props.entity && props.entity.people && props.entity.people.length !== 0 && props.entity.people.map(function (contact, index) {
                            return (
                              <Row key={index}>
                                <div className="user-contact-details" key={index}>
                                  <div className="ucd-name-designation">{contact?.person_first_name} {contact?.person_last_name} {contact?.person_job_title ? "(" + contact?.person_job_title + ")" : ''}</div>
                                  <div className="ucd-email-phone">
                                    {contact?.person_email != "-" ?
                                      (<span className="ucd-email">{contact?.person_email}</span>) : (<span className="ucd-email">{props?.entity?.email}</span>)}
                                    {contact?.person_phone != "-" ?
                                      (<span className="ucd-phone">{contact?.person_phone} {contact?.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ("")}</span>) :
                                      (<span className="ucd-phone">{props.entity.company_phone}
                                        {props.entity.company_phone_tps ? (<span className="tps-badge">TPS</span>) : ("")}</span>)}
                                    {contact.mobile ? (<span className="ucd-mobile">{contact.mobile}</span>) : ''}
                                  </div>
                                </div>
                              </Row>
                            )
                          }))
                          : ('')
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Link>
          </div>
          {
            props.prevNextList === 'projectRoles' ?
              props.entity && props.entity.people !== undefined ?
                <Button className="related-Company-btn" onClick={fetchPeopleList}></Button> :
                ('') : ''}
        </div>
        :
        <CommonPlaceholder />
      }
    </>
  )
}

export default CompanySearchList