import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notificationList: [],
  badgeCount:0,
  calenderList:[],
  notificationBadgeCount:0
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotificationList(state, action) {
        state.notificationList = action.payload
        return state
    }, 
    updateBadgeCount(state,action){
      state.badgeCount = action.payload
      return state
    },
    updateCalendarNotificationList(state,action){
      state.calenderList=action.payload
      return state;
    },
    updateUpdatesBadgeCount(state,action){
      state.notificationBadgeCount=action.payload
      return state;
    }
  }
})

export const { updateNotificationList,updateBadgeCount ,updateCalendarNotificationList,updateUpdatesBadgeCount} = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer