import React, { useEffect, useState } from 'react'
import { Col, ListGroup, Row, TabContent, TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';

import CommonPlaceholder from './commonPlaceholder';
import SearchListRow from '../list/searchListRow';
import { getProjectFramework } from '../../../utils';

const Framework = (props) => {
    const project = useSelector((state) => state.project)
    const [projectFramework, setProjectFramework] = useState({})
    const [activeTab, setActiveTab] = useState(props.tab)

    const handleShowMore = () => { }

    useEffect(() => {
        if ((project && project.primaryFramework) || (project && project.associateFramework)) {
            setProjectFramework(getProjectFramework(project && project.primaryFramework, project && project.associateFramework))
        } else {
            setProjectFramework({})
        }
    }, [project])

    const renderDetails = () => {
        return (
            <>
                <div className="empty-view"></div>
                {
                    <div className="box-view framework-container">
                        <Row className="m-0 pt-3">
                            <TabContent activeTab={"framework"} className="p-0">
                                <TabPane activeTab={"framework"} tabId={"framework"}>
                                    <div className="list-wrapper-other-pro">
                                        <div className="company-groups-listing">
                                            {projectFramework && Object.keys(projectFramework).length > 0 ? (Object.keys(projectFramework).map(function (role, index) {
                                                return (
                                                    <div className="project-role-wrapper" key={`role${index}`}>
                                                        <span className="project-role-name">{role}</span>
                                                        <Row className="result-list-container content-panel" >
                                                            <Col xs="12" lg="12" xl="12" id="searchlistAccordion" className="p-0">
                                                                <ListGroup>
                                                                    {projectFramework && projectFramework[role].map(function (entity, index) {
                                                                        return (
                                                                            <SearchListRow
                                                                                prevNextList='groupedCompanies'
                                                                                roleGroupName={role}
                                                                                roleNo={entity.roleNo}
                                                                                roleName={entity.roleName}
                                                                                index={index}
                                                                                favourite={project.detailsPage.favourite}
                                                                                entity={entity}
                                                                                key={`resultrow${index}`}
                                                                                activeTab={activeTab}
                                                                                details={project && project.projectDetails}
                                                                                sort={'Latest'}
                                                                            />
                                                                        )
                                                                    })
                                                                    }
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })) :
                                                <CommonPlaceholder />
                                            }
                                            {project && project.isAssociateFrameworkShowMore ?
                                                (
                                                    <div className={`show d-flex justify-content-center pt-3 pb-3 showmore-result`}>
                                                        <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                    </div>
                                                ) : (<span></span>)
                                            }
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Row>
                    </div >
                }
            </>
        )
    }

    const renderLoader = () => {
        return (
            <div className="m-3">
                <strong>
                    This project is not part of a framework.
                </strong>
            </div>
        );
    }

    return (
        <>
            {
                project && project.isProjectFrameWork ? (
                    <>
                        {renderDetails()}
                    </>
                ) : (
                    <>
                        {renderLoader()}
                    </>
                )
            }
        </>
    )

}

export default Framework;