import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { updateAdditionalQuery, updateSearchTextObject } from '../../redux/reducer';
import { createCommonQuery } from '../../../../utils/commonFunctions';
import { updateDisplayKey, updateFilterText } from '../redux/reducer';

const CompanyTextSearch = (props) => {

    const searchPersist = useSelector((state) => state.searchPersist);
    const settings = useSelector((state) => state.settings)
    const search = useSelector((state) => state.search)
    const filter = useSelector((state) => state.filter)
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState('Fuzzy search');
    const [radioDisabled, setRadioDisabled] = useState(true);
    const [keyname, setKeyname] = useState('companyInformationEditor');
    const [currentRadio, setCurrentRadio] = useState('companyName');
    const [textRetain, setTextRetain] = useState('');
    const [projectTextBlock, setProjectTextBlock] = useState(false);
    const [textUpdate, setTextUpdate] = useState(false);
    let activeFilterValue = searchPersist.appliedContext;
    const [searchTextObj, setSearchTextObj] = useState(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });
    const [searchData, setSearchData] = useState({
        isButtonClicked: false
    })

    const createPayload = (filterType, keynameForRadioButton) => {
        let query = {};
        let query1 = {};
        let result = {};
        if (selectedValue === "Fuzzy search") {
            if (searchTextObj && searchTextObj[filterType].length > 0) {
                query = createCommonQuery({
                    key: "company_text",
                    operator: "=",
                    value: textRetain
                })
            }
        }
        else if (selectedValue === "Exact match") {
            if (textRetain != "") {
                if (keynameForRadioButton === "companyName") {
                    query = createCommonQuery({
                        key: "company_name",
                        operator: "=",
                        value: textRetain
                    })
                }
                else if (keynameForRadioButton === "companyId") {
                    query = createCommonQuery({
                        key: "company_id",
                        operator: "=",
                        value: textRetain
                    })
                }
            }
        }
        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    const handleSelectedValue = (value) => {
        let updatedRadioDisabled = true;
        let updatedKeyname = 'companyInformationEditor';
        if (value === 'Exact match') {
            updatedRadioDisabled = false;
            updatedKeyname = currentRadio;
        }
        props.variableHandler([(updatedKeyname === 'companyInformationEditor' ? 'companyInformation' : updatedKeyname), "companyArray"], textRetain)
        setSelectedValue(value);
        setRadioDisabled(updatedRadioDisabled);
        setKeyname(updatedKeyname);
        setDropdownOpen(false);
        setProjectTextBlock(false);

    };

    const dropDownToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleRadioClick = (event, keyname) => {
        setCurrentRadio(keyname)
        let payload = createPayload(activeFilterValue, keyname);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        props.variableHandler([(keyname === 'companyInformationEditor' ? 'companyInformation' : keyname), "companyArray"], textRetain)
    }

    const handleTextChange = (event, field) => {
        setTextRetain(event.target.value);
        setProjectTextBlock(true);
        let textObj = activeFilterValue === 'project' ? { project: event.target.value } : activeFilterValue === 'company' ? { company: event.target.value } : { people: event.target.value }
        setSearchTextObj(_.cloneDeep(textObj))
        dispatch(updateSearchTextObject(textObj))
    };

    const onEnterHandler = (event) => {
        if (event.keyCode == 13) {
            let payload1 = {
                value: textRetain,
                dataObj: event.target.value,
                active: textRetain !== '' ? true : false,
                keyname: "companyInformation"
            }
            dispatch(updateFilterText(payload1))
            let payload = createPayload(activeFilterValue, currentRadio);
            dispatch(updateAdditionalQuery(payload))
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true
            }))
            props.variableHandler([(keyname === 'companyInformationEditor' ? 'companyInformation' : keyname), "companyArray"], textRetain)
        }
    }

    const onBlurHandler = (event, clearField, parentModel) => {
        if (textRetain) {
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true
            }))
            props.variableHandler([(keyname === 'companyInformationEditor' ? 'companyInformation' : keyname), "companyArray"], textRetain)
        } else {
            // clearField(parentModel, event);
            setProjectTextBlock(false);
        }
    };

    const handleClearField = () => {
        searchTextObj[activeFilterValue] = []
        setSearchTextObj(_.cloneDeep(searchTextObj))
        dispatch(updateSearchTextObject(searchTextObj))
        setTextRetain('');
        setProjectTextBlock(false);
        let payload = createPayload(activeFilterValue, currentRadio);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        dispatch(updateDisplayKey({ key: 'companyInformation', text: '' }))
        dispatch(updateDisplayKey({ key: currentRadio, text: '' }))
    };

    useEffect(() => {
        let company = []
        if (filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value.length > 0) {
            company.push(filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value)
            setSearchTextObj(activeFilterValue === 'project' ? { project: company } : activeFilterValue === 'company' ? { company: company } : { people: company })
            setTextRetain(Array.isArray(filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value) ? filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value[0] : filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value)
        }
        if (filter.filtersState[activeFilterValue]?.companyName?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.companyName?.value
            setSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setKeyname('companyName')
            setCurrentRadio('companyName')
            setTextRetain(Array.isArray(filter.filtersState[searchPersist.appliedContext]?.companyName?.value) ? filter.filtersState[searchPersist.appliedContext]?.companyName?.value[0] : filter.filtersState[searchPersist.appliedContext]?.companyName?.value)

        }
        if (filter.filtersState[activeFilterValue]?.companyId?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.companyId?.value
            setSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setCurrentRadio('companyId')
            setKeyname('companyId')
            setTextRetain(Array.isArray(filter.filtersState[searchPersist.appliedContext]?.companyId?.value) ? filter.filtersState[searchPersist.appliedContext]?.companyId?.value[0] : filter.filtersState[searchPersist.appliedContext]?.companyId?.value)

        }
        if (filter.filtersState[activeFilterValue]?.companyInformation?.value.length === 0 && filter.filtersState[activeFilterValue]?.companyId?.value === '' && filter.filtersState[activeFilterValue]?.companyName?.value === '') {
            setTextRetain("")
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
        }
    }, [filter.filtersState])

    useEffect(() => {
        if (props?.isClearAll) {
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
            setTextRetain("")
        }
    }, [props?.isClearAll])

    return (
        <div id="new_filter_sci">
            <div className='advanced-filter-grid'>
                <div className="filterHeading">
                    <h5 className="mb-3">Company search</h5>
                </div>
                <div className={`filterBlock ${projectTextBlock === true ? "activeBlock" : ""}`}>
                    <div className="textcontent">
                        <Row className="align-items-center">
                            <Col xs={12} sm={8} md={8} lg={11}>Enter a word or phrase to search all fields, or select exact search and a specific field for more precision.</Col>
                        </Row>
                    </div>
                    <div className="sliderBlock">
                        <FormGroup id={currentRadio} name={currentRadio} className="m-0 px-0">
                            <Row>
                                <Col xs={12} sm={8} md={8} lg={6} xl={8} className="advanced-filter-field-container project-info-filter">
                                    <div className={`radio-btn-cntr`}>
                                        <input
                                            className="form-control"
                                            onChange={(event) => handleTextChange(event, 'field')}
                                            onBlur={onBlurHandler}
                                            onKeyDown={onEnterHandler}
                                            type="text"
                                            name={currentRadio}
                                            id={currentRadio}
                                            placeholder="for e.g. Barbour ABI"
                                            value={searchTextObj[activeFilterValue]}
                                            data-id={currentRadio === 'companyInformationEditor' ? 'companyInformation' : currentRadio}
                                            readOnly={textUpdate}
                                        />
                                        <button
                                            className={`clear-button-advanced-filter clear-textfield ${textRetain && textRetain.length > 0 ? 'display-block' : 'display-none'}`}
                                            name="clearButton"
                                            data-id={currentRadio === 'companyInformationEditor' ? 'companyInformation' : currentRadio}
                                            onClick={handleClearField}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={8} md={8} lg={6} xl={4} className="advanced-filter-field-container pl-0">
                                    <div className="input-container">
                                        <div className="select-input-type">
                                            <ButtonDropdown isOpen={dropdownOpen} toggle={() => dropDownToggle()} className="w-100">
                                                <DropdownToggle caret className="w-100 text-left btn-project-search text-truncate">
                                                    {selectedValue}
                                                </DropdownToggle>
                                                <DropdownMenu className="w-100">
                                                    <DropdownItem onClick={() => handleSelectedValue('Fuzzy search')}>Fuzzy search</DropdownItem>
                                                    <DropdownItem onClick={() => handleSelectedValue('Exact match')}>Exact match</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </FormGroup>

                        <div className={`radio-btn-cntr ${radioDisabled ? "hide" : ""}`}>
                            <Row>
                                <div className="radio-buttons">
                                    <FormGroup check>
                                        <Input
                                            name="search-company-info"
                                            type="radio"
                                            id="companyName"
                                            checked={currentRadio === 'companyName' ? true : false}
                                            onChange={(e) => handleRadioClick(e, "companyName")}
                                        />
                                        <Label check for="companyName">
                                            Company name
                                        </Label>
                                        <div className="check"></div>
                                    </FormGroup>
                                </div>
                                <div className="radio-buttons mr-4">
                                    <FormGroup check>
                                        <Input
                                            name="search-company-info"
                                            type="radio"
                                            id="companyId"
                                            checked={currentRadio === 'companyId' ? true : false}
                                            onChange={(e) => handleRadioClick(e, "companyId")}
                                        />
                                        <Label check for="companyId">
                                            Company ID
                                        </Label>
                                        <div className="check"></div>
                                    </FormGroup>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default CompanyTextSearch;