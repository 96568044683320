// ListPanel and MapPanel
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { JASK_PREVNEXT_LIST, MYLEADS_PREVNEXT_LIST } from '../../utils/settings'
import CompanyHierarchy from '../search/hierarchyList/companyHierarchy'
import ContactHierarchy from '../search/hierarchyList/contactHierarchy'
import ProjectHierarchy from '../search/hierarchyList/projectHierarchy'
import CompanyHierarchyJA from '../justask/search/hierarchyList/companyHierarchy'
import ProjectHierarchyJA from '../justask/search/hierarchyList/projectHierarchy'
import SearchList from '../search/searchlist'
import JustaskSearchList from '../justask/search/searchlist'
import SearchListResult from '../search/searchListResult'
import SearchListResultJA from '../justask/search/searchListResult'
import MapPanel from './mappanel'
import JustAskMapPanel from './justaskmappanel'

const MapView = (props) => {
  let ifMyleadsExist = MYLEADS_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
  let ifJAskListExist = JASK_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
  const justask = useSelector((state) => state.justAsk)
  const justAskPersist = useSelector((state) => state.justAskPersist)
  const searchPersist = useSelector((state) => state.searchPersist)
  const search = useSelector((state) => state.search)
  const project = useSelector((state) => state.project)
  const myleads = useSelector((state) => state.myleads)
  const filter = useSelector((state) => state.filter)
  const justAskFilter = useSelector((state) => state.justAskFilter)
  const [listMapToggle] = useState(ifMyleadsExist ? myleads.isListView : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" ? justAskPersist?.isListView : searchPersist.isListView)
  const [hoverItem, setHoverItem] = useState({ hoverList: [], hoverInfoStatus: false, markerActiveFlag: {} })

  const getSearchListResult = () => {
    if (props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList") {
      return (<SearchListResultJA
        currentOffset={0}
        mode={props.mode}
        prevNextList={props.prevNextList}
        type={project.isProjectSelected ? 'project' : justAskPersist.appliedContext}
        isListMapButtonFlag={listMapToggle}
        activeTab={props.activeTab}
        myHoverList={myHoverList}
      />)
    }
    else {
      return (<SearchListResult
        currentOffset={0}
        mode={props.mode}
        prevNextList={props.prevNextList}
        type={ifMyleadsExist ? searchPersist.leadsAppliedContext[props?.activeTab] : project.isProjectSelected ? 'project' : searchPersist.appliedContext}
        isListMapButtonFlag={listMapToggle}
        activeTab={props.activeTab}
        myHoverList={myHoverList}
      />)
    }
  }

  const myHoverList = (hoverItem) => {
    let datamap = hoverItem ? [hoverItem.longitude, hoverItem.latitude, hoverItem.objectId, hoverItem.objectType] : []
    setHoverItem({ hoverList: datamap, hoverInfoStatus: true, markerActiveFlag: hoverItem })
  }

  return (
    <>
      {
        props.entityFlag ?
          !ifJAskListExist && (search.showSelectedCompanies || search.showSelectedProjects) || (search?.listOfSelectedObjectIds?.length > 0) ?
            <SearchList entityFlag={props.entityFlag} prevNextList={project.isProjectSelected ? "groupedCompanyProjects" : 'selectedSearchList'} myHoverList={myHoverList} /> :
            ifJAskListExist && (justask.showSelectedCompanies || justask.showSelectedProjects) || (justask.listOfSelectedObjectIds?.length > 0) ?
              <JustaskSearchList entityFlag={props.entityFlag} prevNextList={project.isProjectSelected ? "jAskGroupedCompanyProjects" : 'selectedjAskSearchList'} myHoverList={myHoverList} /> :
              (
                ((myleads.showProjectHierarchy && searchPersist.leadsAppliedContext[props?.activeTab] === 'project') || (search.showProjectHierarchy && searchPersist.appliedContext === 'project')) && (search?.fetchRolesForProjects?.roleGroups?.length > 0) ?
                  (<div className={`list-panel`}><div className={`result-wrapper ${!searchPersist.isListView || !myleads.isListView ? 'gridSearchView' : ''}`}><ProjectHierarchy prevNextList={props.prevNextList} listMapToggle={listMapToggle} type={ifMyleadsExist ? searchPersist.leadsAppliedContext[props?.activeTab] : searchPersist.appliedContext} myHoverList={myHoverList} /></div></div>) :
                  (!ifJAskListExist && (search.showCompanyHierarchy && searchPersist.appliedContext === "company")) && (search?.projectsForCompanyId?.length > 0) ?
                    (<div className={`list-panel`}><div className={`result-wrapper ${!searchPersist.isListView || !myleads.isListView ? 'gridSearchView' : ''}`}><CompanyHierarchy prevNextList="companyHierarchicalSearchList" type={searchPersist.appliedContext} myHoverList={myHoverList} /></div></div>) :
                    !ifJAskListExist && search.showCompanyHierarchy && (search?.peopleProjectsList?.length > 0) ?
                      (<div className={`list-panel`}><div className={`result-wrapper ${!searchPersist.isListView || !myleads.isListView ? 'gridSearchView' : ''}`}><ContactHierarchy listMapToggle={listMapToggle} type={searchPersist.appliedContext} prevNextList="contactHierarchicalSearchList"
                      /> </div></div>) :
                      (ifJAskListExist && (justask.showProjectHierarchy && justAskPersist.appliedContext === 'project')) && (justask?.fetchRolesForProjects?.roleGroups?.length > 0) ?
                        (<div className={`list-panel`}><div className={`result-wrapper ${!justAskPersist.isListView || !myleads.isListView ? 'gridSearchView' : ''}`}><ProjectHierarchyJA prevNextList={props.prevNextList} listMapToggle={listMapToggle} type={justAskPersist.appliedContext} myHoverList={myHoverList} /></div></div>) :
                        (ifJAskListExist && (justask.showCompanyHierarchy && justAskPersist.appliedContext === "company")) && (justask?.projectsForCompanyId?.length > 0) ?
                          (<div className={`list-panel`}><div className={`result-wrapper ${!justAskPersist.isListView || !myleads.isListView ? 'gridSearchView' : ''}`}><CompanyHierarchyJA prevNextList="companyHierarchicalSearchListJA" type={searchPersist.appliedContext} myHoverList={myHoverList} /></div></div>) :
                          getSearchListResult()
              ) : getSearchListResult()
      }
      {ifJAskListExist ?
        <div className={`map-panel show-half ${(justAskPersist.appliedContext === 'project' && justAskFilter.filtersState[justAskPersist.appliedContext]?.projectInformation?.active
          || justAskPersist.appliedContext === 'company' && justAskFilter.filtersState[justAskPersist.appliedContext]?.companyInformation?.active) && justAskFilter.searchMode === 'basic' ? 'with-project-info' : ''}`}>
          <div className="JSmap-view">
            <JustAskMapPanel
              entityFlag={props.entityFlag}
              prevNextList={props.prevNextList}
              hoverItem={hoverItem}
              activeTab={props.activeTab}></JustAskMapPanel>
          </div>
        </div> :
        <div className={`map-panel show-half ${(searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active
          || searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active) && filter.searchMode === 'basic' ? 'with-project-info' : ''}`}>
          <div className="map-view">
            <MapPanel
              entityFlag={props.entityFlag}
              prevNextList={props.prevNextList}
              hoverItem={hoverItem}
              activeTab={props.activeTab}></MapPanel>
          </div>
        </div>}
    </>

  )

}

export default MapView
