import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import { fetchSortValue } from '../../../utils/commonFunctions'
import { updateLeadsSortSearch, updateSortSearch } from '../../search/redux/searchPeristReducer';
import { updateSearchListSelection } from '../../search/redux/reducer';
import { fetchArchieveList, fetchFavouritesResults, fetchHistoryResults, fetchSharesResults, fetchTagDetailsById, fetchUpdatesResults } from '../apiclient';
import { updateMyLeadOffsetLimitValue } from '../reducer';


const MyLeadsSortOrder = (props) => {
  const searchPersist = useSelector((state) => state.searchPersist)
  const [sortObj, setSortObj] = useState(searchPersist?.leadsSort)
  const [sortOrder, setSortOrder] = useState({
    dropDownOpen: false,
  })
  const search = useSelector((state) => state.search)
  const myleads = useSelector((state) => state.myleads)
  const dispatch = useDispatch()
  const [buttonClicked, setButtonClicked] = useState(false)
  let activeSort = search.showSelectedCompanies || (search.showSelectedProjects && searchPersist.leadsAppliedContext[props.activeTab] !== "people") ? 'Latest' : sortObj?.[props?.activeTab][searchPersist.leadsAppliedContext[props.activeTab]]?.label
  const [isSortQuerySelected, setIsSortQuerySelected] = useState(false)
  const settings = useSelector((state) => state.settings)

  useEffect(() => {
    if (buttonClicked) {
      if (props?.activeTab === 'updates') {
        fetchUpdatesResults(searchPersist.leadsAppliedContext.updates, myleads, dispatch, settings);
      } else if (props?.activeTab === 'tags' && myleads.tagId !== 0) {
        fetchTagDetailsById(myleads.tagId, myleads, searchPersist, dispatch, '', settings)
      } else if (props?.activeTab === 'favourite') {
        fetchFavouritesResults(searchPersist, myleads, dispatch, settings);
      } else if (props?.activeTab === 'history') {
        fetchHistoryResults(searchPersist, myleads, dispatch, settings);
      }
      else if (props?.activeTab === 'shares') {
        fetchSharesResults(searchPersist, myleads, dispatch, settings);
      }
      else if (props?.activeTab === 'archive') {
        fetchArchieveList(searchPersist, myleads, dispatch)

      }
      setButtonClicked(!buttonClicked)
    }
  }, [buttonClicked]);

  useEffect(() => {
    setSortObj(_.cloneDeep(searchPersist?.leadsSort))
  }, [searchPersist?.leadsSort])


  useEffect(() => {
    if (isSortQuerySelected) {
      if (props?.activeTab === 'tags' && myleads.tagId !== 0) {
        fetchTagDetailsById(myleads.tagId, myleads, searchPersist, dispatch, "", settings)
      } else if (props?.activeTab === 'favourite') {
        fetchFavouritesResults(searchPersist, myleads, dispatch, settings);
      } else if (props?.activeTab === 'history') {
        fetchHistoryResults(searchPersist, myleads, dispatch, settings);
      }
      else if (props?.activeTab === 'shares') {
        fetchSharesResults(searchPersist, myleads, dispatch, settings);
      }
      setIsSortQuerySelected(false)
    }
  }, [isSortQuerySelected])

  useEffect(() => {
    setSortObj(_.cloneDeep(sortObj))
  }, [props?.activeTab])

  const handleCommonSort = (sortOrder) => {
    let newSortOrder = fetchSortValue(sortOrder, searchPersist.leadsAppliedContext[props?.activeTab])
    Object.assign(sortObj?.[props?.activeTab][searchPersist.leadsAppliedContext[props?.activeTab]], { label: sortOrder === "projectCount" ? "Projects" : sortOrder === "projectValue" ? "Value" : sortOrder, value: newSortOrder });
    setSortObj(_.cloneDeep(sortObj))
    dispatch(updateLeadsSortSearch(_.cloneDeep(sortObj)));
    if (search.selectedSelectorControlValue !== 0) {
      dispatch(updateSearchListSelection({ count: 0, list: props.activeTab === 'updates' ? 'myLeadsUpdates' : '' }))
    }
    if (search.countOfSelectedObjectIds !== 0) {
      dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
    }
    dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));

    setButtonClicked(true)
  }


  return (
    <>
      <Dropdown disabled={search.showProjectHierarchy || search.showCompanyHierarchy || search.showDetailPeople} isOpen={sortOrder.dropDownOpen} toggle={() => setSortOrder(prev => ({ ...prev, dropDownOpen: !sortOrder.dropDownOpen }))}>
        {
          (search.showSelectedProjects && searchPersist.appliedContext === 'people' || searchPersist.appliedContext === 'people' && search.showDetailPeople || search.showProjectHierarchy || search.showCompanyHierarchy) ?
            <DropdownToggle caret className="sort-by-factors" disabled="true">
              {activeSort && activeSort.charAt(0).toUpperCase() + activeSort.slice(1)}
            </DropdownToggle>
            :
            <DropdownToggle caret className="sort-by-factors" >
              {activeSort && activeSort.charAt(0).toUpperCase() + activeSort.slice(1)}
            </DropdownToggle>
        }

        <DropdownMenu>


          {(props.activeTab === 'history') ?
            (
              <DropdownItem onClick={() => handleCommonSort("last viewed")}>
                Last viewed
              </DropdownItem>
            ) : ('')
          }
          <DropdownItem divider />

          <DropdownItem onClick={() => handleCommonSort("latest")} >
            Latest
          </DropdownItem>

          <DropdownItem divider />

          {(props.activeTab !== 'archive') ?
            (<DropdownItem
              onClick={() => handleCommonSort("nearest")}>
              Nearest
            </DropdownItem>) : ('')
          }

          <DropdownItem divider />
          <DropdownItem onClick={() => handleCommonSort("value")}>
            Value
          </DropdownItem>

          <DropdownItem divider />

          {((searchPersist && searchPersist.leadsAppliedContext[props?.activeTab] === 'company') && (props.activeTab !== 'archive')) ?
            (
              <div>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("projectCount")}>
                  Projects
                </DropdownItem>
                <DropdownItem divider />
              </div>
            ) : ('')
          }

          {((searchPersist.leadsAppliedContext[props?.activeTab] === 'project') || (props.activeTab === 'archive')) ?
            (<div>
              <DropdownItem onClick={() => handleCommonSort("start date")}>
                Start Date
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => handleCommonSort("end date")}>
                End Date
              </DropdownItem>
              <DropdownItem divider />
            </div>) : ('')
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default MyLeadsSortOrder