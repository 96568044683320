import React from 'react'
import CheckboxList from './checklist'

const NotificationItem = (props) => {
    return (
        <div className="col-md-12 col-sm-12 pt-4">
            <div className="pb-2">{props.header ? <label className="m-0">{props.header === 'Project updates - select the updates you wish to receive' ? (<span><span className="font-bold">Project updates</span> - select the updates you wish to receive</span>) : props.header === 'Company updates - select to see when a company has been added to a project' ? (<span><span className="font-bold">Company updates</span> - select to see when a company has been added to a project</span>) : (<span className="font-bold">{props.header}</span>)}</label> : ('')}
            </div>
            <ul className="mb-0">
                <li className="border-top border-bottom">
                    {props.options ?
                        props.options.map((option) => <CheckboxList
                            roleData={props.roleData}
                            item={option} itemChangeHandler={props.itemChangeHandler} />
                        ) : ('')}
                </li>
            </ul>
        </div>
    )
}

export default NotificationItem