import React from "react";
import { TabContent } from "reactstrap";
import UserAdminTab from "./tabs/index";
import './userAdmin.scss';

const UserAdmin = (props) => {
  return (
    <div id="container">
      <UserAdminTab />
    </div>
  );
};
export default UserAdmin;