import React, { useEffect, useState } from 'react'
import { Col, Label, Row, TabContent, TabPane } from 'reactstrap'
import TruncateMarkup from 'react-truncate-markup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { displayCurrency, displayDuration, displayExternalArea, displayInternalArea, displayProjectLinear, displayProjectUnitOrFlatsOrParking, displayProjectVolume, getFormatedDate, getProjectAddress, isValidText } from '../../../../utils'
import MaterialDetailsTab from './materialsdetailtab';
import CategroiesDetailTab from './categoriesdetailtab';
import SubHeaderTabs from './subheadertabs';
import RoleCompanyTabs from '../../roles&companytabs';
import { convertDateFormat } from '../../../../utils/commonFunctions';

const DetailsTab = (props) => {
    const [shouldTruncate, setShouldTruncate] = useState(true)
    const [sizeCount, setSizeCount] = useState(0)
    const [activetab, setActiveTab] = useState('materials')
    const dispatch = useDispatch();
    const project = useSelector((state) => state.project)

    const toggle = (tab) => {
        setActiveTab(tab)
    }

    const readMoreEllipsis = (
        <span>
            ...{' '}
            <span onClick={() => toggleTruncate()} className="toggle-truncate">
                show more
            </span>
        </span>
    );

    const toggleTruncate = () => {
        setShouldTruncate(!shouldTruncate)
    }

    const getSizeCount = () => {
        let count = 0
        if (isValidText(project && project.detailsPage && project.detailsPage.project_floor_area)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_plan_area)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_site_area)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_volume)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_linear)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_structures)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_units)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_storeys_above)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_houses)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_hotel_beds)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_hospital_beds)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_student_beds)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_carehome_beds)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_sheltered_beds)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_net_retail_floor)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_gross_retail_floor)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_storeys_below)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_parking)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_one_bed)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_two_bed)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_three_bed)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_four_bed)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_five_bed)) {
            count++
        } else if (isValidText(project && project.detailsPage && project.detailsPage.project_flats)) {
            count++
        }
        setSizeCount(count)
    }

    useEffect(() => {
        getSizeCount()
    }, [project && project.detailsPage])

    return (
        <>
            {!props.isPrint ?
                <div className="mt-0 ml-0 mr-0 project-information">
                    <Row>
                        <Col lg="8" md="6" sm="12">
                            <Row>
                                {isValidText(project && project.detailsPage && project.detailsPage.project_scheme) ?
                                    (<Col sm="12" md="12" lg='12' className="col proj-scheme">
                                        <div className="field-name">Scheme</div>
                                        <div className="field-value">
                                            {shouldTruncate ?
                                                (<TruncateMarkup lines={3} ellipsis={readMoreEllipsis}>
                                                    <div>
                                                        {project && project.detailsPage && project.detailsPage.project_scheme}
                                                    </div>
                                                </TruncateMarkup>) : (
                                                    <div>
                                                        {project && project.detailsPage && project.detailsPage.project_scheme}
                                                        <span onClick={() => toggleTruncate()} className="toggle-truncate">show less</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Col>)
                                    :
                                    ('')
                                }
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg='7' className="col p-0 border-bottom-0">
                                    {isValidText(project && project.detailsPage && project.detailsPage.project_planning_stage_display) || isValidText(project && project.detailsPage && project.detailsPage.project_status) ||
                                        isValidText(project && project.detailsPage && project.detailsPage.project_plans_submitted) || isValidText(project && project.detailsPage && project.detailsPage.project_geocode_display)
                                        ?
                                        (<Col sm="12" md="12" lg="12" className={"col proj-planningstages " + (isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage) || isValidText(project && project.detailsPage && project.detailsPage.project_contract_type) ||
                                            isValidText(project && project.detailsPage && project.detailsPage.project_funding) ? '' : 'no-contract-stage')}>
                                            <div className="field-name">Planning stage</div>
                                            <div className="field-value nested-values">
                                                <div className="list-group-wrapper">
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_planning_stage_display) ? (<div className="list-block">
                                                        <Label>Stage: </Label> <span>{project && project.detailsPage && project.detailsPage.project_planning_stage_display}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_status) ? (<div className="list-block blockLevelStatus">
                                                        <Label>Status: </Label> <span>{project && project.detailsPage && project.detailsPage.project_status}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_plans_submitted) ? (<div className="list-block">
                                                        <Label>Application date: </Label> <span>{getFormatedDate(project && project.detailsPage && project.detailsPage.project_plans_submitted, 'DD-MMM-YYYY')}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_planning_portal_submitted) ? (<div className="list-block">
                                                        <Label>Planning Portal submission: </Label> <span>{getFormatedDate(project && project.detailsPage && project.detailsPage.project_planning_portal_submitted, 'DD-MMM-YYYY')}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_geocode_display) ? (<div className="list-block">
                                                        <Label>Council: </Label> <span>{project && project.detailsPage && project.detailsPage.project_geocode_display}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_planning_url) ? (<div className="list-block">
                                                        <Label>View planning application: </Label><span><Link to={project && project.detailsPage && project.detailsPage.project_planning_url} target='__blank'>{project && project.detailsPage && project.detailsPage.project_planning_reference}</Link></span>
                                                    </div>) : ('')}
                                                </div>
                                            </div>
                                        </Col>) : (' ')
                                    }
                                    {isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage_display) || isValidText(project && project.detailsPage && project.detailsPage.project_contract_type_display) || isValidText(project && project.detailsPage && project.detailsPage.project_funding_display)
                                        ? (<Col sm="12" md="12" lg="12" className="col proj-contractstages">
                                            <div className="field-name">Contract stage</div>
                                            <div className="field-value nested-values">
                                                <div className="list-group-wrapper">
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage_display) ? (<div className="list-block">
                                                        <Label>Stage: </Label><span>{project && project.detailsPage && project.detailsPage.project_contract_stage_display}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_contract_type_display) ? (<div className="list-block">
                                                        <Label>Contract Type: </Label><span>{project && project.detailsPage && project.detailsPage.project_contract_type_display}</span>
                                                    </div>) : ('')}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_funding_display) ? (<div className="list-block">
                                                        <Label>Funding: </Label><span>{project && project.detailsPage && project.detailsPage.project_funding_display}</span>
                                                    </div>) : ('')}
                                                </div>
                                            </div>
                                        </Col>) : ('')}
                                    {isValidText(project?.detailsPage?.project_land_type_display) ? (
                                        <Col sm="12" md="12" lg='12' className="col proj-landType border-bottom-0">
                                            <div className="proj-dev-type">
                                                <div className="field-name">Land type</div>
                                                <div className="field-value">{isValidText(project?.detailsPage?.project_land_type_display) ? project?.detailsPage?.project_land_type_display : ""}</div>
                                            </div>
                                        </Col>
                                    ) : ('')}
                                </Col>
                                <Col sm="12" md="12" lg="5" className="p-0 col proj-size-block border-bottom-0">
                                    <Col sm="12" md="12" lg={`${isValidText(project && project.detailsPage && project.detailsPage.project_start_display) || isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) || isValidText(project && project.detailsPage && project.detailsPage.project_duration) ? '12' : '12'}`} className="col proj-dev-category p-0">
                                        {isValidText(project && project.detailsPage && project.detailsPage.project_development_types_display) ? (<div className="proj-dev-type">
                                            <div className="field-name">Development type</div>
                                            <div className="field-value">{project && project.detailsPage && project.detailsPage.project_development_types_display.length > 0 ? project && project.detailsPage.project_development_types_display.join(" / ") : ""}</div>
                                        </div>) : ('')}
                                    </Col>
                                    <Col sm="12" md="12" lg={`${isValidText(project && project.detailsPage && project.detailsPage.project_start_display) || isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) || isValidText(project && project.detailsPage && project.detailsPage.project_duration) ? '12' : '12'}`} className="col proj-dev-category p-0">
                                        {isValidText(project && project.detailsPage && project.detailsPage.project_framework_description) ? (<div className="proj-dev-type">
                                            <div className="field-name">Framework</div>
                                            <div className="field-value">{project && project.detailsPage && project.detailsPage.project_framework_description}</div>
                                        </div>) : ('')}
                                    </Col>
                                    {project && project.detailsPage && project.detailsPage.project_usage_display ? (
                                        <Col sm="12" md="12" lg="12" className="col proj-dev-category p-0">
                                            <div className="proj-dev-type">
                                                <div className="field-name">Usage type</div>
                                                <div className="field-value">{project && project.detailsPage && project.detailsPage.project_usage_display.length > 0 ? project && project.detailsPage.project_usage_display.join(" / ") : ""}</div>
                                            </div>
                                        </Col>
                                    ) : ('')}
                                    {isValidText(project && project.detailsPage && project.detailsPage.project_start_display) || isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) || isValidText(project && project.detailsPage && project.detailsPage.project_duration) || isValidText(project && project.detailsPage && project.detailsPage.project_tender_close_display) ?
                                        (<Col sm="12" md="12" lg={`${isValidText(project && project.detailsPage && project.detailsPage.project_categories_display) ? '12' : '12'}`} className="col proj-timing">
                                            <div className="field-name">Timing <span className='projected-time'>{project && project.detailsPage && project.detailsPage.project_timing_projected ? "(Estimated)" : "(Confirmed)"}</span></div>
                                            <div className="field-value nested-values">
                                                <div className="list-group-wrapper">
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_tender_close_display) ? (<div className="list-block"><Label>Tender close date:</Label> <span>{project && project.detailsPage && project.detailsPage.project_tender_close_display}</span></div>) : ("")}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_start_display) ? (<div className="list-block"><Label>Start:</Label> <span>{convertDateFormat(project && project.detailsPage && project.detailsPage.project_start_display)}</span></div>) : ("")}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) ? (<div className="list-block"><Label>Finish:</Label> <span>{convertDateFormat(project && project.detailsPage && project.detailsPage.project_finish_display)}</span></div>) : ("")}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_duration) ? (<div className="list-block"><Label>Duration:</Label> <span>{displayDuration(project && project.detailsPage && project.detailsPage.project_duration)}</span></div>) : ("")}
                                                    {isValidText(project && project.detailsPage && project.detailsPage.project_length) ? (<div className="field-value"><Label>Length:</Label> <span></span> {project && project.detailsPage && project.detailsPage.project_length}</div>) : ("")}
                                                </div>
                                            </div>
                                        </Col>) : ('')}
                                    {sizeCount > 0
                                        ? (
                                            <Col sm="12" md="12" lg="12" className={sizeCount > 12 ? "col proj-sizes morethan12" : "col proj-sizes lessthan12"}>
                                                <div className="field-name">Size</div>
                                                <div className="field-value nested-values">
                                                    <div className="list-group-wrapper">
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_floor_area) ? (<div className="list-block">
                                                            <Label>Floor area: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_floor_area)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_plan_area) ? (<div className="list-block">
                                                            <Label>Plan area: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_plan_area)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_site_area) ? (<div className="list-block">
                                                            <Label>Site area: </Label> <span>{displayExternalArea(project && project.detailsPage && project.detailsPage.project_site_area)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_volume) ? (<div className="list-block">
                                                            <Label>Volume: </Label> <span>{displayProjectVolume(project && project.detailsPage && project.detailsPage.project_volume)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_linear) ? (<div className="list-block">
                                                            <Label>Length: </Label> <span>{displayProjectLinear(project && project.detailsPage && project.detailsPage.project_linear)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_structures) ? (<div className="list-block">
                                                            <Label>Structures: </Label> <span>{project && project.detailsPage && project.detailsPage.project_structures}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_units) ? (<div className="list-block">
                                                            <Label>Number of units: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_units)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_storeys_above) ? (<div className="list-block">
                                                            <Label>Storeys: </Label> <span>{project && project.detailsPage && project.detailsPage.project_storeys_above}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_houses) ? (<div className="list-block">
                                                            <Label>Houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_houses}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_hotel_beds) ? (<div className="list-block">
                                                            <Label>Hotel beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_hotel_beds}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_hospital_beds) ? (<div className="list-block">
                                                            <Label>Hospital beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_hospital_beds}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_student_beds) ? (<div className="list-block">
                                                            <Label>Student beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_student_beds}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_carehome_beds) ? (<div className="list-block">
                                                            <Label>Care homes: </Label> <span>{project && project.detailsPage && project.detailsPage.project_carehome_beds}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_sheltered_beds) ? (<div className="list-block">
                                                            <Label>Sheltered beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_sheltered_beds}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_net_retail_floor) ? (<div className="list-block">
                                                            <Label>Net retail floor space: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_net_retail_floor)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_gross_retail_floor) ? (<div className="list-block">
                                                            <Label>Gross retail floor space: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_gross_retail_floor)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_storeys_below) ? (<div className="list-block">
                                                            <Label>Storeys below ground: </Label> <span>{project && project.detailsPage && project.detailsPage.project_storeys_below}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_parking) ? (<div className="list-block">
                                                            <Label>Parking spaces: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_parking)}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_one_bed) ? (<div className="list-block">
                                                            <Label>One bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_one_bed}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_two_bed) ? (<div className="list-block">
                                                            <Label>Two bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_two_bed}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_three_bed) ? (<div className="list-block">
                                                            <Label>Three bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_three_bed}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_four_bed) ? (<div className="list-block">
                                                            <Label>Four bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_four_bed}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_five_bed) ? (<div className="list-block">
                                                            <Label>Five+ bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_five_bed}</span>
                                                        </div>) : ('')}
                                                        {isValidText(project && project.detailsPage && project.detailsPage.project_flats) ? (<div className="list-block">
                                                            <Label>Flats: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_flats)}</span>
                                                        </div>) : ('')}
                                                    </div>
                                                </div>
                                            </Col>) : ('')}
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="4" md="6" sm="12" className="mat-cat-container">
                            <SubHeaderTabs toggle={toggle} activeTab={activetab} />
                            <TabContent activeTab={activetab} className="mat-cat-panel">
                                <TabPane tabId="materials">
                                    {activetab === "materials" ? (<MaterialDetailsTab materials={project && project.detailsPage && project.detailsPage.project_materials_text ? project && project.detailsPage.project_materials_text : ""} isPrint={false} />) : ('')}
                                </TabPane>
                                <TabPane tabId="categories">
                                    {activetab === "categories" ? (<CategroiesDetailTab />) : ('')}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </div> :
                <>
                    <Row>
                        {isValidText(project && project.detailsPage && project.detailsPage.project_site1) || isValidText(project && project.detailsPage && project.detailsPage.project_site2) || isValidText(project && project.detailsPage && project.detailsPage.project_site3) || isValidText(project && project.detailsPage && project.detailsPage.project_site4) || isValidText(project && project.detailsPage && project.detailsPage.project_postcode) ?
                            (<Col sm="12" md="12" lg="12" className="proj-address">
                                <div className="field-name">Address</div>
                                <div className="field-value">{getProjectAddress(project && project.detailsPage && project.detailsPage)}</div>
                            </Col>) : ('')}
                    </Row>
                    <Row>
                        {isValidText(project && project.detailsPage && project.detailsPage.project_scheme) ? (<Col sm="12" md="12" lg={`${isValidText(project && project.detailsPage && project.detailsPage.project_start_display) || isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) || isValidText(project && project.detailsPage && project.detailsPage.project_duration) ? '8' : '12'}`} className="proj-scheme">
                            <div className="field-name">Scheme</div>
                            <div className="field-value">{project && project.detailsPage && project.detailsPage.project_scheme}</div>
                        </Col>) : ('')}
                    </Row>
                    <Row>
                        {isValidText(project && project.detailsPage && project.detailsPage.project_value) ? (<Col className=
                            "proj-type proj-value" lg="6" md="6" sm="6">
                            <div className="field-name">Value</div>
                            <div className="field-value">{project && project.detailsPage && displayCurrency(project.detailsPage.project_value, project.detailsPage.project_value_estimated)}</div>
                        </Col>) : ('')}
                    </Row>
                    <Row>
                        {isValidText(project && project.detailsPage && project.detailsPage.project_timing_projected) || isValidText(project && project.detailsPage && project.detailsPage.project_start_display) || isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) || isValidText(project && project.detailsPage && project.detailsPage.project_duration) ?
                            (<Col sm="6" md="6" lg="6" className="proj-timing">
                                <div className="field-name">Timing <span className='projected-time'>{project && project.detailsPage && project.detailsPage.project_timing_projected ? "(Estimated)" : "(Confirmed)"}</span></div>
                                {isValidText(project && project.detailsPage && project.detailsPage.project_tender_close_display) ? (<div className="field-value">Tender close date: {project && project.detailsPage && project.detailsPage.project_tender_close_display}</div>) : ("")}
                                {isValidText(project && project.detailsPage && project.detailsPage.project_start_display) ? (<div className="field-value">Start: {convertDateFormat(project && project.detailsPage && project.detailsPage.project_start_display)}</div>) : ("")}
                                {isValidText(project && project.detailsPage && project.detailsPage.project_finish_display) ? (<div className="field-value">Finish: {convertDateFormat(project && project.detailsPage && project.detailsPage.project_finish_display)}</div>) : ("")}
                                {isValidText(project && project.detailsPage && project.detailsPage.project_duration) ? (<div className="field-value">Duration: {displayDuration(project && project.detailsPage && project.detailsPage.project_duration)}</div>) : ("")}
                            </Col>) : ('')}
                        {sizeCount > 0
                            ? (<Col sm="6" md="6" lg="6" className="p-0 col proj-size-block">
                                <Col sm="12" md="12" lg="12" className={sizeCount > 12 ? "proj-sizes morethan12" : "proj-sizes lessthan12"}>
                                    <div className="field-name">Size</div>
                                    <div className="field-value nested-values">
                                        <div className="list-group-wrapper">
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_floor_area) ? (<div className="list-block">
                                                <Label>Floor area: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_floor_area)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_plan_area) ? (<div className="list-block">
                                                <Label>Plan area: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_plan_area)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_site_area) ? (<div className="list-block">
                                                <Label>Site area: </Label> <span>{displayExternalArea(project && project.detailsPage && project.detailsPage.project_site_area)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_volume) ? (<div className="list-block">
                                                <Label>Volume: </Label> <span>{displayProjectVolume(project && project.detailsPage && project.detailsPage.project_volume)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_linear) ? (<div className="list-block">
                                                <Label>Length: </Label> <span>{displayProjectLinear(project && project.detailsPage && project.detailsPage.project_linear)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_structures) ? (<div className="list-block">
                                                <Label>Structures: </Label> <span>{project && project.detailsPage && project.detailsPage.project_structures}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_units) ? (<div className="list-block">
                                                <Label>Number of units: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_units)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_storeys_above) ? (<div className="list-block">
                                                <Label>Storeys: </Label> <span>{project && project.detailsPage && project.detailsPage.project_storeys_above}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_houses) ? (<div className="list-block">
                                                <Label>Houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_houses}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_hotel_beds) ? (<div className="list-block">
                                                <Label>Hotel beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_hotel_beds}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_hospital_beds) ? (<div className="list-block">
                                                <Label>Hospital beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_hospital_beds}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_student_beds) ? (<div className="list-block">
                                                <Label>Student beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_student_beds}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_carehome_beds) ? (<div className="list-block">
                                                <Label>Care homes: </Label> <span>{project && project.detailsPage && project.detailsPage.project_carehome_beds}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_sheltered_beds) ? (<div className="list-block">
                                                <Label>Sheltered beds: </Label> <span>{project && project.detailsPage && project.detailsPage.project_sheltered_beds}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_net_retail_floor) ? (<div className="list-block">
                                                <Label>Net retail floor space: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_net_retail_floor)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_gross_retail_floor) ? (<div className="list-block">
                                                <Label>Gross retail floor space: </Label> <span>{displayInternalArea(project && project.detailsPage && project.detailsPage.project_gross_retail_floor)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_storeys_below) ? (<div className="list-block">
                                                <Label>Storeys below ground: </Label> <span>{project && project.detailsPage && project.detailsPage.project_storeys_below}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_parking) ? (<div className="list-block">
                                                <Label>Parking spaces: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_parking)}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_one_bed) ? (<div className="list-block">
                                                <Label>One bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_one_bed}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_two_bed) ? (<div className="list-block">
                                                <Label>Two bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_two_bed}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_three_bed) ? (<div className="list-block">
                                                <Label>Three bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_three_bed}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_four_bed) ? (<div className="list-block">
                                                <Label>Four bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_four_bed}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_five_bed) ? (<div className="list-block">
                                                <Label>Five+ bed houses: </Label> <span>{project && project.detailsPage && project.detailsPage.project_five_bed}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_flats) ? (<div className="list-block">
                                                <Label>Flats / apartments: </Label> <span>{displayProjectUnitOrFlatsOrParking(project && project.detailsPage && project.detailsPage.project_flats)}</span>
                                            </div>) : ('')}
                                        </div>
                                    </div>
                                </Col>
                            </Col>) : ('')}
                    </Row>
                    <Row>
                        {isValidText(project && project.detailsPage && project.detailsPage.project_categories_display) ? (<Col sm="6" md="6" lg="6" className="proj-category">
                            <div className="field-name">Categories</div>
                            <div className="field-value">{project?.detailsPage?.project_categories_text ? project?.detailsPage?.project_categories_text.replaceAll('~', ',') : ""}</div>
                        </Col>) : ('')}

                        {isValidText(project && project.detailsPage && project.detailsPage.project_development_types_display) ? (<Col sm="6" md="6" lg="6" className="ps-0">
                            <div className="field-name">Development type</div>
                            <div className="field-value">{project && project.detailsPage && project.detailsPage.project_development_types_display.length > 0 ? project && project.detailsPage.project_development_types_display.join("/") : ""}</div>
                        </Col>) : ('')}
                    </Row>
                    <Row>
                        <Col sm="6" md="6" lg="6">
                            {isValidText(project && project.detailsPage && project.detailsPage.project_planning_stage_display) || isValidText(project && project.detailsPage && project.detailsPage.project_status) ||
                                isValidText(project && project.detailsPage && project.detailsPage.project_plans_submitted) || isValidText(project && project.detailsPage && project.detailsPage.project_geocode_display)
                                ?
                                (<Col sm="12" md="12" lg="12" className={"col proj-planningstages " + (isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage) || isValidText(project && project.detailsPage && project.detailsPage.project_contract_type) ||
                                    isValidText(project && project.detailsPage && project.detailsPage.project_funding) ? '' : 'no-contract-stage')}>
                                    <div className="field-name">Planning stage</div>
                                    <div className="field-value nested-values">
                                        <div className="list-group-wrapper">
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_planning_stage_display) ? (<div className="list-block">
                                                <Label>Stage: </Label> <span>{project && project.detailsPage && project.detailsPage.project_planning_stage_display}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_status) ? (<div className="list-block blockLevelStatus">
                                                <Label>Status: </Label> <span>{project && project.detailsPage && project.detailsPage.project_status}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_plans_submitted) ? (<div className="list-block">
                                                <Label>Application date: </Label> <span>{getFormatedDate(project && project.detailsPage && project.detailsPage.project_plans_submitted, 'DD-MMM-YYYY')}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_geocode_display) ? (<div className="list-block">
                                                <Label>Council: </Label> <span>{project && project.detailsPage && project.detailsPage.project_geocode_display}</span>
                                            </div>) : ('')}
                                        </div>
                                    </div>
                                </Col>) : (' ')
                            }
                            {isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage_display) || isValidText(project && project.detailsPage && project.detailsPage.project_contract_type_display) || isValidText(project && project.detailsPage && project.detailsPage.project_funding_display)
                                ? (<Col sm="12" md="12" lg="12" className="col proj-contractstages">
                                    <div className="field-name">Contract stage</div>
                                    <div className="field-value nested-values">
                                        <div className="list-group-wrapper">
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_contract_stage_display) ? (<div className="list-block">
                                                <Label>Stage: </Label><span>{project && project.detailsPage && project.detailsPage.project_contract_stage_display}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_contract_type_display) ? (<div className="list-block">
                                                <Label>Contract Type: </Label><span>{project && project.detailsPage && project.detailsPage.project_contract_type_display}</span>
                                            </div>) : ('')}
                                            {isValidText(project && project.detailsPage && project.detailsPage.project_funding_display) ? (<div className="list-block">
                                                <Label>Funding: </Label><span>{project && project.detailsPage && project.detailsPage.project_funding_display}</span>
                                            </div>) : ('')}
                                        </div>
                                    </div>
                                </Col>) : ('')}
                        </Col>

                        <Col sm="6" md="6" lg="6" className="p-0">
                            {isValidText(project && project.detailsPage && project.detailsPage.project_planning_url) ?
                                (<Col sm="12" md="12" lg='12' className="proj-url">
                                    <div className="field-name pt-2">View planning application</div>
                                    <div className="field-value"><Link to={project && project.detailsPage && project.detailsPage.project_planning_url} target='__blank'>{project && project.detailsPage && project.detailsPage.project_planning_reference}</Link></div>
                                </Col>) : ('')}

                            {isValidText(project && project.detailsPage && project.detailsPage.project_framework_description) ?
                                (<Col sm="12" md="12" lg='12' className="proj-url">
                                    <div className="proj-dev-type">
                                        <div className="field-name">Framework</div>
                                        <div className="field-value">{project && project.detailsPage && project.detailsPage.project_framework_description}</div>
                                    </div>
                                </Col>) : ('')}
                            {isValidText(project?.detailsPage?.project_land_type_display) ? (
                                <Col sm="12" md="12" lg='12' className="proj-landType">
                                    <div className="proj-dev-type">
                                        <div className="field-name">Land type</div>
                                        <div className="field-value">{isValidText(project?.detailsPage?.project_land_type_display) ? project?.detailsPage?.project_land_type_display : ""}</div>
                                    </div>
                                </Col>
                            ) : ('')}
                        </Col>

                    </Row>
                    <div class="print-contact-people">
                        <Row>
                            <Col className="ms-0 me-0 mb-3 project-tab-panel">
                                <div className="print-contact-people">
                                    <MaterialDetailsTab materials={project && project.detailsPage && project.detailsPage.project_materials_text ? project.detailsPage.project_materials_text : ""} isPrint={true} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ms-0 me-0 mb-3 project-tab-panel">
                                <RoleCompanyTabs sharedType="project" />
                            </Col>
                        </Row>
                    </div>
                </>

            }
        </>
    )
}

export default DetailsTab