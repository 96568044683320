import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, FormGroup, Form } from 'reactstrap'
import { sha256 } from 'js-sha256';
import { useSelector, useDispatch } from 'react-redux'

import { masqueradeUser } from './apiclient';
import { MasqueradeContext } from './context';
import { signInUser } from '../login/apiclient';


const MasqueradeUserList = () => {
    const [ dropdownOpen, setdropdownOpen ] = useState(false)
    const [ selectedUserName, setSelectedUserName ] = useState('User')
    const [ selectedUser, setSelectedUser ] = useState({})
    const [ customerUserList, setCustomerUserList ] = useState({})

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const salesforce = new URLSearchParams(search).get("salesforce");
    const context = useContext(MasqueradeContext);
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch();
    let navigate  = useNavigate();

    useEffect(() => {
        if (Object.keys(context.customerUserList).length === 0) {
            context.dispatchMasqueradeEvent('MASQUERADE_USER', true)
            masqueradeUser(token, salesforce, context, dispatch, navigate);
        }
        if (context.customerUserList && context.customerUserList.user) {
            setCustomerUserList(context.customerUserList.user)
        }
    }, [context])

    const dropDownToggle = () => {
        setdropdownOpen(!dropdownOpen)
    }

    const handleUserNameClick = (record) => {
        setSelectedUser(record)
        setSelectedUserName(record.first_name + " " + record.last_name)
    }

    const onMasqueradeUserClick = () => {
        let user = {
            username: selectedUser.username,
            password: sha256(selectedUser.username),
            rememberMe: false,
            stno: userData.appDetails.sectorNo,
            isMasqueradeUser: true,
            masqueraded_by: customerUserList.cars_user_no
        }
        signInUser(user, navigate, dispatch, {}, {}, null)
    }

	return (
            <Col className="loginForm masquerade col-12 col-md-6 px-5">
                <p>Masquerade</p>
                <Form>
                    <FormGroup className="form-group">
                        <span>Select the user from {customerUserList && customerUserList.hasOwnProperty('customer') && customerUserList.customer.company_name}</span>
                        <Dropdown isOpen={dropdownOpen} toggle={dropDownToggle}>
                            <DropdownToggle caret>
                                {selectedUserName}
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    customerUserList && customerUserList.hasOwnProperty('user_list') && customerUserList.user_list.map(function(item, index) {
                                        return(
                                            <DropdownItem key={index} onClick={() => handleUserNameClick(item)}>{item.first_name + " " + item.last_name +" "+ (item.is_admin ? "(Admin)" : "" )}</DropdownItem>
                                        )
                                    })
                                }
                            </DropdownMenu>
                        </Dropdown >
                    </FormGroup>
                    <FormGroup className="form-group">                                            
                        <button  type="button" onClick={() => onMasqueradeUserClick()} className="btn-block primary-btn">Masquerade</button>
                    </FormGroup>
                    <p className="info">You’ll need an active subscription to use the Barbour ABI app. To learn why we’re the UK’s leading supplier of construction intelligence, <a href="https://www.barbour-abi.com/" target="_blank">visit our site</a></p>
                        <div className="app-btn"><a className="ios" target="_blank" href="https://apps.apple.com/us/app/barbour-abi/id1449550538?ls=1"></a><a className="android" target="_blank" href="https://play.google.com/store/apps/details?id=com.barbour_abi.app"></a></div>
                </Form>
                
            </Col>
	);
}

export default MasqueradeUserList;