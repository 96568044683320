import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormGroup,
    Card,
    Button,
    Label,
    Input,
    Col,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    Row
} from 'reactstrap'
import CreatableSelect from 'react-select';
import IncludeExcludeGrid from './includeExcludeGrid';
import { updateAdditionalQuery, updateBrands } from '../redux/reducer';
import { createCommonQuery } from '../../../utils/commonFunctions';
import { fetchSearchCount } from '../apiclient';
import _ from 'lodash';
import { toggleFuzzyExact, updateFilterText } from './redux/reducer';

const MaterialFilter = (props) => {
    const searchPersist = useSelector((state) => state.searchPersist);
    const search = useSelector((state) => state.search)
    const filter = useSelector((state) => state.filter)
    const [data, setData] = useState(searchPersist?.customerProfile['project_materials']?.values)
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const [projectTextBlock, setProjectTextBlock] = useState(false);
    const [expandAllState, setExpandAllState] = useState(false);
    const [isAllChildSelected, setIsAllChildSelected] = useState(false);
    const [tagInputValue, setTagInputValue] = useState('');
    const [tagValue, setTagValue] = useState([])
    let activeFilterValue = searchPersist.appliedContext;
    const [selectedValue, setSelectedValue] = useState(filter.filtersState?.[activeFilterValue]?.brands?.isFuzzyExact && filter.filtersState?.[activeFilterValue].brands?.isFuzzyExact === 'exact' ? 'Exact match' : 'Fuzzy search');
    const [allSubscribed, setAllSubscribed] = useState(searchPersist?.customerProfile['project_materials']?.all_subscribed)
    const [showSubscribed, setShowSubscribed] = useState(false)
    const [isClearAll, setIsClearAll] = useState(props?.isClearAll)

    const [searchData, setSearchData] = useState({
        isButtonClicked: false
    })
    const [searchTextObj, setSearchTextObj] = useState(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });

    const showAll = () => {
        setShowSubscribed(!showSubscribed)
    }

    useEffect(() => {
        setIsClearAll(props?.isClearAll)
        setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });
        setTagValue([])
    }, [props?.isClearAll])

    const handleChange = (value) => {
        setTagValue(value)
        searchTextObj[activeFilterValue] = [...value];
        setSearchTextObj(_.cloneDeep(searchTextObj))
        let brands = []
        let values = searchTextObj && searchTextObj[activeFilterValue]
        for (var i = 0; i < values?.length; i++) {
            if (values[i].value != undefined || values[i].value != null) {
                brands.push(values[i].value)
            }
        }
        let payload1 = {
            value: brands,
            dataObj: brands,
            active: brands.length > 0 ? true : false,
            keyname: "brands"
        }
        dispatch(updateFilterText(payload1))
    }

    const handleKeyDown = (event) => {
        if (!tagInputValue) return
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (tagInputValue !== '') {
                    searchTextObj[activeFilterValue].push({ label: tagInputValue, value: tagInputValue });
                }
                setTagValue([...tagValue, createOption(tagInputValue)])
                setTagInputValue('')
                setProjectTextBlock(true);
                setSearchTextObj(_.cloneDeep(searchTextObj))
                let brandArray = []
                let values = searchTextObj && searchTextObj[activeFilterValue];
                for (var i = 0; i < values.length; i++) {
                    if (values[i].value != undefined || values[i].value != null) {
                        brandArray.push(values[i].value)
                    }
                }
                let payload1 = {
                    value: brandArray,
                    dataObj: values,
                    active: brandArray && brandArray.length > 0 ? true : false,
                    keyname: "brands"
                }
                dispatch(updateFilterText(payload1))
                dispatch(toggleFuzzyExact(selectedValue === 'Exact match' ? 'exact' : 'fuzzy'))
                event.preventDefault()
                break
            default:
                break
        }
    }

    const createOption = label => ({
        label,
        value: label
    })

    const handleSelectedValue = (value) => {
        setSelectedValue(value);
        setDropdownOpen(false);
        setProjectTextBlock(false);
        dispatch(toggleFuzzyExact(value === 'Exact match' ? 'exact' : 'fuzzy'))
    };

    const dropDownToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    useEffect(() => {
        let brands = []
        if (filter.filtersState[activeFilterValue]?.brands?.value.length > 0) {
            _.map(filter.filtersState[activeFilterValue]?.brands?.value, element => {
                brands.push({ label: element, value: element })
            })
            setSearchTextObj(activeFilterValue === 'project' ? { project: brands } : activeFilterValue === 'company' ? { company: brands } : { people: brands })
        }
        else{
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });
            setTagValue([])
        }
        setSelectedValue(filter.filtersState[activeFilterValue]?.brands.isFuzzyExact === 'exact' ? 'Exact match' : 'Fuzzy search')
    }, [filter.filtersState[activeFilterValue]?.brands])


    return (
        <div id="new_filter_material">
            <div className='advanced-filter-grid'>
                <div className="filterHeading">
                    <h5 className="mb-3">Product specification</h5>
                </div>
                <div className={`filterBlock ${projectTextBlock === true ? "activeBlock" : ""}`}>
                    <div className="textcontent">
                        <Row className="align-items-center">
                            <Col xs={12} sm={8} md={8} lg={11}>Enter a word or phrase to search for product specifications or brands</Col>
                        </Row>
                    </div>
                    <div className="sliderBlock">
                        <FormGroup id="updatedFilter" name="updatedFilter" row className="">
                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                <div className="specification-frames update-location-slider-frames">
                                    <Row>
                                        <Col xs={12} sm={8} md={8} lg={8} className="advanced-filter-field-container project-info-filter product-spec-filter">
                                            <div className="col-md-12 m-0 mb-0 p-0">
                                                <FormGroup className='row position-relative mb-3'>
                                                    <CreatableSelect
                                                        inputValue={tagInputValue}
                                                        menuIsOpen={false}
                                                        onChange={(newValue) => handleChange(newValue)}
                                                        placeholder='Search'
                                                        onKeyDown={handleKeyDown}
                                                        onInputChange={(newValue) => setTagInputValue(newValue)}
                                                        value={searchTextObj[activeFilterValue]}
                                                        isClearable
                                                        clearValue
                                                        isMulti
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={8} md={8} lg={6} xl={4} className="advanced-filter-field-container pl-0">
                                            <div className="input-container">
                                                <div className="select-input-type">
                                                    <ButtonDropdown isOpen={dropdownOpen} toggle={() => dropDownToggle()} className="w-100">
                                                        <DropdownToggle caret className="w-100 text-left btn-project-search text-truncate">
                                                            {selectedValue}
                                                        </DropdownToggle>
                                                        <DropdownMenu className="w-100">
                                                            <DropdownItem onClick={() => handleSelectedValue('Fuzzy search')}>Fuzzy search</DropdownItem>
                                                            <DropdownItem onClick={() => handleSelectedValue('Exact match')}>Exact match</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="advanced-filter-grid">
                <div className="filterHeading">
                    <h5 className="mb-3">Materials</h5>
                </div>
                <div className="filterBlock">
                    <div className="textcontent">
                        Filter by materials specified on projects
                    </div>
                    <div className="checkBoxFilterBx">
                        <FormGroup id="materials" name="category-filter" row className="pb-0 mb-0">
                            <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                                <IncludeExcludeGrid
                                    filter={props.isExclude}
                                    filterData={data}
                                    filterType="project_materials"
                                    filterItemChild={'subParent'}
                                    filterItemSubChild={'subMaterial'}
                                    isMaterialAllSelected={isAllChildSelected}
                                    fetchEntityCounts={props.fetchEntityCounts}
                                    includeFilters={props.currentAdvancedSearch['materials']}
                                    showSubscribed={showSubscribed}
                                    allSubscribed={allSubscribed}
                                    showAll={showAll}
                                    isClearAll={isClearAll}
                                    handleSearchText={props.handleSearchText}
                                />
                            </Col>
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MaterialFilter;
