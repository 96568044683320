import React from 'react';
import Integrations from '../../components/integrations/main';
import LeftPanel from "../../components/leftpanel";
import AlertMessage from "../../components/alerts";
import '../../components/integrations/settings.scss';
import TopHeader from '../../components/header';

const IntegrationsPage = () => {

    return (
        <div id="container">
            <TopHeader/>
            <div className="sidebar-mini">
                <LeftPanel />
                <div id="message-container">
                    <AlertMessage />
                </div>
                <div className="main-content">
                    <div className="settings-page main-container container">
                        <Integrations />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntegrationsPage;