import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';

import { fetchNotificationSettings, fetchRolesLookUp, updateUserSettings } from '../../apiclient';
import NotificationItem from './notificationItem';
import ColoredScrollbars from '../../../common/js/coloredScrollbars';

const Notifications = ({ active }) => {

	const userData = useSelector((state) => state.user.user);
	const [selectedOptions, setSelectedOptions] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])
	const [settingList, setSettingList] = useState([])
	const [rolesOption, setRolesOption] = useState([])
	const settings_container = useSelector((state) => state.settings);
	const [isAnySelected, setIsAnySelected] = useState()
	const [checked, setChecked] = useState({
		switchEmail: settings_container.preferences.email_alert,
		feedCheckbox: settings_container.preferences.feed_alert,
		feedSwitchEmail: settings_container.preferences.feed_email_alert,
		shareCheckbox: settings_container.preferences.share_alert,
		shareSwitchEmail: settings_container.preferences.share_email_alert,
		reminderCheckbox: settings_container.preferences.inactivity_prompts,
		reminderSwitchEmail: settings_container.preferences.inactivity_prompts_email
	})
	const dispatch = useDispatch();

	const isAnyAbiUpdateActive = (notificationSettings) => {
		let isAnyActive = false
		if (notificationSettings && notificationSettings.length > 0) {
			notificationSettings.map(setting => {
				if (setting.options && setting.options.length > 0) {
					setting.options.map(option => {
						if (option.active) isAnyActive = true
						if (option.children && option.children.length > 0) {
							option.children.map(child => {
								if (child.active) isAnyActive = true
							})
						}
					})
				}
			})
		}
		return isAnyActive
	}

	const handleRemoveRoles = (value) => {
		let updatedSelectedRoles = selectedRoles && selectedRoles.filter(e => e.id !== value)
		setSelectedRoles(updatedSelectedRoles)
		let restoreRoleOption = selectedRoles && selectedRoles.filter(e => e.id === value)
		let optionList = rolesOption && rolesOption.concat(restoreRoleOption)
		optionList.sort((a, b) => a.value.localeCompare(b.value))
		setRolesOption(optionList)
	}

	const handleOnChange = (event, isChild) => {
		let list = []
		let nameToCompare = event.target && event.target.dataset && event.target.dataset.parent ? event.target.dataset.parent : ""
		setSettingList(prevList => {
			const updatedList = prevList.map(listItem => {
				const updatedOptions = listItem.options.map(item => {
					if (item.name === nameToCompare) {
						let subList = [];
						let activeChilds = [];
						if (item.value === event.target.value) {
							item = { ...item, active: event.target.checked };
						}
						if (item.children) {
							item.children.forEach(subItem => {
								if (!isChild) {
									if (item.value === event.target.value) {
										subItem = {
											...subItem,
											active: item.active ? true : false
										};
									}
								} else {
									if (subItem.value === event.target.value) {
										subItem = {
											...subItem,
											active: event.target.checked
										};
									}
								}
								activeChilds.push(subItem.active);
								subList.push(subItem);
							});
							if (isChild) {
								item = {
									...item,
									active: activeChilds.every(element => element)
								};
							}
						}
						item = {
							...item,
							children: subList
						};
					}
					return item;
				});
				return {
					...listItem,
					options: updatedOptions
				};
			});
			list.push(updatedList)
			return updatedList;
		});
		let isAnySelectedVal = isAnyAbiUpdateActive(list)
		setIsAnySelected(isAnySelectedVal)
	}

	useEffect(() => {
		fetchNotificationSettings(dispatch)
		fetchRolesLookUp(dispatch)
	}, [])

	useEffect(() => {
		let rolesOptionList = []
		setSettingList(settings_container && settings_container.notificationSettings)
		if (settings_container && settings_container.rolesLookup && settings_container.rolesLookup.length > 0) {
			settings_container.rolesLookup.map(roles => {
				rolesOptionList.push({ value: roles.description, label: roles.description, id: roles.id })
			})
		}
		setIsAnySelected(isAnyAbiUpdateActive(settingList))
		setRolesOption(rolesOptionList)
		if (settings_container.preferences && settings_container.preferences.alert_types && settings_container.preferences && settings_container.preferences.alert_types.length > 0) {
			let projectRoles = settings_container.preferences && settings_container.preferences.alert_types.map(item =>
				!isNaN(item.slice(item.lastIndexOf("-") + 1, item.length)) && parseInt(item.slice(item.lastIndexOf("-") + 1, item.length)))
			const originalSelectedRoles = rolesOptionList && rolesOptionList.filter(x => projectRoles.includes(x.id))
			setSelectedRoles(originalSelectedRoles)
			setRolesOption(rolesOptionList && rolesOptionList.filter(x => !originalSelectedRoles.some(y => y.id === x.id)))
		} else {
			setSelectedRoles([])
			setRolesOption(rolesOptionList)
		}
	}, [settings_container.notificationSettings, settings_container.rolesLookup])

	const handleSwitchEmailNotification = (e) => {
		setChecked((prev => ({ ...prev, switchEmail: e.target.checked })))
	}

	const handleFeedAlert = (e) => {
		setChecked((prev => ({ ...prev, feedCheckbox: e.target.checked, feedSwitchEmail: e.target.checked })))
	}

	const handleFeedEmailAlert = (e) => {
		setChecked((prev => ({ ...prev, feedSwitchEmail: e.target.checked })))
	}

	const handleShareCheckboxNotification = (e) => {
		setChecked((prev => ({ ...prev, shareCheckbox: e.target.checked, shareSwitchEmail: e.target.checked})))
	}

	const handleShareNotification = (e) => {
		setChecked((prev=> ({...prev, shareSwitchEmail: e.target.checked})))
	}

	const handleReminderAlert = (e) => {
		setChecked((prev=> ({...prev, reminderCheckbox: e.target.checked, reminderSwitchEmail: e.target.checked})))	
	}

	const handleReminderEmailAlert = (e) => {
		setChecked((prev=> ({...prev, reminderSwitchEmail: e.target.checked})))
	}

	useEffect(() => {
		if (active) {
			setChecked({
				switchEmail: settings_container.preferences.email_alert,
				feedCheckbox: settings_container.preferences.feed_alert,
				feedSwitchEmail: settings_container.preferences.feed_email_alert,
				shareCheckbox: settings_container.preferences.share_alert,
				shareSwitchEmail: settings_container.preferences.share_email_alert,
				reminderCheckbox: settings_container.preferences.inactivity_prompts,
				reminderSwitchEmail: settings_container.preferences.inactivity_prompts_email
			})
			setSelectedOptions([])
		}
	}, [active])

	const getSelectedAlertIds = (alertIds) => {
		settingList && settingList.map(item => {
			item.options.map(option => {
				if (option.children && option.children.length > 0) {
					option.children.map(child => {
						if (child.active && child.value) {
							alertIds.push(child.value);
						}
					});
				}
				else if (option.active && option.name == "Project roles added" && selectedRoles && selectedRoles.length > 0) {
					selectedRoles && selectedRoles.map(item => {
						alertIds.push(option.value + "-" + item.id)
					})
				} else if (option.active && option.value) {
					alertIds.push(option.value);
				}
			});
		});
	}

	const handleSubmit = () => {
		let alertIds = []
		getSelectedAlertIds(alertIds);
		updateUserSettings({
			"alert_types": alertIds.length > 0 ? alertIds : [],
			"email_alert": checked.switchEmail,
			"share_email_alert": checked.shareSwitchEmail,
			"share_alert": checked.shareCheckbox,
			"feed_alert": checked.feedCheckbox,
			"feed_email_alert": checked.feedSwitchEmail,
			"inactivity_prompts": checked.reminderCheckbox,
			"inactivity_prompts_email": checked.reminderSwitchEmail
		}, userData.user_id, dispatch, 'notifications')
	}

	const handleRoleChange = (selectedOption) => {
		setSelectedOptions(selectedOption)
	}

	const handleAddRoles = () => {
		let updatedSelectedRoles = selectedRoles.slice()
		selectedOptions.map((item, index) => {
			updatedSelectedRoles.push(item)
		})
		updatedSelectedRoles.sort((a, b) => a.value.localeCompare(b.value))
		setSelectedOptions([])
		setSelectedRoles(updatedSelectedRoles)
		setRolesOption(rolesOption && rolesOption.filter(x => !updatedSelectedRoles.some(y => y.value === x.value)))
	}

	const onRoleInputKeyDown = (event) => {
		if (event.keyCode == 13) {
			handleAddRoles()
		}
	}

	return (
		<div className="notification-wrapper notificationTabBlock">
			<ColoredScrollbars className="details-panel-scrollbar">
				<div className="content-panel p-1">
					<div className="col-md-12 col-sm-12 pt-2">
						<label className="m-0 font-bold">Project and company updates</label>
						<p className="mb-0">Receive notifications when our research team make an update to a project or company you have favourited.</p>
					</div>
					<div className="col-md-12 col-sm-12 ps-0">
						<ul className="mb-0">
							{settingList && settingList.map(list => (
								<>
									<NotificationItem
										roleData={{
											options: rolesOption,
											handleRemoveRoles: handleRemoveRoles,
											handleRoleChange: handleRoleChange,
											onRoleInputKeyDown: onRoleInputKeyDown,
											handleAddRoles: handleAddRoles,
											selectedOptions: selectedOptions,
											selectedRoles: selectedRoles
										}}
										{...list} itemChangeHandler={handleOnChange} />
								</>))}
						</ul>
					</div>
					<div className="col-md-12 col-sm-12">
						<div className="switch-panel">
							<div className="switch-notification">
								<Input id="switch-email-notification" type="checkbox" name="switchEmailNotification" className="cmn-toggle cmn-toggle-round"
									checked={checked.switchEmail} onChange={e => handleSwitchEmailNotification(e)} />
								<Label for="switch-email-notification"></Label>
							</div>
							<div className="notification-switch-text">
								<p>Receive notifications by email</p>
							</div>
						</div>
					</div>
				</div>
				<div className="content-panel p-3">
					<div className="col-md-12 col-sm-12 ps-0">
						<label className="font-bold">Feeds</label>
						<span className="display-block"></span>
						<FormGroup className="notification-shares" check >
							<div className="check-box-button">
								<Input type="checkbox" id="feed-alert-checkbox" name="feedNotification" value=""
									checked={checked.feedCheckbox} onChange={e => handleFeedAlert(e)} />
								<Label for="feed-alert-checkbox">Receive scheduled feed notification</Label>
							</div>
						</FormGroup>
						<div className="switch-panel">
							<div className="switch-notification">
								<Input id="feed-email-notification-switch" type="checkbox" name="feedEmailNotificationSwitch" className="cmn-toggle cmn-toggle-round"
									checked={checked.feedSwitchEmail} disabled={!checked.feedCheckbox}
									onChange={e => handleFeedEmailAlert(e)} />
								<Label for="feed-email-notification-switch"></Label>
							</div>
							<div className="notification-switch-text">
								<p>Receive notifications by email</p>
							</div>
						</div>
					</div>
				</div>
				<div className="content-panel p-3">
					<div className="col-md-12 col-sm-12 ps-0">
						<label className="font-bold">Shares</label>
						<span className="display-block">Receive notifications for information shared with you</span>
						<FormGroup className="notification-shares" check key={`recieve notification`}>
							<div className="check-box-button">
								<Input type="checkbox" id="receive-notification-checkbox" name="allShares" value=""
									checked={checked.shareCheckbox} onChange={e => handleShareCheckboxNotification(e)} />
								<Label for="receive-notification-checkbox">All shares</Label>
							</div>
						</FormGroup>
						<div className="switch-panel">
							<div className="switch-notification">
								<Input id="switch-share-notification" type="checkbox" name="switchShareNotification" className="cmn-toggle cmn-toggle-round"
									checked={checked.shareSwitchEmail} disabled={!checked.shareCheckbox}
									onChange={e => handleShareNotification(e)} />
								<Label for="switch-share-notification"></Label>
							</div>
							<div className="notification-switch-text">
								<p>Receive notifications by email</p>
							</div>
						</div>
					</div>
				</div>
				<div className="content-panel p-3">
					<div className="col-md-12 col-sm-12 ps-0">
						<label className="font-bold">Project reminder emails</label>
						<span className="display-block">Receive email notifications for projects you may have missed</span>
						<FormGroup className="notification-shares" check >
							<div className="check-box-button">
								<Input type="checkbox" id="reminder-alert-checkbox" name="reminderEmails" value=""
									checked={checked.reminderCheckbox} onChange={e => handleReminderAlert(e)} />
								<Label for="reminder-alert-checkbox">Receive notifications</Label>
							</div>
						</FormGroup>
						<div className="switch-panel">
							<div className="switch-notification">
								<Input id="switch-reminder-notification" type="checkbox" name="switchReminderNotification" className="cmn-toggle cmn-toggle-round"
									checked={checked.reminderSwitchEmail} disabled={!checked.reminderCheckbox}
									onChange={e => handleReminderEmailAlert(e)} />
								<Label for="switch-reminder-notification"></Label>
							</div>
							<div className="notification-switch-text">
								<p>Receive notifications by email</p>
							</div>
						</div>
					</div>
				</div>
				<button className={"primary-btn btn-save-cancel mt-3"} onClick={handleSubmit}>Save</button>
			</ColoredScrollbars>
		</div>
	);
}

export default Notifications;