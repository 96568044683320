import _, { cloneDeep } from 'lodash';
export function getAvailableLicenses(total_qty, assigned_qty){
    var available_license = 0
    if(total_qty > 0) {
        available_license = total_qty - assigned_qty
    }
    return available_license
}
export const createReceipientUserList = (recipientsUserList) => {
    let options = [];
    _.map(recipientsUserList, (item) => {
            options.push(item);
    });
    options.sort((user1, user2) => {
        if (!user1.first_name) {
            return 1;
        }
        if (!user2.first_name) {
            return -1;
        }
        return user1.first_name.toUpperCase() < user2.first_name.toUpperCase()
            ? -1
            : 1;
    });
    return options;
};
export function getGrantRevokeInfo(assignedUsers, userno, licenseno, status, userLicenses) {
    var is_present = false
    var current_user = {}
    let userLicenseNos = []
    let current_user_index = null
    if (userLicenses) {
        userLicenses.map(function (license, index) {
            userLicenseNos.push(license.license_no)
        })
    }
    for (var index in assignedUsers) {
        if (assignedUsers[index].userno == userno) {
            current_user = assignedUsers[index]
            current_user_index = index
            is_present = true
            break
        }
    }
    var grant = []
    var revoke = []
      var currentUserData = cloneDeep(current_user)
    if (is_present) {
        if (status) {
            if (!currentUserData.grant.includes(licenseno)) {
                if (!userLicenseNos.includes(parseInt(licenseno))) {
                    currentUserData.grant.push(licenseno)
                }
            }
            if (currentUserData.revoke.includes(licenseno)) {
                let license_index = currentUserData.revoke.indexOf(licenseno)
                currentUserData.revoke.splice(license_index, 1)
            }
        } else {
            if (!currentUserData.revoke.includes(licenseno)) {
                if (userLicenseNos.includes(parseInt(licenseno))) {
                    currentUserData.revoke.push(licenseno)
                }
            }
            if (currentUserData.grant.includes(licenseno)) {
                let license_index = currentUserData.grant.indexOf(licenseno)
                currentUserData.grant.splice(license_index, 1)
            }
        }
        current_user=currentUserData
        grant = current_user.grant
        revoke = current_user.revoke
    } else {
        status ? grant.push(licenseno) : revoke.push(licenseno)

        const data = {
            "grant": grant,
            "revoke": revoke,
            "userno": userno,
            
        };
        assignedUsers = [...assignedUsers, data];

    }
    if ((grant && grant.length > 0) || (revoke && revoke.length > 0)) {
        let checkCurrentUserEmpty=_.isEmpty(current_user)
        if(!checkCurrentUserEmpty){
          let updatedAssignedLicences =assignedUsers.map(item=>{
                if(item.userno===current_user.userno){
                    item = { ...item, grant: current_user.grant , revoke:current_user.revoke };
                }
                return item;
         
            })
            return updatedAssignedLicences;
        }
        else{
            return assignedUsers
        }
      
    } else {
        let assignedUserData=cloneDeep(assignedUsers)
        assignedUserData.splice(current_user_index, 1)
        assignedUsers=assignedUserData
        return assignedUsers
    }
  
}

export function getCompactDigitValue(number, digit=2){
    let number_length = (''+number).length;
    if (number_length < 4){
      return number.toString()
    }
    digit = Math.pow(10,digit);
    number_length -= number_length%3;
    return Math.round(number*digit/Math.pow(10,number_length))/digit+" KMBTPEZ"[number_length/3]
  }

