import React from "react";
import AlertMessage from "../../components/alerts";
import LeftPanel from "../../components/leftpanel";
import TopHeader from "../../components/header";
import NewExport from "../../components/export/newExport";

const NewExportPage = () => {
    return (
        <div id="container">
            <TopHeader />
            <div className="sidebar-mini">
                <LeftPanel />
                <div id="message-container">
                    <AlertMessage />
                </div>
                <div className="main-content">
                    <div className="export-new-page pt-3 main-container container">
                        <NewExport />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewExportPage;