import React, { useState, useEffect } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Users from './users';
import AssignLicence from './assignlicence';
import UserActivity from './useractivity';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetAdminUserList, resetUserSearchList,updateAssignLicenseSearchTerm , updateUserSearchTerm } from '../reducer';


const UserAdminTab = () => {
    const { hash } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("users")

    const toggleTab = (activeTab) => {
    dispatch(updateUserSearchTerm(""))
    dispatch(resetUserSearchList([]))
    dispatch(resetAdminUserList([]))
    dispatch(updateAssignLicenseSearchTerm(""))
    navigate("/app/useradmin#" + activeTab)
    }

    useEffect(() => {
        if (hash){
            setActiveTab(hash.replace('#', ''))
        }
         
    }, [hash])

    return (
        <div className="tab-pane active">
            <Row className="page-title-bar">
                <Col col={12} sm={12}>
                    <h2 class="h5 pl-3">Accounts</h2>
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0 company-tab-wrapper">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "users" })}
                                onClick={() => { toggleTab("users"); }}
                            >
                                Users
                                </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "assignLicence" })}
                                onClick={() => { toggleTab("assignLicence"); }}
                            >
                                Assign Licence
                                </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "userActivity" })}
                                onClick={() => { toggleTab("userActivity"); }}
                            >
                                User Activity
                                </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="p-0">
                        <TabPane tabId="users">
                            <Users activeTab={activeTab} />
                        </TabPane>
                        <TabPane tabId="assignLicence">
                            <AssignLicence activeTab={activeTab} />
                        </TabPane>
                        <TabPane tabId="userActivity">
                            <UserActivity activeTab={activeTab} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>
    )
}

export default UserAdminTab