import React, { useEffect, useState ,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Container } from 'reactstrap'
import { AvField, AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { updateIsEditUser, updateIsUniqueUser } from './reducer';
import { findUniqueUser, updateExistingUser } from './apiclient';

const EditUser = (props) => {
    const avFormRef = useRef();
    const useradminData = useSelector(state => state.useradmin);
    const dispatch = useDispatch();
    const [editUserState,setEditUserState]=useState(props?.result)
    const [values,setValues]=useState({})
    const [emailvalue,setEmailValue]=useState(null)
    const [uniqueUser,setUniqueUser]=useState(true)

    const handleCancel = (event) => {
        event.preventDefault();
        dispatch(updateIsEditUser(false));
        props.handleIsEditFlag(false)
		props.isEditUserFlag(false)
        dispatch(updateIsUniqueUser(true))
        setUniqueUser(true)
        if (avFormRef.current) {
            avFormRef.current.reset(); 
          }
   
    }
    const handleEmailChange = (event) => {
        setEmailValue(event.target.value)
    }

    const handleUserNameChange = (event) => {
        findUniqueUser(event.target.value, dispatch)
        props.handleIsEditFlag(true)
        props.isEditUserFlag(true)

    }
     
    const handleUpdateUser=(event, errors, values)=> {
        values.userno=props.result.user_no
        setValues(values)
		if (errors.length === 0) {
            updateExistingUser(values,props.result.user_no,props?.searchTerm,dispatch)
			props.handleIsEditFlag(false)
			props.isEditUserFlag(false)
		}
	}

    useEffect(() => {
		setUniqueUser(useradminData?.isUniqueUser)
	}, [useradminData?.isUniqueUser]);


    return (
        <div className="edit-new-user">
          	<Row className="m-0">
					<Col className="user-names" xs="12" sm="12" md="9">
						<span className="user-icon">AB</span>
						<span className="fl-name">{editUserState?.first_name} {editUserState?.last_name}</span>
					</Col>
					{/* <Col className="user-licenses" xs="12" sm="12" md="3">
						{
							licenses.map(function (name, index) {
								return <span key={`name-${index}`} className="user-license"> {name} </span>
							})
						}
						
					</Col> */}
				</Row>
            <Container className="pl-0 mr-0">
                <AvForm  ref={avFormRef}  onSubmit={handleUpdateUser}>
                    <Row>
                        <AvGroup className="col-lg-3">
                            <AvInput autoComplete="off" type="text" name="first_name" value={editUserState?.first_name}  maxLength="30" className="mr-1 mb-1" placeholder="Enter first name *" required />
                            <AvFeedback>Please enter first name</AvFeedback>
                        </AvGroup>
                        <AvGroup className="col-lg-3">
                            <AvInput autoComplete="off" type="text" name="last_name" value={editUserState?.last_name} maxLength="30" className="mr-1 mb-1" placeholder="Enter last name *" required />
                            <AvFeedback>Please enter last name</AvFeedback>
                        </AvGroup>
                    </Row>
                    <Row>
                        <AvGroup className="col-lg-6">
                            <AvInput
                                autoComplete="off"
                                type="text"
                                name="username"
                                value={editUserState.username}
                                className="mr-1 mb-1"
                                onChange={handleUserNameChange}
                                maxLength="30"
                                placeholder="Username *"
                                required />
                            <AvFeedback>Please enter username</AvFeedback>
                            {!uniqueUser ?
									<div className="form-control-feedback user-exists">Username already exists</div> : ''
								}

                        </AvGroup>
                    </Row>
                    <Row>
                        <AvGroup className="col-lg-6 email-icon">
                            <AvField autoComplete="off" type="text"
                                name="email"
                                maxLength="60"
                                value={editUserState.email}
                                onBlur={handleEmailChange}
                                onKeyDown={e => {
                                if (e.keyCode === 32) return handleEmailChange
                                }}
                                className="mr-1 mb-1"
                                placeholder="Enter email *"
                                validate={{
                                    pattern: { value: '^[\\w-_\.\'+]*[\\w-_\.\']\@([\\w-]+\\.)+[a-zA-Z]{2,}$', errorMessage: 'Please enter valid email' },
                                    required: { value: '', errorMessage: 'Please enter email' },
                                }}
                            />
                        </AvGroup>
                    </Row>
                    <Row>
							<AvGroup className="col-lg-3 mobile-icon">
								<AvField autoComplete="off" type="text" name="mobile" value={(editUserState.mobile != "-") ? editUserState.mobile : ''} className="mobile mr-1 mb-1" placeholder="Enter mobile number" />
							</AvGroup>
							<AvGroup className="col-lg-3 company-icon">
								<AvField autoComplete="off" type="text" name="phone" value={(editUserState.phone != "-") ? editUserState.phone : ''}  className="company-phone mr-1 mb-1" placeholder="Enter company phone"
									validate={{
										pattern: { value: '^[0-9]+$', errorMessage: 'Please enter numeric value' },
										minLength: { value: 8, errorMessage: 'Enter atleast 8 digits without space' },
										maxLength: { value: 12, errorMessage: 'Enter maximum 12 digits without space' },
									}} />
							</AvGroup>
						</Row>
                    <Row className="form-button-row">
                        <button className="primary-btn btn-edit-user">Update</button>
                        <button className="outline-btn" onClick={handleCancel}>Cancel</button>
                    </Row>
                </AvForm>
            </Container>
        </div>
    )
}

export default EditUser;