import React from 'react'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

  const navigate = useNavigate();

  const handleBack = (event) => {
    event.preventDefault()
    navigate(-1);
  }

  const goToLoginPage = (event) => {
    event.preventDefault()
    window.location = '/'
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <nav role="navigation" className="navbar fixed-top navbar-toggleable-md navbar-light bg-faded top-navigation navbar">
              <a href="/" className="logo-placeholder navbar-brand"></a>
              <div className="collapse navbar-collapse justify-content-end" id="profileNavDrop"></div>
          </nav>
        </div>
      </div>
      <div className="row not-found-msg">
        <div className="col">
          <h3>404 page not found</h3>
          <p>We are sorry but the page you are looking for was moved, removed, renamed or might never have existed.</p>
          <div className='d-flex justify-content-center'>
            <Button onClick={handleBack} className="btn-black mr-2">Back</Button>
            <Button onClick={goToLoginPage} className="btn primary-btn red-btn">Barbour ABI</Button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NotFound;
