import React from 'react'
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap'

const DeleteTemplateModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} id="deleteTempate">
            <ModalHeader className="px-4 mx-2">
                Delete template
            </ModalHeader>
            <div className="delete-warning mt-3 mb-0">
                Are you sure you want to delete your template? This cannot be undone
            </div>
            <ModalFooter>
                <Button id="primaryBtn" className="primary-btn btn-save-cancel" onClick={props.handleDelete}>Yes</Button>
                <Button className="outline-btn btn-save-delete" onClick={props.handleCancel}>No</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteTemplateModal