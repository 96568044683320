import React from 'react';
import NotFound from '../../components/notfound';

const NotFoundPage = () => {
	
	return (
		<div className="not-found">
			<NotFound />
		</div>
	);
}

export default NotFoundPage;