import React, { useEffect, useState } from 'react'
import { FormGroup, Row, Col, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { updateMeasures } from './redux/reducer';

const MeasuresFilter = (props) => {
    const [isError, setIsError] = useState({
        totalFloorArea: false,
        groundFloorArea: false,
        aboveGroundStoreys: false,
        siteArea: false,
        belowGroundStoreys: false,
        noOfUnits: false,
        parkingSpaces: false
    });
    const [validationMsg, setValidationMsg] = useState({
        parkingSpaces: 'From field must be smaller than or equal to to field',
        totalFloorArea: 'From field must be smaller than or equal to to field',
        groundFloorArea: 'From field must be smaller than or equal to to field',
        siteArea: 'From field must be smaller than or equal to to field',
        aboveGroundStoreys: 'From field must be smaller than or equal to to field',
        belowGroundStoreys: 'From field must be smaller than or equal to to field',
        noOfUnits: 'From field must be smaller than or equal to to field'
    })

    const [measuresObj, setMeasuresObj] = useState({
        totalFloorArea: {
            from: "", to: ""
        },
        groundFloorArea: {
            from: "", to: ""
        },
        siteArea: {
            from: "", to: ""
        },
        aboveGroundStoreys: {
            from: "", to: ""
        },
        belowGroundStoreys: {
            from: "", to: ""
        },
        noOfUnits: {
            from: "", to: ""
        },
        parkingSpaces: {
            from: "", to: ""
        }

    })
    const [isValidate, setIsValidate] = useState(false)
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)

    const dispatch = useDispatch();

    const handleInputChange = (e, inputRange) => {
        const { name, value } = e.target;
        measuresObj[name][inputRange] = value
        setMeasuresObj(_.cloneDeep(measuresObj))
        if (!isNaN(e.target.value)) {
            setValidationMsg(current => ({ ...current, [name]: "" }));
            setIsError(current => ({ ...current, [name]: false }));
            validateInput(name)
            let payload = {
                [name]: {
                    from: measuresObj[name].from ? measuresObj[name].from : "",
                    to: measuresObj[name].to ? measuresObj[name].to : '',
                    active: measuresObj[name].from || measuresObj[name].to ? true : false
                }
            }
            dispatch(updateMeasures(payload))
            setIsValidate(true)
        } else {
            setValidationMsg(current => ({ ...current, [name]: "Please enter numbers only" }));
            setIsError(current => ({ ...current, [name]: true }));
            measuresObj[name][inputRange] = ""
            setMeasuresObj(_.cloneDeep(measuresObj))
            setIsValidate(false)
        }
    }


    const validateInput = (groupName) => {
        if (measuresObj?.[groupName]?.from) {
            if (parseInt(measuresObj[groupName].from) > parseInt(measuresObj[groupName].to)) {
                setValidationMsg(current => ({ ...current, [groupName]: "From field must be smaller than or equal to to field" }));
                setIsError(current => ({ ...current, [groupName]: true }));
                setIsValidate(false)
            } else {
                setValidationMsg(current => ({ ...current, [groupName]: "" }));
                setIsError({ ...isError, [groupName]: false });
                setIsValidate(true)
            }
        }

    }

    useEffect(() => {
        setMeasuresObj(_.cloneDeep(measuresObj))
    }, [measuresObj])

    useEffect(() => {
        setMeasuresObj(_.cloneDeep(filter?.filtersState[searchPersist.appliedContext]?.measure?.options))
        if(!filter?.filtersState[searchPersist.appliedContext]?.measure.active){
            setMeasuresObj({
                totalFloorArea: {
                    from: "", to: ""
                },
                groundFloorArea: {
                    from: "", to: ""
                },
                siteArea: {
                    from: "", to: ""
                },
                aboveGroundStoreys: {
                    from: "", to: ""
                },
                belowGroundStoreys: {
                    from: "", to: ""
                },
                noOfUnits: {
                    from: "", to: ""
                },
                parkingSpaces: {
                    from: "", to: ""
                }
        
            })
        }
    }, [filter?.filtersState[searchPersist.appliedContext]?.measure?.options])

    useEffect(() => {
        setIsError(_.cloneDeep(isError))
    }, [isError])

    useEffect(() => {
        setValidationMsg(_.cloneDeep(validationMsg))
    }, [validationMsg])


    return (
        <div className="advanced-filter-grid" id="new_filter_measure">
            <div className="filterHeading">
                <h5 className="mb-3">Measures</h5>
            </div>
            <div className={`filterBlock ${(measuresObj?.totalFloorArea) || (measuresObj?.groundFloorArea) || (measuresObj?.groundFloorArea) || (measuresObj?.siteArea) || (measuresObj?.siteArea) || (measuresObj?.aboveGroundStoreys) || (measuresObj?.aboveGroundStoreys) || (measuresObj?.belowGroundStoreys) || (measuresObj?.belowGroundStoreys) || (measuresObj?.noOfUnits) ? "activeBlock" : ""}`}>
                <div className="textcontent mb-0">
                    <Row className="align-items-center">
                        <Col xs={12} sm={8} md={8} lg={11}>Filter by project measures</Col>
                    </Row>
                </div>
                <div className="sliderBlock">
                    <FormGroup id="updatedFilter" name="updatedFilter" row className="">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="measures-frames">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Plan area (ground floor only)</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className='form-control'
                                                    placeholder='123...'
                                                    id='totalFloorArea'
                                                    name='totalFloorArea'
                                                    value={measuresObj?.totalFloorArea?.from}
                                                    onChange={e => handleInputChange(e, "from")}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='totalFloorArea'
                                                    name='totalFloorArea'
                                                    value={measuresObj?.totalFloorArea?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.totalFloorArea &&
                                        <p className="text-danger">{validationMsg.totalFloorArea}</p>
                                    }

                                </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Floor area (all floors)</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='groundFloorArea'
                                                    name='groundFloorArea'
                                                    value={measuresObj?.groundFloorArea?.from}
                                                    onChange={e => handleInputChange(e, "from")}

                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='groundFloorArea'
                                                    name='groundFloorArea'
                                                    value={measuresObj?.groundFloorArea?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.groundFloorArea &&
                                        <p className="text-danger">{validationMsg.groundFloorArea}</p>
                                    }

                                </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Site area</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='siteArea'
                                                    name='siteArea'
                                                    value={measuresObj?.siteArea?.from}
                                                    onChange={e => handleInputChange(e, "from")}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='siteArea'
                                                    name='siteArea'
                                                    value={measuresObj?.siteArea?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">sq.m</span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.siteArea &&
                                        <p className="text-danger">{validationMsg.siteArea}</p>
                                    }         </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Above ground storeys</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='aboveGroundStoreys'
                                                    name='aboveGroundStoreys'
                                                    value={measuresObj?.aboveGroundStoreys?.from}
                                                    onChange={e => handleInputChange(e, "from")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='aboveGroundStoreys'
                                                    name='aboveGroundStoreys'
                                                    value={measuresObj?.aboveGroundStoreys?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.aboveGroundStoreys &&
                                        <p className="text-danger">{validationMsg.aboveGroundStoreys}</p>
                                    }        </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Below ground storeys</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='belowGroundStoreys'
                                                    name='belowGroundStoreys'
                                                    value={measuresObj?.belowGroundStoreys?.from}
                                                    onChange={e => handleInputChange(e, "from")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='belowGroundStoreys'
                                                    name='belowGroundStoreys'
                                                    value={measuresObj?.belowGroundStoreys?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.aboveGroundStoreys &&
                                        <p className="text-danger">{validationMsg.belowGroundStoreys}</p>
                                    }
                                </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Number of units</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='noOfUnits'
                                                    name='noOfUnits'
                                                    value={measuresObj?.noOfUnits?.from}
                                                    onChange={e => handleInputChange(e, "from")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='noOfUnits'
                                                    name='noOfUnits'
                                                    value={measuresObj?.noOfUnits?.to}
                                                    onChange={e => handleInputChange(e, "to")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.noOfUnits &&
                                        <p className="text-danger">{validationMsg.noOfUnits}</p>
                                    }         </Row>
                            </div>
                            <div className="measures-frames mt-3">
                                <Row className="mb-1">
                                    <Col xs={12} sm={8} md={8} lg={11} className='fw-bold'>Parking spaces</Col>
                                </Row>
                                <Row className='update-location-slider-frames'>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">From</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='123...'
                                                    id='parkingSpaces'
                                                    name='parkingSpaces'
                                                    value={measuresObj?.parkingSpaces?.from}
                                                    onChange={(e) => handleInputChange(e, "from")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <FormGroup className='row'>
                                            <div className="col-sm-12 input-group">
                                                <Label className="col-form-label me-2">To</Label>
                                                <Input
                                                    class="form-control"
                                                    placeholder='No limit'
                                                    id='parkingSpaces'
                                                    name='parkingSpaces'
                                                    value={measuresObj?.parkingSpaces?.to}
                                                    onChange={(e) => handleInputChange(e, "to")}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isError.parkingSpaces &&
                                        <p className="text-danger">{validationMsg.parkingSpaces}</p>
                                    }           </Row>
                            </div>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )

}
export default MeasuresFilter;