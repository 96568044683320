import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { ABIPHONE, FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER, YOUTUBE } from "../../utils/settings";
import Icon from "../common/js/icon";

const SignInHeader = () => {

	return (
		<div>
			<Navbar className="navbar fixed-top navbar-toggleable-md navbar-light bg-faded top-navigation  d-flex justify-content-between">

			<NavbarBrand href="/" className="logo-placeholder mx-2 my-0">
			</NavbarBrand>
			<div className='d-flex flex-column flex-sm-row social-media align-self-center'>
				<div className="d-flex p-1 p-sm-0 align-self-center">
				<a className="phone me-2" href="#"></a><Icon size={35} icon="icon-sm-call" className="me-2"/><span className="align-self-center pe-md-5 call-us">{ ABIPHONE }</span>
				</div>
				<div className="d-flex p-1 p-sm-0 align-self-center">
				<a className="linkdin me-2" target="_blank" href={LINKEDIN}><Icon size={35} icon="icon-sm-linkedin"/></a>
				<a className="twitter me-2" target="_blank" href={TWITTER}><Icon size={35} icon="icon-sm-twitter"/></a>
				<a className="insta me-2" target="_blank" href={INSTAGRAM }><Icon size={35} icon="icon-sm-instagram"/></a>
				<a className='youtube me-2' target="_blank" href={YOUTUBE}><Icon size={35} icon="icon-sm-youtube"/></a>
				<a className='facebook me-0 me-sm-2' target="_blank" href={FACEBOOK}><Icon size={35} icon="icon-sm-facebook"/></a>
				</div>
			</div>
			</Navbar>
		</div>
	);
}

export default SignInHeader;