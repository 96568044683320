import { setValidatePasswordResponse, updateDeveloperApiKey, updateIfUserHasApiKey, updateIsDeveloper, updateNotificationSettings, updateProjectRoleLookup, updateUserPreferences } from "../../redux/features/settings/settingslice";
import { signOut } from "../../redux/features/user/userslice";
import { messageAlert } from "../../utils";
import getAxios from "../../utils/baseApi"
import { updateNotificationSettingResponse } from "../../utils/commonFunctions";
import { handleError } from "../../utils/errorHandler";
import { DEVELOPER_LICENSE } from "../../utils/settings";

let axios = getAxios()

export const fetchNotificationSettings = (dispatch) => {
    axios.get('notifications/settings')
        .then(function (response) {
            if (response.status === 200) {
                const formattedNotification = updateNotificationSettingResponse(response.data.notification)
                dispatch(updateNotificationSettings(formattedNotification))
            }
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchRolesLookUp = (dispatch) => {
    axios.get('lookups?lookups=role')
        .then(function (response) {
            if (response.status === 200) {
                dispatch(updateProjectRoleLookup(response.data.lookups.role))
            }
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export const validatePassword = (payload, dispatch) => {
    var params = { password: payload.password }
    axios.post('users/verify_password?user_id=' + payload.user_id, JSON.stringify(params)).then((response) => {
        dispatch(setValidatePasswordResponse(response.data.data))
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const changePassword = (payload, dispatch) => {
    axios.put("users/change_password", payload)
        .then((response) => {
            if (response.status === 201) {
                messageAlert(dispatch, "Password updated successfully", 'success')
                dispatch(signOut())
                window.location = "/"
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export function updateUserSettings(settings, userId, dispatch, tab) {
    settings = {
        preferences: settings
    }
    axios.put('users/' + userId, settings)
        .then(function (response) {
            if (response.status === 200) {
                dispatch(updateUserPreferences(response.data.preferences))
                if (tab === 'notifications') {
                    messageAlert(dispatch, 'Notification settings saved successfully', 'success')
                } else if (tab === 'fav-roles') {
                    messageAlert(dispatch, 'Favourite roles settings saved successfully', 'success')
                    window.Intercom('trackEvent', 'Favourite role')
                } else {
                    messageAlert(dispatch, 'Saved successfully', 'success')
                }
                fetchNotificationSettings(dispatch)
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function fetchUserSettings(userId, fetchSettings, dispatch) {
    axios.get('/users/' + userId)
        .then(function (response) {
            if (response.status === 200) {
                dispatch(updateUserPreferences(response.data && response.data.user && response.data.user.preferences))
                const isDeveloper = DEVELOPER_LICENSE.some(value => response.data.user?.licence_ids?.includes(value));
                    dispatch(updateIsDeveloper(isDeveloper))
                if (fetchSettings) {
                    fetchNotificationSettings(dispatch)
                }
            }
        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export function createApiKey(client_id, dispatch) {
    axios.put('api-keys')
        .then((response) => {
            if (response.status === 201 && response.data.api_key) {
                dispatch(updateDeveloperApiKey(response.data.api_key));
                dispatch(updateIfUserHasApiKey(true))
                updateHasApiKey({"has_api_key": true}, client_id, dispatch)
            }
        })
        .catch((error) => {
            handleError(error);
            throw error;
        });
}

export function deleteApiKey(client_id, dispatch) {
    axios.delete('api-keys')
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                dispatch(updateIfUserHasApiKey(false))
                updateHasApiKey({"has_api_key": false}, client_id, dispatch)
            }
        })
        .catch((error) => {
            handleError(error);
            throw error;
        });
}

export function fetchHasApiKey(id, dispatch) {
    axios.get("customers/" + id + "/config")
        .then((response) => {
            if (response.status === 200) {
                dispatch(updateDeveloperApiKey(null));
                dispatch(updateIfUserHasApiKey(response.data?.has_api_key === "true" ? true : false))

            } else {
                dispatch(updateIfUserHasApiKey(false))
            }

        }).catch(function (error) {
            handleError(error, dispatch)
        })
}

export function updateHasApiKey(payload, id, dispatch) {
    axios.put("customers/" + id + "/config", payload)
        .then((response) => {
            if (response.status === 201) {
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}