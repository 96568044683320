import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, ListGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { fetchPeopleProjectRoles, fetchProjectsForPeopleRoleId } from "../apiclient"
import SearchListRow from "../list/searchListRow"
import './person.scss';

const PeopleProjectsRoles = () => {
	const location = useLocation().pathname;
	const details = useSelector((state) => state.project)
	const [activeTab, setActiveTab] = useState(details?.peopleProjectRoles?.length > 0 ? details.peopleProjectRoles[0].role_code : "0");
	const [roles, setRoles] = useState(details && details.peopleProjectRoles)
	const [projectsForRoleData, setprojectDataForPeopleRoleId] = useState(details && details.projectDataForPeopleRoleId)
	const [isToggleClick, setToggleClick] = useState(false)
	const dispatch = useDispatch();
	const toggle = (tab) => {
		let payload = {
			personId: details && details.detailsPage.person_id,
			roleNo: tab.role_code,
			sort: "-project_last_published",
			limit: 50,
			offset: 0
		}
		fetchProjectsForPeopleRoleId(payload, dispatch)
		setActiveTab(tab.role_code)
		setToggleClick(true)
	}
	useEffect(() => {
		if (!details?.peopleProjectRoles?.length) {
			fetchPeopleProjectRoles(location.split('/')[3], dispatch)
		}
	}, [location])

	useEffect(() => {
		setRoles(details && details.peopleProjectRoles)
		if (!isToggleClick) {
			setActiveTab(details?.peopleProjectRoles?.length > 0 ? details.peopleProjectRoles[0].role_code : "0")
		}
	}, [details.peopleProjectRoles])

	useEffect(() => {
		setprojectDataForPeopleRoleId(details && details.projectDataForPeopleRoleId)
	}, [details && details.projectDataForPeopleRoleId, activeTab])

	return (
		<>
			<div className="tab-pane active">
				<div className="box-view">
					<Row className="pt-3 ms-0 me-0 mb-3 project-tab-panel">
						<Col sm="12" md="12" lg="3" className="p-0 nav-col">
							<div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select a role to view projects</p>
							</div>
							<Nav tabs vertical className="mb-5">
								{roles && roles.length > 0 &&
									roles.map(function (role, index) {
										return (
											<NavItem key={index}>
												<NavLink
													className={classnames({ active: parseInt(activeTab) === role.role_code })}
													onClick={() => { toggle(role); }}
												>

													{role.role_code_display} {role.count ? <span>({role.count})</span> : <span>(0)</span>}
												</NavLink>
											</NavItem>
										)
									})
								}
							</Nav>

						</Col>


						<Col sm="12" md="12" lg="9" className="p-0 detail-col">
							<TabContent activeTab={activeTab} className="p-0">
								{roles && roles.length > 0 && roles.map(function (role, index) {
									if (parseInt(activeTab) === role.role_code) {
										return (
											<TabPane tabId={role.role_code} key={`${role.role_code}${index}`}>
												<div className="selected-col p-3"><p className="m-0 col-heading">Click on a project to view the details</p>


												</div>

												<div className="list-wrapper-other-pro height-350 mt-4 px-3">
													<div className="result-list-container content-panel projectNameIcon">
														<div className="result-list-wrapper">
															<ListGroup>
																{projectsForRoleData && projectsForRoleData.length > 0 ?
																	projectsForRoleData.map((entity, index) => {
																		const newEntity = Object.assign({
																			objectId: entity.project_id,
																			objectType: "project"
																		}, entity);
																		return (<SearchListRow
																			prevNextList='groupedPeopleProjects'
																			entity={newEntity}
																			role={role}
																			sort={'Latest'}
																			key={`resultrow${index}`}
																			index={index} />)
																	}) : ""
																}
															</ListGroup>
														</div>
													</div>
												</div>
											</TabPane>
										)
									}
								})
								}
							</TabContent>
						</Col>
					</Row>


				</div>
			</div>
		</>
	)

}

export default PeopleProjectsRoles;
