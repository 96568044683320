import React from 'react'
import { useSelector } from 'react-redux'
import { formatUrlWithProtocol, getCompanyAddress } from '../../../utils'

const PeopleSearchList = (props) => {
  const project = useSelector((state) => state.project)
  return (
    <>
      {/* {props.isListMapButtonFlag ? ( */}
      <div className="people-container">
        {
          props.entity.linkedIn ?
            (
              <a href={formatUrlWithProtocol(props.entity.linkedIn)} className={`linkedin-icon hide`} target="_blank"></a>
            ) :
            ('')
        }
        {/* <Link id={props.entity.objectId}
          to={state.routingState}
          onClick={handleDetails}
          action className="result-block" data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls={props.index}> */}
          <div className="listviewtable-block">
            <div className="list-table">
              <div className="list-tablerow d-flex">
                <div className={`list-col list-contact-name w-20`}>
                  {(props.entity.name != undefined) &&
                    <span className="user-icon">{(props.entity.person_first_name.match(/\b\w/g).join('')).toUpperCase().slice(0, 2)}</span>
                  }
                  <h5 className="result-title-name mb-1">{props.entity.person_first_name}</h5>
                </div>
                <div className={`list-col list-title w-15`}>
                  <h5 className="result-title-name mb-1">{props.entity.person_job_title}</h5>
                </div>
                <div className={`list-col list-email w-15`}>
                  <h5 className="result-title-name mb-1">{props.entity.person_email}</h5>
                </div>
                <div className={`list-col list-email w-10`}>
                  {
                    props.entity.phone || props.entity.mobile ?
                      <h5 className="result-title-name mb-1">{props.entity.phone}
                        {
                          props.entity.person_phone_tps ?
                            (<span className="tps-badge">TPS</span>)
                            : ("")
                        }
                        <br />
                        {props.entity.mobile}
                        {
                          props.entity.personMobileTps ?
                            (<span className="tps-badge">TPS</span>)
                            : ("")
                        }
                      </h5> :
                      <h5 className="result-title-name mb-1">
                        {props.entity.person_phone}
                        {
                          props.entity.companyPhoneTps ?
                            (<span className="tps-badge">TPS</span>)
                            : ("")
                        }
                      </h5>
                  }
                </div>
                <div className={`list-col list-email w-20`}>
                  <h5 className="result-title-name mb-1">{props.entity.company_name}</h5>
                </div>
                {/* <div className={`list-col list-email w-20`}>
                  {
                    <Row className={`com-address-print`}>
                      {isValidText(props.entity.address1)
                        || isValidText(props.entity.address2)
                        || isValidText(props.entity.address3)
                        || isValidText(props.entity.address4)
                        || isValidText(props.entity.postCode) ?
                        (<Col
                          sm={`${props.isPrint ? '12' : '12'}`}
                          md={`${props.isPrint ? '12' : '12'}`}
                          lg={`${isValidText(props.entity.telephone) ? '12' : '12'}`}
                          className="col comp-address">
                          <div className="field-value">
                            {getCompanyAddress(props.entity)}
                          </div>
                        </Col>) : ('')}
                    </Row>
                  }
                </div> */}
              </div>
            </div>
          </div>
        {/* </Link> */}
        {/* {((props.routing.locationBeforeTransitions.pathname == "/app/myleads" ||
          props.routing.locationBeforeTransitions.pathname.startsWith("/app/myLeads") ||
          props.routing.locationBeforeTransitions.pathname.startsWith("/app/myleads") ||
          props.routing.locationBeforeTransitions.pathname === "/app/list") && !props.entityFlag && !props.selectionRelatedEntities && !props.updateTabFlag) ?
          (
            <Button className="related-Company-btn" onClick={handleContactHierarchy.bind(this, props.entity)}></Button>
          ) : ('')} */}
      </div>
      {/* ) : (<span></span>)} */}
    </>
  )
}

export default PeopleSearchList