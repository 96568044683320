import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import SearchListResult from '../../search/searchListResult';
import MapView from '../../map';
import MyLeadsSearchSelectorDropdown from '../selectorDropdown/searchSelectorDropdown';
import _ from 'lodash';
import ProjectHierarchy from '../../search/hierarchyList/projectHierarchy';

const Favourites = (props) => {

    const search = useSelector((state) => state.search)
    const myleads = useSelector((state) => state.myleads)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [entityFlag, setEntityFlag] = useState(false)
    const [listMapToggle] = useState(myleads.isListView)

    useEffect(() => {
        if (search?.fetchRolesForProjects?.roleGroups?.length > 0) {
            setEntityFlag(true)
        }
    }, [search.fetchRolesForProjects])

    const getSearchListResult = () => {
        return (<SearchListResult
            currentOffset={0}
            type={searchPersist.leadsAppliedContext.favourite}
            prevNextList='myLeadsFavourites'
            activeTab={props.activeTab}
            isListMapButtonFlag={listMapToggle}
        />)
    }

    return (
        props.activeTab === 'favourite' &&
        (<div className="search-list-content">
            <div className={`search-result ${myleads.isListView ? 'list-view-panel' : ''} `}>
                <div className="active page-title-bar">
                    <div className='top-5'>
                        <div className="only-listview d-flex justify-content-between me-3">
                            <MyLeadsSearchSelectorDropdown
                                prevNextList='myLeadsFavourites'
                                activeTab={props.activeTab}
                            />
                        </div>
                    </div>
                </div>
                <Row className="me-0">
                    <Col>
                        {
                            myleads.isListView ?
                                entityFlag ?  // check nested and hierarchical
                                    (
                                        myleads.showProjectHierarchy && searchPersist.leadsAppliedContext.favourite === 'project' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ?
                                            <ProjectHierarchy listMapToggle={listMapToggle} type={searchPersist.leadsAppliedContext.favourite} prevNextList="projectHierarchicalMyLeadsFavourites" />
                                            : getSearchListResult()
                                    ) :
                                    getSearchListResult() : <MapView activeTab={props.activeTab} entityFlag={entityFlag}
                                        prevNextList={myleads.showProjectHierarchy && searchPersist.leadsAppliedContext.favourite === 'project' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ? 'projectHierarchicalMyLeadsFavourites' : 'myLeadsFavourites'} />
                        }
                    </Col>
                </Row>
            </div>
        </div>)
    )
}

export default Favourites