import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap'
import { createNewTemplate, deleteTemplate, fetchExportTemplateFields, updateTemplate } from '../../apiclient'
import { exportTemplateIdSelectionHandler, exportTemplateSelectionHandler, saveAllExportFieldsOnEdit, updateClearAllExportFields, updateExcludeContactsWithNoConfirmedName, updateExcludeContactsWithNoEmail, updateExcludeMailed, updateSelectAllExportFields, updateUserDefinedCheckedFlags } from '../../reducer'
import { updateCheckedFlags } from '../../utils'
import DeleteTemplateModal from './projectFieldStep/DeleteTemplateModal'
import SelectFieldsComponent from './SelectFieldsComponent'

const SelectContactsFieldStep = (props) => {
  let selectCheckedList = []
  let clearCheckedList = []
  const exports = useSelector((state) => state.export)
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState("Select a template");
  const [templateStatus, setTemplateStatus] = useState(false);
  const [selectedValueId, setSelectedValueId] = useState(false)
  const [isUserTemplate, setIsUserTemplate] = useState(false)
  const [isSystemTemplate, setIsSystemTemplate] = useState(false)
  const [isCreateTemplate, setIsCreateTemplate] = useState(false)
  const [isEditTemplate, setIsEditTemplate] = useState(false)
  const [templateName, setTemplateName] = useState(false)
  const [isProjectsChecked, setIsProjectsChecked] = useState(false);
  const [isCompaniesChecked, setIsCompaniesChecked] = useState(false);
  const [isContactsChecked, setIsContactsChecked] = useState(false);
  const [isProjectsCheckActive, setIsProjectCheckActive] = useState(false);
  const [isCompaniesCheckActive, setIsCompanyCheckActive] = useState(false);
  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState(false)
  const [showSavetemplateButton, setShowSavetemplateButton] = useState(true)

  function handleCheck(type) {
    exports.exportStaticData[type]?.map(function (item, index) {
      if (item.display_value) {
        item = {
          ...item, active: true
        }
        selectCheckedList.push(item.value)
      }
    })
    dispatch(updateSelectAllExportFields({ data: selectCheckedList, type: type }))
  }

  useEffect(() => {
    const fetchData = async () => {
      if (exports.exportCriteria.setting.selectedTemplate) {
        let selectedItem = exports.exportStaticData.templates.find(x => x.template_name === exports.exportCriteria.setting.selectedTemplate)
        if (selectedItem && selectedItem.template_name) {
          if (exports.isCopyFeed === false && selectedItem.template_id !== 4) {
            await fetchExportTemplateFields(dispatch, selectedItem.template_id);
            handleCheck("projects")
            handleCheck("companies")
            handleCheck("contacts")
            dispatch(updateUserDefinedCheckedFlags({
              isProjectsChecked: true,
              isCompaniesChecked: true,
              isContactsChecked: true
            }))
          }
          setSelectedValue(selectedItem.template_name)
        }
      } else {
        dispatch(exportTemplateSelectionHandler("0"))
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (exports.exportCriteria.setting.selectedTemplate) {
      let selectedItem;
      if (exports.exportCriteria.setting.templateid) {
        selectedItem = exports.exportStaticData.templates.find(x => x.template_id === exports.exportCriteria.setting.templateid);
      } else {
        selectedItem = exports.exportStaticData.templates.find(x => x.template_name === exports.exportCriteria.setting.selectedTemplate && !x.is_system);
      }
      if (selectedItem && selectedItem.template_name) {
        setSelectedValue(selectedItem.template_name);
        setSelectedValueId(selectedItem.template_id);
        setIsUserTemplate(!selectedItem.is_system && selectedItem.is_editable);
        setIsSystemTemplate(selectedItem.is_system && !selectedItem.is_editable);
      }
    } else {
      dispatch(exportTemplateSelectionHandler("0"))
    }
  }, [exports?.exportCriteria])

  useEffect(() => {
    setIsCompanyCheckActive(exports.isCompaniesCheckActive);
    setIsCompaniesChecked(exports.isCompaniesChecked);
    setIsContactsChecked(exports.isContactsChecked);
    setIsProjectsChecked(exports.isProjectsChecked);
    setIsProjectCheckActive(exports.isProjectsCheckActive);
  }, []);

  const setDefaultState = () => {
    // Set default state using the useState hook
    setIsProjectsChecked(false);
    setIsProjectCheckActive(false);
    setIsCompaniesChecked(false);
    setIsCompanyCheckActive(false);
    setIsContactsChecked(false);
    setTemplateName("");
    setIsCreateTemplate(false);
    setIsEditTemplate(false);
    setSelectedValueId(false);
    setIsUserTemplate(false);
    setIsSystemTemplate(false);

    // Update props using the provided update functions
    dispatch(
      updateExcludeMailed({
        excludeMailedProjects: false,
        excludeMailedProjectsInterval: false,
        excludeMailedCompanies: false,
        excludeMailedCompaniesInterval: false,
        excludeMailedContacts: false,
        excludeMailedContactsInterval: false,
        avoidDuplicates: false,
      })
    );

    dispatch(updateExcludeContactsWithNoConfirmedName(false));
    dispatch(updateExcludeContactsWithNoEmail(false));
    dispatch(saveAllExportFieldsOnEdit({ data: [], type: "projects" }));
    dispatch(saveAllExportFieldsOnEdit({ data: [], type: "companies" }));
    dispatch(saveAllExportFieldsOnEdit({ data: [], type: "contacts" }));
  };

  const handleLabelClick = (e, value) => {
    setDefaultState()
    const labelData = async () => {
      let selectedItem = exports.exportStaticData.templates.find(x => x.template_id === value);
      if (selectedItem) {
        dispatch(exportTemplateSelectionHandler(selectedItem.template_name))
        dispatch(exportTemplateIdSelectionHandler(selectedItem.template_id))
        // user-defined templates
        if (selectedItem.is_editable && !selectedItem.is_system) {
          await fetchExportTemplateFields(dispatch, selectedItem.template_id);
          handleCheck("projects");
          handleCheck("companies");
          handleCheck("contacts");
          dispatch(updateUserDefinedCheckedFlags({
            isProjectsChecked: exports.exportStaticData["projects"]?.some(item => item.active),
            isCompaniesChecked: exports.exportStaticData["companies"]?.some(item => item.active),
            isCompaniesCheckActive: exports.exportStaticData["companies"]?.some(item => item.active),
            isContactsChecked: exports.exportStaticData["contacts"]?.some(item => item.active),
            isContactsCheckActive: exports.exportStaticData["contacts"]?.some(item => item.active),
          }));
        }
        // system-defined templates
        else if (!selectedItem.is_editable && selectedItem.is_system) {
          dispatch(updateUserDefinedCheckedFlags({
            isProjectsChecked: true,
            isCompaniesChecked: true,
            isContactsChecked: true,
          }));
          await fetchExportTemplateFields(dispatch, selectedItem.template_id);
          handleCheck("projects");
          handleCheck("companies");
          handleCheck("contacts");
          props.handleValidationCheck(true);
        }
        // crm templates
        else if (selectedItem.is_editable && selectedItem.is_system && selectedItem.template_id === 5) {
          await fetchExportTemplateFields(dispatch, selectedItem.template_id);
          handleProjectsFieldClick();
          handleCompaniesFieldClick();
          handleContactsFieldClick();
        }
        // bespoke templates
        else {
          props.handleValidationCheck(false);
          dispatch(updateUserDefinedCheckedFlags({
            isProjectsChecked: false,
            isCompaniesChecked: false,
            isContactsChecked: false,
            isCompaniesCheckActive: false,
            isContactsCheckActive: false,
          }));
          fetchExportTemplateFields(dispatch, selectedItem.template_id);
        }
      }
    }
    labelData()
  }

  async function handleEdit() {
    setIsEditTemplate(true)
    setIsCreateTemplate(false)
    setTemplateName(selectedValue)
    if (exports.isCopyFeed === false) {
      await fetchExportTemplateFields(dispatch, selectedValueId);
      handleSelectedItems(exports.exportStaticData)
      handleEditCheck("projects")
      handleEditCheck("companies")
      handleEditCheck("contacts")
    } else {
      handleSelectedItems(exports.exportStaticData)
      handleEditCheck("projects")
      handleEditCheck("companies")
      handleEditCheck("contacts")
    }
  }

  function handleEditCheck(type) {
    let selectList = []
    exports.exportStaticData[type]?.map(function (item, index) {
      if (item.active) {
        item = {
          ...item,
          active: true
        }
        selectList.push(item.value)
      }
    })
    dispatch(updateSelectAllExportFields({ data: selectList, type: type }))
    dispatch(saveAllExportFieldsOnEdit({ data: selectList, type: type }))
  }

  function setIsDeleteTemplateModalOpen(value) {
    setDeleteTemplateModalOpen(value)
  }

  function handleSelectedItems(exportStaticData) {
    // Check if contacts are selected
    const contactsCheck = exportStaticData["contacts"].some((item) => item.active);
    if (contactsCheck) {
      setIsContactsChecked(contactsCheck);
      setIsCompanyCheckActive(contactsCheck);
    }

    // Check if companies are selected
    const companiesCheck = exportStaticData["companies"].some((item) => item.active);
    if (companiesCheck) {
      setIsCompaniesChecked(companiesCheck);
      setIsCompanyCheckActive(companiesCheck);
      setIsProjectCheckActive(companiesCheck);
    }

    // Check if projects are selected
    const projectsCheck = exportStaticData["projects"].some((item) => item.active);
    if (projectsCheck) {
      setIsProjectsChecked(projectsCheck);
      setIsProjectCheckActive(projectsCheck);
    }

    // Update checked flags in parent component
    dispatch(updateUserDefinedCheckedFlags({
      isProjectsChecked: projectsCheck,
      isCompaniesChecked: companiesCheck,
      isContactsChecked: contactsCheck
    }))
  }

  async function handleDelete() {
    await deleteTemplate(selectedValueId, dispatch)
    let selectedItem = exports.exportStaticData.templates.find(x => x.template_name === exports.exportCriteria.setting.selectedTemplate)
    if (selectedItem && selectedItem.template_name) {
      dispatch(updateUserDefinedCheckedFlags({
        isProjectsChecked: true,
        isCompaniesChecked: true,
        isContactsChecked: true
      }))
      await fetchExportTemplateFields(dispatch, selectedItem.template_id)
      handleCheck("projects")
      handleCheck("companies")
      handleCheck("contacts")
      setTemplateName(selectedItem.template_name)
      setIsEditTemplate(false)
    }
    setIsDeleteTemplateModalOpen(false)
    dispatch(exportTemplateSelectionHandler("0"))
  }


  function handleUncheck(type) {
    exports.exportStaticData[type]?.map(function (item, index) {
      if (item.display) {
        clearCheckedList.push(item.value)
        item = {
          ...item, active: false
        }
      }
    })
    dispatch(updateClearAllExportFields(clearCheckedList, type))
  }

  const handleProjectsFieldClick = () => {
    if (!isProjectsChecked) {
      setIsProjectsChecked(true)
      updateCheckedFlags(
        {
          isProjectsChecked: true,
        },
        'projects',
        'project',
        exports.exportCriteria, dispatch
      );
      handleCheck('projects');
   
    } else {
      setIsProjectsChecked(false);
      updateCheckedFlags(
        {
          isProjectsChecked: false,
  
        },
        'projects',
        'project',
        exports.exportCriteria, dispatch
      );

      handleUncheck('projects');
   
    }
  };

  const handleCompaniesFieldClick = () => {
    if (!isCompaniesChecked) {
      setIsCompaniesChecked(true);
     setIsProjectCheckActive(true)
      updateCheckedFlags(
        {
          isCompaniesChecked: true,
          isProjectsCheckActive: true,
        },
        'companies',
        'project',
        exports.exportCriteria, dispatch
      );

      handleCheck('companies');
    } else {
      setIsCompaniesChecked(false);
      setIsProjectCheckActive(false);
      setIsProjectsChecked(false);

      updateCheckedFlags(
        {
          isCompaniesChecked: false,
          isProjectsCheckActive: false,
          isProjectsChecked: false,
        },
        'companies',
        'project',
        exports.exportCriteria, dispatch
      );

      handleUncheck('companies');
      handleUncheck('projects');
    }
  };

  const handleContactsFieldClick = () => {
    if (!isContactsChecked) {
      setIsContactsChecked(true);
      setIsCompanyCheckActive(true)

      updateCheckedFlags(
        {
          isContactsChecked: true,
          isCompaniesCheckActive: true
        },
        'contacts',
        'project',
        exports.exportCriteria, dispatch
      );

      handleCheck('contacts');
      props.handleValidationCheck(true)
    } else {
      setIsContactsChecked(false);
      setIsCompaniesChecked(false);
      setIsCompanyCheckActive(false)
      setIsProjectCheckActive(false)
      setIsProjectsChecked(false)
      setIsCreateTemplate(false)

      updateCheckedFlags(
        {
          isContactsChecked: false,
          isCompaniesChecked: false,
          isCompaniesCheckActive: false,
				  isProjectsCheckActive: false,
				  isProjectsChecked: false
        },
        'contacts',
        'project',
        exports.exportCriteria, dispatch
      );
      handleUncheck('contacts');
      handleUncheck('companies');
      handleUncheck('projects');
      props.handleValidationCheck(false)
    }
  };

  const renderDropdownItems = () => {
    let items = []
    if (exports?.exportStaticData?.templates?.some(item => item.is_editable && !item.is_system)) {
      items.push(<DropdownItem className="dropdown-header">My templates</DropdownItem>);
    }
    exports?.exportStaticData?.templates?.forEach((item, i) => {
      if (item.is_editable && !item.is_system) {
        items.push(<DropdownItem onClick={(e) => handleLabelClick(e, item.template_id)} key={i + 1} value={item.template_name} data-label={item.template_name}>{item.template_name}</DropdownItem>);
      }
    });
    items.push(<DropdownItem className="dropdown-header">Standard templates</DropdownItem>);

    exports?.exportStaticData?.templates?.forEach((item, i) => {
      if (!item.is_editable && item.is_system) {
        items.push(<DropdownItem onClick={(e) => handleLabelClick(e, item.template_id)} key={i + 1} value={item.template_name} data-label={item.template_name}>{item.template_name}</DropdownItem>);
      }
    });
    items.push(<DropdownItem className="dropdown-header">Custom - select your own fields</DropdownItem>);

    exports?.exportStaticData?.templates?.forEach((item, i) => {
      if (item.is_editable && item.is_system) {
        items.push(<DropdownItem onClick={(e) => handleLabelClick(e, item.template_id)} key={i + 1} value={item.template_name} data-label={item.template_name}>{item.template_name}</DropdownItem>);
      }
    });
    return items
  }

  function handleTemplateName(event) {
    setTemplateName(event.target.value)
    props.handleTemplateNameCheck(event.target.value)
    if (event.target.value.length > 0) {
      props.handleSetExportTemplateTag()
    }
  }

  const handleCreate = () => {
    if (!isCreateTemplate) {
      setIsCreateTemplate(true)
      setIsEditTemplate(false)
    } else {
      setIsCreateTemplate(false)
    }
  }

  function getFieldsArray() {
    let fieldsArray = []
    let fields = exports.exportCriteria.setting.fields;
    exports.exportStaticData.projects?.map(function (option, index) {
      fieldsArray.push({
        field_id: option.id,
        seqno: option.seq_no,
        is_selected: fields.projects && fields.projects.length === 0 ? false : option.active
      })
    })
    exports.exportStaticData.companies?.map(function (option, index) {
      fieldsArray.push({
        field_id: option.id,
        seqno: option.seq_no,
        is_selected: fields.companies && fields.companies.length === 0 ? false : option.active
      })
    })
    exports.exportStaticData.contacts?.map(function (option, index) {
      fieldsArray.push({
        field_id: option.id,
        seqno: option.seq_no,
        is_selected: fields.contacts && fields.contacts.length === 0 ? false : option.active
      })
    })
    return fieldsArray
  }


  async function handleSaveClick(event) {
    setShowSavetemplateButton(false)
    const fieldsArray = getFieldsArray()
    if (!isCreateTemplate) {
      await updateTemplate(selectedValueId,
        {
          template_name: templateName,
          is_system: false,
          field_ids: fieldsArray
        }, dispatch)
      setIsEditTemplate(false)
      setShowSavetemplateButton(true)
    } else {
      await createNewTemplate({
        template_name: templateName,
        is_system: false,
        field_ids: fieldsArray
      }, dispatch)
      setShowSavetemplateButton(true)
    }
  }

  return (
    <>
      <div className="input-container">
        <div className="has-error">
          <span className='title'>Choose an existing template or select custom fields</span>
          <span className="hint-text d-none">choose a particular template to pre-select the fields chosen for the export</span>
          <div className="select-tag d-flex align-items-center">
            <div className="btn-group">
              <Dropdown className='custom-dropdown' toggle={() => { setTemplateStatus(!templateStatus) }} isOpen={templateStatus}>
                <DropdownToggle caret>
                  {selectedValue}
                </DropdownToggle>
                <DropdownMenu>
                  {renderDropdownItems()}
                </DropdownMenu>
              </Dropdown>
            </div>
            {
              isUserTemplate &&
              <>
                <button onClick={handleEdit} className="ml-2 btn btn-sm btn-link">Edit</button>
                <button onClick={() => setIsDeleteTemplateModalOpen(true)} className="ml-2 btn btn-sm btn-link red-link">Delete</button>
              </>
            }
          </div>
          <div className="val-err-tooltip"></div>
        </div>
        <div className='create-template-block p-3 d-none'></div>
      </div>
      <div className={`input-container mb-0 ${((isUserTemplate && !isEditTemplate) || isSystemTemplate) ? 'hide' : ''}`}>
        <div className="create-template-block p-3">
          <span className="pb-0">Select fields</span>
          <span className="hint-text">Confirm the objects and fields you would like to export. For example, include Company and Contact fields for <a href="http://help.barbour-abi.com/barbour-abi/export/mail-merge-sample-templates" target="_blank"> mail merge.</a></span>
          <div className={`check-box-button`}>
            <div>
              <Input type="checkbox" checked={isContactsChecked} id='contacts' onChange={handleContactsFieldClick} />
              <Label for="contacts">Contacts</Label>
              <div>{props?.fieldsErrorMsg}</div>
            </div>
            {
              isContactsChecked ? (
                <SelectFieldsComponent type="contacts" component="contacts" />
              ) : ('')
            }
          </div>

          <div className={`check-box-button ${isCompaniesCheckActive ? "" : "disabled"}`}>
            <Input type="checkbox" checked={isCompaniesChecked} disabled={!isCompaniesCheckActive} id='companies' onChange={handleCompaniesFieldClick} />
            <Label for="companies">Companies</Label>
            {
              isCompaniesChecked ? (
                <SelectFieldsComponent type="companies" component="contacts" />
              ) : ('')
            }
          </div>
          <div className={`check-box-button ${isProjectsCheckActive ? "" : "disabled"}`}>
            <Input type="checkbox" checked={isProjectsChecked} disabled={!isProjectsCheckActive} id='projects' onChange={handleProjectsFieldClick} />
            <Label for="projects">Projects</Label>
            {
              isProjectsChecked ? (
                <SelectFieldsComponent type="projects" component="contacts" />
              ) : ('')
            }
          </div>
          <div className="input-container save-template px-4 py-3 mt-2 mb-0">
            <span>Save selected fields as a template (Optional)</span>
            <div className={`check-box-button ${!(exports?.isProjectsChecked && exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isContactsChecked) || isEditTemplate ? 'disabled' : ''}`}>
              <Input type="checkbox" checked={isCreateTemplate || isEditTemplate} disabled={isEditTemplate || !(exports?.isProjectsChecked && exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isContactsChecked)} id='template_check' onChange={handleCreate} />
              <Label for="template_check">Save as template</Label>
            </div>
            <div>
              <Input
                type="text"
                autoComplete="off"
                placeholder="Enter template name"
                value={templateName}
                id="templateName" onChange={handleTemplateName}
                maxLength="60" disabled={isEditTemplate || !isCreateTemplate || !(exports?.isProjectsChecked && exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isContactsChecked) ? true : false}
              />
              <div>{props.exportTemplateMsg}</div>
            </div>
            {isCreateTemplate ?
              <button id="save-btn" disabled={(templateName === null || templateName === '' || isEditTemplate || !isCreateTemplate || !showSavetemplateButton || !(exports?.isProjectsChecked && exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isContactsChecked))} className={"outline-btn btn-save mt-3"} onClick={handleSaveClick}>Save template</button>
              :
              <button id="save-btn" disabled={(templateName === null || templateName === '' || !isEditTemplate || isCreateTemplate || !showSavetemplateButton || !(exports?.isProjectsChecked && exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isCompaniesChecked && exports?.isContactsChecked || exports?.isContactsChecked))} className={"outline-btn btn-save mt-3"} onClick={handleSaveClick}>Save template</button>
            }
          </div>
          <DeleteTemplateModal
            isOpen={deleteTemplateModalOpen}
            handleDelete={handleDelete}
            handleCancel={() => setIsDeleteTemplateModalOpen(false)} />
          <div className="required">{props.saveSearchMsg}</div>
        </div>
      </div>
    </>
  )
}

export default SelectContactsFieldStep