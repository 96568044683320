import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { shareInformationDetails } from '../../../redux/features/user/userslice';
import { getFormatedDate } from '../../../utils';
import { updateListFromDetailsBack, updateListFromDetailsBackOfJustAsk, updateMyleadsListFromDetailsBack } from '../../../utils/commonFunctions';
import Icon from '../../common/js/icon';
import { fetchArchieveList, fetchFavouritesResults, fetchSharesResults, fetchTagDetailsById, fetchUpdatesResults } from '../../myleads/apiclient';
import { updateMyLeadOffsetLimitValue } from '../../myleads/reducer';
import { fetchRolesForCompanyId, fetchSearchListCount } from '../../search/apiclient';
import { preProcessorQuery } from '../../search/filters/preProcessorQuery';
import { updateOffsetLimit } from '../../search/filters/redux/reducer';
import { updateOffsetLimit as jAskUpdateOffsetLimit } from '../../justask/search/filters/redux/reducer';
import { resetRelationalEntititySelectionCount, updateBackObjectId, updateHierarchyBack, updateObjectType } from '../../search/redux/reducer';
import { updateHierarchyBack as jAskUpdateHierarchyBack, updateObjectType as jAskUpdateObjectType, updateBackObjectId as jAskUpdateBackObjectId, resetRelationalEntititySelectionCount as jAskResetRelationalEntititySelectionCount } from '../../justask/search/redux/reducer';
import { collabList, notesDetailForObjectId, rolesForProject, updateEventInDetails, updateShowMoreOffsetLimit, updateTabDetails } from '../redux/reducer';
import { fetchRolesForCompanyId as jAskFetchRolesForCompanyId, fetchSearchListCount as jAskFetchSearchListCount } from '../../justask/search/apiclient';

const NavigationTab = (props) => {
    const search = useSelector((state) => state.search)
    const myleads = useSelector((state) => state.myleads)
    const details = useSelector((state) => state.project)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const filter = useSelector((state) => state.filter)
    const justask = useSelector((state) => state.justAsk)
    const jAskFilter = useSelector((state) => state.justAskFilter)
    const jAskPersist = useSelector((state) => state.justAskPersist)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [index, setIndex] = useState(location.state?.index);
    const [fetchNext, setFetchNext] = useState(false);
    const [showMoreResult, setShowMoreResult] = useState(false);
    const [nextButton, setNextButton] = useState(false)
    const [leadFlags, setLeadFlags] = useState(location.state?.list)
    const [showMoreActive, setShowMoreActive] = useState(false)
    const isPrevListCalendarEvents = location.state?.prevNextList === "calendarEvents" ? true : false;

    useEffect(() => {
        setIndex(location.state?.index)
    }, [location])

    const handleBack = () => {
        let backSearchList = ['searchList', 'selectedSearchList', 'companyHierarchicalSearchList', 'contactHierarchicalSearchList', 'projectHierarchicalSearchList', 'groupedCompanyProjects'].includes(location.state?.prevNextList)
        let backMyleadsList = ['myLeadsUpdates', 'myLeadsFavourites', 'myLeadsHistory', 'myLeadsTags', 'myLeadsShares', 'myLeadsArchive', 'myLeadsNotes',
            'projectHierarchicalMyLeadsFavourites', 'projectHierarchicalMyLeadsHistory', 'projectHierarchicalMyLeadsTags', 'projectHierarchicalMyLeadsShares', 'projectHierarchicalMyLeadsArchive'].includes(location.state?.prevNextList)
        let backJAskSearchList = ['jAskSearchList', 'selectedjAskSearchList', 'companyHierarchicalSearchListJA', 'projectHierarchicalSearchListJA', 'JAskGroupedCompanyProjects'].includes(location.state?.prevNextList)
        let detailsData = []
        detailsData.push(details.detailsPage)
        detailsData.push(details.userActivityCount)
        if (backJAskSearchList) {
            dispatch(jAskUpdateBackObjectId(location.pathname.split('/')[3]))
            dispatch(jAskUpdateObjectType('project'))
            dispatch(jAskUpdateHierarchyBack(true))
            updateListFromDetailsBackOfJustAsk('project', justask, dispatch, detailsData)
            dispatch(jAskResetRelationalEntititySelectionCount())
            const routingState = {
                ...location.state?.previousState,
                activeTab: location.state?.tab
            };
            location.state?.prevNextList === 'JAskGroupedCompanyProjects' ? navigate('/app/justask', { state: routingState }) : navigate('/app/justask')
        } else if (backSearchList) {
            updateObjectdata()
            updateListFromDetailsBack('project', search, dispatch, detailsData)
            const routingState = {
                ...location.state?.previousState,
                activeTab: location.state?.tab
            };
            location.state?.prevNextList === 'groupedCompanyProjects' ? navigate('/app/list', { state: routingState }) : navigate('/app/list')
        } else if (backMyleadsList) {
            updateObjectdata()
            updateMyleadsListFromDetailsBack('project', search, myleads, dispatch, detailsData, location.state?.prevNextList)
            let activeTab = location.state?.prevNextList === 'myLeadsUpdates' ? 'updates'
                : ['myLeadsFavourites', 'projectHierarchicalMyLeadsFavourites'].includes(location.state?.prevNextList) ? 'favourite'
                    : ['myLeadsHistory', 'projectHierarchicalMyLeadsHistory'].includes(location.state?.prevNextList) ? 'history'
                        : ['myLeadsTags', 'projectHierarchicalMyLeadsTags'].includes(location.state?.prevNextList) ? 'tags'
                            : ['myLeadsShares', 'projectHierarchicalMyLeadsShares'].includes(location.state?.prevNextList) ? 'shares'
                                : ['myLeadsArchive', 'projectHierarchicalMyLeadsArchive'].includes(location.state?.prevNextList) ? 'archive'
                                    : 'notes'
            navigate("/app/myleads#" + activeTab)
        } else if (location.state?.prevNextList === 'companyHierarchicalLeagueTableList') {
            updateObjectdata()
            navigate('/app/leaguetable')
        } else {
            updateObjectdata()
            const routingState = {
                ...location.state?.previousState,
                activeTab: location.state?.tab
            };
            if (location.state?.prevNextList === 'companyProjects' || location.state?.prevNextList === 'relatedCompaniesJointProjects') {
                navigate(`/app/company/${location.state?.project_id}/${location.state?.company_id}`, { state: routingState })
            } else if (location.state?.prevNextList === 'groupedCompanies') {
                navigate(`/app/project/${location.state?.project_id}`, { state: routingState })
            } else if (location.state?.prevNextList === 'groupedPeopleProjects') {
                navigate(`/app/person/${location.state?.person_id}`, { state: routingState })
            }
        }
    }

    const updateObjectdata = () => {
        dispatch(updateBackObjectId(location.pathname.split('/')[3]))
        dispatch(updateObjectType('project'))
        dispatch(updateHierarchyBack(true))
        dispatch(resetRelationalEntititySelectionCount())
    }

    useEffect(() => {
        handleListAndShowMore(false)
    }, [location])

    useEffect(() => {
        if (showMoreResult) {
            let type = location.state?.prevNextList === 'searchList' ? searchPersist.appliedContext :
                searchPersist.appliedContext === "project" ? 'company' : 'project'
            var formValues = preProcessorQuery('', filter.filtersState, type, search, searchPersist)
            filter.filtersState[type].records > 50 && searchPersist.appliedContext !== 'project' ?
                dispatch(updateOffsetLimit({ offset: 0, records: 50 })) :
                location.state?.prevNextList === 'companyHierarchicalSearchList' ?
                    fetchRolesForCompanyId(search.objectId, search, searchPersist, dispatch)
                    : fetchSearchListCount(formValues, dispatch, search, settings);
            setShowMoreResult(false)
        }
    }, [filter.filtersState[searchPersist.appliedContext].offset]);

    useEffect(() => {
        if (showMoreResult) {
            if (location.state?.prevNextList === 'myLeadsUpdates') {
                fetchUpdatesResults(searchPersist, myleads, dispatch, settings);
            }
            if (location.state?.prevNextList === 'myLeadsFavourites') {
                fetchFavouritesResults(searchPersist, myleads, dispatch, settings);
            }
            if (location.state?.prevNextList === 'myLeadsShares') {
                fetchSharesResults(searchPersist, myleads, dispatch, settings);
            }
            if (location.state?.prevNextList === 'myLeadsTags' && myleads.tagId !== 0) {
                fetchTagDetailsById(myleads.tagId, myleads, searchPersist, dispatch, '', settings)
            }
            if (location.state?.prevNextList === "myLeadsArchive") {
                fetchArchieveList(searchPersist, myleads, dispatch)
            }
            setShowMoreResult(false)
        }
    }, [myleads.offsetLimitValue.offset]);

    useEffect(() => {
        if (showMoreResult) {
            let type = location.state?.prevNextList === 'jAskSearchList' ? jAskPersist.appliedContext :
                jAskPersist.appliedContext === "project" ? 'company' : 'project'
            var formValues = preProcessorQuery('', jAskFilter.filtersState, type, justask, jAskPersist)
            jAskFilter.filtersState[type].records > 50 && jAskPersist.appliedContext !== 'project' ?
                dispatch(jAskUpdateOffsetLimit({ offset: 0, records: 50 })) :
                location.state?.prevNextList === 'companyHierarchicalSearchListJA' ?
                    jAskFetchRolesForCompanyId(justask.objectId, justask, jAskPersist, dispatch)
                    : jAskFetchSearchListCount(formValues, dispatch, justask, settings, jAskPersist.query);
            setShowMoreResult(false)
        }
    }, [jAskFilter.filtersState[jAskPersist.appliedContext].offset]);

    useEffect(() => {
        if (fetchNext) {
            handleListAndShowMore(true)
        }
    }, [search.searchList, search.listOfSelectedObjectIds, search.projectsForCompanyId, myleads.searchList, , myleads.updatesSearchList,
    justask.searchList, justask.listOfSelectedObjectIds, justask.projectsForCompanyId])

    useEffect(() => {
        if (fetchNext) {
            handleNext()
        }
        setFetchNext(false);
    }, [leadFlags])

    useEffect(() => {
        if (fetchNext) {
            manageMoreResult();
        }
    }, [fetchNext]);

    const handleListAndShowMore = (isListRefresh) => {
        let list = leadFlags
        if (isListRefresh) {
            list = location.state?.prevNextList === 'searchList' ? search?.searchList
                : location.state?.prevNextList === 'selectedSearchList' ? search?.listOfSelectedObjectIds
                    : location.state?.prevNextList === 'projectHierarchicalSearchList' ? [search.projectRowData]
                        : location.state?.prevNextList === 'companyHierarchicalSearchList' ? search.projectsForCompanyId
                            : location.state?.prevNextList === 'contactHierarchicalSearchList' ? search.peopleProjectsList
                                : location.state?.prevNextList === 'companyProjects' ? details.projectDataForRoleId
                                    : location.state?.prevNextList === 'relatedCompaniesJointProjects' ? details?.jointProjects?.projects
                                        : location.state?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                                            : location.state?.prevNextList === 'jAskSearchList' ? justask?.searchList
                                                : location.state?.prevNextList === 'selectedjAskSearchList' ? justask?.listOfSelectedObjectIds
                                                    : location.state?.prevNextList === 'projectHierarchicalSearchListJA' ? [justask.projectRowData]
                                                        : location.state?.prevNextList === 'companyHierarchicalSearchListJA' ? justask.projectsForCompanyId
                                                            : myleads?.searchList
            setLeadFlags(list)
        }
        let backMyleadsList = ['myLeadsFavourites', 'myLeadsTags', 'myLeadsShares', 'myLeadsArchive'].includes(location.state?.prevNextList)
        let showMoreActiveFlag = location.state?.prevNextList === 'searchList' ? search.isShowMoreActive
            : location.state?.prevNextList === 'selectedSearchList' ? search.listOfSelectedObjectIds && search.listOfSelectedObjectIds.length !== search.listOfSelectedObjectIdsCount
                : ['projectHierarchicalSearchList', 'projectHierarchicalSearchListJA'].includes(location.state?.prevNextList) ? false
                    : location.state?.prevNextList === 'companyHierarchicalSearchList' ? search?.projectsForCompanyIdCount !== search?.projectsForCompanyId?.length
                        : backMyleadsList ? myleads.isShowMoreActive
                            : location.state?.prevNextList === 'jAskSearchList' ? justask.isShowMoreActive
                                : location.state?.prevNextList === 'selectedjAskSearchList' ? justask.listOfSelectedObjectIds?.length !== search.listOfSelectedObjectIdsCount
                                    : location.state?.prevNextList === 'companyHierarchicalSearchListJA' ? justask?.projectsForCompanyIdCount !== justask?.projectsForCompanyId?.length
                                        : false
        showMoreActiveFlag = location.state?.from && location.state?.from === 'mapClick' ? false : showMoreActiveFlag
        setShowMoreActive(showMoreActiveFlag)
        setNextButton(list?.length === 0 || list === undefined ? true :
            (!showMoreActiveFlag && list?.length > 0
                && list[list.length - 1].project_id?.toString() === location.pathname.split('/')[3])
            && list.length - 1 === location.state?.index)
    }

    const handlePrevious = () => {
        let prevObject = {}
        resetData();
        if (leadFlags && leadFlags[index - 1] != undefined) {
            prevObject = leadFlags[index - 1]
            const routingState = {
                ...location.state,
                index: index - 1,
                lastRelease: getFormatedDate(location.state?.lastRelease, 'DD-MMM-YYYY'),
                dist: location.dist,
            };
            navigate(`/app/project/${prevObject.project_id}`, { state: routingState })
        }
    }

    const handleNext = () => {
        let nextObject = {};
        resetData();
        if (leadFlags && leadFlags[index + 1] !== undefined) {
            nextObject = leadFlags[index + 1];
            const routingState = {
                ...location.state,
                index: index + 1,
                lastRelease: getFormatedDate(location.state?.lastRelease, 'DD-MMM-YYYY'),
                dist: location.dist,
                list: leadFlags
            };
            navigate(`/app/project/${nextObject.project_id}`, { state: routingState })
            let detailsData = [details.detailsPage, details.userActivityCount];
            const prevNextList = location.state?.prevNextList;
            if (prevNextList === 'jAskSearchList') {
                updateListFromDetailsBackOfJustAsk('project', justask, dispatch, detailsData);
            } else if (prevNextList === 'searchList') {
                updateListFromDetailsBack('project', search, dispatch, detailsData);
            }

        } else {
            if (showMoreActive) {
                setFetchNext(true);
                setShowMoreResult(true)
            }
        }

    };

    const manageMoreResult = () => {
        let newOffset = ''
        if (['groupedCompanyProjects', 'jAskGroupedCompanyProjects'].includes(location.state?.prevNextList)) {
            let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
            dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
        } else if (location.state?.prevNextList === 'myLeadsFavourites' || location.state?.prevNextList === 'myLeadsTags' || location.state?.prevNextList === 'myLeadsHistory' || location.state?.prevNextList === 'myLeadsArchive' || location.state?.prevNextList === 'myLeadsShares' || location.state?.prevNextList === 'myLeadsUpdates') {
            newOffset = myleads.offsetLimitValue.offset + myleads.offsetLimitValue.records;
            dispatch(updateMyLeadOffsetLimitValue({ offset: newOffset, records: myleads.offsetLimitValue.records }));
        } else if (location.state?.prevNextList === 'jAskSearchList') {
            newOffset = jAskFilter.filtersState[jAskPersist.appliedContext].offset + jAskFilter.filtersState[jAskPersist.appliedContext].records;
            dispatch(jAskUpdateOffsetLimit({ offset: newOffset, records: jAskFilter.filtersState[jAskPersist.appliedContext].records }))
            dispatch(jAskUpdateHierarchyBack(false))
        } else {
            newOffset = filter.filtersState[searchPersist.appliedContext].offset + filter.filtersState[searchPersist.appliedContext].records;
            dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[searchPersist.appliedContext].records }))
            dispatch(updateHierarchyBack(false))
        }
    }

    const resetData = () => {
        dispatch(rolesForProject({ roleGroups: [] }));
        dispatch(updateTabDetails([]));
        dispatch(collabList([]));
        dispatch(notesDetailForObjectId([]));
        dispatch(updateEventInDetails({ events: [] }));
        let sharedPayload = { objectType: 'project', sharedInfo: [] };
        dispatch(shareInformationDetails(sharedPayload));
    }

    return (
        !isPrevListCalendarEvents ? (
            <div className="dp-nav-buttons-wrapper d-flex justify-content-end">
                <div>
                    <button
                        className='user-ctrl-btn back-to-resultpage-button' disabled={index === undefined || ["calenderNotificationList", "NotificationList"].includes(location.state?.prevNextList)} onClick={handleBack}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" />Back</button>
                </div>
                {!['myLeadsUpdates', 'myLeadsNotes'].includes(location.state?.prevNextList) ?
                    <div className="right-side-button d-inline-flex">
                        <button
                            className='user-ctrl-btn dp-prev-button' disabled={index === 0 || index === undefined} onClick={handlePrevious}><Icon color="#053874" size={20} icon="icon-gen-chevron" className="me-1 flip-vertically" />Previous</button>
                        <button
                            className='user-ctrl-btn dp-next-button' disabled={nextButton} onClick={handleNext}>Next<Icon color="#053874" size={20} icon="icon-gen-chevron" className="ms-1" /></button>
                    </div> :
                    <div></div>
                }
            </div>
        ) : ''
    );
}

export default NavigationTab;
