import React, { useState, useEffect } from 'react';
import { Media, Button, Input, Row } from 'reactstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import CreatableSelect from 'react-select'
import { addMemberToTeam, editTeamMember, removeMemberFromTeam } from './apiclient';
import { useDispatch,useSelector } from 'react-redux';
import { getInitials } from '../../utils';
import { updateSelectedTeamId } from './reducer';


const TeamsList = (props) => {
  const [teamId, setTeamId] = useState(props?.teamId);
  const [userId, setUserId] = useState('');
  const [addClass, setAddClass] = useState(false);
  const [member, setMember] = useState(null);
  const [team, setTeam] = useState(props.team);
  const [teamName, setTeamName] = useState(props.team.name);
  const [isTeamEdit, setIsTeamEdit] = useState(false);
  const [teamNo, setTeamNo] = useState(props.team.team_no);
  const [isAddNewTeamMember, setIsAddNewTeamMember] = useState(props.isEditTeam);
  const [options, setOptions] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [autoCompleteUserNo, setAutoCompleteUserNo] = useState('');
  const [isDuplicateTeamName, setIsDuplicateTeamName] = useState(false);
  const teams =useSelector(state=>state.teams)
  const project=useSelector(state=>state.project)
  const user = useSelector(state => state.user)
  const userData = useSelector((state) => state.user.user);
  const dispatch=useDispatch()


  useEffect(() => {
    setTeam(props.team)
    setIsAddNewTeamMember(props?.isEditTeam)
    setTeamName(isTeamEdit?teamName:props.team.name)
    setTeamNo(isTeamEdit?teamNo:props.team.team_no)
  }, [props?.team,props?.isEditTeam,props?.team?.name])


  useEffect(() => {
    setTeamId(teams?.selectedTeamId)
  }, [teams?.selectedTeamId])
  
  useEffect(() => {
    setIsDuplicateTeamName(teams?.isTeamAlreadyExists)
    if(teams?.isTeamAlreadyExists!=null &&teams?.isTeamAlreadyExists === false ){
      setIsTeamEdit(false)
    }
}, [teams?.isTeamAlreadyExists])

 useEffect(()=>{
  let recipientOptions=[];
  if (project?.fetchShareRecipients ?.length > 0) {
    project?.fetchShareRecipients?.map(user => {
        recipientOptions.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email, sharedType: 'U' })
    })
    setOptions(recipientOptions)
}

},[project?.fetchShareRecipients])


  function handleTeamNameChange(event) {
   setTeamName(event.target.value);
  }

  function handleEditClick(event, team_no) {
    setIsTeamEdit(true);
    dispatch(updateSelectedTeamId(team_no))
    props.closeAddTeamOnEditClick(false);
  }

  function handleTeamUpdate() {
    let payload = {
      teamno: parseInt(teamNo),
      team_name: teamName,
    };
    if (teamName.trim().length > 0) {
      editTeamMember(payload,userData,dispatch);
    }
    setIsTeamEdit(false);
    setIsDuplicateTeamName(false)
  
  }

  function handleEditCancel() {
    setTeamName(props.team.name);
    setIsTeamEdit(false);
    setIsDuplicateTeamName(false)
    dispatch(updateSelectedTeamId(null))
   
  }

  function handleAddNewMember(event) {
    props.closeAddTeamOnEditClick(false);
    setIsAddNewTeamMember(!isAddNewTeamMember);
    dispatch(updateSelectedTeamId(null))
    props.handleAddMembersToTeamToggle(!isAddNewTeamMember, teamNo);
  }

  function handleRemoveMemberFromTeam(event) {
  
    let payload = {
      user_no: member.user_id,
      team_no: teamNo,
      member_name: member.first_name + ' ' + member.last_name,
    };
    removeMemberFromTeam(payload,userData,dispatch)
    props.handleAddMembersToTeamToggle(true, teamNo);
  }

  function handleAddNewMemberSubmit(item, event) {
    let payload = {
      user_no: item.value,
      member_name: item.label,
      team_no: teamNo,
    };
    let userAlreadyExistsInTeam = false;
    if (props.team.users && props.team.users.length > 0) {
      props.team.users.forEach((user) => {
        if (props.team.team_no == teamNo && user.user_id == item.value) {
          userAlreadyExistsInTeam = true;
        }
      });
    }
    if (!userAlreadyExistsInTeam) {
      addMemberToTeam(payload,userData,dispatch)
    }
    setAutocompleteValue('');
    setAutoCompleteUserNo(item.value);
    props.handleAddMembersToTeamToggle(true, teamNo);
   
  }

  function handleModal(event, user) {
    setUserId(user.user_id);
    setMember(user);
    event.target.nextSibling.className = 'user-' + user.user_id + ' show';
  }

  function handleCancel(event, user) {
    setUserId(user.user_id);
    event.target.parentNode.className = 'user-' + user.user_id + ' hide';
  }

  function handleKeyDown(event) {
    if (event.keyCode == 13) {
      handleTeamUpdate(event);
    }
  }

  function shareOptionsRenderer(option) {
    return (
      <span key={option.value} className="user-info-block">
        <a className="user-dropdown-icon">{option.initials}</a>
        <span className="user-info-wrapper">
          {option.label}
          <a className="email-address">{option.email}</a>
        </span>
      </span>
    );
  }

  function handleOnChange(value) {
    setAutocompleteValue(value);
    handleAddNewMemberSubmit(value);
  }

  const teamInitials = (value) => {
    if (value.length == 0) {
      return '';
    } else {
      var teamName = value.trim().split(' ');
      var teamInitial = teamName.length > 1 ? teamName[0][0] + teamName[1][0] : teamName[0][0];
      return teamInitial.toUpperCase();
    }
  }

  function renderLoader() {
    const defaultTeamRenderer = [1, 2, 3];
    return (
      <div>
        {defaultTeamRenderer.map(() => {
          return (
            <div className="team-list-container placeholder" key={Math.random()}>
              <span className="preloader"></span>
              <Row>
                <div className="circle"></div>
                <div className="header">
                  <div className="sub-header"></div>
                </div>
                <div className="right">
                  <Row>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <button className="btn btn-secondary"></button>
                  </Row>
                </div>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

  const renderContent = () => {
    return (

      <div className="team-list-container" key={props.index}>
        <div className="media">
          <a href="#" class="media-left">{teamInitials(teamName)}</a>
          <div className="media-body">
          <h4 className="media-heading">
            <div className="d-flex flex-wrapper justify-content-between">
              <div>
            {teamId != "" && teamId === team.team_no ? (
              <div className="edit-team-container d-flex align-items-start">
                <div>
                  <Input
                    type="text"
                    name="teamName"
                    className="me-1 edit-text"
                    maxLength="30"
                    placeholder="Enter Team name"
                    required
                    id={team.team_no}
                    value={teamName}
                    onChange={handleTeamNameChange}
                    onKeyDown={handleKeyDown}
                  />
                  {isDuplicateTeamName ? <p className="text-error">Team name already exists</p> : ''}
                </div>
                <div className="edit-title-button">
                  <Button type="primary" className="save-team" onClick={handleTeamUpdate}>
                    +
                  </Button>
                  <Button
                    type="button"
                    className="cancel-team btn btn-secondary"
                    onClick={(event) => handleEditCancel(event, team.team_no)}
                  >
                    cancel
                  </Button>
                </div>
              </div>
            ) : (

              <strong className="team-name">
                {team.name}
                <button
                  type="button"
                  className="edit-button btn btn-secondary"
                  onClick={(event) => handleEditClick(event, team.team_no)}
                ></button>
              </strong>
            )}
            {team.users && (
              <p className="team-member" onClick={handleAddNewMember}>
                {team.users ? team.users.length : 0} {team.users && team.users.length > 1 ? 'Members' : 'Member'}
              </p>
            )}
            </div>
            <div className="d-flex align-items-center">
            {team.users && team.users.length > 0 ? (
              <div className="team-member-list me-2">
                {team.users &&
                  team.users.map(function (user, index) {
                    return index < 3 ? (
                      <span key={'user' + index}>
                        <span className="team-member-name"  data-tooltip-id={'' + user.user_id}>
                          { getInitials(user.first_name + " " + user.last_name)}
                        </span>
                        <ReactTooltip id={'' + user.user_id} place="top" type="dark">
                          <p>{user.first_name + ' ' + user.last_name}</p>
                        </ReactTooltip>
                      </span>
                    ) : (
                      ''
                    );
                  })}

                {team.users.length - 3 > 0 ? (
                  <span className="more-team-member"  data-tooltip-id="ALL" onClick={handleAddNewMember}>
                    +{team.users.length - 3}
                  </span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <div className="user-button">
              <button
                type="button"
                className={`outline-btn add-team-user ${isAddNewTeamMember ? 'active' : ''}`}
                onClick={handleAddNewMember}
              >
                {isAddNewTeamMember === true ? 'Close' : ' + Users'}
              </button>
            </div>
            </div>
            
            </div></h4>
          </div>
        </div>

        <div className={`add-new-team-user ${isAddNewTeamMember ? 'show' : 'hide'}`}>
          <div className="share-details-wrapper">
            <CreatableSelect
              cache={false}
              options={options}
              formatOptionLabel={(option) => shareOptionsRenderer(option)}
              onChange={handleOnChange}
              value={autocompleteValue}
              clearable={false}
              placeholder={<span className="label-placeholder"> Search & add users to this team </span>}
            />
          </div>
          <div>
            {team.users &&
              team.users.map(function (user, index) {
  
                return (
                  <div className="users-list" key={index}>
                    <span key={'team-user-' + index}>
                      <a className="user-profile-icon">{ getInitials(user.first_name + " " + user.last_name)}</a>
                      <a className="user-name">{user.first_name + ' ' + user.last_name}</a>
                      <div className="delete-box">
                        <button
                          type="button"
                          className="delete-button btn btn-secondary"
                          onClick={(event) => {
                            handleModal(event, user);
                          }}
                          data-tip
                          data-for="delete"
                        >
                          delete
                        </button>
                        {userId === user.user_id ? (
                          <div className={`user-${user.user_id} show`}>
                            <Button className="outline-btn btn-save-cancel" onClick={(event) => handleCancel(event, user)}>
                              Cancel
                            </Button>
                            /
                            <Button className="primary-btn btn-save-delete" onClick={handleRemoveMemberFromTeam}>
                              Delete
                            </Button>{' '}
                          </div>
                        ) : (
                          <div className={`user-${user.user_id} hide`}>
                            <Button className="outline-btn btn-save-cancel" onClick={(event) => handleCancel(event, user)}>
                              Cancel
                            </Button>
                            /
                            <Button className="primary-btn btn-save-delete" onClick={handleRemoveMemberFromTeam}>
                              Delete
                            </Button>{' '}
                          </div>
                        )}
                      </div>
                      <p className="email-address">{user.email}</p>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>

      </div>
    );
  }

  return props.team && props.team.name ? renderContent() : renderLoader();
};

export default TeamsList;