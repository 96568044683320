import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import SearchFormIndex from './search';

const RightPanel = () => {
  const justAsk = useSelector((state) => state.justAsk)
  const [jAskListCount, setJAskListCount] = useState(0)

  useEffect(() => {
    setJAskListCount(justAsk.searchList ? justAsk.searchList.length : 0)
  }, [justAsk.searchList])

  return (
    <>
      {jAskListCount === 0 ? (
        <Container className="content">
          <Row>
            <Col xs="12" className="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" className="logo">
                <path fillRule="evenodd" clipRule="evenodd" d="M26.4336 54.9711L30.3979 52.8569H54.1479C56.357 52.8569 58.1479 51.066 58.1479 48.8569V21.1426H64.7193C66.9285 21.1426 68.7193 22.9334 68.7193 25.1426V73.9997L48.8979 63.4283H30.4336C28.2245 63.4283 26.4336 61.6374 26.4336 59.4283V54.9711Z" fill="#053874"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M48.8566 0C51.0657 0 52.8566 1.79086 52.8566 4V43.5714C52.8566 45.7806 51.0657 47.5714 48.8566 47.5714H25.1066L5.28516 58.1429V4C5.28516 1.79086 7.07602 0 9.28516 0H48.8566ZM18.4994 29.0714C19.9591 29.0714 21.1423 27.8882 21.1423 26.4286C21.1423 24.969 19.9591 23.7857 18.4994 23.7857C17.0398 23.7857 15.8566 24.969 15.8566 26.4286C15.8566 27.8882 17.0398 29.0714 18.4994 29.0714ZM29.0709 29.0714C30.5305 29.0714 31.7137 27.8882 31.7137 26.4286C31.7137 24.969 30.5305 23.7857 29.0709 23.7857C27.6113 23.7857 26.428 24.969 26.428 26.4286C26.428 27.8882 27.6113 29.0714 29.0709 29.0714ZM42.2852 26.4286C42.2852 27.8882 41.1019 29.0714 39.6423 29.0714C38.1827 29.0714 36.9994 27.8882 36.9994 26.4286C36.9994 24.969 38.1827 23.7857 39.6423 23.7857C41.1019 23.7857 42.2852 24.969 42.2852 26.4286Z" fill="#053874"/>
              </svg>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-start">
              <p>Just Ask uses AI-powered search to make finding your ideal projects easier than ever with Barbour ABI.</p>
              <p>Simply ask a question like you would ask a colleague and Just Ask will return the projects that answer your needs.</p>
              <h3>How it works:</h3>
              <ul>
                <li>Make a clear prompt. Say something like, “show me projects in Manchester starting next year”.</li>
                <li>Just Ask will deliver every project that meets your criteria.</li>
                <li>If you want to get more specific, then simply refine your search further. E.g. “only include projects worth £10m and above”.</li>
                <li>Your pool of results will narrow to meet your new needs.</li>
                <li>You can continue to refine until you’re left with only your ideal projects.</li>
                <li>If you already know exactly which projects you want to see, then you can be as specific as you want to be from your very first prompt.</li>
              </ul>
              <p>If you need some inspiration, try out our example searches.</p>
              <p>After that, all you need to do to get started is Just Ask.</p>
            </Col>
          </Row>
        </Container>
      ) : (
        <SearchFormIndex />
      )}
    </>
  );
};

export default RightPanel;
