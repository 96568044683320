import React from 'react';
import { Col, Row } from 'reactstrap';
import UsersettingsDetail from './details';
import './usersettings.scss';

const Usersettings = () => {
	
	return (
			<div className="usersettings-page">
				<div className="usersettings-page-wrapper">
					<Row className="header-section flex-row-reverse pb-2">
						<Col lg="12" md="12" sm="12" className="pt-2">
							<h2 className="h5 pt-1 ps-4">Settings</h2>
						</Col>
					</Row>
					<UsersettingsDetail />
				</div>
            </div>
	);
}

export default Usersettings;