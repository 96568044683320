import moment from 'moment';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label } from 'reactstrap';

import Icon from '../../../common/js/icon';
import { deleteNote, editNote } from '../../apiclient';
import { summaryActivityDetail, updateActivityIsClicked } from '../../redux/reducer';

const NotesList = (props) => {
    const [noteValues, setNoteValues] = useState(props.note.note)
    const [isEmpty, setIsEmpty] = useState(false)
    const [isDeleteNote, setIsDeleteNote] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [isEditNote, setIsEditNote] = useState(false)
    const [editNoteId, setEditNoteId] = useState()
    const dispatch = useDispatch();

    let navigate = useNavigate()
    const searchPersist = useSelector((state) => state.searchPersist)
    const myleads = useSelector((state) => state.myleads)

    const moveCaretAtEnd = (e) => {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    }

    const dropDownToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleEditUpdate = (note, event) => {
        if (isEditNote) {
            let notesPayload = {
                id: note.note_id,
                object_id: note.object_id,
                object_type: note.object_type,
                description: noteValues
            }
            if (note.note_id) {
                editNote(notesPayload, dispatch, searchPersist, myleads)
                onHandleCancelClick()
            }
        }
    }

    const breakText = (text) => {
        let title = ''
        let body = ''

        if (text.split('\n')[0].length <= 50) {
            title = text.split('\n')[0]
        } else {
            text.split(' ').every(function (element, index) {
                if ((title + element).length > 50) return false
                else {
                    title += element + ' ';
                    return true
                }
            })
        }
        body = text.substring(title.trim().length + 1, 4001)
        return {
            'title': title,
            'body': body
        }
    }

    const handleEditNote = (note, event) => {
        setIsEditNote(true)
        setNoteValues(note.note)
        setEditNoteId(note.note_id)
    }

    const handleModal = (event) => {
        setIsDeleteNote(true)
    }

    const onHandleCancelClick = () => {
        setIsEditNote(false)
        setIsDeleteNote(false)
        setEditNoteId("")
    }

    const handleChange = (event) => {
        if (event.target.value.trim().length === 0) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        if (event.target.value.length > 4000) {
            event.target.value = event.target.value.slice(0, 4000);
        }
        setNoteValues(event.target.value);
    }

    const handleDelete = (note, event) => {
        if (note.note_id) {
            deleteNote(note.note_id, note.object_id, note.object_type, dispatch, searchPersist, myleads)
            setIsDeleteNote(false)
        }
    }

    const handleViewEntityNote = (e, note) => {
        e.preventDefault()
        let routingState = {
            prevNextList: props.prevNextList,
            showMoreResult: false,
            index: 0,
            list: [],
            activeTab: note.object_type === "project" ? '4' : '5'
        }
        dispatch(summaryActivityDetail("notes"))
        dispatch(updateActivityIsClicked(true))
        if (note.object_id && note.object_type) {
            if (note.object_type === "project") {
                navigate(`/app/project/` + note.object_id, { state: routingState })
            } else if (note.object_type === "company") {
                navigate(`/app/company/0/` + note.object_id, { state: routingState })
            }
        }
    }

    let text = breakText(props.note.note)
    return (
        <div className="note-block">
            {
                (isEditNote && editNoteId === props.note.note_id) ? (
                    <div className="note-list-wrapper d-flex">
                        <Icon color="#053874" size={35} icon="icon-gen-notes" className="me-3 round-border" />
                        <InputGroup className="note-form">
                            <div className="note-input">
                                <Input onFocus={(e) => moveCaretAtEnd(e)} autoFocus={true} type="textarea" placeholder="Add a note to the project" value={noteValues} onChange={(e) => handleChange(e)} />
                                <span className="text-area-counter">{noteValues.length}/4000</span>
                            </div>
                            <div className="btn-holder">
                                <button className={`primary-btn btn-save `} onClick={() => handleEditUpdate(props.note)}>Save</button>
                                <button className="outline-btn btn-cancel" onClick={() => onHandleCancelClick()}>Cancel</button>
                            </div>
                        </InputGroup>
                    </div>
                ) : (
                    <div className="d-flex">
                        <div className="note-block-wrapper">
                            {
                                (
                                    props.prevNextList === 'myLeadsNotes' ?
                                        (<div id={"noteBx" + props.note.note_id}>
                                               <input type="checkbox" className="read-more-state" id={props.note.note_id} />
                                            <Link onClick={(e) => handleViewEntityNote(e, props.note)}>
                                                <p>
                                                    <span className="update-time">{props.note.updated_on ? moment(props.note.updated_on).format("DD-MMM-YYYY") : moment(props.note.created_on).format("DD-MMM-YYYY")}</span>
                                                    <span className={props.note.object_type === 'project' ? "pro" : "com"}>{props?.note?.object_type === 'project' ? props?.note?.project_title : props?.note?.company_name}</span>
                                                </p>
                                                <strong dangerouslySetInnerHTML={{
                                                    __html: text.title
                                                }} />
                                                <pre className={'notes-list'} id={`note-${props.index}`}>
                                                    <input type="checkbox" className="read-more-state" id={props.note.note_id} />
                                                    <div className={"" + (text.body.length > 800 ? 'read-more-wrap' : '')}>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: text.body
                                                            }}
                                                        />
                                                    </div>
                                                    </pre>
                                                    </Link>
                                                    <Label for={props.note.note_id} className={"" + (text.body.length > 800 ? 'read-more-trigger' : 'hide')}></Label>
                                              
                                           </div>) :
                                        (<div>
                                            <p>
                                                <span className="update-time">{props.note.updated_on ? moment(props.note.updated_on).format("DD-MMM-YYYY") : moment(props.note.created_on).format("DD-MMM-YYYY")}</span>
                                            </p>
                                            <strong dangerouslySetInnerHTML={{
                                                __html: text.title
                                            }} />
                                            <pre className={'notes-list'} id={`note-${props.index}`}>
                                                <input type="checkbox" className="read-more-state" id={props.note.note_id} />
                                                <div className={"" + (text.body.length > 800 ? 'read-more-wrap' : '')}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: text.body
                                                        }}
                                                    />
                                                </div>
                                                <Label for={props.note.note_id} className={"" + (text.body.length > 800 ? 'read-more-trigger' : 'hide')}></Label>
                                            </pre>
                                        </div>)
                                )
                            }
                            <div className={`inline-pop ${isDeleteNote ? 'show' : 'hide'}`}>
                                <Button className="btn-label-cancel" onClick={() => onHandleCancelClick()}>Cancel</Button><span></span>
                                <Button className="btn-label-delete" onClick={() => handleDelete(props.note)}>Delete</Button>
                            </div>
                            <ButtonDropdown className="more-actionsheet" isOpen={dropdownOpen} toggle={() => dropDownToggle()}>
                                <DropdownToggle caret>
                                    Actions
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-right">
                                    {props.prevNextList === 'myLeadsNotes' && <DropdownItem onClick={(e) => handleViewEntityNote(e, props.note)}>View</DropdownItem>}
                                    <DropdownItem onClick={() => handleEditNote(props.note)}>Edit</DropdownItem>
                                    <DropdownItem onClick={(event) => handleModal(event)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default NotesList