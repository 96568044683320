import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Button } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Icon from '../common/js/icon';
import _ from 'lodash'
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import {
    updateDropDownSelectedObjectType, updateShowSelectedCompanies, updateShowSelectedProjects, updateObjectType, updatePeopleDetail,
    fetchSearchListForAllObjectType, updateShowHierarchy, updateSelectedSelectorControlValue, updateHierarchyBack, resetRelationalEntititySelectionCount,
    updatelistOfSelectedObjectIds, fetchShowMoreListOfSelectedObjectIds, updateObjectId, updateRemoveFilterToggle, updateClusterList, updateBackButtonCluster, updateClusterClickCount, updateBackClusterList, updateNavigationBack, updateSearchLoader, updateBackObjectId,
    updateIsShowProjectsOrCompanySelected
} from '../search/redux/reducer';
import { updateActivityIsClicked } from '../details/redux/reducer';
import MyLeadsSort from './searchSort'
import { addShare, deleteShare, fetchNotesForAllDetails } from '../details/apiclient';
import { createTeamList, getInitials, getSharedListBySharedDate } from '../../utils';
import { selectorControlValue } from '../../utils/commonFunctions'
import { updateAppliedContext, updateIsMyleadsListView, updateIsProjectSelected, updateMyLeadOffsetLimitValue, updateMyLeadsShowHierarchy, updateNotesSearchText, updateSearchList, updateTagId } from './reducer';
import { updateIsListView, updateLeadsAppliedContext } from '../search/redux/searchPeristReducer';
import { fetchFavouritesResults, fetchHistoryResults, fetchTagDetailsById, fetchUpdatesResults, fetchArchieveList, fetchSharesResults } from './apiclient';
import { shareInformationDetails } from '../../redux/features/user/userslice';

const MyLeadsHeader = (props) => {

    const myleads = useSelector((state) => state.myleads)
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const project = useSelector(state => state.project)
    const userData = useSelector(state => state.user.user)
    const user = useSelector(state => state.user)
    const settings = useSelector((state) => state.settings)
    let activeFilterValue = searchPersist.leadsAppliedContext[props?.activeTab];
    const [searchData, setSearchData] = useState({
        dropdownOpen: false,
        dropDownFilterType: ["project", "company"],
        activeDisplayValue: activeFilterValue,
        displayFilterDropDownText: activeFilterValue === "company" ? "Companies" : "Projects",
        isButtonClicked: false
    })
    const [shareButtonValue, setShareButtonValue] = useState(false)
    const [options, setOptions] = useState({})
    const [multiInputValue, setMultiInputValue] = useState([])
    const [multiInputTagValue, setMultiInputTagValue] = useState([])
    const [shareDetails, setSharedDetails] = useState([])
    const [sharedMessage, setSharedMessage] = useState('')
    const [inputValue, setInputValue] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('')
    const [listMapToggle, setListMapToggle] = useState(myleads.isListView)
    const [listMapSearchToggle, setListMapSearchToggle] = useState(searchPersist.isListView)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState(myleads?.notesSearchText ? myleads?.notesSearchText : '')
    const [seachClicked, setSeachClicked] = useState(false)
    const [listCount, setListCount] = useState(search && search.searchListCount)
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        isShowRelatedEntitiesMode: false,
        isModalOpen: false,
        modal: false,
        archive: false
    })
    const [searchControlLimit, setSearchControlLimit] = useState(selectorControlValue(search && search.searchListCount))

    useEffect(() => {
        if (searchData.isButtonClicked) {
            if (props?.activeTab === 'updates') {
                fetchUpdatesResults(searchPersist, myleads, dispatch, settings);
            } else if (props?.activeTab === 'tags' && myleads.tagId !== 0) {
                fetchTagDetailsById(myleads.tagId, myleads, searchPersist, dispatch, '', null, settings)
            } else if (props?.activeTab === 'favourite') {
                fetchFavouritesResults(searchPersist, myleads, dispatch, null, settings);
            } else if (props?.activeTab === 'history') {
                fetchHistoryResults(searchPersist, myleads, dispatch, null, settings)
            } else if (props?.activeTab === 'shares') {
                fetchSharesResults(searchPersist, myleads, dispatch, null, settings);
            } else if (props?.activeTab === 'notes') {
                fetchNotesForAllDetails(searchPersist.leadsAppliedContext[props?.activeTab], myleads, dispatch)
            } else if (props?.activeTab === 'archive') {
                fetchArchieveList(searchPersist, myleads, dispatch)
            }

            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: false
            }))
        }
    }, [searchData.isButtonClicked])

    useEffect(() => {
        if (seachClicked) {
            if (myleads?.offsetLimitValue?.offset > 0) {
                dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }))
            } else {
                fetchNotesForAllDetails(searchPersist.leadsAppliedContext[props?.activeTab], myleads, dispatch)
            }
            setSeachClicked(false)
        }
    }, [myleads?.notesSearchText])

    useEffect(() => {
        setListMapToggle(searchPersist.isListView)
    }, [myleads.isListView])

    const handleFilterDropdownText = (type) => {
        setSearchData((prevState) => {
            return {
                ...prevState,
                dropdownOpen: false,
                activeDisplayValue: type,
                displayFilterDropDownText: type === 'project' ? 'Projects' : 'Companies'
            };
        });
        // dispatch(updateLeadsAppliedContext(type , props?.activeTab))
        dispatch(updateLeadsAppliedContext({ activeTab: props?.activeTab, context: type }));
        setMultiInputValue([])
        setSearchData(prev => ({ ...prev, isButtonClicked: true }))
        setSearchText('')
        dispatch(updateNotesSearchText(''))
        dispatch(updateDropDownSelectedObjectType(true))
        dispatch(updateShowSelectedCompanies(false))
        dispatch(updateShowSelectedProjects(false))
        dispatch(updateIsShowProjectsOrCompanySelected(false))
        dispatch(updatePeopleDetail(false))
        dispatch(updateObjectType(''))
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
        dispatch(fetchSearchListForAllObjectType([]))
        dispatch(updateSelectedSelectorControlValue(0))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateActivityIsClicked(false))
        dispatch(updatelistOfSelectedObjectIds([]))
        dispatch(fetchShowMoreListOfSelectedObjectIds([]))
        dispatch(updateObjectId(undefined))
        dispatch(updateHierarchyBack(false))
    }

    const handleListView = () => {
        dispatch(updateHierarchyBack(true))
        setListMapToggle(!listMapToggle)
        setListMapSearchToggle(!listMapSearchToggle)
        dispatch(updateIsMyleadsListView(!listMapToggle))
        dispatch(updateIsListView(!listMapSearchToggle))
    }

    const handleToggleChange = () => {
        dispatch(updateRemoveFilterToggle(!search.removeFilterToggle))
    }

    const handleBack = () => {
        props.onClickStatus(false)
        dispatch(shareInformationDetails({}))
        setSharedDetails([])
        dispatch(updateTagId({}))
        dispatch(updateSearchList([]))
        dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
    }

    // useEffect(() => {
    //     let recipientOptions = []
    //     let teamListData = createTeamList(user?.teamListDetails)
    //     recipientOptions.push({ value: 'Everyone', label: 'Everyone', initials: '-', email: '-', sharedType: '-', className: 'everyone-class' })
    //     recipientOptions.push({ value: 'Teams', label: 'Teams', initials: '-', email: '-', sharedType: '-' })
    //     if (teamListData && teamListData.length > 0) {
    //         // eslint-disable-next-line array-callback-return
    //         teamListData && teamListData.map(team => {
    //             recipientOptions.push({ value: team.team_no, label: team.name, initials: '-', email: '-', sharedType: 'T', className: 'teams-class' })
    //         })
    //     }
    //     recipientOptions.push({ value: 'Users', label: 'Users', initials: '-', email: '-', sharedType: '-' })
    //     if (project && project.fetchShareRecipients && project.fetchShareRecipients.length > 0) {
    //         project && project.fetchShareRecipients && project.fetchShareRecipients.map(user => {
    //             recipientOptions.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email, sharedType: 'U' })
    //         })
    //     }
    //     if (props.activeTab === "MyLeadsTags" && user?.shareInfoDetails?.objectType === "tags") {
    //         setSharedDetails(user?.shareInfoDetails?.sharedInfo)
    //     }
    //     setOptions(recipientOptions)

    // }, [user, user?.shareInfoDetails])

    useEffect(() => {
        if (props.activeTab === "tags" && user?.shareInfoDetails?.objectType === "tags") {
            let recipientOptions = []
            let shareTagDetails = []
            let teamListData = createTeamList(user?.teamListDetails)
            recipientOptions.push({ value: 'Everyone', label: 'Everyone', initials: '-', email: '-', sharedType: '-', className: 'everyone-class' })
            recipientOptions.push({ value: 'Teams', label: 'Teams', initials: '-', email: '-', sharedType: '-' })
            if (teamListData && teamListData.length > 0) {
                // eslint-disable-next-line array-callback-return
                teamListData && teamListData.map(team => {
                    recipientOptions.push({ value: team.team_no, label: team.name, initials: '-', email: '-', sharedType: 'T', className: 'teams-class' })
                })
            }
            recipientOptions.push({ value: 'Users', label: 'Users', initials: '-', email: '-', sharedType: '-' })
            if (project && project.fetchShareRecipients && project.fetchShareRecipients.length > 0) {
                project && project.fetchShareRecipients && project.fetchShareRecipients.map(user => {
                    recipientOptions.push({ value: user.user_id, label: (user.first_name + " " + user.last_name), initials: getInitials(user.first_name + " " + user.last_name), email: user.email, sharedType: 'U' })
                })
            }
            if (user?.shareInfoDetails?.sharedInfo) {
                shareTagDetails = getSharedListBySharedDate(user?.shareInfoDetails?.sharedInfo)
            }
            setOptions(recipientOptions)
            let shareDetailsData = []
            if (shareTagDetails && Object.keys(shareTagDetails).length > 0) {
                Object.keys(shareTagDetails).map(shared => {
                    shareTagDetails[shared].map(sharedObj => {
                        const sharedOnObj = { sharedOn: sharedObj.sharedOn };
                        const updatedSharedObj = { ...sharedObj.sharedBy, ...sharedOnObj };
                        shareDetailsData.push(updatedSharedObj)
                        sharedObj.shareWith.map(item => {
                            shareDetailsData.push(item)
                        })
                    })
                }
                )
            }
            let uniqData = _.uniqBy(shareDetailsData, (obj) => obj.id)
            setSharedDetails(uniqData)
        }
        setShareButtonValue(false)
    }, [user, user?.shareInfoDetails])

    const teamInitials = (value) => {
        var teamName = value && value.trim().split(" ")
        var teamInitial = teamName && teamName.length > 1 ? teamName && teamName[0] && teamName[0][0] + teamName && teamName[1] && teamName[1][0] : teamName && teamName[0] && teamName[0][0]
        return teamInitial.toUpperCase()
    }

    const shareOptionsRenderer = (option) => {
        return (
            <div className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? `block-title ${option.label === "Everyone" ? ' everyone-icon' : ''}` : 'list-user-team'}`} key={option.value}>
                {option.initials != '-' ? (<span className="user-dropdown-icon">{option.initials}</span>) : ((<span className="team-member-name team-ss-icon">{teamInitials(option.label)}</span>))}
                <span className={`${(option.label === 'Teams' || option.label === 'Users' || option.label === "Everyone") ? 'select-header' : option.email == '-' ? 'team-name-class users-teams-title' : 'users-teams-title'}`}>{option.label}
                    {option.email != '-' ? (<a className="email-address"> {option.email} </a>) : ('')}</span>
            </div>
        )
    }


    const handleOnChange = (values) => {
        let options = []
        values.map(function (value) {
            if (!(value.value === 'Teams' || value.value === 'Users')) {
                options.push(value)
            }
        })
        setMultiInputValue(options)
    }

    const handleShareMessage = () => {
        setShareButtonValue(true)
        setShowMore(false)
        let sharedWith = []
        let payload = {}
        var objectId = myleads?.tagId
        if (multiInputValue.length > 0) {
            multiInputValue.map(function (value) {
                if (value.value === 'Everyone') {
                    sharedWith.push({ share_type: "company" })
                }
            })
            let userIds = []
            multiInputValue.filter(value => value.sharedType === 'U').map(inputValue => userIds.push(inputValue.value))
            sharedWith.push({ share_type: 'user', user_id: userIds })
            let teamIds = []
            multiInputValue.filter(value => value.sharedType === 'T').map(inputValue => teamIds.push(inputValue.value))
            sharedWith.push({ share_type: 'team', team_id: teamIds })
            payload = {
                "shared_with": sharedWith
            }
            if (sharedMessage !== "") {
                payload["shared_message"] = sharedMessage
            }
            addShare(objectId, 'tags', payload, dispatch, user, userData, myleads?.tagName)
        }
        else {
            payload = {
                "shared_with": []
            }
        }

        setMultiInputValue([])
        setSharedMessage('')
    }

    const handleDeleteButtonClick = (userId) => {
        setDeleteUserId(userId)
    }

    const handleCancel = () => {
        setDeleteUserId('')
    }

    const handleShareDelete = (shareDetails, event) => {

        let payload = {
            'shared_with': [
                shareDetails.shared_type === 'U' ?
                    {
                        'shared_type': 'user',
                        'user_id': [shareDetails.shared_id]
                    }
                    :
                    shareDetails.shared_type === 'T' ?
                        {
                            'shared_type': 'team',
                            'team_id': [shareDetails.shared_id]
                        } :
                        {
                            'share_type': 'company',
                        }
            ]
        }
        deleteShare(myleads.tagId, 'tags', payload, dispatch, user, userData)
        setDeleteUserId("")
    }

    const removeShareBox = () => {
        setShareButtonValue(false)
    }

    const toggleShowMore = () => {
        setShowMore(!showMore);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            setSearchText(event.target.value)
            setSeachClicked(true)
            dispatch(updateNotesSearchText(event.target.value))
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleClearText = () => {
        setSearchText('');
        setSeachClicked(true)
        dispatch(updateNotesSearchText(''))
    };

    useEffect(() => {
        setSearchControlLimit(selectorControlValue(search && search.searchListCount))
        booleanData.isShowRelatedEntitiesMode ? setListCount(search && search.listOfSelectedObjectIdsCount) : setListCount(search && search.searchListCount)
    }, [search.searchListCount, search.listOfSelectedObjectIdsCount], search.countOfSelectedObjectIds)

    useEffect(() => {
        dispatch(updateHierarchyBack(false))
    }, [listMapToggle])

    const handleFind = () => {
        setSeachClicked(true)
        dispatch(updateNotesSearchText(searchText))
    }

    // useEffect(() => {
    //     if (searchText !== '') {
    //         handleFind()
    //     }
    // }, [searchText])


    const goBack = (event) => {
        if (search.navigationBack === 'clusterClick') {
            onClusterBack()
        } else {
            searchPersist.appliedContext === 'project' ? dispatch(updateShowSelectedCompanies(false)) : dispatch(updateShowSelectedProjects(false))
            setBooleanData(prev => ({
                ...prev,
                isShowRelatedEntitiesMode: false
            }))
            setListCount(search && search.searchListCount)
            if (search.showSelectedCompanies && !booleanData.isShowRelatedEntitiesMode || search.showSelectedProjects && !booleanData.isShowRelatedEntitiesMode) {
                dispatch(updateSelectedSelectorControlValue(search && search.selectedLeadsFlag && search.selectedLeadsFlag.length))
            }
            dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
            if (project.isProjectSelected) {
                navigate(`/app/company/0/${project.objectId}`)
                dispatch(updateIsProjectSelected({ isProjectSelected: false }))
            }
            dispatch(updateNavigationBack(search.clusterList.leadsFlags?.length > 0 ? 'clusterClick' : ''))
        }
        dispatch(updateSearchLoader(false))
        dispatch(updateBackObjectId(search.projectRowData?.project_id ? search.projectRowData.project_id :
            search.objectId ? search.objectId : undefined))
    }

    const onClusterBack = () => {
        if (search.clusterClickCount >= 2) {
            dispatch(updateClusterClickCount(search.clusterClickCount - 1))
            dispatch(updateClusterList(search.backClusterList.slice(-2)[0]))
            let backClusterList = _.clone(search.backClusterList)
            backClusterList.pop()
            dispatch(updateBackClusterList(backClusterList))
        } else {
            dispatch(updateClusterClickCount(0))
            dispatch(updateClusterList({ leadsFlags: [] }))
            dispatch(updateBackButtonCluster({ back: false }))
            dispatch(updateBackClusterList([]))
            dispatch(updateNavigationBack(''))
        }
    }


    return (
        <>
            <div className="myLeaderTypeHeader d-flex align-items-center m-2">

                {props.activeTab === 'tags' && search.clusterList?.leadsFlags?.length === 0 && !myleads.showProjectHierarchy && (<button class="user-ctrl-btn back-to-tagDetails-button" onClick={handleBack}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" /> Back</button>)}

                {
                    (searchPersist.leadsAppliedContext[props?.activeTab] === 'project' && myleads.showProjectHierarchy) || search.clusterList?.leadsFlags?.length ?
                        (<button class="user-ctrl-btn back-to-tagDetails-button" onClick={goBack}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" /> Back</button>) : ('')
                }


                {
                    (props.activeTab === 'updates' || props.activeTab === 'favourite' || props.activeTab === 'tags' || props.activeTab === 'history' || props.activeTab === 'shares' || props.activeTab === 'notes') ? (
                        <div className={`objectType-dropdown ${!myleads.isListView ? 'gridViewHeader' : ''} me-2`}>
                            {!search.clusterList?.leadsFlags?.length ?
                                <Dropdown isOpen={searchData.dropdownOpen} toggle={() => setSearchData(prevState => ({ ...prevState, dropdownOpen: !searchData.dropdownOpen }))} >
                                    <DropdownToggle caret>
                                        <Icon color="#053874" size={20} icon={`${searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'icon-gen-project' : 'icon-gen-company'}`} className="me-1" />{searchPersist.leadsAppliedContext[props?.activeTab]}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        Search for
                                        {
                                            searchData.dropDownFilterType && searchData.dropDownFilterType.map(text => (
                                                <>
                                                    <DropdownItem onClick={() => handleFilterDropdownText(text)}>
                                                        <Icon color="#292B2C" size={20} icon={`${searchData.activeDisplayValue === text ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1" />
                                                        <Icon color="#053874" size={20} icon={`${text === 'project' ? 'icon-gen-project' : text === 'company' ? 'icon-gen-company' : ''}`} className="me-1" />
                                                        {text === 'project' ? "Projects" : text === 'company' ? "Companies" : ""}
                                                    </DropdownItem>
                                                </>
                                            ))
                                        }
                                    </DropdownMenu>
                                </Dropdown> : ('')
                            }
                            {
                                (props.activeTab !== 'notes' && props.activeTab !== 'updates' && (props.activeTab === 'favourite' || (props.activeTab === 'tags' && myleads.tagId !== 0) || props.activeTab === 'history' || props.activeTab === 'shares')) && myleads?.leadSearchListCount > 0 ? (
                                    <p className="result-count">{myleads?.leadSearchListCount} {searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'projects' : 'companies'}</p>
                                ) : ''
                            }
                            {
                                props.activeTab === 'updates' && myleads?.updatesSearchListCount > 0 ? (
                                    <p className="result-count">{myleads?.updatesSearchListCount} {searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'projects' : 'companies'}</p>
                                ) : ''
                            }
                            {
                                props.activeTab === 'notes' && (
                                    <Col md={12} className="pl-2">
                                        <Row className="row">
                                            <Col md={9} className="pr-0 search-notes-container">
                                                <Input
                                                    placeholder="Search notes"
                                                    onChange={handleSearchTextChange}
                                                    value={searchText}
                                                    autoFocus={true}
                                                    onKeyDown={handleKeyDown}
                                                    id="notesText"
                                                    maxLength={50}
                                                    autoComplete="off">
                                                </Input>
                                                <Button
                                                    className={`clear-textfield ${searchText && searchText.length > 0 ? 'display-block' : 'display-none'}`}
                                                    onClick={handleClearText}>X</Button>
                                            </Col>
                                            <Col md={3} className="pl-0">
                                                <button type="button" className="primary-btn btn-save-cancel btn btn-secondary btn-notes" onClick={handleFind}>Search</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }

                        </div>
                    ) : (
                        <div className={`objectType-dropdown ${!myleads.isListView ? 'gridViewHeader' : ''} me-2`}>
                            <Dropdown isOpen={searchData.dropdownOpen} toggle={() => setSearchData(prevState => ({ ...prevState, dropdownOpen: !searchData.dropdownOpen }))} >
                                <DropdownToggle>
                                    <Icon color="#053874" size={20} icon='icon-gen-project' className="me-1" />Projects
                                </DropdownToggle>
                            </Dropdown>
                            {myleads?.leadSearchListCount > 0 ?
                                <p className="result-count">{myleads?.leadSearchListCount} projects</p>
                                : ''
                            }
                        </div>
                    )
                }

                <div className="d-flex justify-content-end">
                    {props.activeTab === 'tags' &&
                        (<div className="tag-share">
                            {/* <div className={`shared-users ${shareButtonValue ? 'd-none' : ''}`}>
                                <span className="shared-user-blocks" id="collaborators-0">
                                    <button type="button" class="team-member-name" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Barnali Evotestone">BE</button>
                                </span>
                                <span className="shared-user-blocks" id="collaborators-1">
                                    <button type="button" class="team-member-name" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ashwini Dere">AD</button>
                                </span>
                            </div> */}
                            <div className={`shared-users ${shareButtonValue ? 'd-none' : ''}`}>
                                {shareDetails.length > 0 ?
                                    shareDetails.map((share, index) => {
                                        return (
                                            index < 5 ? (
                                                <span className="shared-user-blocks" id={`collaborators-${index}`}>
                                                    {share.type === "T" ? (
                                                        <span className="team-member-name team-ss-icon" data-tooltip-id={share.id.toString()}
                                                            data-tooltip-hidden={!settings.preferences.hint_text}>{share.initials}</span>
                                                    ) : share.type === "U" ? (
                                                        <span className="team-member-name" data-tooltip-id={share.id.toString()}
                                                            data-tooltip-hidden={!settings.preferences.hint_text}>{share.initials}</span>
                                                    ) : share.client_no ? <span className="team-member-name" data-tooltip-id={share.id.toString()}
                                                        data-tooltip-hidden={!settings.preferences.hint_text}>{share.initials}</span>
                                                        : (<div className="share-everyone" data-tooltip-id={index.toString()}
                                                            data-tooltip-hidden={!settings.preferences.hint_text}></div>)
                                                    }
                                                    <ReactTooltip id={share.id && share.id !== 'company' ? share.id.toString() : index.toString()} place="bottom" type='dark'>
                                                        <p>{share.first_name && share.last_name ? share.first_name + ' ' + share.last_name : share.name} <br /> {moment(share.sharedOn).format("DD-MMM-YYYY")}</p>
                                                    </ReactTooltip>
                                                </span>
                                            ) : ('')
                                        )
                                    }) : ('')}
                                {shareDetails.length > 5 ? (
                                    <span className="shared-user-blocks" id={`collaborators+1`}>
                                        <span className="team-member-name" data-tooltip-id="+1"
                                            data-tooltip-hidden={!settings.preferences.hint_text}>+ {shareDetails.length - 5}</span>
                                    </span>
                                ) : ('')}
                            </div>


                            {shareDetails?.length > 0 ? <span className={`all-team-member ${shareButtonValue ? 'd-none' : ''}`} id="show-all" onClick={() => toggleShowMore()}>{showMore ? 'Close' : 'Show all'}</span> : ""}
                            <div className={`tag-icon ${shareButtonValue ? 'd-block' : 'd-none'}`}>
                                <Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="circle-border" />
                            </div>
                            <InputGroup className={`share-input-box ${shareButtonValue ? 'd-block' : 'd-none'}`}>
                                <CreatableSelect
                                    options={options}
                                    isClearable
                                    isMulti
                                    value={multiInputValue}
                                    formatOptionLabel={(option) => shareOptionsRenderer(option)}
                                    placeholder={<span className='label-placeholder'> Enter user or team to share </span>}
                                    onChange={(newValue) => handleOnChange(newValue)}
                                    onInputChange={(newValue) => setInputValue(newValue)}
                                    isValidNewOption={() => false}
                                    className='share-select-dropdown'

                                />
                            </InputGroup>
                            <button disabled={!myleads.createdByLoggedInUser} className="primary-btn btn-share" onClick={() => handleShareMessage()}>Share</button>
                            <div className={`remove-icon ${shareButtonValue ? 'd-block' : 'd-none'}`} onClick={removeShareBox}>
                                <Icon size={18} icon="icon-gen-crossthick" className="" />
                            </div>
                        </div>)
                    }

                    {
                        (props.activeTab === 'favourite' || props.activeTab === 'history' || props.activeTab === 'tags' || props.activeTab === 'shares' || props.activeTab === 'archive')
                        && <MyLeadsSort activeTab={props.activeTab} />
                    }

                    {(props.activeTab !== 'archive' && props.activeTab !== 'notes') ?
                        (<div className="maplist-container me-2">
                            <button onClick={handleListView} className={`listview outline-btn`}>
                                {listMapToggle ?
                                    <span><Icon color="#ED1C24" size={20} icon="icon-gen-map" /> Map view </span> :
                                    <span><Icon color="#ED1C24" size={20} icon="icon-gen-list" /> List view </span>}
                            </button>
                        </div>) : ('')
                    }

                </div>
            </div>

            {
                shareDetails.length > 0 && props.activeTab === "tags" && showMore ? (
                    <div className="label-container">
                        {shareDetails.map((sharedUser, index) =>
                            <>
                                {sharedUser?.is_delete === undefined || sharedUser?.is_delete === false ?
                                    <div className="users-list">
                                        <div className="">
                                            <div className="user-block">
                                                <a className="user-profile-icon">{sharedUser?.initials}</a>
                                                <span className="name-class">{`${sharedUser?.name}`} <p className="email-address">{sharedUser?.email}</p></span>
                                                <span className="share-date">{moment(sharedUser?.sharedOn).format("DD-MMM-YYYY")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="users-list">
                                        <div className="">
                                            <div className="user-block d-flex justify-content-between">
                                                <div>
                                                    <a className={`${sharedUser.type === 'T' ? 'team-member-name team-ss-icon' : sharedUser.type == 'U' ? 'user-profile-icon' : 'share-everyone'}`}>{sharedUser?.initials ? sharedUser.initials : ''}</a>
                                                    <span className={`${sharedUser.email ? 'name-class' : 'team-name-class'}`}>{sharedUser.first_name && sharedUser.last_name ? sharedUser.first_name + " " + sharedUser.last_name : sharedUser.name} {sharedUser.email ? (<p className="email-address">{sharedUser.email}</p>) : ('')}</span>
                                                </div>
                                                <div>
                                                    <div className="delete-container">
                                                        <Button className="delete-button" id={`delete`} onClick={() => handleDeleteButtonClick(sharedUser?.id)}>Delete</Button>
                                                        <div className={`share ${deleteUserId === sharedUser?.id ? 'show' : 'hide'}`}>
                                                            <Button className="btn-label-cancel" id={`cancel`} onClick={handleCancel}>Cancel</Button><span></span>
                                                            <Button className="btn-label-delete" id={`delete`} onClick={() => handleShareDelete({ shared_type: sharedUser?.type, shared_id: sharedUser?.id })}>Delete</Button>
                                                        </div>
                                                    </div>
                                                    <span className="share-date">{moment(sharedUser?.sharedOn).format("DD-MMM-YYYY")}</span>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )}
                        {/* )} */}
                    </div>


                ) : ''
            }
        </>
    )
}


export default MyLeadsHeader