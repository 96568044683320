import React, { useState } from 'react'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'

const DeleteModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} id="deleteModal" className='deleteModalBox'>
        <ModalHeader className='d-flex' toggle={props.handleCancelDelete}>
            Delete save search
        </ModalHeader>
        <ModalBody className='pt-1'>
            Are you sure you want to delete '{props.title}'?
        </ModalBody>
        <ModalFooter>
            <Button className="outline-btn btn-save-delete" onClick={props.handleCancelDelete}>Cancel</Button>
            <Button id="primaryBtn" className="primary-btn btn-save-cancel" onClick={props.handleDeleteYes} autoFocus={true}>Delete</Button>
        </ModalFooter>
    </Modal>
    )
}

export default DeleteModal;