import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { getSectorNo } from '../../../utils';
import { getCompactDigitValue } from '../utils';



const UserActivityList = (props) => {
  const getHumanizeDateForUpdates = (updatedOn) => {
    if (updatedOn) {
      let relativeTime = moment.utc(updatedOn).format("YYYY-MM-DD HH:mm:ss");
      relativeTime = moment.tz(relativeTime, 'Europe/London');
      var lastDate = relativeTime.calendar(null, {
        sameDay: '[Today] [at] HH:mm',
        lastDay: '[Yesterday] [at] HH:mm',
        lastWeek: 'DD-MMM-YYYY [at] HH:mm',
        sameElse: 'DD-MMM-YYYY [at] HH:mm'
      });
      return lastDate;
    } else {
      return "";
    }
  }

 

  var events = props.result.events;

  return (
    !props.preloaderFlag ? (
      <div className="user-list-block">
        <Row className="m-0">
          <Col className="user-details user-names" xs="12" sm="12" md={`${getSectorNo(window.location).sectorNo !== '5' ? '2' : '3'}`}>
            <span className="user-icon">{props.result.initials}</span>
            <div className="userActivityInfo">
              <span className="fl-name">{props.result.first_name} {props.result.last_name}</span>
              {props.result.username ? (<span className="user-name">{props.result.username}</span>) : ('')}
              {props.result.last_seen ? (<span className="user-lastseen">{"Last Seen " + getHumanizeDateForUpdates(props.result.last_seen)}</span>) : ('')}
            </div>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.searches : 0}</span>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.projects_viewed : 0}</span>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.companies_viewed : 0}</span>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.saved_searches : 0}</span>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.favourites : 0}</span>
          </Col>
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.tags : 0}</span>
          </Col>
          {(getSectorNo(window.location).sectorNo !== '5') ?
            <Col className="center" xs="12" sm="12" md="0.8">
              <span className="user-mobile">{typeof events !== 'undefined' ? events.shares : 0}</span>
            </Col>
            : ('')
          }
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.notes : 0}</span>
          </Col>
          {(getSectorNo(window.location).sectorNo !== '5') ?
            <Col className="center" xs="12" sm="12" md="0.8">
              <span className="user-mobile">{typeof events !== 'undefined' ? events.exports : 0}</span>
            </Col>
            : ('')
          }
          {(getSectorNo(window.location).sectorNo !== '5') ?
            <Col className="center" xs="12" sm="12" md="0.8">
              <span className="user-mobile">{typeof events !== 'undefined' ? getCompactDigitValue(events.no_of_records_exported) : 0}</span>
            </Col>
            : ('')
          }
          <Col className="center" xs="12" sm="12" md="0.8">
            <span className="user-mobile">{typeof events !== 'undefined' ? events.justask_conversations : 0}</span>
          </Col>
        </Row>
      </div>
    ) : ""
  );
}

export default UserActivityList;
