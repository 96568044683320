import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input } from 'reactstrap';
import _ from 'lodash';
import Slider from 'rc-slider';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { FaSearch } from "react-icons/fa";
import { components } from 'react-select';
import { useRef } from 'react';

import { TOURFILTERVIEW, TOURPROJECTLISTVIEW } from '../../../utils/tour';
import './filter.scss';
import FilterMenu from './filterMenu';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import UpdatedFilter from './updatedFilter';
import CategoryTypeFilter from './categoryTypeFilter';
import DevTypeFilter from './devTypeFilter';
import StagesFilter from './stagesFilter';
import MaterialFilter from './materialFilter';
import RoleFilter from './roleFilter';
import ValueFilter from './valueFilter';
import FrameworkFilter from './frameworkFilter';
import BuildPhase from './buildPhase';
import MeasuresFilter from './measuresFilter';
import IncludeCompletedProjectsFilter from './includeCompletedProjectsFilter';
import ProjectLocationFilter from './projectFilters/projectLocationFilter';
import ProjectTextSearch from './projectFilters/projectTextSearch';
import ProjectRegionsCountiesFilter from './projectFilters/projectRegionsCountiesFilter';
import ProjectTimingFilter from './projectFilters/projectTimingFilter';
import { fetchSearchListCount, fetchSearchCount } from "../apiclient"
import SummaryTiles from './summaryTiles';
import { checkSearchByIdFilter, fetchAppliedFilters, fetchHandleSubmitSort, fetchSearchMode, getConstructionDateValue, getIncludeExcludeFilterList, getSortValueContextChange, returnTimeFrame, fetchSortValue, fetchSortLabel } from '../../../utils/commonFunctions';
import CompanyTextSearch from './companyFilters/companyTextSearch';
import PersonTextSearch from './peopleFilters/personTextSearch';
import { toggleFilterActiveStateForElement } from './utils';
import CompanyLocationRegionsCounties from './companyFilters/companyRegionsCountiesFilter';
import CompanyLocation from './companyFilters/companyLocation';
import { preProcessorQuery } from './preProcessorQuery';
import { updateAppliedContext, updateLocationSearchObj, updateFilterSearchText, updateAdditionalQuery, updateSortSearch, updateSummaryTags, updateSaveSeachRedirectionApplied, updateFilterQuery, updateIsListView, updateLoginRedirectDefaultSaveSearch, updateNotificationRedirectSaveSearch, updateSortClick, updateLogin } from '../redux/searchPeristReducer';
import {
    toggleFilterActiveState,
    toggleLastUpdatedFilter, updateCategoryLandTypeFilter, toggleNewUpdatedFilter, updateActivateFilter,
    updateCategoryUsageTypeFilter, updateDisplayKey, updateMeasures, updatePrimaryCategoriesOnlyFlag,
    updateTextInputFilter, updateIncludeExcludeCheckbox, updateOnNoFilterOption, resetFilterStateWithDefaultFilters,
    updateContextType, updateContextFilter, updateAdvanceLocation, updateFilterText, updateSelectedSearchType, updateSort,
    updateFilterActive,
    updateOffsetLimit,
    toggleAnyAllVal,
    updateNewSearch,
    updateSearchHere,
    updateExcludeStageFilter,
    updateHandleViewForShowCompanyShowProjects
} from './redux/reducer';
import { updateActivityIsClicked, updateGroupCompaniesDetails, updateIsProjectSelected } from '../../details/redux/reducer';
import AutoComplete from '../autoComplete';
import { MIN_MILES } from '../../../utils/settings';
import Icon from '../../common/js/icon';
import {
    updateSearchTextObject, updateSelectedSelectorControlValue, resetRelationalEntititySelectionCount,
    updateLocationSearchObject, updateSearchLoader, updateAdditionalQuery as updateNormalReducerAdditionalQuery,
    updateDropDownSelectedObjectType, updateShowSelectedCompanies, updateShowSelectedProjects, updatePeopleDetail,
    updateObjectType, updateShowHierarchy, updateHierarchyBack, updateQueryResult,
    updateOffsetLimitValue, updateFrameworkTags, fetchSearchListForAllObjectType, updateSearchListSelection,
    updatelistOfSelectedObjectIds, fetchShowMoreListOfSelectedObjectIds, updateObjectId, updatePeopleProjectsList,
    searchFilterInput, updateBuildPhase, updateBrands, updateProjectsForCompanyId, updateGroupCompanyProjectsFav,
    updateBackObjectId, updateExactSearchTextObject, updateIncludeCompletedProj, updateIfMoreResultAvailable,
    updateBuildPhaseSwitch, updateNoResult, rolesForProject, trackEvent,
    resetIncludeExcludeIds,
    updateIdsForShowRelatedEntities,
    updateRemoveFilterToggle,
    updateApiCall,
    updateIsShowProjectsOrCompanySelected,
    updateCompanyDetail

} from '../redux/reducer';
var widthOfMainSearch, children, totalWidth;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSearch />
        </components.DropdownIndicator>
    )
}

const SearchFilterModal = (props) => {
    const dispatch = useDispatch()
    const [removeFilters, setRemoveFilters] = useState([])
    const [data, setData] = useState([])
    const [removeCount, setRemoveCount] = useState(false)
    const search = useSelector((state) => state.search)
    const filter = useSelector((state) => state.filter)
    const user = useSelector((state) => state.user.user)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const appliedFilter = searchPersist && searchPersist.appliedContext
    const [tagList, setTagList] = useState(searchPersist?.summaryTags ? searchPersist?.summaryTags : [])
    const [searchTextObj, setSearchTextObj] = useState({ project: [], people: [], company: [] });
    const [exactSearchTextObj, setexactSearchTextObj] = useState({ project: "", people: "", company: "" });
    const [searchLocationObject, setSearchLocationObject] = useState({ project: {}, company: {} })

    const [productTextObj, setProductTextObj] = useState([]);
    const [frameworkTags, setFrameWorkTags] = useState([]);
    const [siteToBeSold, setSiteToBeSold] = useState(null)
    const [isBuildToRentEnabled, setIsBuildToRentEnabled] = useState(false)
    const [buildToRent, setBuildToRent] = useState(false)
    const [checkboxFilterList, setCheckboxFilterList] = useState(getIncludeExcludeFilterList())
    const [currentAdvancedSearch, setCurrentAdvancedSearch] = useState()
    const [usageTypes, setUsageTypes] = useState(searchPersist?.customerProfile?.project_usage?.values.length !== 0 ? searchPersist?.customerProfile?.project_usage?.values : [])
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false)
    const [isViewClicked, setIsViewClicked] = useState(true)
    const [greenFieldChecked, setGreenFieldChecked] = useState(false)
    const [brownFieldChecked, setBrownFieldChecked] = useState(false)
    const [isClearAll, setIsClearAll] = useState(false)
    const [isOpenconfirmation, setIsOpenconfirmation] = useState(false);
    const [isShowMoreTown, setIsShowMoreTown] = useState(true)
    const [isShowMorePostCode, setIsShowMorePostCode] = useState(true)
    const [postCodeHasMore, setPostCodeHasMore] = useState(false)
    const [hasmore, setHasMore] = useState(false)
    const [hasmoreProjectText, setHasMoreProjectText] = useState(false)
    const [autoHeight, setAutoHeight] = useState(false)
    let activeFilterValue = searchPersist.appliedContext;
    const [searchData, setSearchData] = useState({
        dropdownOpen: false,
        locationDropdownOpen: false,
        dropDownFilterType: ["project", "company", "people"],
        locationFilterType: ["Radial search", "Town search", "Postcode search"],
        activeDisplayValue: activeFilterValue,
        displayFilterDropDownText: activeFilterValue == 'people' ?
            "People" : activeFilterValue == "company" ? "Companies" : "Projects",
        displayLocationDropDownText: searchPersist.filterSearchText,
        inputValue: '',
        textPlaceholder: activeFilterValue == 'people' ?
            ("Person information") : activeFilterValue == "company" ? ("Company information") : ("Project information"),
        isShow: false,
        isDisabled: false,
        addAnyButtonValue: 'any',
        isAddAnyButtonClicked: false,
        locationCloseButton: activeFilterValue !== "people" && (searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.location && filter.filtersState[searchPersist.appliedContext]?.location?.location !== '') || (searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyLocation && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== '') ? true : false,
        clearSearch: false,
        latitude: 0,
        longitude: 0,
        distance: activeFilterValue !== "people" && (searchPersist.appliedContext === 'project' && filter.filtersState[activeFilterValue]?.location?.radius ? filter.filtersState[activeFilterValue]?.location?.radius : '') || (searchPersist.appliedContext === 'company' && filter.filtersState[activeFilterValue]?.companyLocation?.radius ? filter.filtersState[activeFilterValue]?.companyLocation?.radius : ''),
        locationSearchData: "",
        isRadialSearchActive: false,
        isButtonClicked: false,
        inputTownValue: "",
        inputPostCodeValue: "",
        isSetCurrentLocation: false,
        radialView: false,
        isToggleClicked: false,
        isCall: false,
        isUnlinkCompany: false,
        isApiCallForUnlinking: false,
        isRadialActive: false,
        isSortUpdatedForUnkingTags: false
    })

    const [multiInputValue, setMultiInputValue] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [searchLocationObj, setSearchLocationObj] = useState({ project: {}, company: {} })
    const [multiTownInputValue, setMultiTownInputValue] = useState([]);
    const [sortOrderObj, setSortOrderObj] = useState(searchPersist.defaultSort)
    const [confirmClose, setConfirmClose] = useState(false)
    const [appliedSearchFilters, setAppliedSearchFilters] = useState(filter.defaultFilter)
    const [searchMode, setSearchMode] = useState(filter.filtersState ? (fetchSearchMode(filter.filtersState?.[activeFilterValue], activeFilterValue) ? "basic" : "advanced") : "basic")
    const [tagsShowMoreClass, setTagsShowMoreClass] = useState('')
    const googleInputRef = useRef(null);
    const townSearchInput = useRef(null);
    const postCodeSearchInput = useRef(null);
    const projectSerachRef = useRef(null);
    const [textSearch, setTextSearch] = useState(false)



    useEffect(() => {
        if (projectSerachRef?.current !== null && projectSerachRef?.current !== undefined) {
            projectSerachRef.current.inputRef.maxLength = 30;
        }
    }, []);
    const projectTextInput = useRef(null);

    useEffect(() => {
        setAppliedSearchFilters(filter.filtersState)
    }, [filter.profileStore])

    useEffect(() => {
        if (search.hierarchyBack) {
            let textboxDisabled = filter.filtersState[filter.selectedSearchType]?.companyInformation?.value.length > 0 && filter.selectedSearchType === 'company'
                || filter.filtersState[filter.selectedSearchType]?.personInformation?.value.length > 0 && filter.selectedSearchType === 'people'
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: search.hierarchyBack ? false : true,
                isDisabled: textboxDisabled
            }))
            dispatch(updateHierarchyBack(false))
        }
    }, [search.hierarchyBack])

    useEffect(() => {
        toggleField('town-search');
        const townPostcodeHeightReset = (e) => {
            if (townSearchInput && !townSearchInput.current?.contains(e.target) || postCodeSearchInput && !postCodeSearchInput.current?.contains(e.target)) {
                setIsShowMoreTown(true)
                setIsShowMorePostCode(true)
            }
        }
        let textboxDisabled = filter.filtersState[filter.selectedSearchType]?.companyInformation?.value.length > 0 && filter.selectedSearchType === 'company'
            || filter.filtersState[filter.selectedSearchType]?.personInformation?.value.length > 0 && filter.selectedSearchType === 'people'
        setSearchData(prev => ({
            ...prev,
            isButtonClicked: search.hierarchyBack ? false : true,
            isDisabled: textboxDisabled
        }))
        setTimeout(() => {
            toggleProjectTextHeight()
        }, 200);
        document.addEventListener('click', townPostcodeHeightReset)
        return () => {
            document.removeEventListener('click', townPostcodeHeightReset);
        };
    }, [])


    useEffect(() => {
        if (searchPersist.saveSeachRedirectionApplied) {
            initTags(filter.selectedSearchType)
            dispatch(updateContextType({ context: searchPersist.appliedContext, searchMode: searchMode }))
            dispatch(updateIsListView(filter.selectedSearchType === 'people' ? true : searchPersist.isListView))
            let textboxDisabled = filter.filtersState[filter.selectedSearchType]?.companyInformation?.value.length > 0 && filter.selectedSearchType === 'company'
                || filter.filtersState[filter.selectedSearchType]?.personInformation?.value.length > 0 && filter.selectedSearchType === 'people'
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true,
                isDisabled: textboxDisabled
            }))
        }
    }, [searchPersist.saveSeachRedirectionApplied])

    useEffect(() => {
        if (filter.profileStore && searchPersist.loginRedirectDefaultSaveSearch) {
            let type = filter.selectedSearchType
            let search = fetchSearchMode(filter.filtersState[type], type) ? 'basic' : 'advanced'
            setSearchMode(search)
            initTags(type)
            dispatch(updateContextType({ context: type, searchMode: search }))
            dispatch(updateIsListView(filter.selectedSearchType === 'people' ? true : false))
            dispatch(updateLoginRedirectDefaultSaveSearch(false))
            let textboxDisabled = filter.filtersState[filter.selectedSearchType]?.companyInformation?.value.length > 0 && filter.selectedSearchType === 'company'
                || filter.filtersState[filter.selectedSearchType]?.personInformation?.value.length > 0 && filter.selectedSearchType === 'people'
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true,
                displayFilterDropDownText: type === 'project' ? 'Projects' : type === 'company' ? 'Companies' : 'People',
                activeDisplayValue: type,
                isDisabled: textboxDisabled,
                textPlaceholder: type === 'project' ? 'Project information' : type === 'company' ? 'Company information' : 'Person information',
            }))
        } else if (filter.profileStore && !user.default_saved_search) {
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: search.hierarchyBack ? false : true,
            }))
            dispatch(updateLogin(false))
        }
    }, [searchPersist.loginRedirectDefaultSaveSearch, filter.profileStore])

    useEffect(() => {
        if (searchPersist.notificationRedirectSaveSearch) {
            let type = filter.selectedSearchType
            let search = fetchSearchMode(filter.filtersState[type], type) ? 'basic' : 'advanced'
            setSearchMode(search)
            initTags(type)
            dispatch(updateContextType({ context: type, searchMode: search }))
            dispatch(updateNotificationRedirectSaveSearch(false))
            dispatch(updateIsListView(filter.selectedSearchType === 'people' ? true : false))
            let textboxDisabled = filter.filtersState[filter.selectedSearchType]?.companyInformation?.value.length > 0 && filter.selectedSearchType === 'company'
                || filter.filtersState[filter.selectedSearchType]?.personInformation?.value.length > 0 && filter.selectedSearchType === 'people'
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true,
                displayFilterDropDownText: type === 'project' ? 'Projects' : type === 'company' ? 'Companies' : 'People',
                activeDisplayValue: type,
                isDisabled: textboxDisabled,
                textPlaceholder: type === 'project' ? 'Project information' : type === 'company' ? 'Company information' : 'Person information',
            }))
        }
    }, [searchPersist.notificationRedirectSaveSearch])

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
            case 'Tab':
            case ",":
                handleSearchText();
        }
    };

    const handleOnBlur = (event) => {
        if (event.target.value) {
            if (searchData.activeDisplayValue === 'project') {
                setMultiInputValue(event.target.value)
            } else {
                setSearchData(prev => ({ ...prev, inputValue: event.target.value }))
            }
            handleSearchText();
        }
    }

    const handleSearchText = () => {
        if (searchData.inputValue !== '') {
            searchTextObj[searchData.activeDisplayValue].push({ label: searchData.inputValue, value: searchData.inputValue });
        }
        setSearchData((prevState) => {
            return {
                ...prevState,
                isShow: true,
                inputValue: '',
            };
        });
        setMultiInputValue((prev) => [...prev, { label: searchData.inputValue, value: searchData.inputValue }]);
        setMultiInputValue([]);
        setSearchTextObj(_.cloneDeep(searchTextObj));
        dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        if (search.showSelectedCompanies || search.showSelectedProjects) {
            searchPersist.appliedContext === "project"
                ? dispatch(updateShowSelectedCompanies(false))
                : dispatch(updateShowSelectedProjects(false));
            dispatch(updateSelectedSearchType(searchPersist.appliedContext))
            if (searchPersist.appliedContext === "company") {
                dispatch(updateHandleViewForShowCompanyShowProjects(true))
            }
            else {
                setSearchData((prevState) => ({
                    ...prevState,
                    isCall: true
                }));

            }
        }
        let projectInforArray = [];
        let values = searchTextObj && searchTextObj[searchData.activeDisplayValue];
        for (var i = 0; i < values?.length; i++) {
            if (values[i].value != undefined || values[i].value != null) {
                projectInforArray.push(values[i].value);
            }
        }
        let payload1 = {
            value: projectInforArray,
            dataObj: searchData.inputValue,
            active: projectInforArray.length > 0 ? true : false,
            keyname: searchPersist.appliedContext === 'project' ? 'projectInformation' : searchPersist.appliedContext === 'company' ? 'companyInformation' : 'personInformation',
            isAnyAll: projectInforArray.length > 1 ? searchData.addAnyButtonValue : 'any'
        };
        setTimeout(() => {
            toggleProjectTextHeight()
        }, 200);
        dispatch(updateFilterText(payload1));
        if (searchData.activeDisplayValue !== "project" && searchData.inputValue !== '') {
            setSearchData((prevState) => ({
                ...prevState,
                isDisabled: !searchData.isDisabled,
                isButtonClicked: true
            }));
            let payload = createPayload(searchData.activeDisplayValue);
            dispatch(updateAdditionalQuery(payload));
            let clonedObject = { ...sortOrderObj };
            clonedObject = { ...clonedObject, [searchPersist.appliedContext]: { label: "relevance", value: "R" } };
            setSortOrderObj(_.cloneDeep(clonedObject));
            dispatch(updateSortSearch(_.cloneDeep(clonedObject)));
            dispatch(updateSelectedSelectorControlValue(0));
            dispatch(resetRelationalEntititySelectionCount());
        }
        else {
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: false
            }));
        }
    }

    // This method is setting town and postcode search
    const handleKeyDownLocation = (event) => {
        if (searchData.displayLocationDropDownText === "Town search") {
            if (!searchData.inputTownValue) return;
            switch (event.key) {
                case 'Enter':
                    event.preventDefault()
                case 'Tab':
                case ",":
                    let townArr = searchLocationObj[searchData.activeDisplayValue].town != undefined ? searchLocationObj[searchData.activeDisplayValue].town : []
                    townArr.push({ label: searchData.inputTownValue, value: searchData.inputTownValue })
                    Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { town: townArr });
                    setSearchData((prevState) => {
                        return {
                            ...prevState,
                            inputTownValue: '',
                        }
                    })
                    setMultiTownInputValue((prev) => [...prev, { label: searchData.inputTownValue, value: searchData.inputTownValue }]);
                    setSearchLocationObj(_.cloneDeep(searchLocationObj))
                    dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObj)));
                    let projectLocationArray = []
                    for (var i = 0; i < townArr?.length; i++) {
                        if (townArr[i].value != undefined || townArr[i].value != null) {
                            projectLocationArray.push(townArr[i].value)
                        }
                    }

                    let payload = {
                        value: projectLocationArray,
                        dataObj: searchData.inputTownValue,
                        active: projectLocationArray && projectLocationArray.length > 0 ? true : false,
                        keyname: searchData.activeDisplayValue === 'project' ? "projectTown" : "companyTown"
                    }
                    dispatch(updateFilterText(payload))


            }
        }
        else {
            if (!searchData.inputPostCodeValue) return;
            switch (event.key) {
                case 'Enter':
                    event.preventDefault()
                case 'Tab':
                case ",":
                    let postcodeArr = searchLocationObj[searchData.activeDisplayValue].postcode != undefined ? searchLocationObj[searchData.activeDisplayValue].postcode : []
                    postcodeArr.push({ label: searchData.inputPostCodeValue, value: searchData.inputPostCodeValue })
                    Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { postcode: postcodeArr });
                    setSearchData((prevState) => {
                        return {
                            ...prevState,
                            inputPostCodeValue: '',
                        }
                    })
                    // setMultiPostCodeValue((prev) => [...prev, { label: searchData.inputPostCodeValue, value: searchData.inputPostCodeValue }]);
                    setSearchLocationObj(_.cloneDeep(searchLocationObj))
                    dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObj)));
                    let projectLocationArray = []
                    for (var i = 0; i < postcodeArr?.length; i++) {
                        if (postcodeArr[i].value != undefined || postcodeArr[i].value != null) {
                            projectLocationArray.push(postcodeArr[i].value)
                        }
                    }
                    let payload = {
                        value: projectLocationArray,
                        dataObj: searchData.inputPostCodeValue,
                        active: projectLocationArray && projectLocationArray.length > 0 ? true : false,
                        keyname: searchData.activeDisplayValue === 'project' ? "projectPostcode" : "companyPostcode"
                    }
                    dispatch(updateFilterText(payload))
            }
        }


    };

    useEffect(() => {
        if (!search.backObjectId && searchPersist.sortClick) {
            setSortOrderObj(_.cloneDeep(searchPersist.defaultSort))
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true
            }))
            dispatch(updateSortClick(false))
        }
    }, [searchPersist.sortClick])

    useEffect(() => {
        setUsageTypes(searchPersist?.customerProfile?.project_usage?.values)
    }, [searchPersist?.customerProfile])

    useEffect(() => {
        let locationDropDown = searchData.displayLocationDropDownText
        if (filter.filtersState[searchPersist.appliedContext]?.location && filter.filtersState[searchPersist.appliedContext]?.location?.location !== '' && searchPersist.appliedContext === 'project') {
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: filter.filtersState[searchPersist.appliedContext]?.location?.location !== '' ? true : false,
                clearSearch: filter.filtersState[searchPersist.appliedContext]?.location?.location !== '' ? false : true,
                locationSearchData: filter.filtersState[searchPersist.appliedContext]?.location?.location,
                distance: filter.filtersState[searchPersist.appliedContext]?.location?.radius
            }))
            var locationData = {
                location: filter.filtersState[searchPersist.appliedContext]?.location?.location,
                latitude: filter.filtersState[searchPersist.appliedContext]?.location?.latitude,
                longitude: filter.filtersState[searchPersist.appliedContext]?.location?.longitude,
                distance: filter.filtersState[searchPersist.appliedContext]?.location?.radius
            }
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], locationData);
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            locationDropDown = 'Radial search'
        }
        else if (filter.filtersState[searchPersist.appliedContext]?.location && filter.filtersState[searchPersist.appliedContext]?.location?.location === '' && searchPersist.appliedContext === 'project') {
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: filter.filtersState[searchPersist.appliedContext]?.location?.location !== '' ? true : false,
                clearSearch: filter.filtersState[searchPersist.appliedContext]?.location?.location !== '' ? false : true,
                locationSearchData: filter.filtersState[searchPersist.appliedContext]?.location?.location,
                distance: filter.filtersState[searchPersist.appliedContext]?.location?.radius
            }))
            var locationData = {
                location: filter.filtersState[searchPersist.appliedContext]?.location?.location,
                latitude: filter.filtersState[searchPersist.appliedContext]?.location?.latitude,
                longitude: filter.filtersState[searchPersist.appliedContext]?.location?.longitude,
                distance: filter.filtersState[searchPersist.appliedContext]?.location?.radius
            }
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], locationData);
            setSearchLocationObj(_.cloneDeep(searchLocationObj))

        }
        if (filter.filtersState[searchPersist.appliedContext]?.companyLocation && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location !== '' && searchPersist.appliedContext === 'company') {
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location === '' ? false : true,
                clearSearch: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location !== '' ? false : true,
                locationSearchData: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location,
                distance: filter.filtersState[searchPersist.appliedContext]?.companyLocation.radius
            }))
            var locationData = {
                location: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location,
                latitude: filter.filtersState[searchPersist.appliedContext]?.companyLocation.latitude,
                longitude: filter.filtersState[searchPersist.appliedContext]?.companyLocation.longitude,
                distance: filter.filtersState[searchPersist.appliedContext]?.companyLocation.radius
            }
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], locationData);
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            locationDropDown = 'Radial search'
        }
        else if (filter.filtersState[searchPersist.appliedContext]?.companyLocation && filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location === '' && searchPersist.appliedContext === 'company') {
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location === '' ? false : true,
                clearSearch: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location !== '' ? false : true,
                locationSearchData: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location,
                distance: filter.filtersState[searchPersist.appliedContext]?.companyLocation.radius
            }))
            var locationData = {
                location: filter.filtersState[searchPersist.appliedContext]?.companyLocation.location,
                latitude: filter.filtersState[searchPersist.appliedContext]?.companyLocation.latitude,
                longitude: filter.filtersState[searchPersist.appliedContext]?.companyLocation.longitude,
                distance: filter.filtersState[searchPersist.appliedContext]?.companyLocation.radius
            }
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], locationData);
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
        }
        if (filter.filtersState[searchPersist.appliedContext]?.projectTown?.value &&
            filter.filtersState[searchPersist.appliedContext]?.projectTown?.value.length > 0 && searchPersist.appliedContext === 'project') {
            let location = filter.filtersState[searchPersist.appliedContext]?.projectTown.value
            let townArr = []
            location?.map(item => {
                townArr.push({ label: item, value: item })
            })
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { town: townArr });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            setTimeout(() => {
                toggleField('town-search');
            }, 200);
            locationDropDown = 'Town search'
        } else if (filter.filtersState[searchPersist.appliedContext]?.projectTown?.value &&
            filter.filtersState[searchPersist.appliedContext]?.projectTown?.value.length === 0 && searchPersist.appliedContext === 'project') {
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { town: [] });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
        }
        if (filter.filtersState[searchPersist.appliedContext]?.companyTown?.value &&
            filter.filtersState[searchPersist.appliedContext]?.companyTown?.value.length > 0 && searchPersist.appliedContext === 'company') {
            let location = filter.filtersState[searchPersist.appliedContext]?.companyTown.value
            let townArr = []
            location?.map(item => {
                townArr.push({ label: item, value: item })
            })
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { town: townArr });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            setTimeout(() => {
                toggleField('town-search');
            }, 200);
            locationDropDown = 'Town search'
        } else if (filter.filtersState[searchPersist.appliedContext]?.companyTown?.value &&
            filter.filtersState[searchPersist.appliedContext]?.companyTown?.value.length === 0 && searchPersist.appliedContext === 'company') {
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { town: [] });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
        }
        if (filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value &&
            filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value.length > 0 && searchPersist.appliedContext === 'project') {
            let location = filter.filtersState[searchPersist.appliedContext]?.projectPostcode.value
            let postcodeArr = []
            location?.map(item => {
                postcodeArr.push({ label: item, value: item })
            })
            setTimeout(() => {
                toggleField('postcode-search');
            }, 200);
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { postcode: postcodeArr });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            locationDropDown = 'Postcode search'
        } else if (filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value &&
            filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value.length === 0 && searchPersist.appliedContext === 'project') {
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { postcode: [] });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
        }
        if (filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value &&
            filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value.length > 0 && searchPersist.appliedContext === 'company') {
            let location = filter.filtersState[searchPersist.appliedContext]?.companyPostcode.value
            let postcodeArr = []
            location?.map(item => {
                postcodeArr.push({ label: item, value: item })
            })
            setTimeout(() => {
                toggleField('postcode-search');
            }, 200);
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { postcode: postcodeArr });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
            locationDropDown = 'Postcode search'
        } else if (filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value &&
            filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value.length === 0 && searchPersist.appliedContext === 'company') {
            Object.assign(searchLocationObj && searchLocationObj[searchPersist.appliedContext], { postcode: [] });
            setSearchLocationObj(_.cloneDeep(searchLocationObj))
        }

        setSearchData((prevState) => ({
            ...prevState,
            displayLocationDropDownText: locationDropDown
        }));
        dispatch(updateFilterSearchText(locationDropDown));
    }, [filter.filtersState[searchPersist.appliedContext]?.location, filter.filtersState[searchPersist.appliedContext]?.companyLocation,
    filter.filtersState[searchPersist.appliedContext]?.projectTown, filter.filtersState[searchPersist.appliedContext]?.projectPostcode,
    filter.filtersState[searchPersist.appliedContext]?.companyTown, filter.filtersState[searchPersist.appliedContext]?.companyPostcode])

    useEffect(() => {
        let project = []
        if (filter.filtersState[searchPersist.appliedContext]?.projectInformation?.value.length > 0 && searchPersist.appliedContext === 'project') {
            _.map(filter.filtersState[searchPersist.appliedContext]?.projectInformation?.value, element => {
                project.push({ label: element, value: element })
            })
            setSearchData(prev => ({
                ...prev,
                addAnyButtonValue: filter.filtersState[activeFilterValue]?.projectInformation.isAnyAll
            }))
        }
        let company = []
        if (filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value.length > 0 && searchPersist.appliedContext === 'company') {
            let elem = filter.filtersState[searchPersist.appliedContext]?.companyInformation?.value
            company.push({ label: elem, value: elem })
        }
        let people = []
        if (filter.filtersState[searchPersist.appliedContext]?.personInformation?.value.length > 0 && searchPersist.appliedContext === 'people') {
            let elem = filter.filtersState[searchPersist.appliedContext]?.personInformation?.value
            people.push({ label: elem, value: elem })
        }
        setSearchTextObj({ project: project, company: company, people: people })
    }, [filter.filtersState[searchPersist.appliedContext]?.personInformation, filter.filtersState[searchPersist.appliedContext]?.projectInformation, filter.filtersState[searchPersist.appliedContext]?.companyInformation])

    useEffect(() => {
        // Enable BuildToRent checkbox only when Residential is selected in IncludeCategories
        if (filter.filtersState[searchPersist.appliedContext]?.includeCategories &&
            filter.filtersState[searchPersist.appliedContext]?.includeCategories.options &&
            filter.filtersState[searchPersist.appliedContext]?.includeCategories.options.length > 0) {
            let residentialCategory = filter.filtersState[searchPersist.appliedContext]?.includeCategories.options.filter(x => x.name === "Residential")
            if (residentialCategory.length > 0) {
                if (residentialCategory[0].isChildSelected || residentialCategory[0].active) {
                    setIsBuildToRentEnabled(true)
                } else {
                    setIsBuildToRentEnabled(false)
                    setBuildToRent(false)
                    let payload = {
                        isSelected: false,
                        value: usageTypes ? usageTypes.buildToRent : null,
                    }
                    dispatch(updateCategoryUsageTypeFilter(payload))
                }
            }

        }
    }, [filter.filtersState[filter.selectedSearchType].includeCategories, filter.filtersState[filter.selectedSearchType].excludeCategories])


    useEffect(() => {
        if (!filter?.filtersState[filter.selectedSearchType]?.projectUsage?.active && filter?.filtersState[filter.selectedSearchType]?.projectUsage?.value?.length === 0) {
            setSiteToBeSold(false)
        }
        if (filter?.filtersState[filter.selectedSearchType]?.projectUsage?.value?.length > 0 && filter?.filtersState[filter.selectedSearchType]?.projectUsage?.value?.find(x => x === 11)) {
            setSiteToBeSold(true)
        }
        if (isBuildToRentEnabled && filter?.filtersState[filter.selectedSearchType]?.projectUsage?.value?.length > 0 && filter?.filtersState[filter.selectedSearchType]?.projectUsage?.value?.find(x => x === 7)) {
            setBuildToRent(true)
        }
    }, [filter?.filtersState[filter.selectedSearchType]?.projectUsage, isBuildToRentEnabled])


    useEffect(() => {
        if (!filter?.filtersState[filter.selectedSearchType]?.projectLandType?.active && filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.length === 0) {
            setBrownFieldChecked(false)
            setGreenFieldChecked(false)
        }
        if (filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.length > 0 && filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.find(x => x === 1)) {
            setGreenFieldChecked(true)
        }
        if (filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.length > 0 && filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.find(x => x === 2)) {
            setBrownFieldChecked(true)
        }
    }, [filter?.filtersState[filter.selectedSearchType]?.projectLandType])


    useEffect(() => {
        if (!filter?.filtersState[filter.selectedSearchType]?.projectLandType?.active && filter?.filtersState[filter.selectedSearchType]?.projectLandType?.value?.length === 0) {
            setBrownFieldChecked(false)
            setGreenFieldChecked(false)
        }
    }, [filter.filtersState[filter.selectedSearchType].projectLandType])

    // useEffect(() => {
    //     if (!filter.onNoFilterOption || searchPersist.summaryTags) {
    //         if (searchPersist.summaryTags?.length > 0) {
    //             _.map(searchPersist.summaryTags, tags => {
    //                 if (tags.title === 'projectInformation' && searchData.activeDisplayValue === 'project') {
    //                     let project = []
    //                     if (tags?.value?.includes(',') && tags?.value?.split(",")?.length > 0) {
    //                         _.map(tags.value.split(","), element => {
    //                             project.push({ label: element, value: element.replace('(Any)', "") })
    //                         })
    //                     } else {
    //                         project.push({ label: tags.value[0], value: tags.value[0] })
    //                     }
    //                     setSearchTextObj((prevState) => {
    //                         return {
    //                             ...prevState,
    //                             project: project
    //                         }
    //                     })
    //                 }
    //                 if (tags.title === 'companyInformation' && tags?.value?.length > 0 && searchData.activeDisplayValue === 'company') {
    //                     let company = []
    //                     company.push({ label: tags.value, value: tags.value })
    //                     setSearchTextObj((prevState) => {
    //                         return {
    //                             ...prevState,
    //                             company: company
    //                         }
    //                     })
    //                 }
    //                 if (tags.title === 'personInformation' && tags?.value?.length > 0 && searchData.activeDisplayValue === 'people') {
    //                     let people = []
    //                     people.push({ label: tags.value, value: tags.value })
    //                     setSearchTextObj((prevState) => {
    //                         return {
    //                             ...prevState,
    //                             people: people
    //                         }
    //                     })
    //                 }
    //                 if (tags.title === 'projectTown' && searchData.activeDisplayValue === 'project') {
    //                     let location = []
    //                     location = extractTownPostcode(tags, location);
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { town: location });
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Town search',
    //                     }));
    //                 }
    //                 if (tags.title === 'projectPostcode' && searchData.activeDisplayValue === 'project') {
    //                     let location = []
    //                     location = extractTownPostcode(tags, location);
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { postcode: location });
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Postcode search',
    //                     }));
    //                 }
    //                 if (tags.title === 'location' && searchData.activeDisplayValue === 'project') {
    //                     var locationData = {
    //                         location: tags?.value,
    //                         latitude: tags?.lat ? parseFloat(tags?.lat) : tags?.lat,
    //                         longitude: tags?.lng ? parseFloat(tags?.lng) : tags?.lng,
    //                         radius: tags?.radius ? parseFloat(tags?.radius) : tags?.radius,
    //                         active: true,
    //                         keyname: "location"
    //                     }
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], locationData);
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Radial search',
    //                     }));
    //                 }
    //                 if (tags.title === 'companyTown' && searchData.activeDisplayValue === 'company') {
    //                     let location = []
    //                     location = extractTownPostcode(tags, location);
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { town: location });
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Town search',
    //                     }));
    //                 }
    //                 if (tags.title === 'companyPostcode' && searchData.activeDisplayValue === 'company') {
    //                     let location = []
    //                     location = extractTownPostcode(tags, location);
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { postcode: location });
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Postcode search',
    //                     }));
    //                 }
    //                 if (tags.title === 'companyLocation' && searchData.activeDisplayValue === 'company') {
    //                     var locationData = {
    //                         location: tags?.value,
    //                         latitude: tags?.lat ? parseFloat(tags?.lat) : tags?.lat,
    //                         longitude: tags?.lng ? parseFloat(tags?.lng) : tags?.lng,
    //                         radius: tags?.radius ? parseFloat(tags?.radius) : tags?.radius,
    //                         active: true,
    //                         keyname: "companyLocation"
    //                     }
    //                     Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], locationData);
    //                     setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //                     setSearchData((prevState) => ({
    //                         ...prevState,
    //                         displayLocationDropDownText: 'Radial search',
    //                     }));
    //                 }
    //             })
    //         }
    //     } else {
    //         setSearchTextObj({
    //             project: [], people: [], company: []
    //         })
    //         Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { postcode: [], town: [] });
    //         setSearchLocationObj(_.cloneDeep(searchLocationObj))
    //     }
    // }, [searchPersist.summaryTags, filter.onNoFilterOption])

    useEffect(() => {
        const executeSearch = filter.filtersState[searchPersist.appliedContext].offset !== undefined && filter.filtersState[searchPersist.appliedContext].offset === 0 ? true
            : filter.filtersState[searchPersist.appliedContext].offset === undefined ? true : false
        if (searchData.isButtonClicked && !search.backObjectId && executeSearch) {
            fetchSearchList();
        } else if (searchData.isButtonClicked && executeSearch) {
            fetchSearchList();
        }
    }, [searchData.isButtonClicked])


    useEffect(() => {
        if (search.isApiCallFinish) {
            dispatch(updateApiCall(false))
        }

    }, [search?.isApiCallFinish])

    useEffect(() => {
        setSearchData((prevState) => ({
            ...prevState,
            displayLocationDropDownText: searchPersist.filterSearchText,
        }))
    }, [searchPersist.filterSearchText])

    useEffect(() => {
        if (filter.newSearchClick === true || filter.searchHere) {
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true,
                isDisabled: false
            }))
            dispatch(updateNewSearch(false))
            dispatch(updateSearchHere(false))
            setSearchMode(filter.newSearchClick === true ? 'basic' : searchMode)
            setTagList(filter.newSearchClick === true ? [] : tagList)
        }
    }, [filter.newSearchClick, filter.searchHere])

    useEffect(() => {
        if (searchData?.isUnlinkCompany && search.isApiCallFinish && searchData.isApiCallForUnlinking) {
            setSearchData((prevState) => ({
                ...prevState,
                isDisabled: false,
                isUnlinkCompany: false,
                isApiCallForUnlinking: false
            }));
            if (search.showCompanyHierarchy) {
                dispatch(updateProjectsForCompanyId([]));
                dispatch(updateObjectId(undefined));
                dispatch(updateCompanyDetail(undefined))
                dispatch(
                    updateShowHierarchy({
                        showProjectHierarchy: false,
                        showCompanyHierarchy: false,
                    })
                );
                dispatch(updateRemoveFilterToggle(true))
                dispatch(updateHierarchyBack(true))
                dispatch(updateProjectsForCompanyId([]));
            }
        }


    }, [searchData?.isUnlinkCompany, search.isApiCallFinish, searchData.isApiCallForUnlinking])


    useEffect(() => {
        if (searchData?.isUnlinkCompany && ((filter.filtersState[searchPersist?.appliedContext]?.companyInformation?.active === false && searchPersist?.appliedContext === "company") || (filter.filtersState[searchPersist?.appliedContext]?.personInformation?.active === false && searchPersist?.appliedContext === "people"))) {
            updateSortOnClick(searchTextObj, searchData, searchPersist, sortOrderObj, searchLocationObj);
        }
    }, [searchData?.isUnlinkCompany, filter.filtersState[searchPersist?.appliedContext]?.companyInformation, filter.filtersState[searchPersist?.appliedContext]?.personInformation])


    useEffect(() => {
        if (searchData?.isSortUpdatedForUnkingTags) {
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true,
                isSortUpdatedForUnkingTags: false
            }));


        }
    }, [searchData?.isSortUpdatedForUnkingTags])

    const fetchSearchList = async () => {
        var formValues;

        if (search?.showSelectedCompanies || search?.showSelectedProjects) {
            let type = searchPersist.appliedContext === "project" ? 'company' : 'project';
            formValues = preProcessorQuery('', filter.filtersState, type, search, searchPersist);
            if (searchPersist?.appliedContext === 'project' && search?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.project_id = {
                    operator: '=',
                    value: search?.idsForShowRelatedEntities
                };
            }

            if (searchPersist?.appliedContext === 'company' && search?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.company_id = {
                    operator: '=',
                    value: search?.idsForShowRelatedEntities
                };
            }
        } else {
            formValues = preProcessorQuery('', filter.filtersState, searchData.activeDisplayValue);
        }
        dispatch(updateApiCall(false))
        await fetchSearchListCount(formValues, dispatch, search, settings);
        setSearchData((prevState) => ({
            ...prevState,
            isRadialSearchActive: false,
            isApiCallForUnlinking: searchData.isUnlinkCompany ? true : false,
            isButtonClicked: false
        }));
        dispatch(updateFilterQuery(formValues));

    }

    const handleFilterDropdownText = async(type, filterState) => {
        if (search.objectId === undefined) {
            dispatch(updateSearchLoader(true))
        }
        else {
            dispatch(updateSearchLoader(false))
        }
        let textboxDisabled = filterState.filtersState[type]?.companyInformation?.value.length > 0 && type === 'company'
            || filterState.filtersState[type]?.personInformation?.value.length > 0 && type === 'people'
        setSearchData((prevState) => {
            return {
                ...prevState,
                dropdownOpen: false,
                activeDisplayValue: type,
                displayLocationDropDownText: searchPersist.filterSearchText,
                displayFilterDropDownText: type === 'project' ? 'Projects' : type === 'company' ? 'Companies' : 'People',
                textPlaceholder: type === 'project' ? 'Project information' : type === 'company' ? 'Company information' : 'Person information',
                isDisabled: textboxDisabled,

            };
        });

        await dispatch(fetchSearchListForAllObjectType([]))
        dispatch(updateAppliedContext(type));
        dispatch(updateSelectedSearchType(type));
        setMultiInputValue([])
        let payload = createPayload(type);
        dispatch(updateAdditionalQuery(payload))
        dispatch((updateNormalReducerAdditionalQuery(payload)))
        setSearchData(prev => ({ ...prev, isButtonClicked: true }))
        dispatch(updateDropDownSelectedObjectType(true))
        dispatch(updateShowSelectedCompanies(false))
        dispatch(updateShowSelectedProjects(false))
        dispatch(updatePeopleDetail(false))
        dispatch(updateObjectType(''))
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        dispatch(updateSelectedSelectorControlValue(0))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateActivityIsClicked(false))
        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }))
        dispatch(fetchShowMoreListOfSelectedObjectIds({ leadsFlags: [], count: 0 }))
        dispatch(updateObjectId(undefined))
        dispatch(updateBackObjectId(undefined))
        dispatch(updateHierarchyBack(false))
        dispatch(updatePeopleProjectsList(null))
        dispatch(searchFilterInput({ key: 'includeCompletedProjects', value: false }))
        dispatch(searchFilterInput({ key: 'buildPhaseToggle', value: false }))
        dispatch(updateBuildPhase([]))
        dispatch(updateIsProjectSelected({ isProjectSelected: false }))
        dispatch(updateGroupCompanyProjectsFav([]))
        dispatch(updateGroupCompaniesDetails([]))
        setSearchData(prev => ({
            ...prev,
            locationSearchData: "",
            latitude: "",
            longitude: "",
            distance: ""
        }))
        dispatch(updateProjectsForCompanyId([]))
        dispatch(rolesForProject([]))
        let searchModeValue = fetchSearchMode(filterState.filtersState[type], type) ? "basic" : "advanced"
        dispatch(updateContextType({ context: type, searchMode: searchModeValue }))
        setSearchMode(searchModeValue)
        let sort = fetchHandleSubmitSort(type, filterState.filtersState[type], true)
        dispatch(updateSort(sort))
        let updatedSortOrderObj = _.cloneDeep(searchPersist.defaultSort);
        updatedSortOrderObj[type] = {
            label: fetchSortLabel(sort),
            value: sort,
        };
        dispatch(updateSortSearch(updatedSortOrderObj));
        if (type === 'people') {
            dispatch(updateIsListView(true))
        }
        dispatch(updateOffsetLimit({ offset: 0, records: 50 }))
        initTags(type)
    }

    const handleLocationFilter = (type) => {
        setSearchData(prev => ({
            ...prev, displayLocationDropDownText: type,
            clearSearch: true,
            locationCloseButton: false,
            isToggleClicked: true
        }))
        let key = type === "Town search" ? "town" : "postcode";
        searchLocationObject[activeFilterValue].location = ""
        searchLocationObject[activeFilterValue].latitude = ""
        searchLocationObject[activeFilterValue].longitude = ""
        searchLocationObject[activeFilterValue].distance = ""
        searchLocationObject[activeFilterValue][key] = []
        setSearchLocationObj(_.cloneDeep(searchLocationObject))
        // dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        handleFocus(type)
    }

    const handleFocus = (tab) => {
        if (tab == "Radial search") {
            let payload = {
                value: [],
                dataObj: [],
                active: false,
                keyname: searchData.activeDisplayValue === 'project' ? 'projectTown' : 'companyTown'
            }
            dispatch(updateFilterText(payload))
            payload.keyname = searchData.activeDisplayValue === 'project' ? 'projectPostcode' : 'companyPostcode'
            dispatch(updateFilterText(payload))
        } else if (tab === "Town search" || tab === "Postcode search") {
            let payload = {
                value: [],
                dataObj: [],
                active: false,
                keyname: tab === "Town search" ? (searchData.activeDisplayValue === 'project' ? 'projectPostcode' : 'companyPostcode') : (searchData.activeDisplayValue === 'project' ? 'projectTown' : 'companyTown')
            }
            dispatch(updateFilterText(payload))
            var locationData = { location: '', latitude: 0, longitude: 0, radius: MIN_MILES, active: false, keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation" }
            dispatch(updateAdvanceLocation(locationData))
        }
    }

    const handleSubmit = () => {
        if (searchData?.isCall) {
            setSearchData((prevState) => ({
                ...prevState,
                isCall: false
            }));
            dispatch(updateHandleViewForShowCompanyShowProjects(true))
        }

        if (searchData.displayLocationDropDownText === "Radial search" && searchData.clearSearch) {
            setSearchData(prev => ({
                ...prev,
                isRadialSearchActive: true,
            }))
        }
        if (searchData.radialView) {
            setSearchData(prevState => ({
                ...prevState,
                radialView: false
            }))
        }
        if (searchData.activeDisplayValue !== 'people') {
            dispatch(updateFilterSearchText(searchData.displayLocationDropDownText));
        }

        // dispatch(updateSearchTextObj(_.cloneDeep(searchTextObj)));
        if (searchData.displayLocationDropDownText === "Radial search") {
            setSearchData(prev => ({
                ...prev,
                isRadialActive: true,
            }))
            let filterLocation = searchData.activeDisplayValue === 'project' ? filter.filtersState[searchPersist.appliedContext]?.location : filter.filtersState[searchPersist.appliedContext]?.companyLocation
            if (searchLocationObj[searchData.activeDisplayValue]) {
                searchLocationObj[searchData.activeDisplayValue].latitude = searchData.latitude
                searchLocationObj[searchData.activeDisplayValue].longitude = searchData.longitude
                searchLocationObj[searchData.activeDisplayValue].distance = searchData.distance
                searchLocationObj[searchData.activeDisplayValue].location = filterLocation.location
            }
            if (searchData.distance !== filterLocation.radius) {
                var locationData = {
                    location: filterLocation.location,
                    latitude: filterLocation.latitude,
                    longitude: filterLocation.longitude,
                    radius: searchData.distance,
                    active: true,
                    keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation"
                }
                dispatch(updateAdvanceLocation(locationData))
            }
        }
        setSearchLocationObj(_.cloneDeep(searchLocationObj))
        dispatch(updateLocationSearchObj(_.cloneDeep(searchLocationObj)));
        if (searchData.locationSearchData === "") {
            var locationData = { location: '', latitude: 0, longitude: 0, radius: MIN_MILES, active: false, keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation" }
            dispatch(updateAdvanceLocation(locationData))
        }
        if (searchData.activeDisplayValue === 'project' || searchData.activeDisplayValue === 'company') {
            let townArr = []
            if (searchData.activeDisplayValue === 'project' && searchLocationObj[searchData.activeDisplayValue].town && searchLocationObj[searchData.activeDisplayValue].town.length !== filter.filtersState[searchPersist.appliedContext]?.projectTown?.value?.length) {
                _.map(searchLocationObj[searchData.activeDisplayValue].town, town => {
                    townArr.push(town.value)
                })
                let townPayload = {
                    value: townArr,
                    dataObj: townArr,
                    active: townArr.length > 0 ? true : false,
                    keyname: 'projectTown'
                }
                dispatch(updateFilterText(townPayload))
            }
            if (searchData.activeDisplayValue === 'company' && searchLocationObj[searchData.activeDisplayValue].town && searchLocationObj[searchData.activeDisplayValue].town?.length !== filter.filtersState[searchPersist.appliedContext]?.companyTown?.value?.length) {
                _.map(searchLocationObj[searchData.activeDisplayValue].town, town => {
                    townArr.push(town.value)
                })
                let townPayload = {
                    value: townArr,
                    dataObj: townArr,
                    active: townArr.length > 0 ? true : false,
                    keyname: 'companyTown'
                }
                dispatch(updateFilterText(townPayload))
            }

            let postcodeArr = []
            if (searchData.activeDisplayValue === 'project' && searchLocationObj[searchData.activeDisplayValue].postcode && searchLocationObj[searchData.activeDisplayValue].postcode?.length !== filter.filtersState[searchPersist.appliedContext]?.projectPostcode?.value?.length) {
                _.map(searchLocationObj[searchData.activeDisplayValue].postcode, postcode => {
                    postcodeArr.push(postcode.value)
                })
                let postcodePayload = {
                    value: postcodeArr,
                    dataObj: postcodeArr,
                    active: postcodeArr.length > 0 ? true : false,
                    keyname: 'projectPostcode'
                }
                dispatch(updateFilterText(postcodePayload))
            }
            if (searchData.activeDisplayValue === 'company' && searchLocationObj[searchData.activeDisplayValue].postcode && searchLocationObj[searchData.activeDisplayValue].postcode?.length !== filter.filtersState[searchPersist.appliedContext]?.companyPostcode?.value?.length) {
                _.map(searchLocationObj[searchData.activeDisplayValue].postcode, postcode => {
                    postcodeArr.push(postcode.value)
                })
                let postcodePayload = {
                    value: postcodeArr,
                    dataObj: postcodeArr,
                    active: postcodeArr.length > 0 ? true : false,
                    keyname: 'companyPostcode'
                }
                dispatch(updateFilterText(postcodePayload))
            }
        }

        if (searchTextObj[searchData.activeDisplayValue]?.length === 0) {
            let payload1 = {
                value: [],
                dataObj: [],
                active: false,
                keyname: searchData.activeDisplayValue === 'project' ? "projectInformation"
                    : searchData.activeDisplayValue === 'company' ? "companyInformation" : "personInformation",
                isAnyAll: 'any'
            }
            dispatch(updateFilterText(payload1))
        } else {
            if (((searchData.activeDisplayValue === 'project' && searchTextObj[searchData.activeDisplayValue].length !== filter.filtersState[searchPersist.appliedContext]?.projectInformation?.value?.length)) || ((searchData.activeDisplayValue === 'project' || searchData.activeDisplayValue === 'company') && filter.filtersState[searchPersist.appliedContext]?.projectInformation?.value?.length > 0 && searchData.addAnyButtonValue !== filter.filtersState[searchPersist.appliedContext].projectInformation.isAnyAll)) {
                let projectText = []
                _.map(searchTextObj[searchData.activeDisplayValue], text => {
                    projectText.push(text.value)
                })
                let payload1 = {
                    value: projectText,
                    dataObj: [],
                    active: true,
                    keyname: "projectInformation",
                    isAnyAll: searchTextObj[searchData.activeDisplayValue].length > 1 ? searchData.addAnyButtonValue : 'any'
                }
                dispatch(updateFilterText(payload1))
            }
        }
        let payload = createPayload(searchData.activeDisplayValue);
        dispatch(updateAdditionalQuery(payload))
        updateSortOnClick(searchTextObj, searchData, searchPersist, sortOrderObj, searchLocationObj);
        dispatch(updateSelectedSelectorControlValue(0))
        dispatch(resetRelationalEntititySelectionCount())
        // if (search.selectedSelectorControlValue !== 0) {
        dispatch(updateSearchListSelection(0))
        // }
        // if (search.countOfSelectedObjectIds !== 0) {
        dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
        // }
        dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateHierarchyBack(false))
        setAppliedSearchFilters(filter.filtersState)
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        updateWidthSearchBoxTile();
        dispatch(updateSummaryTags(tagList))

        if (!searchData?.isUnlinkCompany) {
            setSearchData(prev => ({
                ...prev,
                isButtonClicked: true
            }))
        }



    }

    function updateSortOnClick(searchTextObj, searchData, searchPersist, sortOrderObj, searchLocationObj) {
        const appliedContext = searchPersist.appliedContext;
        const activeDisplayValue = searchData.activeDisplayValue;
        const locationSearchObj = searchLocationObj[activeDisplayValue];
        const defaultSort = searchPersist.defaultSort[activeDisplayValue];
        let updatedSortOrderObj = sortOrderObj ? { ...sortOrderObj } : {};
        if (
            (activeDisplayValue === 'project' || activeDisplayValue === 'company') && (locationSearchObj?.location !== '' && locationSearchObj?.location !== undefined)
        ) {
            updatedSortOrderObj[appliedContext] = {
                label: 'nearest',
                value: fetchSortValue('nearest', activeDisplayValue),
            };
        } else if (activeDisplayValue === 'project' || activeDisplayValue === 'company') {
            if (searchTextObj && searchTextObj[activeDisplayValue]?.length > 0 || filter.filtersState[appliedContext]?.projectInformation?.active || filter.filtersState[appliedContext]?.companyInformation?.active) {
                updatedSortOrderObj[appliedContext] = {
                    label: 'relevance',
                    value: fetchSortValue('relevance', activeDisplayValue),
                };
            } else {
                updatedSortOrderObj[appliedContext] = {
                    label:
                        defaultSort.value === 'project_start_min,project_id'
                            ? 'Start Date'
                            : defaultSort.value === '-project_value'
                                ? 'Value'
                                : defaultSort.value === 'project_finish_max,project_id'
                                    ? 'End Date'
                                    : defaultSort.value === "-count_project_id" ? "Projects"

                                        : 'Latest',
                    value: fetchSortValue(
                        defaultSort.label === 'Value'
                            ? 'value'
                            : defaultSort.label === 'Start Date'
                                ? 'start date'
                                : defaultSort.label === 'Projects' ? 'projects'
                                    : 'Latest',
                        activeDisplayValue
                    ),
                };
            }
        } else if (activeDisplayValue === 'people') {
            if (searchTextObj && searchTextObj[activeDisplayValue]?.length > 0) {
                updatedSortOrderObj[appliedContext] = {
                    label: 'relevance',
                    value: fetchSortValue('relevance', activeDisplayValue),
                };
            } else {
                updatedSortOrderObj[appliedContext] = {
                    label: 'alphabetical',
                    value: fetchSortValue('alphabetical', activeDisplayValue),
                };
            }
        }
        setSortOrderObj(updatedSortOrderObj);
        dispatch(updateSortSearch(updatedSortOrderObj));
        dispatch(updateSort(updatedSortOrderObj[activeDisplayValue].value))
        if (searchData?.isUnlinkCompany) {
            setSearchData(prev => ({
                ...prev,
                isSortUpdatedForUnkingTags: true
            }))
        }


    }

    const toggleAnyAll = (e, type) => {
        setSearchData(prev => ({
            ...prev,
            addAnyButtonValue: type,
            isAddAnyButtonClicked: true
        }))
        dispatch(toggleAnyAllVal(type))
    }

    const handleUnLinkTags = (item) => {
        searchTextObj[searchData.activeDisplayValue] = _.filter(searchTextObj[searchData.activeDisplayValue], (elem) => {
            return elem.label !== item.label
        })
        setSearchTextObj(_.cloneDeep(searchTextObj))
        dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        if (searchData.activeDisplayValue === 'project') {
            let projectInforArray = []
            let values = searchTextObj?.[searchData.activeDisplayValue]
            for (var i = 0; i < values?.length; i++) {
                if (values[i].value != undefined || values[i].value != null) {
                    projectInforArray.push(values[i].value)
                }
            }
            let payload1 = {
                value: projectInforArray,
                dataObj: projectInforArray,
                active: projectInforArray.length > 0 ? true : false,
                keyname: "projectInformation",
                isAnyAll: projectInforArray.length > 1 ? searchData.addAnyButtonValue : 'any'
            }
            dispatch(updateFilterText(payload1))
            setTimeout(() => {
                toggleProjectTextHeight()
            }, 200);
        } else if (searchData.activeDisplayValue === 'company') {
            dispatch(updateDisplayKey({ key: 'companyInformation', text: '' }))
        } else if (searchData.activeDisplayValue === 'people') {
            dispatch(updateDisplayKey({ key: 'personInformation', text: '' }))
        }
        if (searchData.activeDisplayValue !== "project") {
            setSearchData((prevState) => ({
                ...prevState,
                isUnlinkCompany: true,
            }))
        }

    }

    const createPayload = (filterType) => {
        let query = {};
        let query1 = {};
        let result = {};
        let addAnyValue;
        let key = filterType == "project" ? "project_text" : filterType === "company" ? "company_text" : "person_text"
        let values = [], town = [], postcode = [];
        if (searchTextObj && searchTextObj[filterType].length > 0) {
            searchTextObj[filterType].map((elem) => {
                values.push(elem.value)
            })
            query = { [key]: { "operator": "=", "value": filterType == "project" ? values : values[0] } }
        }
        if (filterType == "project" && searchTextObj && searchTextObj[filterType].length > 1) {
            result.addAnyValue = searchData.isAddAnyButtonClicked ? searchData.addAnyButtonValue : "any"
        }
        if (filterType !== "people" && searchLocationObj && searchLocationObj[filterType].location !== undefined && searchLocationObj[filterType].location !== "" && searchLocationObj[filterType].longitude != "" && searchData.distance != "" && searchLocationObj[filterType].longitude != undefined && searchData.displayLocationDropDownText === "Radial search" && !searchData.clearSearch) {
            let latitudeKey = filterType === "project" ? "project_latitude" : "company_latitude"
            let longitudeLey = filterType === "project" ? "project_longitude" : "company_longitude"
            let distanceKey = filterType === "project" ? "project_distance" : "company_distance"
            query1 = {
                [latitudeKey]: { "operator": "=", "value": searchLocationObj[filterType].latitude }, [longitudeLey]: { "operator": "=", "value": searchLocationObj[filterType].longitude }, [distanceKey]: { "operator": "=", "value": searchData.distance }
            }

        }
        else if (filterType !== "people" && searchData.displayLocationDropDownText === "Town search") {
            if (searchLocationObj && searchLocationObj[filterType].town && searchLocationObj[filterType].town.length > 0) {
                searchLocationObj[filterType].town.map((elem) => {
                    town.push(elem.value)
                })
                let townKey = filterType === "project" ? "project_town" : "company_town"
                query1 = { [townKey]: { "operator": "=", "value": town } }
            }

        }
        else {
            if (filterType !== "people" && searchLocationObj && searchLocationObj[filterType].postcode && searchLocationObj[filterType].postcode.length > 0) {
                searchLocationObj[filterType].postcode.map((elem) => {
                    postcode.push(elem.value)
                })
                let postCodeKey = filterType === "project" ? "project_postcode" : "company_postcode"
                query1 = { [postCodeKey]: { "operator": "=", "value": postcode } }
            }

        }
        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    const suggestionSelect = (result, lat, lng, text, radius) => {
        if (radius == searchData.distance || searchData.distance == '') {
            setSearchData(prevState => ({
                ...prevState,
                distance: radius
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                distance: searchData.distance
            }))
        }
        setSearchData(prev => ({
            ...prev,
            locationCloseButton: result && result.length > 0 ? true : false,
            clearSearch: false,
            latitude: lat ? parseFloat(lat) : lat,
            longitude: lng ? parseFloat(lng) : lng,
            distance: radius ? parseFloat(radius) : radius,
            locationSearchData: result
        }))
        searchLocationObject[searchData.activeDisplayValue].location = result
        setSearchLocationObj(_.cloneDeep(searchLocationObject))
        // dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        var locationData = {
            location: result,
            latitude: lat ? parseFloat(lat) : lat,
            longitude: lng ? parseFloat(lng) : lng,
            radius: radius ? parseFloat(radius) : radius,
            active: true,
            keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation"
        }
        dispatch(updateAdvanceLocation(locationData))
    }

    const handleLocationClose = (event) => {
        event.preventDefault()
        setSearchData(prev => ({
            ...prev,
            locationCloseButton: false,
            clearSearch: true,
            locationSearchData: "",
            latitude: "",
            longitude: "",
            distance: ""

        }))
        dispatch(updateQueryResult([]))
        searchLocationObject[searchData.activeDisplayValue].location = ""
        searchLocationObject[searchData.activeDisplayValue].latitude = ""
        searchLocationObject[searchData.activeDisplayValue].longitude = ""
        searchLocationObject[searchData.activeDisplayValue].distance = ""

        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        var locationData = { location: '', latitude: 0, longitude: 0, radius: MIN_MILES, active: false, keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation" }
        dispatch(updateAdvanceLocation(locationData))
    }

    const enableCurrentLocation = () => {
        let lat = null,
            lng = null
        navigator.geolocation.getCurrentPosition(position => {
            lat = position.coords.latitude
            lng = position.coords.longitude
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: true,
                clearSearch: false,
                latitude: lat ? parseFloat(lat) : lat,
                longitude: lng ? parseFloat(lng) : lng,
                distance: MIN_MILES,
                locationSearchData: "Current location",
                isSetCurrentLocation: true
            }))
            searchLocationObject[searchData.activeDisplayValue].location = "Current location"
            searchLocationObject[searchData.activeDisplayValue].latitude = lat ? parseFloat(lat) : lat
            searchLocationObject[searchData.activeDisplayValue].longitude = lng ? parseFloat(lng) : lng
            searchLocationObject[searchData.activeDisplayValue].distance = MIN_MILES
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            // dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
            var locationData = {
                location: "Current location",
                latitude: lat ? parseFloat(lat) : lat,
                longitude: lng ? parseFloat(lng) : lng,
                radius: MIN_MILES,
                active: true,
                keyname: searchData.activeDisplayValue === 'project' ? "location" : "companyLocation"
            }
            dispatch(updateAdvanceLocation(locationData))
        })
    }

    /****** Search Filter --- Modal */
    const handleFilter = () => {
        setIsOpen(true)
        setSearchData(prev => ({
            ...prev,
            isToggleClicked: false,
            isButtonClicked: false
        }))
        dispatch(updateOnNoFilterOption(false))
        dispatch(updateSelectedSearchType(searchData.activeDisplayValue))
        setIsViewClicked(false)
        dispatch(updateFilterActive(true))
    }

    useEffect(() => {
        if (filter.isFilterActive === true) {
            fetchEntityCounts()
        }
    }, [filter.isFilterActive])

    const handleCancel = () => {
        // setIsOpen(false)
        setConfirmClose(true)
    }

    const handleConfirmCancel = () => {
        setConfirmClose(false)
        setIsOpen(false)
        dispatch(updateFilterActive(false))
        setIsViewClicked(true)
    }

    const handleRadial = () => {
        if (searchData.radialView) {
            setSearchData(prevState => ({
                ...prevState,
                radialView: false
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                radialView: true
            }))
        }
    }

    const setValue = (value) => {
        setSearchData(prevState => ({
            ...prevState,
            distance: value
        }))
    }

    const onSliderChange = (value) => {
        setSearchData(prevState => ({
            ...prevState,
            distance: value
        }))

        let tempFilter = searchPersist && searchPersist.locationSearchObj && searchPersist.locationSearchObj[activeFilterValue] && searchPersist.locationSearchObj[activeFilterValue].location;
        if (activeFilterValue === "project" || activeFilterValue === "company") {
            if (tempFilter) {
                tempFilter.distance = value;
            }
        }
    }

    const handleTownSearchChange = (value) => {
        setMultiTownInputValue(value)
        let townArr = []
        value && value.map(item => {
            townArr.push({ label: item.label, value: item.value })
        })
        setTimeout(() => {
            toggleField('town-search');
        }, 200);
        Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { town: townArr });
        setSearchLocationObj(_.cloneDeep(searchLocationObj))
        // dispatch((updateLocationSearchObject(searchLocationObj)))
    }

    const handlePostcodeSearchChange = (value) => {
        // setMultiPostCodeValue(value)
        let postcodArr = []
        value && value.map(item => {
            postcodArr.push({ label: item.label, value: item.value })
        })
        setTimeout(() => {
            toggleField('postcode-search');
        }, 200);
        Object.assign(searchLocationObj && searchLocationObj[searchData.activeDisplayValue], { postcode: postcodArr });
        setSearchLocationObj(_.cloneDeep(searchLocationObj))
        // dispatch((updateLocationSearchObject(searchLocationObj)))
    }

    const updateAppliedSearchFilters = () => {
        setAppliedSearchFilters(filter.filtersState)
        setSearchLocationObj({ project: {}, company: {} })
        setSearchTextObj({ project: [], people: [], company: [] })
    }

    const updateActiveDisplayValue = (type) => {
        setSearchData((prevState) => {
            return {
                ...prevState,
                activeDisplayValue: type
            }
        })
    }

    useEffect(() => {
        setRemoveCount(false)
    }, [searchPersist])

    useEffect(() => {
        if (!textSearch) {
            setCurrentAdvancedSearch(filter?.filtersState[appliedFilter])
            fetchEntityCounts()
            if (tagList.length === 0 && searchMode === 'advanced' && !searchPersist.saveSeachRedirectionApplied) {
                dispatch(updateContextType({ context: searchData.activeDisplayValue, searchMode: 'basic' }))
                setSearchMode('basic')
                setSearchData(prevState => ({ ...prevState, isDisabled: false }));
            }
            if (searchPersist.saveSeachRedirectionApplied && tagList.length !== 0) {
                dispatch(updateSaveSeachRedirectionApplied(false))
            }
        }
        setTextSearch(false)
    }, [filter.filtersState])

    const handleSearchTextFromFilter = () => {
        setTextSearch(true)
    }

    const initTags = (contextType) => {
        let type = contextType ? contextType : searchPersist.appliedContext
        let i = 0,
            j = 0,
            addTagArr = [],
            removeTagArr = [];
        //project information
        let tempFilter = filter.filtersState[type]
        if (tempFilter?.projectInformation?.active &&
            tempFilter?.projectInformation?.value != ""
        ) {
            let message = Array.isArray(tempFilter.projectInformation.value) &&
                tempFilter.projectInformation.value.length > 1 ?
                tempFilter.projectInformation.value.slice(0, -1).join(', ') + ', ' + tempFilter.projectInformation.value.slice(-1) :
                tempFilter.projectInformation.value
            if (tempFilter.projectInformation.value.length > 1) {
                message += ' (' + (tempFilter.projectInformation.isAnyAll === "all" ?
                    'All' :
                    'Any') + ')'
            }
            addTagArr.push({
                name: "Project info",
                value: message,
                title: "projectInformation",
                gotoComponentID: "new_filter_spi"
            })
        } else {
            tagList.map(item => {
                if (item.title == "projectInformation") {
                    removeTagArr.push("projectInformation")
                }
            })
        }
        //project title
        if (tempFilter?.projectTitle?.active &&
            tempFilter?.projectTitle?.value != ""
        ) {
            addTagArr.push({
                name: "Project title",
                value: tempFilter.projectTitle.value,
                title: "projectTitle",
                gotoComponentID: "new_filter_spi"
            })
        } else {
            tagList.map(item => {
                if (item.title == "projectTitle") {
                    removeTagArr.push("projectTitle")
                }
            })
        }
        //project scheme
        if (tempFilter?.projectScheme?.active &&
            tempFilter?.projectScheme?.value != ""
        ) {
            addTagArr.push({
                name: "Project scheme",
                value: tempFilter.projectScheme.value,
                title: "projectScheme",
                gotoComponentID: "new_filter_spi"
            })
        } else {
            tagList.map(item => {
                if (item.title == "projectScheme") {
                    removeTagArr.push("projectScheme")
                }
            })
        }

        //company information
        if (tempFilter?.companyInformation?.active &&
            tempFilter?.companyInformation?.value != ""
        ) {
            addTagArr.push({
                name: "Company information",
                value: tempFilter.companyInformation.value,
                title: "companyInformation",
                gotoComponentID: "new_filter_sci"
            })
        } else {
            tagList.map(item => {
                if (item.title == "companyInformation") {
                    removeTagArr.push("companyInformation")
                }
            })
        }
        //person information
        if (tempFilter?.personInformation?.active &&
            tempFilter?.personInformation?.value != ""
        ) {
            addTagArr.push({
                name: "Person information",
                value: tempFilter.personInformation.value,
                title: "personInformation",
                gotoComponentID: "new_filter_personsearch"
            })
        } else {
            tagList.map(item => {
                if (item.title == "personInformation") {
                    removeTagArr.push("personInformation")
                }
            })
        }

        //company name
        if (tempFilter?.companyName?.active &&
            tempFilter?.companyName?.value != ""
        ) {
            addTagArr.push({
                name: "Company name",
                value: tempFilter.companyName.value,
                title: "companyName",
                gotoComponentID: "new_filter_sci"
            })
        } else {
            tagList.map(item => {
                if (item.title == "companyName") {
                    removeTagArr.push("companyName")
                }
            })
        }
        // Person name
        if (tempFilter?.personName?.active &&
            tempFilter?.personName?.value != "") {
            addTagArr.push({
                name: "Person name",
                value: tempFilter.personName.value,
                title: "personName",
                gotoComponentID: "new_filter_personsearch"
            })
        } else {
            tagList.map(item => {
                if (item.title == "personName") {
                    removeTagArr.push("personName")
                }
            })
        }

        //company id
        if (tempFilter?.companyId?.active &&
            tempFilter?.companyId?.value != ""
        ) {
            addTagArr.push({
                name: "Company ID",
                value: tempFilter.companyId.value,
                title: "companyId",
                gotoComponentID: "new_filter_sci"
            })
        } else {
            tagList.map(item => {
                if (item.title == "companyId") {
                    removeTagArr.push("companyId")
                }
            })
        }
        //person job title
        if (tempFilter?.personJobTitle?.active &&
            tempFilter?.personJobTitle?.value != ""
        ) {
            addTagArr.push({
                name: "Job title",
                value: tempFilter.personJobTitle.value,
                title: "personJobTitle",
                gotoComponentID: "new_filter_spi"
            })
        } else {
            tagList.map(item => {
                if (item.title == "personJobTitle") {
                    removeTagArr.push("personJobTitle")
                }
            })
        }

        //search by location
        if (tempFilter?.location?.active &&
            (tempFilter?.location?.location?.toLowerCase() == "Current Location".toLowerCase() ||
                tempFilter?.location?.latitude && tempFilter?.location?.longitude)) {
            addTagArr.push({
                name: "Project location",
                value: tempFilter.location.location,
                title: "location",
                gotoComponentID: "new_filter_pl"
            })
        } else {
            tagList.map(item => {
                if (item.title == "location") {
                    removeTagArr.push("location")
                }
            })
        }
        // search by town
        if (tempFilter?.projectTown?.active &&
            tempFilter?.projectTown?.value?.length > 0) {
            let townArray = tempFilter.projectTown.value,
                tagValue = ''
            if (townArray.length > 0) {
                if (townArray.length != 0 && townArray.length == 1) {
                    tagValue = townArray
                } else {
                    tagValue = townArray.slice(0, -1).join(', ') + ' and ' + townArray.slice(-1)
                }
                addTagArr.push({
                    name: "Project town",
                    value: tagValue,
                    title: "projectTown",
                    gotoComponentID: "new_filter_pl"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "projectTown") {
                    removeTagArr.push("projectTown")
                }
            })
        }

        //search by postcode
        if (tempFilter?.projectPostcode?.active &&
            tempFilter?.projectPostcode?.value?.length > 0) {
            let postCodeArray = tempFilter.projectPostcode.value,
                tagValue = ''

            if (postCodeArray.length > 0) {
                if (postCodeArray.length != 0 && postCodeArray.length == 1) {
                    tagValue = postCodeArray
                } else {
                    tagValue = postCodeArray.slice(0, -1).join(', ') + ' and ' + postCodeArray.slice(-1)
                }
                addTagArr.push({
                    name: "Project postcode",
                    value: tagValue,
                    title: "projectPostcode",
                    gotoComponentID: "new_filter_pl"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "projectPostcode") {
                    removeTagArr.push("projectPostcode")
                }
            })
        }

        //search by framework
        if (tempFilter?.projectFramework?.active &&
            tempFilter?.projectFramework?.dataObj?.length > 0) {
            let frameworkArray = tempFilter.projectFramework.dataObj.map(item => item.name),
                tagValue = ''

            if (frameworkArray.length > 0) {
                if (frameworkArray.length != 0 && frameworkArray.length == 1) {
                    tagValue = frameworkArray
                } else {
                    tagValue = frameworkArray.slice(0, -1).join(', ') + ' and ' + frameworkArray.slice(-1)
                }
                addTagArr.push({
                    name: "Framework",
                    value: tagValue,
                    title: "projectFramework",
                    gotoComponentID: "new_filter_framework"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "projectFramework") {
                    removeTagArr.push("projectFramework")
                }
            })
        }

        // search by brands
        if (tempFilter?.brands?.active &&
            tempFilter?.brands?.value?.length > 0) {
            let brandArray = tempFilter.brands.value,
                tagValue = ''
            if (brandArray.length > 0) {
                if (brandArray.length != 0 && brandArray.length == 1) {
                    tagValue = brandArray
                } else {
                    tagValue = brandArray.slice(0, -1).join(', ') + ', ' + brandArray.slice(-1)
                }
                addTagArr.push({
                    name: "Product specification",
                    value: tagValue,
                    title: "brands",
                    gotoComponentID: "new_filter_material"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "brands") {
                    removeTagArr.push("brands")
                }
            })
        }

        //materials
        if (tempFilter?.materials?.active &&
            tempFilter?.materials?.options?.some(item => item.active || item.isChildSelected)) {
            let parentCount = 0
            let childCount = 0
            let parent = []
            let subParentCount = 0
            let secondParent = []
            let childMat = []
            let message = ""
            let childMaterial = []
            if (tempFilter?.materials?.options?.every(item => item.active)) {
                message += "All are selected"
            } else {
                parent = tempFilter?.materials?.options?.filter(item => item.active || item.isChildSelected)
                parent.length > 0 && parent.map(item => {
                    if (item.active) {
                        secondParent = item.subParent
                        secondParent.map(child => {
                            childMat = child.children
                            childCount += childMat.length
                            childMat.map(lastChild => {
                                childMaterial.push(lastChild.name)
                            })
                        })
                    } else {
                        secondParent = item.subParent.filter(subItem => subItem.active || subItem.isChildSelected)
                        secondParent.map(child => {
                            childMat = child.children.filter(subChild => subChild.active)
                            childCount += childMat.length
                            childMat.map(lastChild => {
                                childMaterial.push(lastChild.name)
                            })
                        })
                    }
                })
                if (childMaterial.length && childMaterial.length <= 2) {
                    message += childMaterial[0] + (childMaterial.length > 1 ? ", " + childMaterial[1] : "")
                } else if (childCount) {
                    message += childCount + " materials"
                }
            }
            addTagArr.push({
                name: "Materials",
                value: message,
                title: "materials",
                gotoComponentID: "new_filter_material"
            })
        } else {
            tagList.map(item => {
                if (item.title == "materials") {
                    removeTagArr.push("materials")
                }
            })
        }

        //search by company location
        if (tempFilter?.companyLocation?.active &&
            tempFilter?.companyLocation?.location != '' &&
            (
                tempFilter?.companyLocation?.location?.toLowerCase() == "Current Location".toLowerCase() ||
                tempFilter?.companyLocation?.latitude &&
                tempFilter?.companyLocation?.longitude
            )
        ) {
            addTagArr.push({
                name: "Company location",
                value: tempFilter.companyLocation.location,
                title: "companyLocation",
                gotoComponentID: "new_filter_cl"
            })
        } else {
            tagList.map(item => {
                if (item.title == "companyLocation") {
                    removeTagArr.push("companyLocation")
                }
            })
        }

        // search by company town
        if (tempFilter?.companyTown?.active &&
            tempFilter?.companyTown?.value?.length > 0) {
            let townArray = tempFilter.companyTown.value,
                tagValue = ''
            if (townArray.length > 0) {
                if (townArray.length != 0 && townArray.length == 1) {
                    tagValue = townArray
                } else {
                    tagValue = townArray.slice(0, -1).join(', ') + ' and ' + townArray.slice(-1)
                }
                addTagArr.push({
                    name: "Company town",
                    value: tagValue,
                    title: "companyTown",
                    gotoComponentID: "new_filter_cl"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "companyTown") {
                    removeTagArr.push("companyTown")
                }
            })
        }

        //search by company postcode
        if (tempFilter?.companyPostcode?.active &&
            tempFilter?.companyPostcode?.value?.length > 0) {
            let postCodeArray = tempFilter?.companyPostcode?.value,
                tagValue = ''

            if (postCodeArray.length > 0) {
                if (postCodeArray.length != 0 && postCodeArray.length == 1) {
                    tagValue = postCodeArray
                } else {
                    tagValue = postCodeArray.slice(0, -1).join(', ') + ' and ' + postCodeArray.slice(-1)
                }
                addTagArr.push({
                    name: "Company postcode",
                    value: tagValue,
                    title: "companyPostcode",
                    gotoComponentID: "new_filter_cl"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title == "companyPostcode") {
                    removeTagArr.push("companyPostcode")
                }
            })
        }
        //completed project
        if (tempFilter?.completedProject?.active) {
            addTagArr.push({
                name: "Include completed projects",
                title: "completedProject",
                gotoComponentID: "new_filter_scp"
            })
        } else {
            tagList.map(item => {
                if (item.title == "completedProject") {
                    removeTagArr.push("completedProject")
                }
            })
        }

        //buildPhase 
        if (tempFilter?.buildPhase?.active) {
            let data = tempFilter?.buildPhase?.options?.filter(item => item.active).map(item => item.title)
            if (!tempFilter.buildPhase.options.filter(item => item.active).map(item => item.value).includes("no_timing"))
                addTagArr.push({
                    name: "Build Phase",
                    value: [data.slice(0, -1).join(', '), data.slice(-1)[0]].join(data.length < 2 ? '' : ' and '),
                    title: "buildPhase",
                    gotoComponentID: "new_filter_bp"
                })
        } else {
            tagList.map(item => {
                if (item.title == "buildPhase") {
                    removeTagArr.push("buildPhase")
                }
            })
        }

        // categories
        if ((tempFilter?.includeCategories?.active &&
            tempFilter?.includeCategories?.options?.some(item => item.active || item.isChildSelected)) ||
            (tempFilter?.excludeCategories?.active &&
                tempFilter?.excludeCategories?.options.some(item => item.active || item.isChildSelected)) ||
            siteToBeSold === 11
        ) {
            let includecount = 0,
                include = [],
                exclude = [],
                excludeCount = 0
            let inclusionMsg = ""
            let exclusionMsg = ""
            if (tempFilter?.includeCategories?.options.every(item => item.active)) {
                inclusionMsg = "Included all " + (tempFilter.primaryCategoriesOnly ? 'primary' : '') + " categories"
            } else {
                tempFilter?.includeCategories?.options.map(item => {
                    if (item.active) {
                        includecount += item.subCategories.length
                        item.subCategories.map(subItem => {
                            if (subItem.active) {
                                if (includecount <= 2) {
                                    include.push(subItem.name)
                                }
                            }
                        })
                    } else if (item.isChildSelected) {
                        item.subCategories.map(subItem => {
                            if (subItem.active) {
                                includecount += 1
                                if (includecount <= 2) {
                                    include.push(subItem.name)
                                }
                            }
                        })
                    }
                })
                if (includecount > 2) {
                    inclusionMsg = "Include " + includecount.toString() + (tempFilter.primaryCategoriesOnly ? ' primary' : '') + " categories"
                } else if (includecount == 2) {
                    inclusionMsg = "Include " + (tempFilter.primaryCategoriesOnly ? ' primary category ' : '') + include[0] + " and " + include[1]
                } else if (includecount == 1) {
                    inclusionMsg = "Include " + (tempFilter.primaryCategoriesOnly ? ' primary category ' : '') + include[0]
                }
            }
            if (tempFilter.excludeCategories.options.every(item => item.active)) {
                exclusionMsg = "Excluded all " + (tempFilter.primaryCategoriesOnly ? 'primary' : '') + " categories"
            } else {
                tempFilter.excludeCategories.options.map(item => {
                    if (item.active) {
                        excludeCount += item.subCategories.length
                        item.subCategories.map(subItem => {
                            if (subItem.active) {
                                if (excludeCount <= 2) {
                                    exclude.push(subItem.name)
                                }
                            }
                        })
                    } else if (item.isChildSelected || item.active) {
                        item.subCategories.map(subItem => {
                            if (subItem.active) {
                                excludeCount += 1
                                if (excludeCount <= 2) {
                                    exclude.push(subItem.name)
                                }
                            }
                        })
                    }
                })
                if (excludeCount > 2) {
                    exclusionMsg = "Exclude " + excludeCount.toString() + (tempFilter.primaryCategoriesOnly ? ' primary' : '') + " categories"
                } else if (excludeCount == 2) {
                    exclusionMsg = "Exclude " + (tempFilter.primaryCategoriesOnly ? ' primary category ' : '') + exclude[0] + " and " + exclude[1]
                } else if (excludeCount == 1) {
                    exclusionMsg = "Exclude " + (tempFilter.primaryCategoriesOnly ? ' primary category ' : '') + exclude[0]
                }
            }
            let message = (inclusionMsg ? inclusionMsg : "") + (inclusionMsg && exclusionMsg ? " and " : "") + (exclusionMsg ? exclusionMsg : "")
            let landTypeMessage = setLandTypeFilterTile(tempFilter) ? " (" + setLandTypeFilterTile(tempFilter) + ") " : ""
            message = message + setUsageTypeFilterTile(tempFilter) + landTypeMessage
            addTagArr.push({
                name: "Categories",
                value: message,
                title: "categories",
                gotoComponentID: "new_filter_category"
            })
        }
        else if (tempFilter?.projectLandType?.active &&
            !tempFilter?.includeCategories?.active && !tempFilter?.excludeCategories?.active &&
            siteToBeSold !== 11) {
            let message = ""
            message = message + setUsageTypeFilterTile(tempFilter) + setLandTypeFilterTile(tempFilter)
            if (message) {
                addTagArr.push({
                    name: "Categories",
                    value: message,
                    title: "categories",
                    gotoComponentID: "new_filter_category"
                })
            } else {
                tagList.map(item => {
                    if (item.title === "categories") {
                        if (!tempFilter?.includeCategories?.active && !tempFilter?.includeCategories?.options.some(item => item.active)) {
                            removeTagArr.push("categories")
                            // dispatch(updateTextInputFilter({
                            //     child: "children",
                            //     excludeKey: "excludeCategories",
                            //     filter: "categories",
                            //     includeKey: "includeCategories",
                            //     isIncludeExclude: true,
                            //     text: "",
                            // }))
                        }
                    }
                })
            }
        }
        else {
            let message = ""
            message = message + setUsageTypeFilterTile(tempFilter) + setLandTypeFilterTile(tempFilter)
            if (message) {
                addTagArr.push({
                    name: "Categories",
                    value: message,
                    title: "categories",
                    gotoComponentID: "new_filter_category"
                })
            } else {
                tagList.map(item => {
                    if (item.title === "categories") {
                        if (!tempFilter?.includeCategories?.active && !tempFilter?.includeCategories?.options.some(item => item.active)) {
                            removeTagArr.push("categories")
                            // dispatch(updateTextInputFilter({
                            //     child: "children",
                            //     excludeKey: "excludeCategories",
                            //     filter: "categories",
                            //     includeKey: "includeCategories",
                            //     isIncludeExclude: true,
                            //     text: "",
                            // }))
                        }
                    }
                })
            }
        }

        // development types
        if (tempFilter?.includeDevelopmentTypes?.active &&
            tempFilter?.includeDevelopmentTypes?.options?.some(item => item.active) ||
            tempFilter?.excludeDevelopmentTypes?.active &&
            tempFilter?.excludeDevelopmentTypes?.options?.some(item => item.active)
        ) {
            let include = []
            let exclude = []
            tempFilter.includeDevelopmentTypes.options.map(item => {
                if (item.active) {
                    include.push(item.name)
                }
            })
            tempFilter.excludeDevelopmentTypes.options.map(item => {
                if (item.active) {
                    exclude.push(item.name)
                }
            })
            let includeMsg = include.length > 0 ? "Includes " : ""
            include.length > 0 && include.map(item => {
                includeMsg += item
                includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
            })
            let excludeMsg = exclude.length > 0 ? "Excludes " : ""
            exclude.length > 0 && exclude.map(item => {
                excludeMsg += item
                excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
            })
            let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")

            addTagArr.push({
                name: "Development type",
                value: message,
                title: "developmentTypes",
                gotoComponentID: "new_filter_devtype"
            })

        } else {
            tagList.map(item => {
                if (item.title === "developmentTypes") {
                    if (!tempFilter?.includeDevelopmentTypes?.active && !tempFilter?.includeDevelopmentTypes?.options.some(item => item.active)) {
                        removeTagArr.push("developmentTypes")
                        // dispatch(updateTextInputFilter({
                        //     child: null,
                        //     excludeKey: "excludeDevelopmentTypes",
                        //     filter: "developmentTypes",
                        //     includeKey: "includeDevelopmentTypes",
                        //     isIncludeExclude: true,
                        //     text: ""
                        // }))
                    }
                }
            })
        }

        // roles
        if (tempFilter?.includeRoles?.active &&
            tempFilter?.includeRoles?.options?.some(item => item.active) ||
            tempFilter?.excludeRoles?.active &&
            tempFilter?.excludeRoles?.options?.some(item => item.active)
        ) {
            let include = []
            let exclude = []
            tempFilter.includeRoles.options.map(item => {
                if (item.active) {
                    include.push(item.name)
                }
            })
            tempFilter.excludeRoles.options.map(item => {
                if (item.active) {
                    exclude.push(item.name)
                }
            })
            let includeMsg = include.length > 0 ? "Includes " : ""
            include.length > 0 && include.map(item => {
                includeMsg += item
                includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
            })
            let excludeMsg = exclude.length > 0 ? "Excludes " : ""
            exclude.length > 0 && exclude.map(item => {
                excludeMsg += item
                excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
            })
            let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")

            addTagArr.push({
                name: "Roles",
                value: message,
                title: "roles",
                gotoComponentID: "new_filter_roles"
            })
        } else {
            tagList.map(item => {
                if (item.title === "roles") {
                    if ((!tempFilter?.includeRoles?.active || !tempFilter?.excludeRoles?.active) && (!tempFilter.includeRoles.options.some(item => item.active) || !tempFilter.excludeRoles.options.some(item => item.active))) {
                        removeTagArr.push("roles")
                        // dispatch(updateTextInputFilter({
                        //     child: null,
                        //     excludeKey: "excludeRoles",
                        //     filter: "roles",
                        //     includeKey: "includeRoles",
                        //     isIncludeExclude: true,
                        //     text: ""
                        // }))
                    }
                }
            })
        }

        //search by project regions and counties
        if (tempFilter?.regions?.active &&
            tempFilter?.regions?.options?.some(item => item.active || item.isChildSelected)
        ) {
            let regions = tempFilter.regions.options
            if (regions != undefined && regions.length > 0) {
                let selectedRegions = [],
                    selectedCounties = [],
                    regionNames = ''
                for (i = 0; i < regions.length; i++) {
                    for (j = 0; j < regions[i].counties.length; j++) {
                        if (regions[i].counties[j].active) {
                            if (selectedCounties.indexOf(regions[i].counties[j].name) == -1) { //avoiding duplication
                                selectedCounties.push(regions[i].counties[j].name)
                            }
                            if (selectedRegions.indexOf(regions[i].name) == -1) {
                                selectedRegions.push(regions[i].name)
                            }
                        } else if (regions[i].active && selectedRegions.indexOf(regions[i].name) == -1) {
                            selectedRegions.push(regions[i].name)
                        }
                    }
                }
                if (selectedRegions.length != 0 && selectedRegions.length == 1) {
                    regionNames = selectedRegions
                    if (selectedRegions.length > 0 && selectedCounties.length === 0) {
                        _.map(regions, region => {
                            if (selectedRegions.includes(region.name)) {
                                _.map(region.counties, county => {
                                    selectedCounties.push(county.name)
                                })
                            }
                        })
                    }
                } else {
                    regionNames = selectedRegions.slice(0, -1).join(', ') + ' and ' + selectedRegions.slice(-1)
                    if (selectedRegions.length > 0 && selectedCounties.length === 0) {
                        _.map(regions, region => {
                            if (selectedRegions.includes(region.name)) {
                                _.map(region.counties, county => {
                                    selectedCounties.push(county.name)
                                })
                            }
                        })
                    }
                }
                if (selectedCounties.length > 0) {
                    let tagValue = selectedCounties.length + " regions in " + regionNames
                    addTagArr.push({
                        name: "Project regions and counties",
                        value: tagValue,
                        title: "regions",
                        gotoComponentID: "new_filter_prcs"
                    })
                }
            }
        } else {
            tagList.map(item => {
                if (item.title == "regions") {
                    removeTagArr.push("regions")
                }
            })
        }

        //search by company regions and counties
        if (tempFilter?.companyRegions?.active) {
            let regions = tempFilter.companyRegions.options
            if (regions != undefined && regions.length > 0) {
                let selectedRegions = [],
                    selectedCounties = [],
                    regionNames = ''
                for (i = 0; i < regions.length; i++) {
                    for (j = 0; j < regions[i].counties.length; j++) {
                        if (regions[i].counties[j].active) {
                            if (selectedCounties.indexOf(regions[i].counties[j].name) == -1) { //avoiding duplication
                                selectedCounties.push(regions[i].counties[j].name)
                            }
                            if (selectedRegions.indexOf(regions[i].name) == -1) {
                                selectedRegions.push(regions[i].name)
                            }
                        } else if (regions[i].active && selectedRegions.indexOf(regions[i].name) == -1) {
                            selectedRegions.push(regions[i].name)
                        }
                    }
                }
                if (selectedRegions.length != 0 && selectedRegions.length == 1) {
                    regionNames = selectedRegions
                    if (selectedRegions.length > 0 && selectedCounties.length === 0) {
                        _.map(regions, region => {
                            if (selectedRegions.includes(region.name)) {
                                _.map(region.counties, county => {
                                    selectedCounties.push(county.name)
                                })
                            }
                        })
                    }
                } else if (selectedRegions.length != 0 && selectedRegions.length > 1) {
                    regionNames = selectedRegions.slice(0, -1).join(', ') + ' and ' + selectedRegions.slice(-1)
                    if (selectedRegions.length > 0 && selectedCounties.length === 0) {
                        _.map(regions, region => {
                            if (selectedRegions.includes(region.name)) {
                                _.map(region.counties, county => {
                                    selectedCounties.push(county.name)
                                })
                            }
                        })
                    }
                } else {
                    tagList.map(item => {
                        if (item.title == "companyRegions") {
                            removeTagArr.push("companyRegions")
                        }
                    })
                }

                if (selectedCounties.length > 0) {
                    let tagValue = selectedCounties.length + " regions in " + regionNames
                    addTagArr.push({
                        name: "Company regions and counties",
                        value: tagValue,
                        title: "companyRegions",
                        gotoComponentID: "new_filter_crcs"
                    })
                }
            }
        } else {
            tagList.map(item => {
                if (item.title == "companyRegions") {
                    removeTagArr.push("companyRegions")
                }
            })
        }


        //valueFilter
        if (tempFilter?.value?.active &&
            (tempFilter?.value?.from || tempFilter?.value?.to)) {
            let toValue = tempFilter.value.to && tempFilter.value.to != '' && tempFilter.value.to != null ? tempFilter.value.displayTo : 'No limit'
            let fromValue = tempFilter.value.from && tempFilter.value.displayFrom != "" ? tempFilter.value.displayFrom : "0"
            let tagValue = tempFilter.value.from || tempFilter.value.to ? 'From: ' + fromValue + ' To: ' + toValue : null
            addTagArr.push({
                name: "Value",
                value: tagValue,
                title: "value",
                gotoComponentID: "new_filter_value"
            })
        } else {
            tagList.map(item => {
                if (item.title == "value") {
                    removeTagArr.push("value")
                }
            })
        }

        // stages
        if (tempFilter?.includeStages?.active &&
            tempFilter?.includeStages?.options?.some(item => item.active || item.isChildSelected) ||
            tempFilter?.advanceExcludeStages?.active &&
            tempFilter?.advanceExcludeStages?.options?.some(item => item.active || item.isChildSelected)
        ) {
            let include = []
            let exclude = []
            tempFilter?.includeStages?.options?.map(item => {
                if (item) {
                    item.children.map(stage => {
                        if (stage.active) {
                            include.push(stage.name)
                        }
                    })
                }
            })
            tempFilter?.advanceExcludeStages?.options?.map(item => {
                if (item) {
                    item.children.map(stage => {
                        if (stage.active) {
                            exclude.push(stage.name)
                        }
                    })
                }
            })
            let includeMsg = include.length > 0 ? "Includes " : ""
            let excludeMsg = exclude.length > 0 ? "Excludes " : ""

            include.length > 0 && include.map(item => {
                includeMsg += item
                includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
            })
            exclude.length > 0 && exclude.map(item => {
                excludeMsg += item
                excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
            })
            let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")
            addTagArr.push({
                name: "Stages",
                value: message,
                title: "stages",
                gotoComponentID: "new_filter_stages"
            })
        } else {
            tagList.map(item => {
                if (item.title === "stages") {
                    if (!tempFilter?.includeStages?.active && !tempFilter.includeStages.options.some(item => item.active) || tempFilter.advanceExcludeStages.options.some(item => item.active)) {
                        removeTagArr.push("stages")
                        // props.updateTextInputFilter({
                        //     child: "subStages",
                        //     excludeKey: "advanceExcludeStages",
                        //     filter: "includeStages",
                        //     includeKey: "includeStages",
                        //     isIncludeExclude: true,
                        //     text: "",
                        // })
                    }
                }
            })
        }

        //stages - inclusions
        if (!checkSearchByIdFilter(tempFilter) && tempFilter?.excludeStages?.active && tempFilter?.excludeStages?.toggleActive) {
            let message = "withdrawn, abandoned"
            addTagArr.push({
                name: "Inclusions",
                value: message,
                title: "inclusions",
                gotoComponentID: "new_filter_stages"
            })
        } else {
            tagList.map(item => {
                if (item.title == "inclusions") {
                    removeTagArr.push("inclusions")
                }
            })
        }

        //project timeline
        //construction start
        if (tempFilter?.startConstruction?.active ||
            tempFilter?.endConstruction?.active
        ) {
            let message = ""
            let startValue = getConstructionDateValue(tempFilter.startConstruction)
            let endValue = getConstructionDateValue(tempFilter.endConstruction)
            let start
            if (startValue[0] == startValue[1]) {
                start = "Construction " + (startValue[0] == 0 ? "starting" : startValue[0] < 0 ? "started" : "starts") + returnTimeFrame(startValue[0]).message
                    + (startValue[0] < 0 ? " in the past" : startValue[0] > 0 ? " in the future" : "")
            } else if (startValue[0] <= 0 && startValue[1] > 0) {
                start = "Construction started between " + returnTimeFrame(startValue[0]).message + (startValue[0] != 0 ? " ago" : "") +
                    " or due to start " + returnTimeFrame(startValue[1]).message + " in the future"
            } else {
                start = "Construction " + (startValue[0] < 0 ? "started" : "starts") + " between " +
                    returnTimeFrame(startValue[0]).message + " and " + returnTimeFrame(startValue[1]).message +
                    (startValue[1] < 0 ? " in the past" : startValue[1] > 0 ? " in the future" : "")
            }

            let end
            if (endValue[0] === endValue[1]) {
                end = "Construction " + (endValue[0] == 0 ? "ending" : endValue[0] < 0 ? "ended" : "is due to end") + returnTimeFrame(endValue[0]).message +
                    (endValue[0] < 0 ? " in the past" : endValue[0] > 0 ? " in the future" : "")
            } else if (endValue[0] < 0 && endValue[1] > 0) {
                end = "Construction ended between " + returnTimeFrame(endValue[0]).message + " ago or is due to end " +
                    returnTimeFrame(endValue[1]).message + " in the future"
            } else {
                end = "Construction " + (endValue[0] == 0 ? "will end " : endValue[0] < 0 ? "ended " : "is due to end ") + "between " +
                    returnTimeFrame(endValue[0]).message + (endValue[0] == 0 ? " or " : " and ") +
                    returnTimeFrame(endValue[1]).message + (endValue[1] < 0 ? " in the past" : endValue[1] > 0 ? " in the future" : "")
            }
            let startMessage = tempFilter.startConstruction.active ? start : ""
            let endMessage = tempFilter.endConstruction.active ? end : ""
            message = startMessage + (startMessage && endMessage && " and ") + endMessage

            addTagArr.push({
                name: "Rolling timeline",
                value: message,
                title: "relativeTimings",
                gotoComponentID: "new_filter_pt"
            })
        } else {
            tagList.map(item => {
                if (item.title == "relativeTimings") {
                    removeTagArr.push("relativeTimings")
                }
            })
        }
        //construction end  
        if (tempFilter?.calendarEndDate?.active &&
            (tempFilter?.calendarEndDate?.from != "" ||
                tempFilter?.calendarEndDate?.to != "") ||
            tempFilter?.calendarStartDate?.active &&
            (tempFilter?.calendarStartDate?.from != "" ||
                tempFilter?.calendarStartDate?.to != "")
        ) {

            let startDateMsg = ''
            let endDateMsg = ''
            if (tempFilter.calendarStartDate.active && (tempFilter.calendarStartDate.from != "" || tempFilter.calendarStartDate.to != "")) {
                let startFromDate = tempFilter.calendarStartDate.from ? moment(tempFilter.calendarStartDate.from).format('DD-MMM-YYYY') : "*"
                let startToDate = tempFilter.calendarStartDate.to ? moment(tempFilter.calendarStartDate.to).format('DD-MMM-YYYY') : "*"
                startDateMsg = ((new Date() <= new Date(tempFilter.calendarStartDate.from)) ? "Starts between " : "Started between ") + startFromDate + " and " + startToDate
            }
            if (tempFilter.calendarEndDate.active && (tempFilter.calendarEndDate.from != "" || tempFilter.calendarEndDate.to != "")) {
                let endFromDate = tempFilter.calendarEndDate.from ? moment(tempFilter.calendarEndDate.from).format('DD-MMM-YYYY') : "*"
                let endToDate = tempFilter.calendarEndDate.to ? moment(tempFilter.calendarEndDate.to).format('DD-MMM-YYYY') : "*"
                endDateMsg = ((new Date() <= new Date(tempFilter.calendarStartDate.from)) ? "Ends between " : "Ended between ") + endFromDate + " and " + endToDate
            }

            let message = (startDateMsg ? startDateMsg : "") + (startDateMsg && endDateMsg ? " and " : "") + (endDateMsg ? endDateMsg : "")
            addTagArr.push({
                name: 'Calendar timeline',
                value: message,
                title: 'calendarTiming',
                gotoComponentID: "new_filter_pt"
            })
        } else {
            tagList.map(item => {
                if (item.title == "calendarTiming") {
                    removeTagArr.push("calendarTiming")
                }
            })
        }

        //updated
        if ((tempFilter?.new?.active && tempFilter?.new?.toggleActive) ||
            (tempFilter?.updated?.active && tempFilter?.updated?.toggleActive)) {
            let preMsg = ""
            if ((tempFilter.new.active && tempFilter.new.toggleActive) && !(tempFilter.updated.active && tempFilter.updated.toggleActive)) {
                preMsg += "New "
            }
            else if ((tempFilter.updated.active && tempFilter.updated.toggleActive) && (tempFilter.new.active && tempFilter.new.toggleActive)) {
                preMsg += "New and updated "
            }

            if ((tempFilter.updated && tempFilter.updated.active && tempFilter.updated.toggleActive) && !(tempFilter.new && tempFilter.new.active && tempFilter.new.toggleActive)) {
                if (tempFilter.updated.options.filter(c => c.active && !c.disabled).length > 0) {
                    let message = preMsg + (tempFilter.updated && tempFilter.updated.options && tempFilter.updated.options.filter(c => c.active && !c.disabled)[0].name)
                    addTagArr.push({
                        name: "Updated",
                        value: message,
                        title: "updated",
                        gotoComponentID: "new_filter_updated"
                    })
                }
            } else {
                if (tempFilter.new.options.filter(c => c.active && !c.disabled).length > 0) {
                    let message = preMsg + (tempFilter.new && tempFilter.new.options && tempFilter.new.options.filter(c => c.active && !c.disabled)[0].name)
                    addTagArr.push({
                        name: "Updated",
                        value: message,
                        title: "updated",
                        gotoComponentID: "new_filter_updated"
                    })
                }
            }
        } else {
            tagList.map(item => {
                if (item.title === "updated") {
                    removeTagArr.push("updated")
                }
            })
        }

        //role added
        if (tempFilter?.roleAdded?.active && tempFilter?.roleAdded?.toggleActive) {
            if (tempFilter.roleAdded.options.filter(c => c.active).length > 0) {
                let message = "in the last " + (tempFilter.roleAdded && tempFilter.roleAdded.options && tempFilter.roleAdded.options.filter(c => c.active)[0].name)
                addTagArr.push({
                    name: "Role added",
                    value: message,
                    title: "roleAdded",
                    gotoComponentID: "new_filter_roles"
                })
            }
        } else {
            tagList.map(item => {
                if (item.title === "roleAdded") {
                    removeTagArr.push("roleAdded")
                }
            })
        }

        //measures
        //parking

        if (tempFilter?.measure?.active) {
            if (tempFilter?.measure.options.parkingSpaces.active && (tempFilter.measure.options.parkingSpaces.from != "" || tempFilter.measure.options.parkingSpaces.to != "")) {
                let fromValue = tempFilter.measure.options.parkingSpaces.from && tempFilter.measure.options.parkingSpaces.from != '' ? tempFilter.measure.options.parkingSpaces.from : 0
                let toValue = tempFilter.measure.options.parkingSpaces.to && tempFilter.measure.options.parkingSpaces.to != '' ? tempFilter.measure.options.parkingSpaces.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Parking spaces",
                    value: tagValue,
                    title: "parkingSpaces",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "parkingSpaces") {
                        removeTagArr.push("parkingSpaces")
                    }
                })
            }

            //totalFloorArea
            if (tempFilter.measure.options.totalFloorArea.active && (tempFilter.measure.options.totalFloorArea.from != "" || tempFilter.measure.options.totalFloorArea.to != "")) {
                let fromValue = tempFilter.measure.options.totalFloorArea.from && tempFilter.measure.options.totalFloorArea.from != "" ? tempFilter.measure.options.totalFloorArea.from : 0
                let toValue = tempFilter.measure.options.totalFloorArea.to && tempFilter.measure.options.totalFloorArea.to != '' ? tempFilter.measure.options.totalFloorArea.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Plan area (ground floor only)",
                    value: tagValue,
                    title: "totalFloorArea",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "totalFloorArea") {
                        removeTagArr.push("totalFloorArea")
                    }
                })
            }

            //groundFloorArea
            if (tempFilter.measure.options.groundFloorArea.active && (tempFilter.measure.options.groundFloorArea.from != '' || tempFilter.measure.options.groundFloorArea.to != '')) {
                let fromValue = tempFilter.measure.options.groundFloorArea.from && tempFilter.measure.options.groundFloorArea.from != "" ? tempFilter.measure.options.groundFloorArea.from : 0
                let toValue = tempFilter.measure.options.groundFloorArea.to && tempFilter.measure.options.groundFloorArea.to != '' ? tempFilter.measure.options.groundFloorArea.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Floor area (all floors)",
                    value: tagValue,
                    title: "groundFloorArea",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "groundFloorArea") {
                        removeTagArr.push("groundFloorArea")
                    }
                })
            }

            //siteArea
            if (tempFilter.measure.options.siteArea.active && (tempFilter.measure.options.siteArea.from != "" || tempFilter.measure.options.siteArea.to != "")) {
                let fromValue = tempFilter.measure.options.siteArea.from && tempFilter.measure.options.siteArea.from != "" ? tempFilter.measure.options.siteArea.from : 0
                let toValue = tempFilter.measure.options.siteArea.to && tempFilter.measure.options.siteArea.to != '' ? tempFilter.measure.options.siteArea.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Site area",
                    value: tagValue,
                    title: "siteArea",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "siteArea") {
                        removeTagArr.push("siteArea")
                    }
                })
            }

            //aboveGroundStoreys
            if (tempFilter.measure.options.aboveGroundStoreys.active && (tempFilter.measure.options.aboveGroundStoreys.from != "" || tempFilter.measure.options.aboveGroundStoreys.to != "")) {
                let fromValue = tempFilter.measure.options.aboveGroundStoreys.from && tempFilter.measure.options.aboveGroundStoreys.from != "" ? tempFilter.measure.options.aboveGroundStoreys.from : 0
                let toValue = tempFilter.measure.options.aboveGroundStoreys.to && tempFilter.measure.options.aboveGroundStoreys.to != '' ? tempFilter.measure.options.aboveGroundStoreys.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Above ground storeys",
                    value: tagValue,
                    title: "aboveGroundStoreys",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "aboveGroundStoreys") {
                        removeTagArr.push("aboveGroundStoreys")
                    }
                })
            }

            //belowGroundStoreys
            if (tempFilter.measure.options.belowGroundStoreys.active && (tempFilter.measure.options.belowGroundStoreys.from != "" || tempFilter.measure.options.belowGroundStoreys.to != "")) {
                let fromValue = tempFilter.measure.options.belowGroundStoreys.from && tempFilter.measure.options.belowGroundStoreys.from != "" ? tempFilter.measure.options.belowGroundStoreys.from : 0
                let toValue = tempFilter.measure.options.belowGroundStoreys.to && tempFilter.measure.options.belowGroundStoreys.to != '' ? tempFilter.measure.options.belowGroundStoreys.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Below ground storeys",
                    value: tagValue,
                    title: "belowGroundStoreys",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "belowGroundStoreys") {
                        removeTagArr.push("belowGroundStoreys")
                    }
                })
            }

            //noOfUnits
            if (tempFilter.measure.options.noOfUnits.active && (tempFilter.measure.options.noOfUnits.from != "" || tempFilter.measure.options.noOfUnits.to != "")) {
                let fromValue = tempFilter.measure.options.noOfUnits.from && tempFilter.measure.options.noOfUnits.from != "" ? tempFilter.measure.options.noOfUnits.from : 0
                let toValue = tempFilter.measure.options.noOfUnits.to && tempFilter.measure.options.noOfUnits.to != '' ? tempFilter.measure.options.noOfUnits.to : 'No limit'
                let tagValue = 'From: ' + fromValue + ' To: ' + toValue
                addTagArr.push({
                    name: "Number of units",
                    value: tagValue,
                    title: "noOfUnits",
                    gotoComponentID: "new_filter_measure"
                })
            } else {
                tagList.map(item => {
                    if (item.title == "noOfUnits") {
                        removeTagArr.push("noOfUnits")
                    }
                })
            }


        } else {
            tagList.map(item => {
                if (item.title == "parkingSpaces") {
                    removeTagArr.push("parkingSpaces")
                }
                if (item.title == "totalFloorArea") {
                    removeTagArr.push("totalFloorArea")
                }
                if (item.title == "groundFloorArea") {
                    removeTagArr.push("groundFloorArea")
                }
                if (item.title == "siteArea") {
                    removeTagArr.push("siteArea")
                }
                if (item.title == "aboveGroundStoreys") {
                    removeTagArr.push("aboveGroundStoreys")
                }
                if (item.title == "belowGroundStoreys") {
                    removeTagArr.push("belowGroundStoreys")
                }
                if (item.title == "noOfUnits") {
                    removeTagArr.push("noOfUnits")
                }
            })
        }
        if (removeTagArr && removeTagArr.length) {
            addRemoveTag(removeTagArr, false, false, null, contextType)
        }
        if (addTagArr && addTagArr.length) {
            addRemoveTag(addTagArr, true, true, null, contextType)
        }
    }

    const setUsageTypeFilterTile = (tempFilter) => {
        let message = ""
        if (tempFilter?.projectUsage?.value && Array.isArray(tempFilter?.projectUsage?.value) &&
            tempFilter?.projectUsage?.value?.find(x => x === usageTypes[0]?.value) &&
            tempFilter?.projectUsage?.value?.find(x => x === usageTypes[1]?.value)) {
            message = message + ' (Build to rent and Site to be sold)'
        } else {
            if (tempFilter?.includeCategories?.active && tempFilter?.projectUsage?.value && Array.isArray(tempFilter?.projectUsage?.value) &&
                tempFilter?.projectUsage?.value?.find(x => x === usageTypes[0]?.value)) {
                message = message + ' (Build to rent)'
            }
            if (((tempFilter?.includeCategories?.active) || (tempFilter?.excludeCategories?.active)) && tempFilter?.projectUsage?.value &&
                Array.isArray(tempFilter?.projectUsage?.value) && tempFilter?.projectUsage?.value?.find(x => x === usageTypes[1]?.value)) {
                message = message + ' (Site to  be sold)'
            }
            if (tempFilter?.projectUsage?.value &&
                Array.isArray(tempFilter?.projectUsage?.value) &&
                tempFilter?.projectUsage?.value?.find(x => x === usageTypes[1]?.value) &&
                !(tempFilter?.includeCategories?.active) &&
                !(tempFilter?.excludeCategories?.active)) {
                message = "Site to be sold"
            }
        }
        return message
    }

    const addRemoveTag = (element, addRemove, flush, event, contextChange = false) => {
        event?.stopPropagation()
        if (addRemove) {
            let tags = []
            if (flush) {
                if (removeFilters && removeFilters.length) {
                    tags = element.filter((value) => !removeFilters.includes(value.title))
                } else {
                    tags = element
                }
            } else {
                tags = tagList.filter(function (item) {
                    return !(item.title == element.title)
                })
                if (element.value) {
                    tags.push(element)
                }
            }
            setTagList(tags)
            if (contextChange) {
                dispatch(updateSummaryTags(tags))
            }
            setIsAnyFilterApplied(tags.length > 0)
        }
        else {
            let removeTag = element
            let removeCountFlg = false
            let tagsList = []
            if (event != undefined) {
                event.preventDefault()
                event.stopPropagation()
                removeTag = element.target ? element.target.dataset.closestate : element
            }
            if (!Array.isArray(removeTag)) {
                if (!removeCount) {
                    removeCountFlg = true
                    updateRemoveCount(true)
                }
                tagsList = tagList.filter(item => item.title !== removeTag)
            } else if (Array.isArray(removeTag) && removeTag.length) {
                tagsList = tagList.filter(item => !removeTag.includes(item.title));
            }
            if (event != undefined) {
                if (element === 'categories') {
                    dispatch(updatePrimaryCategoriesOnlyFlag(false))
                    dispatch(updateCategoryUsageTypeFilter({ isSelected: false, value: 7 }))
                    dispatch(updateCategoryUsageTypeFilter({ isSelected: false, value: 11 }))
                    dispatch(updateCategoryLandTypeFilter({ isSelected: false, value: 1 }))
                    dispatch(updateCategoryLandTypeFilter({ isSelected: false, value: 2 }))
                }
                if (removeCountFlg || removeCount) {
                    // let rmFilters = removeFilters
                    // rmFilters.push(element)
                    // setRemoveFilters(rmFilters)
                    toggleFilterActiveStateForElement({ key: element === 'inclusions' ? 'excludeStages' : element, value: false, isRemove: true }, dispatch)
                    removeTagHandler(element)
                }
            }
            setTagList(tagsList)
            if (contextChange) {
                dispatch(updateSummaryTags(tagsList))
            }
            setIsAnyFilterApplied(tagsList?.length > 0)
        }
    }

    const removeTagHandler = (tag) => {
        setRemoveCount(true)
        if (tag === "projectInformation") {
            searchTextObj["project"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        }
        else if (tag === "projectTitle" || tag === "projectScheme") {
            exactSearchTextObj["project"] = ""
            setexactSearchTextObj(_.cloneDeep(exactSearchTextObj))
            dispatch(updateExactSearchTextObject(_.cloneDeep(exactSearchTextObj)));
        }
        else if (tag === "personInformation") {
            searchTextObj["people"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        }
        else if (tag === "personName" || tag === "personJobTitle") {
            exactSearchTextObj["people"] = ""
            setexactSearchTextObj(_.cloneDeep(exactSearchTextObj))
            dispatch(updateExactSearchTextObject(_.cloneDeep(exactSearchTextObj)));
        }
        else if (tag === "companyInformation" || tag === "companyName" || tag === "companyId") {
            searchTextObj["company"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        }
        else if (tag === "location") {
            searchLocationObject["project"] = []
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        }
        else if (tag === "companyLocation") {
            searchLocationObject["company"] = []
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        }
        else if (tag === "brands") {
            setProductTextObj([])
            // dispatch(updateBrands([]))
        }
        else if (tag === "projectFramework") {
            setFrameWorkTags([])
            dispatch(updateFrameworkTags(_.cloneDeep(frameworkTags)))
        }
        else if (tag === "completedProject") {
            dispatch(updateIncludeCompletedProj(false))
        }
        else if (tag === "buildPhase") {
            dispatch(updateBuildPhaseSwitch(false))
        }
        else if (tag === "groundFloorArea" || tag === "totalFloorArea" || tag === "siteArea" || tag === "aboveGroundStoreys" || tag === "belowGroundStoreys" || tag === "noOfUnits" || tag === "parkingSpaces") {
            let anyOneActive = false
            let updatedFilterObj = _.cloneDeep(filter.filtersState[searchPersist.appliedContext])
            for (let i in updatedFilterObj.measure.options) {
                anyOneActive = anyOneActive || (updatedFilterObj.measure.options[i].active)
            }
            let payload = {
                [tag]: {
                    from: "",
                    to: '',
                    active: anyOneActive,
                    name: tag
                }
            }
            dispatch(updateMeasures(_.cloneDeep(payload)))
        }
        else if (tag === 'clearAll') {
            searchTextObj["project"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
            dispatch(updateBuildPhaseSwitch(false))
            dispatch(updateIncludeCompletedProj(false))
            setFrameWorkTags([])
            dispatch(updateFrameworkTags(_.cloneDeep(frameworkTags)))
            setProductTextObj([])
            // dispatch(updateBrands([]))
            searchLocationObject["company"] = []
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
            searchLocationObject["project"] = []
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
            searchTextObj["company"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
            searchTextObj["project"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
            exactSearchTextObj["project"] = ""
            setexactSearchTextObj(_.cloneDeep(exactSearchTextObj))
            dispatch(updateExactSearchTextObject(_.cloneDeep(exactSearchTextObj)));
            searchTextObj["people"] = []
            setSearchTextObj(_.cloneDeep(searchTextObj))
            dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
            exactSearchTextObj["people"] = ""
            setexactSearchTextObj(_.cloneDeep(exactSearchTextObj))
            dispatch(updateExactSearchTextObject(_.cloneDeep(exactSearchTextObj)));
            dispatch(updateIncludeExcludeCheckbox([]))
            dispatch(updateContextType({ context: searchData.activeDisplayValue, searchMode: 'basic' }))
            setSearchMode('basic')
        }
    }

    const updateRemoveCount = (type) => {
        setRemoveCount(type)
        if (!type) {
            setRemoveFilters([])
        }
    }

    const variableBinding = (option, event) => {
        if (typeof (option) === 'object' && option.length === 2) { //project, company or person text search
            var stateRestored = event.target ? event.target.value : event && event.length > 0 ? event : ""
            if (!Array.isArray(stateRestored) && stateRestored?.trim() !== "") {
                //new login to keep text search mutually exclusive
                let contextObject = {
                    projectArray: ["projectTitle", "projectId", "projectScheme"],
                    companyArray: ["companyId", "companyName", "companyInformation"],
                    personArray: ["personName", "personJobTitle", "personInformation"]
                }, intermediateArray = [], remainingElems = []
                var [selectedElem] = contextObject[option[1]].filter(elem => elem === option[0]) //array destructuring to get string
                remainingElems = contextObject[option[1]].filter(elem => elem !== option[0])
                remainingElems.forEach(function (element) {
                    intermediateArray.push({
                        text: "",
                        key: element,
                    })
                })
                if (selectedElem) {
                    intermediateArray.push({
                        text: stateRestored,
                        key: selectedElem
                    })
                }
                intermediateArray.forEach(function (item) {
                    dispatch(updateDisplayKey(item))
                })

            }
        } else if (option === "IncludeOthers") {
            dispatch(updateExcludeStageFilter({ name: "excludeStages", active: event.target.checked }))
        } else if (option === "updated" || option === "new") {
            if (option === "updated") {
                dispatch(toggleLastUpdatedFilter(event.target.checked))
                dispatch(toggleFilterActiveState({ key: "updated", value: event.target.checked }))
            }
            if (option === "new") {
                dispatch(toggleNewUpdatedFilter(event.target.checked))
                dispatch(toggleFilterActiveState({ key: "new", value: event.target.checked }))
            }
        } else if (option === "projectUsage") {
            let payload
            if (event.target.name == 'buildToRent') {
                payload = {
                    isSelected: event.target.checked,
                    value: usageTypes?.length > 0 ? usageTypes[0]?.label === 'Build to rent' ? usageTypes[0]?.value : '' : ''
                }
                setBuildToRent(event.target.checked)
            } else {
                payload = {
                    isSelected: event.target.checked,
                    value: usageTypes?.length > 0 ? usageTypes[1]?.label === 'Site to be sold' ? usageTypes[1]?.value : '' : '',
                }
                setSiteToBeSold(event.target.checked)
            }
            dispatch(updateCategoryUsageTypeFilter(payload))
        }
        else if (option === "projectLandType") {
            let payload
            if (event.target.name == 'greenField') {
                payload = {
                    isSelected: event.target.checked,
                    value: 1
                }
                setGreenFieldChecked(event.target.checked)
            } else {
                payload = {
                    isSelected: event.target.checked,
                    value: 2,
                }
                setBrownFieldChecked(event.target.checked)
            }
            dispatch(updateCategoryLandTypeFilter(payload))
        }
    }

    const setLandTypeFilterTile = (tempFilter) => {
        let message = ""
        if (tempFilter?.projectLandType?.value && Array.isArray(tempFilter?.projectLandType?.value) && tempFilter?.projectLandType?.value?.find(x => x === 1) && tempFilter?.projectLandType?.value?.find(x => x === 2)) {
            message = message + 'Greenfield and Brownfield'
        } else {
            if (tempFilter?.projectLandType?.value && Array.isArray(tempFilter?.projectLandType?.value) && tempFilter?.projectLandType?.value?.find(x => x === 1)) {
                message = message + 'Greenfield'
            }
            if (tempFilter?.projectLandType?.value && Array.isArray(tempFilter?.projectLandType?.value) && tempFilter?.projectLandType?.value?.find(x => x === 2)) {
                message = message + 'Brownfield'
            }
        }
        return message
    }

    const scrollToComponent = (componentID) => {
        let elmnt = document.getElementById(componentID);
        if (elmnt && elmnt !== undefined) {
            elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    const sendData = (data) => {
        setData(oldArray => [...oldArray, data]);
    }

    const handleView = (context, filterState) => {
        handleCancel()
        dispatch(updateAppliedContext(context))
        dispatch(updateSelectedSearchType(context))
        handleContextChange(context, filterState)
        dispatch(updateSelectedSelectorControlValue(0));
        if (search.showSelectedCompanies || search.showSelectedProjects || (searchData.isRadialActive && search.isShowProjectsOrCompanySelected)) {
            setSearchData(prev => ({
                ...prev,
                isRadialActive: false
            }))
            dispatch(updateIsShowProjectsOrCompanySelected(false))
            dispatch(updateShowSelectedCompanies(false))
            dispatch(updateShowSelectedProjects(false));

            dispatch(updateHandleViewForShowCompanyShowProjects(true))

        }
    }

    const handleViewCount = () => {
        setRemoveCount(false)
        setRemoveFilters([])
        fetchEntityCounts()
    }

    const fetchEntityCounts = () => {
        var formValues = preProcessorQuery('', filter.filtersState, searchData.activeDisplayValue)
        if (!isViewClicked) {
            fetchSearchCount(formValues, dispatch, searchPersist)
            initTags()
        } else {
            initTags()
        }
    }

    const clearAllTags = () => {
        updateRemoveCount(false)
        setIsClearAll(true)
        let defaultFilter = filter.defaultFilter[searchPersist.appliedContext]
        dispatch(updateActivateFilter(defaultFilter))
        removeTagHandler('clearAll')
        setIsBuildToRentEnabled(true)
        setBuildToRent(false)
        setSiteToBeSold(false)
        setGreenFieldChecked(false)
        setBrownFieldChecked(false)
        setSearchData(prev => ({
            ...prev,
            isDisabled: false
        }))
    }

    useEffect(() => {
        if (isClearAll) {
            var formValues = preProcessorQuery('', filter.filtersState, searchData.activeDisplayValue)
            if (isViewClicked) {
                fetchSearchListCount(formValues, dispatch, search, settings)
            } else {
                fetchSearchCount(formValues, dispatch, searchPersist)
            }
            setIsClearAll(false)
        }
    }, [isClearAll])

    const confirmationToggle = () => {
        setIsOpenconfirmation(!isOpenconfirmation)
    }

    const toggleParentModal = () => {
        let isFiltersApplied = fetchAppliedFilters(searchPersist.summaryTags, tagList)
        if (!isFiltersApplied) {
            setIsOpenconfirmation(true)
        } else {
            setIsOpenconfirmation(false)
            handleConfirmCancel()
        }
    }

    const handleContextChange = (context, filterState, isCancel) => {
        if (context === 'project') {
            setTimeout(() => {
                toggleProjectTextHeight()
            }, 200);
        }
        let oldContext = filterState.selectedSearchType
        let newFilter = null
        if (context !== oldContext) {
            dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: oldContext }))
            dispatch(updateIfMoreResultAvailable(false))
            dispatch(updateSearchLoader(false))
            dispatch(updateNoResult(false))
            // 1. set the filters for new context as per the changes made in current filter object
            let filtersWithEditorPanelChanges = _.cloneDeep(filterState.filtersState[oldContext])

            // 2. Reset display, sort, offset, records to what it was as per previously applied search
            filtersWithEditorPanelChanges = {
                ...filtersWithEditorPanelChanges,
                display: appliedSearchFilters[context].display,
                sort: appliedSearchFilters[context].sort,
                offset: 0,
                records: 50
            }

            // 3. assign the new object to the new context selected
            let payload = {
                context: context,
                filter: filtersWithEditorPanelChanges
            }
            dispatch(updateContextFilter(payload))

            // 4. latest object having been updated, now we need to reset the previous context's filtersstate object which was modified with editor panel changes
            // let originalFiltersForPreviousContext = _.cloneDeep(appliedSearchFilters[oldContext])
            // payload = {
            //     context: oldContext,
            //     filter: originalFiltersForPreviousContext
            // }
            // dispatch(updateContextFilter(payload))
            newFilter = filtersWithEditorPanelChanges
            dispatch(updateSummaryTags(tagList))
            updateAppliedSearchFilters()
            // updateActiveDisplayValue(context)
            // dispatch(updateAppliedContext(context));
            // dispatch(updateSelectedSearchType(context));
            setSearchData((prevState) => {
                return {
                    ...prevState,
                    dropdownOpen: false,
                    activeDisplayValue: context,
                    displayLocationDropDownText: searchPersist.filterSearchText,
                    displayFilterDropDownText: context === 'project' ? 'Projects' : context === 'company' ? 'Companies' : 'People',
                    textPlaceholder: context === 'project' ? 'Project information' : context === 'company' ? 'Company information' : 'Person information'
                };
            });
            // if (context !== 'people') {
            //     this.props.setSelectionEntitiesFilter(context === 'project' ? 'company' : 'project')
            // }

            //TODO:: Need to handle this for savesearch
            // if(!this.props.filters.activeContextSaveSearchIds[context] ||
            //     this.props.filters.activeContextSaveSearchIds[oldContext]) {
            //     this.props.updateSaveSearchContext({display: context, id: null})
            // }
        } else {
            // since the new context and previous context are same, nothing special needs to be done
            // this else condition can be removed later if not in use
            updateActiveDisplayValue(context)
            newFilter = isCancel ? _.cloneDeep(appliedSearchFilters[searchData.activeDisplayValue]) : _.cloneDeep(filterState.filtersState[filterState.selectedSearchType])
            newFilter.display = context
            if (!isCancel) {
                newFilter.offset = 0
                newFilter.records = 50
            }
            let payload = {
                context: context,
                filter: newFilter
            }
            dispatch(updateContextFilter(payload))
            if (!isCancel) {
                dispatch(updateSummaryTags(tagList))
                updateAppliedSearchFilters()
            } else {
                setTagList(searchPersist.summaryTags)
                dispatch(updateOnNoFilterOption(true))
                dispatch(resetFilterStateWithDefaultFilters(appliedSearchFilters))
            }
        }
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        setIsOpenconfirmation(false)
        handleConfirmCancel()
        // newFilter = isCancel ? _.cloneDeep(appliedSearchFilters[searchData.activeDisplayValue]) : _.cloneDeep(filterState.filtersState[filterState.selectedSearchType])
        let display = isCancel ? searchData.activeDisplayValue : context
        let searchModeValue = fetchSearchMode(newFilter, display) ? "basic" : "advanced"
        dispatch(updateContextType({ context: context, searchMode: searchModeValue }))
        setSearchMode(searchModeValue)
        let sort = isCancel ? appliedSearchFilters[searchData.activeDisplayValue].sort : oldContext !== context ? getSortValueContextChange(context, appliedSearchFilters[oldContext].sort, filterState.filtersState[filterState.selectedSearchType]) : fetchHandleSubmitSort(searchData.activeDisplayValue, filterState.filtersState[filterState.selectedSearchType], true)
        dispatch(updateSort(sort))
        if (!isCancel) {
            let updatedSortOrderObj = _.cloneDeep(searchPersist.defaultSort);
            updatedSortOrderObj[context] = {
                label: fetchSortLabel(sort),
                value: sort,
            };
            dispatch(updateSortSearch(updatedSortOrderObj));
        }
        let textboxDisabled = filterState.filtersState[context]?.companyInformation?.value.length > 0 && context === 'company'
            || filterState.filtersState[context]?.personInformation?.value.length > 0 && context === 'people'
        setSearchData(prev => ({
            ...prev,
            isButtonClicked: true,
            isDisabled: textboxDisabled
        }))
        // newFilter.offset = 0
        //TODO: Need to check archive scenario
        // filter.records = getDefaultRecordCount(this.props.archiveCount, this.state.activeDisplayValue)
        // newFilter.records = 50
        // newFilter.event_type = 'search'
        // setTimeout(() => {
        //     updateWidthSearchBoxTile();
        // }, 500);
    }

    const updateWidthSearchBoxTile = () => {
        if (googleInputRef.current) {
            widthOfMainSearch = document.getElementById(googleInputRef.current.id)?.offsetWidth;
            children = document.getElementById(googleInputRef.current.id)?.children;
            totalWidth = 146;
            if (children) {
                for (var i = 0; i < children.length; i++) {
                    totalWidth += children[i].offsetWidth;
                }
            }
        }
        else {
            totalWidth = 0
        }
    }

    const showMoreTags = () => {
        if (tagsShowMoreClass == "") {
            setTagsShowMoreClass('show-more')
        } else {
            setTagsShowMoreClass('')
        }
    }

    const toggleShowTown = (e) => {
        e.stopPropagation()
        setIsShowMoreTown(!isShowMoreTown)
    }

    const toggleShowPostCode = (e) => {
        e.stopPropagation()
        setIsShowMorePostCode(!isShowMorePostCode)
    }

    const toggleField = (fieldInput) => {
        if (fieldInput === 'town-search') {
            if (townSearchInput.current !== null) {
                let selectCntrlWrapper = townSearchInput.current.children[0].children[2];
                let townsearchlistElement = townSearchInput.current.children[0].children[2].children[0].children;
                let allElWidth = 0;
                for (let i = 0; i < townsearchlistElement.length; i++) {
                    let eachEle = townsearchlistElement[i];
                    let elWidth = eachEle.getBoundingClientRect();
                    allElWidth += parseInt(elWidth.width) + 5;
                }
                if (allElWidth > (selectCntrlWrapper.offsetWidth)) {
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            }
        } else {
            if (postCodeSearchInput.current !== null) {
                let selectCntrlWrapper = postCodeSearchInput.current.children[0].children[2];
                let postcodelistElement = postCodeSearchInput.current.children[0].children[2].children[0].children;
                let allElWidth = 0;
                for (let i = 0; i < postcodelistElement.length; i++) {
                    let eachEle = postcodelistElement[i];
                    let elWidth = eachEle.getBoundingClientRect();
                    allElWidth += parseInt(elWidth.width) + 5;
                }
                if (allElWidth > (selectCntrlWrapper.offsetWidth)) {
                    setPostCodeHasMore(true)
                } else {
                    setPostCodeHasMore(false)
                }
            }


        }

    }

    const toggleProjectTextHeight = () => {
        if (projectTextInput.current !== null) {
            let selectCntrlWrapper = projectTextInput.current;
            let projectInfolistElement = projectTextInput.current.children[1].children;
            let allElWidth = 0;
            for (let i = 0; i < projectInfolistElement.length; i++) {
                let eachEle = projectInfolistElement[i];
                let elWidth = eachEle.getBoundingClientRect();
                allElWidth += parseInt(elWidth.width) + 5;
            }
            if (allElWidth > (selectCntrlWrapper.offsetWidth - 205)) {
                setHasMoreProjectText(true)
            } else {
                setHasMoreProjectText(false)
            }
        }
    }

    const toggleProjectTextBoxHeight = () => {
        setAutoHeight(!autoHeight)
    }

    return (
        <>
            <div className="search-container new-filter">
                <Form>
                    <div className="search-container-wrapper">
                        <div className={`d-flex justify-content-between pro-comp-field ${searchMode === 'advanced' ? 'advanced-search-form-field' : ""}`}>
                            <div className="form-container d-flex">
                                <div className={`object-info-container d-flex ${searchData.activeDisplayValue === 'people' ? 'full-width' : ''}`}>
                                    <div className="objectType-dropdown me-2">
                                        <Dropdown isOpen={searchData.dropdownOpen} toggle={() => setSearchData(prevState => ({ ...prevState, dropdownOpen: !searchData.dropdownOpen }))} data-intro={!isOpen ? TOURPROJECTLISTVIEW.switchbetween.intro : null} data-position={TOURPROJECTLISTVIEW.switchbetween.position} data-class={TOURPROJECTLISTVIEW.switchbetween.class}>
                                            <DropdownToggle caret>
                                                <Icon color="#053874" size={20} icon={`${searchData.displayFilterDropDownText === 'Projects' ? 'icon-gen-project' : searchData.displayFilterDropDownText === 'Companies' ? 'icon-gen-company' : 'icon-gen-ppl'}`} className="me-1" />{searchData.displayFilterDropDownText}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                Search for
                                                {
                                                    searchData.dropDownFilterType && searchData.dropDownFilterType.map(text => (
                                                        <>
                                                            <DropdownItem onClick={() => handleFilterDropdownText(text, filter)}>
                                                                <Icon color="#292B2C" size={20} icon={`${searchData.activeDisplayValue === text ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1" /><Icon color="#053874" size={20} icon={`${text === 'project' ? 'icon-gen-project' : text === 'company' ? 'icon-gen-company' : 'icon-gen-ppl'}`} className="me-1" />{text === 'project' ? "Projects" : text === 'company' ? "Companies" : "People"}
                                                            </DropdownItem>
                                                        </>
                                                    ))
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className={"project-search-box" + (searchMode !== "advanced" ? "" : " hide")}>
                                        {
                                            searchData.activeDisplayValue != "project" ?
                                                <div className="hori-placeholder-tour right-zero top-zero" data-intro={!isOpen ? TOURPROJECTLISTVIEW.performsearch.intro : null} data-position={TOURPROJECTLISTVIEW.performsearch.position} data-class={TOURPROJECTLISTVIEW.performsearch.class}>&nbsp;</div> :
                                                ""
                                        }
                                        {searchData.activeDisplayValue === "project" ?
                                            <CreatableSelect
                                                ref={projectSerachRef}
                                                components={{ DropdownIndicator }}
                                                inputValue={searchData.inputValue}
                                                menuIsOpen={false}
                                                isClearable
                                                isMulti
                                                value={multiInputValue}
                                                placeholder={<span className='label-placeholder'>{searchData.textPlaceholder}</span>}
                                                autoBlur={true}
                                                onChange={(newValue) => setMultiInputValue(newValue)}
                                                onInputChange={(newValue) => setSearchData(prev => ({ ...prev, inputValue: newValue }))}
                                                onKeyDown={handleKeyDown}
                                                onBlur={handleOnBlur}
                                                styles={{
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        // workaround zone
                                                        pointerEvents: "none",
                                                        userSelect: "none",
                                                        MozUserSelect: "none",
                                                        WebkitUserSelect: "none",
                                                        msUserSelect: "none"
                                                    }),
                                                    input: (base) => ({
                                                        ...base,
                                                        gridTemplateColumns: '0fr',
                                                    }),
                                                    placeholder: (base) => ({
                                                        ...base,
                                                        pointerEvents: "none",
                                                        userSelect: "none",
                                                    }),
                                                }}
                                            />
                                            :
                                            <Input type="text"
                                                autoComplete="off"
                                                placeholder={searchData.textPlaceholder}
                                                onChange={(e) => setSearchData(prev => ({ ...prev, inputValue: e.target.value }))}
                                                onKeyDown={handleKeyDown}
                                                value={searchData.inputValue}
                                                disabled={searchData.isDisabled}
                                                onBlur={handleOnBlur}
                                            />
                                        }
                                    </div>

                                </div>
                                {searchData.activeDisplayValue !== 'people' ?
                                    <div className={"radial-search location-field textfield-wrapper d-flex ms-2" + (searchMode !== "advanced" ? "" : " hide")}>
                                        {activeFilterValue !== "people" ?
                                            <>
                                                <Dropdown isOpen={searchData.locationDropdownOpen} toggle={() => setSearchData(prevState => ({ ...prevState, locationDropdownOpen: !searchData.locationDropdownOpen }))}>
                                                    <DropdownToggle caret>
                                                        {searchData.displayLocationDropDownText}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            searchData.locationFilterType && searchData.locationFilterType.map(text => (
                                                                <>
                                                                    <DropdownItem onClick={() => handleLocationFilter(text)}>
                                                                        {text}
                                                                    </DropdownItem>
                                                                </>
                                                            ))
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                                {
                                                    searchData.displayLocationDropDownText === "Radial search" ?

                                                        <div className="radial-search-input-container" data-intro={!isOpen ? TOURPROJECTLISTVIEW.addresspostcode.intro : null} data-position={TOURPROJECTLISTVIEW.addresspostcode.position} data-class={TOURPROJECTLISTVIEW.addresspostcode.class}>
                                                            <AutoComplete
                                                                inputClass='form-control search mb-0'
                                                                onSuggestionSelect={suggestionSelect}
                                                                country='gb,ie,im,je,gg'
                                                                limit='10'
                                                                placeholder="Location"
                                                                clearSearchQuery={searchData.clearSearch}
                                                                isRadialSearchActive={searchData.isRadialSearchActive}
                                                                isSetCurrentLocation={searchData.isSetCurrentLocation}
                                                            />

                                                            <Button className={`clear-textfield clear-location-field-basic-search ${searchData.locationCloseButton ? 'display-block' : 'display-none'}`} onClick={handleLocationClose} tabIndex="-1">x</Button>
                                                            <Button className="radial-textfield clear-location-field-basic-search" disabled={!searchData.locationCloseButton}
                                                                onClick={handleRadial} tabIndex="-1"></Button>
                                                            <Button onClick={enableCurrentLocation} data-tooltip-id="get-location" data-tooltip-hidden={!settings.preferences.hint_text} className="get-location-icon" tabIndex="-1"></Button>
                                                            <ReactTooltip id="get-location" place="bottom" type='dark' effect="solid">
                                                                <p className="tool-tip-sidebar">Get current location</p>
                                                            </ReactTooltip>
                                                            <div className={`radius-src-filter ${searchData.radialView ? '' : 'hide'}`}>
                                                                <div className="selected-radius">
                                                                    <span>Search radius </span>
                                                                    <span>{searchData.distance}<label className="unit">{searchData.distance == 1 ? 'mile' : 'miles'}</label></span>
                                                                </div>
                                                                <div className="rc-slider-wrapper">
                                                                    <Slider
                                                                        allowCross={true}
                                                                        onAfterChange={onSliderChange}
                                                                        onChange={setValue}
                                                                        value={searchData && searchData.distance ? searchData.distance : 25}
                                                                        min={1}
                                                                        max={50} />
                                                                    <span className="range-start-label">1</span>
                                                                    <span className="range-end-label">50</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                                {
                                                    searchData.displayLocationDropDownText === "Town search" ?
                                                        <>
                                                            <div className={`${isShowMoreTown ? 'show-more-text' : ""}`} ref={townSearchInput}>
                                                                <CreatableSelect
                                                                    components={{ DropdownIndicator }}
                                                                    inputValue={searchData.inputTownValue}
                                                                    menuIsOpen={false}
                                                                    isClearable
                                                                    isMulti
                                                                    value={searchLocationObj[searchData.activeDisplayValue].town}
                                                                    placeholder="Search multiple towns"
                                                                    autoBlur={true}
                                                                    onChange={(newValue) => handleTownSearchChange(newValue)}
                                                                    onInputChange={(newValue) => setSearchData(prev => ({ ...prev, inputTownValue: newValue }))}
                                                                    onKeyDown={handleKeyDownLocation}
                                                                    className="react-town-select-container"

                                                                />
                                                                {hasmore ?
                                                                    <div onClick={(e) => toggleShowTown(e)} className={`${!isShowMoreTown ? 'show-less-town-arrow' : 'show-more-town-arrow'}`}></div> : ""}
                                                            </div>
                                                        </>
                                                        : ""}
                                                {searchData.displayLocationDropDownText === "Postcode search" ?
                                                    <>
                                                        <div className={`${isShowMorePostCode ? 'show-more-text' : ""}`} ref={postCodeSearchInput}>
                                                            <CreatableSelect
                                                                components={{ DropdownIndicator }}
                                                                inputValue={searchData.inputPostCodeValue}
                                                                menuIsOpen={false}
                                                                isClearable
                                                                isMulti
                                                                value={searchLocationObj[searchData.activeDisplayValue].postcode}
                                                                placeholder="Search partial and full postcodes "
                                                                autoBlur={true}
                                                                onChange={(newValue) => handlePostcodeSearchChange(newValue)}
                                                                onInputChange={(newValue) => setSearchData(prev => ({ ...prev, inputPostCodeValue: newValue }))}
                                                                onKeyDown={handleKeyDownLocation}
                                                                className="react-town-select-container"
                                                            />
                                                            {postCodeHasMore ?
                                                                <div onClick={(e) => toggleShowPostCode(e)} className={`${!isShowMorePostCode ? 'show-less-postCode-arrow' : 'show-more-postCode-arrow'}`}></div> : ""}
                                                        </div>
                                                    </>
                                                    : ""}
                                            </> : ("")}
                                    </div> : ("")
                                }
                                <div id="mainSearchBox" className={`advanced-filters-tags ${tagsShowMoreClass} ${tagList.length <= 2 ? 'less-than-2' : ''} ${searchMode !== "advanced" ? "hide" : ""}`}>
                                    {tagList?.map(function (item, index) {
                                        return (
                                            <div className="tag-element" key={index} ref={googleInputRef}>
                                                <span className={`tag-title ${item.value == "" || item.value == null ? 'onlyTitle' : ''}`}>{item.name}</span>
                                                <span className="tag-content" title={item.value}>{item.value}</span>
                                                <span className="tag-close-button" data-closestate={item.title} onClick={(e) => { addRemoveTag(item.title, false, false, e) }}></span>
                                            </div>
                                        )
                                    }, this)}
                                    <div className="advancedShow-btn justify-content-end d-flex">
                                        <a className={`advanced-secondary-btn ${tagList.length > 0 ? 'show' : 'hide'}`} onClick={clearAllTags}>Clear all</a>
                                        <a className={`advanced-show-more advanced-secondary-btn ${tagList.length > 2 || totalWidth >= widthOfMainSearch ? 'show' : 'hide'}`} onClick={showMoreTags}>{tagsShowMoreClass == "show-more" ? "Show less" : "Show more"}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="search-action src-button d-flex ms-2">
                                <div className="src-button">
                                    <button className="btn btn-default" type="button" onClick={handleFilter} data-intro={!isOpen ? TOURPROJECTLISTVIEW.morefilters.intro : null} data-position={TOURPROJECTLISTVIEW.morefilters.position} data-class={TOURPROJECTLISTVIEW.morefilters.class}>Filter <span className={`${tagList.length === 0 ? "hide" : ""}`}>{tagList.length}</span></button>
                                </div>
                                <div className="ms-2 right-save-col">
                                    <button className="btn primary-btn" type="button" onClick={handleSubmit} data-intro={!isOpen ? TOURPROJECTLISTVIEW.runsearch.intro : null} data-position={TOURPROJECTLISTVIEW.runsearch.position} data-class={TOURPROJECTLISTVIEW.runsearch.class} ><Icon color="#FFFFFF" size={25} icon="icon-sb--search" className="me-1" />Search</button>
                                </div>
                            </div>
                        </div>
                        {searchMode === 'basic' ?
                            searchTextObj?.[searchData.activeDisplayValue] != undefined && searchTextObj?.[searchData.activeDisplayValue]?.length > 0 ?
                                <div className="d-flex align-items-top">
                                    <div ref={projectTextInput} className={`project-information-wrapper d-flex selected-info-label-list mt-1 ${searchData.activeDisplayValue !== "project" ? 'single-info' : ''} `}>
                                        <label className="label me-1">{(searchData.activeDisplayValue).charAt(0).toUpperCase() + (searchData.activeDisplayValue).slice(1)} info :-</label>
                                        {
                                            <div className={`tag-list-wrapper d-flex ${autoHeight ? 'auto-height' : ""}`}>
                                                {searchTextObj[searchData.activeDisplayValue].map(function (item, index) {
                                                    return (
                                                        <>
                                                            <span key={`label-list-${index}`} className='Select-value-tag me-1'>
                                                                <span className="Select-value-icon" aria-hidden="true" onClick={() => handleUnLinkTags(item)}>×</span>
                                                                <span className="Select-value-label">{item.value}</span>
                                                            </span>
                                                        </>)
                                                })
                                                }
                                            </div>
                                        }
                                        {(searchData.activeDisplayValue === "project") && (searchTextObj?.[searchData.activeDisplayValue] != undefined) && (searchTextObj?.[searchData.activeDisplayValue]?.length > 1) ?
                                            <div className="any-all-comp">
                                                <div className={`switch-with-text ${searchPersist.searchTextInfo[searchData.activeDisplayValue].length > 1 || searchTextObj[searchData.activeDisplayValue].length > 1 ? "" : "disabled"}`}>
                                                    <input type="radio" className="switch-input" name="view" value="any" id="any" onChange={(e) => toggleAnyAll(e, "any")} checked={searchData.addAnyButtonValue === 'any'} />
                                                    <label for="any" className="switch-label switch-label-off">Any</label>
                                                    <input type="radio" className="switch-input" name="view" value="all" id="all" onChange={(e) => toggleAnyAll(e, "all")} checked={searchData.addAnyButtonValue === 'all'} />
                                                    <label for="all" className="switch-label switch-label-on">All</label>
                                                    <span className="switch-selection"></span>
                                                </div>
                                            </div>
                                            : ""}
                                    </div>
                                    {hasmoreProjectText ? <div className="showmore-tiles ms-2" onClick={toggleProjectTextBoxHeight}>Show {autoHeight ? 'less' : 'more'}</div> : ""}
                                    <div className={search.invalidRequest ? 'term-error' : ''}>{search.invalidRequest ? <><p>You have reached the maximum number of words for this search</p></> : ''}</div>
                                </div>
                                : "" : ""
                        }
                    </div>
                </Form>
                <Modal isOpen={isOpen} id="filterModal" className='filterModalBox modal-xl'>
                    <ModalHeader className='d-flex pt-0 ps-0' toggle={toggleParentModal}>
                        <div class="container ps-0">
                            <div class="row">
                                <div class="col-7 d-flex align-items-center">
                                    <h3>Refine search</h3><span>Quick guide</span>
                                </div>
                                <div class="col-5 d-flex ps-3" data-intro={TOURFILTERVIEW.clearall.intro} data-position={TOURFILTERVIEW.clearall.position} data-class={TOURFILTERVIEW.clearall.class}>
                                    <h3>Summary</h3>
                                    <Button className="btn-outline-red mb-1 ms-3" onClick={clearAllTags}>Clear all</Button>
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody className='pt-1 px-0'>
                        <Row className="bg-white filter-page-wrapper">
                            <Col lg="2" md="2" className="col-one">
                                <FilterMenu
                                    advancedFilterState={filter}
                                    gotoComponent={scrollToComponent}
                                    currentActiveState={data}
                                ></FilterMenu>
                            </Col>
                            <Col lg="5" md="5" className="filter-col-wrapper col-two">
                                <ColoredScrollbars className="advanced-filter-panel-scroller">
                                    <ProjectTextSearch sendData={sendData}
                                        variableHandler={variableBinding}
                                        initTags={initTags} isFilterPanel={true}
                                        fetchEntityCounts={fetchEntityCounts}
                                        isClearAll={isClearAll}
                                    />
                                    <UpdatedFilter variableHandler={variableBinding} initTags={initTags} fetchEntityCounts={fetchEntityCounts} />
                                    <ProjectLocationFilter variableHandler={variableBinding} initTags={initTags}
                                        fetchEntityCounts={fetchEntityCounts} searchLocationObj={searchLocationObj} isClearAll={isClearAll} />
                                    <ProjectRegionsCountiesFilter isExclude={true} variableHandler={variableBinding} initTags={initTags}
                                        fetchEntityCounts={fetchEntityCounts} currentAdvancedSearch={currentAdvancedSearch} isClearAll={isClearAll}
                                        handleSearchText={handleSearchTextFromFilter}
                                    />
                                    <CategoryTypeFilter checkboxListFilters={[checkboxFilterList[1]]}
                                        currentAdvancedSearch={currentAdvancedSearch} variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags}
                                        greenFieldChecked={greenFieldChecked}
                                        brownFieldChecked={brownFieldChecked}
                                        isBuildToRentEnabled={isBuildToRentEnabled}
                                        buildToRent={buildToRent}
                                        siteToBeSold={siteToBeSold}
                                        isClearAll={isClearAll}
                                        handleSearchText={handleSearchTextFromFilter}
                                    />
                                    <DevTypeFilter checkboxListFilters={[checkboxFilterList[2]]}
                                        currentAdvancedSearch={currentAdvancedSearch} variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags}
                                        isClearAll={isClearAll}
                                        handleSearchText={handleSearchTextFromFilter}
                                    />
                                    <ValueFilter initTags={initTags}
                                        fetchEntityCounts={fetchEntityCounts}
                                        isClearAll={isClearAll}
                                    />
                                    <FrameworkFilter variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags} isClearAll={isClearAll} />
                                    <ProjectTimingFilter fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags} isClearAll={isClearAll} />
                                    <StagesFilter variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags}
                                        currentAdvancedSearch={currentAdvancedSearch}
                                        handleSearchText={handleSearchTextFromFilter}
                                        isClearAll={isClearAll}  />
                                    <BuildPhase fetchEntityCounts={fetchEntityCounts} isClearAll={isClearAll} />
                                    <IncludeCompletedProjectsFilter sendData={sendData} fetchEntityCounts={fetchEntityCounts} isClearAll={isClearAll} />
                                    <MaterialFilter isExclude={true} variableHandler={variableBinding}
                                        initTags={initTags}
                                        fetchEntityCounts={fetchEntityCounts}
                                        currentAdvancedSearch={currentAdvancedSearch}
                                        isClearAll={isClearAll}
                                        handleSearchText={handleSearchTextFromFilter}
                                    />
                                    <MeasuresFilter initTags={initTags} isClearAll={isClearAll} />
                                    <RoleFilter checkboxListFilters={[checkboxFilterList[0]]}
                                        currentAdvancedSearch={currentAdvancedSearch} variableHandler={variableBinding}
                                        initTags={initTags}
                                        fetchEntityCounts={fetchEntityCounts}
                                        isClearAll={isClearAll}
                                        handleSearchText={handleSearchTextFromFilter}
                                    />
                                    <CompanyTextSearch variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags} isClearAll={isClearAll} />
                                    <CompanyLocation fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags} searchLocationObj={searchLocationObj} isClearAll={isClearAll} />
                                    <CompanyLocationRegionsCounties
                                        fetchEntityCounts={fetchEntityCounts} currentAdvancedSearch={currentAdvancedSearch} isClearAll={isClearAll}
                                        isExclude={true} variableHandler={variableBinding} initTags={initTags}
                                        handleSearchText={handleSearchTextFromFilter} />
                                    <PersonTextSearch variableHandler={variableBinding}
                                        fetchEntityCounts={fetchEntityCounts}
                                        initTags={initTags} isClearAll={isClearAll} />
                                </ColoredScrollbars>
                            </Col>
                            <Col lg="5" md="5" className="summary-col-wrapper col-three">
                                <ColoredScrollbars className="advanced-filter-panel-scroller">
                                    <SummaryTiles setRemoveCount={setRemoveCount} tagList={tagList} gotoComponent={scrollToComponent} addRemoveTag={addRemoveTag} />
                                </ColoredScrollbars>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className='border-top-0 pe-4'>
                        {removeCount ?
                            <Button className="outline-btn d-flex refresh-icon justify-content-center" onClick={handleViewCount}><i></i>View Count</Button>
                            :
                            <>
                                {
                                    appliedFilter !== undefined && appliedFilter !== "people" ? (
                                        <><Button id="primaryBtn" onClick={() => handleView('project', filter)} className="common-btn btn btn-default first-btn p-2 me-0"><div class="viewTxtcount d-flex">View</div>
                                            <span> {isAnyFilterApplied ? search?.searchCount?.projects_count : "All"} </span>
                                            <abbr>projects</abbr></Button><Button onClick={() => handleView('company', filter)} className="outline-btn common-btn second-btn p-2 ms-0"><div class="viewTxtcount d-flex">View</div>
                                                <span> {isAnyFilterApplied ? search?.searchCount?.companies_count : "All"} </span>
                                                <abbr>companies</abbr></Button></>
                                    )
                                        :
                                        <Button onClick={() => handleView('people', filter)} className="outline-btn common-btn second-btn p-2 ms-0"><div class="viewTxtcount d-flex">View</div>
                                            <span> {isAnyFilterApplied ? search?.searchCount?.people_count : "All"} </span>
                                            <abbr>people</abbr></Button>
                                }
                            </>
                        }
                    </ModalFooter>
                </Modal>
                {
                    <>
                        <Modal className="save-filter-modal" isOpen={isOpenconfirmation}>
                            <ModalHeader toggle={confirmationToggle}>
                                You have unsaved changes
                            </ModalHeader>
                            <ModalBody className="save-model-body dialog-warning-msg">
                                Do you want to save your filter changes?
                            </ModalBody>
                            <ModalFooter>
                                <Button className="primary-btn btn-save-cancel btn btn-secondary" onClick={() => handleContextChange(appliedFilter, filter)}>Yes</Button>
                                <Button className="outline-btn btn-save-delete btn btn-secondary" onClick={() => handleContextChange(appliedFilter, filter, true)}>No</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                }
            </div>
        </>
    )
}

export default SearchFilterModal;