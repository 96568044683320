import React, { useEffect, useState } from 'react'
import { InputGroup } from 'reactstrap';
import Icon from '../../common/js/icon';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSettings, updateUserSettings } from '../apiclient';

function FavouriteRoles({active}) {

	const [multiInputValue, setMultiInputValue] = useState([])
	const [rolesOption, setRolesOption] = useState([])
	const [favouriteRoles, setFavouriteRoles] = useState([])

	const setting = useSelector((state) => state.settings);
	const userData = useSelector((state) => state.user.user);
	const dispatch = useDispatch();

	useEffect(() => {
		let rolesOptionList = []
		if (setting && setting.rolesLookup && setting.rolesLookup.length > 0) {
			setting.rolesLookup.map(roles => {
				rolesOptionList.push({ value: roles.id, label: roles.description })
			})
		}
		let data = rolesOptionList.filter(x => setting.preferences && setting.preferences.favourite_roles && setting.preferences.favourite_roles.includes(parseInt(x.value)))
		setFavouriteRoles(data)
		if (setting.preferences && setting.preferences.favourite_roles && setting.preferences.favourite_roles.length > 0) {
			setRolesOption(rolesOptionList && rolesOptionList.filter(x => !data.some(y => y.value === x.value)))
		} else {
			setRolesOption(rolesOptionList)
		}
	}, [setting.rolesLookup, setting.notificationSettings])

	const handleAddRoles = () => {
		const updatedFavouriteRoles = [...favouriteRoles, ...multiInputValue];
		setFavouriteRoles([...new Set(updatedFavouriteRoles)])
		const uniqueRoles = [...new Set(updatedFavouriteRoles)];
		const roleIds = uniqueRoles.map((x) => parseInt(x.value));
		updateUserSettings({ "favourite_roles": roleIds.length > 0 ? roleIds : '' }, userData.user_id, dispatch, 'fav-roles')
		setMultiInputValue([]);
	};

	const handleRemoveRoles = (value) => {
		let roleIds = []
		const uniqueRole = favouriteRoles.filter(e => e.value !== value)
		setFavouriteRoles(uniqueRole);
		roleIds = uniqueRole.map(x => parseInt(x.value));
		updateUserSettings({ "favourite_roles": roleIds.length > 0 ? roleIds : [] }, userData.user_id, dispatch, 'fav-roles')
	}

	const handleLinkLabelChange = (values) => {
		setMultiInputValue(values)
	}

	useEffect(()=>{
		if (active) {
			setMultiInputValue([])
		}
	},[active])

	const NoOptionsMessage = (props) => {
		return (
		 <div {...props} className="text-left">No result found</div>
		);
	   }

	return (
		<div className="favouriteRoles-wrapper">
			<div className="content-panel p-1 pt-2">
				<div className="col-md-12 col-sm-12">
					<p>Select the roles that are most important to you</p>
					<div className="share-details label-list">
						<div className="share-details-wrapper">
							<InputGroup>
								<Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="me-2 circle-border" />
								<CreatableSelect
									options={rolesOption}
									isClearable
									isMulti
									value={multiInputValue}
									placeholder={<span className='label-placeholder'> Select roles </span>}
									onChange={handleLinkLabelChange}
									isValidNewOption={() => false}
									noOptionsMessage = {NoOptionsMessage}
									inputProps={{autoComplete: 'random-string', autofill:'off' }}
								/>
								<div><button className="primary-btn btn-share" disabled={multiInputValue == null || multiInputValue == ''} onClick={handleAddRoles}>Add roles</button></div>
							</InputGroup>
						</div>
						<div className="label-details-wrapper">
							{
								favouriteRoles && favouriteRoles.map(function (favouriteRole, index) {
									return (
										<span key={`label-list-${index}`} className='labels-list-wrapper' >
											<a id={`label-${index}`} className='labels-list'>
												{favouriteRole.label}
											</a>
											{
												<span className="remove-label" aria-hidden="true" onClick={() => handleRemoveRoles(favouriteRole.value)}>×</span>
											}
										</span>)
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FavouriteRoles;