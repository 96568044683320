import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import 'rc-slider/assets/index.css';
import _, { cloneDeep } from 'lodash';
import Slider from 'rc-slider';
import { FaSearch } from "react-icons/fa";
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { createCommonQuery } from '../../../../utils/commonFunctions';
import { MIN_MILES } from '../../../../utils/settings'
import AutoComplete from '../autoComplete';
import { updateQueryResult, updateLocationSearchObject } from '../../redux/reducer';
import { updateAdvanceLocation, updateFilterText } from '../redux/reducer';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { updateLocationSearchObj } from '../../redux/searchPeristReducer';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <FaSearch />
        </components.DropdownIndicator>
    )
}

const CompanyLocation = (props) => {
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const filter = useSelector((state) => state.filter)
    let activeFilterValue = searchPersist.appliedContext;
    const [activeTab, setActiveTab] = useState(props.searchLocationObj[activeFilterValue]?.town?.length > 0 ? 'Town search' : props.searchLocationObj[activeFilterValue]?.postcode?.length > 0 ? 'Postcode search' : 'Radial search');
    const [searchLocationObject, setSearchLocationObject] = useState({ project: {}, company: {}, people: {} })
    const [searchData, setSearchData] = useState({
        clearSearch: false,
        latitude: 0,
        longitude: 0,
        distance: filter.filtersState[activeFilterValue]?.companyLocation?.radius ? filter.filtersState[activeFilterValue]?.companyLocation?.radius : '',
        locationSearchData: "",
        isRadialSearchActive: false,
        inputTownValue: "",
        inputPostCodeValue: "",
        isSetCurrentLocation: false,
        townlength: 0,
        postcodelength: 0,
        locationCloseButton: (searchPersist?.locationSearchObj?.[activeFilterValue]?.companyLocation?.length > 0 || search?.locationSearchObject?.[activeFilterValue]?.companyLocation?.length > 0) ? true : false,
        radialView: false,
        isToggleClicked: false
    })

    const dispatch = useDispatch();

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
            setSearchData(prev => ({
                ...prev,
                clearSearch: true,
                locationCloseButton: false,
                isToggleClicked: true
            }))
            searchLocationObject[activeFilterValue].location = ""
            searchLocationObject[activeFilterValue].latitude = ""
            searchLocationObject[activeFilterValue].longitude = ""
            searchLocationObject[activeFilterValue].distance = ""
            searchLocationObject[activeFilterValue].town = []
            searchLocationObject[activeFilterValue].postcode = []
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
            handleFocus(tab)
        }
    }

    const handleFocus = (tab) => {
        if (tab === "Radial search") {
            let payload = {
                value: [],
                dataObj: [],
                active: false,
                keyname: 'companyTown'
            }
            dispatch(updateFilterText(payload))
            payload.keyname = 'companyPostcode'
            dispatch(updateFilterText(payload))
        } else if (tab === "Town search" || tab === "Postcode search") {
            let payload = {
                value: [],
                dataObj: [],
                active: false,
                keyname: tab === "Town search" ? 'companyPostcode' : 'companyTown'
            }
            dispatch(updateFilterText(payload))
            var locationData = { location: '', latitude: 0, longitude: 0, radius: MIN_MILES, active: false, keyname: "companyLocation" }
            dispatch(updateAdvanceLocation(locationData))
        }
    }

    const suggestionSelect = (result, lat, lng, text, radius) => {
        if (radius === searchData.distance || searchData.distance === '') {
            setSearchData(prevState => ({
                ...prevState,
                distance: radius
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                distance: searchData.distance
            }))
        }
        setSearchData(prev => ({
            ...prev,
            locationCloseButton: result?.length > 0 ? true : false,
            clearSearch: false,
            latitude: lat ? parseFloat(lat) : lat,
            longitude: lng ? parseFloat(lng) : lng,
            distance: radius ? parseFloat(radius) : radius,
            locationSearchData: result
        }))
        var locationData = {
            location: result,
            latitude: lat ? parseFloat(lat) : lat,
            longitude: lng ? parseFloat(lng) : lng,
            radius: radius ? parseFloat(radius) : radius,
            active: true,
            keyname: "companyLocation"
        }
        dispatch(updateAdvanceLocation(locationData))
        searchLocationObject[activeFilterValue].location = result
        searchLocationObject[activeFilterValue].latitude = lat ? parseFloat(lat) : lat
        searchLocationObject[activeFilterValue].longitude = lng ? parseFloat(lng) : lng
        searchLocationObject[activeFilterValue].distance = searchData.distance === '' ? radius ? parseFloat(radius) : radius : searchData.distance
        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObj(_.cloneDeep(searchLocationObject)));
    }

    const handleLocationClose = (event) => {
        event.preventDefault()
        setSearchData(prev => ({
            ...prev,
            locationCloseButton: false,
            clearSearch: true,
            locationSearchData: "",
            latitude: "",
            longitude: "",
            distance: ""
        }))
        dispatch(updateQueryResult([]))
        searchLocationObject[activeFilterValue].location = ""
        searchLocationObject[activeFilterValue].latitude = ""
        searchLocationObject[activeFilterValue].longitude = ""
        searchLocationObject[activeFilterValue].distance = ""
        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        dispatch(updateLocationSearchObj(_.cloneDeep(searchLocationObject)));
        var locationData = { location: '', latitude: 0, longitude: 0, radius: MIN_MILES, active: false, keyname: "companyLocation" }
        dispatch(updateAdvanceLocation(locationData))
    }

    const enableCurrentLocation = () => {
        let lat = null,
            lng = null
        navigator.geolocation.getCurrentPosition(position => {
            lat = position.coords.latitude
            lng = position.coords.longitude
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: true,
                clearSearch: false,
                latitude: lat ? parseFloat(lat) : lat,
                longitude: lng ? parseFloat(lng) : lng,
                distance: MIN_MILES,
                locationSearchData: "Current location",
                isSetCurrentLocation: true
            }))
            searchLocationObject[activeFilterValue].location = "Current location"
            searchLocationObject[activeFilterValue].latitude = lat ? parseFloat(lat) : lat
            searchLocationObject[activeFilterValue].longitude = lng ? parseFloat(lng) : lng
            searchLocationObject[activeFilterValue].distance = searchData?.distance != "" ? searchData.distance : MIN_MILES
            setSearchLocationObject(_.cloneDeep(searchLocationObject))
            var locationData = {
                location: "Current location",
                latitude: lat ? parseFloat(lat) : lat,
                longitude: lng ? parseFloat(lng) : lng,
                radius: MIN_MILES,
                active: true,
                keyname: "companyLocation"
            }
            dispatch(updateAdvanceLocation(locationData))
        })
    }

    useEffect(() => {
        if (filter.filtersState[activeFilterValue]?.companyLocation?.active) {
            setSearchData(prev => ({
                ...prev,
                locationCloseButton: filter.filtersState[activeFilterValue]?.companyLocation.location === '' ? false : true,
                clearSearch: filter.filtersState[activeFilterValue]?.companyLocation.location !== '' ? false : true,
                locationSearchData: filter.filtersState[activeFilterValue]?.companyLocation.location
            }))
            var locationData = {
                location: filter.filtersState[activeFilterValue]?.companyLocation.location,
                latitude: filter.filtersState[activeFilterValue]?.companyLocation.latitude,
                longitude: filter.filtersState[activeFilterValue]?.companyLocation.longitude,
                distance: filter.filtersState[activeFilterValue]?.companyLocation.distance
            }

            if (searchLocationObject && searchLocationObject?.[activeFilterValue] != undefined) {
                Object.assign(searchLocationObject?.[activeFilterValue], locationData);
                setSearchLocationObject(_.cloneDeep(searchLocationObject))
            }

        }

        if (filter.filtersState[activeFilterValue]?.companyTown?.value &&
            filter.filtersState[activeFilterValue]?.companyTown?.value.length > 0) {
            let location = filter.filtersState[activeFilterValue]?.companyTown.value
            let townArr = []
            location?.map(item => {
                townArr.push({ label: item, value: item })
            })
            if (searchLocationObject && searchLocationObject?.[activeFilterValue] != undefined) {
                Object.assign(searchLocationObject?.[activeFilterValue], { town: townArr });
                setSearchLocationObject(_.cloneDeep(searchLocationObject))
            }
        }
        if (filter.filtersState[activeFilterValue]?.companyPostcode?.value &&
            filter.filtersState[activeFilterValue]?.companyPostcode?.value.length > 0) {
            let location = filter.filtersState[activeFilterValue]?.companyPostcode.value
            let postcodeArr = []
            location?.map(item => {
                postcodeArr.push({ label: item, value: item })
            })
            if (searchLocationObject && searchLocationObject?.[activeFilterValue] != undefined) {
                Object.assign(searchLocationObject?.[activeFilterValue], { postcode: postcodeArr });
                setSearchLocationObject(_.cloneDeep(searchLocationObject))
            }
        }
        if (searchData?.isToggleClicked === false) {
            filter.filtersState[activeFilterValue]?.companyTown?.value.length > 0
                ? setActiveTab('Town search') : filter.filtersState[activeFilterValue]?.companyPostcode?.value.length > 0
                    ? setActiveTab('Postcode search') : setActiveTab('Radial search')
        }
        if (!filter.filtersState[activeFilterValue]?.companyLocation?.active && !filter.filtersState[activeFilterValue]?.companyTown?.active && !filter.filtersState[activeFilterValue]?.companyPostcode.active) {
            setSearchData((prevState) => {
                return {
                    ...prevState,
                    townlength: 0,
                    postcodelength: 0,
                    clearSearch: true,
                    locationSearchData: "",
                    location: "",
                    latitude: "",
                    longitude: "",
                    distance: "",
                    locationCloseButton: false,
                    inputTownValue: "",
                    inputPostCodeValue: ""
                }
            })
            setSearchLocationObject(activeFilterValue === 'project' ? { project: {} } : activeFilterValue === 'company' ? { company: {} } : { people: {} })
        }
    }, [filter.filtersState[activeFilterValue]?.companyLocation, filter.filtersState[activeFilterValue]?.companyTown, filter.filtersState[activeFilterValue]?.companyPostcode]);

    const handleKeyDownLocation = (event) => {
        if (activeTab === 'Town search') {
            if (!searchData.inputTownValue) return;
            switch (event.key) {
                case 'Enter':
                    event.preventDefault()
                case 'Tab':
                case ",":
                    let townArr = searchLocationObject?.[activeFilterValue]?.town != undefined ? searchLocationObject[activeFilterValue].town : []
                    townArr.push({ label: searchData.inputTownValue, value: searchData.inputTownValue })
                    Object.assign(searchLocationObject?.[activeFilterValue], { town: townArr });
                    setSearchData((prevState) => {
                        return {
                            ...prevState,
                            inputTownValue: '',
                        }
                    })
                    setSearchLocationObject(_.cloneDeep(searchLocationObject))
                    dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
                    let companyLocationArray = []
                    for (var i = 0; i < townArr?.length; i++) {
                        if (townArr[i].value !== undefined || townArr[i].value !== null) {
                            companyLocationArray.push(townArr[i].value)
                        }
                    }
                    let payload = {
                        value: companyLocationArray,
                        dataObj: event.target.value,
                        active: companyLocationArray && companyLocationArray.length > 0 ? true : false,
                        keyname: "companyTown"
                    }
                    dispatch(updateFilterText(payload))
            }
        }
        else {
            if (!searchData.inputPostCodeValue) return;
            switch (event.key) {
                case 'Enter':
                    event.preventDefault()
                case 'Tab':
                case ",":
                    let postcodeArr = searchLocationObject?.[activeFilterValue]?.postcode != undefined ? searchLocationObject[activeFilterValue].postcode : []
                    postcodeArr.push({ label: searchData.inputPostCodeValue, value: searchData.inputPostCodeValue })
                    Object.assign(searchLocationObject?.[activeFilterValue], { postcode: postcodeArr });
                    setSearchData((prevState) => {
                        return {
                            ...prevState,
                            inputPostCodeValue: '',
                        }
                    })
                    setSearchLocationObject(_.cloneDeep(searchLocationObject))
                    dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
                    let companyLocationArray = []
                    for (var i = 0; i < postcodeArr?.length; i++) {
                        if (postcodeArr[i].value !== undefined || postcodeArr[i].value !== null) {
                            companyLocationArray.push(postcodeArr[i].value)
                        }
                    }
                    let payload = {
                        value: companyLocationArray,
                        dataObj: event.target.value,
                        active: companyLocationArray && companyLocationArray.length > 0 ? true : false,
                        keyname: "companyPostcode"
                    }
                    dispatch(updateFilterText(payload))
            }
        }

    };

    const handleCompanyTownSearchChange = (value) => {
        let townArr = []
        value && value.map(item => {
            townArr.push({ label: item.label, value: item.value })
        })
        Object.assign(searchLocationObject?.[activeFilterValue], { town: townArr });
        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        let projectLocationArray = []
        for (var i = 0; i < townArr?.length; i++) {
            if (townArr[i].value != undefined || townArr[i].value != null) {
                projectLocationArray.push(townArr[i].value)
            }
        }
        let payload = {
            value: projectLocationArray,
            dataObj: searchData.inputTownValue,
            active: projectLocationArray && projectLocationArray.length > 0 ? true : false,
            keyname: "companyTown"
        }
        dispatch(updateFilterText(payload))
    }

    const handleCompanyPostcodeSearchChange = (value) => {
        let postcodArr = []
        value?.map(item => {
            postcodArr.push({ label: item.label, value: item.value })
        })
        Object.assign(searchLocationObject?.[activeFilterValue], { postcode: postcodArr });
        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));
        let projectLocationArray = []
        for (var i = 0; i < postcodArr?.length; i++) {
            if (postcodArr[i].value != undefined || postcodArr[i].value != null) {
                projectLocationArray.push(postcodArr[i].value)
            }
        }
        let payload = {
            value: projectLocationArray,
            dataObj: searchData.inputPostCodeValue,
            active: projectLocationArray && projectLocationArray.length > 0 ? true : false,
            keyname: "companyPostcode"
        }
        dispatch(updateFilterText(payload))
    }
    const createPayload = (filterType) => {
        let query = {};
        let query1 = {};
        let result = {};
        let town = [], postcode = [];
        if (searchLocationObject?.searchLocationObject?.[filterType]?.location !== "" && activeTab === 'Radial search' && !searchData?.clearSearch) {
            query = { "company_latitude": { "operator": "=", "value": searchLocationObject[filterType].latitude }, "company_longitude": { "operator": "=", "value": searchLocationObject[filterType].longitude }, "company_distance": { "operator": "=", "value": searchLocationObject[activeFilterValue].distance } }
        }
        else if (activeTab === 'Town search' && !searchData.clearSearch) {
            if (searchLocationObject?.[filterType]?.town?.length > 0) {
                searchLocationObject[filterType].town.map((elem) => {
                    town.push(elem.value)
                })

                query = createCommonQuery({
                    key: "company_town",
                    operator: "=",
                    value: town
                })
            }
        }
        else if (activeTab === 'Postcode search' && !searchData.clearSearch) {
            if (searchLocationObject?.[filterType]?.postcode?.length > 0) {
                searchLocationObject[filterType].postcode.map((elem) => {
                    postcode.push(elem.value)
                })
                query = createCommonQuery({
                    key: "company_postcode",
                    operator: "=",
                    value: postcode
                })

            }
        }
        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    const handleRadial = () => {
        if (searchData.radialView) {
            setSearchData(prevState => ({
                ...prevState,
                radialView: false
            }))
        } else {
            setSearchData(prevState => ({
                ...prevState,
                radialView: true
            }))
        }
    }
    const setValue = (value) => {
        setSearchData(prevState => ({
            ...prevState,
            distance: value
        }))
    }
    const onSliderChange = (value) => {
        setSearchData(prevState => ({
            ...prevState,
            distance: value
        }))
        searchLocationObject[activeFilterValue].distance = value
        setSearchLocationObject(_.cloneDeep(searchLocationObject))
        dispatch(updateLocationSearchObject(_.cloneDeep(searchLocationObject)));

        let tempFilter = cloneDeep(filter.filtersState[searchPersist.appliedContext])
        tempFilter.companyLocation['radius'] = Math.round(value)

        var locationData = { location: tempFilter?.companyLocation ? tempFilter?.companyLocation?.location : '', latitude: tempFilter?.companyLocation.latitude, longitude: tempFilter?.companyLocation.longitude, radius: Math.round(value), active: true, keyname: "companyLocation" }
        dispatch(updateAdvanceLocation(locationData))
    }

    useEffect(() => {
        if (props?.isClearAll) {
            setSearchLocationObject({ project: {}, people: {}, company: {} })
            setSearchData(prev => ({
                ...prev,
                townlength: 0,
                postcodelength: 0,
                clearSearch: false,
                locationSearchData: "",
                location: "",
                latitude: "",
                longitude: "",
                distance: "",
                clearSearch: true,
                locationCloseButton: false,
                inputTownValue: "",
                inputPostCodeValue: ""
            }))
            setActiveTab("Radial search")
        }
    }, [props?.isClearAll])

    const radialSearch = () => {
        return (
            <>
                <Row className="align-items-center mt-1">
                    <Col xs={12} sm={8} md={8} lg={11}>Enter area to search with a specified radius</Col>
                </Row>
                <Row>
                    <div className="sliderBlock">
                        <div className="advanced-filter-field-container">
                            <div className="radial-search location-field textfield-wrapper">
                                <div className="radial-search-input-container" >
                                    <AutoComplete
                                        inputClass='form-control'
                                        onSuggestionSelect={suggestionSelect}
                                        country='gb,ie,im,je,gg'
                                        limit='10'
                                        placeholder="Location"
                                        clearSearchQuery={searchData.clearSearch}
                                        isRadialSearchActive={searchData.isRadialSearchActive}
                                        isSetCurrentLocation={searchData.isSetCurrentLocation}
                                        context="company"
                                        searchLocationObject={searchLocationObject}
                                    />
                                    <Button className={`clear-textfield clear-location-field-basic-search ${searchData.locationCloseButton ? 'display-block' : 'display-none'}`} onClick={handleLocationClose} tabIndex="-1">x</Button>
                                    <Button className="radial-textfield clear-location-field-basic-search" disabled={!searchData.locationCloseButton}
                                        onClick={handleRadial} tabIndex="-1"></Button>
                                    <Button onClick={enableCurrentLocation} data-tooltip-id="get-location" data-tooltip-hidden={!settings.preferences.hint_text} className="get-location-icon" tabIndex="-1"></Button>
                                    <ReactTooltip id="get-location" place="bottom" type='dark' effect="solid">
                                        <p className="tool-tip-sidebar">Get current location</p>
                                    </ReactTooltip>
                                    <div className={`radius-src-filter ${searchData.radialView ? '' : 'hide'}`}>
                                        <div className="selected-radius">
                                            <span>Search radius </span>
                                            <span>{searchData.distance}<label className="unit">{searchData.distance === 1 ? 'mile' : 'miles'}</label></span>
                                        </div>
                                        <div className="rc-slider-wrapper">
                                            <Slider
                                                allowCross={true}
                                                onAfterChange={onSliderChange}
                                                onChange={setValue}
                                                value={searchData?.distance ? searchData.distance : 25}
                                                min={1}
                                                max={50} />
                                            <span className="range-start-label">1</span>
                                            <span className="range-end-label">50</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </>
        );
    }

    const townSearch = () => {
        return (
            <>
                <Row className="align-items-center mt-1">
                    <Col xs={12} sm={8} md={8} lg={11}>Enter the town for multiple locations separate by a comma</Col>
                </Row>
                <Row>
                    <div className="sliderBlock">
                        <div className="advanced-filter-field-container">
                            <CreatableSelect
                                components={{ DropdownIndicator }}
                                inputValue={searchData.inputTownValue}
                                menuIsOpen={false}
                                isClearable
                                isMulti
                                value={searchLocationObject[activeFilterValue]?.town}
                                placeholder="Search multiple towns"
                                autoBlur={true}
                                onChange={(newValue) => handleCompanyTownSearchChange(newValue)}
                                onInputChange={(newValue) => setSearchData(prev => ({ ...prev, inputTownValue: newValue }))}
                                onKeyDown={handleKeyDownLocation}
                            />
                        </div>
                    </div>
                </Row>
            </>
        );
    }

    const postCodeSearch = () => {
        return (
            <>
                <Row className="align-items-center mt-1">
                    <Col xs={12} sm={8} md={8} lg={11}>Enter the postcode for multiple locations separate by a comma</Col>
                </Row>
                <Row>
                    <div className="sliderBlock">
                        <div className="advanced-filter-field-container">
                            <CreatableSelect
                                components={{ DropdownIndicator }}
                                inputValue={searchData.inputPostCodeValue}
                                menuIsOpen={false}
                                isClearable
                                isMulti
                                value={searchLocationObject?.[activeFilterValue]?.postcode}
                                placeholder="Search partial and full postcodes "
                                autoBlur={true}
                                onChange={(newValue) => handleCompanyPostcodeSearchChange(newValue)}
                                onInputChange={(newValue) => setSearchData(prev => ({ ...prev, inputPostCodeValue: newValue }))}
                                onKeyDown={handleKeyDownLocation}
                            />
                        </div>
                    </div>
                </Row>
            </>
        );
    }

    useEffect(() => {
        if (activeTab === 'Radial search') {
            if (searchData.townlength > 0) {
                setSearchData((prevState) => {
                    return {
                        ...prevState,
                        townlength: 0,
                        postcodelength: 0
                    }
                })
            }
        }
        if (activeTab === 'Town search') {
            let townlength = searchLocationObject?.[activeFilterValue]?.town ? (searchLocationObject[activeFilterValue].town).length : 0
            setSearchData((prevState) => {
                return {
                    ...prevState,
                    townlength: townlength,
                    postcodelength: 0,
                    clearSearch: false,
                    locationSearchData: "",
                    latitude: "",
                    longitude: "",
                    distance: ""
                }
            })
        }
        if (activeTab === 'Postcode search') {
            let postcodelength = searchLocationObject?.[activeFilterValue]?.postcode ? (searchLocationObject[activeFilterValue].postcode).length : 0
            setSearchData((prevState) => {
                return {
                    ...prevState,
                    postcodelength: postcodelength,
                    townlength: 0,
                    clearSearch: false,
                    locationSearchData: "",
                    latitude: "",
                    longitude: "",
                    distance: ""
                }
            })
        }
    }, [searchLocationObject?.[activeFilterValue]?.town, searchLocationObject?.[activeFilterValue]?.postcode, activeTab])

    return (
        <div className="advanced-filter-grid company-location-frames" id="new_filter_cl">
            <div className="filterHeading">
                <h5 className="mb-3">Company location</h5>
            </div>
            <div className={`filterBlock ${(search?.locationSearchObject?.company?.location) || (searchData.townlength > 0) || (searchData.postcodelength > 0) ? "activeBlock" : ""}`}>
                <div className="textcontent">

                    <Col className="p-0">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={activeTab === 'Radial search' ? 'active' : ''} onClick={() => toggle('Radial search')}>
                                    Radial search
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 'Town search' ? 'active' : ''} onClick={() => toggle('Town search')}>
                                    Town search
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 'Postcode search' ? 'active' : ''} onClick={() => toggle('Postcode search')}>
                                    Postcode search
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='Radial search' className="ps-0">{radialSearch()}</TabPane>
                            <TabPane tabId='Town search' className="ps-0">{townSearch()}</TabPane>
                            <TabPane tabId='Postcode search' className="ps-0">{postCodeSearch()}</TabPane>
                        </TabContent>
                    </Col>
                </div>
            </div>
        </div>
    )
}
export default CompanyLocation;