import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { checkIfProjectIsNew, displayCurrency, isValidText, getOnsiteBadge, getFormatedDate, getFormatedStartOrEndDate } from '../../../utils'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../common/js/icon';
import { favourite } from '../apiclient';
import TagShareNoteCount from './tagShareNoteCount';
import { fetchSortValue } from '../../../utils/commonFunctions';
import { convertMilesToYards } from '../../search/utils';

const ProjectSearchList = (props) => {
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings)
    const search = useSelector((state) => state.search)
    const userData = useSelector((state) => state.user)
    const [allUpdatesList, setAllUpdatesList] = useState([])
    const [showAllUpdates, setShowAllUpdates] = useState(false)
    const [currentObjectId, setCurrentObjectId] = useState()
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()

    const showAllUpdatesFunction = (id, type) => {
        setAllUpdatesList(!showAllUpdates)
        setCurrentObjectId(id)
        if (!showAllUpdates) {
        }
    }

    const handleFavourite = (event) => {
        event.preventDefault()
        event.stopPropagation()
        let payload = {
            favourite: props.entity.favourite ? false : true,
            objectId: props.entity.project_id,
            objectType: "project",
            objectName: props.entity.project_title,
            prevNextList: props.prevNextList,
            columnOneRole: props.columnOneRole,
            columnTwoRole: props.columnTwoRole,
            companyId: location.pathname.split('/')[4],
            objectTypeByLocation: location.pathname.split('/')[2],
            view_company_id: props.entity.company_id
        }
        favourite(payload, dispatch, project, userData, '', settings, props.roleId, fetchSortValue(props?.sort?.toLowerCase(), 'project'), search)
    }

    const handleDetails = (event, projectId) => {
        event.preventDefault();
        let routingState = {
            prevNextList: props.prevNextList,
            showMoreResult: true,
            index: props.prevNextList === 'groupedCompanies' ? 0 : props.index,
            lastRelease: getFormatedDate(props.entity?.project_last_published, 'DD-MMM-YYYY'),
            dist: convertMilesToYards(props.entity?.project_distance),
            previousState: location.state,
            project_id: location.pathname.split('/')[3] ? location.pathname.split('/')[3] : '',
            company_id: location.pathname.split('/')[4] ? location.pathname.split('/')[4] : '',
            person_id: location.pathname.split('/')[3] ? location.pathname.split('/')[3] : '',
            list: props.prevNextList === 'companyProjects' ? project.projectDataForRoleId
                : props.prevNextList === 'relatedCompaniesJointProjects' ? project?.jointProjects?.projects
                    : props.prevNextList === 'groupedCompanies' ? [props.entity]
                        : props.prevNextList === 'groupedPeopleProjects' ? project?.projectDataForPeopleRoleId
                            : [],
            tab: props.prevNextList === 'companyProjects' ? '2'
                : props.prevNextList === 'relatedCompaniesJointProjects' ? '3'
                    : props.prevNextList === 'groupedCompanies' ? '6'
                        : props.prevNextList === 'groupedPeopleProjects' ? '1' : null
        }
        navigate(`/app/project/${projectId}`, { state: routingState })
    }

    const getRoles = (rolesValue) => {
        var data = []
        var roles = []
        if (rolesValue != undefined) {
            data = rolesValue.split(',')
            let rolesToDisplay = data.slice(0, 3)
            for (let i = 0; i < rolesToDisplay.length; i++) {
                roles.push(<span key={`roles${i}`}>{data[i]}</span>)
            }
            if (data.length - 3 > 0) {
                roles.push((<span className="roles-count"><span className="plusIcon">+</span>{data.length - 3 + " more"}</span>))
            }
            return roles
        }
    }
    return (
        <div id={props.entity.objectId} className={`project-container ps-0 ${props.entity.read ? '' : 'read-block'}`}>
            <div className="linkWrapper">
                <Link className="result-block pt-0" aria-expanded="false" onClick={(e) => handleDetails(e, props.entity.project_id)}
                    to={location.pathname.split('/')[3] !== `${props.entity.project_id}` || project?.isProjectFrameWork === false ? `/app/project/${props.entity.project_id}` : 'javascript:;'} >
                    <div className="listviewtable-block">
                        <div className="list-table">
                            <div className="list-tablerow">
                                <div className="list-col list-name">
                                    <div className="listNameIcon p-2">
                                        <div className="result-row-icon-placeholder">
                                            <span className="mb-0 icon-project"><Icon size={35} icon='icon-gen-project-list' /></span>
                                            <div className="result-row-button-block ml-2">
                                                <div className="right-block">
                                                    <div className="">
                                                        {/* <button onClick={handleFavourite} className='user-ctrl-btn label-favourite-icon'>
                                                            {project && project.detailsPage && project.detailsPage.favourite ?
                                                                <Icon size={20} icon='icon-gen-star-active' /> :
                                                                <Icon size={20} icon='icon-gen-star-inactive' />
                                                            }
                                                        </button> */}
                                                        <button onClick={handleFavourite} className='user-ctrl-btn label-favourite-icon'>
                                                            {
                                                                (<>
                                                                    {
                                                                        props.entity && props.entity.favourite ?
                                                                            <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                                                            <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                                                    }
                                                                </>
                                                                )
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-row-overview">
                                            <h5 className="result-title-name mb-0 text-wrap">
                                                <strong className="me-2">{props?.entity?.project_title}</strong>
                                                {
                                                    props?.entity?.archive ?
                                                        <div className="archive-badge-flag mt-3 ">
                                                            Archived
                                                        </div> : ('')
                                                }
                                            </h5>
                                            <div className="sub-resultdetails-content">
                                                <div className="result-client-name-value">
                                                    {isValidText(props?.entity?.role_11_company_names && props?.entity?.role_11_company_names.includes('undefined') ? props.entity.role_11_company_names.replace('undefined', '') : props.entity.role_11_company_names ? props.entity.role_11_company_names : '-')
                                                        ? (<div className="rs-client-name">
                                                            {props.entity.role_11_company_names}
                                                        </div>) : ''}
                                                    {isValidText(props.entity.project_value) ?
                                                        (props.entity.project_value && props.entity.project_value == '-') ? ('') : (<span
                                                            className="rs-value ">{displayCurrency(props.entity.project_value, props.entity.project_value_estimated)}</span>) : ('')
                                                    }
                                                    <span className="rs-stages">
                                                        {
                                                            props.prevNextList === 'companyProjects' || props.prevNextList === 'groupedPeopleProjects' || props?.prevNextList === "relatedCompaniesJointProjects" ?
                                                                <>
                                                                    {isValidText(props?.entity?.project_planning_stage_display) && isValidText(props?.entity?.project_contract_stage_display) ?
                                                                        (<span>{props?.entity?.project_planning_stage_display == "-" ? null : props?.entity?.project_planning_stage_display + ", "}</span>) :
                                                                        (<span>{props?.entity?.project_planning_stage_display == "-" ? null : props?.entity?.project_planning_stage_display}</span>)}
                                                                    {isValidText(props?.entity?.project_contract_stage_display) ? (<span>{props?.entity?.project_contract_stage_display}</span>) : ('')}
                                                                </>
                                                                :
                                                                <span className={!(isValidText(project && project.detailsPage && project.detailsPage.project_planning_stage_display) && isValidText(props.entity.project_contract_stage_display)) ? 'w-100 d-inline-block' : ''}>
                                                                    {project && project.detailsPage && project.detailsPage.project_planning_stage_display == "-" ?
                                                                        null :
                                                                        project && project.detailsPage && project.detailsPage.project_planning_stage_display}
                                                                    {isValidText(props.entity.project_contract_stage_display) ?
                                                                        !project && project.detailsPage && project.detailsPage.project_planning_stage_display
                                                                            ? props.entity.project_contract_stage_display :
                                                                            (',' + props.entity.project_contract_stage_display) :
                                                                        project && project.detailsPage && project.detailsPage.project_planning_stage_display ? '' : '-'}
                                                                </span>
                                                        }

                                                    </span>
                                                </div>

                                            </div>

                                            {isValidText(props.entity.project_site3) ?
                                                <span className="src-location-placeholder">
                                                    {props.entity.project_site3}
                                                </span> : ("")
                                            }

                                            <span className={`src-date ${props.sort === 'End Date' || props.sort === 'Start Date' ? 'ps-0' : ''}`}>
                                                <span className={props.prevNextList === 'companyProjects' && props.sort === 'Start Date' ? 'rs-start-date' : props.prevNextList === 'companyProjects' && props.sort === 'End Date' ? 'rs-end-date' : 'src-latest'}>
                                                    {props.prevNextList === 'companyProjects' && props.sort === 'Start Date' ?
                                                        getFormatedStartOrEndDate(props.entity.project_start_display) :
                                                        props.prevNextList === 'companyProjects' && props.sort === 'End Date' ?
                                                            getFormatedStartOrEndDate(props.entity.project_finish_display) :
                                                            getFormatedDate(props.entity.project_last_published, 'DD-MMM-YYYY')
                                                    }
                                                </span>
                                            </span>

                                            {
                                                props?.entity?.project_stopped_display ?
                                                    <div className="project-build-phase-pro-flag on-hold-flag ms-2"> {props.entity.project_stopped_display}</div>
                                                    :
                                                    'project_site_progression' in props.entity &&
                                                        props.entity.project_build_phase &&
                                                        (props.entity.project_build_phase == "active_on_site" ||
                                                            props.entity.project_build_phase == "on_site") ?
                                                        <div className="project-build-phase-pro-flag ms-2">
                                                            {getOnsiteBadge(props.entity.project_site_progression, props.entity.project_build_phase)}
                                                        </div> : ('')
                                            }

                                            {
                                                checkIfProjectIsNew(props.entity.project_last_published, props.entity.project_first_published) ?
                                                    <div className="new-pro-flag d-inline-block ms-1">
                                                        New
                                                    </div> : ('')
                                            }

                                            {isValidText(props.entity && props.entity.personFullName)
                                                ? (<div className="list-col list-client w-15">
                                                    {isValidText(props.entity && props.entity.personFullName)
                                                        ? (<span className="rs-personFullName">
                                                            {getRoles(props.entity && props.entity.personFullName)}
                                                        </span>) : (<span className="w-100 d-inline-block">-</span>)}
                                                </div>) : ('')
                                            }
                                            {
                                                props.entity && props.entity.tags_count != 0 ||
                                                    props.entity && props.entity.shares_count != 0 ||
                                                    props.entity && props.entity.mail ||
                                                    props.entity && props.entity.notes_count != 0 ?
                                                    (<TagShareNoteCount entity={props.entity} />) : ("")
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default ProjectSearchList
