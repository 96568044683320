import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import {
    Card,
    Button,
    ButtonDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setIncludeCheckboxLength, setExcludeCheckboxLength } from '../../redux/reducer';
import CheckboxTree from 'react-checkbox-tree';

const Exclude = (props) => {

    const [checked, setChecked] = useState(props.checked);

    const {
        search_container,
    } = useSelector((state) => {
            return {
                search_container: state.search
            }
        });

    const dispatch = useDispatch();

    // const onCheck = (newChecked) => {
    //     setChecked(newChecked);
    //     dispatch(setExcludeCheckboxLength(newChecked.length))
    //     dispatch(setIncludeCheckboxLength('0'))
    // };
    useEffect(() => {
        setChecked(props.checked)
     }, [props.checked])

    return (
        <CheckboxTree
            checked={checked}
            expanded={(props.expandValues.length > 0 && props.includExpand.length === 0) ? props.expandValues : (props.expandValues.length === 0 && props.includExpand.length > 0) ? props.includExpand : []}
            nodes={props?.filteredNodes}
            onCheck={props.onCheck}
        />
    )
}

export default Exclude;