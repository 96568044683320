import { checkSearchByIdFilter, fetchHandleSubmitSort } from "../../../utils/commonFunctions"
import { createCompanySelectedQuery, createProjectSelectedQuery } from "../utils"

export function preProcessorQuery(tempCriteria, tempFilter, searchType, search, searchPersist) {
    var formValues = {}
    var value = null
    var splitValue = null
    var value1 = null
    var value2 = null
    var value3 = null
    var isRoleAdded = null

    if (tempCriteria) {
        Object.keys(tempCriteria).forEach(function (key) {
            if (tempCriteria[key]) {
                formValues[key] = tempCriteria[key]
            }
        })

        formValues['offset'] = tempCriteria['offset']
        formValues['records'] = tempCriteria['records'] ? tempCriteria['records'] : 50;
    }
    if (search?.showSelectedCompanies || search?.showSelectedProjects) {
        formValues['query'] = {}
        formValues['display'] = searchType
        if (search?.showSelectedCompanies) {
            let selected_ids = createCompanySelectedQuery(search)
            formValues['query'] = selected_ids['query1']
        }
        else if (search?.showSelectedProjects) {
            let selected_ids = createProjectSelectedQuery(search, searchPersist)
            formValues['query'] = selected_ids['query1']
        }
        if (search.removeFilterToggle) {
            value = getBuildPhaseValue(tempFilter[searchType])
            if (value && value.length > 0 && !checkSearchByIdFilter(tempFilter[searchType])) {
                formValues['query']['project_build_phase'] = { operator: "=", value: value }
            } else {
                delete formValues['query']['project_build_phase']
            }
            let excludeStagesValue = updateExcludeStagesCriteria(tempFilter[searchType])
            let includeStagesValue = updateIncludeStagesCriteria(tempFilter[searchType])
            if (!checkSearchByIdFilter(tempFilter[searchType]) && (includeStagesValue || excludeStagesValue)) {
                let query = []
                var index = includeStagesValue ? includeStagesValue.indexOf(30) : -1
                if (index != -1)
                    includeStagesValue.splice(index)
                if (includeStagesValue) {
                    query.push({ operator: "=", value: includeStagesValue })
                }
                if (excludeStagesValue) {
                    query.push({ operator: "!=", value: excludeStagesValue })
                }
                formValues["query"]["project_stages"] = query
            }
        }
        formValues['sort'] = tempFilter?.[searchType]?.sort ? tempFilter[searchType].sort : '-project_last_published'
        formValues['offset'] = tempFilter?.[searchType]?.['offset'] ? tempFilter[searchType]['offset'] : 0
        formValues['records'] = tempFilter?.[searchType]?.['records'] ? tempFilter[searchType]['records'] : 50
    } else if (tempFilter) {
        formValues['display'] = tempFilter[searchType]?.display
        if (tempFilter?.event_type !== null) {
            formValues['event_type'] = tempFilter?.event_type
        }
        formValues['query'] = {}
        value = updateValueCriteria(tempFilter[searchType])
        if (value) {
            if (value.from == "*" || parseInt(value.from) == 0 || !value.from) {
                formValues['query']['project_value'] = {
                    operator: "<=",
                    value: parseInt(value.to)
                }
            } else if (value.to == "*" || parseInt(value.to) == 0 || !value.to) {
                formValues['query']['project_value'] = {
                    operator: ">=",
                    value: parseInt(value.from)
                }
            } else if (value.from != "*" && value.to != "*") {
                formValues['query']['project_value'] = {
                    operator: "..",
                    value1: parseInt(value.from),
                    value2: parseInt(value.to)
                }
            }
        } else {
            delete formValues['query']['project_value']
        }
        value = updateRegionAndCountiesCriteria(tempFilter[searchType])
        if (value.regions.length > 0) {
            formValues['query']['project_geocode'] = { operator: "=", value: value.regions }
        } else {
            delete formValues['query']['project_geocode']
        }

        if (value.companyRegions.length > 0) {
            formValues['query']['company_geocode'] = { operator: "=", value: value.companyRegions }
        } else {
            delete formValues['query']['company_geocode']
        }

        value = updateTown(tempFilter[searchType])
        if (value && value.project && value.project.length > 0) {
            formValues['query']['project_town'] = { operator: "=", value: value.project }
        } else {
            delete formValues['query']['project_town']
        }
        if (value && value.company && value.company.length > 0) {
            formValues['query']['company_town'] = { operator: "=", value: value.company }
        } else {
            delete formValues['query']['company_town']
        }

        value = updatePostCode(tempFilter[searchType])
        if (value && value.project && value.project.length > 0) {
            formValues['query']['project_postcode'] = { operator: "=", value: value.project }
        } else {
            delete formValues['query']['project_postcode']
        }
        if (value && value.company && value.company.length > 0) {
            formValues['query']['company_postcode'] = { operator: "=", value: value.company }
        } else {
            delete formValues['query']['company_postcode']
        }

        let keys = {
            'projectTitle': "project_title",
            'projectId': 'project_id',
            'projectScheme':'project_scheme',
            'companyInformation': 'company_text',
            'personInformation': 'person_text',
            'companyName': "company_name",
            'companyId': 'company_id',
            'personName': 'person_full_name',
            'personJobTitle': 'person_job_title'
            
        }
        value = updateScheme(tempFilter[searchType])
        if (value) {
            formValues['query']['project_scheme'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_scheme']
        }
        addToObjectIfKeysAvailable(tempFilter[searchType], formValues, keys)
        value = updateLocation(tempFilter[searchType])
        if (value && value.project) {
            //formValues['location'] = value.project.location
            formValues['query']['project_latitude'] = { operator: "=", value: value.project.latitude }
            formValues['query']['project_longitude'] = { operator: "=", value: value.project.longitude }
            formValues['query']['project_distance'] = { operator: "=", value: value.project.radius }
        } else {
            //delete formValues['location']
            delete formValues['query']['project_latitude']
            delete formValues['query']['project_longitude']
            delete formValues['query']['project_distance']
        }

        if (value && value.company) {
            //formValues['companyLocation'] = value.company.location
            formValues['query']['company_latitude'] = { operator: "=", value: value.company.latitude }
            formValues['query']['company_longitude'] = { operator: "=", value: value.company.longitude }
            formValues['query']['company_distance'] = { operator: "=", value: value.company.radius }
        } else {
            //delete formValues['companyLocation']
            delete formValues['query']['company_latitude']
            delete formValues['query']['company_longitude']
            delete formValues['query']['company_distance']
        }

        value = updateMaterialValue(tempFilter[searchType])
        if (value.length > 0) {
            formValues['query']['project_materials'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_materials']
        }

        value = updateProjectFramework(tempFilter[searchType])
        if (value.length > 0) {
            formValues['query']['project_framework'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_framework']
        }

        value = updateProjectUsage(tempFilter[searchType])
        if (value.length > 0) {
            formValues['query']['project_usage'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_usage']
        }

        value = updateProjectLandType(tempFilter[searchType])
        if (value.length > 0) {
            formValues['query']['project_land_type'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_land_type']
        }

        value = getBuildPhaseValue(tempFilter[searchType])
        // let sectorDetails = getSectorNo(window.location)
        if (value && value.length > 0 && !checkSearchByIdFilter(tempFilter[searchType])) {
            formValues['query']['project_build_phase'] = { operator: "=", value: value }
        } else {
            delete formValues['query']['project_build_phase']
        }

        value1 = updateIncludeDevTypes(tempFilter[searchType], "includeDevelopmentTypes")
        value2 = updateIncludeDevTypes(tempFilter[searchType], "excludeDevelopmentTypes")
        value3 = updateDevTypesCriteria(tempFilter[searchType], "developmentTypes")
        if (value1.length > 0 || value2.length > 0 || value3.length > 0) {
            let query = []
            if (value1.length > 0 || value3.length > 0) {
                query.push({
                    "operator": "=",
                    value: value1.length > 0 ? value1 : value3

                })
            }
            if (value2.length > 0) {
                query.push({
                    operator: "!=",
                    value: value2
                })
            }
            formValues['query']['project_development_types'] = query
        } else {
            delete formValues['query']['project_development_types']
        }

        value1 = updateIncludeRoles(tempFilter[searchType], "includeRoles")
        value2 = updateIncludeRoles(tempFilter[searchType], "excludeRoles")
        isRoleAdded = updateRoleAddedCriteria(tempFilter[searchType])
        if (value1.length > 0 || value2.length > 0) {
            let query = []
            if (value1.length > 0) {
                query.push({
                    operator: "=",
                    value: value1
                })
            }
            if (value2.length > 0) {
                query.push({
                    operator: "!=",
                    value: value2
                })
            }
            if (isRoleAdded) {
                delete formValues['query']['project_roles']
                formValues['query']['role_code'] = query
            }
            else {
                delete formValues['query']['role_code']
                formValues['query']['project_roles'] = query
            }
        } else {
            if (isRoleAdded) {
                delete formValues['query']['role_code']
            }
            else {
                delete formValues['query']['project_roles']
            }
        }

        value1 = updateCategoriesCriteria(tempFilter[searchType])
        value2 = updateAdvanceCategoriesCriteria(tempFilter[searchType], "includeCategories")
        value3 = updateAdvanceCategoriesCriteria(tempFilter[searchType], "excludeCategories")
        if (value1.length > 0 || value2.length > 0 || value3.length > 0) {
            let key = tempFilter[searchType].primaryCategoriesOnly ? "project_primary_category" : "project_categories"
            formValues['query'][key] = value1.length > 0 || value2.length > 0 ? [{ operator: "=", value: value1.length > 0 ? value1 : value2 }] : []
            if (value3.length > 0) {
                formValues['query'][key].push({ operator: "!=", value: value3 })
            }
        } else {
            delete formValues['query']['project_categories']
        }

        value = updateBrands(tempFilter[searchType])
        if (value) {
            formValues['query']['project_brands'] = {
                operator: "=",
                value: value
            }
        } else {
            delete formValues['query']['project_brands']
        }

        value = updateInformation(tempFilter[searchType])
        if (value) {
            formValues['query']['project_text'] = {
                operator: "=",
                value: value
            }
        } else {
            delete formValues['query']['project_text']
        }

        let buildStartsValue = updateBuildStartsWithCriteria(tempFilter[searchType])
        if (buildStartsValue) {
            splitValue = splitDate(buildStartsValue)
            formValues['query']['project_start'] = { operator: "..", value1: parseInt(splitValue[0]), value2: parseInt(splitValue[1]) }
        } else if (formValues['query']['project_start']) {
            delete formValues['query']['project_start']
        }

        value1 = updateStartConstructionTimingCriteria(tempFilter[searchType])
        value2 = updateCalenderStartCriteria(tempFilter[searchType])
        if (value1 || value2) {
            splitValue = splitDate(value1 ? value1 : value2)
            if (value1) {
                formValues['query']['project_start'] = { operator: "..", value1: parseInt(splitValue[0]), value2: parseInt(splitValue[1]) }
            } else {
                if (value2.includes('*')) {
                    if (value2.indexOf("*") == 0) {
                        formValues['query']['project_start'] = {
                            operator: "<=",
                            value: splitValue[1]
                        }
                    } else {
                        formValues['query']['project_start'] = {
                            operator: ">=",
                            value: splitValue[0]
                        }
                    }
                } else {
                    formValues['query']['project_start'] = { operator: "..", value1: splitValue[0], value2: splitValue[1] }
                }
            }
        } else if (!buildStartsValue && 'project_start' in formValues['query']) {
            delete formValues['query']['project_start']
        }

        value1 = updateEndConstructionTimingCriteria(tempFilter[searchType])
        value2 = updateCalenderEndCriteria(tempFilter[searchType])
        if (value1 || value2) {
            splitValue = splitDate(value1 ? value1 : value2)
            if (value1) {
                formValues['query']['project_finish'] = { operator: "..", value1: parseInt(splitValue[0]), value2: parseInt(splitValue[1]) }
            } else {
                if (value2.includes('*')) {
                    if (value2.indexOf("*") == 0) {
                        formValues['query']['project_finish'] = {
                            operator: "<=",
                            value: splitValue[1]
                        }
                    } else {
                        formValues['query']['project_finish'] = {
                            operator: ">=",
                            value: splitValue[0]
                        }
                    }
                } else {
                    formValues['query']['project_finish'] = { operator: "..", value1: splitValue[0], value2: splitValue[1] }
                }
            }
        } else {
            delete formValues['query']['project_finish']
        }

        let excludeStagesValue = updateExcludeStagesCriteria(tempFilter[searchType])
        let includeStagesValue = updateIncludeStagesCriteria(tempFilter[searchType])
        if (!checkSearchByIdFilter(tempFilter[searchType]) && (includeStagesValue || excludeStagesValue)) {
            let query = []
            var index = includeStagesValue ? includeStagesValue.indexOf(30) : -1
            if (index != -1)
                includeStagesValue.splice(index)
            if (includeStagesValue) {
                query.push({ operator: "=", value: includeStagesValue })
            }
            if (excludeStagesValue) {
                query.push({ operator: "!=", value: excludeStagesValue })
            }
            formValues["query"]["project_stages"] = query
        }
        value = updateMeasureParking(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_parking'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_parking'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_parking'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_parking']
        }

        value = updateMeasureTotal(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_plan_area'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_plan_area'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_plan_area'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_plan_area']
        }

        value = updateMeasureGroundFloor(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_floor_area'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_floor_area'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_floor_area'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_floor_area']
        }

        value = updateMeasureSite(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_site_area'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_site_area'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_site_area'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_site_area']
        }

        value = updateMeasureAboveGround(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_storeys_above'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_storeys_above'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_storeys_above'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_storeys_above']
        }

        value = updateMeasureBelowGround(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_storeys_below'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_storeys_below'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_storeys_below'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_storeys_below']
        }

        value = updateMeasureUnits(tempFilter[searchType])
        if (value) {
            value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
            value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
            if (value2 !== '*' && value1 !== '*') {
                formValues['query']['project_units'] = { operator: '..', value1: value1, value2: value2 }
            } else if (value1 === '*') {
                formValues['query']['project_units'] = { operator: '<=', value: value2 }
            } else {
                formValues['query']['project_units'] = { operator: '>=', value: value1 }
            }
        } else {
            delete formValues['query']['project_units']
        }

        value = updateRoleAddedCriteria(tempFilter[searchType])
        if (value) {
            formValues['query']['role_updated'] = { operator: "..", value1: -value, value2: 0 }
        } else {
            delete formValues['query']['role_updated']
        }

        // value1 = Math.abs(updateLastUpdatedCriteria(tempFilter))
        // value2 = Math.abs(updateFistPublishCriteria(tempFilter))
        value1 = updateLastUpdatedCriteria(tempFilter[searchType])
        value2 = updateFistPublishCriteria(tempFilter[searchType])
        if (value1 || value2) {
            formValues['query']['project_last_published'] = { operator: "..", value1: value1 ? -value1 : -value2, value2: 0 }
            if ((value1 && !value2) || (!value1 && value2)) {
                formValues['query']['project_is_new'] = { operator: "=", value: (!value1 && value2 ? true : false) }
            }
        } else {
            delete formValues['query']['project_last_published']
        }

        if (tempFilter && tempFilter[searchType] && tempFilter[searchType].projectInformation &&
            tempFilter[searchType].projectInformation.active &&
            tempFilter[searchType].projectInformation.value &&
            tempFilter[searchType].projectInformation.value.length > 1) {
            formValues['project_search'] = tempFilter[searchType].projectInformation.isAnyAll
        } else if (formValues['project_search']) {
            delete formValues['project_search']
        }

        if (tempFilter && tempFilter[searchType] && tempFilter[searchType].brands &&
            tempFilter[searchType].brands.active &&
            tempFilter[searchType].brands.value != 0) {
            formValues['brand_search'] = tempFilter[searchType].brands.isFuzzyExact
        } else if (formValues['brand_search']) {
            delete formValues['brand_search']
        }
        if (tempFilter?.[searchType]?.sort !== '') {
            formValues['sort'] = tempFilter?.[searchType]?.sort ? tempFilter[searchType].sort : '-project_last_published'
        }
        formValues['offset'] = tempFilter?.[searchType]?.['offset'] ? tempFilter[searchType]['offset'] : 0
        formValues['records'] = tempFilter?.[searchType]?.['records'] ? tempFilter[searchType]['records'] : 50


    }
    return formValues
}

function splitDate(dateString) {
    return dateString?.split(" TO ")
}

function updateScheme(payload) {
    let value = ""
    if (payload?.projectScheme?.active) {
        value = payload.projectScheme.value
    }
    return value
}

function updateBuildStartsWithCriteria(searchFilter) {
    var value = null
    let buildStartsWith = "0 TO 90"
    //Timing criteria update
    if (searchFilter?.buildStartDate?.active) {
        searchFilter.buildStartDate.options.map(function (item, index) {
            if (item.active) {
                buildStartsWith = item.value
            }
        })
        value = buildStartsWith
    }
    return value
}

function addToObjectIfKeysAvailable(tempFilter, formValues, keys) {
    for (let i in keys) {
        if (tempFilter && tempFilter[i]?.active && tempFilter[i]?.value !== "") {
            formValues['query'][keys[i]] = {
                operator: "=",
                value: ( i == "companyId") && !isNaN(tempFilter[i].value)
                    ? parseInt(tempFilter[i].value) : Array.isArray(tempFilter[i].value)
                        ? encodeURIComponent(tempFilter[i].value[0]) : encodeURIComponent(tempFilter[i].value)
            }
        }
    }
}

function updateValueCriteria(searchFilter) {
    var value = null
    //value criteria update
    if (searchFilter?.value?.active) {
        let valueFrom = 0
        let valueTo = "*"
        if (searchFilter.value.from) {
            valueFrom = searchFilter.value.from
        }
        if (searchFilter.value.to && searchFilter.value.to !== '') {
            valueTo = searchFilter.value.to
        }
        value = valueFrom || valueTo !== "*" ? { from: valueFrom, to: valueTo } : null
    }
    return value
}

function updateIncludeStagesCriteria(searchFilter) {
    var value = null
    //IncludeStages criteria update
    let includeStage = []
    if (searchFilter?.includeStages?.active) {
        searchFilter.includeStages.options.map(function (item, index) {
            item.children.map(stage => {
                if (stage.active) {
                    if (Array.isArray(stage.value)) {
                        includeStage.push(...stage.value)
                    } else {
                        includeStage.push(stage.value)
                    }

                }
            })
        })
    }
    if (includeStage.length > 0) {
        value = includeStage
    }
    return value
}

function updateExcludeStagesCriteria(searchFilter) {
    //ExcludeStages criteria update
    var value = null
    let excludeStage = []
    if (searchFilter?.excludeStages || searchFilter?.advanceExcludeStages?.active) {
        if (searchFilter.excludeStages.options && searchFilter.excludeStages.options[0].active && !searchFilter.excludeStages.toggleActive) {
            excludeStage.push(...searchFilter.excludeStages.options[0].value)
        }
        if (searchFilter.advanceExcludeStages && searchFilter.advanceExcludeStages.active) {
            searchFilter.advanceExcludeStages.options.map(item => {
                item.children.map(stage => {
                    if (stage.active) {
                        if (Array.isArray(stage.value)) {
                            excludeStage.push(...stage.value)
                        } else {
                            excludeStage.push(stage.value)
                        }
                    }
                })
            })
        }
        if (excludeStage.length > 0) {
            value = excludeStage
        }
    }
    return value
}

function updateStartConstructionTimingCriteria(searchFilter) {
    var value = null
    let startConstruction = "0 TO 180"
    //Timing criteria update
    if (searchFilter?.startConstruction?.active) {
        startConstruction = searchFilter.startConstruction.options.filter(item => item.active).map(item => item.value)
        if (startConstruction.length > 1) {
            startConstruction = startConstruction.join(" TO ")
        } else if (startConstruction.length == 1) {
            startConstruction = startConstruction[0] + " TO " + startConstruction[0]
        }
        value = startConstruction
    }
    return value
}

function updateEndConstructionTimingCriteria(searchFilter) {
    var value = null
    let endConstruction = "0 TO 90"
    //Timing criteria update
    if (searchFilter?.endConstruction?.active) {
        endConstruction = searchFilter.endConstruction.options.filter(item => item.active).map(item => item.value)
        if (endConstruction.length > 1) {
            endConstruction = endConstruction.join(" TO ")
        } else if (endConstruction.length == 1) {
            endConstruction = endConstruction[0] + " TO " + endConstruction[0]
        }
        value = endConstruction
    }
    return value
}

export function updateCalenderStartCriteria(searchFilter) {
    let startDateFromandTo = ''
    if (searchFilter?.calendarStartDate?.active && (searchFilter?.calendarStartDate?.from !== "" || searchFilter?.calendarStartDate?.to !== "")) {
        let startDateFrom = searchFilter.calendarStartDate.from !== "" ? searchFilter.calendarStartDate.from.toString() : "*"
        let startDateTo = searchFilter.calendarStartDate.to !== "" ? searchFilter.calendarStartDate.to.toString() : "*"
        startDateFromandTo = startDateFrom + " TO " + startDateTo
    }
    return startDateFromandTo
}

export function updateCalenderEndCriteria(searchFilter) {
    let endDateFromTo = ''
    if (searchFilter?.calendarEndDate?.active && (searchFilter?.calendarEndDate?.from !== "" || searchFilter?.calendarEndDate?.to !== "")) {
        let endDateFrom = searchFilter.calendarEndDate.from !== "" ? searchFilter.calendarEndDate.from.toString() : "*"
        let endDateTo = searchFilter.calendarEndDate.to !== "" ? searchFilter.calendarEndDate.to.toString() : "*"
        endDateFromTo = endDateFrom + " TO " + endDateTo
    }
    return endDateFromTo
}


export function updateRegionAndCountiesCriteria(searchFilter) {
    let parentRegions = {
        regions: [],
        companyRegions: []
    }
    if (searchFilter?.regions?.active) {
        searchFilter.regions.options.map(function (item, index) {
            if (item.active) {
                if (item.value.indexOf(',') > -1) {
                    parentRegions.regions = parentRegions.regions.concat(item.value.split(', '))
                } else {
                    parentRegions.regions.push(item.value)
                }
            }
            item.counties.map(function (subItem, key) {
                if (subItem.active) {
                    if (subItem.value.indexOf(',') > -1) {
                        parentRegions.regions = parentRegions.regions.concat(subItem.value.split(', '))
                    } else {
                        parentRegions.regions.push(subItem.value)
                    }
                }
            })
        })
    }
    if (searchFilter?.companyRegions?.active) {
        searchFilter.companyRegions.options.map(function (item, index) {
            if (item.active) {
                if (item.value.indexOf(',') > -1) {
                    parentRegions.companyRegions = parentRegions.companyRegions.concat(item.value.split(', '))
                } else {
                    parentRegions.companyRegions.push(item.value)
                }
            }
            item.counties.map(function (subItem, key) {
                if (subItem.active) {
                    if (subItem.value.indexOf(',') > -1) {
                        parentRegions.companyRegions = parentRegions.companyRegions.concat(subItem.value.split(', '))
                    } else {
                        parentRegions.companyRegions.push(subItem.value)
                    }
                }
            })
        })
    }
    return parentRegions
}


function updateInformation(searchFilter) {
    let value = ""
    if (searchFilter?.projectInformation?.active && searchFilter?.projectInformation?.value != "") {
        value = searchFilter.projectInformation.value && Array.isArray(searchFilter.projectInformation.value) ? searchFilter.projectInformation.value.map(val => encodeURIComponent(val)) : []
    }
    return value
}

function updateBrands(searchFilter) {
    let value = ""
    if (searchFilter?.brands?.active && searchFilter?.brands?.value != "") {
        value = searchFilter.brands.value && Array.isArray(searchFilter.brands.value) ? searchFilter.brands.value.map(val => encodeURIComponent(val)) : []
    }
    return value
}

function updatePostCode(searchFilter) {
    let postCodeCodeObj = {}
    if (searchFilter?.projectPostcode?.active) {
        postCodeCodeObj.project = searchFilter.projectPostcode.value
    }
    if (searchFilter?.companyPostcode?.active) {
        postCodeCodeObj.company = searchFilter.companyPostcode.value
    }
    return postCodeCodeObj
}

function updateTown(searchFilter) {
    let townCodeObj = {}
    if (searchFilter?.projectTown?.active) {
        townCodeObj.project = searchFilter.projectTown.value
    }
    if (searchFilter?.companyTown?.active) {
        townCodeObj.company = searchFilter.companyTown.value
    }
    return townCodeObj
}

function updateLocation(payload) {
    let locationObj = payload?.location ? payload?.location : {}, companyLocation = payload?.companyLocation ? payload?.companyLocation : {},
        obj = {};
    if (locationObj?.active && locationObj?.location !== '') {
        obj.project = {
            location: locationObj.location ? locationObj.location : '',
            latitude: locationObj.latitude ? locationObj.latitude : 0,
            longitude: locationObj.longitude ? locationObj.longitude : 0,
            radius: locationObj.radius
        }
    }
    if (companyLocation?.active && companyLocation?.location !== '') {
        obj.company = {
            location: companyLocation.location ? companyLocation.location : '',
            latitude: companyLocation.latitude ? companyLocation.latitude : 0,
            longitude: companyLocation.longitude ? companyLocation.longitude : 0,
            radius: companyLocation.radius
        }
    }
    return obj;
}

function updateMeasureParking(payload) {
    let value
    if (payload?.measure?.options?.parkingSpaces?.active &&
        (payload?.measure?.options?.parkingSpaces?.from !== '' || payload?.measure?.options?.parkingSpaces?.to !== '')) {
        value = {
            from: payload.measure.options.parkingSpaces.from,
            to: payload.measure.options.parkingSpaces.to
        }
    }
    return value ? value : ''
}

function updateMeasureTotal(payload) {
    let value
    if (payload?.measure?.options?.totalFloorArea?.active &&
        (payload?.measure?.options?.totalFloorArea?.from !== ''
            || payload?.measure?.options?.totalFloorArea?.to !== '')) {
        value = {
            from: payload.measure.options.totalFloorArea.from,
            to: payload.measure.options.totalFloorArea.to
        }
    }
    return value ? value : ''
}

function updateMeasureGroundFloor(payload) {
    let value
    if (payload?.measure?.options?.groundFloorArea?.active &&
        (payload?.measure?.options?.groundFloorArea?.from !== '' || payload?.measure?.options?.groundFloorArea?.to !== '')) {
        value = {
            from: payload.measure.options.groundFloorArea.from,
            to: payload.measure.options.groundFloorArea.to
        }
    }
    return value ? value : ''
}

function updateMeasureSite(payload) {
    let value
    if (payload?.measure?.options?.siteArea?.active &&
        (payload?.measure?.options?.siteArea?.from !== '' || payload?.measure?.options?.siteArea?.to !== '')) {
        value = {
            from: payload.measure.options.siteArea.from,
            to: payload.measure.options.siteArea.to
        }
    }
    return value ? value : ''
}

function updateMeasureAboveGround(payload) {
    let value
    if (payload?.measure?.options?.aboveGroundStoreys?.active &&
        (payload?.measure?.options?.aboveGroundStoreys?.from !== '' || payload?.measure?.options?.aboveGroundStoreys?.to !== "")) {
        value = {
            from: payload.measure.options.aboveGroundStoreys.from,
            to: payload.measure.options.aboveGroundStoreys.to
        }
    }
    return value ? value : ''
}

function updateMeasureBelowGround(payload) {
    let value
    if (payload?.measure?.options?.belowGroundStoreys?.active &&
        (payload?.measure?.options?.belowGroundStoreys?.from !== '' || payload?.measure?.options?.belowGroundStoreys?.to !== '')) {
        value = {
            from: payload.measure.options.belowGroundStoreys.from,
            to: payload.measure.options.belowGroundStoreys.to
        }
    }
    return value ? value : ''
}

function updateMeasureUnits(payload) {
    let value
    if (payload?.measure?.options?.noOfUnits?.active &&
        (payload?.measure?.options?.noOfUnits?.from !== '' || payload?.measure?.options?.noOfUnits?.to !== "")) {
        value = {
            from: payload.measure.options.noOfUnits.from,
            to: payload.measure.options.noOfUnits.to
        }
    }
    return value ? value : ''
}

function updateMaterialValue(filter) {
    let value = []
    if (filter?.materials?.active) {
        filter.materials.options.map(mainParent => {
            if (mainParent.active || mainParent.isChildSelected) {
                if (mainParent.active) {
                    value.push(mainParent.value)
                } else {
                    mainParent.subParent.map(subItem => {
                        if (subItem.active || subItem.isChildSelected) {
                            if (subItem.active) {
                                value.push(subItem.value)
                            } else {
                                subItem?.children?.map(child => {
                                    if (child.active) {
                                        value.push(child.value)
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }
    return value
}

function updateProjectCovers(payload) {
    if (payload.noOfCovers && payload.noOfCovers.active && payload.noOfCovers.value) {
        return {
            value: parseInt(payload.noOfCovers.value),
            operator: payload.noOfCovers.value && parseInt(payload.noOfCovers.value) < 50 ? "<" : ">="
        }
    } else {
        return false
    }
}

function updateProjectFramework(searchFilter) {
    return searchFilter?.projectFramework?.active && searchFilter?.projectFramework?.value ? searchFilter?.projectFramework?.value : []
}

function updateProjectUsage(searchFilter) {
    return searchFilter?.projectUsage?.value && Array.isArray(searchFilter?.projectUsage?.value) && searchFilter?.projectUsage?.value?.length > 0 ? searchFilter.projectUsage.value : []
}

function updateProjectLandType(searchFilter) {
    return searchFilter?.projectLandType?.value && Array.isArray(searchFilter?.projectLandType?.value) && searchFilter?.projectLandType?.value?.length > 0 ? searchFilter.projectLandType.value : []
}

function updateCategoriesCriteria(searchFilter) {
    let categories = []
    if (searchFilter?.categories?.active) {
        searchFilter.categories.options.map(function (item, index) {
            if (item.active) {
                categories.push(parseInt(item.value))
            }
            item.subCategories.map(function (subItem, key) {
                if (subItem.active) {
                    categories.push(parseInt(subItem.value))
                }
            })
        })
    }
    return categories
}

function updateAdvanceCategoriesCriteria(searchFilter, type) {
    let categories = []
    if (searchFilter && searchFilter[type]?.active) {
        searchFilter[type].options.map(item => {
            if (item.active) {
                categories.push(parseInt(item.value))
            }
            item.subCategories.map(subItem => {
                if (subItem.active) {
                    categories.push(parseInt(subItem.value))
                }
            })
        })
    }
    return categories;
}

function getBuildPhaseValue(payload) {
    let value = []

    if (payload?.buildPhase || payload?.completedProject) {
        if (payload.buildPhase) {
            payload.buildPhase.options.map(item => {
                if (item.active) {
                    value.push(item.value)
                }
            })
        }
        if (payload.completedProject && payload.completedProject.active) {
            value.push(payload.completedProject.value)
        }
    }
    return value
}

function updateDevTypesCriteria(searchFilter, type) {
    let devCatType = []
    if (searchFilter && searchFilter[type] && searchFilter[type]?.active) {
        searchFilter[type].options.map(function (item) {
            if (item.active) {
                devCatType.push(parseInt(item.value))
            }
        })
    }
    return devCatType
}

function updateIncludeDevTypes(searchFilter, type) {
    let developmentTypes = []
    if (searchFilter && searchFilter[type] && searchFilter[type]?.active) {
        searchFilter[type].options.map(item => {
            if (item.active) {
                developmentTypes.push(parseInt(item.value))
            }
        })
    }
    return developmentTypes
}


function updateIncludeRoles(searchFilter, type) {
    let roles = []
    if (searchFilter && searchFilter[type] && searchFilter[type]?.active) {
        searchFilter[type].options.map(item => {
            if (item.active) {
                roles.push(parseInt(item.value))
            }
        })
    }
    return roles
}

function updateLastUpdatedCriteria(searchFilter) {
    var value = null
    //LastUpdated criteria update
    if (searchFilter?.updated?.active && searchFilter?.updated?.toggleActive) {
        searchFilter.updated.options.map(function (item, index) {
            if (item.active) {
                value = item.value
            }
        })
    }
    return value
}

function updateFistPublishCriteria(searchFilter) {
    var value = null
    //LastUpdated criteria update
    if (searchFilter?.new?.active && searchFilter?.new?.toggleActive) {
        searchFilter.new.options.map(function (item, index) {
            if (item.active) {
                value = item.value
            }
        })
    }
    return value
}

function updateRoleAddedCriteria(searchFilter) {
    var value = null
    //roleAdded criteria update
    if (searchFilter?.roleAdded?.active && searchFilter?.roleAdded?.toggleActive) {
        searchFilter.roleAdded.options.map(function (item) {
            if (item.active) {
                value = item.value
            }
        })
    }
    return value
}