import React, { useState, useEffect } from 'react'
import { Input, Dropdown, Label, DropdownItem, DropdownMenu, DropdownToggle, Row, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { resetExportDate, updateExportFromDate, updateExportSortBy, updateExportToDate, updateIsExportCountAPILoaded, updateMarkAsMailed, updateSizeOfDelivery } from '../../reducer';
import { createNewExport, fetchExportRecordsCount } from '../../apiclient';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../common/js/icon';
import { extractPayloadForDatesExportCount } from '../../utils';

const ThirdPage = (props) => {
    const exports = useSelector((state) => state.export)
    const user = useSelector((state) => state.user.user);
    const [selectedNumberOfRecords, setSelectedNumberOfRecords] = useState("All");
    const [microcopyMsg, setMicrocopyMsg] = useState(exports?.microcopyMsg)
    const [startFromDate, setStartFromDate] = useState('')
    const [startToDate, setStartToDate] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortByDropdownOpen, setSortByDropdownOpen] = useState(false);
    const [selectedSortBy, setSelectedSortBy] = useState(exports?.exportCriteria.setting.type === "Contacts" ? "Alphabetical" : "Latest")
    const [dropDownList, setDropDownList] = useState([])
    const [entityLimit, setEntityLimit] = useState(2000)
    const [isMarkProjectsAsMailed, setIsMarkProjectsAsMailed] = useState(exports?.exportCriteria.setting.isMarkProjectsAsMailed);
    const [isMarkCompaniesAsMailed, setIsMarkCompaniesAsMailed] = useState(exports?.exportCriteria.setting.isMarkCompaniesAsMailed);
    const [isMarkContactsAsMailed, setIsMarkContactsAsMailed] = useState(exports?.exportCriteria.setting.isMarkContactsAsMailed);
    const [hasOnlyContactsFieldsSelected, setHasOnlyContactsFieldsSelected] = useState(false)
    const [saveSearchMsg, setSaveSearchMsg] = useState('');
    const [exportCountMsg, setExportCountMsg] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartFromDateChange = (startfromdate) => {
        let endDateVal = new Date(startfromdate);
        endDateVal.setHours(endDateVal.getHours() + 1);
        if (startfromdate) {
            let momentFromFormat = moment(startfromdate).format('YYYY-MM-DD')
            dispatch(updateExportFromDate({ 'from': momentFromFormat }))
            setStartFromDate(startfromdate)
            if (exports.exportCountQuery && startToDate !== '') {
                dispatch(updateIsExportCountAPILoaded(true))
                let momentToFormat = moment(startToDate).format('YYYY-MM-DD')
                let value = _.clone(exports.exportCountQuery)
                value = extractPayloadForDatesExportCount(value, momentFromFormat, momentToFormat)
                props.updateRefreshCountQuery(value)
                fetchExportRecordsCount(value, dispatch)
                setSaveSearchMsg('')
                setExportCountMsg('')
            }
        }
    }

    const handleStartToDateChange = (starttodate) => {
        let endDateVal = new Date(starttodate);
        endDateVal.setHours(endDateVal.getHours() + 1);
        if (starttodate) {
            let momentToFormat = moment(starttodate).format('YYYY-MM-DD')
            dispatch(updateExportToDate({ 'to': momentToFormat }))
            setStartToDate(starttodate)
            if (exports.exportCountQuery && startFromDate !== '') {
                dispatch(updateIsExportCountAPILoaded(true))
                let momentFromFormat = moment(startFromDate).format('YYYY-MM-DD')
                let value = _.clone(exports.exportCountQuery)
                value = extractPayloadForDatesExportCount(value, momentFromFormat, momentToFormat);
                props.updateRefreshCountQuery(value)
                fetchExportRecordsCount(value, dispatch)
                setSaveSearchMsg('')
                setExportCountMsg('')
            }
        }
    }

    useEffect(() => {
        setMicrocopyMsg(exports?.microcopyMsg);
        let newDropDownList = exports?.exportCriteria.setting.type === 'Projects'
            ? exports?.projectDownloadRecordSettings.slice()
            : exports?.companyDownloadRecordSettings.slice();

        if (exports?.exportCriteria.setting.exportRecordsCount > entityLimit) {
            newDropDownList.shift();
            newDropDownList.push({ name: 'Top ' + entityLimit, value: entityLimit });
        }
        setDropDownList(
            newDropDownList.filter(
                (setting) =>
                    exports?.exportCriteria.setting.exportRecordsCount > setting.value || setting.name === 'All'
            )
        );

        let newSelectedNumberOfRecords =
            exports?.exportCriteria.setting.exportRecordsCount > entityLimit &&
                selectedNumberOfRecords === 'All'
                ? 'Top ' + entityLimit
                : exports?.exportCriteria.setting.exportRecordsCount < entityLimit &&
                    selectedNumberOfRecords === 'Top ' + entityLimit
                    ? 'All'
                    : exports?.exportCriteria.setting.exportRecordsCount < selectedNumberOfRecords.split(' ')[1]
                        ? 'All'
                        : selectedNumberOfRecords;

        setSelectedNumberOfRecords(newSelectedNumberOfRecords);

        // let sizeOfDelivery = newDropDownList.filter((setting) => newSelectedNumberOfRecords === setting.name)[0].value
        // // exports?.exportCriteria.setting.sizeOfDelivery = sizeOfDelivery;
        // // dispatch(updateSizeOfDelivery({ 'sizeOfDelivery': sizeOfDelivery }))

        setIsMarkProjectsAsMailed(exports?.exportCriteria.setting.isMarkProjectsAsMailed);
        setIsMarkCompaniesAsMailed(exports?.exportCriteria.setting.isMarkCompaniesAsMailed);
        setIsMarkContactsAsMailed(exports?.exportCriteria.setting.isMarkContactsAsMailed);

        let fields = exports?.exportCriteria.setting.fields;
        if (
            fields &&
            ((fields.companies && fields.companies.length === 0) || !fields.companies) &&
            ((fields.projects && fields.projects.length === 0) || !fields.projects) &&
            fields.contacts &&
            fields.contacts.length !== 0
        ) {
            setHasOnlyContactsFieldsSelected(true);
        } else {
            setHasOnlyContactsFieldsSelected(false);
        }
    }, [exports, selectedNumberOfRecords]);

    const handleOnClose = () => {

    }

    function handleMarkAsMailed(event, type) {
        event.stopPropagation()
        let objectToUpdate = {}
        if (type === "projects") {
            objectToUpdate = { isMarkProjectsAsMailed: event.target.checked }
        }
        if (type === "companies") {
            objectToUpdate = { isMarkCompaniesAsMailed: event.target.checked }
        }
        if (type === "contacts") {
            objectToUpdate = { isMarkContactsAsMailed: event.target.checked }
        }
        dispatch(updateMarkAsMailed(objectToUpdate))
    }

    function handleSortByDropdownClick(value) {
        setSelectedSortBy(value)
        dispatch(updateExportSortBy({ sortBy: value }))
        // if (value === "Project value") {
        //     dispatch(updateExportSortBy({ sortBy: "V" }))
        // } else if (value === "Latest") {
        //     dispatch(updateExportSortBy({ sortBy: "L" }))
        // } else if (value === "Alphabetical") {
        //     dispatch(updateExportSortBy({ sortBy: "AL" }))
        // }
    }

    function handleRecordSettingChange(value, e) {
        setSelectedNumberOfRecords(value.name)
        dispatch(updateSizeOfDelivery({ 'sizeOfDelivery': value.value }))
    }

    useEffect(() => {
        setDropDownList(
            exports?.exportCriteria.setting.type === 'Projects'
                ? exports?.projectDownloadRecordSettings
                : exports?.companyDownloadRecordSettings
        );
        // dropDownList.forEach((setting) => {
        //     if (setting.value === exports?.exportCriteria.setting.sizeOfDelivery) {
        //         handleRecordSettingChange(setting);
        //     }
        // });
        if (exports?.exportCriteria.setting.isMarkProjectsAsMailed) {
            setIsMarkProjectsAsMailed(true);
        }
        if (exports?.exportCriteria.setting.isMarkCompaniesAsMailed) {
            setIsMarkCompaniesAsMailed(true);
        }
        if (exports?.exportCriteria.setting.isMarkContactsAsMailed) {
            setIsMarkContactsAsMailed(true);
        }
        if (exports?.exportCriteria.setting.sortBy) {
            switch (exports?.exportCriteria.setting.sortBy) {
                case 'V':
                    handleSortByDropdownClick('Project value');
                    break;
                case 'L':
                    handleSortByDropdownClick('Latest');
                    break;
                case 'AL':
                    handleSortByDropdownClick('Alphabetical');
                    break;
                default:
                    break;
            }
        } else {
            const defaultSortBy = exports?.exportCriteria.setting.type === 'Contacts' ? 'AL' : 'AL';
            // dispatch(updateExportSortBy({ sortBy: defaultSortBy }))
        }

        let fields = exports?.exportCriteria.setting.fields;
        if (
            fields &&
            ((fields.companies && fields.companies.length === 0) || !fields.companies) &&
            ((fields.projects && fields.projects.length === 0) || !fields.projects) &&
            fields.contacts &&
            fields.contacts.length !== 0
        ) {
            setHasOnlyContactsFieldsSelected(true);
        } else {
            setHasOnlyContactsFieldsSelected(false);
        }
        if (exports?.exportCriteria?.setting?.from && exports?.exportCriteria?.setting?.to) {
            setStartFromDate(new Date(exports?.exportCriteria?.setting?.from))
            setStartToDate(new Date(exports?.exportCriteria?.setting?.to))
        }
    }, []);

    const handleResetDateClick = () => {
        dispatch(resetExportDate({ 'from': '', 'to': '' }))
        setStartFromDate('')
        setStartToDate('')
        let criteriaObj = exports?.exportCountQuery
        dispatch(updateIsExportCountAPILoaded(true));
        fetchExportRecordsCount(criteriaObj, dispatch)
        setSaveSearchMsg('')
        setExportCountMsg('')
    }

    function validateExport() {
        let returnVal = true

        if (exports?.exportCriteria.setting.from && !exports?.exportCriteria.setting.to
            || !exports?.exportCriteria.setting.from && exports?.exportCriteria.setting.to) {
            setSaveSearchMsg('Please select a correct date range')
            return false
        }

        if (exports?.exportCriteria.setting?.exportRecordsCount <= 0) {
            setExportCountMsg('Nothing to export')
            return false
        }
        return returnVal
    }

    const handleExport = () => {
        if (validateExport()) {
            let payload = _.clone(exports?.exportCriteria);
            if (payload?.setting?.fields?.projects?.length === 0) {
                const { setting, ...settings } = payload
                const { fields, ...updatedFields } = setting
                const { projects, ...updatedNestedObject } = fields
                payload = {
                    ...settings,
                    setting: {
                        ...updatedFields,
                        fields: updatedNestedObject

                    }
                }
            }
            if (payload?.setting?.fields?.companies?.length === 0) {
                const { setting, ...settings } = payload
                const { fields, ...updatedFields } = setting
                const { companies, ...updatedNestedObject } = fields
                payload = {
                    ...settings,
                    setting: {
                        ...updatedFields,
                        fields: updatedNestedObject

                    }
                }
            }
            if (payload?.setting?.fields?.contacts?.length === 0) {
                const { setting, ...settings } = payload
                const { fields, ...updatedFields } = setting
                const { contacts, ...updatedNestedObject } = fields
                payload = {
                    ...settings,
                    setting: {
                        ...updatedFields,
                        fields: updatedNestedObject

                    }
                }
            }
            payload.user_no = user.user_id
            createNewExport(payload, dispatch, navigate)
        }
        // Reset selected number of records
        setSelectedNumberOfRecords('All')
    }


    return (
        <>
            <div className="input-container">
                <span className='title'>Records updated between</span>
                <span className={`reset ${exports?.exportCriteria.setting.from !== '' || exports?.exportCriteria.setting.to !== '' ? '' : 'hide'}`} id="reset-date" onClick={handleResetDateClick} >Clear dates</span>
                <Row className="text-left pd-tp-b-0 align-items-center">

                    <Col sm={3} className="DayPickerInput">
                        <DatePicker
                            selected={startFromDate}
                            onChange={(date) => handleStartFromDateChange(date)}
                            onCalendarClose={handleOnClose}
                            className="startDate datepickerbox"
                            monthsShown={2}
                            //minDate={new Date()}
                            selectsStart
                            startDate={startFromDate}
                            endDate={startToDate}
                            //minDate={new Date()}
                            placeholderText="From"
                            dateFormat="dd-MMM-yyyy"
                            filterDate={(date) => {
                                return moment() > date;
                            }}
                        />
                    </Col>
                    <div className="col-md-custom-one p-0">
                        <b className="line-separator">—</b>
                    </div>
                    <Col sm={3} className="DayPickerInput">
                        <DatePicker
                            selected={startToDate}
                            onChange={(date) => handleStartToDateChange(date)}
                            onCalendarClose={handleOnClose}
                            className="endDate datepickerbox"
                            monthsShown={2}
                            startDate={startFromDate}
                            endDate={startToDate}
                            minDate={startFromDate}
                            selectsEnd
                            placeholderText="To"
                            dateFormat="dd-MMM-yyyy"
                            filterDate={(date) => {
                                return moment() > date;
                            }}
                        />
                    </Col>



                    <Col sm={2}>
                        <div className='count-panel'>
                            <div className="max-count-msg">{`${exports.exportCriteria?.setting?.exportRecordsCount > entityLimit ? 'Maximum of ' + entityLimit + ' will be exported' : ''}`}</div>
                            <div className="d-inline-flex align-items-center">
                                <h2 className={`${exports.exportCriteria?.setting?.exportRecordsCount > entityLimit ? "red mr-2 my-0 d-inline-block" : "green mr-2 my-0 d-inline-block"}`}>{new Intl.NumberFormat('en-UK').format(exports.exportCriteria.setting.exportRecordsCount)}</h2>
                                <span className="record"> {microcopyMsg}</span>
                            </div>
                            <div className={`${exportCountMsg === '' ? 'hide' : 'no-export'}`}>{exportCountMsg}</div>
                        </div>
                    </Col>
                </Row>
                <div className="required">{saveSearchMsg}</div>
                <div className="val-err-tooltip"></div>
            </div>
            {
                exports?.exportCriteria?.setting.type != "Contacts" &&
                <div className="input-container">
                    <span>Sort by</span>
                    <Dropdown isOpen={sortByDropdownOpen} className="btn-group" toggle={() => { setSortByDropdownOpen(!sortByDropdownOpen) }}>
                        <DropdownToggle caret>
                            {selectedSortBy}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleSortByDropdownClick("Latest")}>Latest</DropdownItem>
                            <DropdownItem onClick={() => handleSortByDropdownClick("Project value")}>Project value</DropdownItem>
                        </DropdownMenu>
                        {/* } */}
                    </Dropdown>
                </div>
            }
            <div className="input-container">
                <span>Number of {microcopyMsg} to download</span>
                <Dropdown className="btn-group"
                    // disabled={exports?.ExportCountAPILoadingCount != 0 ? true : false} 
                    isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
                    <DropdownToggle caret>
                        {selectedNumberOfRecords}
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            dropDownList?.map(function (setting, index) {
                                return (
                                    <DropdownItem key={index} onClick={() => handleRecordSettingChange(setting, setting.value)}>{setting.name}</DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div className="input-container">
                {exports?.isProjectsChecked ? (
                    <div className={`check-box-button mb-3`}>
                        <Input type="checkbox" id='mark-projects-as-mailed' checked={isMarkProjectsAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "projects")} />
                        <Label for="mark-projects-as-mailed" className="mb-0">Mark these projects as mailed today</Label>
                    </div>
                ) : (
                    <div className={`check-box-button disabled my-3`}>
                        <Input disabled type="checkbox" id='mark-projects-as-mailed' checked={isMarkProjectsAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "projects")} />
                        <Label for="mark-projects-as-mailed" className="mb-0">Mark these projects as mailed today</Label>
                    </div>
                )
                }
                {exports?.isCompaniesChecked ? (
                    <div className={`check-box-button mt-3`}>
                        <Input type="checkbox" id='mark-companies-as-mailed' checked={isMarkCompaniesAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "companies")} />
                        <Label for="mark-companies-as-mailed" className="mb-0">Mark these companies as mailed today</Label>
                    </div>
                ) : (
                    <div className={`check-box-button disabled my-3`}>
                        <Input disabled type="checkbox" id='mark-companies-as-mailed' checked={isMarkCompaniesAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "companies")} />
                        <Label for="mark-companies-as-mailed" className="mb-0">Mark these companies as mailed today</Label>
                    </div>
                )
                }
                {exports?.isContactsChecked ? (
                    <div className={`check-box-button mt-3`}>
                        <Input type="checkbox" id='mark-contacts-as-mailed' checked={isMarkContactsAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "contacts")} />
                        <Label for="mark-contacts-as-mailed" className="mb-0">Mark these contacts as mailed today</Label>
                    </div>
                ) : (
                    <div className={`check-box-button disabled my-3`}>
                        <Input disabled type="checkbox" id='mark-contacts-as-mailed' checked={isMarkContactsAsMailed}
                            onChange={(e) => handleMarkAsMailed(e, "contacts")} />
                        <Label for="mark-contacts-as-mailed" className="mb-0">Mark these contacts as mailed today</Label>
                    </div>
                )
                }
            </div>
            <div className="steps-footer-buttons">
                <button className="outline-btn cancel me-3" onClick={() => props.handleCancel()}>Cancel</button>
                {props.pageindex !== 0 && <button className="outline-btn previous me-3" onClick={() => props.handlePrevious()}><Icon color="#053874" size={20} icon="icon-gen-arrow-back" className="me-1" />Previous</button>}
                {exports.ExportCountAPILoadingCount ?
                    <button className="primary-btn next" disabled={true} >Export</button> :
                    <button className="primary-btn next" onClick={() => handleExport()}>Export</button>
                }
            </div>

        </>
    )
}

export default ThirdPage
