import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, ListGroupItem } from 'reactstrap'
import { updateIsSelectedToSpecificRow, updateSelectedRecordCount } from '../redux/reducer'
import CompanySearchList from './companySearchList'
import PeopleSearchList from './peopleSearchList'
import ProjectSearchList from './projectSearchList'

const SearchListRow = (props) => {
  const dispatch = useDispatch();

  const handleCheckboxChange = (value) => {
    dispatch(updateIsSelectedToSpecificRow({
      id: value,
      isSelected: !props.entity.is_selected,
      entityType: props.prevNextList
    }))
  }

  useEffect(() => {
    dispatch(updateSelectedRecordCount(props.length && props.length.roles && props.length.roles.length))
  }, [props.length])

  return (
    <ListGroupItem key={props.index}>
      {
        ((["searchList", "projectRoles", "companyRelatedCompanies", "worksWithCompanies", "companyProjects", "groupedCompanyProjects", "relatedCompaniesJointProjects", "groupedRelatedCompanies", "companyRelatedCompanies", "groupedCompanies",  "favouriteList", "myLeadsUpdates", "historyList", "sharedList", "labelsList", "archiveList", "leagueList"].includes(props.prevNextList)) && !props.isClusterClick) ? (
          <label className='container'>
            <Input
              value={props.entity.objectId}
              type="checkbox" onChange={() => handleCheckboxChange(props.entity.objectId)}
              checked={props.entity.is_selected}
            />
            <span className="checkmark"></span>
          </label>
        ) : ('')
      }
      {props.entity.objectType == 'project' ?
        (<ProjectSearchList
          roleGroupName={props.roleGroupName}
          prevNextList={props.prevNextList}
          role={props.role}
          entity={props.entity}
          index={props.index}
          user={props.user}
          activeTab={props.activeTab}
          sort={props.sort}
          roleId={props.roleId}
          columnOneRole={props.columnOneRole}
          columnTwoRole={props.columnTwoRole}
        />
        ) : props.entity.objectType == 'company' ?
          (<CompanySearchList
            prevNextList={props.prevNextList}
            roleGroupName={props.roleGroupName}
            entity={props.entity}
            index={props.index}
            user={props.user}
            sort={props.sort}
            ptno={props.ptno}
            columnOneRole={props.columnOneRole}
            columnTwoRole={props.columnTwoRole}
          />
          ) : props.entity.objectType == 'people' ?
            (<PeopleSearchList
              role={props.role}
              entity={props.entity}
              index={props.index}
              user={props.user}
              prevNextList={props.prevNextList}
            />
            ) :
            ('')
      }
    </ListGroupItem>
  )
}

export default SearchListRow