import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useSelector, useDispatch } from 'react-redux';
import { Col, FormGroup, Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from 'reactstrap';
import _ from 'lodash';
import { updateSearchTextObject, updateAdditionalQuery, updateExactSearchTextObject } from "../../redux/reducer"
import { createCommonQuery } from '../../../../utils/commonFunctions';
import { toggleAnyAllVal, updateDisplayKey, updateFilterText, updatePreviousSelectedValue } from '../redux/reducer';

const ProjectTextSearch = (props) => {
    
    const dispatch = useDispatch()
    const searchPersist = useSelector((state) => state.searchPersist);
    const search = useSelector((state) => state.search)
    const filter = useSelector((state) => state.filter)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [tagInputValue, setTagInputValue] = useState('')
    const [tagValue, setTagValue] = useState([])
    const [selectedValue, setSelectedValue] = useState('Fuzzy search');
    const [radioDisabled, setRadioDisabled] = useState(true);
    const [currentRadio, setCurrentRadio] = useState('projectTitle');
    const [textRetain, setTextRetain] = useState('');
    const [projectTextBlock, setProjectTextBlock] = useState(false);
    const settings = useSelector((state) => state.settings)
    const [exactSearchTextObj, setexactSearchTextObj] = useState({ project: "", people: "", company: "" });
    let activeFilterValue = searchPersist.appliedContext
    const [searchData, setSearchData] = useState({
        addAnyButtonValue: 'any',
        isAddAnyButtonClicked: false,
        isButtonClicked: false
    })

    const [searchTextObj, setSearchTextObj] = useState(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] });
    const handleProjectSearchChange = (value) => {
        setTagValue(value)
        searchTextObj[activeFilterValue] = [...value];
        setSearchTextObj(_.cloneDeep(searchTextObj))
        dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        let payload = createPayload(activeFilterValue);
        dispatch(updateAdditionalQuery(payload))
        let projectInforArray = []
        let values = searchTextObj && searchTextObj[activeFilterValue]
        for (var i = 0; i < values?.length; i++) {
            if (values[i].value != undefined || values[i].value != null) {
                projectInforArray.push(values[i].value)
            }
        }
        let payload1 = {
            value: projectInforArray,
            dataObj: projectInforArray,
            active: projectInforArray.length > 0 ? true : false,
            keyname: "projectInformation",
            isAnyAll: projectInforArray.length > 1 ? searchData.addAnyButtonValue : 'any'
        }
        dispatch(updateFilterText(payload1))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
    }

    useEffect(() => {
        if(props?.isClearAll){
            setTagInputValue('')
            setexactSearchTextObj({ project: "", people: "", company: "" })
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
            setTextRetain('')
        }  
    }, [props?.isClearAll])

    const handleKeyDown = (event) => {
        if (!tagInputValue) return
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                handleSearchText(tagInputValue, event);
                break
            default:
                break
        }
    }

    const handleOnBlur = (event) => {
        let tagInput = event.target.value
        if (!tagInput) return
        if (tagInput) {
            handleSearchText(tagInput, event)
        }
    }

    const handleSearchText = (tagInputValue, event) => {
        if (tagInputValue !== '') {
            searchTextObj[activeFilterValue].push({ label: tagInputValue, value: tagInputValue });
        }
        setTagValue([...tagValue, createOption(tagInputValue)]);
        setTagInputValue('');
        setProjectTextBlock(true);
        setSearchTextObj(_.cloneDeep(searchTextObj));
        dispatch(updateSearchTextObject(_.cloneDeep(searchTextObj)));
        let payload = createPayload(activeFilterValue);
        dispatch(updateAdditionalQuery(payload));
        let projectInforArray = [];
        let values = searchTextObj && searchTextObj[activeFilterValue];
        for (var i = 0; i < values?.length; i++) {
            if (values[i].value != undefined || values[i].value != null) {
                projectInforArray.push(values[i].value);
            }
        }
        let payload1 = {
            value: projectInforArray,
            dataObj: event.target.value,
            active: projectInforArray.length > 0 ? true : false,
            keyname: "projectInformation",
            isAnyAll: projectInforArray.length > 1 ? searchData.addAnyButtonValue : 'any'
        };
        dispatch(updateFilterText(payload1));
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }));
        event.preventDefault();
    }

    const createOption = (label) => ({
        label,
        value: label
    })

    const handleSelectedValue = (event, value) => {
        let updatedRadioDisabled = true;
        let updatedKeyname = 'projectInformationEditor';
        let projectInforArray = []
        let values = searchTextObj && searchTextObj[activeFilterValue]
        for (var i = 0; i < values?.length; i++) {
            if (values[i].value != undefined || values[i].value != null) {
                projectInforArray.push(values[i].value)
            }
        }
        if (value === 'Exact match') {
            updatedRadioDisabled = false;
            updatedKeyname = currentRadio;
            let payload1 = {
                value: [],
                dataObj: [],
                active: false,
                keyname: "projectInformation",
                isAnyAll: 'any'
            }
            dispatch(updateFilterText(payload1))
            if (filter.filtersState[activeFilterValue].projectInformation.value &&
                filter.filtersState[activeFilterValue].projectInformation.value.length > 0) {
                dispatch(updatePreviousSelectedValue(filter.filtersState[activeFilterValue].projectInformation.value))
            }
            if (filter.previousSelectedValue) {
                props.variableHandler([(updatedKeyname === 'projectInformationEditor' ? 'projectInformation' : updatedKeyname), "projectArray"], filter.previousSelectedValue)
            }
        }
        else {
            dispatch(updateDisplayKey({ text: "", key: "projectTitle" }))
            dispatch(updateDisplayKey({ text: "", key: "projectScheme" }))
            if (filter.previousSelectedValue) {
                let values = []
                filter.previousSelectedValue.map(item => {
                    values.push({
                        label: item,
                        value: item
                    })
                })
                let payload = {
                    value: filter.previousSelectedValue,
                    dataObj: values,
                    active: filter.previousSelectedValue && filter.previousSelectedValue.length > 0 ? true : false,
                    keyname: "projectInformation",
                    isAnyAll: filter.previousSelectedValue?.length > 1 ? searchData.addAnyButtonValue : 'any'
                }
                dispatch(updateFilterText(payload))
            }
            if (textRetain != "") {
                dispatch(updatePreviousSelectedValue(textRetain))
            }
        }
        setSelectedValue(value);
        setRadioDisabled(updatedRadioDisabled);
        setDropdownOpen(false);
        setProjectTextBlock(false);
        let payload = createPayload(activeFilterValue);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
    };

    const handleRadioClick = (event, keyname) => {
        setCurrentRadio(keyname)
        let payload = createPayload(activeFilterValue);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        props.variableHandler([(keyname === 'projectInformationEditor' ? 'projectInformation' : keyname), "projectArray"], textRetain)
    }

    const toggleAnyAll = (e, type) => {
        setSearchData(prev => ({
            ...prev,
            addAnyButtonValue: type,
            isAddAnyButtonClicked: true,
            isButtonClicked: true
        }))
        let payload = createPayload(activeFilterValue);
        dispatch(toggleAnyAllVal(type))
        dispatch(updateAdditionalQuery(payload))

    }

    const dropDownToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleTextChange = (event, field) => {
        setTextRetain(event.target.value);
        let textObj = activeFilterValue === 'project' ? { project: event.target.value } : activeFilterValue === 'company' ? { company: event.target.value } : { people: event.target.value }
        setexactSearchTextObj(_.cloneDeep(textObj))
        dispatch(updateExactSearchTextObject(textObj))
        setProjectTextBlock(true);
    };

    const onEnterHandler = (event) => {
        if (event.keyCode == 13 || event.keyCode === 9) {
            let payload = createPayload(activeFilterValue);
            dispatch(updateAdditionalQuery(payload))
            setSearchData((prevState) => ({
                ...prevState,
                isButtonClicked: true
            }))
            props.variableHandler([(currentRadio === 'projectInformationEditor' ? 'projectInformation' : currentRadio), "projectArray"], textRetain)
        }
    }

    const onBlurHandler = (event, clearField, parentModel) => {
        if (!textRetain) {
            setProjectTextBlock(false);
        }
    };

    const handleClearField = () => {
        exactSearchTextObj[activeFilterValue] = ""
        setexactSearchTextObj(exactSearchTextObj)
        dispatch(updateExactSearchTextObject(exactSearchTextObj))
        setTextRetain('');
        setProjectTextBlock(false);
        let payload = createPayload(activeFilterValue);
        dispatch(updateAdditionalQuery(payload))
        setSearchData((prevState) => ({
            ...prevState,
            isButtonClicked: true
        }))
        dispatch(updateDisplayKey({ key: currentRadio, text: '' }))
    };

    const createPayload = (filterType) => {
        let query = {};
        let query1 = {};
        let result = {};
        let values = [];
        if (selectedValue === "Fuzzy search") {
            if (searchTextObj && searchTextObj[filterType].length > 0) {
                searchTextObj[filterType].map((elem) => {
                    values.push(elem.value)
                })
                query = createCommonQuery({
                    key: "project_text",
                    operator: "=",
                    value: values
                })
            }
            if (searchTextObj[filterType]?.length > 1) {
                result.addAnyValue = searchData.isAddAnyButtonClicked ? searchData.addAnyButtonValue : "any"
            }
        }
        else if (selectedValue === "Exact match") {
            if (textRetain != "") {
                if (currentRadio === "projectTitle") {
                    query = createCommonQuery({
                        key: "project_title",
                        operator: "=",
                        value: textRetain
                    })
                }
                else if (currentRadio === "projectScheme") {
                    query = createCommonQuery({
                        key: "project_scheme",
                        operator: "=",
                        value: textRetain
                    })
                }
            }
        }
        query = Object.assign(query, query1)
        result.query = query;
        return result
    }

    // useEffect(() => {
    //     setSearchTextObj(_.cloneDeep(search.searchTextInformation))
    // }, [search.searchTextInformation])

    useEffect(() => {
        let project = []
        if (filter.filtersState[activeFilterValue]?.projectInformation?.value.length > 0) {
            _.map(filter.filtersState[activeFilterValue]?.projectInformation?.value, element => {
                project.push({ label: element, value: element })
            })
            setSearchTextObj(activeFilterValue === 'project' ? { project: project } : activeFilterValue === 'company' ? { company: project } : { people: project })
            setSearchData(prev => ({
                ...prev,
                addAnyButtonValue: filter.filtersState[activeFilterValue]?.projectInformation.isAnyAll
            }))
        }
        else if(filter.filtersState[activeFilterValue]?.projectInformation?.value.length === 0){
            setTagInputValue('')
            setTextRetain("")
            setSearchTextObj(activeFilterValue === 'project' ? { project: [] } : activeFilterValue === 'company' ? { company: [] } : { people: [] })
        }
        if (filter.filtersState[activeFilterValue]?.projectTitle?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.projectTitle?.value
            setexactSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setTextRetain(filter.filtersState[searchPersist.appliedContext]?.projectTitle?.value)
        }
        if (filter.filtersState[activeFilterValue]?.projectScheme?.value !== '') {
            let exactText = filter.filtersState[activeFilterValue]?.projectScheme?.value
            setexactSearchTextObj(activeFilterValue === 'project' ? { project: exactText } : activeFilterValue === 'company' ? { company: exactText } : { people: exactText })
            setSelectedValue('Exact match')
            setRadioDisabled(false);
            setCurrentRadio('projectScheme')
            setTextRetain(filter.filtersState[searchPersist.appliedContext]?.projectScheme?.value)
        }
        if(filter.filtersState[activeFilterValue]?.projectScheme?.value === '' && filter.filtersState[activeFilterValue]?.projectTitle?.value === ''){
            setexactSearchTextObj(activeFilterValue === 'project' ? { project: "" } : activeFilterValue === 'company' ? { company: "" } : { people: "" })
            setTextRetain("")
        }
    }, [filter.filtersState])

    // useEffect(() => {
    //     setexactSearchTextObj(_.cloneDeep(search.exactSearchTextInformation))
    // }, [search.exactSearchTextInformation])

    return (
        <div className="advanced-filter-grid" id="new_filter_spi">
            <div className="filterHeading">
                <h5 className="mb-3">Project search</h5>
            </div>
            <div className={`filterBlock ${projectTextBlock === true ? "activeBlock" : ""}`}>
                <div className="textcontent">Enter a word or phrase to search all fields, or select exact search and a specific field for more precision.</div>
                <FormGroup id={props.currentRadio} name={props.currentRadio} className="m-0 px-0">
                    <Row>
                        <Col xs={12} sm={8} md={8} lg={6} xl={8} className="advanced-filter-field-container project-info-filter">
                            <div className={`radio-btn-cntr ${radioDisabled ? "hide" : ""}`}>
                                <input
                                    className="form-control"
                                    onChange={(event) => handleTextChange(event, 'field')}
                                    onBlur={onBlurHandler}
                                    onKeyDown={onEnterHandler}
                                    type="text"
                                    name={currentRadio}
                                    id={currentRadio}
                                    placeholder="for e.g. University, School"
                                    value={exactSearchTextObj[activeFilterValue]}
                                    data-id={currentRadio === 'projectInformationEditor' ? 'projectInformation' : currentRadio}
                                />
                                <button
                                    className={`clear-button-advanced-filter clear-textfield ${textRetain && textRetain.length > 0 ? 'display-block' : 'display-none'}`}
                                    name="clearButton"
                                    data-id={currentRadio === 'projectInformationEditor' ? 'projectInformation' : currentRadio}
                                    onClick={handleClearField}
                                />
                            </div>
                            <div className={`radio-btn-cntr ${radioDisabled ? "" : "hide"}`}>
                                <CreatableSelect
                                    //components={{ DropdownIndicator: null }}
                                    inputValue={tagInputValue}
                                    menuIsOpen={false}
                                    onChange={(newValue) => handleProjectSearchChange(newValue)}
                                    placeholder='Project information'
                                    onKeyDown={handleKeyDown}
                                    onInputChange={(newValue) => setTagInputValue(newValue)}
                                    value={searchTextObj[activeFilterValue]}
                                    isClearable
                                    clearValue
                                    isMulti
                                    onBlur={handleOnBlur}
                                />


                                <div className="any-all-comp">
                                    <div className={`switch-with-text ${searchTextObj?.[activeFilterValue]?.length > 1 ? "" : "disabled"}`}>

                                        <input type="radio" className="switch-input" name="view" value="any" id="any2" onChange={(e) => toggleAnyAll(e, "any")} checked={searchData.addAnyButtonValue === 'any'} />
                                        <label for="any2" className="switch-label switch-label-off">Any</label>
                                        <input type="radio" className="switch-input" name="view" value="all" id="all2" onChange={(e) => toggleAnyAll(e, "all")} checked={searchData.addAnyButtonValue === 'all'} />
                                        <label for="all2" className="switch-label switch-label-on">All</label>
                                        <span className="switch-selection"></span>
                                    </div>
                                </div>
                            </div>
                            <div className={search.invalidRequest ? 'filter-term-error' : ''}>{search.invalidRequest ? <><p>You have reached the maximum number of words for this search</p></> : ''}</div>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={6} xl={4} className="advanced-filter-field-container pl-0">
                            <div className="input-container">
                                <div className="select-input-type">
                                    <ButtonDropdown isOpen={dropdownOpen} toggle={() => dropDownToggle()} className="w-100">
                                        <DropdownToggle caret className="w-100 text-left btn-project-search text-truncate">
                                            {selectedValue}
                                        </DropdownToggle>
                                        <DropdownMenu className="w-100">
                                            <DropdownItem onClick={(e) => handleSelectedValue(e, 'Fuzzy search')}>Fuzzy search</DropdownItem>
                                            <DropdownItem onClick={(e) => handleSelectedValue(e, 'Exact match')}>Exact match</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </FormGroup>
                <div className={`radio-btn-cntr ${radioDisabled ? "hide" : ""}`}>
                    <Row>
                        <div className="radio-buttons">
                            <FormGroup check>
                                <Input
                                    name="search-project-info"
                                    type="radio"
                                    id="projectTitle"
                                    checked={currentRadio === 'projectTitle' ? true : false}
                                    onChange={(e) => handleRadioClick(e, "projectTitle")}
                                />
                                <Label for="projectTitle" className="form-check-label">
                                    Project title
                                </Label>
                                <div className="check"></div>
                            </FormGroup>
                        </div>
                        <div className="radio-buttons">
                            <FormGroup check>
                                <Input
                                    name="search-project-info"
                                    type="radio"
                                    id="projectScheme"
                                    checked={currentRadio === 'projectScheme' ? true : false}
                                    onChange={(e) => handleRadioClick(e, "projectScheme")}
                                />
                                <Label for={"projectScheme"} className="form-check-label">
                                    Project Scheme
                                </Label>
                                <div className="check"></div>
                            </FormGroup>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    )

}

export default ProjectTextSearch;