import React, { useEffect, useState } from 'react';
import { FormGroup, Row, Col, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import 'rc-slider/assets/index.css';
import Slider, { Range } from 'rc-slider';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { searchFilterInput } from '../../redux/reducer';
import { EVENT_PRIORITY, EVENT_REMINDER, EVENT_STATUS, EVENT_TYPE } from '../../../../utils/settings';
import { capitalizeFirstLetter, convertDate, getConstructionDateValue, returnTimeFrame } from '../../../../utils/commonFunctions';
import { addEvent, updateEvent } from '../../../details/apiclient';
import { toggleCalendarTimingFilter, toggleEndConstructionFilter, toggleStartConstructionFilter, updateCalenderEndFrom, updateCalenderEndTo, updateCalenderStartFrom, updateCalenderStartTo, updateEndConstructionFilter, updateStartConstructionFilter } from '../redux/reducer';

const ProjectTimingFilter = (props) => {
    const { utcToZonedTime } = require("date-fns-tz");
    const search = useSelector((state) => state.search)
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const {
        search_container,
    } = useSelector((state) => {
        return {
            search_container: state.search
        }
    });
    const filter = useSelector((state) => state.filter)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [activeTab, setActiveTab] = useState('1')

    const [eventId, setEventId] = useState(0)
    const [constructionStartRange, setConstructionStartRange] = useState([0, 1])
    const [constructionEndRange, setConstructionEndRange] = useState([0, 1])
    const [calendarTimings, setCalendarTimings] = useState({
        start: {
            from: filter?.filtersState[filter.selectedSearchType]?.calendarStartDate?.from ? moment(filter?.filtersState[filter.selectedSearchType]?.calendarStartDate.from).toDate() : "",
            to: filter?.filtersState[filter.selectedSearchType]?.calendarStartDate?.to ? moment(filter?.filtersState[filter.selectedSearchType]?.calendarStartDate.to).toDate() : ""
        },
        end: {
            from: filter?.filtersState[filter.selectedSearchType]?.calendarEndDate?.from ? moment(filter?.filtersState[filter.selectedSearchType]?.calendarEndDate.from).toDate() : "",
            to: filter?.filtersState[filter.selectedSearchType]?.calendarEndDate?.to ? moment(filter?.filtersState[filter.selectedSearchType]?.calendarEndDate.to).toDate() : ""
        }
    });

    const dispatch = useDispatch();
    const linkLocation = useLocation().pathname;


    useEffect(() => {
        let tempFilter = filter.filtersState[filter.selectedSearchType]
        setCalendarTimings({
          start: {
            from: tempFilter?.calendarStartDate?.from ? moment(tempFilter?.calendarStartDate?.from).toDate() : "",
            to: tempFilter?.calendarStartDate?.to ? moment(tempFilter?.calendarStartDate?.to).toDate() : ""
          },
          end: {
            from: tempFilter?.calendarEndDate?.from ? moment(tempFilter?.calendarEndDate?.from).toDate() : "",
            to: tempFilter?.calendarEndDate?.to ? moment(tempFilter?.calendarEndDate?.to).toDate() : ""
          }
        });
        if(tempFilter.startConstruction.active){setConstructionStartRange(getConstructionDateValue(tempFilter.startConstruction))}
        if(tempFilter.endConstruction.active){setConstructionEndRange(getConstructionDateValue(tempFilter.endConstruction))}
  
      }, [filter?.filtersState[filter.selectedSearchType]]);


    const handleStartFromDateChange = (startfromdate) => {
        removeRollingTimelineFilters()
        if (startfromdate) {
            let date = convertDate(startfromdate)
            dispatch(updateCalenderStartFrom(date))
            dispatch(toggleCalendarTimingFilter(true))
        }
        else {
            dispatch(updateCalenderStartFrom(""))
            dispatch(toggleCalendarTimingFilter(filter.filtersState[filter.selectedSearchType]?.calendarStartDate.from ||
                filter.filtersState[filter.selectedSearchType]?.calendarEndDate.from || filter.filtersState[filter.selectedSearchType]?.calendarEndDate.to ? true : false));

        }
    }

    const handleStartToDateChange = (starttodate) => {
        removeRollingTimelineFilters()
        if (starttodate) {
            let date = convertDate(starttodate)
            dispatch(updateCalenderStartTo(date))
            dispatch(toggleCalendarTimingFilter(true))
        }
        else {
            dispatch(updateCalenderStartTo(""))
            dispatch(toggleCalendarTimingFilter(filter.filtersState[filter.selectedSearchType]?.calendarStartDate.from ||
                filter.filtersState[filter.selectedSearchType]?.calendarEndDate.from || filter.filtersState[filter.selectedSearchType]?.calendarEndDate.to ? true : false))
        }
    }

    const handleEndFromDateChange = (endFromDate) => {
        removeRollingTimelineFilters()
        if (endFromDate) {
            let date = convertDate(endFromDate)
            dispatch(updateCalenderEndFrom(date))
            dispatch(toggleCalendarTimingFilter(true))
        }
        else {
            dispatch(updateCalenderEndFrom(""))
            dispatch(toggleCalendarTimingFilter(filter.filtersState[filter.selectedSearchType]?.calendarStartDate.from ||
                filter.filtersState[filter.selectedSearchType]?.calendarStartDate.to || filter.filtersState[filter.selectedSearchType]?.calendarEndDate.to ? true : false))

        }
    }

    const handleEndToDateChange = (endToDate) => {
        removeRollingTimelineFilters()
        if (endToDate) {
            let date = convertDate(endToDate)
            dispatch(updateCalenderEndTo(date))
            dispatch(toggleCalendarTimingFilter(true))
        }
        else {
            dispatch(updateCalenderEndTo(""))
            dispatch(toggleCalendarTimingFilter(filter.filtersState[filter.selectedSearchType]?.calendarStartDate.from ||
                filter.filtersState[filter.selectedSearchType]?.calendarStartDate.to || filter.filtersState[filter.selectedSearchType]?.calendarEndDate.to ? true : false))
        }
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleConstructionEnd = (e) => {
        setConstructionEndRange(e)
    }

    const handleConstructionStart = (e) => {
        setConstructionStartRange(e)
    }

    const handleConstructionStartAfterChange = (e) => {
        let activeTimings = [returnTimeFrame(e[0]).name, returnTimeFrame(e[1]).name]
        dispatch(updateStartConstructionFilter(activeTimings))
    }

    const handleConstructionEndAfterChange = (e) => {
        let activeTimings = [returnTimeFrame(e[0]).name, returnTimeFrame(e[1]).name]
        dispatch(updateEndConstructionFilter(activeTimings))
    }

    const handleStartConstructionToggleChange = (event) => {
        event.stopPropagation();
        removeCalenderTimelineFilters()
        setConstructionStartRange([0,1])
        dispatch(toggleStartConstructionFilter(event.target.checked))
    }

    const handleEndConstructiontoggleChange = (event) => {
        event.stopPropagation();
        removeCalenderTimelineFilters()
        setConstructionEndRange([0,1])
        dispatch(toggleEndConstructionFilter(event.target.checked))
    }
    const removeRollingTimelineFilters = () => {
        if ((filter.filtersState[filter.selectedSearchType]?.startConstruction && filter.filtersState[filter.selectedSearchType]?.startConstruction.active) ||
            (filter.filtersState[filter.selectedSearchType]?.endConstruction && filter.filtersState[filter.selectedSearchType]?.endConstruction.active)) {
            dispatch(toggleStartConstructionFilter(false))
            dispatch(toggleEndConstructionFilter(false))
        }
    }

    const removeCalenderTimelineFilters = () => {
        dispatch(toggleCalendarTimingFilter(false))
    }


    const rollingTimelineList = () => {
        return (
            <>
                <Row className="align-items-center mt-1 mb-3">
                    <Col xs={12} sm={8} md={8} lg={11}>Select start and end dates using slide. A tolerance ensures any projects just outside a range are included.</Col>
                </Row>
                <div className="sliderBlock">
                    <FormGroup id="projectTimelineFilter" name="projectTimelineFilter" row className="">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="update-location-slider-frames">
                                <Row>
                                    <Col xs={12} sm={10} md={10} lg={10}>
                                        {
                                            constructionStartRange[0] === constructionStartRange[1] ?
                                                (<div>
                                                    Construction <b>{constructionStartRange[0] == 0 ? "starting " : constructionStartRange[0] < 0 ? "started " : "starts "}</b>
                                                    <span>{returnTimeFrame(constructionStartRange[0])?.message}</span>
                                                    {constructionStartRange[0] < 0 ? " in the past" : constructionStartRange[1] > 0 ? " in the future" : ""}
                                                </div>) :
                                                constructionStartRange[0] <= 0 && constructionStartRange[1] > 0 ?
                                                    (<div>
                                                        Construction <b>started</b> between
                                                        <span>{returnTimeFrame(constructionStartRange[0])?.message}</span>
                                                        {constructionStartRange[0] == 0 ? "" : " ago"} or is due to
                                                        <span> start {returnTimeFrame(constructionStartRange[1])?.message} </span>
                                                        in the future
                                                    </div>) :
                                                    (<div>
                                                        Construction <b>{constructionStartRange[0] < 0 ? "started " : "starts "}</b> between
                                                        <span>{returnTimeFrame(constructionStartRange[0])?.message} </span>
                                                        and <span>{returnTimeFrame(constructionStartRange[1])?.message}</span>
                                                        {constructionStartRange[1] < 0 ? " in the past" : constructionStartRange[1] > 0 ? " in the future" : ""}
                                                    </div>)
                                        }
                                    </Col>
                                    <Col xs={12} sm={2} md={2} lg={2}>
                                        <div className="switch">
                                            <Input id="updateStartConstToggle" type="checkbox" name="updateStartConstToggle" className="cmn-toggle cmn-toggle-round" checked={filter?.filtersState?.[searchPersist.appliedContext]?.startConstruction?.active} onChange={handleStartConstructionToggleChange} />
                                            <Label for="updateStartConstToggle" className="m-0"></Label>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>

                                        {/* <Slider
                                                range min={1}
                                                max={10}
                                            //value={[1, 2]}
                                            /> */}
                                        <div className={`customSliderHandles ${filter?.filtersState?.[searchPersist.appliedContext]?.startConstruction?.active ? "" : "disableBlock"}`}>
                                            <Slider range disabled={!filter?.filtersState?.[searchPersist.appliedContext]?.startConstruction?.active ? true : false} min={-8} max={8} allowCross={false} onChange={handleConstructionStart} value={constructionStartRange} onAfterChange={handleConstructionStartAfterChange} />
                                        </div>
                                        <div className="rangeIndicator mt-2">
                                            <Row>

                                                <Col sm={4} className="text-left fw-bold text-black"> Past </Col>
                                                <Col sm={4} className="text-center fw-bold centerIndicator text-black"> Today </Col>
                                                <Col sm={4} className="text-right fw-bold text-black"> Future </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='py-3'>
                                        <div className='divider'></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={10} md={10} lg={10}>
                                        {
                                            constructionEndRange[0] == constructionEndRange[1] ?
                                                <div>
                                                    Construction <b>{constructionEndRange[0] == 0 ? "ending " : constructionEndRange[0] < 0 ? "ended " : "is due to end"}</b>
                                                    <span>{returnTimeFrame(constructionEndRange[0])?.message} </span>
                                                    {constructionEndRange[0] > 0 ? " in the future" : constructionEndRange[0] < 0 ? " in the past" : ""}
                                                </div> :
                                                constructionEndRange[0] < 0 && constructionEndRange[1] > 0 ?
                                                    <div>
                                                        Construction <b>ended</b> between
                                                        <span>{returnTimeFrame(constructionEndRange[0])?.message}</span> ago or is due to
                                                        <span> end {returnTimeFrame(constructionEndRange[1])?.message}</span> in the future
                                                    </div> :
                                                    <div>
                                                        Construction <b>{constructionEndRange[0] < 0 ? "ended " : constructionEndRange[0] == 0 ? "will end " : "is due to end "}</b>
                                                        between <span>{returnTimeFrame(constructionEndRange[0])?.message}</span> {constructionEndRange[0] == 0 ? " or " : " and "}
                                                        <span>{returnTimeFrame(constructionEndRange[1])?.message}</span>
                                                        {constructionEndRange[1] < 0 ? " in the past" : constructionEndRange[1] > 0 ? " in the future" : ""}
                                                    </div>
                                        }
                                    </Col>
                                    <Col xs={12} sm={2} md={2} lg={2}>
                                        <div className="switch">
                                            <Input id="updateEndConstToggle" name="updateEndConstToggle" type="checkbox" className="cmn-toggle cmn-toggle-round" checked={filter?.filtersState?.[searchPersist.appliedContext]?.endConstruction?.active} onChange={handleEndConstructiontoggleChange} />
                                            <Label for="updateEndConstToggle" className="m-0"></Label>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        {/* <Slider
                                                range
                                                min={1}
                                                max={10}
                                            //value={[1, 2]}
                                            /> */}
                                        <div className={`customSliderHandles ${filter?.filtersState?.[searchPersist.appliedContext]?.endConstruction?.active ? "" : "disableBlock"}`}>
                                            <Slider range disabled={!filter?.filtersState?.[searchPersist.appliedContext]?.endConstruction?.active ? true : false} min={-5} max={8} allowCross={false} onChange={handleConstructionEnd} value={constructionEndRange} onAfterChange={handleConstructionEndAfterChange} />
                                        </div>
                                        <div className="rangeIndicator mt-2">
                                            <Row>
                                                <Col sm={4} className="text-left fw-bold text-black"> Past </Col>
                                                <Col sm={4} className="text-left fw-bold centerIndicator centerIndicatorforConsEnd text-black"> Today </Col>
                                                <Col sm={4} className="text-right fw-bold text-black"> Future </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </FormGroup>
                </div>
            </>
        );
    }


    const calendarTimelineList = () => {
        return (
            <>
                <Row className="align-items-center mt-1 mb-3">
                    <Col xs={12} sm={8} md={8} lg={11}>Select start and end dates on calendar</Col>
                </Row>
                <div className="sliderBlock">
                    <FormGroup id="projectTimelineFilter" name="projectTimelineFilter" row className="">
                        <Col xs={12} sm={8} md={8} lg={12} className="advanced-filter-field-container">
                            <div className="update-location-slider-frames">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='range-heading mb-2'>Construction <b>starts / started</b> between</div>
                                    </Col>
                                </Row>
                                <Row className="text-left pd-tp-b-0">
                                    <Col sm={5} className="DayPickerInput">
                                        <DatePicker
                                            onChange={(date) => handleStartFromDateChange(date)}
                                            className="startDate"
                                            placeholderText="From"
                                            dateFormat="dd-MMM-yyyy"
                                            selected={calendarTimings.start.from}
                                           
                                        />
                                        {/* {startDate !== null ? <button className="clearButton" onClick={() => clearDate('startDate')}></button> : ("")}
                                    {!currentStartDateValid ? <p className="error-text">Enter correct start date</p> : ("")} */}

                                    </Col>
                                    <Col sm={2} className="text-center">
                                        <b className="line-separator">-</b>
                                    </Col>
                                    <Col sm={5} className="DayPickerInput">
                                        <DatePicker
                                            onChange={(date) => handleStartToDateChange(date)}
                                            className="endDate form-control"
                                            placeholderText="To"
                                            dateFormat="dd-MMM-yyyy"
                                            selected={calendarTimings.start.to}
                                            minDate={calendarTimings.start.from}
                                            
                                        />
                                        {/* {endDate !== null ? <button className="clearButton" onClick={() => clearDate('endDate')}></button> : ("")}
                                    {!currentEndDateValid ? <p className="error-text">Enter correct end date</p> : ("")} */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className='py-3'>
                                        <div className='divider'></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className='range-heading mb-2'>Construction <b>ends / ended</b> between</div>
                                    </Col>
                                </Row>
                                <Row className="text-left pd-tp-b-0">
                                    <Col sm={5} className="DayPickerInput">
                                        <DatePicker
                                            onChange={(date) => handleEndFromDateChange(date)}
                                            className="startDate form-control"
                                            placeholderText="From"
                                            dateFormat="dd-MMM-yyyy"
                                            selected={calendarTimings.end.from}
                                            
                                        />
                                        {/* {startDate !== null ? <button className="clearButton" onClick={() => clearDate('startDate')}></button> : ("")}
                                    {!currentStartDateValid ? <p className="error-text">Enter correct start date</p> : ("")} */}
                                    </Col>
                                    <Col sm={2} className="text-center">
                                        <b className="line-separator">-</b>
                                    </Col>
                                    <Col sm={5} className="DayPickerInput">
                                        <DatePicker
                                            onChange={(date) => handleEndToDateChange(date)}
                                            className="endDate form-control"
                                            placeholderText="To"
                                            dateFormat="dd-MMM-yyyy"
                                            selected={calendarTimings.end.to}
                                            minDate={calendarTimings.end.from}
                                            
                                        />
                                        {/* {endDate !== null ? <button className="clearButton" onClick={() => clearDate('endDate')}></button> : ("")}
                                    {!currentEndDateValid ? <p className="error-text">Enter correct end date</p> : ("")} */}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </FormGroup>
                </div>
            </>
        );
    }

    return (
        <div className="advanced-filter-grid projectTimingFilter-frames" id="new_filter_pt">
            <div className="filterHeading">
                <h5 className="mb-3">Project Timeline</h5>
            </div>
            <div className="filterBlock">
                <div className="textcontent">

                    <Col className="p-0">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}>
                                    Rolling timeline
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggle('2')}>
                                    Calendar timeline
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className="ps-0">{rollingTimelineList()}</TabPane>
                            <TabPane tabId="2" className="ps-0">{calendarTimelineList()}</TabPane>
                        </TabContent>
                    </Col>
                </div>
            </div>
        </div>
    )

}
export default ProjectTimingFilter;