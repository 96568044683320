import getAxios from '../../utils/baseApi';
import { messageAlert, modifyUsersResponse } from '../../utils';
import { handleError } from '../../utils/errorHandler';
import { resetAssignedLicenses, updateAdminUserCount, updateIsAddNewUser, updateIsShowMoreActiveForUserActivity, updateIsUniqueUser, updateUserActivityFilePath, updateUserActivityList, updateUserActivityShowMoreList, updateUserAdminShowMoreActive, updateUserListDeleteWithTransfer, userDetails, userLicences } from './reducer';
var axios = getAxios()

export const fetchAllUsersForAdmin = (payload, dispatch,useradminData) => {
    let url;
    if (payload?.searchTerm === "") {
        url = "/users?filter_by=deleted" + '&offset=' + payload?.offset + '&limit=' + payload?.limit
    }
    else {
        url = '/users?filter="' + payload?.searchTerm + '"'
    }
    axios.get(url).then(async (response) => {
        if (response.status === 200) {
            let users_list = response.data
            let licences_list = []
            await axios.get('/licences').then((res) => {
                if (res.status === 200) {
                    licences_list = res.data
                }
            })
            const http_response = {
                'users': users_list,
                'licences': licences_list
            }
            let users = modifyUsersResponse(http_response);
            dispatch(updateAdminUserCount(response.data.users_count))
            if (response?.data?.users_count > (parseInt(payload?.offset) + parseInt(payload?.limit))) {
                dispatch(updateUserAdminShowMoreActive(true))
            } else {
                dispatch(updateUserAdminShowMoreActive(false))
            }
            if (payload?.offset > 0) {
                dispatch(userDetails({...useradminData?.allUserDetails, ...users})) 
            }
            else{
                dispatch(userDetails(users)) 
            }
            dispatch(userLicences(licences_list.licences))
            dispatch(resetAssignedLicenses([]))
         
          
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const createNewUser = (payload, searchTerm, dispatch) => {
    axios.post('/users', payload).then(async (response) => {
        if (response.status === 201) {
            dispatch(updateIsAddNewUser(false))
            messageAlert(dispatch, "user created Successfully", 'success')
            fetchAllUsersForAdmin({offset: 0,limit: 50,searchTerm: searchTerm}, dispatch)

        }
    }).catch((error) => {
        if (error?.response?.data?.error?.status_code === 400) {
            messageAlert(dispatch, error?.response?.data.error.message, 'danger')
            handleError(error, dispatch)
        }

    })
}

export const findUniqueUser = (payload, dispatch) => {
    axios.get('/unique_user?username=' + payload
    ).then(async (response) => {
        if (response.status === 200) {
            if (Array.isArray(response.data)) {
                dispatch(updateIsUniqueUser(false))
            }
            else {
                dispatch(updateIsUniqueUser(true))
            }
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const updateExistingUser = (payload,id, searchTerm, dispatch) => {
    let jsonData = JSON.stringify(payload)
    axios.put(`/users/${id}`, jsonData).then((response) => {
        if (response.status === 200) {
            messageAlert(dispatch, "User updated successfully", 'success')
            dispatch(updateIsAddNewUser(false))
            fetchAllUsersForAdmin({offset:0,limit:50,searchTerm:searchTerm}, dispatch)
          

        }
    }).catch((error) => {
        if (error?.response?.data?.error?.status_code === 400) {
            messageAlert(dispatch, error?.response?.data.error.message, 'danger')
            handleError(error, dispatch)
        }

    })
}


export const deleteUserWithoutTransfering = (userId, searchTerm, dispatch) => {
    axios.delete('/users/' + userId)
        .then(function (response) {
            if (response.status === 200) {
                fetchAllUsersForAdmin({ offset:0, limit:50,searchTerm:searchTerm}, dispatch)
                messageAlert(dispatch, "User deletion scheduled successfully", 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const deleteUserWitTransfering = (userId, moveToUserId, searchTerm, dispatch) => {
    let moveObj = { "move_to":moveToUserId }
    axios.delete('/users/' + userId, { data: JSON.stringify(moveObj) })
        .then(function (response) {
            if (response.status === 200) {
                fetchAllUsersForAdmin({ offset:0, limit:50,searchTerm:searchTerm}, dispatch)
                messageAlert(dispatch, "User deletion scheduled successfully", 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const resendWelcomeMail = (payload, dispatch) => {
    axios.post(`users/${payload?.user_id}?send_welcome_email=${payload.send_welcome_email}`)
        .then(function (response) {
            if (payload.send_welcome_email) {
                messageAlert(dispatch, "Resent mail successfully", 'success')
            }
            else {
                messageAlert(dispatch, "Sent mail successfully", 'success')
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const grantUserLicenses = (payload,searchTerm, dispatch) => {
    if (payload?.length > 0) {
        payload.forEach(element => {
            if(element?.revoke?.length>0){
                let licenceObj = { "licence_id": element.revoke }
                axios.delete(`users/${element?.userno}/licences`, { data: JSON.stringify(licenceObj) }).then((response) => {
                    if (response.status === 201) {
                        messageAlert(dispatch, 'Your preferences have been saved', 'success')
                        fetchAllUsersForAdmin({offset:0,limit:50,searchTerm:searchTerm}, dispatch)
                    }
                }).catch((error) => {
                    handleError(error, dispatch)
                })
            }
            if(element?.grant?.length>0){
            var jsonData = JSON.stringify({ "licence_id": element.grant });
                axios.put(`users/${element?.userno}/licences`, jsonData).then((response) => {
                    if (response.status === 201) {
                        messageAlert(dispatch, 'Your preferences have been saved', 'success')
                        fetchAllUsersForAdmin({offset:0,limit:50,searchTerm:searchTerm }, dispatch)
                    }
                }).catch((error) => {
                    handleError(error, dispatch)
                })
            }
            
        });

    }

}

export const fetchAllUserListDeleteWithTransfer = (dispatch) => {
    axios.get( "/users?filter_by=deleted&offset=0&limit=10000").then(async (response) => {
        if (response.status === 200) {
            dispatch(updateUserListDeleteWithTransfer(response?.data?.users))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchUserActivities = (payload, dispatch) => {
    var reportData = {
        ...payload,
        viewreport: true
    }
    delete reportData.offset;
    delete reportData.limit;
    axios.get(`/user_activities`, {
        params: {
            agg_group: 'user_id',
            query: JSON.stringify({
                event_on: {
                    value1: payload.from,
                    value2: payload.to,
                    operator: '..',
                },
            }),
            offset: payload.offset,
            limit: payload.limit,
        }
    }).then(async (response) => {

        if (response.status === 200) {
            if (response?.data) {
                response.data.user_activity.forEach(userActivity => {
                    userActivity.initials = ((userActivity.first_name ? userActivity.first_name[0].toUpperCase() : "") + (userActivity.last_name ? userActivity.last_name[0].toUpperCase() : ""));
                })
            }
            if (response?.data?.users_count > parseInt(payload?.offset) + parseInt(payload?.limit)) {
                dispatch(updateIsShowMoreActiveForUserActivity(true));
            } else {
                dispatch(updateIsShowMoreActiveForUserActivity(false));
            }
            if (payload?.offset > 0) {
                dispatch(updateUserActivityShowMoreList(response?.data?.user_activity));
            } else {
                dispatch(updateUserActivityList(response?.data?.user_activity));
            }
            axios.get(`/user_activities`, {
                params: {
                    agg_group: 'user_id',
                    query: JSON.stringify({
                        event_on: {
                            value1: payload.from,
                            value2: payload.to,
                            operator: '..',
                        },
                    }),
                    "view_csv": "True"

                }
            })
                .then(function (response) {
                    dispatch(updateUserActivityFilePath(response.data.file_path));
                })
                .catch(function (error) {
                    handleError(error, dispatch)
                })
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}