import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { updateHierachialSort, updateSortClickJA, updateSortSearch } from '../redux/searchPeristReducer';
import { updateSearchListSelection } from '../redux/reducer';
import { updateOffsetLimit, updateSort } from '../filters/redux/reducer';
import { capitalizeWordsAfterSpace, fetchSortValue } from '../../../../utils/commonFunctions';

const SortOrder = () => {
  const searchPersist = useSelector((state) => state.justAskPersist)
  const [sortOrderObj, setSortOrderObj] = useState(searchPersist.defaultSort)
  const [heirachialSortObj, setHeirachialSortObj] = useState(searchPersist.heirarchialSort)
  const [sortOrder, setSortOrder] = useState({
    dropDownOpen: false,
  })
  const search = useSelector((state) => state.justAsk)
  const filter = useSelector((state) => state.justAskFilter)
  const dispatch = useDispatch()
  const type = search?.showSelectedCompanies === true ? "company" : "project"
  const [activeSort, setActiveSort] = useState((search.showSelectedCompanies && searchPersist.appliedContext !== "people") || (search.showSelectedProjects && searchPersist.appliedContext !== "people") ? heirachialSortObj?.[type].label : searchPersist.defaultSort?.[searchPersist.appliedContext].label)
  


  useEffect(() => {
    setSortOrderObj(_.cloneDeep(searchPersist.defaultSort))
    setHeirachialSortObj(_.cloneDeep(searchPersist.heirarchialSort))
    setActiveSort(((search.showSelectedCompanies && searchPersist.appliedContext !== "people") || (search.showSelectedProjects && searchPersist.appliedContext !== "people")) ? heirachialSortObj?.[type].label : searchPersist.defaultSort?.[searchPersist.appliedContext].label)
  }, [searchPersist.defaultSort, searchPersist.heirarchialSort, searchPersist.appliedContext])


  useEffect(() => {
    if (search.showSelectedCompanies || search.showSelectedProjects) {
      Object.assign({}, heirachialSortObj && heirachialSortObj[type], { label: heirachialSortObj?.[type].label, value: heirachialSortObj?.[type].value });
      let newSortOrder = fetchSortValue(searchPersist?.heirarchialSort[type]?.label?.charAt(0)?.toLowerCase() + searchPersist?.heirarchialSort[type]?.label?.slice(1), type);
      dispatch(updateSort(newSortOrder))
      setHeirachialSortObj(_.cloneDeep(heirachialSortObj))
      dispatch(updateHierachialSort(_.cloneDeep(heirachialSortObj)));

    }
    setActiveSort(((search.showSelectedCompanies && searchPersist.appliedContext !== "people") || (search.showSelectedProjects && searchPersist.appliedContext !== "people")) ? heirachialSortObj?.[type].label : searchPersist.defaultSort?.[searchPersist.appliedContext].label)


  }, [search.showSelectedCompanies, search.showSelectedProjects])

  const handleCommonSort = (sortOrder) => {
    if (search.showSelectedCompanies || search.showSelectedProjects) {
      let newSortOrder = fetchSortValue(sortOrder, type)
      Object.assign(heirachialSortObj && heirachialSortObj[type], { label: sortOrder === "projectCount" ? "Projects" : sortOrder === "projectValue" ? "Value" : sortOrder, value: newSortOrder });
      dispatch(updateSort(newSortOrder))
      setHeirachialSortObj(_.cloneDeep(heirachialSortObj))
      dispatch(updateSortClickJA(true))
      dispatch(updateHierachialSort(_.cloneDeep(heirachialSortObj)));
    }
    else {
      let newSortOrder = fetchSortValue(sortOrder, searchPersist.appliedContext)
      Object.assign(sortOrderObj && sortOrderObj[searchPersist.appliedContext], { label: sortOrder === "projectCount" ? "Projects" : sortOrder === "projectValue" ? "Value" : sortOrder, value: newSortOrder });
      dispatch(updateSort(newSortOrder))
      setSortOrderObj(_.cloneDeep(sortOrderObj))
      dispatch(updateSortClickJA(true))
      dispatch(updateSortSearch(_.cloneDeep(sortOrderObj)));
    }


    if (search.selectedSelectorControlValue !== 0) {
      dispatch(updateSearchListSelection(0))
    }
    if (search.countOfSelectedObjectIds !== 0) {
      dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
    }
    dispatch(updateOffsetLimit({ offset: 0, records: 50 }));

  }

  return (
    <>
      <Dropdown disabled={search.showProjectHierarchy || search.showCompanyHierarchy || search.showDetailPeople} isOpen={sortOrder.dropDownOpen} toggle={() => setSortOrder(prev => ({ ...prev, dropDownOpen: !sortOrder.dropDownOpen }))}>
        {
          (search.showSelectedProjects && searchPersist.appliedContext === 'people' || searchPersist.appliedContext === 'people' && search.showDetailPeople || search.showProjectHierarchy || search.showCompanyHierarchy) ?
            <DropdownToggle caret className="sort-by-factors" disabled="true">
              {capitalizeWordsAfterSpace(activeSort)}
            </DropdownToggle>
            :
            <DropdownToggle caret className="sort-by-factors" >
              {capitalizeWordsAfterSpace(activeSort)}
            </DropdownToggle>
        }

        <DropdownMenu>
          {
            (searchPersist.appliedContext === 'company') ?
              (
                <div>
                  <DropdownItem disabled={true}>
                    Alphabetical
                  </DropdownItem>
                  <DropdownItem divider />
                </div>
              ) : ('')
          }

          {(searchPersist.appliedContext === 'project' || searchPersist.appliedContext === 'company') ?
            (
              <DropdownItem disabled={searchPersist.appliedContext === "people"} onClick={() => handleCommonSort("latest")} >
                Latest
              </DropdownItem>
            ) : ('')
          }
          <DropdownItem divider />
          {
            (searchPersist.appliedContext === 'project' || searchPersist.appliedContext === 'company') ?
              (<DropdownItem disabled={(filter.filtersState[searchPersist.appliedContext]?.location?.location === '' && searchPersist.appliedContext === 'project')
                || (filter.filtersState[searchPersist.appliedContext]?.companyLocation?.location === '' && searchPersist.appliedContext === 'company') || (search.isShowProjectsOrCompanySelected)}
                onClick={() => handleCommonSort("nearest")}>
                Nearest
              </DropdownItem>) : ('')
          }
          {(!search.showSelectedCompanies && (searchPersist.appliedContext === 'project') || (search.showSelectedCompanies && searchPersist.appliedContext === 'company') || (search.showSelectedProjects)) ?
            (
              <div>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("value")}>
                  Value
                </DropdownItem>
              </div>
            ) : ('')
          }
          {(searchPersist.appliedContext === 'people') ?
            (
              <div>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("alphabetical")}>
                  Alphabetical
                </DropdownItem>
              </div>
            ) : ('')
          }
          {(!search.showSelectedCompanies && (searchPersist.appliedContext === 'company') || (search.showSelectedCompanies && searchPersist.appliedContext === 'project')) && !search.showSelectedProjects ?
            (
              <div>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("projectCount")}>
                  Projects
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("projectValue")}>
                  Value
                </DropdownItem>
              </div>
            ) : ('')
          }
          <DropdownItem divider />
          {
            (!search.showSelectedCompanies && searchPersist.appliedContext === 'project' || (search.showSelectedCompanies && searchPersist.appliedContext === 'company') || search.showSelectedProjects) ?
              (<div>
                <DropdownItem onClick={() => handleCommonSort("start date")}>
                  Start Date
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleCommonSort("end date")}>
                  End Date
                </DropdownItem>
                <DropdownItem divider />
              </div>) : ('')
          }
          {
            !search.showSelectedCompanies ?
              (<DropdownItem disabled={(filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active === false && filter.filtersState[searchPersist.appliedContext]?.projectTitle?.active === false && filter.filtersState[searchPersist.appliedContext]?.projectScheme?.active === false && searchPersist.appliedContext === 'project')
                || (filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active === false && filter.filtersState[searchPersist.appliedContext]?.companyId?.active === false && filter.filtersState[searchPersist.appliedContext]?.companyName?.active === false && searchPersist.appliedContext === 'company')
                || (filter.filtersState[searchPersist.appliedContext]?.personInformation?.active === false && filter.filtersState[searchPersist.appliedContext]?.personName?.active === false && filter.filtersState[searchPersist.appliedContext]?.personJobTitle?.active === false && searchPersist.appliedContext === 'people')}
                onClick={() => handleCommonSort("relevance")}>
                Relevance
              </DropdownItem>) : ('')
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default SortOrder